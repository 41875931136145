import React from "react";
import { Accordion } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AccordionToggle from "src/shared/styleguide/accordiontoggle/AccordionToggle";
import "./Sidebar.scss";

export const Sidebar = ({ categories = [] }) => {
  return (
    <React.Fragment>
      {categories.map((category) => (
        <React.Fragment key={category.id}>
          <Accordion
            defaultActiveKey={category.expanded ? category.id.toString() : null}
            className="py-2"
          >
            <AccordionToggle
              eventKey={category.id.toString()}
              expanded={category.expanded}
            >
              {category.label}
            </AccordionToggle>
            <Accordion.Collapse eventKey={category.id.toString()}>
              <React.Fragment>
                {category.links.map((link) => (
                  <Link
                    className={classnames(
                      "d-block py-1 sidebar-link text-decoration-none cursor-pointer text-reset",
                      {
                        "font-weight-bold": link.isActive,
                        "sidebar-link-active": link.isActive,
                      },
                    )}
                    key={link.id}
                    to={link.to}
                  >
                    {link.label}
                  </Link>
                ))}
              </React.Fragment>
            </Accordion.Collapse>
          </Accordion>
          <hr className="m-0 p-0" />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      expanded: PropTypes.bool,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          to: PropTypes.string,
          label: PropTypes.label,
          isActive: PropTypes.bool,
        }),
      ),
    }),
  ),
};

export default Sidebar;
