import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Button, { ButtonGroup } from "src/shared/styleguide/button/Button";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import Form from "src/shared/styleguide/form/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import "src/components/reviews/get_reviews/GetReviewsInviteForm.scss";
import { validatePhoneNumber } from "src/utils/phoneValidator";
import PropTypes from "prop-types";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import { addToast } from "src/utils/ToastUtil"

import { showCompanyId as companyId } from "src/shared/SessionHelper";

const GetReviewsInviteForm = ({
  createEmailInvitation,
  createCustomerCall,
}) => {
  const [activeBtn, setActiveBtn] = useState("email");
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // state
  // add method to yup to validate phone numbers
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneMobile: "",
      phoneDaytime: "",
      phoneDaytimeExt: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Please enter a valid email address")
        .test("email", "Please enter a valid email address", () => validEmail),
      phoneMobile: Yup.string()
        .test("phoneMobile", "Please enter a mobile number", () => validPhone)
        .test(
          "phoneMobile",
          "Please enter a valid mobile number",
          validatePhoneNumber,
        ),
      phoneDaytime: Yup.string().test(
        "phoneDaytime",
        "Please enter a valid daytime phone number",
        validatePhoneNumber,
      ),
      // might need to bring back depending on product
      // phoneDaytimeExt: Yup.string().test('phoneDaytimeExt', 'Please enter a valid extn', extn => {
      //   if (!extn) return true
      //   if (extn.length > 6) return false
      //   const regex = new RegExp(/^\d+$/);
      //   return regex.test(extn)
      // })
    }),
    onSubmit: (values) => {
      saveChanges(values);
    },
  });

  const handleSuccess = () => {
    addToast({
      message: "Customer Invited Successfully",
      type: "success",
    });
  };

  const handleErrorMessage = (message) => {
    addToast({
      message: message,
      type: "error",
    });
  };

  useEffect(() => {
    if (activeBtn === "email") {
      if (!formik.values.email) setValidEmail(false);
      if (formik.values.email) setValidEmail(true);
      setValidPhone(true);
    } else {
      if (!formik.values.phoneMobile && !formik.values.phoneDaytime)
        setValidPhone(false);
      if (formik.values.phoneMobile || formik.values.phoneDaytime)
        setValidPhone(true);
      setValidEmail(true);
    }
  }, [formik.values, activeBtn]);

  // methods
  const saveChanges = async (values) => {
    setSubmitting(true);
    if (activeBtn === "email") {
      try {
        await createEmailInvitation({
          variables: { ...values, companyId: companyId },
          fetchPolicy: "no-cache",
        });

        setSubmitting(false);
        formik.resetForm();
        handleSuccess();
      } catch (e) {
        if (e.message === "GraphQL error: Contact was invited recently")
          handleErrorMessage(e.message.split(":")[1]);
        setSubmitting(false);
      }
    }

    if (activeBtn === "call") {
      try {
        await createCustomerCall({
          variables: { ...values, companyId: companyId },
          fetchPolicy: "no-cache",
        });

        setSubmitting(false);
        formik.resetForm();
        handleSuccess();
      } catch (e) {
        if (e.message === "GraphQL error: Contact was invited recently")
          handleErrorMessage(e.message.split(":")[1]);
        setSubmitting(false);
      }
    }
  };

  // render
  return (
    <div className="my-4 py-3 invite-form-container">
      {/* form elements */}
      <Row className="mx-1 inner-form-container">
        {/* first name */}
        <div className="input-long">
          <Form.Group controlId="firstName">
            <Form.Label className="text-dark">First Name</Form.Label>
            <Form.Control
              value={formik.values.firstName}
              placeholder="Enter first name"
              onChange={formik.handleChange}
            />
            <div
              className="get-reviews-error"
              style={{
                display:
                  formik.errors.firstName && formik.touched.firstName
                    ? "flex"
                    : "none",
              }}
            >
              {formik.errors.firstName}
            </div>
          </Form.Group>
        </div>

        {/* last name */}
        <div className="input-long">
          <Form.Group controlId="lastName">
            <Form.Label className="text-dark">Last Name</Form.Label>
            <Form.Control
              value={formik.values.lastName}
              placeholder="Enter last name"
              onChange={formik.handleChange}
            />
            <div
              className="get-reviews-error"
              style={{
                display:
                  formik.errors.lastName && formik.touched.lastName
                    ? "flex"
                    : "none",
              }}
            >
              {formik.errors.lastName}
            </div>
          </Form.Group>
        </div>

        {/* email address */}
        <div className="input-long">
          <Form.Group controlId="email">
            <Form.Label className="text-dark">Email Address</Form.Label>
            <Form.Control
              value={formik.values.email}
              placeholder="Enter email address"
              onChange={formik.handleChange}
            />
            <div
              className="get-reviews-error"
              style={{
                display:
                  formik.errors.email && formik.touched.email ? "flex" : "none",
              }}
            >
              {formik.errors.email}
            </div>
          </Form.Group>
        </div>

        {/* mobile number */}
        <div className="input-long">
          <Form.Group controlId="phoneMobile">
            <Form.Label className="text-dark">Mobile Number</Form.Label>
            <Form.Control
              value={formik.values.phoneMobile}
              placeholder="Enter mobile no."
              onChange={formik.handleChange}
            />
            <div
              className="get-reviews-error"
              style={{
                display:
                  formik.errors.phoneMobile && formik.touched.phoneMobile
                    ? "flex"
                    : "none",
              }}
            >
              {formik.errors.phoneMobile}
            </div>
          </Form.Group>
        </div>

        {/* daytime phone number */}
        <div>
          <Form.Group
            className="d-inline-block"
            style={{ position: "relative" }}
            controlId="phoneDaytime"
          >
            <Form.Label className="text-dark">Daytime Phone Number</Form.Label>
            <Form.Control
              className="input-medium"
              value={formik.values.phoneDaytime}
              placeholder="Enter daytime phone no."
              onChange={formik.handleChange}
            />
            <div
              className="get-reviews-error"
              style={{
                position: "absolute",
                width: "235px",
                display:
                  formik.errors.phoneDaytime && formik.touched.phoneDaytime
                    ? "flex"
                    : "none",
              }}
            >
              {formik.errors.phoneDaytime}
            </div>
          </Form.Group>
          <Form.Group
            className="d-inline-block mr-4"
            controlId="phoneDaytimeExt"
          >
            <Form.Label className="text-dark"></Form.Label>
            <Form.Control
              className="input-small"
              value={formik.values.phoneDaytimeExt}
              placeholder="Extn"
              onChange={formik.handleChange}
            />
          </Form.Group>
          <ButtonGroup
            style={{
              position: "relative",
              top: -2,
            }}
          >
            <PopupInfo
              message="Send Call Invite"
              icon={
                <Button
                  style={{
                    ...(activeBtn === "call"
                      ? {
                          backgroundColor: "#f47821",
                          border: "1px solid #f47821 !important",
                        }
                      : {
                          backgroundColor: "white",
                          border: "1px solid #ced4da !important",
                        }),
                  }}
                  className="d-flex p-2 px-3 button-border"
                  variant="outline-light"
                  onClick={() => setActiveBtn("call")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 20.783 20.776"
                    fill={activeBtn === "call" ? "white" : "#667587"}
                  >
                    <g transform="translate(-.563 .35)">
                      <path
                        d="M4.965 13.345a21.3 21.3 0 0 0 7.084 5.549 11.026 11.026 0 0 0 3.964 1.173c.1 0 .187.008.283.008a3.283 3.283 0 0 0 2.546-1.094.091.091 0 0 0 .017-.021 10.72 10.72 0 0 1 .8-.832c.2-.187.4-.383.587-.582a2.009 2.009 0 0 0-.008-2.991l-2.5-2.5a2.024 2.024 0 0 0-1.464-.674 2.092 2.092 0 0 0-1.481.67l-1.489 1.489c-.137-.079-.279-.15-.412-.216a5.144 5.144 0 0 1-.458-.25 15.761 15.761 0 0 1-3.763-3.427A9.076 9.076 0 0 1 7.4 7.617c.391-.354.757-.724 1.111-1.086.125-.129.254-.258.383-.387a2.133 2.133 0 0 0 .691-1.5 2.116 2.116 0 0 0-.691-1.5l-1.24-1.24c-.146-.146-.283-.287-.424-.433-.277-.277-.564-.571-.847-.83A2.074 2.074 0 0 0 4.919 0a2.135 2.135 0 0 0-1.481.645L1.882 2.2a3.2 3.2 0 0 0-.953 2.047 7.7 7.7 0 0 0 .578 3.328 19.361 19.361 0 0 0 3.458 5.77zm-3.02-9.01a2.2 2.2 0 0 1 .661-1.415l1.547-1.547a1.12 1.12 0 0 1 .765-.354 1.063 1.063 0 0 1 .749.362c.279.258.541.528.824.815.141.146.287.291.433.441l1.24 1.24a1.13 1.13 0 0 1 .391.778 1.13 1.13 0 0 1-.391.778l-.387.391c-.387.391-.749.761-1.148 1.115l-.021.021a.813.813 0 0 0-.208.924c0 .012.008.021.012.033a9.79 9.79 0 0 0 1.46 2.375 16.635 16.635 0 0 0 4.01 3.652 5.954 5.954 0 0 0 .549.3 5.144 5.144 0 0 1 .458.25l.046.025a.87.87 0 0 0 .4.1.882.882 0 0 0 .62-.283l1.556-1.556a1.1 1.1 0 0 1 .761-.37 1.045 1.045 0 0 1 .736.37l2.508 2.5a1 1 0 0 1-.012 1.568c-.175.187-.358.366-.553.553a11.981 11.981 0 0 0-.869.9 2.284 2.284 0 0 1-1.785.757c-.071 0-.146 0-.216-.008a10.017 10.017 0 0 1-3.581-1.062A20.233 20.233 0 0 1 5.755 12.7a18.532 18.532 0 0 1-3.286-5.47 6.709 6.709 0 0 1-.525-2.895z"
                        style={{
                          strokeWidth: 0.7,
                        }}
                      />
                    </g>
                  </svg>
                </Button>
              }
            />
            <PopupInfo
              message="Send Email Invite"
              icon={
                <Button
                  style={{
                    ...(activeBtn === "email"
                      ? {
                          backgroundColor: "#f47821",
                          border: "1px solid #f47821 !important",
                        }
                      : {
                          backgroundColor: "white",
                          border: "1px solid #ced4da !important",
                        }),
                  }}
                  className="d-flex p-2 px-3 button-border"
                  onClick={() => setActiveBtn("email")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.2"
                    height="14.958"
                    viewBox="0 0 22.2 16.958"
                    fill={activeBtn === "email" ? "white" : "#667587"}
                  >
                    <g transform="translate(.1 -60.9)">
                      <g transform="translate(0 61)">
                        <path
                          d="M20.066 61H1.934A1.937 1.937 0 0 0 0 62.934v12.89a1.937 1.937 0 0 0 1.934 1.934h18.132A1.937 1.937 0 0 0 22 75.824v-12.89A1.937 1.937 0 0 0 20.066 61zm-.266 1.289l-8.758 8.758-8.835-8.758zM1.289 75.557V63.194L7.5 69.349zm.911.911l6.212-6.212 2.177 2.158a.645.645 0 0 0 .91 0l2.122-2.122 6.179 6.177zm18.51-.911l-6.178-6.178 6.179-6.179z"
                          transform="translate(0 -61)"
                          style={{
                            strokeWidth: 0.2,
                          }}
                        />
                      </g>
                    </g>
                  </svg>
                </Button>
              }
            />
          </ButtonGroup>
        </div>
      </Row>

      {/* action buttons */}
      <div className="d-flex flex-column btn-container">
        <Row className="mx-1 mb-2">
          <div>
            <Button
              disabled={submitting}
              style={{ ...(submitting ? { opacity: 0.4 } : { opacity: 1 }) }}
              onClick={formik.handleSubmit}
              variant="primary"
              className="mr-2"
            >
              Send Invite
            </Button>
            <Button
              onClick={() => {
                formik.resetForm();
                setActiveBtn("email");
              }}
              className="clear-btn"
              variant="outline-secondary"
            >
              Clear
            </Button>
          </div>
        </Row>

        {/* info icon */}
        <Row className="mx-1">
          <div>
            <PopupIcon
              props={{
                className: "mb-1 mr-1",
              }}
              iconSize={16}
            />
            <span className="font-italic">
              A customer is added when an invite is sent.
            </span>
          </div>
        </Row>
      </div>
    </div>
  );
};

GetReviewsInviteForm.propTypes = {
  createCustomerCall: PropTypes.func.isRequired,
  createEmailInvitation: PropTypes.func.isRequired,
};

export default GetReviewsInviteForm;
