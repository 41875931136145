import { gql } from "apollo-boost";

export const QUERY_DIRECT_CONNECTION_STATUS = gql`
  query getDirectConnectSettings($id: ID!) {
    directConnectSettings(companyId: $id) {
      linkEstablished
      lastRunDate
    }
  }
`;

export const QUERY_FOLLOW_UP_SETTINGS_STATUS = gql`
  query globalCampaignSetting($id: ID!) {
    globalCampaignSetting(companyId: $id) {
      email
      emailFirstName
    }
  }
`;