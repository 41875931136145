import CustomerReviewsCircle from 'src/assets/images/widgets/read_customer_circle.png'
import CustomerReviewsSquare from 'src/assets/images/widgets/read_customer_widget.png'
import ClientReviewsSquare from 'src/assets/images/widgets/read_client_widget.png'
import ClientReviewsCircle from 'src/assets/images/widgets/read_client_circle.png'

function copyTextToClipboard(text) {
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
    } catch (e) {

    }

    document.body.removeChild(textArea);
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text)
}

function copyReviewWidgetToClipboard({ companyId, widgetWidth, reviewCount, abbreviatedName, widgetColor }) {
  const codeToCopy = `<script type='text/javascript' id='review-widget' data-customerlobby-company-id=${companyId} data-customerlobby-widget-type='review_widget' data-customerlobby-widget='true' data-widget-width=${widgetWidth} data-review-count=${reviewCount}>
  (function() {
    var script   = document.createElement("script");
    script.id    = "customer-lobby-review-widget-script";
    script.src   = "${process.env.REACT_APP_API_SECURE_URL}/widgets/widget";
    script.async = true;
    var entry    = document.getElementById("review-widget");
    entry.parentNode.insertBefore(script, entry);
  })();
  </script>`
  copyTextToClipboard(codeToCopy)
}

function copyStaticButtonOne({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby--><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/', 'ReviewPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768, height=817,left=570,
  top=200,screenX=570,screenY=200'); return false;" target="_blank"><img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}"
  alt="Statistics" style="display: none; border: none;" /><img alt="Review of ${companyName}"
  src='https://customerlobby-widget-images.s3.amazonaws.com/v2/customers_static_circle.png' style="border: none;height:110px;width:110px;" /></a><!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyStaticButtonTwo({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby--><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/', 'ReviewPage', 'statusbar=no,
  menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768, height=817,left=570,
  top=200,screenX=570,screenY=200'); return false;" target="_blank"><img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}"
  alt="Statistics" style="display: none; border: none;" /><img alt="Review of ${companyName}"
  src="https://customerlobby-widget-images.s3.amazonaws.com/v2/client_static_circle.png" style="border: none;height:110px;width:110px;" /></a><!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyStaticButtonThree({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby--><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/', 'ReviewPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,
  width=768, height=817,left=570,top=200,screenX=570,screenY=200');
  return false;" target="_blank"><img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}"
  alt="Statistics" style="display: none; border: none;" />
  <img alt="${process.env.REACT_APP_WWW_URL}"
  src="https://customerlobby-widget-images.s3.amazonaws.com/v2/customers_static_rectangle.png"
  style="border: none;height:110px;width:110px;" /></a><!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyStaticButtonFour({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby--><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/" onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/',
  'ReviewPage', 'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768, height=817,
  left=570,top=200,screenX=570,screenY=200'); return false;" target="_blank">
  <img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}" alt="Statistics" style="display: none; border: none;" />
  <img alt="Review of ${companyName}" src="https://customerlobby-widget-images.s3.amazonaws.com/v2/client_static_rectangle.png" style="border: none;height:110px;width:110px;" /></a><!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyDynamicButtonOne({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby-->
  <script type='text/javascript' id='review-widget' data-customerlobby-company-id=${companyId} data-customerlobby-widget-type='dynamic_reviews_button' data-customerlobby-widget='true'>
  (function() {
    var script   = document.createElement("script");
    script.id    = "customer-lobby-review-widget-script";
    script.src   = "${process.env.REACT_APP_API_SECURE_URL}/widgets/widget";
    script.async = true;
    var entry    = document.getElementById("review-widget");
    entry.parentNode.insertBefore(script, entry);
  })();
  </script>
  <!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyDynamicButtonTwo({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby--><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/" onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/',
  'ReviewPage', 'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768, height=817,left=570,
  top=200,screenX=570,screenY=200'); return false;" target="_blank"><img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}"
  alt="Statistics" style="display: none; border: none;" /><img alt="Review of ${companyName}"
  src="${process.env.REACT_APP_WWW_URL}img/${companyId}/standard" style="border: none;" /></a><!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyAppointmentAndReviewButton({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby-->
  <div><center><div><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/appointment?s=widget"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/appointment', 'AppointmentPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,
  height=817,left=570,top=200,screenX=570,screenY=200'); return false;"
  target="_blank"><img alt="Request appointment with ${companyName}"
  src="${process.env.REACT_APP_WWW_URL}images/logos/schedule_appointment_a.png" style="border: none;"  width = "148"/></a>
  </div><div style=" line-height:5px;">&nbsp;</div><div>
  <a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/', 'ReviewPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,
  height=817,left=570,top=200,screenX=570,screenY=200'); return false;"
  target="_blank"><img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}" alt="Statistics"
  style="display: none; border: none;"><img alt="Review of ${companyName}"
  src="${process.env.REACT_APP_WWW_URL}img/${companyId}/compact" style="border: none;"></a></div></center></div>
  <!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyAppointmentAndReviewButtonV2({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby-->
  <div><center><div><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/appointment?s=widget"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/appointment', 'AppointmentPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,
  height=817,left=570,top=200,screenX=570,screenY=200'); return false;"
  target="_blank"><img alt="Request appointment with ${companyName}"
  src="${process.env.REACT_APP_WWW_URL}images/logos/schedule_appointment_a.png" style="border: none;"  width = "148"/></a>
  </div><div style=" line-height:5px;">&nbsp;</div><div>
  <a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/"
  onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/', 'ReviewPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,
  height=817,left=570,top=200,screenX=570,screenY=200'); return false;"
  target="_blank"><img src="${process.env.REACT_APP_WWW_URL}ctrack-${companyId}" alt="Statistics"
  style="display: none; border: none;"><img alt="Review of ${companyName}"
  src="${process.env.REACT_APP_WWW_URL}img/${companyId}/compact" style="border: none;"></a></div></center></div>
  <!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyAppointmentButton({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `<!--Start Customer Lobby--><a href="${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/appointment" onclick="window.open('${process.env.REACT_APP_WWW_URL}reviews/${companyId}/${abbreviatedName}/appointment',
  'AppointmentPage', 'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,height=620,left=570,top=200,
  screenX=570,screenY=200'); return false;" target="_blank"><img alt="Request appointment with ${companyName}"
  src="${process.env.REACT_APP_WWW_URL}images/logos/schedule_appointment_a.png" style="border: none;" /></a><!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyAppointmentAndDynamicButtonV2({ companyId, abbreviatedName, companyName, appointmentLink }) {
  const codeToCopy = `<!--Start Customer Lobby-->
  <div style='display:flex;flex-direction:column;align-items:center;font-family: "proxima-nova", sans-serif !important;-webkit-font-smoothing: antialiased;font-weight: 500;'>
    <a href="${appointmentLink}"
    onclick="window.open('${appointmentLink}', 'AppointmentPage',
    'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,
    height=817,left=570,top=200,screenX=570,screenY=200'); return false;"
    target="_blank" style='text-decoration:none;margin-bottom:-4px'>
      <img src='https://customerlobby-widget-images.s3.amazonaws.com/v2/appointment_widget.png' style="width:220px;" />
    </a>

    <script type='text/javascript' id='review-widget' data-customerlobby-company-id=${companyId} data-customerlobby-widget-type='dynamic_reviews_button' data-customerlobby-widget='true'>
    (function() {
      var script   = document.createElement("script");
      script.id    = "customer-lobby-review-widget-script";
      script.src   = "${process.env.REACT_APP_API_SECURE_URL}/widgets/widget";
      script.async = true;
      var entry    = document.getElementById("review-widget");
      entry.parentNode.insertBefore(script, entry);
    })();
    </script>

  </div>
  <!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyAppointmentButtonV2({ companyId, abbreviatedName, companyName, appointmentLink }) {
  const codeToCopy = `<!--Start Customer Lobby-->
  <a href="${appointmentLink}"
  onclick="window.open('${appointmentLink}', 'AppointmentPage',
  'statusbar=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,width=768,
  height=817,left=570,top=200,screenX=570,screenY=200'); return false;"
  target="_blank" style='text-decoration:none;font-family: "proxima-nova", sans-serif !important;-webkit-font-smoothing: antialiased;font-weight: 500;'>
    <img src='https://customerlobby-widget-images.s3.amazonaws.com/v2/appointment_widget.png' style="width:200px;" />

  </a>
  <!--End Customer Lobby-->`
  copyTextToClipboard(codeToCopy)
}

function copyWordpressButton({ companyId, abbreviatedName, companyName }) {
  const codeToCopy = `${process.env.REACT_APP_API_SECURE_URL}/v1/companies/${companyId}/reviews.rss?featured=true`
  copyTextToClipboard(codeToCopy)
}

export {
  copyTextToClipboard,
  copyReviewWidgetToClipboard,
  copyStaticButtonOne,
  copyAppointmentButtonV2,
  copyStaticButtonTwo,
  copyStaticButtonThree,
  copyStaticButtonFour,
  copyDynamicButtonOne,
  copyDynamicButtonTwo,
  copyAppointmentAndReviewButton,
  copyAppointmentButton,
  copyWordpressButton,
  copyAppointmentAndDynamicButtonV2,
}
