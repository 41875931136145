import { gql } from "apollo-boost";

const QUERY_COMPANY_PLACES = gql`
  query($companyId: ID!, $status: String) {
    companyPlaces(companyId: $companyId, status: $status) {
      id
      companyId
      name
      address1
      address2
      city
      state
      zipcode
      phoneNumber
      source
      sourceId
      matchScore
      status
    }
  }
`;

const QUERY_SYNDICATION_SETTINGS = gql`
  query companyInfo($companyId: ID!) {
    getCompanyInformation(companyId: $companyId) {
      setting {
        id
        syndicateReviews
      }
    }
    company(companyId: $companyId) {
      id
      name: companyName
      address1
      address2
      state
      city
      zipcode: zip
    }
  }
`;

const MUTATION_UPDATE_PLACE = gql`
  mutation updateCompanyPlace($id: ID!, $companyId: ID!, $status: String) {
    updateCompanyPlace(id: $id, companyId: $companyId, status: $status) {
      id
      companyId
      name
      address1
      address2
      city
      state
      zipcode
      phoneNumber
      source
      sourceId
      matchScore
      status
    }
  }
`;

const MUTATION_UPDATE_SETTINGS = gql`
  mutation companySettings($companyId: ID!, $enabled: Boolean!) {
    updateCompanySettings(companyId: $companyId, syndicateReviews: $enabled) {
      syndicateReviews
    }
  }
`;

export {
  QUERY_COMPANY_PLACES,
  QUERY_SYNDICATION_SETTINGS,
  MUTATION_UPDATE_PLACE,
  MUTATION_UPDATE_SETTINGS
};
