import React from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import ReadMoreNotes from "src/shared/styleguide/common/ReadMoreNotes";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";

export const PopoverStyleguide = () => {
  useDocumentTitle("Style Guide - Popover");

  return (
    <React.Fragment>
      <React.Fragment>
        <h3 className="text-dark font-weight-bold">Pop Overs</h3>
        <StyleGuideSection
          title="Popup Info"
          description="By default, popup infos display 'i' icons, but can accept any custom element. Messages can also be provided as HTML elements."
        >
          <CodePreview
            elements={
              <React.Fragment>
                <PopupInfo message="By default popup info uses this handy icon" />
              </React.Fragment>
            }
            code={`import PopupInfo from "src/shared/styleguide/common/PopupInfo";

<PopupInfo
  message="By default popup info uses this handy icon"
/>
          `}
          />
        </StyleGuideSection>
      </React.Fragment>

      <React.Fragment>
        <StyleGuideSection
          title="Read More Notes"
          description="By default, Read More Notes uses a note icon, but it will accept any element.  By default, messages are cropped at 160 characters.  Technically, the component can accept strings and nodes, but be aware that deeply nested nodes may break the experience.  It is best to keep your DOM elements as shallow as possible. * pro tip - unsorted list items (<li>) do not need to be nested in a <ul> element."
        >
          <CodePreview
            elements={
              <React.Fragment>
                <ReadMoreNotes
                  placement="right"
                  content="This accepts text, but not formating - strings only! I could say more, but not much more without triggering a 'Read More' prompt. Wait for it. Wait for it. Wait for it. Triggered!"
                  title="My Excellent Note"
                  trigger="click"
                />
              </React.Fragment>
            }
            code={`import ReadMoreNotes from "src/shared/styleguide/common/ReadMoreNotes"

<ReadMoreNotes
  placement="right"
  content="This accepts text, but not formating - strings only! I could say more, but not much more without triggering a 'Read More' prompt. Wait for it. Wait for it. Wait for it. Triggered!"
  title="My Excellent Note"
  trigger="click"
/>
          `}
          />
          <CodePreview
            elements={
              <React.Fragment>
                <ReadMoreNotes
                  content={
                    <div>
                      <h5>For this feature:</h5>
                      <li>Create new component</li>
                      <li>Update documentation</li>
                      <li>Create pull request</li>
                      <li>
                        Request review by a <b>co-worker</b>
                      </li>
                      <li>Merge code</li>
                    </div>
                  }
                  icon="Open Note"
                  placement="bottom-start"
                  rootClose={true}
                  threshold={30}
                  title="My To Do List"
                  trigger="click"
                />
              </React.Fragment>
            }
            code={`import ReadMoreNotes from "src/shared/styleguide/common/ReadMoreNotes"

<ReadMoreNotes
  content={
    <div>
      <h5>For this feature:</h5>
      <li>Create new component</li>
      <li>Update documentation</li>
      <li>Create pull request</li>
      <li>
        Request review by <a href="#">co-worker</a>
      </li>
      <li>Merge code</li>
    </div>
  }
  icon="Open Note"
  placement="bottom-start"
  rootClose={true}
  threshold={30}
  title="My To Do List"
  trigger="click"
/>
          `}
          />
        </StyleGuideSection>
      </React.Fragment>
    </React.Fragment>
  );
};

export default PopoverStyleguide;
