import React from "react";
import rightArrow from "src/styles/assets/review-settings-icon/right-arrow.svg";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export const PageHeader = ({
  showArrow = false,
  onArrowClick = () => {},
  leftComponent,
  rightComponent,
  className,
  leftColumnWidth = { md: 6 },
  rightColumnWidth = { md: 6 },
}) => {
  return (
    <Row className={`page-header ${className}`}>
      <Col {...leftColumnWidth}>
        <h1 className="text-dark">
          {showArrow === true ? (
            <img
              onClick={onArrowClick}
              src={rightArrow}
              className="mr-3 cursor-pointer"
              alt="Arrow right"
            />
          ) : null}
          {leftComponent}
        </h1>
      </Col>
      <Col {...rightColumnWidth} className="text-right">
        {rightComponent}
      </Col>
    </Row>
  );
};

PageHeader.propTypes = {
  showArrow: PropTypes.bool,
  onArrowClick: PropTypes.func,
  leftComponent: PropTypes.node,
  rightComponent: PropTypes.node,
  className: PropTypes.string,
  leftColumnWidth: PropTypes.object,
  rightColumnWidth: PropTypes.object,
};

export default PageHeader;
