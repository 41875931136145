import React from "react";
import EmailSignatureLink from "./EmailSignatureLink";
import AutoInvitationSettings from "./AutoInvitationSettings";
import SmartCallQueuingSettings from "./SmartCallQueuingSettings";
import CustomEmailTemplateSettings from "./CustomEmailTemplateSettings";
import SmartInviteSettings from "./SmartInviteSettings";

const InvitesSettings = () => {
  // render
  return (
    <React.Fragment>
      <div className="mb-4">
        <SmartInviteSettings />
      </div>
      <div className="mb-4">
        <CustomEmailTemplateSettings />
      </div>
      <div className="mb-4">
        <EmailSignatureLink />
      </div>
      <div className="mb-4">
        <AutoInvitationSettings />
      </div>
      <div className="mb-4">
        <SmartCallQueuingSettings />
      </div>
    </React.Fragment>
  );
};

export default InvitesSettings;
