import { gql } from "apollo-boost";

export const GET_PRIVATE_FEEDBACK_DISPOSITIONS = gql`
  query privateFeedbackDispositions(
    $companyId: ID!
    $dateFrom: String!
    $dateBucket: String!
    $searchTerm: String
  ) {
    privateFeedbackDispositions(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      searchTerm: $searchTerm
    ) {
      id
      name
      count
    }
  }
`;

export const LIST_PRIVATE_FEEDBACKS = gql`
  query listPrivateFeedbacks(
    $companyId: ID!
    $dateBucket: String!
    $dateFrom: String!
    $disposition: String!
    $sortBy: String!
    $page: Int!
    $rpp: Int!
    $searchTerm: String
  ) {
    listPrivateFeedbacks(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      disposition: $disposition
      sortBy: $sortBy
      page: $page
      rpp: $rpp
      searchTerm: $searchTerm
    ) {
      id
      contact {
        contactDisplayName
        email
        contactId
        formattedPhoneDaytime
        formattedPhoneAlt
        formattedPhoneMobile
      }
      createdAt
      suggestionQuestion {
        suggestionTopic
      }
      suggestionAnswer
      reviewId
    }
  }
`;

export const GET_PRIVATE_FEEDBACK_DETAILS = gql`
  query privateFeedbackDetails($id: ID!, $companyId: ID!) {
    privateFeedbackDetails(companyId: $companyId, id: $id) {
      records {
        suggestionQuestion {
          suggestionTopic
        }
        suggestionAnswer
        reviewId
      }
      contact {
        email
        contactDisplayName
        phoneDaytime
      }
      createdAt
    }
  }
`;

export const PRIVATE_FEEDBACK_DATE_RANGE = [
  { label: "Last 7 days", value: "7days" },
  { label: "Last 30 days", value: "30days" },
  { label: "Last 60 days", value: "60days" },
  { label: "Last 90 days", value: "90days" },
  { label: "Last 365 days", value: "365days" },
];

export const PRIVATE_FEEDBACK_SORT_BY = [
  { label: "Newest First", value: "newest" },
  { label: "Oldest First", value: "oldest" },
];
