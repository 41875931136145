import React from "react";
import PropTypes from "prop-types";
import "./ResultsNotFound.scss";

const ResultNotFound = ({
  marginTop = 80,
  marginBottom = 0,
  iconSize = 220,
  svgIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="98.637"
      viewBox="0 0 140 118.637"
    >
      <defs>
        <style>
          {`.cls-1{fill:#dee3e6}.cls-2{fill:#fff}.cls-3{fill:#c3c9ce}`}
        </style>
      </defs>
      <path
        d="M175.648 82.2v74.213a11.528 11.528 0 0 1-11.528 11.528H51.172V82.2A11.528 11.528 0 0 1 62.7 70.672h101.42A11.528 11.528 0 0 1 175.648 82.2zm-54.119 58.316a20.088 20.088 0 0 0 12.22-18.23c0-11.461-10.042-20.678-22.128-19.968-10.834.638-19.476 9.469-19.513 19.9a19.775 19.775 0 0 0 12.22 18.224v12a4.192 4.192 0 0 0 4.193 4.2h8.816a4.192 4.192 0 0 0 4.193-4.2zm0 0"
        className="cls-1"
        transform="translate(-35.648 -49.304)"
      />
      <path
        id="Path_2315"
        d="M227.75 194.868a20.088 20.088 0 0 1-12.22 18.23v4.062h-17.2v-4.138a19.775 19.775 0 0 1-12.22-18.224c.037-10.43 8.679-19.261 19.513-19.9 12.085-.708 22.127 8.51 22.127 19.97zm0 0"
        className="cls-2"
        data-name="Path 2315"
        transform="translate(-129.649 -121.886)"
      />
      <path
        d="M243.592 314.289v7.864a4.192 4.192 0 0 1-4.193 4.2h-8.816a4.192 4.192 0 0 1-4.193-4.2v-7.864zm0 0"
        className="cls-2"
        transform="translate(-157.71 -219.015)"
      />
      <path
        d="M51.752 4.48l13.973 17.126H27.052a11.528 11.528 0 0 0-11.528 11.528v85.742h-4A11.528 11.528 0 0 1 0 107.347V11.766A11.528 11.528 0 0 1 11.528.238H42.82a11.531 11.531 0 0 1 8.932 4.242zm0 0"
        className="cls-3"
        transform="translate(0 -.238)"
      />
      <g transform="translate(35.092 31.327)">
        <path
          d="M251.592 116.154v-10.227a2.427 2.427 0 1 0-4.854 0v10.227a2.427 2.427 0 0 0 4.854 0zm0 0"
          className="cls-2"
          transform="translate(-206.977 -103.5)"
        />
        <path
          d="M118.1 229.77a2.427 2.427 0 1 0 0 4.854h10.642a2.427 2.427 0 0 0 0-4.854zm0 0"
          className="cls-2"
          transform="translate(-115.672 -191.463)"
        />
        <path
          d="M355.8 229.77h-10.643a2.427 2.427 0 0 0 0 4.854H355.8a2.427 2.427 0 0 0 0-4.854zm0 0"
          className="cls-2"
          transform="translate(-273.847 -191.463)"
        />
        <path
          d="M164.124 152.5a2.427 2.427 0 0 0 1.672-4.187l-7.578-7.2a2.427 2.427 0 0 0-3.343 3.52l7.578 7.2a2.416 2.416 0 0 0 1.671.667zm0 0"
          className="cls-2"
          transform="translate(-142.456 -129.24)"
        />
        <path
          d="M316.808 152.5a2.416 2.416 0 0 0 1.671-.667l7.578-7.2a2.427 2.427 0 1 0-3.343-3.52l-7.578 7.2a2.427 2.427 0 0 0 1.672 4.187zm0 0"
          className="cls-2"
          transform="translate(-254.098 -129.239)"
        />
      </g>
    </svg>
  ),
  primaryMsg,
  secondaryMsg,
  iconClassName = "",
  primaryMsgClassName = "",
  secondaryMsgClassName = "",
}) => {
  // render
  return (
    <div
      style={{
        marginTop,
        marginBottom,
      }}
      className="results-not-found-container"
    >
      {/* icon */}
      <div
        style={{
          width: iconSize,
          height: iconSize,
        }}
        className={`mx-auto icon-style ${iconClassName}`}
      >
        {svgIcon}
      </div>

      {/* primary msg */}
      <div
        className={`text-center text-dark primary-message ${primaryMsgClassName}`}
        >
        {primaryMsg}
      </div>

      {/* secondary msg */}
      <div className={`text-center secondary-message ${secondaryMsgClassName}`}>
        {secondaryMsg}
      </div>
    </div>
  );
};

ResultNotFound.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  iconSize: PropTypes.number,
  svgIcon: PropTypes.node,
  primaryMsg: PropTypes.node,
  secondaryMsg: PropTypes.node,
  iconClassName: PropTypes.string,
  primaryMsgClassName: PropTypes.string,
  secondaryMsgClassName: PropTypes.string,
};

export default ResultNotFound;
