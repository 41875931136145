export const CREATE_REVIEW_CHALLENGE = `
  mutation createReviewChallenge(
    $reviewId: ID!
    $reason: String!
    $communicationBody: String!
    $userId: ID
    $userType: String
    ) {
    createReviewChallenge(
      reviewId: $reviewId
      reason: $reason
      communicationBody: $communicationBody
      userId: $userId
      userType: $userType
      ) {
      id
    reviewId
    status
    read
    reason
    challengeCommunications {
        communicationBody
        userId
        userType
        accessedAt
        createdAt
        updatedAt
      }
    }
  }
`

export const CREATE_REVIEW_COMMENT = `
  mutation createReviewComment(
    $reviewId: ID!
    $comment: String!
    $emailReviewer: Boolean!
    $userId: ID!
    $userType: String!
    ) {
    createReviewComment(
      reviewId: $reviewId
      comment: $comment
      emailReviewer: $emailReviewer
      userId: $userId
      userType: $userType
      ) {
      id
      reviewId
      comment
      userId
      userType
    }
  }
`

export const CREATE_REVIEW_WITHDRAWAL_REQUEST = `
  mutation company(
    $reviewId: ID!
    $note: String!
    ) {
    createReviewWithdrawalRequest(
      reviewId: $reviewId
      note: $note
      ) {
      id
      reviewId
      status
      note
      formattedRequestDate
      formattedWithdrawDate
    }
  }
`

export const UPDATE_REVIEW = `
  mutation updateReview(
    $reviewId: ID!
    $isFeatured: Boolean
    $reviewStatus: String
    ) {
    updateReview(
      reviewId: $reviewId
      isFeatured: $isFeatured
      reviewStatus: $reviewStatus
      ) {
      reviewId
      isFeatured
      reviewStatus
    }
  }
`
