import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import BlockUi from "react-block-ui";
import { showUserId, showCompanyId } from "src/shared/SessionHelper";
import { addToast } from "src/utils/ToastUtil";
import { MUTATION_ACCEPT_TERMS } from "src/graphql/users/mutations.js";
import "./AcceptTerms.scss";
import { setCookie } from "../SessionHelper";
import { COOKIES } from "../Config";

const AcceptTerms = () => {
  //   variables
  const history = useHistory();
  const [acceptTerms] = useMutation(MUTATION_ACCEPT_TERMS);

  //   state
  const [loading, setLoading] = React.useState(false);

  //   Handler to accept customer terms
  const acceptCustomerTerms = () => {
    setLoading(true);
    acceptTerms({
      variables: {
        companyUserId: showUserId,
        acceptTerms: true,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res) {
          addToast({
            type: "success",
            message: "Terms have been accepted successfully.",
          });
          setCookie(
            COOKIES.ACCEPT_TERMS,
            true,
            process.env.REACT_APP_APP_DOMAIN,
          );
          setTimeout(
            () =>
              (window.location.href = `/companies/${showCompanyId}/dashboard`),
            500,
          );
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          message:
            err && err.graphQLErrors && err.graphQLErrors[0]
              ? err.graphQLErrors[0].message
              : "Failed.",
        });
      });
  };
  return (
    <BlockUi loader={loading}>
      <div className="accept-terms-container">
        <div className="title">Customer Terms of Use</div>
        <div className="last-updated-info">Last Updated: August 5, 2008</div>
        <div className="terms-information">
          <div className="my-3">
            The following are the terms and conditions for use of the Customer
            Lobby, LLC ("Customer Lobby") Internet-based products, desktop
            software and services ("Services") by registered customers of
            Customer Lobby. Please read these Customer Terms of Use ("Terms")
            carefully before completing your registration, accessing the
            Customer Lobby Web site or using the Services (the Services together
            with the Web site, the "Site"). By checking the box that you have
            read and agreed to these Customer Terms of Use ("Accepting the
            Terms") as required during the registration process, you are
            representing that you have read, understand and are consenting to be
            bound by these Terms in their entirety, and your act of Accepting
            the Terms will constitute your legally binding signature. Access to
            and use of the customer-only features of the Site, is contingent
            upon your agreement to and continued compliance with these Terms. If
            you do not agree to all of the provisions in these Terms, do not
            check the box that you have read and agreed to these Terms, and you
            will not receive access to the customer-only features of the Site as
            a registered user. By Accepting the Terms and becoming a registered
            user you will be fully bound by these Terms.
          </div>
          <div className="my-3">
            After becoming a registered user and so long as your account has not
            been terminated, cancelled or deactivated, you, and any Additional
            Users to whom you give the privilege, will have the ability to add
            additional users to your account ("Additional Users"). By adding an
            Additional User, you hereby agree that such Additional Users shall
            be fully bound by these Terms.
          </div>
          <div className="my-3">
            Your use of the Site as a user (as opposed to a Customer, as defined
            below) is governed by the public-use terms and conditions at the
            following link (the "General Terms"): Terms Of Use. The General
            Terms are incorporated herein by this reference and, by clicking
            Accepting the Terms you are also representing that you have read,
            understand, and are agreeing to become bound by such General Terms
            in your use of the Site.
          </div>
          <div className="my-3">
            The Customer Lobby Site provides an online community for rating and
            reviewing businesses, services, products and locations, as well as a
            variety of other features and services to support and build that
            community. Customer Lobby reserves the right to change or modify any
            of these Terms, the General Terms, its privacy policy, the Site or
            any policy relating to the Site, at any time, by posting the new
            agreement or policy, as applicable, to the Customer Lobby website
            located at CustomerLobby Home (or such other URL as Customer Lobby
            may provide). In the event of any change to these Terms or the
            General Terms, you will be notified by email that a change has
            occurred, and your continuing to use the customer-only features of
            the Customer Lobby Site (including but not limited to the management
            features, receipt of email notifications and maintaining a public
            link to a Customer Lobby review page) thereafter will bind you to
            any such changes.
          </div>
        </div>
        <ol className="pl-3">
          <li className="my-3">
            <div className="terms-heading">Account</div>
            <div className="terms-description my-3">
              A person or entity can become a customer by completing the
              customer account set-up process on the Site or by being named as
              an additional user on the account of a current authorized customer
              (in either instance, a "Customer"). You must complete the set-up
              process by providing Customer Lobby with complete and accurate
              information as prompted by the registration form, including your
              e-mail address and password. You shall protect your password, and
              are solely responsible for any and all use that occurs under your
              account, including any usage by additional authorized users on
              your account or any use of your password or the passwords of your
              authorized users. You agree to notify Customer Lobby immediately
              of any unauthorized use of your account or any other breach of
              security.
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">
              Nondisclosure of customer information
            </div>
            <div className="terms-description my-3">
              <div className="my-3">
                In the normal course of using the Customer Lobby Site, you, your
                customers or our automated systems may input your customers'
                contact information or details of their transactions with your
                business ("Customer Information") into the Site. Unless you ask
                us to, we will not disclose your Customer Information to a third
                party except when (i) your customer includes Customer
                Information in the body or subject line of a review of your
                business or (ii) a review of your business is published in which
                case we will disclose the first name, last initial and location
                of the reviewer. We reserve the right to aggregate Customer
                Information with other businesses' Customer Information to
                provide general analysis tools to our customers.{" "}
              </div>
              <div className="my-3">
                We reserve the right to disclose your Customer Information as
                required by law; or to protect the rights, property, or safety
                of Customer Lobby, our users, or others. In the event that we
                are legally compelled to disclose your Customer Information to a
                third party, we will notify you unless doing so would violate
                the law or a court order.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Content</div>
            <div className="terms-description">
              <div className="my-2">
                All information, words, images, sounds, and other material on
                all pages of the Site ("Content," which shall be deemed to
                include, without limitation, all Customer Content as defined
                below), to the extent proprietary in nature, is the property of
                Customer Lobby. Customer Lobby takes no responsibility and
                assumes no liability for any message, profile, opinion,
                question, answer, posting, data, information, file, text, link,
                music, sound, image, video, photo, graphic, code, work of
                authorship or other material posted, stored, or uploaded or
                downloaded by a Customer or any third party to your review
                pages, including without limitation content provided by you,
                your customers and reviewers (including, but not limited to, any
                reviews transcribed and summarized by Customer Lobby from phone
                calls with your customers), and your Ask the Reviewer
                communications by questioners and your customers and reviewers
                (collectively, "Customer Content"), or for any loss or damage to
                or resulting from Customer Content. Customer Content is
                expressly limited to any Content derived from your account or
                posted on your account's review page, including but not limited
                to your published reviews, non-published reviews, Ask the
                Reviewer communications and suggestion box communications. AS A
                PROVIDER OF INTERACTIVE SERVICES, CUSTOMER LOBBY IS NOT LIABLE
                FOR ANY MISTAKES, DEFAMATION, SLANDER, LIBEL, OMISSIONS,
                FALSEHOODS, OBSCENITY, PORNOGRAPHY OR PROFANITY IN ANY CONTENT,
                NOR WILL CUSTOMER LOBBY BE LIABLE FOR ANY STATEMENTS,
                REPRESENTATIONS OR ANY OTHER INFORMATION OR CONTENT PROVIDED BY
                ITS USERS IN ANY PUBLIC FORUM, PERSONAL HOME PAGE OR OTHER
                INTERACTIVE AREA, WHETHER OR NOT PART OF THE SITE. Although
                Customer Lobby has no obligation to screen, edit or monitor any
                of the Content posted to or distributed through the Site,
                Customer Lobby reserves the right, and has absolute discretion,
                to remove, screen or edit, with or without notice, any Content
                posted or stored on the Site, at any time and for any reason.
                You agree that an authorized user of your customer account will
                review the Customer Content relating to your account and will
                promptly notify Customer Lobby if any such Customer Content
                contains inaccurate information or otherwise does not meet the
                definition of a Valid Review. A "Valid Review" is a review that
                does not include any of the following: (a) Obscenities,
                discriminatory language, or other inappropriate comments
                (including any comments written in a language other than
                English) not suitable for this forum; (b) Advertisements, "spam"
                content, or references to other companies, reviewers, services,
                products, offers, or websites; (c) Email addresses, URL's, phone
                numbers, physical addresses, or other forms of contact
                information; (d) Reviews that: are not written by a customer of
                the company being reviewed; include pricing information; have
                been withdrawn by the reviewer; or that Customer Lobby believes,
                in its sole judgment, to be untrue; (e) Critical or spiteful
                comments about other reviews or their authors; (f) Computer
                viruses, scripts, tags, worms or other potentially damaging
                computer programs or files; or (g) Comments that may reasonably
                be considered to be defamatory, libelous, hateful, racially or
                religiously biased or offensive, unlawfully threatening or
                unlawfully harassing to any individual, partnership or
                corporation.
              </div>

              <div className="my-2">
                On our Customers' behalf, Customer Lobby has secured a
                nonexclusive, royalty-free, perpetual, transferable, irrevocable
                and fully sublicensable right to use, reproduce, modify, adapt,
                translate, distribute, publish, delete, edit, create derivative
                works of and publicly display the Content throughout the world
                in any media, now known or hereafter devised. In addition,
                Customers have the right to display the first name associated
                with the account of the user that submitted such Content.
              </div>

              <div className="my-2">
                By posting or distributing Customer Content or other Content to
                or through the Site, you (a) grant Customer Lobby, its
                affiliates and the entity about which the Content is written a
                nonexclusive, royalty-free, perpetual, transferable, irrevocable
                and fully sublicensable right to use, reproduce, modify, adapt,
                translate, distribute, publish, delete, edit, create derivative
                works of and publicly display such Content throughout the world
                in any media, now known or hereafter devised; (b) grant Customer
                Lobby, its affiliates and sublicensees the right to display the
                name, title and company name associated with the account of the
                user that submitted such Content if such user has agreed to
                display such information publicly and, in instances when such
                user has not agreed to display such information publicly, grant
                Customer Lobby, its affiliates and sublicensees the right to
                display the first name associated with the account of the user
                that submitted such Content; (c) grant Customer Lobby, its
                affiliates and sublicensees the right to reject or refuse to
                post Content; (d) grant Customer Lobby, its affiliates and
                sublicensees the right to contact you or any Content authors via
                email or telephone; and (e) represent and warrant to Customer
                Lobby that (i) you own and control all of the rights to the
                Content that you post or otherwise distribute, or you otherwise
                have the lawful right to post and distribute such Content to or
                through the Site; (ii) such Content is accurate and not
                misleading; and (iii) use and posting or other transmission of
                such Content does not violate these Terms and will not violate
                any rights of or cause injury to any person or entity. You
                further grant Customer Lobby the right to pursue at law any
                person or entity that violates your or Customer Lobby's rights
                in the Content by a breach of these Terms, which right is
                without the implication of any obligation to do so.
              </div>
              <div className="my-2">
                Content submitted by any user will be considered public and
                non-confidential and Customer Lobby may freely disclose or use
                such Content without consent or notice. Customer Lobby shall
                have no obligation to offer or provide you any payment or other
                consideration for Content that you or any user submits. Customer
                Lobby shall have no duty to attribute authorship of Content. You
                specifically agree that Customer Lobby shall not be responsible
                for unauthorized access to or alteration of any Content,
                including without limitation the Customer Content.
              </div>
              <div className="my-2">
                Customer Lobby may provide Content to third-party web sites to
                be published on such sites. Customer Lobby makes no
                representations or assurances that any Content will be published
                on any third-party web site; that any Content that is published
                on any third-party web site will be accurately reproduced; that
                any Content that is published on any third-party web site will
                not be reproduced onto additional web sites that have not been
                approved by Customer Lobby; or that, if any Content is published
                on a third-party web site, that such Content will remain on such
                site. Customer Lobby may, at its sole discretion, seek to remove
                any Content from any third-party web site at anytime including,
                but not limited to, upon the termination of your account.
              </div>
              <div className="my-2">
                If it is determined that you retain moral rights (including
                rights of attribution or integrity) in the Content, you hereby
                declare that (a) you do not require that any personally
                identifying information be used in connection with the Content,
                or any derivative works of or upgrades or updates thereto; (b)
                you have no objection to the publication, use, modification,
                deletion and exploitation of the Content by Customer Lobby or
                its licensees, successors and assigns; (c) you forever waive and
                agree not to claim or assert any entitlement to any and all
                moral rights of an author in any of the Content; and (d) you
                forever release Customer Lobby, and its licensees, successors
                and assigns, from any claims that you could otherwise assert
                against Customer Lobby by virtue of any such moral rights.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Proprietary rights</div>
            <div className="terms-description">
              <div className="my-2">
                Except for the limited license granted below, Customer Lobby
                expressly reserves all right, title and interest in and to the
                Site, the Content (including without limitation the Customer
                Content), the Customer Lobby Technology (as defined below) and
                all other Customer Lobby IP (as defined below) and no rights
                therein are transferred to you by your access to the Site or the
                Customer Lobby IP. "Customer Lobby Technology" means all
                technology, inventions, know-how and related intellectual
                property owned or developed by Customer Lobby, including without
                limitation Customer Lobby-related software, code, script or API
                and all processing, algorithms and other software and technology
                used by Customer Lobby in the provision of the Site, together
                with any fixes, updates and upgrades of the foregoing. "Customer
                Lobby IP" means all intellectual property of Customer Lobby,
                including withour limitation the Site, the Content, the Customer
                Lobby Technology, all trade secret or confidential information
                of Customer Lobby, including without limitation all Customer
                Information (as defined below), all trademarks, trade names,
                service marks, logos, trade dress and similar intellectual
                property rights of Customer Lobby, the Customer Lobby-provided
                logo and link, and any copyright, patent, trademark, other
                intellectual property right or federal or state right pertaining
                thereto, together with all derivatives, improvements,
                enhancements or extensions of any of the foregoing intellectual
                property conceived, reduced to practice or otherwise developed
                on or on behalf of Customer Lobby. Customer Lobby IP constitutes
                valuable assets of Customer Lobby. Unless explicitly stated
                herein, nothing in these Terms may be construed as conferring
                any license to Customer Lobby IP, whether by estoppel,
                implication or otherwise.
              </div>
              <div className="my-2">
                Customer Lobby and the Customer Lobby logo, and all other
                product or service names or slogans displayed on the Site in
                connection with the Services, are registered and/or common law
                trademarks, service marks or trade names of Customer Lobby
                and/or its suppliers or licensors, and, except as expressly
                provided herein, may not be copied, imitated or used, in whole
                or in part, without the prior written permission of Customer
                Lobby or the applicable trademark holder. In addition, the look
                and feel of the Site constitute the service marks, trademarks
                and/or trade dress of Customer Lobby and, except as expressly
                provided herein, may not be copied, imitated or used, in whole
                or in part, without the prior written permission of Customer
                Lobby. All other trademarks, or service marks or trade names or
                trade dress, product names and company names or logos, whether
                or not registered, mentioned on the Site are the property of
                their respective owners. Reference to any products, services,
                processes or other information, by trade name, trademark,
                manufacturer, supplier or otherwise, does not constitute or
                imply endorsement, sponsorship or recommendation thereof by
                Customer Lobby.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Licenses</div>
            <div className="terms-description">
              <div className="my-2">
                Subject to these Terms, Customer Lobby hereby grants to you a
                limited, revocable, non-exclusive, non-transferable license
                ("License") to access and use the Site and, to the extent
                necessary to your use of the Site, the Customer Lobby IP, in
                each case solely in accordance with these Terms and the
                applicable policies of Customer Lobby made available on the
                Customer Lobby Site.
              </div>
              <div className="my-2">
                During the term of the License, Customer Lobby hereby grants to
                you a limited, revocable, non-exclusive, non-transferable
                license to include a link to the Site and an image from the Site
                of the Customer Lobby logo on your web site solely for the
                purpose of accessing the information available from the Site.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">
              Confidentiality; restrictions on use
            </div>
            <div className="terms-description">
              <div className="my-2">
                The Customer Lobby IP is to be used only to facilitate the
                exchange of opinions and information about the businesses,
                services, products and locations reviewed on the Site, and each
                person or entity supplying information to or using information
                from the Site shall only use the Customer Lobby IP for such
                purpose. You shall not: (a) use, or allow the use of, any
                Customer Lobby IP, except pursuant to the limited rights
                expressly granted in these Terms; (b) use the Customer Lobby IP
                other than for its intended purpose or, with respect to the
                Customer Lobby Technology that is a part thereof, in any manner
                that is inconsistent with user documentation, if any, made
                available to you by Customer Lobby; (c) attempt to decompile,
                reverse engineer, hack into or compromise any aspect of the
                Customer Lobby IP (including without limitation the Customer
                Lobby Technology), or attempt to access personal data of any
                other registered users; (d) use any data mining, robots or
                similar data gathering or extraction methods to collect
                information or data from the Customer Lobby IP; (e) remove,
                obscure or alter any legal notices, including without limitation
                notices of intellectual property rights appearing in or on the
                Customer Lobby IP or any materials delivered to you by Customer
                Lobby; (f) use, post, transmit or introduce any device, software
                or routine which interferes or attempts to interfere with the
                operation of the Customer Lobby IP; (g) reproduce, distribute,
                display, modify or make derivative uses of the Customer Lobby IP
                or the Site Content, or any portion thereof (except for any
                Content for which you have been given written permission),
                except as expressly provided herein; or (h) engage in any
                prohibited conduct, as detailed below.
              </div>
              <div className="my-2">
                Without limiting the generality of the foregoing, you
                specifically agree to the following restrictions on disclosure
                and use of Customer Information (as hereinafter defined): You
                agree not to use the Customer Information for any purpose
                whatsoever other than evaluating the businesses reviewed as
                potential service providers. You agree not to disclose the
                Customer Information to anyone except as necessary for the
                foregoing use. Without limiting the foregoing in any manner, you
                specifically agree not to use any Customer Information to
                solicit any customer of Customer Lobby in connection with any
                business whatsoever. "Customer Information" as used herein means
                all information posted or accessible on the Site relating to or
                concerning Customer Lobby customers.
              </div>
              <div className="my-2">
                Recognizing the global nature of the Internet, you agree to
                comply with all local rules regarding online conduct and
                acceptable content. You will not use the Customer Lobby IP in
                any way or for any purpose that would violate, or would have the
                effect of violating, any applicable laws, rules or regulations
                or any rights of any third parties, including without limitation
                any law or right regarding the transmission of technical data
                exported from the United States or the country in which you
                reside, any laws affecting the transmission or posting of
                content or affecting the privacy of persons, or any law or right
                regarding any copyright, patent, trademark, trade secret, music,
                image or other proprietary or property right, false advertising,
                unfair competition, defamation, invasion of privacy or rights of
                celebrity.
              </div>
              <div className="my-2">
                Except as expressly permitted herein, any use of any portion of
                the Customer Lobby IP without the prior written permission of
                Customer Lobby is strictly prohibited and will terminate the
                License. Any such unauthorized use may also violate applicable
                laws, including without limitation copyright, trade secret and
                trademark laws and applicable communications regulations and
                statutes.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Prohibited conduct</div>
            <div className="terms-description">
              <div className="my-2">
                You agree that you are responsible for your own conduct and
                Content while using the Customer Lobby IP and for any
                consequences thereof. You agree to use the Customer Lobby IP
                only for purposes that are legal, proper and in accordance with
                these Terms and any applicable policies or guidelines. By way of
                example, and not as a limitation, you agree not to post, email,
                or otherwise make available Content: (a) that is unlawful,
                harmful, threatening, abusive, harassing, defamatory, libelous,
                invasive of another's privacy, or is harmful to minors in any
                way; (b) that is pornographic or depicts a human being engaged
                in sexual conduct; (c) that harasses, degrades, intimidates or
                is hateful toward an individual or group of individuals on the
                basis of religion, gender, sexual orientation, race, ethnicity,
                age or disability; (d) that impersonates any person or entity,
                including, but not limited to, a Customer Lobby owner or
                employee, or falsely states or otherwise misrepresents your
                affiliation with a person or entity; (e) that includes personal
                or identifying information, beyond a first name, about another
                person without that person's explicit consent; (f) that is
                false, deceptive, misleading, deceitful, misinformative, or
                constitutes "bait and switch"; (g) that infringes any patent,
                trademark, trade secret, copyright or other proprietary rights
                of any party, or Content that you do not have a right to make
                available under any law or under contractual or fiduciary
                relationships; (h) that constitutes or contains "affiliate
                marketing," "link referral code," "junk mail," "spam," "chain
                letters," "pyramid schemes," or unsolicited commercial
                advertisement or any similar matter; (i)that constitutes or
                contains any form of advertising or solicitation if: posted in
                areas of the Customer Lobby Site which are not designated for
                such purposes (j) that communicates directly regarding services,
                products or commercial interests (via email or phone) to a
                Customer Lobby user who has not previously given you their email
                address and indicated in writing that it is all right to contact
                them about the same; (k) that advertises any illegal service or
                the sale of any items the sale of which is prohibited or
                restricted by any applicable law, including without limitation
                items services the sale of which is prohibited; (l) that
                contains software viruses or any other computer code, files or
                programs; (m) that disrupts the normal flow of dialogue with an
                excessive number of messages (flooding attack) to the Site, or
                that otherwise negatively affects other users' ability to use
                the Site; or (n) that employs misleading email addresses, or
                forged headers or otherwise manipulated identifiers in order to
                disguise the origin of Content transmitted through the Site.
                Additionally, you agree not to: (i) contact anyone who has asked
                not to be contacted; (ii) "stalk" or otherwise harass anyone;
                (iii) attempt to gain unauthorized access to Customer Lobby's
                computer systems or engage in any activity that disrupts,
                diminishes the quality of, interferes with the performance of,
                or impairs the functionality of, the Site; or (iv) use any form
                of automated device or computer program that enables the
                submission of postings on or through the Site without each
                posting being manually entered by the author thereof (an
                "automated posting device"), including without limitation the
                use of any such automated posting device to submit postings in
                bulk, or for automatic submission of postings at regular
                intervals.
              </div>
              <div className="my-2">
                The above list is only representative of the conduct not
                permitted under these Terms and is not exhaustive. Customer
                Lobby does not screen the behavior of users and is not liable
                for their actions or violations of these Terms. If you suspect a
                user is in violation of these Terms, please contact Customer
                Lobby at info@customerlobby.com. Any conduct deemed
                inappropriate by Customer Lobby may result in suspension or
                termination of your Customer Lobby account and License.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Third parties</div>
            <div className="terms-description">
              <div className="my-2">
                The Site may contain links to third-party Web sites
                ("Third-Party Sites") and third-party content ("Third-Party
                Content"). You use links to Third-Party Sites, and any
                Third-Party Content therein, at your own risk. Customer Lobby
                does not monitor or have any control over, and makes no claim or
                representation regarding, Third-Party Content or Third-Party
                Sites. Customer Lobby provides these links only as a
                convenience, and a link to a Third-Party Site or Third-Party
                Content does not imply Customer Lobby's endorsement, adoption or
                sponsorship of, or affiliation with, such Third-Party Site or
                Third-Party Content. Customer Lobby accepts no responsibility
                for reviewing changes or updates to, or the quality, content,
                policies, nature or reliability of, Third-Party Content,
                Third-Party Sites or Web sites linking to the Site. When you
                leave the Site, our terms and policies no longer govern. You
                should review applicable terms and policies, including without
                limitation privacy and data-gathering practices, of any
                Third-Party Site, and should make whatever investigation you
                feel necessary or appropriate before proceeding with any
                transaction with any third party.
              </div>
              <div className="my-2">
                Your interactions with other users of the Site or with
                advertisers, including without limitation payment and delivery
                of goods or services, or other terms, conditions, warranties or
                representations associated with such dealings, are solely
                between you and the other user or the advertiser. You agree that
                Customer Lobby will not be responsible for any loss or damage
                incurred as the result of any such dealings or with respect to
                any third party's use or disclosure of personally identifiable
                information supplied to such third party by you. If there is a
                dispute between you and any third party (including without
                limitation another user), Customer Lobby has the right, but not
                the obligation, to participate in or monitor such dispute;
                provided, however, that neither the possession nor the exercise
                of such right shall be deemed to create any duty, liability or
                obligation on Customer Lobby.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Fees</div>
            <div className="terms-description">
              <div className="my-2">
                To continue your license after the expiration of your Free
                Account, an amount equal to the then-current set-up fee (the
                "Set-Up Fee") plus the first month's membership fee (the
                "Membership Fee") (together, the "Fees") is due and payable. The
                current Fees can be found on the Site. Until your account is
                terminated, each month, on the monthly anniversary of your
                set-up date, the then-current Membership Fee is due and payable.
                From time to time, Customer Lobby may offer discounts on certain
                fees, but is not obligated to do so and is not obligated to
                offer all discounts to existing customers.
              </div>
              <div className="my-2">
                Unless you have updated the credit card information on the Site,
                the credit card with which you registered for the Site will be
                charged on the date that any fee becomes due and payable. You
                are responsible for maintaining and updating the credit card
                information on file in your account to ensure that your account
                is not deactivated.
              </div>
              <div className="my-2">
                Customer Lobby reserves the right to change its fee schedule for
                use of the Site and the Customer Lobby IP in the future. As a
                condition of your continued use of and access to the Site
                following notice thereof, you agree to pay Customer Lobby
                pursuant to the then-current fee schedule available on the Site,
                and Customer Lobby reserves the right to deactivate your access
                to the Site for failure to pay any such applicable fee on or
                prior to the due date. Deactivation of your account does not
                serve as account termination, and your financial obligations to
                Customer Lobby in such a case under these Terms would continue
                to accrue. If your account is deactivated, to reactivate your
                account a reactivation fee (a "Reactivation Fee") equal to the
                then-current Set-Up Fee will be charged to your credit card.
              </div>
              <div className="my-2">
                Upon termination or cancellation of your account, no refund of
                any pre-paid Membership Fee will be issued. Any refund of fees
                that is issued by Customer Lobby will be in the form of a check
                or a credit to the credit card on file and will be at Customer
                Lobby's sole discretion.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Free trial account</div>
            <div className="terms-description my-3">
              After completing the free trial registration process (a "Free
              Account"), you may be entitled to use of the Site with out cost to
              you. Customer Lobby reserves the right to terminate any Free
              Account at anytime and with out prior notice. Terminated accounts
              will have no access to data that was part of their account.
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Privacy</div>
            <div className="terms-description">
              <div className="my-3">
                Access to the Site may store a cookie on your computer. In
                connection with the provision of the Site, Customer Lobby may
                aggregate data derived from your use of the Site to compile
                aggregate statistics, metrics and general trend data about the
                Site for marketing and promotional purposes.
              </div>
              <div className="my-3">
                You agree to not use the Site to track or collect personally
                identifiable information of other users, nor will you associate
                any data gathered with any personally identifying information
                from any source as part of your use of the Site.
              </div>
              <div className="my-3">
                Customer Lobby is committed to protecting your privacy and the
                information that Customer Lobby gathers. Customer Lobby's
                complete privacy policy (the "Customer Lobby Privacy Policy"),
                which is incorporated into this Agreement by reference, is
                available at the following link: Privacy Policy. By Accepting
                the Terms, you represent and acknowledge that you have read,
                understand and agree to the terms of the Customer Lobby Privacy
                Policy.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">
              Notification of claims of infringement
            </div>
            <div className="terms-description">
              <div className="my-3">
                If you believe that your work has been copied in a way that
                constitutes copyright infringement, or your intellectual
                property rights have been otherwise violated, please notify
                Customer Lobby's agent for notice of claims of copyright or
                other intellectual property infringement ("Agent") at:
                info@customerlobby.com with "Attn: Copyright Agent" in the
                subject line.
              </div>
              <div className="my-3">
                Please provide our Agent with the following notice ("Notice"):
                (a) a description of the copyrighted work that you claim has
                been infringed; (b) a description of the material on the Site
                that you claim is infringing, with enough detail so that we may
                locate it; (c) a statement by you that you have a good-faith
                belief that the disputed use is not authorized by the copyright
                owner, its agent, or the law; (d) a statement by you declaring
                under penalty of perjury that (i) the above information in your
                Notice is accurate, and (ii) that you are the owner of the
                copyright interest involved or that you are authorized to act on
                behalf of that owner; (e) your address, telephone number, and
                email address; and (f) the physical or electronic signature of
                the person authorized to act on behalf of the owner of the
                copyright interest. Customer Lobby will remove the infringing
                posting(s), subject to the procedures outlined in the Digital
                Millennium Copyright Act ("DMCA") (including without limitation
                the counter-notice and put-back procedures).
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Indemnification</div>
            <div className="terms-description my-3">
              You agree to indemnify, hold harmless and defend Customer Lobby
              and its members, officers, directors, employees, agents and
              affiliates, at your expense, against any and all third-party
              claims, actions, proceedings, and suits brought against Customer
              Lobby or any of its members, officers, directors, employees,
              agents or affiliates, and all related liabilities, damages,
              settlements, penalties, fines, costs or expenses (including
              without limitation reasonable attorneys' fees) incurred by
              Customer Lobby or any of its officers, directors, employees,
              agents or affiliates, arising out of or relating to (a) your
              breach of any term or condition of these Terms, or (b) your use of
              the Site or any use of the site by your authorized user or misuse
              of your or your authorized users' passwords, or (c) any use by
              others of your Customer Content. In such a case, Customer Lobby
              will provide you with written notice of such claim, suit or
              action. You shall cooperate as fully as reasonably required in the
              defense of any claim. Customer Lobby reserves the right, at its
              own expense, to assume the exclusive defense and control of any
              matter subject to indemnification by you, and in such an event
              your obligation to indemnify and hold harmless Customer Lobby (and
              its members, officers, directors, employees, agents and
              affiliates) shall continue in full effect with the sole exception
              of costs of defense.
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Release</div>
            <div className="terms-description my-3">
              You hereby release Customer Lobby, and its members, officers,
              directors, employees, agents and affiliates, from any and all
              claims, demands, losses, damages, rights, and actions of any kind,
              including without limitation financial or non-financial loss,
              damage to your or your company's reputation, personal injuries,
              death, and property damage, that are either directly or indirectly
              related to or arise from (a) any interactions with other users or
              any Customer Lobby IP, (b) any incorrect or inaccurate Customer
              Content or other Content (including without limitation any
              information in profiles) posted on or through the Site, whether
              caused by users or by any of the equipment or programming
              associated with or utilized in the Site, (c) conduct of any user
              of the Site, or (d) removal of any Customer Content or other
              Content provided by you to the Site. You hereby waive California
              Civil Code section 1542, which states: "A general release does not
              extend to claims which the creditor does not know or suspect to
              exist in his or her favor at the time of executing the release,
              which if known by him or her must have materially affected his or
              her settlement with the debtor."
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">
              Representations and warranties; disclaimers
            </div>
            <div className="terms-description">
              <div className="my-3">
                The site and all customer lobby ip are provided "as is" and "as
                available." there are no warranties, claims or representations
                made by customer lobby (or any member, officer, employee or
                agent thereof), either express or implied, with respect to the
                site or any customer lobby ip, including, but not limited to,
                warranties of quality, performance, non-infringement,
                merchantability, title or fitness for a particular purpose, nor
                are there any warranties created by course of dealing, course of
                performance, or trade usage. the information and services
                included in or available through the site or services may
                include inaccuracies or typographical errors. customer lobby (or
                any member, officer, employee or agent thereof) does not warrant
                that (a) the site or other customer lobby ip will meet your
                needs, (b) the site or other customer lobby ip will be
                error-free or accessible at all times, (c) defects will be
                corrected, (d) the site or the server that makes the site
                available are free of viruses or other harmful components, or
                (e) the use or the results of the use of the site or the content
                made available as part of the site will be correct, accurate,
                timely or otherwise reliable. you assume alll responsibility for
                selection and use of content and all other customer lobby ip.
              </div>
              <div className="my-3">
                The foregoing exclusions and disclaimers are an essential part
                of these terms and formed the basis for determining the price
                charged for the services. Some states do not allow exclusion of
                an implied warranty, so this disclaimer may not apply to you.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Limitations of liability</div>
            <div className="terms-description">
              <div className="my-3">
                To the maximum extent allowed by law, under no circumstances,
                including , without limitation, negligence, shall customer lobby
                be liable to you or any third party for any special, punitive,
                incidental, consequential or other indirect damages, whether or
                not foreseeable, including, without limitation, lost revenues,
                lost profits, lost business, or lost data, arising from your use
                of, or any other party's use of, or your or any other party's
                inability to use, the site, the content or any other customer
                lobby ip, whether based on a claim or action of contract,
                warranty, negligence, strict liability or other tort, breach of
                any statutory duty, indemnity or contribution, or otherwise,
                even if customer lobby has been advised of the possibility of
                such damages.
              </div>
              <div className="my-3">
                Customer Lobby's cumulative liability to you or any other party
                for any loss or damage resulting from any claims, demands, or
                actions arising out of or relating to these Terms shall not
                exceed one hundred dollars (US$100). Some states do not allow
                the limitation or exclusion of liability for incidental or
                consequential damages, so the above limitations or exclusions
                may not apply to you.
              </div>
              <div className="my-3">
                The limitations on damages hereunder are part of the terms under
                which customer lobby is willing to allow customers to register
                and be included on the site as contemplated herein. customer
                acknowledges that the services, site and content would not be
                provided under the same terms, including without limitation the
                same low cost, to customer, if customer lobby were responsible
                for customer's damages.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Internet</div>
            <div className="terms-description my-3">
              Customer Lobby reserves the right to make the Site unavailable or
              to take it down without notice as may be required or desired to
              make modifications or for any other reason. In addition, any
              software, information, content, or material of any kind downloaded
              or otherwise obtained through the use of the Site is obtained at
              your own discretion and risk, and you shall be solely responsible
              for any damage to any computer or loss of data that results from
              such download or use of the Content or access to the Site.
              Portions of the Site utilize Internet connectivity to transmit and
              receive Web pages, Content and data. Customer Lobby may utilize
              this information to gather usage statistics. In addition, you are
              warned that the Internet is not a secure medium or environment,
              and thus all use of the Internet, including in without limitation
              connection with the Site and Content, is solely at your own risk.
              Customer Lobby is not responsible for any viruses, spam or other
              actions or results that may occur as a result of your use of the
              Internet, Software, Site or Content.
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">
              Termination; modification of license and site offering
            </div>
            <div className="terms-description">
              <div className="my-3">
                You can terminate your Customer Lobby account at any time by
                calling 866-718-9549 and asking us to terminate your account for
                you. You agree that you have not terminated your account until
                and unless you have received an email from Customer Lobby
                confirming your account termination. Upon termination of your
                account, the License to use the Site and the other Customer
                Lobby IP, or any portion thereof, will terminate simultaneously.
                Notwithstanding any provision of these Terms, Customer Lobby
                reserves the right, in its sole discretion, without any notice
                or liability to you, to (a) terminate your License to use the
                Site and the other Customer Lobby IP, or any portion thereof;
                (b) block or prevent your future access to and use of all or any
                portion of the Site or the other Customer Lobby IP; (c) change,
                suspend or discontinue any aspect of the Site or the other
                Customer Lobby IP; and (d) impose limits on the Site and the
                other Customer Lobby IP. Either party hereto may terminate the
                License at any time and for any reason. In addition, the License
                will terminate immediately, without notice, if you fail to
                comply with these Terms.
              </div>
              <div className="my-3">
                Upon any termination of the License, you will discontinue use of
                the Site and the other Customer Lobby IP and, if applicable,
                delete all copies of the Customer Lobby IP from your browser,
                Web servers, servers and any other location where Customer Lobby
                IP has been saved. Upon termination of the License, you will no
                longer have access to the Site or any of the Content contained
                therein.
              </div>
              <div className="my-3">
                You understand and acknowledge that, unless and until the
                Customer Lobby IP is deleted from your servers, Web servers, Web
                sites, and browser, these Terms remain in effect.
              </div>
              <div className="my-3">
                Sections 2, 3, 4, 6, 7 and 10-17 will survive termination of the
                License.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Irreparable harm</div>
            <div className="terms-description my-3">
              You understand that in the event it fails to comply with this
              Agreement, Customer Lobby may suffer irreparable harm which may
              not be adequately compensated for by monetary damages alone. You,
              therefore, agree that in the event of your breach or threatened
              breach of this Agreement, including without limitation Sections 2
              through 6, Customer Lobby will be entitled to injunctive and/or
              other preliminary or equitable relief, in addition to any other
              remedies available at law.
            </div>
            <div className="my-3 highlight-text">
              Section 20 below applies only if you are subject to the HIPAA
              Security and Privacy Rule.
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">
              HIPAA Business Associate Agreement
            </div>
            <div className="terms-description">
              <div>
                <div className="my-4 highlight-text text-decoration-none">
                  Definitions
                </div>
                <div className="my-3">
                  The following terms used in this Agreement shall have the same
                  meaning as those terms in the HIPAA Rules: Breach, Data
                  Aggregation, Designated Record Set, Disclosure, Health Care
                  Operations, Individual, Minimum Necessary, Notice of Privacy
                  Practices, Protected Health Information, Required By Law,
                  Secretary, Security Incident, Subcontractor, Unsecured
                  Protected Health Information, and Use.
                </div>
                <ul className="my-3">
                  <li className="my-4">
                    <u>Business Associate.</u> “Business Associate” shall
                    generally have the same meaning as the term “business
                    associate” at 45 CFR 160.103, and in reference to the party
                    to this agreement, shall mean Customer Lobby.
                  </li>
                  <li className="my-4">
                    <u>Covered Entity.</u> “Covered Entity” shall generally have
                    the same meaning as the term “covered entity” at 45 CFR
                    160.103, and in reference to the party to this agreement,
                    shall mean a Customer.
                  </li>
                  <li className="my-4">
                    <u>HIPAA Rules.</u> “HIPAA Rules” shall mean the Privacy,
                    Security, Breach Notification, and Enforcement Rules at 45
                    CFR Part 160 and Part 164.
                  </li>
                </ul>
              </div>
              <div>
                <div className="my-4 highlight-text text-decoration-none">
                  Obligations and Activities of Business Associate
                </div>
                <div className="my-3">Business Associate agrees to:</div>
                <ul>
                  <li className="my-4">
                    (a) Not use or disclose protected health information other
                    than as permitted or required by the Agreement or as
                    required by law;
                  </li>
                  <li className="my-4">
                    (b) Use appropriate safeguards, and comply with Subpart C of
                    45 CFR Part 164 with respect to electronic protected health
                    information, to prevent use or disclosure of protected
                    health information other than as provided for by the
                    Agreement;
                  </li>
                  <li className="my-4">
                    (c) Report to covered entity any use or disclosure of
                    protected health information not provided for by the
                    Agreement of which it becomes aware, including breaches of
                    unsecured protected health information as required at 45 CFR
                    164.410, and any security incident of which it becomes
                    aware;
                  </li>
                  <li className="my-4">
                    (d) In accordance with 45 CFR 164.502(e)(1)(ii) and
                    164.308(b)(2), if applicable, ensure that any subcontractors
                    that create, receive, maintain, or transmit protected health
                    information on behalf of the business associate agree to the
                    same restrictions, conditions, and requirements that apply
                    to the business associate with respect to such information;
                  </li>
                  <li className="my-4">
                    (e) Make any amendment(s) to protected health information in
                    a designated record set as directed or agreed to by the
                    covered entity pursuant to 45 CFR 164.526, or take other
                    measures as necessary to satisfy covered entity’s
                    obligations under 45 CFR 164.526;
                  </li>
                  <li className="my-4">
                    (f) Maintain and make available the information required to
                    provide an accounting of disclosures to the covered entity
                    as necessary to satisfy covered entity’s obligations under
                    45 CFR 164.528;
                  </li>
                  <li className="my-4">
                    (g) To the extent the business associate is to carry out one
                    or more of covered entity's obligation(s) under Subpart E of
                    45 CFR Part 164, comply with the requirements of Subpart E
                    that apply to the covered entity in the performance of such
                    obligation(s); and
                  </li>
                  <li className="my-4">
                    (h) Make its internal practices, books, and records
                    available to the Secretary for purposes of determining
                    compliance with the HIPAA Rules.
                  </li>
                </ul>
              </div>
              <div>
                <div className="my-4 highlight-text text-decoration-none">
                  Permitted Uses and Disclosures by Business Associate
                </div>
                <ul>
                  <li className="my-4">
                    {" "}
                    (a) Business associate may only use or disclose protected
                    health information as necessary to perform the services set
                    forth in the Customer Terms of Use.
                  </li>
                  <li className="my-4">
                    (b) Business associate may use or disclose protected health
                    information as required by law.
                  </li>
                  <li className="my-4">
                    (c) Business associate agrees to make uses and disclosures
                    and requests for protected health information consistent
                    with covered entity’s minimum necessary policies and
                    procedures.
                  </li>
                  <li className="my-4">
                    (d) Business associate may not use or disclose protected
                    health information in a manner that would violate Subpart E
                    of 45 CFR Part 164 if done by covered entity.
                  </li>
                </ul>
              </div>
              <div>
                <div className="my-4 highlight-text text-decoration-none">
                  Provisions for Covered Entity to Inform Business Associate of
                  Privacy Practices and Restrictions
                </div>
                <ul>
                  <li className="my-4">
                    (a) Covered entity shall notify business associate of any
                    limitation(s) in the notice of privacy practices of covered
                    entity under 45 CFR 164.520, to the extent that such
                    limitation may affect business associate’s use or disclosure
                    of protected health information.
                  </li>
                  <li className="my-4">
                    (b) Covered entity shall notify business associate of any
                    changes in, or revocation of, the permission by an
                    individual to use or disclose his or her protected health
                    information, to the extent that such changes may affect
                    business associate’s use or disclosure of protected health
                    information.
                  </li>
                  <li className="my-4">
                    (c) Covered entity shall notify business associate of any
                    restriction on the use or disclosure of protected health
                    information that covered entity has agreed to or is required
                    to abide by under 45 CFR 164.522, to the extent that such
                    restriction may affect business associate’s use or
                    disclosure of protected health information.
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Miscellaneous</div>
            <div className="terms-description">
              <div className="my-3">
                These Terms represent the complete agreement between us
                concerning its subject matter, and supersede all prior
                agreements and representations between the parties hereto.
              </div>
              <div className="my-3">
                These Terms shall be governed by and construed under the laws of
                the State of California without reference to its conflict-of-law
                principles. In the event of any conflicts between foreign law,
                rules and regulations and California law, California law, rules
                and regulations shall prevail and govern.
              </div>
              <div className="my-3">
                Each party hereto agrees to submit to the exclusive and personal
                jurisdiction of the state and federal courts located in San
                Francisco, California, with respect to any dispute between the
                parties related to this Agreement, the Services or the Customer
                Lobby IP (whether based in contract tort or any other right).
                The United Nations Convention on Contracts for the International
                Sale of Goods and the Uniform Computer Information Transactions
                Act shall not apply to these Terms.
              </div>
              <div className="my-3">
                The Customer Lobby IP is controlled by U.S. Export Regulations,
                and may be not be exported to or used by embargoed countries or
                individuals.
              </div>
              <div className="my-3">
                Any notices to Customer Lobby must be sent via email addressed
                to: contact@customerlobby.com, and are deemed given upon
                receipt. Notices to you will be sent to your email address on
                record with Customer lobby, and are also deemed given upon
                receipt.
              </div>
              <div className="my-3">
                If any portion of these Terms shall be held by a court of
                competent jurisdiction to be invalid, void or otherwise
                unenforceable, the remaining provisions shall remain enforceable
                to the fullest extent permitted by law. Furthermore, to the
                fullest extent possible, the provisions of these Terms
                (including, without limitation, each portion of these Terms
                containing any provision held to be invalid, void or otherwise
                unenforceable, that is not itself invalid, void or
                unenforceable) shall be construed so as to give effect to the
                intent manifested by the provision held invalid, void or
                unenforceable.
              </div>
              <div className="my-3">
                A waiver of any default is not a waiver of any subsequent
                default.
              </div>
              <div className="my-3">
                These Terms shall be binding upon and inure to the benefit of
                the respective successors and assigns of the parties hereto.
              </div>
              <div className="my-3">
                Anything contained on the Site inconsistent with or conflicting
                with these Terms is superseded by these Terms, as in effect at
                any given time.
              </div>
              <div className="my-3">
                You represent and warrant that you are at least eighteen (18)
                years of age and that you have the right and ability to enter
                into these terms. you further represent that you are authorized
                to enter into these terms, and that these terms constitute your
                valid and legally binding obligation.
              </div>
            </div>
          </li>
          <li className="my-3">
            <div className="terms-heading">Questions and comments</div>
            <div className="terms-description">
              <div className="my-3">
                If you have any questions regarding these Terms or your use of
                the Site, please submit them to us at{" "}
                <a href="mailto:info@customerlobby.com" target="_blank">
                  info@customerlobby.com.
                </a>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div className="accept-terms-cta-container">
        <button
          className="accept-terms-cta"
          onClick={() => {
            acceptCustomerTerms();
          }}
          disabled={loading}
        >
          Click to accept terms
        </button>
      </div>
    </BlockUi>
  );
};

export default AcceptTerms;
