import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { showCompanyId, showIsManager } from "src/shared/SessionHelper";
import { QUERY_DIRECT_CONNECT_SETTINGS } from "src/graphql/direct_connect/queries";
import { QUERY_SUBSCRIPTION_DETAILS } from "src/graphql/settings/billing/queries";
import { CARD_STATUSES } from "src/constants/Billing";
import BrowserUtil from "src/utils/BrowserUtil";
import CookieUtil from "src/utils/CookieUtil";
import { COOKIES } from "src/shared/Config";
import * as sessionHelper from "src/shared/SessionHelper";
import { addToast } from "src/utils/ToastUtil";

const DirectConnectAndCreditToast = ({ children }) => {
  // state
  const client = useApolloClient();

  const companyAccountClass = sessionHelper.loadCookie(COOKIES.ACCOUNT_CLASS);
  const partnerCompanyId = sessionHelper.loadCookie(COOKIES.PARTNER_COMPANY_ID);
  const accountClassHide =
    companyAccountClass === "freetrial" ||
    companyAccountClass === "insights" ||
    companyAccountClass === "internal" ||
    companyAccountClass === "phantom";

  // methods
  const showDCToast = () => {
    client
      .query({
        query: QUERY_DIRECT_CONNECT_SETTINGS,
        variables: {
          companyId: showCompanyId,
        },
      })
      .then((res) => {
        // Invoice system connection status will be calculated by graphql endpoint.
        if (
          res &&
          res.data &&
          res.data.directConnectSettings &&
          (res.data.directConnectSettings.invoiceSystemConnected ||
            !res.data.directConnectSettings.lastRunDate)
        ) {
          return;
        }
        addToast({
          sortOrder: 1000,
          type: "warn",
          style: {
            backgroundColor: "#f47821",
          },
          onClose: () => {
            CookieUtil.setCookie(COOKIES.HIDE_DC_TOAST_KEY, "1");
          },
          message: (
            <span>
              The connection to your invoicing system is not available. Click
              below to submit a Support Request to resolve this issue.
            </span>
          ),
          action: (
            <a
              onClick={() => {
                CookieUtil.setCookie(COOKIES.HIDE_DC_TOAST_KEY, "1");
              }}
              className="text-white"
              href={`/companies/${showCompanyId}/help-center`}
            >
              <u>Link</u>
            </a>
          ),
          persist: true,
        });
      })
      .catch(() => {
        // do nothing
      });
  };

  const showCreditToast = () => {
    client
      .query({
        query: QUERY_SUBSCRIPTION_DETAILS,
        variables: {
          companyId: showCompanyId,
        },
      })
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.company &&
          res.data.company.paymentMethod !== "ach" &&
          (!res.data.subscriptionDetails ||
            res.data.subscriptionDetails.status !== CARD_STATUSES.CURRENT)
        ) {
          addToast({
            sortOrder: 999,
            type: "warn",
            style: {
              backgroundColor: "#f47821",
            },
            onClose: () => {
              CookieUtil.setCookie(COOKIES.HIDE_CC_TOAST_KEY, "1");
            },
            message: (
              <span>
                The Credit Card on file requires updating. Click below to visit the billing page to update your card.{" "}
              </span>
            ),
            action: (
              <a
                onClick={() => {
                  CookieUtil.setCookie(COOKIES.HIDE_CC_TOAST_KEY, "1");
                }}
                className="text-white"
                href={`/companies/${showCompanyId}/billing`}
              >
                <u>here</u>
              </a>
            ),
            persist: true,
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  // effects
  React.useEffect(() => {
    if (!BrowserUtil.isMobile() && !accountClassHide) {
      if (!CookieUtil.getCookie(COOKIES.HIDE_DC_TOAST_KEY)) {
        showDCToast();
      }
      if (showIsManager && !CookieUtil.getCookie(COOKIES.HIDE_CC_TOAST_KEY)) {
        showCreditToast();
      }
    }
  }, []);

  // render
  return <React.Fragment>{children}</React.Fragment>;
};

export default DirectConnectAndCreditToast;
