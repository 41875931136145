import React from "react";
import { numberFormatter } from "src/shared/Utils";
const NEW_LINE_REGEX = /(\r\n|\r|\n)/g;

export default class StringUtil {
  /**
   *
   * @param {string} str
   */
  static nl2br(str) {
    if (typeof str !== "string") {
      return str;
    }

    return str.split(NEW_LINE_REGEX).map(function (line, index) {
      if (line.match(NEW_LINE_REGEX)) {
        return React.createElement("br", { key: index });
      }
      return line;
    });
  }

  /**
   *
   * @param {string} str
   */
  static capitalize(str) {
    if (!str || typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   *
   * @param {*} str - String to be capitalize
   * @param {*} charToReplace - character to replace with white space
   * @returns The removed  from sting and each words
   */
  static capitalizeEachWord = (str, charToReplace = "_") => {
    if (!str || typeof str !== "string" || str.length === 0) {
      return numberFormatter(str, 0);
    }
    str = StringUtil.capitalize(str.replaceAll(charToReplace, " "));
    return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  static highlightWithinWord(
    string,
    subString,
    numbersOnly = false,
    minSearchTermLength = 2,
  ) {
    if (string && subString && subString.length > minSearchTermLength) {
      if (numbersOnly) {
        const searchTerm = subString
          .split("")
          .join("[() -]*")
          .replace(/\+/g, "\\+")
          .replace(/\(/g, "\\(")
          .replace(/\)/g, "\\)");
        const matches = string.match(searchTerm);
        if (matches && matches[0]) {
          subString = matches[0];
        }
      }
      const indexOf = string.toLowerCase().indexOf(subString.toLowerCase());
      if (indexOf !== -1) {
        return (
          <span className="pre-wrap">
            {string.substr(0, indexOf)}
            <span className="pre-wrap highlight-within-word font-weight-bold">
              {string.substr(indexOf, subString.length)}
            </span>
            {string.substr(indexOf + subString.length)}
          </span>
        );
      }
    }
    return string;
  }

  /**
   * Provides the token after last '/' in the url.
   * @param {string} strUrl -- Url
   * @returns -- The token after last '/' in the url.
   */
  static getLastTokenFromURL(strUrl) {
    var url = new URL(strUrl);
    if (url) {
      return (
        url.pathname?.substring(
          url.pathname.lastIndexOf("/") + 1,
          url.pathname.length,
        ) || "download"
      );
    }
    return "download";
  }

  static stringWithNewLines = (text) => {
    return (
      <div>
        {text.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  };
}
