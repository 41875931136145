import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import NumberUtil from "src/utils/NumberUtil";
import Chart from "src/shared/styleguide/charts/Chart";
import BlockUi from "react-block-ui";
import {
  chartBorderOffset,
  defaultNoDataDemonstrated,
  getBaseConfig,
  getHorizontalConfig,
  getLeftPositionCorrection,
  getVerticalConfig,
  legendLineHeight,
  legendVerticalPadding,
  updateChartConfig,
  updateNoData,
  updateTooltipFormatter,
} from "./DonutChartHelper.js";

const DonutChartResponsive = ({
  breakPoint = 390,
  chartData = [],
  chartId,
  chartTitle = "",
  chartTitleFontSize,
  labelFormatter,
  legendEnabled = true,
  legendFontSize = 15,
  legendIsPaginated = true,
  legendItemMargin = 9,
  legendLayout = "vertical",
  legendX,
  legendY = 20,
  loading = false,
  pieSize = 220,
  pieThickness = 36,
  spacingBottom = 0,
  spacingLeft = 0,
  spacingRight = 0,
  spacingTop = 0,
  symbolPadding = 8,
  symbolRadius = 50,
  symbolSize = 14,
  tooltipClassName,
  tooltipFormatter,
  zeroStateCustom,
  zeroStateDemonstrated = false,
}) => {
  const chartRef = useRef();
  const [chartWidth, setChartWidth] = useState(0);
  const leftPositionCorrection = getLeftPositionCorrection(pieSize);
  const legendHeight =
    legendVerticalPadding +
    chartData.length * (legendFontSize * legendLineHeight) +
    (chartData.length - 1) * legendItemMargin;
  const noDataDemonstrated = (config) =>
    zeroStateCustom
      ? zeroStateCustom(config)
      : () => defaultNoDataDemonstrated(config);

  const baseConfig = getBaseConfig({
    chartBorderOffset,
    chartData,
    labelFormatter,
    leftPositionCorrection,
    legendEnabled,
    legendFontSize,
    legendIsPaginated,
    legendItemMargin,
    legendLayout,
    legendX,
    pieSize,
    pieThickness,
    spacingBottom,
    spacingLeft,
    spacingRight,
    spacingTop,
    symbolPadding,
    symbolRadius,
    symbolSize,
    tooltipClassName,
    tooltipFormatter,
  });

  const horizontalConfig = getHorizontalConfig({
    baseConfig,
    leftPositionCorrection,
    legendX,
    pieSize,
  });

  const verticalConfig = getVerticalConfig({
    baseConfig,
    legendHeight,
    legendY,
    pieSize,
  });

  // state
  const [noData, setNoData] = useState(false);
  const [chartConfig, setChartConfig] = useState({
    ...horizontalConfig,
    responsive: {
      rules: [
        {
          condition: {
            callback: function() {
              setChartWidth(this.chartWidth);
              return this.chartWidth <= breakPoint;
            },
          },
          chartOptions: verticalConfig,
        },
        {
          condition: {
            callback: function() {
              setChartWidth(this.chartWidth);
              return this.chartWidth > breakPoint;
            },
          },
          chartOptions: {
            chart: {
              ...horizontalConfig.chart,
            },
            ...baseConfig,
            legend: {
              ...horizontalConfig.legend,
            },
            plotOptions: {
              pie: {
                ...horizontalConfig.plotOptions.pie,
              },
            },
          },
        },
      ],
    },
  });

  // effects
  useEffect(() => {
    updateChartConfig({
      chartConfig,
      chartData,
      noDataDemonstrated,
      pieSize,
      pieThickness,
      setChartConfig,
      setNoData,
      zeroStateCustom,
      zeroStateDemonstrated,
    });
  }, [chartData]);

  useEffect(() => {
    updateTooltipFormatter({
      chartConfig,
      tooltipFormatter,
      setChartConfig,
    });
  }, [tooltipFormatter]);

  useEffect(() => {
    updateNoData({loading, setNoData});
  }, [loading]);

  // render
  return (
    <React.Fragment>
      <span
        className="text-dark font-weight-bold my-0"
        style={{
          fontSize: `${
            chartTitleFontSize ? chartTitleFontSize + "px" : "unset"
          }`,
          position: "absolute",
          height: pieSize,
          width: chartWidth > breakPoint ? pieSize : "calc(100% - 30px)",
          display: "grid",
          placeItems: "center",
          zIndex: 0,
        }}
      >
        {NumberUtil.toK(chartTitle)}
      </span>
      <div className="card-chart">
        <BlockUi loader={<React.Fragment />} blocking={noData}>
          <Chart ref={chartRef} chartId={chartId} chartConfig={chartConfig} />
        </BlockUi>
      </div>
    </React.Fragment>
  );
};

DonutChartResponsive.propTypes = {
  breakPoint: PropTypes.number,
  chartData: PropTypes.array.isRequired,
  chartId: PropTypes.string.isRequired,
  chartTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartTitleFontSize: PropTypes.number,
  labelFormatter: PropTypes.func,
  legendEnabled: PropTypes.bool,
  legendFontSize: PropTypes.number,
  legendIsPaginated: PropTypes.bool,
  legendItemMargin: PropTypes.number,
  legendLayout: PropTypes.string,
  legendX: PropTypes.number,
  legendY: PropTypes.number,
  loading: PropTypes.bool,
  pieSize: PropTypes.number,
  pieThickness: PropTypes.number,
  spacingBottom: PropTypes.number,
  spacingLeft: PropTypes.number,
  spacingRight: PropTypes.number,
  spacingTop: PropTypes.number,
  symbolPadding: PropTypes.number,
  symbolRadius: PropTypes.number,
  symbolSize: PropTypes.number,
  tooltipClassName: PropTypes.string,
  tooltipFormatter: PropTypes.func,
  zeroStateCustom: PropTypes.func,
  zeroStateDemonstrated: PropTypes.bool,
};

export default DonutChartResponsive;
