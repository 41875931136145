import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  magnetMailer: false,
  uploadMagnetImage: false,
  steperFlag:false,
  isUploadCustomImage:true,
  isMagnetImageNull:true,
  isMagnetImageUpload:false,
  isMagnetAlertDiloagbox:true,
  goToNext:false,
};

export const magnetMailerSlice = createSlice({
  name: "magnetMailer",
  initialState,
  reducers: {
    setMagnetMailer: (state, action) => {
      state.magnetMailer = action.payload;
    },
    setUploadMagnetImage: (state, action) => {
      state.uploadMagnetImage = action.payload;
    },
    setSteperFlag: (state, action) => {
      state.steperFlag = action.payload;
    },
    setIsUploadMagnetImage: (state, action) => {
      state.isUploadCustomImage = action.payload;
    },
    setIsMagnetImageNull: (state, action) => {
      state.isMagnetImageNull = action.payload;
    },
    setIsMagnetImageUpload: (state, action) => {
      state.isMagnetImageUpload = action.payload;
    },
    setsMagnetAlertDiloagbox: (state, action) => {
      state.isMagnetAlertDiloagbox = action.payload;
    },
    setGoToNext: (state, action) => {
      state.goToNext = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMagnetMailer, setUploadMagnetImage,setSteperFlag,setIsUploadMagnetImage,setIsMagnetImageNull,setIsMagnetImageUpload,setsMagnetAlertDiloagbox,setGoToNext} = magnetMailerSlice.actions;

export default magnetMailerSlice.reducer;
