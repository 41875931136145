import React, { useEffect, useState, useRef } from 'react'
import './EditEmailModal.scss'
import editEmailImg from '../../../../../../../styles/assets/information.png'
import './ResendConfirmationModal.scss'
import './Success.scss'
import { confirmationHelper } from './query_actions'
import SuccessToast from './SuccessToast'

const ResendConfirmationModal = ({ setUpdateComponent, updateComponent, handleInvitations, display, contact, setDisplay, authHeader }) => {
  const emailModalEl = useRef(null)
  const [ successDisplay, setSuccessDisplay ] = useState('none')
  const [ state, setState ] = useState({ firstName: "", lastName: "", phoneNumber: "", id: null, email: "", confirrmationVia: "" })
  const [ validInput, setValidInput ] = useState(false)

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validatePhone = (phone) => {
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return re.test(String(phone))
  }

  const handleChange = (value) => {
    return e => {
      let newValue = e.target.value
      if(value === 'email') {
        if(newValue === contact.email || !validateEmail(e.target.value)) {
          setValidInput(false)
        } else {
          setValidInput(true)
        }
      } else {
        if(newValue === contact.emailPhone || !validatePhone(newValue)) {
          setValidInput(false)
        } else {
          setValidInput(true)
        }
      }
      setState({...state, [value]: newValue})
    }
  }

  const upperCaseFirstLetter = (string) => {
    if(!string) return ""

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {

    if(contact) {
      let firstName = upperCaseFirstLetter(contact.firstName)
      let lastName = upperCaseFirstLetter(contact.lastName)
      let id = contact.reviewConfirmationId || null
      let confirmationVia = contact.confirmationVia || ""
      let phoneNumber = contact.emailPhone || ""
      let email = contact.email || ""
      setState({...state, firstName, lastName, phoneNumber, id, email, confirmationVia})
    }
  }, [contact])

  const closeModal = (e) => {
    setDisplay('none')
  }

  const handleSubmit = async () => {
    let res = await confirmationHelper({ firstName, id, lastName, phoneMobile: phoneNumber, authHeader, email })

    setDisplay('none')
    setSuccessDisplay ('flex')
    handleInvitations()
    setUpdateComponent(!updateComponent)
    setTimeout(() => {
      setSuccessDisplay('none')
    }, 5000)
  }

  const handleInputs = () => {
    let { confirmationVia, email, phoneNumber } = state
    if(confirmationVia) {
      if(confirmationVia === 'email') {
        return [
          <div key={0} className="edit-email-label">Email</div>,
          <input key={1} onChange={handleChange('email')} className="edit-email-input" type="text" value={email}/>
        ]
      }
      return[
        <div key={0} className="edit-email-label">Phone Number</div>,
        <input key={1} placeholder="555-555-5555" pattern="\d{3}[\-]\d{3}[\-]\d{4}" onChange={handleChange('phoneNumber')} className="edit-email-input" type="text" value={phoneNumber}/>
      ]
    }
    return null
  }

  let { firstName, lastName, phoneNumber, id, email } = state

  return (
    <React.Fragment>
      <div ref={emailModalEl} style={{ display }} id="email-modal-container">
        <div className="inner-container">
          <div className="header-container">
            <div className="header-modal-label">Resend Confirmation</div>
            <div onClick={closeModal} className="close-modal">X</div>
          </div>
          <form onSubmit={e => e.preventDefault()}>
            <div className="edit-email-label">First Name</div>
            <input onChange={handleChange('firstName')} className="edit-email-input" type="text" value={firstName}/>
            <div className="edit-email-label">Last Name</div>
            <input onChange={handleChange('lastName')} className="edit-email-input" type="text" value={lastName}/>

            {handleInputs()}
          </form>
          <div className="confirmation-divider"></div>
          <div className="edit-email-btns-container">
            <div onClick={() => setDisplay('none')} className='edit-email-cancel'>Cancel</div>
            <div onClick={validInput ? handleSubmit : null} style={{opacity: validInput ? 1 : 0.6}} className="edit-email-resend">Resend</div>
          </div>
        </div>
      </div>
      <SuccessToast content={"Confirmation sucssessfully resent."} display={successDisplay} />

    </React.Fragment>
  )
}

export default ResendConfirmationModal
