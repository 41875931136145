import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import Card from "src/shared/styleguide/card/Card"
import "./ListCard.scss";
import megaphoneIcon from "src/assets/images/common/campaign.svg";

export const ListCard = ({
  children,
  className="",
  flagColor="#f47821",
  flagIcon=megaphoneIcon,
  highlight=false,
  cardKey="",
}) => (
    <Accordion key={cardKey} className={`list-card-container ${className}`}>
      {highlight ? 
        <div className="list-card-flag-container">
          <div className="list-card-flag" style={{background: flagColor}}></div>
          <img src={flagIcon} alt="Flag" className="list-card-flag-icon" />
        </div>
      : null}
      <Card 
        className="list-card"
        padding="0"
      >
        {children}
      </Card>
    </Accordion>
  )

ListCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  flagColor: PropTypes.string,
  flagIcon: PropTypes.string,
  highlight: PropTypes.bool,
  cardKey: PropTypes.string,
};

export default ListCard;
