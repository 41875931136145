import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import img1 from "src/assets/images/settings/angies-list-popup-img.png";

const AngieListSmartInviteHelp = ({ showModal, setShowModal }) => {
  // render
  return (
    <Modal
      zIndex={2999}
      modalSize="lg"
      onHide={() => setShowModal(false)}
      body={
        <div className="pa-2">
          <h3 className="text-dark">Step 1</h3>
          <div>
            Go to{" "}
            <a
              href="https://angieslist.com"
              className="sg-text-info"
              target="_blank"
              rel="noopener noreferrer"
            >
              angieslist.com
            </a>
            . Enter your company name and ZIP code in the search box. Select
            your company from the list of alternatives.
          </div>
          <h3 className="text-dark my-2">Step 2</h3>
          <div>Copy the "Company ID", (see below).</div>
          <img
            className="my-4 img-fluid"
            src={img1}
            alt="Google search"
          />
          <h3 className="text-dark">Step 3</h3>
          <div>
            Paste the "Company ID" in the Angies' List Company ID box above.
          </div>
        </div>
      }
      footer={
        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      }
      show={showModal}
      handleClose={() => {}}
    />
  );
};

export default AngieListSmartInviteHelp;
