import React from "react";
import StringUtil from "src/utils/StringUtil";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import PropTypes from "prop-types";
import { REVIEWS_BASEURL } from "src/constants/ReviewResults";
import UrlUtil from "src/utils/UrlUtil";

const CustomEmailTemplatePreview = ({
  firstName,
  template,
  logo,
  showPreview,
  setShowPreview,
  companyId,
  companyAbbreviatedName,
}) => {
  // variables
  const linkSatisfied = UrlUtil.joinUrl(
    REVIEWS_BASEURL,
    companyId,
    companyAbbreviatedName,
    "write",
  );
  const linkUnsatisfied = UrlUtil.joinUrl(
    " https://www.customerlobby.com/email-invitations",
    companyId,
    "unhappy-feedback-preview",
  );

  // render
  return (
    <Modal
      onHide={() => setShowPreview(false)}
      header={<h2 className="mb-0">Custom Email Preview</h2>}
      body={
        <React.Fragment>
          <div
            style={{
              borderRadius: 4,
              border: "1px solid rgba(126, 126, 126, 0.2)",
            }}
            className="p-4 m-2"
          >
            <div className="text-center">{logo}</div>
            <div
              style={{
                width: 600,
              }}
              className="mx-auto"
            >
              <p>
                {template.greeting} {firstName},
                <br />
                <br />
                {StringUtil.nl2br(template.mainBody)}
              </p>
              <p className="text-center my-4">
                {template.unhappyCustomerFeedback ? (
                  <React.Fragment>
                    <Button className="mr-3">
                      <a
                        className="d-block color-inherit text-decoration-none"
                        href={linkSatisfied}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        I'm Satisfied
                      </a>
                    </Button>
                    <Button>
                      <a
                        className="d-block color-inherit text-decoration-none"
                        href={linkUnsatisfied}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        I'm Not Satisfied
                      </a>
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button>
                      <a
                        className="d-block color-inherit text-decoration-none"
                        href={linkSatisfied}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Write a review
                      </a>
                    </Button>
                  </React.Fragment>
                )}
              </p>
              <p>{StringUtil.nl2br(template.signature)}</p>
            </div>
          </div>
        </React.Fragment>
      }
      footer={null}
      show={showPreview}
      handleClose={() => {}}
    />
  );
};

CustomEmailTemplatePreview.propTypes = {
  setShowPreview: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  showPreview: PropTypes.bool.isRequired,
  logo: PropTypes.node.isRequired,
  firstName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  companyAbbreviatedName: PropTypes.string.isRequired,
};

export default CustomEmailTemplatePreview;
