import React, { useEffect } from 'react'
import { useMutation } from "@apollo/react-hooks";
import { showCompanyId as companyId } from 'src/shared/SessionHelper'
import { UPDATE_DIRECT_CONNECT_SETTINGS } from 'src/graphql/direct_connect/mutations'
import { NO_CACHE } from 'src/shared/Constants'

const FreshbooksOnlineCallback = () => {
  const [updateDirectConnectSettings] = useMutation(UPDATE_DIRECT_CONNECT_SETTINGS)

  const handleUpdate = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const oauthVerifier = urlParams.get('oauth_verifier')
    const oauthToken = urlParams.get('oauth_token')
    const domain = localStorage.getItem('freshbooksDomain')
    const secret = localStorage.getItem('freshbooksSecret')
    localStorage.removeItem('freshbooksDomain')
    localStorage.removeItem('freshbooksSecret')

    if (!oauthVerifier) window.location.replace(`${document.location.origin}/companies/${companyId}/direct-connect-settings`)

    const res = await updateDirectConnectSettings({
      variables: {
        companyId,
        workflowSystem: 'Freshbooks',
        oauthVerifier,
        oauthToken,
        secret,
        domain
      },
      fetchPolicy: NO_CACHE,
    })

    if (res && !res.loading && res.data && res.data.updateDirectConnectSetting) {
      window.location.replace(`${document.location.origin}/companies/${companyId}/direct-connect-settings`)
    }
  }

  useEffect(() => {
    handleUpdate()
  }, [])

  return null;
}

export default FreshbooksOnlineCallback
