import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MEDIUM_CHART_HEIGHT } from "src/constants/ReviewResults";
import { QUERY_SENTIMENT_SCORE_TREND } from "src/graphql/reviews/results/queries";
import { useQuery } from "@apollo/react-hooks";
import CalendarUtil from "src/utils/CalendarUtil";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";
import LineChart from "./LineChart";

const SentimentScoreTrend = ({ companyId, filter }) => {
  // state
  const [noData, setNoData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [sentimentScoreTrend, setSentimentScoreTrend] = useState([
    {
      name: "Sentiment Score Trend",
      data: [],
      color: "#f47821"
    }
  ]);

  // call apis
  const { loading, data } = useQuery(QUERY_SENTIMENT_SCORE_TREND, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });

  // effects
  useEffect(() => {
    if (data && data.sentimentScoreTrend) {
      const newSentimentScoreTrend = { ...sentimentScoreTrend };
      newSentimentScoreTrend[0].data = [];
      setCategories(
        data.sentimentScoreTrend.data
          .map(trend => trend.from)
          .map(dateStr => CalendarUtil.toDateStrByChartType(dateStr, filter.id))
      );
      data.sentimentScoreTrend.data.forEach((trend, index) => {
        newSentimentScoreTrend[0].data.push({
          from: trend.from,
          to: trend.to,
          y: trend.sentiment_score
        });
      });
      const total = data.sentimentScoreTrend.data.reduce(
        (acc, val) => acc + val.sentiment_score,
        0
      );
      setNoData(total === 0);
      setSentimentScoreTrend(newSentimentScoreTrend);
    }
  }, [data]);
  return (
    <LineChart
      noData={noData}
      loading={loading}
      chartId="sentimentScoreTrend"
      title="Sentiment Score Trend"
      popupInfoMsg="This shows the Sentiment Score as a trend line of published Customer Lobby reviews for the selected date range."
      categories={categories}
      chartData={sentimentScoreTrend}
      chartHeight={MEDIUM_CHART_HEIGHT}
      showLegend={false}
      cardBodyStyle={{
        paddingLeft: 0,
        paddingRight: 0
      }}
      tooltipFormatter={function() {
        const { point } = this;
        return `${point.from ? point.from : ""}${
          point.to && point.from !== point.to ? ` to ${point.to}` : ""
        }<br />Score: ${point.y}%`;
      }}
    />
  );
};

SentimentScoreTrend.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired
};

export default SentimentScoreTrend;
