import React from "react";
import PropTypes from "prop-types";
import Chart from "src/shared/styleguide/charts/Chart";
import BlockUi from "react-block-ui";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import { DEFAULT_FONT, COLOR_PALETTE } from "src/shared/Constants";

highchartsMore(Highcharts);

const GaugeChart = ({
  backgroundColor = "white",
  breakPoint = 550,
  chartData,
  chartId,
  dataTitle = "",
  max = 100,
  min = 0,
  noData = false,
  title = "",
  units = "%",
  events,
  ...props
}) => {
  const baseChartConfig = {
    chart: {
      type: "gauge",
      plotBackgroundColor: backgroundColor,
      plotBorderWidth: 0,
      height: "43%",
      events: events,
    },

    title: {
      text: title,
      x: 24,
      y: 44,
      style: {
        fontSize: "20px",
        fontWeight: 500,
        opacity: 0.6,
        color: COLOR_PALETTE.blackRockBlue,
        fontFamily: DEFAULT_FONT,
      },
    },

    pane: {
      startAngle: -90,
      endAngle: 90,
      background: null,
      center: ["50%", "80%"],
      size: "90%",
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: chartData?.tickPositions?.map((item) => item.value),
      tickColor: COLOR_PALETTE.white,
      tickLength: 40,
      tickWidth: 0.8,
      minorTickInterval: null,
      labels: {
        distance: 18,
        style: {
          opacity: 0.7,
          width: "200px",
          color: COLOR_PALETTE.blackRockBlue,
          fontFamily: DEFAULT_FONT,
          fontSize: "12px",
          lineHeight: "12px",
        },
        formatter: (ctx) => {
          return `<b>${ctx?.value}%</b> ${
            chartData?.tickPositions?.filter(
              (item) => item.value === ctx.value,
            )[0]?.description
          }`;
        },
      },
      plotBands: [
        // fill object
        {
          from: min,
          to: chartData?.dialPosition,
          color: COLOR_PALETTE.vividBlue,
          thickness: 40,
        },
        // remainder object
        {
          from: chartData?.dialPosition,
          to: max,
          color: COLOR_PALETTE.lightNavy,
          thickness: 40,
        },
      ],
    },

    series: [
      {
        name: dataTitle,
        data: [chartData?.dialPosition],
        tooltip: {
          valueSuffix: units,
        },
        dataLabels: {
          borderWidth: 0,
          format: `{y}${units}`,
          style: {
            opacity: 0.7,
            color: COLOR_PALETTE.blackRockBlue,
            fontFamily: DEFAULT_FONT,
            fontSize: "1rem",
          },
        },
        dial: {
          backgroundColor: COLOR_PALETTE.paleNavy,
          baseLength: "0%",
          baseWidth: 8,
          radius: "50%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: COLOR_PALETTE.paleNavy,
          radius: 10,
        },
      },
    ],
    ...props,
  };

  const chartConfig = {
    ...baseChartConfig,
    responsive: {
      rules: [
        {
          condition: {
            callback: function() {
              return this.chartWidth <= breakPoint;
            },
          },
          chartOptions: {
            ...baseChartConfig,
            chart: {
              ...baseChartConfig.chart,
              height: "55%",
            },

            pane: {
              ...baseChartConfig.pane,
              center: ["50%", "80%"],
              size: "80%",
            },

            yAxis: {
              ...baseChartConfig.yAxis,
              labels: {
                ...baseChartConfig.yAxis.labels,
                style: {
                  ...baseChartConfig.yAxis.labels.style,
                  width: "200px",
                },
              },
            },
          },
        },
        {
          condition: {
            callback: function() {
              return this.chartWidth > breakPoint;
            },
          },
          chartOptions: {
            ...baseChartConfig,
          },
        },
      ],
    },

    ...props,
  };

  return (
    <div className="card-chart">
      <BlockUi loader={<React.Fragment />} blocking={noData}>
        <div id="container"></div>
        <Chart chartId={chartId} chartConfig={chartConfig} />
      </BlockUi>
    </div>
  );
};

GaugeChart.propTypes = {
  breakPoint: PropTypes.number,
  chartData: PropTypes.object.isRequired,
  chartId: PropTypes.string.isRequired,
  dataTitle: PropTypes.string,
  events: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  noData: PropTypes.bool,
  props: PropTypes.object,
  title: PropTypes.string,
  units: PropTypes.string,
};

export default GaugeChart;
