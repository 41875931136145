export const GET_REVIEWS_METADATA = `
  query(
    $companyId: ID!
    $dateFrom: String!
    $source: String!
    $searchTerm: String
  ) {
    getReviewsMetadata(
      companyId: $companyId
      dateFrom: $dateFrom
      source: $source
      searchTerm: $searchTerm
    ) {
      total
      requested
      reviewed
      bounced
      unsubscribed
      declined
      confirmed
      invalidEmail
      inProgress
      wrongNumber
      unresponsive
      pending
    }
  }
`;

export const GET_CREDITS = `
  query($companyId: ID!, $creditType: String!) {
    getCredits(companyId: $companyId, creditType: $creditType) {
      credits
      nextGrantDate
      formattedGrantDate
      formattedDate
      formattedCount
    }
  }
`;
