import React, { Component } from "react";
import "./NotFoundPage.scss";
import { showCompanyId } from "src/shared/SessionHelper";

class NotFoundPage extends Component {
  render() {
    return (
      <div className="error-page jumbotron">
        <h2 className="headline text-orange"> 404</h2>

        <div className="error-content">
          <h3>
            <i className="fa fa-warning text-orange"></i> Oops! Page not found.
          </h3>
          <p>
            We could not find the page you were looking for. Meanwhile, you may{" "}
            <a href={`/companies/${showCompanyId}/dashboard`}>
              return to dashboard
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
