import React from 'react';
import GrayAppImg from 'src/img/gray_app.png';
import PhoneImg from 'src/img/phone_img.png'
import AppleStoreImg from 'src/img/apple_store.png'
import GoogleStoreImg from 'src/img/google_play.png'

const MobileAppInfo = () => {

  return (
    <div className="mobile-app-outer-container">
      <div className="mobile-app-container-one">
        <img className="app-img mr-2" src={GrayAppImg} alt="app" />
        <div className='inner-container-one'>
          <div className="large-text">Apps</div>
          <div className="small-gray-text">Download our native iOS or Android app.</div>
          <div className="mobile-app-container-two">
            <img className="phone-img" alt="phone-img" src={PhoneImg} />
            <div className="inner-container-two">
              <div className="large-text-two">Mobile Apps</div>
              <div className="small-text-two">To text customers, send review invites and manage reviews on the go, download the app</div>
              <div className="app-images-container">
                <a href="https://apps.apple.com/us/app/spotlight-by-customer-lobby/id1472281204?ls=1" target="_blank">
                  <img className="apple-store-image" rel="noopener noreferrer" src={AppleStoreImg} alt='apple-store' />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.customerlobby.spotlight" target="_blank">
                  <img className="google-store-image" rel="noopener noreferrer" src={GoogleStoreImg} alt='google-store' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileAppInfo;
