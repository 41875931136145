export const REVIEW_INVITATION_INDEX = `
  query(
    $direction: String
    $column: String
    $companyId: ID!
    $source: String!
    $filter: String
    $dateFrom: String!
    $dateBucket: String!
    $page: Int!
    $searchTerm: String
  ) {
    trackInvitationIndex(
      direction: $direction
      column: $column
      companyId: $companyId
      source: $source
      filter: $filter
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      page: $page
      searchTerm: $searchTerm
    ) {
      id
      contactId
      scrapedReviewId
      reviewId
      displayName
      emailAddress
      inviteDate
      lastServiceDate
      email
      status
      reviewType
      phoneNumber
      firstName
      destination
      lastName
      reviewConfirmationId
      callDate
      confirmationVia
      dateTranscribed
      emailPhone
      formattedPhoneDaytime
      formattedPhoneMobile
      formattedPhoneAlt
      medium
    }
  }
`;
