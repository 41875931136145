import React, { useState, useEffect } from "react";
import NumberUtil from "src/utils/NumberUtil";
import DonutChart from "src/shared/styleguide/charts/DonutChart.jsx";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_TRAFFIC_TO_REVIEW_PAGES } from "src/graphql/reviews/results/queries";

const TrafficToReviewPages = ({
  companyId,
  filter,
  toDate,
  fromDate,
}) => {
  //state
  const [noData, setNoData] = useState(false);
  const [trafficToReviewPages, setTrafficToReviewPages] = useState([
    {
      type: "pie",
      name: "Traffic to review pages",
      data: [
        { name: "Google", y: 0, color: "#f6b200" },
        { name: "Other Search Engines", y: 0, color: "#dc5700" },
        { name: "Your Website", y: 0, color: "#898989" },
        { name: "Other", y: 0, color: "#5d7ae1" },
      ],
    },
  ]);

  // call query
  const { loading, data } = useQuery(QUERY_TRAFFIC_TO_REVIEW_PAGES, {
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket,
    },
  });

  // effects
  useEffect(() => {
    if (data && data.reviewPagesTraffic) {
      const newTrafficToReviewPages = { ...trafficToReviewPages };
      let total = 0;
      ["Google", "Other Search Engines", "Your Website", "Other"].map(
        (opt, index) => {
          if (data.reviewPagesTraffic[opt] !== undefined) {
            newTrafficToReviewPages[0].data[index].y =
              data.reviewPagesTraffic[opt];
            total += data.reviewPagesTraffic[opt];
          }
        }
      );
      if (total === 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setTrafficToReviewPages(newTrafficToReviewPages);
    }
  }, [data]);

  // render
  return (
    <DonutChart
      loading={loading}
      noData={noData}
      chartId="trafficToReviewPages"
      title="Traffic to Review Pages"
      popupInfoMsg="This shows how people are finding your Customer Lobby review page, in the context of which search engine they used for the selected date range."
      chartData={trafficToReviewPages}
      pieSize={188}
      tooltipFormatter={function () {
        const { point } = this;
        const { data } = this.series;
        const total = data.reduce((acc, val) => acc + val.y, 0) || 1;
        return `
${fromDate}${fromDate !== toDate ? ` to ${toDate}` : ""}
<br />${point.name}: ${point.y}%
<br />Total: ${total}`;
      }}
      labelFormatter={(ctx) => `${ctx.name} (${NumberUtil.toK(ctx.y, 2)})`}
    />
  );
};

TrafficToReviewPages.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  toDate: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
};

export default TrafficToReviewPages;
