import React from 'react'
import {getNameInitials, dateFormatter} from '../../../../../../shared/Utils';
import {NOT_AVAILABLE} from "../../../../../../shared/Constants";

/**
 * Component to render the private feedback header.
 * @param privateFeedback - privateFeedback to be used from parent.
 * @returns {XML} -> Return the PrivateFeedbackHeader.
 */
const PrivateFeedbackHeader = ({privateFeedback}) => {

  return (
    <div className="row mt-2">
      <div className="col-md-1 col-12  d-flex justify-content-md-center">
        <div className="ellipse d-flex align-items-center">
          <div className="name-initials w-100">
            {privateFeedback.contact ? getNameInitials(privateFeedback.contact.contactDisplayName) : NOT_AVAILABLE}
          </div>
        </div>
      </div>
      <div className="col-md-11 col-12 pl-lg-0 mt-1">
        <div className="row">
          <div className="col-12 name">
            {privateFeedback.contact ? privateFeedback.contact.contactDisplayName : NOT_AVAILABLE}
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-md-inline-flex">
            <div className="type">
              {dateFormatter(privateFeedback.createdAt)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PrivateFeedbackHeader;