import React, {useState} from "react";
import Button from "src/shared/styleguide/button/Button";
import { Accordion, Col, Row } from "react-bootstrap";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import Card from "src/shared/styleguide/card/Card";
import ListCard from "src/shared/styleguide/card/ListCard";
import MiniCard from "src/shared/styleguide/card/MiniCard";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

export const FormStyleGuide = () => {
  useDocumentTitle("Style Guide - Cards");

  const [listCardBodyShow, setListCardBodyShow] = useState(false);
  const toggleListCardBody = () => {
    setListCardBodyShow(!listCardBodyShow);
  }

  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Cards</h3>
      <div>
        A card is a flexible and extensible content container. It includes
        options for headers and footers, a wide variety of content, contextual
        background colors, and powerful display options.
      </div>

      <StyleGuideSection
        title="Basic Card"
        description="A basic card has no fixed width and is easily customizable."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <Card
                    popupInfoMsg="Overall review performance"
                    title="Overall Review Performance"
                  >
                    <p>
                      <img
                        src="https://picsum.photos/400"
                        alt="Card Style Guide Image"
                      />
                    </p>
                    <div>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </div>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Card from "src/shared/styleguide/card/Card";

<Card
  popupInfoMsg="Overall review performance"
  title="Overall Review Performance"
>
  <p>
    <img
      src="https://picsum.photos/400"
      alt="Card Style Guide Image"
    />
  </p>
  <div>
    Some quick example text to build on the card title and
    make up the bulk of the card's content.
  </div>
</Card>`}
        />
      </StyleGuideSection>
      <StyleGuideSection
          title="Action Card"
          description="Add an image, text, emphasize text, or buttons.  The important thing is that you use
          the 'sg-action-card' class for styling."
      >
      <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <Card
                   className="sg-action-card"
                   padding = "0" 
                  >
                    <img
                      src="https://picsum.photos/400"
                      alt="Card Style Guide Image"
                    />

                    <p className="sg-card-body-title">Your title in CAPS</p>

                    <p>Body text goes here. P tags preferred but not  required.</p>
                    <p>
                      You can emphasize text <span className="sg-card-body-emphasize">like this!</span>
                    </p>
                      <Button variant="primary">
                        Click Me
                      </Button>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Card from "src/shared/styleguide/card/Card";

  <Card className="sg-action-card">
  <img
    src="https://picsum.photos/400"
    alt="Card Style Guide Image"
  />

  <p className="sg-card-body-title">Your title in CAPS</p>

  <p>
    Body text goes here. P tags preferred but not  required.
  </p>
  <p>
    You can emphasize text <span className="sg-card-body-emphasize">like this!</span>
  </p>
    <Button variant="primary">
      Click Me
    </Button>
</Card>`}
        />
      </StyleGuideSection>
      <StyleGuideSection
          title="Action Card Mini"
          description="Add an image, text, emphasize text, or buttons.  The important thing is that you use
          the 'sg-action-card' class for styling AND append the `mini` class to scale down."
      >
      <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Card
                   className="sg-action-card mini"
                   padding = "0" 
                  >
                    <img
                      src="https://picsum.photos/400"
                      alt="Card Style Guide Image"
                    />

                    <p className="sg-card-body-title">Your title in CAPS</p>

                    <p>Body text goes here. P tags preferred but not  required.</p>
                    <p>
                      You can emphasize text <span className="sg-card-body-emphasize">like this!</span>
                    </p>
                      <Button variant="primary">
                        Click Me
                      </Button>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Card from "src/shared/styleguide/card/Card";

  <Card className="sg-action-card mini">
  <img
    src="https://picsum.photos/400"
    alt="Card Style Guide Image"
  />

  <p className="sg-card-body-title">Your title in CAPS</p>

  <p>
    Body text goes here. P tags preferred but not  required.
  </p>
  <p>
    You can emphasize text <span className="sg-card-body-emphasize">like this!</span>
  </p>
    <Button variant="primary">
      Click Me
    </Button>
</Card>`}
        />
      </StyleGuideSection>
      <StyleGuideSection
        title="Basic List Card"
        description="A Basic List Card is a full width card that can accept any DOM elements
        as children but also can hide and show content by utilizing React Bootstrap 
        Accordion. It also accepts parameters to display a customizable flag which pins to the 
        upper left corner."
      >
        <CodePreview
          elements={
            <ListCard>
              <Accordion.Toggle
                as="div"
                eventKey="0"
                expanded="false"
                onClick={toggleListCardBody}
              >
                <i
                  className={`fa ${listCardBodyShow ? "fa-chevron-up" : "fa-chevron-down"}`} 
                  style={{paddingRight: "1rem"}}
                />
                Anything can go in a list card. Bring your own styles.
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div>
                  Use React Bootstrap Accordion to display collapsable content. <br />
                  Nest your content in a div for appropriate styling.
                </div>
              </Accordion.Collapse>
            </ListCard>
          }
          code={
            `
import ListCard from "src/shared/styleguide/listcard/ListCard";
import { Accordion, Card as rbCard } from "react-bootstrap";

const [listCardBodyShow, setListCardBodyShow] = useState(false);
const toggleListCardBody = () => {
  setListCardBodyShow(!listCardBodyShow);
}

<ListCard>
  <Accordion.Toggle
    as="div"
    eventKey="0"
    expanded="false"
    onClick={toggleListCardBody}
  >
    Anything can go in a list card. Bring your own styles.
  </Accordion.Toggle>
  <Accordion.Collapse eventKey="0">
    <div>
      Use React Bootstrap Accordion to display collapsable content. <br />
      Nest your content in a div for appropriate styling.
    </div>
  </Accordion.Collapse>
</ListCard>
            `
          }
        />
      </StyleGuideSection>
      <StyleGuideSection
        title="Mini Card"
        description="This component was created for Review Results and is a little opionated.
        We made some edits so that you can override most of its defaults, and make it your own."
      >
        <CodePreview
          elements={
            <Row>
              <Col sm={4}>
                <MiniCard
                  title="Example Mini Card"
                  popupInfoMsg="We can provide all of the info!"
                  cardBodyStyle={{padding: "0 1rem"}}
                >
                  We use these on report pages, but you can use them anywhere.
                  Place it inside of a Bootstrap Col for sizing.
                </MiniCard>
              </Col>
            </Row>

          }
          code={
            `
import MiniCard from "src/shared/styleguide/card/MiniCard";
import { Row, Col } from "react-bootstrap";

<Row>
  <Col sm={4}>
    <MiniCard
      title="Example Mini Card"
      popupInfoMsg="We can provide all of the info!"
      cardBodyStyle={{padding: "0 1rem"}}
    >
      We use these on report pages, but you can use them anywhere.
      Place it inside of a Bootstrap Col for sizing.
    </MiniCard>
  </Col>
</Row>
            `
          }
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default FormStyleGuide;
