import { gql } from 'apollo-boost';


const UPDATE_APP_INTEGRATION_SETTING = gql`
  mutation updateAppIntegrationSetting(
    $companyId: ID!,
    $shortName: String!,
    $status: String!,
    $metaData: JSON
  ) {
    updateAppIntegrationSetting(
      companyId: $companyId,
      shortName: $shortName,
      status: $status,
      metaData:$metaData
    ) {
      name
      status
      shortName
      metaData
      updatedAt
    }
  }
`

export {
  UPDATE_APP_INTEGRATION_SETTING
}
