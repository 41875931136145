import React, { useState } from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import Form from "src/shared/styleguide/form/Form";
import NumberUtil from "src/utils/NumberUtil";
import { validatePhoneNumber } from "src/utils/phoneValidator";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import { Row, Col } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { ToastContainer } from "react-toastify";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import { constants } from "src/shared/Constants";
import engb from "date-fns/locale/en-GB";
registerLocale("en-gb", engb);

export const FormStyleGuide = () => {
  const [text5, setText5] = useState("Some text");
  const [text6, setText6] = useState("");
  const [text7, setText7] = useState("Some text");
  const [text8, setText8] = useState("");

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(true);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(true);

  const [MultiItemTextAreaArray, setMultiItemTextAreaArray] = useState([]);

  const [radio1, setRadio1] = useState(false);
  const [radio2, setRadio2] = useState(false);

  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(true);
  const [switch3, setSwitch3] = useState(false);
  const [switch4, setSwitch4] = useState(true);

  const [select1, setSelect1] = useState("");

  const [multiSelect, setMultiSelect] = useState([]);
  const setSelectMulti = (items) => {
    let newItems = [...items];
    if (items.length > 1 && items.slice(-1)[0].value === "all") {
      newItems = [items.pop()];
    } else if (items.length > 1) {
      newItems = items.filter((item) => item.value !== "all");
    }
    setMultiSelect([...newItems]);
  };

  const [multiSelectFilter, setMultiSelectFilter] = useState([]);
  const setSelectMultiFilter = (items) => {
    let newItems = [...items];
    if (items.length > 1 && items.slice(-1)[0].value === "all") {
      newItems = [items.pop()];
    } else if (items.length > 1) {
      newItems = items.filter((item) => item.value !== "all");
    }
    setMultiSelectFilter([...newItems]);
  };

  const [multiSelectFilterPaginated, setMultiSelectFilterPaginated] = useState(
    [],
  );
  const setSelectMultiFilterPaginated = (items) => {
    let newItems = [...items];
    if (items.length > 1 && items.slice(-1)[0].value === "all") {
      newItems = [items.pop()];
    } else if (items.length > 1) {
      newItems = items.filter((item) => item.value !== "all");
    }
    setMultiSelectFilterPaginated([...newItems]);
  };

  const [range1, setRange1] = useState(50);
  const [date1, setDate1] = useState(null);
  const [range2, setRange2] = useState({ min: 2, max: 10 });
  const [time1, setTime1] = useState(null);
  const sampleZipCodeArray = [
    { value: "all", label: "All" },
    { value: "00001", label: "00001" },
    { value: "00002", label: "00002" },
    { value: "00003", label: "00003" },
    { value: "00004", label: "00004" },
    { value: "00005", label: "00005" },
    { value: "00006", label: "00006" },
    { value: "00007", label: "00007" },
    { value: "00008", label: "00008" },
    { value: "00009", label: "00009" },
    { value: "00010", label: "00010" },
    { value: "01001", label: "01001" },
    { value: "01002", label: "01002" },
    { value: "01003", label: "01003" },
    { value: "01004", label: "01004" },
    { value: "01005", label: "01005" },
    { value: "01006", label: "01006" },
    { value: "01007", label: "01007" },
    { value: "01008", label: "01008" },
    { value: "01009", label: "01009" },
    { value: "01010", label: "01010" },
    { value: "02001", label: "02001" },
    { value: "02002", label: "02002" },
    { value: "02003", label: "02003" },
    { value: "02004", label: "02004" },
    { value: "02005", label: "02005" },
    { value: "02006", label: "02006" },
    { value: "02007", label: "02007" },
    { value: "02008", label: "02008" },
    { value: "02009", label: "02009" },
    { value: "02010", label: "02010" },
  ];

  const multiSelectFilterName = "needed-for-key";
  const readMoreContent = (value) => {
    return (
      <div className="multi-select-filter-read-more-content-container">
        {multiSelectFilter.map((item, i) => (
          <div key={`${multiSelectFilterName.replace(/\s/g, "")}-${i}`}>
            {item.label}
            <span
              className="Select-value-icon"
              onClick={() => {
                setSelectMultiFilter(
                  value.filter((el) => el.value !== item.value),
                );
              }}
            >
              ×
            </span>
          </div>
        ))}
      </div>
    );
  };

  const multiSelectFilterNamePaginated = "needed-for-key";
  const PAGE_LENGTH = 4;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [paginatedData, setPaginatedData] = React.useState(
    multiSelectFilterPaginated.slice(1, PAGE_LENGTH + 1),
  );
  const onPagination = (event) => {
    setCurrentPage(event.selected);
  };
  React.useEffect(() => {
    const startCount = currentPage * PAGE_LENGTH;
    const endCount = startCount + PAGE_LENGTH;
    setPaginatedData(multiSelectFilterPaginated.slice(startCount, endCount));
  }, [multiSelectFilterPaginated, currentPage]);
  const readMoreContentPaginated = (value) => {
    return (
      <React.Fragment>
        {/* // TODO consider making this a component */}
        <Row className="read-more-show-all-container container-fluid d-flex">
          {paginatedData.map((item, index) => (
            <Col
              xs={6}
              key={`${multiSelectFilterNamePaginated.replace(
                /\s/g,
                "",
              )}-${index}`}
            >
              <div className="value">
                {item.label}
                <span
                  className="Select-value-icon cursor-pointer"
                  onClick={() => {
                    setSelectMultiFilterPaginated(
                      value.filter((el) => el.value !== item.value),
                    );
                  }}
                >
                  ×
                </span>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} className="paginator-container">
            <PaginatorComponent
              pageCount={multiSelectFilterPaginated.length / PAGE_LENGTH}
              pageChangeHandler={onPagination}
              currentPage={currentPage}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  useDocumentTitle("Style Guide - Forms");
  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Forms</h3>
      <div>
        Examples and usage guidelines for form control styles, layout options
        and custom components for creating a wide variety of forms.
      </div>

      {/* Input boxes */}
      <StyleGuideSection
        title="Input Box"
        description="A basic form control with disabled and read only mode."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicInput0">
                    <Form.Label className="text-dark">Label</Form.Label>
                    <Form.Control placeholder="Input box" />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicInput1">
                    <Form.Label className="text-dark">Label</Form.Label>
                    <Form.Control disabled placeholder="Input box (disabled)" />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicTextarea0">
                    <Form.Label className="text-dark">Text area</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Text area"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicTextarea1">
                    <Form.Label className="text-dark">Text area</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      disabled
                      placeholder="Text area (disabled)"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicInput0">
  <Form.Label className="text-dark">Label</Form.Label>
  <Form.Control placeholder="Input box" />
</Form.Group>

<Form.Group controlId="formBasicInput1">
  <Form.Label className="text-dark">Label</Form.Label>
  <Form.Control disabled placeholder="Input box (disabled)" />
</Form.Group>

<Form.Group controlId="formBasicTextarea0">
  <Form.Label className="text-dark">Text area</Form.Label>
  <Form.Control
    as="textarea"
    rows="3"
    placeholder="Text area"
  />
</Form.Group>

<Form.Group controlId="formBasicTextarea1">
  <Form.Label className="text-dark">Text area</Form.Label>
  <Form.Control
    as="textarea"
    rows="3"
    disabled
    placeholder="Text area (disabled)"
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* valid and invalid states */}
      <StyleGuideSection
        title="Validation State"
        description="A form control with success, warning and error state."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicInput2">
                    <Form.Label className="text-dark">
                      Label <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      isValid={text5 !== ""}
                      isInvalid={text5 === ""}
                      value={text5}
                      placeholder="Input box"
                      onChange={(e) => setText5(e.target.value)}
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      This is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicInput3">
                    <Form.Label className="text-dark">
                      Label{" "}
                      <small className="font-weight-bold text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      isValid={text6 !== ""}
                      isInvalid={text6 === ""}
                      value={text6}
                      placeholder="Input box"
                      onChange={(e) => setText6(e.target.value)}
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      This is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicInput4">
                    <Form.Label className="text-dark">
                      Text area{" "}
                      <small className="font-weight-bold text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Text area"
                      isValid={text7 !== ""}
                      isInvalid={text7 === ""}
                      value={text7}
                      onChange={(e) => setText7(e.target.value)}
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      This is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicInput5">
                    <Form.Label className="text-dark">
                      Text area{" "}
                      <small className="font-weight-bold text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Text area"
                      isValid={text8 !== ""}
                      isInvalid={text8 === ""}
                      value={text8}
                      onChange={(e) => setText8(e.target.value)}
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      This is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicInput2">
  <Form.Label className="text-dark">
    Label <small className="text-danger">*</small>
  </Form.Label>
  <Form.Control
    isValid={text5 !== ""}
    isInvalid={text5 === ""}
    value={text5}
    placeholder="Input box"
    onChange={e => setText5(e.target.value)}
  />
  <Form.Control.Feedback type="valid">
    Looks good!
  </Form.Control.Feedback>
  <Form.Control.Feedback type="invalid">
    This is required.
  </Form.Control.Feedback>
</Form.Group>

<Form.Group controlId="formBasicInput3">
  <Form.Label className="text-dark">
    Label{" "}
    <small className="font-weight-bold text-danger">*</small>
  </Form.Label>
  <Form.Control
    isValid={text6 !== ""}
    isInvalid={text6 === ""}
    value={text6}
    placeholder="Input box"
    onChange={e => setText6(e.target.value)}
  />
  <Form.Control.Feedback type="valid">
    Looks good!
  </Form.Control.Feedback>
  <Form.Control.Feedback type="invalid">
    This is required.
  </Form.Control.Feedback>
</Form.Group>

<Form.Group controlId="formBasicInput4">
  <Form.Label className="text-dark">
    Textarea{" "}
    <small className="font-weight-bold text-danger">*</small>
  </Form.Label>
  <Form.Control
    as="textarea"
    rows="3"
    placeholder="Text area"
    isValid={text7 !== ""}
    isInvalid={text7 === ""}
    value={text7}
    onChange={e => setText7(e.target.value)}
  />
  <Form.Control.Feedback type="valid">
    Looks good!
  </Form.Control.Feedback>
  <Form.Control.Feedback type="invalid">
    This is required.
  </Form.Control.Feedback>
</Form.Group>

<Form.Group controlId="formBasicInput5">
  <Form.Label className="text-dark">
    Textarea{" "}
    <small className="font-weight-bold text-danger">*</small>
  </Form.Label>
  <Form.Control
    as="textarea"
    rows="3"
    placeholder="Text area"
    isValid={text8 !== ""}
    isInvalid={text8 === ""}
    value={text8}
    onChange={e => setText8(e.target.value)}
  />
  <Form.Control.Feedback type="valid">
    Looks good!
  </Form.Control.Feedback>
  <Form.Control.Feedback type="invalid">
    This is required.
  </Form.Control.Feedback>
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* checkboxes */}
      <StyleGuideSection
        title="Multi-Item TextArea"
        description="Designed to appear, and largely behave, as if it is a simple text area input,
        this actually a single input designed to accept values, add to an array, and display that
        array in pill form 'inside the text area'.  This component can be called via Form or
        directly from the source MultiItemTextArea.jsx file."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.MultiItemTextArea
                    array={MultiItemTextAreaArray}
                    setArray={setMultiItemTextAreaArray}
                    arrayItemValidator={validatePhoneNumber}
                    keyStrokeValidator={NumberUtil.isNumber}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`
import NumberUtil from "src/utils/NumberUtil";
import { validatePhoneNumber } from "src/utils/phoneValidator";

const [MultiItemTextAreaArray, setMultiItemTextAreaArray] = useState([]);

<Row>
  <Col lg={6}>
    <Form.MultiItemTextArea 
      array={MultiItemTextAreaArray}
      setArray={setMultiItemTextAreaArray}
      arrayItemValidator={validatePhoneNumber}
      keyStrokeValidator={NumberUtil.isNumber}
    />
  </Col>
</Row>`}
        />
      </StyleGuideSection>

      {/* checkboxes */}
      <StyleGuideSection
        title="Checkboxes"
        description="For even more customization and cross browser consistency, use our completely custom checkbox element to replace the browser defaults."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicCheckbox1">
                    <Form.Check
                      custom
                      checked={checkbox1}
                      onChange={() => setCheckbox1(!checkbox1)}
                      type="checkbox"
                      label="Custom checkbox"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicCheckbox2">
                    <Form.Check
                      custom
                      checked={checkbox2}
                      onChange={() => setCheckbox2(!checkbox2)}
                      type="checkbox"
                      label="Custom checkbox (checked)"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicCheckbox3">
                    <Form.Check
                      custom
                      disabled
                      checked={checkbox3}
                      onChange={() => setCheckbox3(!checkbox3)}
                      type="checkbox"
                      label="Custom checkbox (disabled)"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicCheckbox4">
                    <Form.Check
                      custom
                      disabled
                      checked={checkbox4}
                      onChange={() => setCheckbox4(!checkbox4)}
                      type="checkbox"
                      label="Custom checkbox (checked disabled)"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicCheckbox1">
  <Form.Check
    custom
    checked={checkbox1}
    onChange={() => setCheckbox1(!checkbox1)}
    type="checkbox"
    label="Custom checkbox"
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicCheckbox2">
  <Form.Check
    custom
    checked={checkbox2}
    onChange={() => setCheckbox2(!checkbox2)}
    type="checkbox"
    label="Custom checkbox (checked)"
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicCheckbox3">
  <Form.Check
    custom
    disabled
    checked={checkbox3}
    onChange={() => setCheckbox3(!checkbox3)}
    type="checkbox"
    label="Custom checkbox (disabled)"
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicCheckbox4">
  <Form.Check
    custom
    disabled
    checked={checkbox4}
    onChange={() => setCheckbox4(!checkbox4)}
    type="checkbox"
    label="Custom checkbox (checked disabled)"
    className="text-dark"
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* radio */}
      <StyleGuideSection
        title="Radios"
        description="For even more customization and cross browser consistency, use our completely custom radio element to replace the browser defaults."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicRadio1">
                    <Form.Check
                      custom
                      checked={radio1}
                      onChange={(e) => setRadio1(!radio1)}
                      type="radio"
                      label="Custom radio"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicRadio2">
                    <Form.Check
                      custom
                      checked={!radio1}
                      onChange={() => setRadio1(!radio1)}
                      type="radio"
                      label="Custom radio"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicRadio3">
                    <Form.Check
                      custom
                      disabled
                      checked={radio2}
                      onChange={(e) => setRadio2(!radio2)}
                      type="radio"
                      label="Custom radio (disabled)"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicRadio4">
                    <Form.Check
                      custom
                      disabled
                      checked={!radio2}
                      onChange={() => setRadio2(!radio2)}
                      type="radio"
                      label="Custom radio (disabled)"
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicRadio1">
  <Form.Check
    custom
    checked={radio1}
    onChange={e => setRadio1(!radio1)}
    type="radio"
    label="Custom radio"
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicRadio2">
  <Form.Check
    custom
    checked={!radio1}
    onChange={() => setRadio1(!radio1)}
    type="radio"
    label="Custom radio"
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicRadio3">
  <Form.Check
    custom
    disabled
    checked={radio2}
    onChange={e => setRadio2(!radio2)}
    type="radio"
    label="Custom radio (disabled)"
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicRadio4">
  <Form.Check
    custom
    disabled
    checked={!radio2}
    onChange={() => setRadio2(!radio2)}
    type="radio"
    label="Custom radio (disabled)"
    className="text-dark"
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Switches */}
      <StyleGuideSection
        title="Switches"
        description="A switch has the markup of a custom checkbox which renders a toggle switch. Switches also support disabled attribute."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicsSwitch1">
                    <Form.Check
                      custom
                      checked={switch1}
                      onChange={() => setSwitch1(!switch1)}
                      type="switch"
                      label={
                        <span className="ml-2">Toggle this switch element</span>
                      }
                      className="text-dark custom-switch-lg"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicsSwitch2">
                    <Form.Check
                      custom
                      checked={switch2}
                      onChange={() => setSwitch2(!switch2)}
                      type="switch"
                      label={
                        <span className="ml-2">Toggle this switch element</span>
                      }
                      className="text-dark custom-switch-lg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicsSwitch3">
                    <Form.Check
                      custom
                      disabled
                      checked={switch3}
                      onChange={() => setSwitch3(!switch3)}
                      type="switch"
                      label={
                        <span className="ml-2">Disabled switch element</span>
                      }
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicsSwitch4">
                    <Form.Check
                      custom
                      disabled
                      checked={switch4}
                      onChange={() => setSwitch4(!switch4)}
                      type="switch"
                      label={
                        <span className="ml-2">Disabled switch element</span>
                      }
                      className="text-dark"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicsSwitch1">
  <Form.Check
    custom
    checked={switch1}
    onChange={() => setSwitch1(!switch1)}
    type="switch"
    label={<span className="ml-2">Toggle this switch element</span>}
    className="text-dark custom-switch-lg"
  />
</Form.Group>

<Form.Group controlId="formBasicsSwitch2">
  <Form.Check
    custom
    checked={switch2}
    onChange={() => setSwitch2(!switch2)}
    type="switch"
    label={<span className="ml-2">Toggle this switch element</span>}
    className="text-dark custom-switch-lg"
  />
</Form.Group>

<Form.Group controlId="formBasicsSwitch3">
  <Form.Check
    custom
    disabled
    checked={switch3}
    onChange={() => setSwitch3(!switch3)}
    type="switch"
    label={<span className="ml-2">Disabled switch element</span>}
    className="text-dark"
  />
</Form.Group>

<Form.Group controlId="formBasicsSwitch4">
  <Form.Check
    custom
    disabled
    checked={switch4}
    onChange={() => setSwitch4(!switch4)}
    type="switch"
    label={<span className="ml-2">Disabled switch element</span>}
    className="text-dark"
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Select Menu */}
      <StyleGuideSection
        title="Select Menu"
        description="Custom select menus are styled to appear the same across browsers."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicSelect">
                    <Form.Label className="text-dark">Normal</Form.Label>
                    <Form.Select
                      value={select1}
                      onChange={setSelect1}
                      placeholder="Open this select menu"
                      options={[
                        { value: 0, label: "Open this select menu" },
                        { value: 1, label: "One" },
                        { value: 2, label: "Two" },
                        { value: 3, label: "Three" },
                      ]}
                      menuBuffer={30}
                      searchable={false}
                      clearable={false}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicSelect">
  <Form.Label className="text-dark">Normal</Form.Label>
  <Form.Select
    value={select1}
    onChange={setSelect1}
    placeholder="Open this select menu"
    options={[
      { value: 0, label: "Open this select menu" },
      { value: 1, label: "One" },
      { value: 2, label: "Two" },
      { value: 3, label: "Three" }
    ]}
    menuBuffer={30}
    searchable={false}
    clearable={false}
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Grouped-Select Menu */}
      <StyleGuideSection
        title="Grouped-Select Menu"
        description="Grouped select menus are just like custom select menus that come with
        styling to display disabled options as visual parents, presenting options in a
        visually grouped fashion."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicSelect">
                    <Form.Label className="text-dark">Grouped</Form.Label>
                    <Form.GroupedSelect
                      clearable={false}
                      onChange={setSelect1}
                      options={[
                        { value: "group1", label: "Group 1", disabled: true },
                        { value: "first", label: "First" },
                        { value: "second", label: "Second" },
                        { value: "third", label: "Third" },
                        { value: "group2", label: "Group 2", disabled: true },
                        { value: "more", label: "More" },
                        { value: "evenMore", label: "Even More" },
                        { value: "MoreStill", label: "More Still" },
                      ]}
                      placeholder="Open this select menu"
                      searchable={false}
                      value={select1}
                      isOptionDisabled={(option) => option.disabled}
                      inputProps={{ readOnly: true }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicSelect">
  <Form.Label className="text-dark">Grouped</Form.Label>
  <Form.GroupedSelect
    clearable={false}
    onChange={setSelect1}
    options={[
      { value: "group1", label: "Group 1", disabled: true },
      { value: "first", label: "First" },
      { value: "second", label: "Second" },
      { value: "third", label: "Third" },
      { value: "group2", label: "Group 2", disabled: true },
      { value: "more", label: "More" },
      { value: "evenMore", label: "Even More" },
      { value: "MoreStill", label: "More Still" }
    ]}
    placeholder="Open this select menu"
    searchable={false}
    value={select1}
    isOptionDisabled={(option) => option.disabled}
    inputProps={{ readOnly: true }}
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Multi-Select Menu */}
      <StyleGuideSection
        title="Multi-Select Menu"
        description="Multi select menus are use checkboxes and accept arrays of objects.  
        See setSelectMulti() for basic example to unselect options upon selecting 'All'."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicSelect">
                    <Form.Label className="text-dark">Multi</Form.Label>
                    <Form.MultiSelect
                      clearable={false}
                      onChange={(items) => setSelectMulti(items)}
                      options={[
                        { value: "all", label: "All" },
                        { value: "first", label: "First" },
                        { value: "second", label: "Second" },
                        { value: "third", label: "Third" },
                      ]}
                      placeholder="Open this select menu"
                      searchable={false}
                      value={multiSelect}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

const [multiSelect, setMultiSelect] = useState([]);
const setSelectMulti = (items) => {
  let newItems = [...items];
  if (items.length > 1 && items.slice(-1)[0].value === "all") {
    newItems = [items.pop()];
  } else if (items.length > 1) {
    newItems = items.filter(item => item.value !== "all");
  }
  setMultiSelect([ ...newItems ]);
}

<Form.Group controlId="formBasicSelect">
  <Form.Label className="text-dark">Multi</Form.Label>
  <Form.MultiSelect
    clearable={false}
    onChange={(items) => setSelectMulti(items)}
    options={[
      { value: "all", label: "All" },
      { value: "first", label: "First" },
      { value: "second", label: "Second" },
      { value: "third", label: "Third" }
    ]}
    placeholder="Open this select menu"
    searchable={false}
    value={multiSelect}
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Multi-Select Filter Menu */}
      <StyleGuideSection
        title="Multi-Select Filter Menu"
        description="Multi Select Filter menus attempts to solve the problem of handling multi
        selection with large data sets, common in dealing with location.  This menu item provides
        filtering to make it easy to find options, ability to copy/paste values, advanced value 
        display functionality that lean on pagination and the Read More Notes component.
        This component diviates from prior multi select design by removing check boxes.
        Note Multi Select had a `searchable` parameter that allows filtering."
      >
        <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Label className="text-dark">
                    Multi Select with Filter
                  </Form.Label>
                  <Form.Group controlId="formBasicSelect">
                    <Form.MultiSelectFilter
                      clearable={true}
                      name={multiSelectFilterName}
                      onChange={(items) => {
                        setSelectMultiFilter(items);
                      }}
                      options={sampleZipCodeArray}
                      placeholder="Open this select menu"
                      readMoreContent={readMoreContent(multiSelectFilter)}
                      value={multiSelectFilter}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Label className="text-dark">
                    Multi Select with Filter
                  </Form.Label>
                  <Form.Group controlId="formBasicSelectPaginated">
                    <Form.MultiSelectFilter
                      clearable={true}
                      minWidth={290}
                      name={multiSelectFilterName}
                      onChange={(items) => {
                        setSelectMultiFilterPaginated(items);
                      }}
                      onExited={() => setCurrentPage(0)}
                      options={sampleZipCodeArray}
                      placeholder="Open this select menu"
                      readMoreContent={readMoreContentPaginated(
                        multiSelectFilterPaginated,
                      )}
                      value={multiSelectFilterPaginated}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Label className="text-dark">
  Multi Select with Filter
</Form.Label>
<Form.Group controlId="formBasicSelect">
  <Form.MultiSelectFilter
    clearable={true}
    name="needed-for-key"
    onChange={(items) => {
      setSelectMultiFilter(items);
    }}
    options={sampleZipCodeArray}
    placeholder="Open this select menu"
    value={multiSelectFilter}
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Multi Range */}
      <StyleGuideSection
        title="Multi Range"
        description="Multi Range allows us to set a range, and assign a min and max value within the set range. 
        The library does not support style customizations, so if needed, set a parent container, and override 
        existing styles per class. This component is ported from the members repo."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicMultiRange">
                    <Form.Label className="text-dark">Example range</Form.Label>
                    <Form.MultiRange
                      min={0}
                      max={100}
                      value={range2}
                      onChange={(value) => setRange2(value)}
                      onChangeComplete={(value) =>
                        console.log("onChangeComplete value: ", value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicMultiRange">
  <Form.Label className="text-dark">Example range</Form.Label>
  <Form.MultiRange
    min={0}
    max={100}
    value={range2}
    onChange={(value) => setRange2(value)}
    onChangeComplete={(value) => console.log("onChangeComplete value: ", value)}
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Range */}
      <StyleGuideSection
        title="Range"
        description="Create custom range controls. The track (the background) and thumb (the value) are both styled to appear the same across browsers."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <Form.Group controlId="formBasicRange">
                    <Form.Label className="text-dark">Example range</Form.Label>
                    <Form.Range
                      min={0}
                      max={100}
                      value={range1}
                      onChange={setRange1}
                      className="mt-2"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicRange">
  <Form.Label className="text-dark">Example range</Form.Label>
  <Form.Range
    min={0}
    max={100}
    value={range1}
    onChange={setRange1}
    className="mt-2"
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Datepicker */}
      <StyleGuideSection
        title="Datepicker"
        description="The datepicker is tied to a standard form input field. Focus on the input (click, or use the tab key) to open an interactive calendar in a small overlay."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicDatePicker">
                    <Form.Label className="text-dark">Date</Form.Label>
                    <Form.DatePicker
                      placeholderText="Choose date"
                      minDate={new Date("01/01/2020")}
                      maxDate={new Date("01/01/2025")}
                      selected={date1}
                      onChange={setDate1}
                      useWeekdaysShort={false}
                      locale="en-gb"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicDatePicker">
  <Form.Label className="text-dark">Date</Form.Label>
  <Form.DatePicker
    placeholderText="Choose date"
    minDate={new Date("01/01/2020")}
    maxDate={new Date("01/01/2025")}
    selected={date1}
    onChange={setDate1}
    useWeekdaysShort={false}
    locale="en-gb"
  />
</Form.Group>`}
        />
      </StyleGuideSection>

      {/* Timepicker */}
      <StyleGuideSection
        title="Timepicker"
        description="The timepicker is tied to a standard form input field. Focus on the input (click, or use the tab key) to open an interactive dropdown in a small overlay."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formBasicTimePicker">
                    <Form.Label className="text-dark">Time</Form.Label>
                    <Form.TimePicker
                      placeholder="Choose time"
                      showSecond={false}
                      value={time1}
                      onChange={setTime1}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Form from "src/shared/styleguide/form/Form";

<Form.Group controlId="formBasicTimePicker">
  <Form.Label className="text-dark">Time</Form.Label>
  <Form.TimePicker
    placeholder="Choose time"
    showSecond={false}
    value={time1}
    onChange={setTime1}
  />
</Form.Group>`}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default FormStyleGuide;
