import AllReview from "../../../img/star (1).svg";
import pending from "../../../img/time.svg";
import positive from "../../../styles/assets/review-settings-icon/add.svg";
import negative from "../../../styles/assets/review-settings-icon/negative.svg";
import featuredIcon from "../../../styles/assets/review-settings-icon/validation.svg";
import thumbsUp from "../../../styles/assets/review-settings-icon/thumbs-up-hand-symbol.svg";
import thumbsDown from "../../../styles/assets/review-settings-icon/thumbs-down-hand-symbol.svg";

export const SORT_BY = [
    {label: 'Newest First', value:'newest_first'},
    {label: 'Highest Rating', value:'highest_rating'},
    {label: 'Lowest Rating', value:'lowest_rating'}
];

 export const FILTER_BY = [
    {label: 'ALL Sources', value:'all_sources'},
    {label: 'Email', value:'email'},
    {label: 'Review me', value:'review_me'},
    {label: 'Customer Calls', value:'customer_calls'},
    {label: 'Handwritten', value:'handwritten'},
    {label: 'Custom Invites', value:'custom_invites'}
 ];
 export const PLATFORM = [
    {label: 'ALL Platforms',  value:'all', disabled: false},
    {label: 'Customer Lobby', value:'customerlobby', disabled: false},
    {label: 'Google', value:'google', disabled: true}
 ];
export const DATE_RANGE = [
    {label: 'Last 7 days',  value:7},
    {label:'Last 30 days',  value:30},
    {label: 'Last 60 days', value:60},
    {label: 'Last 90 days', value:90},
    {label: 'Last 365 days',value:365},
];

export const VIEW_BY = [
    {label: 'All Reviews', count: 0, value:'All Reviews',icon:AllReview},
    {label: 'Pending',    count: 0, value:'Pending',icon:pending},
    {label: 'Positive',   count: 0, value:'Positive',icon:positive},
    {label: 'Negative',   count: 0, value:'Negative',icon:negative},
    {label: 'Featured',   count: 0, value:'Featured',icon:featuredIcon},
];

export const VIEW_BY_TEMPLATE = {
  allReviews: {label: 'All Reviews', count: 0, value:'All Reviews', icon:AllReview},
  pending: {label: 'Pending',    count: 30, value:'Pending', icon:pending},
  positive : {label: 'Positive',   count: 100, value:'Positive', icon:positive},
  negative: {label: 'Negative',   count: 46, value:'Negative', icon:negative},
  featured: {label: 'Featured',   count: 15, value:'Featured', icon:featuredIcon}
}

export const PRIVATE_FEEDBACK_VIEW_BY = [
    {label: 'All Feedback', count: 56, value:'all_feedback',icon:AllReview},
    {label: 'General Feedback', count: 26, value:'general_feedback',icon:thumbsUp},
    {label: 'Unhappy Feedback', count: 30, value:'unhappy_feedback',icon:thumbsDown},
]
