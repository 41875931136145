import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import img1 from "src/assets/images/settings/google_search_results.png";
import img2 from "src/assets/images/settings/google_address.png";

const GoogleSmartInviteHelp = ({ showModal, setShowModal }) => {
  // render
  return (
    <Modal
      zIndex={2999}
      modalSize="xl"
      onHide={() => setShowModal(false)}
      body={
        <div className="pa-2">
          <h3 className="text-dark">Step 1</h3>
          <div>
            Go to{" "}
            <a
              href="https://google.com"
              className="sg-text-info"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://google.com
            </a>
            . Enter your company name, city, and state in the search box. Click
            the "Google reviews" link (see below).
          </div>
          <img
            className="my-4 img-fluid"
            src={img1}
            alt="Google search"
          />
          <h3 className="text-dark">Step 2</h3>
          <div>Copy the link address (see below).</div>
          <img
            className="my-4 img-fluid"
            src={img2}
            alt="Google search"
          />
          <h3 className="text-dark">Step 3</h3>
          <div>Paste the link in the Google Page Address box.</div>
        </div>
      }
      footer={
        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      }
      show={showModal}
      handleClose={() => {}}
    />
  );
};

export default GoogleSmartInviteHelp;
