import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CompanyInformation from "./CompanyInformation";
import ContactDetails from "./ContactDetails";
import BusinessHours from "./BusinessHours";
import TabsComponent from "src/shared/tabs/TabsComponent";
import {
  PROFILE_SETTINGS_TAB,
  PROFILE_SETTINGS_ROUTES
} from "src/shared/Constants";
import "./CompanySettings.scss";

const ProfileSettings = ({
  client,
  history,
  reload = false,
  selectedTab: selectedTabProp
}) => {
  // state
  const tabs = [
    {
      eventKey: PROFILE_SETTINGS_TAB.companyInformation,
      title: "Company Information",
      component: <CompanyInformation client={client} />
    },
    {
      eventKey: PROFILE_SETTINGS_TAB.contactDetails,
      title: "Contact Details",
      component: <ContactDetails client={client} />
    },
    {
      eventKey: PROFILE_SETTINGS_TAB.businessHours,
      title: "Business Hours",
      component: <BusinessHours history={history} client={client} />
    }
  ];
  const [selectedTab, setSelectedTab] = useState(
    tabs.find(tab => tab.eventKey === selectedTabProp) || tabs[0]
  );

  // methods
  const handleRoute = tabIndex => {
    const tab = tabs[tabIndex] || tabs[0];
    const { eventKey } = tab;
    switch (eventKey) {
      case PROFILE_SETTINGS_TAB.companyInformation:
        history.push(PROFILE_SETTINGS_ROUTES.companyInformation);
        break;
      case PROFILE_SETTINGS_TAB.contactDetails:
        history.push(PROFILE_SETTINGS_ROUTES.contactDetails);
        break;
      case PROFILE_SETTINGS_TAB.businessHours:
        history.push(PROFILE_SETTINGS_ROUTES.businessHours);
        break;
      default:
        break;
    }
    setSelectedTab(tab);
  };

  // effects
  useEffect(() => {
    const tab = tabs.find(tab => tab.eventKey === selectedTabProp) || tabs[0];
    setSelectedTab(tab);
  }, [reload]);

  // render
  return (
    <TabsComponent
      selectedTab={selectedTab.eventKey}
      onTabChange={handleRoute}
      className="tabs"
      tabs={tabs}
    />
  );
};

export default withRouter(ProfileSettings);
