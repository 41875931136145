import React from "react";
import Form from "./Form";
import PropTypes from "prop-types";
import "./SearchInput.scss";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.513"
      height="19.176"
      viewBox="0 0 18.513 19.176"
    >
      <path
        d="M19.216 17.466l-4.564-4.747a7.739 7.739 0 1 0-5.926 2.765 7.659 7.659 0 0 0 4.436-1.4l4.6 4.783a1.01 1.01 0 1 0 1.456-1.4zM8.726 2.02A5.722 5.722 0 1 1 3 7.742 5.729 5.729 0 0 1 8.726 2.02z"
        transform="translate(-.984)"
      />
    </svg>
  );
};

const ClearIcon = ({ size = 22.627 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.627"
      height="22.627"
      viewBox="0 0 22.627 22.627"
      style={{
        minWidth: size,
        minHeight: size,
      }}
    >
      <path
        d="M13.657 2.343A8 8 0 0 0 2.343 13.657 8 8 0 0 0 13.657 2.343zM8 14.75A6.75 6.75 0 1 1 14.75 8 6.758 6.758 0 0 1 8 14.75zm.625-7.376h2.813v1.25H8.625v2.813h-1.25V8.624H4.562v-1.25h2.813V4.562h1.25zm0 0"
        transform="rotate(-45 13.657 5.657)"
      />
    </svg>
  );
};

const SearchInput = ({
  placeholder,
  onChange,
  onClear = () => {},
  value,
  maxLength = undefined,
  onKeyUp = () => {},
  clearIconAlwaysShown = false,
  clearIconSize = null,
}) => {
  // render
  return (
    <div className="sg-search-control d-flex">
      <div className="sg-search-icon">
        <SearchIcon />
      </div>
      <Form.Control
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        className="sg-search-input"
      />
      {(value && value.length > 0) || clearIconAlwaysShown ? (
        <div
          onClick={() => {
            onClear();
            onChange({
              target: { value: "" },
            });
          }}
          className="cursor-pointer sg-clear-icon"
        >
          <ClearIcon size={clearIconSize} />
        </div>
      ) : null}
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onKeyUp: PropTypes.func,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  clearIconAlwaysShown: PropTypes.bool,
  clearIconSize: PropTypes.number,
};

export default SearchInput;
