import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as config from "src/shared/Config";
import Modal from "src/shared/styleguide/modal/Modal";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import InfoIcon from "src/assets/images/common/info-icon-large.svg";
import SegmentNameInput from "src/components/customers_segments/segments/segment/SegmentNameInput";
import { nameValidator } from "./SegmentUtils";

import "./SegmentModal.scss";

const SegmentIsDefaultModal = ({
  createFilter,
  filterName,
  filterOriginalName,
  filtersList,
  formik,
  segmentNameChangeHandler,
  setShowModal,
  showModal,
}) => {
  const history = useHistory();

  // state
  const [isValid, setIsValid] = React.useState(false);

  // methods
  const handleCancel = () => {
    setShowModal(false);
    history.push(`/${config.COMPANIES_TOKEN}/segments/`);
  };

  useEffect(() => {
    setIsValid(nameValidator(filtersList, filterName, filterOriginalName));
  }, [filterName]);

  // render
  return (
    <React.Fragment>
      <Modal
        className="segment-modal-container"
        modalSize="md"
        onHide={() => {
          setShowModal(false);
        }}
        body={
          <div className="modal-body-content">
            <img src={InfoIcon} alt="Card Style Guide Image" />
            <p className="segment-in-use-paragraph">
              You are editing a default segment; please create a new custom
              segment in order to be able to save the changes{" "}
            </p>    
            <Form>
              <div className="segment-name-container">
                <SegmentNameInput
                  classNames="segment-name-input"
                  filtersList={filtersList}
                  formik={formik}
                  isValid={isValid}
                  segmentName={filterName}
                  segmentNameChangeHandler={segmentNameChangeHandler}
                  setIsValid={setIsValid}
                />
              </div>
            </Form>
            <div className="button-container segment-in-use-modal-button-container">
              <Button
                variant="outline-secondary"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                onClick={() => createFilter()}
                variant="primary"
              >
                Create
              </Button>
            </div>
          </div>
        }
        show={showModal}
      />
    </React.Fragment>
  );
};

export default SegmentIsDefaultModal;
