import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import "./TypographyStyleGuide.scss";

const ButtonStyleGuide = () => {
  useDocumentTitle("Style Guide - Typography");

  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Typography</h3>
      <div>
        A document, with examples, for typography that includes examples on
        heading, body text, list and more.
      </div>
      <hr className="thick" />
      <Row>
        <Col className="font-preview">
          <Card>
            <Card.Body>
              <h1 className="font-weight-bold text-center">Aa</h1>
              <hr />
              <h5 className="text-center">Proxima Nova</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={9}>
          <h6 className="font-weight-bold">Proxima Nova</h6>
          <div className="text-dark h2 tracking-widest py-2">
            A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
          </div>
          <div className="text-dark h2 tracking-widest py-2">
            a b c d e f g h i j k l m n o p q r s t u v w x y z
          </div>
          <div className="text-dark h2 tracking-widest py-2">
            0 1 2 3 4 5 6 7 8 9
          </div>
          <Row className="py-2 mt-2">
            <Col md={12}>
              <h1>
                <span className="pr-4">H1</span>
                <span className="text-dark">
                  The spectacle before us was indeed sublime
                </span>
                <span className="pl-2">26px</span>
              </h1>
            </Col>
          </Row>
          <Row className="py-2 mt-2">
            <Col md={12}>
              <h2>
                <span className="pr-4">H2</span>
                <span className="text-dark">
                  The spectacle before us was indeed sublime
                </span>
                <span className="pl-2">22px</span>
              </h2>
            </Col>
          </Row>
          <Row className="py-2 mt-2">
            <Col md={12}>
              <h3>
                <span className="pr-4">H3</span>
                <span className="text-dark">
                  The spectacle before us was indeed sublime
                </span>
                <span className="pl-2">18px</span>
              </h3>
            </Col>
          </Row>
          <Row className="py-2 mt-2">
            <Col md={12}>
              <h4>
                <span className="pr-4">H4</span>
                <span className="text-dark">
                  The spectacle before us was indeed sublime
                </span>
                <span className="pl-2">15px</span>
              </h4>
            </Col>
          </Row>
          <Row className="py-2 mt-2">
            <Col md={12}>
              <h5>
                <span className="pr-4">H5</span>
                <span className="text-dark">
                  The spectacle before us was indeed sublime
                </span>
                <span className="pl-2">14px</span>
              </h5>
            </Col>
          </Row>
          <h6 className=" font-weight-bold my-4">HEADING WITH SUBHEADER</h6>
          <h2 className="text-dark">
            The spectacle before us was indeed sublime.
          </h2>
          <h3 className="font-weight-bold">Subheader</h3>
          <h4 className="my-4">PARAGRAPH</h4>
          <p className="text-dark">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            rhoncus urna lorem, at molestie magna aliquam id. Sed et bibendum
            dolor, sit amet pellentesque ex. Donec sed tempor ex, et egestas
            dui. Vestibulum rutrum ipsum a facilisis finibus. Aenean luctus sit
            amet ante non maximus. Aenean hendrerit augue nec ligula mollis
            eleifend. Vestibulum elit ligula, faucibus vitae ipsum sit amet,
            lobortis molestie eros. Suspendisse molestie elementum varius.
          </p>
          <p className="text-muted">This is an example of muted text.</p>
          <p className="sg-text-primary">This is an example of primary text.</p>
          <p className="sg-text-success">This is an example of success text.</p>
          <p className="sg-text-info">This is an example of info text.</p>
          <p className="sg-text-warning">This is an example of warning text.</p>
          <p className="sg-text-danger">This is an example of danger text.</p>
          <p className="small">
            This is an example of small text.
          </p>
          <p>
            Use the mark tag to <mark className="sg-text-highlight">highlight</mark> text.
          </p>
          <p>
            Use the b tag to <b className="text-dark">bold</b> text, or use strong tag to{" "}
            <strong className="text-dark">emphasize</strong> text.
          </p>
          <p>
            <s>This line of text is meant to be treated as deleted text.</s>
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ButtonStyleGuide;
