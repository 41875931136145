import React from "react";
import "./NoDataFoundComponent.scss";
import rotate from "../../styles/assets/rotate-tablet_xs.jpg"

const PotraitOrientation = ({heading, message, dateRange}) => {
    return (
        <div id="orientationNotSupported">
            <div className="d-flex justify-content-center no-data-lower-container">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={rotate} alt="Icon"></img>
                </div>
            </div>
            <div className="message">
                <div className="d-flex justify-content-center">
                    <div>
                        <div className="row d-flex justify-content-center">
                            <div className="no-data">{heading}</div>{" "}
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="no-data-subheading">{message}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PotraitOrientation;
