import React from "react";
import PropTypes from "prop-types";

const ReviewLink = ({ href, isVisible = true, text, onClick = undefined }) => {
  return isVisible ? (
    <a
      onClick={onClick}
      href={href}
      className="review-results-header-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  ) : null;
};

ReviewLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ReviewLink;
