import React from "react";
import UrlUtil from "src/utils/UrlUtil";
import Form from "src/shared/styleguide/form/Form";
import { InputGroup } from "react-bootstrap";
import Table from "src/shared/styleguide/table/Table";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import PropTypes from "prop-types";
import { addToast } from "src/utils/ToastUtil";

const FACE_BASE_URL = "https://facebook.com/";

const FacebookProfileInformation = ({ facebookProfiles, setStatus }) => {
  // variables
  const perPage = 10;

  // state
  const [profiles, setProfiles] = React.useState([]);
  const [tableBody, setTableBody] = React.useState([[]]);
  const [pageCount, setPageCount] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [statuses, setStatuses] = React.useState({});

  // methods
  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
  };
  const updateStatus = (profileId, status) => {
    const thisStatuses = { ...statuses };
    thisStatuses[profileId] = status;
    setStatuses(thisStatuses);
    setStatus({
      variables: {
        id: profileId,
        status: status ? "enabled" : "disabled",
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Social Network page has been updated.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Social Network page was not updated.",
        });
      });
  };
  const getStatus = (profileId, status) => {
    if (Object.prototype.hasOwnProperty.call(statuses, profileId)) {
      return statuses[profileId];
    }
    return status === "enabled";
  };

  // effects
  React.useEffect(() => {
    const thisSearchTerm = searchTerm.toLowerCase();
    const thisProfiles = facebookProfiles.filter(
      (profile) =>
        !searchTerm ||
        profile.screenName.toLowerCase().includes(thisSearchTerm),
    );
    setProfiles(thisProfiles);
    const thisPageCount = Math.ceil(thisProfiles.length / perPage);
    setPageCount(thisPageCount);
    if (pageCount !== thisPageCount) {
      setCurrentPage(0);
    }
  }, [searchTerm]);

  React.useEffect(() => {
    setTableBody(
      profiles
        .slice(currentPage * perPage, (currentPage + 1) * perPage)
        .map((profile) => [
          {
            node: (
              <a
                href={UrlUtil.joinUrl(FACE_BASE_URL, profile.pageUid)}
                rel="noopener noreferrer"
                target="_blank"
                className="sg-text-info"
              >
                {profile.screenName}
              </a>
            ),
            props: {
              width: "84%",
            },
          },
          {
            props: {
              className: "text-center",
              style: {
                minWidth: 180,
              },
            },
            node: profile.pages[0].errorMessage ? (
              <PopupInfo
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#f42134"
                    viewBox="0 0 24 24"
                  >
                    <g style={{ opacity: 0.9 }}>
                      <g
                        style={{
                          fill: "transparent",
                          stroke: "#f42134",
                          strokeWidth: 2,
                        }}
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="12"
                          style={{ stroke: "none" }}
                        />
                        <circle
                          cx="12"
                          cy="12"
                          r="11"
                          style={{ fill: "none" }}
                        />
                      </g>
                      <g transform="translate(10.941 4.885)">
                        <path
                          d="M224.216 179.8v7.134a1.379 1.379 0 0 0 1.376 1.376 1.428 1.428 0 0 0 1.376-1.376V179.8a1.376 1.376 0 1 0-2.751 0z"
                          transform="translate(-224.216 -178.421)"
                        />
                        <path
                          d="M225.592 360.921a1.379 1.379 0 0 0-1.376 1.376 1.379 1.379 0 0 0 1.376 1.376 1.428 1.428 0 0 0 1.376-1.376 1.388 1.388 0 0 0-1.376-1.376z"
                          transform="translate(-224.216 -348.909)"
                        />
                      </g>
                    </g>
                  </svg>
                }
                placement="left"
                message="Full permission has not been granted to publish to your Group or Page. You may need to go to your Facebook profile to Re-Authorize permissions."
                iconSize={16}
              />
            ) : (
              <Form.Group className="mb-0" controlId={`switch-${profile.id}`}>
                <Form.Check
                  custom
                  checked={getStatus(
                    profile.pages[0].id,
                    profile.pages[0].status,
                  )}
                  onChange={() => {
                    updateStatus(
                      profile.pages[0].id,
                      !getStatus(profile.pages[0].id, profile.pages[0].status),
                    );
                  }}
                  type="switch"
                  label={<React.Fragment />}
                  className="text-dark custom-switch-lg"
                />
              </Form.Group>
            ),
          },
        ]),
    );
  }, [profiles, statuses, currentPage]);

  // render
  return (
    <div>
      <div>
        <Form.Group
          className="mb-4 d-flex justify-content-end"
          controlId="search"
        >
          <InputGroup
            style={{
              width: 240,
              borderRadius: 4,
              border: "1px solid #ced4da",
            }}
          >
            <InputGroup.Prepend>
              <InputGroup.Text
                className="pr-0 border-0 bg-transparent"
                id="inputGroupPrepend"
              >
                <span
                  className="fa fa-search"
                  style={{
                    opacity: 0.5,
                  }}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              value={searchTerm}
              onChange={(evt) => setSearchTerm(evt.target.value)}
              className="border-0"
              placeholder="Search"
            />
          </InputGroup>
        </Form.Group>
      </div>
      <Table head={["PAGE NAME", "SYNDICATION STATUS"]} body={tableBody} />
      {pageCount > 1 ? (
        <PaginatorComponent
          pageCount={pageCount}
          pageChangeHandler={onPagination}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  );
};

FacebookProfileInformation.propTypes = {
  facebookProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default FacebookProfileInformation;
