
import { loadCookie, removeCookies }from './SessionHelper';
import { COOKIES, APPOLO_FETCH_POLICY, MEMBERS_URLS }from './Config';
import { gql } from "apollo-boost";
import { CACHE_AND_NETWORK } from "src/shared/Constants";

/**
 * Executes graphQL Query[GET] API
 * @param client - Client on which query will be executed
 * @param query - GraphQL query to be executed.
 * @param callback - Callback for response and error
 * @param variables - Variables to be sent for GraphQL.
 */
export function query(client, query, callback, variables = {}) {
  return  client.watchQuery({
    fetchPolicy: CACHE_AND_NETWORK,
    variables: variables,
    query: gql`${query}`,
    fetchPolicy: APPOLO_FETCH_POLICY,
    context: headers()
  }).subscribe({
    next: response => {
      callback(null, response);
    },
    error: (error, done) => {
      handleError(error);
      callback(error, null);
    }
  })
};

/**
 * Executes graphQL Mutation[POST, PUT] API
 * @param client - Client on which query will be executed
 * @param mutate - GraphQL query to be executed.
 * @param callback - Callback for response and error
 * @param variables - Variables to be sent for GraphQL.
 */
export function mutate(client, mutation, callback, variables = {}) {
  return  client.mutate({
    variables: variables,
    mutation: gql`${mutation}`,
    context: headers()
  }).then(response => {
    callback(null, response);
  }).catch(error => {
    handleError();
    callback(error, null);
  })
};

/**
 * Provide headers required for execution of query or mutation.
 * @returns {{headers: {AUTHORIZATION: *}}} -> Return json object of headers
 */
var headers = function () {
  return { headers: {AUTHORIZATION: loadCookie(COOKIES.AUTH_TOKEN)}}
};

/**
 * Handles the common errors.
 * @param error -> Error Object.
 */
var handleError =  function(error) {
  if(error && error.message) {
    if(error.message.includes('unauthorized')) {
      removeCookies();
      window.location.href = MEMBERS_URLS.login;
    }
  }
};
