import { gql } from "apollo-boost";

const REMOVE_CONTACT_FROM_FILTER = gql`
  mutation($contactId: ID!, $filterId: ID!) {
    removeContactFromFilter(contactId: $contactId, filterId: $filterId) {
      name
      id
    }
  }
`;

const ADD_CONTACT_TO_FILTER = gql`
  mutation($contactId: ID!, $filterId: ID!, $additionType: String) {
    addContactToFilter(
      contactId: $contactId
      filterId: $filterId
      additionType: $additionType
    ) {
      name
      id
    }
  }
`;

const MUTATION_UPDATE_QUICK_REPLIES = gql`
  mutation updateCompanyFeatureFlag(
    $companyId: ID!, 
    $component: String!,
    $status: Boolean!,
    $userId: ID!,
    $userType: String!,
    ) {
      updateCompanyFeatureFlag (
      companyId: $companyId
      component: $component
      status: $status
      userId: $userId
      userType: $userType
    )
  }
`;

const MUTATION_UPDATE_QUICK_REPLY = gql`
  mutation quickReply($id: ID!, $enabled: Boolean!) {
    updateQuickReplyTemplate(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`;

const MUTATION_UPDATE_MESSAGE_TEMPLATES = gql`
  mutation messageTemplates(
    $companyId: ID!, 
    $component: String!,
    $status: Boolean!,
    $userId: ID!,
    $userType: String!,
    ) {
        updateCompanyFeatureFlag (
          companyId: $companyId
          component: $component
          status: $status
          userId: $userId
          userType: $userType
        )
      }
`;

const MUTATION_UPDATE_MESSAGE_TEMPLATE = gql`
  mutation updateTextMessageTemplate(
    $id: ID!
    $enabled: Boolean
    $title: String
    $content: String
  ) {
    updateTextMessageTemplate(
      id: $id
      enabled: $enabled
      title: $title
      content: $content
    ) {
      id
      title
      enabled
      content
    }
  }
`;

const MUTATION_CREATE_MESSAGE_TEMPLATE = gql`
  mutation createTextMessageTemplate(
    $companyId: ID!
    $title: String!
    $content: String!
  ) {
    createTextMessageTemplate(
      companyId: $companyId
      title: $title
      content: $content
    ) {
      id
      title
      enabled
      content
      defaultTemplate {
        id
        title
      }
    }
  }
`;

const MUTATION_STAR_CONVERSATION = gql`
  mutation starConversatoin(
    $companyId: ID!
    $contactId: ID!
    $starred: Boolean!
  ) {
    starConversation(
      companyId: $companyId
      contactId: $contactId
      starred: $starred
    ) {
      contactId
      starred
    }
  }
`;

const MUTATION_DELETE_CONVERSATION = gql`
  mutation deleteConversation(
    $companyId: ID
    $contactId: ID
    $id: ID
    $status: String
  ) {
    deleteConversation(
      companyId: $companyId
      contactId: $contactId
      id: $id
      status: $status
    ) {
      companyId
      contactId
    }
  }
`;

const MUTATION_DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteTextMessageTemplate($id: ID!) {
    deleteTextMessageTemplate(id: $id)
  }
`;

const MUTATION_CREATE_TEXT_MESSAGE = gql`
  mutation createTextMessage(
    $companyId: ID
    $contactId: ID
    $sourcePhone: String
    $destinationPhone: String
    $content: String
    $status: String
    $viewed: Boolean
    $deleted: Boolean
    $category: String
    $reviewInvitation: Boolean
  ) {
    createTextMessage(
      companyId: $companyId
      contactId: $contactId
      sourcePhone: $sourcePhone
      destinationPhone: $destinationPhone
      content: $content
      status: $status
      viewed: $viewed
      deleted: $deleted
      category: $category
      reviewInvitation: $reviewInvitation
    ) {
      id
      companyId
      contactId
      sourcePhone
      destinationPhone
      content
      status
      viewed
      deleted
      category
      updatedAt
    }
  }
`;

const MUTATION_MARK_CONVERSATION_READ = gql`
  mutation markConversationRead($companyId: ID!, $contactId: ID!) {
    markConversationRead(companyId: $companyId, contactId: $contactId) {
      id
      viewed
    }
  }
`;

const MUTATION_MARK_CONVERSATION_UNREAD = gql`
  mutation markConversationUnread($contactId: ID!) {
    markConversationUnread(contactId: $contactId) {
      id
      companyId
      contactId
      sourcePhone
      destinationPhone
      content
      status
      viewed
      deleted
      category
      updatedAt
    }
  }
`;

export {
  REMOVE_CONTACT_FROM_FILTER,
  ADD_CONTACT_TO_FILTER,
  MUTATION_UPDATE_QUICK_REPLIES,
  MUTATION_UPDATE_QUICK_REPLY,
  MUTATION_UPDATE_MESSAGE_TEMPLATES,
  MUTATION_CREATE_MESSAGE_TEMPLATE,
  MUTATION_UPDATE_MESSAGE_TEMPLATE,
  MUTATION_STAR_CONVERSATION,
  MUTATION_DELETE_CONVERSATION,
  MUTATION_DELETE_MESSAGE_TEMPLATE,
  MUTATION_CREATE_TEXT_MESSAGE,
  MUTATION_MARK_CONVERSATION_READ,
  MUTATION_MARK_CONVERSATION_UNREAD,
};
