import React from "react";
import PropTypes from "prop-types";
import BlockUi from "react-block-ui";
import { COLOR_PALETTE } from "src/shared/Constants";
import Slider from "rc-slider";
import NumberUtil from "src/utils/NumberUtil.js";
import "src/shared/styleguide/charts/Chart.scss";

const LinearPlotChart = ({
  bgColor = COLOR_PALETTE.lightGrey,
  chartId,
  chartData,
  className,
  description = "",
  fgColor = COLOR_PALETTE.carrotOrange,
  height = 8,
  max = 100,
  min = 0,
  noData = false,
  title = "",
  units = "$",
  ...props
}) => {
  const createSliderWithTooltip = Slider.createSliderWithTooltip;
  const Range = createSliderWithTooltip(Slider.Range);
  const markStyle = {
    fontSize: 18,
    fontWeight: 600,
    opacity: 0.8,
    color: COLOR_PALETTE.blackRockBlue,
  };

  const minThreshold = Math.min(min, chartData.min, chartData.current);
  const maxThreshold = Math.max(max, chartData.max, chartData.current);

  return (
    <div className={`sg-linear-plot-chart${className ? ` ${className}` : ""}`}>
      <BlockUi loader={<React.Fragment />} blocking={noData}>
        <div className="lpc-title">{title}</div>
        <div className="lpc-description">{description}</div>
        <div className="lpc-range-container">
          <Range
            className="test"
            min={minThreshold}
            max={maxThreshold}
            value={[chartData.current]}
            handleStyle={{
              top: -height * 0.8,
              margin: 0,
              width: height * 2.4,
              height: height * 2.4,
              backgroundColor: fgColor,
              borderColor: fgColor,
            }}
            dotStyle={{
              top: -(height * 1.4),
              marginLeft: -height * 1.2,
              width: height * 2.4,
              height: height * 2.4,
              backgroundColor: fgColor,
              borderColor: fgColor,
            }}
            marks={{
              [chartData.min]: {
                style: markStyle,
                label: `${units}${NumberUtil.toHumanReadableNumber(
                  chartData.min,
                )}`,
              },
              [chartData.max]: {
                style: markStyle,
                label: `${units}${NumberUtil.toHumanReadableNumber(
                  chartData.max,
                )}`,
              },
            }}
            railStyle={{
              backgroundColor: bgColor,
              height,
              top: 0,
            }}
            tipProps={{ visible: true }}
            tipFormatter={(item) =>
              `${units}${NumberUtil.toHumanReadableNumber(item)}`
            }
            trackStyle={[{ height: 0 }, { height: 0 }]}
            {...props}
          />
        </div>
      </BlockUi>
    </div>
  );
};

LinearPlotChart.propTypes = {
  bgColor: PropTypes.string,
  chartData: PropTypes.object.isRequired,
  chartId: PropTypes.string.isRequired,
  description: PropTypes.string,
  fgColor: PropTypes.string,
  height: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  noData: PropTypes.bool,
  props: PropTypes.object,
  title: PropTypes.string,
  units: PropTypes.string,
};

export default LinearPlotChart;
