import { gql } from "apollo-boost";
import { NUM_MAX_RESULTS } from "src/constants/ReviewSettings";

const QUERY_FEATURE_FLAGS = gql`
  query companyFeatureFlags($companyId: ID!) {
    companyFeatureFlags(companyId: $companyId)
  }
`;

const QUERY_PUBLISHING_NOTIFICATION = gql`
  query companyInfo($companyId: ID!) {
    getCompanyInformation(companyId: $companyId) {
      setting {
        # publishing settings
        autoPublishHighScoreReviews
        syndicateLowScoreReviews
        reviewPageDisplayElement

        # posting frequency
        reviewsDailyMax
        reviewsIntervalDays

        # reminder notification
        noReviewReminderStatus
        noReviewReminderFrequency
        noReviewReminderPeriod
        noContactReminderStatus
        noContactReminderFrequency
      }
    }
  }
`;

const QUERY_REVIEW_TOPICS = gql`
  query reviewTopics($companyId: ID!) {
    reviewsList(companyId: $companyId) {
      total
    }
    company(companyId: $companyId) {
      id
      reviewTopics {
        averageScore
        reviewTopicId
        reviewTopic
      }
    }
  }
`;

const QUERY_DEFAULT_REVIEW_TOPICS = gql`
  query defaultReviewTopics {
    defaultReviewTopics {
      displayOrder
      topic
      topicId
    }
  }
`;

const QUERY_SUGGESTION_TOPICS = gql`
  query suggestionTopics($companyId: ID!) {
    company(companyId: $companyId) {
      id
      acceptContactSuggestions
      suggestionTopics {
        suggestionTopic
        suggestionTopicId
      }
    }
  }
`;

const QUERY_DEFAULT_SUGGESTION_TOPICS = gql`
  query defaultSuggestionTopics {
    defaultSuggestionTopics {
      displayOrder
      topic
      topicId
    }
  }
`;

const QUERY_FACEBOOK_PROFILE = gql`
  query socialNetworkProfiles($companyId: ID!) {
    socialNetworkProfiles(companyId: $companyId, type: "FacebookProfile") {
      id
      screenName
      pageUid
      pages {
        id
        status
        errorMessage
      }
    }
  }
`;

const QUERY_TWITTER_PROFILE = gql`
  query socialNetworkProfiles($companyId: ID!) {
    socialNetworkProfiles(companyId: $companyId, type: "TwitterProfile") {
      id
      screenName
      twitterAvatarUrl
    }
  }
`;

const QUERY_TWITTER_AUTH_CREDS = gql`
  query twitterAuthCreds {
    twitterAuthCreds {
      token
      secret
      authUrl
    }
  }
`;

const QUERY_AUTO_INVITATION = gql`
  query($companyId: ID!) {
    companySettings(companyId: $companyId) {
      autoInviteTransactionThreshold
      invitationDelayInterval
    }
    companyFeatureFlags(companyId: $companyId)
  }
`;

const QUERY_EMAIL_SIGNATURE = gql`
  query company($companyId: ID!) {
    company(companyId: $companyId) {
      id
      abbreviatedName
    }
  }
`;

const QUERY_CUSTOM_EMAIL_TEMPLATE = gql`
  query customEmailTemplate($companyId: ID!, $companyUserId: ID!) {
    getCompanyUser(companyUserId: $companyUserId) {
      companyId
      firstName
    }
    company(companyId: $companyId) {
      id
      logoUrl
      abbreviatedName
    }
    getCompanyInformation(companyId: $companyId) {
      setting {
        unhappyCustomerFeedback
      }
    }
    customEmailTemplate(companyId: $companyId) {
      id
      autoResend
      subject
      status
      greeting
      fromEmail
      replyName
      replyEmail
      mainBody
      signature
    }
    customEmailTemplateMeta(companyId: $companyId) {
      autoResend
      subject
      status
      greeting
      fromEmail
      replyName
      replyEmail
      mainBody
      signature
    }
  }
`;

const QUERY_REVIEW_DESTINATIONS_MASTER = gql`
  query defaultDestinationsList {
    defaultDestinationsList(page: 1, limit: ${NUM_MAX_RESULTS}, offset: 0) {
      total
      defaultDestinations {
        id
        displayName
      }
    }
  }
`;

const QUERY_SMART_INVITES = gql`
  query smartInvites($companyId: ID!) {
    reviewDestinationsList(companyId: $companyId, limit: ${NUM_MAX_RESULTS}) {
      reviewDestinations {
        id
        url
        metaData {
          accessToken
          connectionStatus
          gmailOnly
          refreshToken
        }
        displayName
      }
    }
  }
`;

export {
  QUERY_FEATURE_FLAGS,
  QUERY_PUBLISHING_NOTIFICATION,
  QUERY_REVIEW_TOPICS,
  QUERY_DEFAULT_REVIEW_TOPICS,
  QUERY_SUGGESTION_TOPICS,
  QUERY_DEFAULT_SUGGESTION_TOPICS,
  QUERY_AUTO_INVITATION,
  QUERY_EMAIL_SIGNATURE,
  QUERY_CUSTOM_EMAIL_TEMPLATE,
  QUERY_FACEBOOK_PROFILE,
  QUERY_TWITTER_PROFILE,
  QUERY_TWITTER_AUTH_CREDS,
  QUERY_REVIEW_DESTINATIONS_MASTER,
  QUERY_SMART_INVITES
};
