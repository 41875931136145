const CUSTOM_IMAGE_WIDTH = 400;
const CUSTOM_IMAGE_HEIGHT = 200;

const onDrop = ({
  file,
  formik,
  setPhotoError
}) => {
  if (file.length === 0 || file.length > 1) return setPhotoError('The file has exceeded the limit');
  const reader = new FileReader();
  reader.readAsDataURL(file[0])
  reader.onload = () => {
    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      const height = image.height;
      const width = image.width;
      if (height >= CUSTOM_IMAGE_HEIGHT && width >= CUSTOM_IMAGE_WIDTH) {
        const base64Image = reader.result
        const splitBase64 = base64Image.split(',')
        const goodBase64Image = splitBase64.length > 1 ? splitBase64[1] : base64Image

        formik.setFieldValue('image', goodBase64Image, false)
        formik.setFieldValue('imageOriginalFileName', 'instaint-image', false)
        return setPhotoError(null)
      } else {
        setPhotoError('The file has exceeded the limit')
      }
    }
  }
}

const openFinder = () => {
  const dropZoneEl = document.querySelector('.file-drop-zone')
  if (dropZoneEl) {
    dropZoneEl.click()
  }
}

const removeImg = ({
  formik,
}) => {
  formik.setFieldValue('image', '', false)
  formik.setFieldValue('imageOriginalFileName', '', false)
  formik.setFieldValue('avatarUrl', '', false)
}

export {
  onDrop,
  openFinder,
  removeImg,
}
