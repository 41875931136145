import React, { useState, useEffect, useContext } from "react";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import {
  NEXT_MONTH_STARTING,
  NEXT_MONTH_ENDING,
} from "src/components/customers_segments/segments/segment/SegmentConstants";
import ellipsisV from "src/styles/assets/ellipsis-v.svg";
import { Col, Row } from "react-bootstrap";
import TextingAccordion from "src/shared/styleguide/accordions/TextingAccordion";
import StringUtil from "src/utils/StringUtil";
import { showCompanyId } from "src/shared/SessionHelper";
import { useHistory } from "react-router-dom";
import {
  numberFormatter,
  dateFormatter,
  stringConvertor,
} from "src/shared/Utils";
import { getCriteriaCount } from "./segment/SegmentUtils";
import { SegmentsContext } from "./SegmentsContext";

const SegmentsSummarySidebar = ({ data, advanceFilter }) => {
  const [metaData, setMetaData] = useState(data.metaData);
  const [displayContactsData, setDisplayContactsData] = useState({});
  const [displayTransactionsData, setDisplayTransactionsData] = useState({});
  const {
    rawCitiesData,
    rawStatesData,
    rawZipCodesData,
    getLocationData,
  } = useContext(SegmentsContext);

  const history = useHistory();
  // methods
  /**
   *
   * @param {*} label -- Left side label
   * @param {*} value -- Right side value
   * @returns
   */
  const sideBarContent = (label, value, dropdownComponent) => {
    return (
      <Row className="summary-info">
        <Col sm={4}>{label}</Col>
        <Col sm={4} className="summary-values">
          {value}
        </Col>
        <Col sm={4}>{dropdownComponent}</Col>
      </Row>
    );
  };

  const criteriaCounts = getCriteriaCount(advanceFilter, metaData);

  const showCriteriaElement = (type, criteria = {}) => {
    let allowed = ["customer_details", "transactions"];

    if (type === "exclude") {
      allowed = ["exclude_transactions"];
    }

    const displayObject = Object.keys(criteria)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        const displayKey =
          key === "exclude_transactions" ? "transactions" : key;
        obj[displayKey] = criteria[key];

        return obj;
      }, {});

    return (
      <div className="show-criteria-container">
        {Object.keys(displayObject).map((item, index) => (
          <div
            key={`segment-${type}-${item}`}
            className="show-criteria-category"
          >
            {Object.keys(displayObject[item]).map((el, i) => {
              if (typeof displayObject[item][el] === "object") {
                return (
                  <div className="pb-2" key={`segment-${type}-${item}-${el}`}>
                    <div className="label">{stringConvertor(el)}</div>
                    <b className="values">
                      {displayObject[item][el].map(
                        (node, n) =>
                          `${stringConvertor(node)}${
                            n < displayObject[item][el].length - 1 ? ", " : ""
                          }`,
                      )}
                    </b>{" "}
                    {i < Object.keys(displayObject[item]).length - 1 ? (
                      <br />
                    ) : (
                      ""
                    )}
                  </div>
                );
              } else {
                return `${displayObject[item][el]}${
                  i < displayObject[item].length - 1 ? ", " : ""
                }
                  `;
              }
            })}
          </div>
        ))}
      </div>
    );
  };

  const showAdvanceCriteriaElement = (criteria = {}) => {
    const displayObject = Object.keys(criteria)
      .filter((key) => "advance_filter" === key)
      .reduce((obj, key) => {
        const displayKey =
          key === "exclude_transactions" ? "transactions" : key;
        obj[displayKey] = criteria[key];
        return obj;
      }, {});

    return (
      <div className="include-details">
        {Object.keys(displayObject).map((item, index) => (
          <div key={`segment-include-${item}`} className="values">
            {Object.keys(displayObject[item]).map((el, i) => {
              return `${displayObject[item][el]}${
                i < displayObject[item].length - 1 ? ", " : ""
              }`;
            })}
          </div>
        ))}
      </div>
    );
  };

  const shapeForContactsDisplay = (
    data,
    rawCitiesData,
    rawStatesData,
    rawZipCodesData,
  ) => {
    const displayObj = {};

    displayObj.customer_details = {};
    displayObj.customer_details.type = [];
    displayObj.customer_details.source = [];
    displayObj.customer_details.service_contract_status = [];
    displayObj.customer_details.service_contract_type = [];
    displayObj.customer_details.service_contract_start_date = [];
    displayObj.customer_details.service_contract_end_date = [];
    displayObj.customer_details.state = [];
    displayObj.customer_details.city = [];
    displayObj.customer_details.zip_code = [];
    displayObj.customer_details.has_mailing_address = [];
    displayObj.customer_details.has_mobile_number = [];
    displayObj.customer_details.has_email_address = [];

    if (data.contacts && data.contacts.commercial) {
      const isAny = data.contacts.commercial.values[0] === "any";
      data.contacts.commercial.values.map((item) => {
        if (item === "0") {
          displayObj.customer_details.type.push("Residential");
        }
        if (item === "1") {
          displayObj.customer_details.type.push("Commercial");
        }
      });
    } else {
      displayObj.customer_details.type.push("All");
    }

    if (data.contacts && data.contacts.source) {
      data.contacts.source.values.map((item) => {
        displayObj.customer_details.source.push(item);
      });
    } else {
      displayObj.customer_details.source.push("All");
    }

    if (data.contacts && data.contacts.in_contract) {
      data.contacts.in_contract.values.map((item) => {
        if (parseInt(item) === 0) {
          displayObj.customer_details.service_contract_status.push(
            "Has No Service Contract",
          );
        }
        if (parseInt(item) === 1) {
          displayObj.customer_details.service_contract_status.push(
            "Has Service Contract",
          );
        }
      });
    } else {
      displayObj.customer_details.service_contract_status.push("All");
    }

    if (data.contacts && data.contacts.service_contract_type) {
      data.contacts.service_contract_type.values.map((item) => {
        displayObj.customer_details.service_contract_type.push(item);
      });
    } else {
      displayObj.customer_details.service_contract_type.push("All");
    }

    if (data.contacts && data.contacts.contract_start_date) {
      if (data.contacts.contract_start_date.operator === "range") {
        displayObj.customer_details.service_contract_start_date.push(
          `${
            data.contacts.contract_start_date.values[0]
              ? dateFormatter(data.contacts.contract_start_date.values[0])
              : ""
          } ${
            data.contacts.contract_start_date.values[1]
              ? ` to ${dateFormatter(
                  data.contacts.contract_start_date.values[1],
                )}`
              : ""
          }`,
        );
      } else {
        displayObj.customer_details.service_contract_start_date.push(
          data.contacts.contract_start_date.values[0] !== NEXT_MONTH_STARTING
            ? `${
                data.contacts.contract_start_date.values[0] === 0
                  ? "Next"
                  : "Previous"
              } 30 Days`
            : "Next Calendar Month",
        );
      }
    } else {
      displayObj.customer_details.service_contract_start_date.push(
        "Any to Any",
      );
    }

    if (data.contacts && data.contacts.contract_end_date) {
      if (data.contacts.contract_end_date.operator === "range") {
        displayObj.customer_details.service_contract_end_date.push(
          ` ${
            data.contacts.contract_end_date.values[0]
              ? dateFormatter(data.contacts.contract_end_date.values[0])
              : ""
          } ${
            data.contacts.contract_end_date.values[1]
              ? ` to ${dateFormatter(
                  data.contacts.contract_end_date.values[1],
                )}`
              : ""
          }`,
        );
      } else {
        displayObj.customer_details.service_contract_end_date.push(
          data.contacts.contract_end_date.values[0] !== NEXT_MONTH_ENDING
            ? `${
                data.contacts.contract_end_date.values[0] === 0
                  ? "Next"
                  : "Previous"
              } 30 Days`
            : "Next Calendar Month",
        );
      }
    } else {
      displayObj.customer_details.service_contract_end_date.push("Any to Any");
    }

    if (data.contacts && data.contacts.state) {
      data.contacts.state.values.map((item) => {
        displayObj.customer_details.state.push(
          `${item}${
            rawStatesData[item]
              ? ` (${numberFormatter(rawStatesData[item], 0)})`
              : ""
          }`,
        );
      });
    } else {
      displayObj.customer_details.state.push("All");
    }

    if (data.contacts && data.contacts.city) {
      data.contacts.city.values.map((item) => {
        displayObj.customer_details.city.push(
          `${item}${
            rawCitiesData[item]
              ? ` (${numberFormatter(rawCitiesData[item], 0)})`
              : ""
          }`,
        );
      });
    } else {
      displayObj.customer_details.city.push("All");
    }

    if (data.contacts && data.contacts.zip) {
      data.contacts.zip.values.map((item) => {
        displayObj.customer_details.zip_code.push(
          `${item}${
            rawZipCodesData[item]
              ? ` (${numberFormatter(rawZipCodesData[item], 0)})`
              : ""
          }`,
        );
      });
    } else {
      displayObj.customer_details.zip_code.push("All");
    }

    if (data.contacts && data.contacts.has_mailing_address) {
      data.contacts.has_mailing_address.values.map((item) => {
        if (parseInt(item) === 0) {
          displayObj.customer_details.has_mailing_address.push("No");
        } else if (parseInt(item) === 1) {
          displayObj.customer_details.has_mailing_address.push("Yes");
        }
      });
    } else {
      displayObj.customer_details.has_mailing_address.push("All");
    }

    if (data.contacts && data.contacts.has_mobile_number) {
      data.contacts.has_mobile_number.values.map((item) => {
        if (parseInt(item) === 0) {
          displayObj.customer_details.has_mobile_number.push("No");
        } else if (parseInt(item) === 1) {
          displayObj.customer_details.has_mobile_number.push("Yes");
        }
      });
    } else {
      displayObj.customer_details.has_mobile_number.push("All");
    }

    if (data.contacts && data.contacts.has_email_address) {
      data.contacts.has_email_address.values.map((item) => {
        if (parseInt(item) === 0) {
          displayObj.customer_details.has_email_address.push("No");
        } else if (parseInt(item) === 1) {
          displayObj.customer_details.has_email_address.push("Yes");
        }
      });
    } else {
      displayObj.customer_details.has_email_address.push("All");
    }
    return displayObj;
  };

  const shapeForTransactionsDisplay = (data) => {
    const displayObj = {};

    displayObj.transactions = {};
    displayObj.transactions.number_of_transactions = [];
    displayObj.transactions.time_since_last_transaction = [];
    displayObj.transactions.total_dollars_spent = [];
    displayObj.transactions.dollar_spent_per_transaction = [];
    displayObj.transactions.transaction_date_range = [];
    displayObj.exclude_transactions = {};

    if (data.transactions && data.transactions.job_type) {
      displayObj.transactions.job_type = data.transactions.job_type.values;
    } else {
      displayObj.transactions.job_type = ["All"];
    }

    if (data.transactions && data.transactions.count) {
      displayObj.transactions.number_of_transactions.push(
        `${StringUtil.capitalizeEachWord(data.transactions.count.values[0])}${
          data.transactions.count.values[1]
            ? ` to ${StringUtil.capitalizeEachWord(
                data.transactions.count.values[1],
              )}`
            : ""
        }`,
      );
    } else {
      displayObj.transactions.number_of_transactions.push("Any to Any");
    }

    if (
      data.transactions &&
      (data.transactions.months || data.transactions.days)
    ) {
      if (data.transactions.months) {
        displayObj.transactions.time_since_last_transaction.push(
          `${StringUtil.capitalizeEachWord(
            data.transactions.months.values[0],
          )}${
            data.transactions.months.values[1]
              ? ` to ${StringUtil.capitalizeEachWord(
                  data.transactions.months.values[1],
                )}`
              : ""
          } months`,
        );
      } else {
        displayObj.transactions.time_since_last_transaction.push(
          `${StringUtil.capitalizeEachWord(data.transactions.days.values[0])}${
            data.transactions.days.values[1]
              ? ` to ${StringUtil.capitalizeEachWord(
                  data.transactions.days.values[1],
                )}`
              : ""
          } days`,
        );
      }
    } else {
      displayObj.transactions.time_since_last_transaction.push("Any to Any");
    }

    if (data.transactions && data.transactions.amount) {
      displayObj.transactions.total_dollars_spent.push(
        `${StringUtil.capitalizeEachWord(data.transactions.amount.values[0])}${
          data.transactions.amount.values[1]
            ? ` to ${StringUtil.capitalizeEachWord(
                data.transactions.amount.values[1],
              )}`
            : ""
        }`,
      );
    } else {
      displayObj.transactions.total_dollars_spent.push("Any to Any");
    }

    if (data.transactions && data.transactions.amount_per_txn) {
      displayObj.transactions.dollar_spent_per_transaction.push(
        `${StringUtil.capitalizeEachWord(
          data.transactions.amount_per_txn.values[0],
        )}${
          data.transactions.amount_per_txn.values[1]
            ? ` to ${StringUtil.capitalizeEachWord(
                data.transactions.amount_per_txn.values[1],
              )}`
            : ""
        }`,
      );
    } else {
      displayObj.transactions.dollar_spent_per_transaction.push("Any to Any");
    }

    if (data.transactions && data.transactions.txn_date) {
      displayObj.transactions.transaction_date_range.push(
        `${dateFormatter(data.transactions.txn_date.values[0])}${
          data.transactions.txn_date.values[1]
            ? ` to ${dateFormatter(data.transactions.txn_date.values[1])}`
            : ""
        }`,
      );
    } else {
      displayObj.transactions.transaction_date_range.push("Any to Any");
    }

    if (data.transactions && data.transactions.not_count) {
      const val = `${StringUtil.capitalizeEachWord(
        data.transactions.not_count.values[0],
      )}${
        data.transactions.not_count.values[1]
          ? ` to ${StringUtil.capitalizeEachWord(
              data.transactions.not_count.values[1],
            )}`
          : ""
      }`;
      if (val.length) {
        displayObj.exclude_transactions.number_of_transactions = [val];
      }
    } else {
      displayObj.exclude_transactions.number_of_transactions = [`N/A`];
    }

    if (data.transactions && data.transactions.not_amount) {
      const val = `${StringUtil.capitalizeEachWord(
        data.transactions.not_amount.values[0],
      )}${
        data.transactions.not_amount.values[1]
          ? ` to ${StringUtil.capitalizeEachWord(
              data.transactions.not_amount.values[1],
            )}`
          : ""
      }`;

      if (val.length) {
        displayObj.exclude_transactions.total_dollars_spent = [val];
      }
    } else {
      displayObj.exclude_transactions.total_dollars_spent = [`N/A`];
    }

    return displayObj;
  };

  const shapeForAdvanceCriteriaDisplay = (customFiltersList, data) => {
    const displayObj = {};
    if (data.customer_tags) {
      displayObj.advance_filter = [];
      if (
        data.customer_tags.custom_filter_ids &&
        data.customer_tags.custom_filter_ids.values
      ) {
        customFiltersList.map((item) => {
          if (data.customer_tags.custom_filter_ids.values.includes(item.id)) {
            displayObj.advance_filter.push(item.name);
          }
        });
      }
    }
    return displayObj;
  };

  useEffect(() => {
    if (data.metaData) {
      setMetaData(data.metaData);
    }
  }, [data.metaData]);

  useEffect(() => {
    if (data.metaData) {
      setDisplayContactsData(
        shapeForContactsDisplay(
          data.metaData,
          rawCitiesData,
          rawStatesData,
          rawZipCodesData,
        ),
      );
      setDisplayTransactionsData(shapeForTransactionsDisplay(data.metaData));
    }
  }, [data]);

  useEffect(() => {
    if (data.metaData && rawCitiesData && rawStatesData && rawZipCodesData) {
      setDisplayContactsData(
        shapeForContactsDisplay(
          data.metaData,
          rawCitiesData,
          rawStatesData,
          rawZipCodesData,
        ),
      );
    }
  }, [rawCitiesData, rawStatesData, rawZipCodesData]);

  return (
    <div className="segment-summary">
      <div className="summary-title">Segment Summary</div>
      <div className="summary-card">
        {sideBarContent(
          "Segment Type",
          `${data.default ? "Default" : "Custom"}`,
          <span className="option-dropdown">
            <DropdownButton
              alignRight={true}
              direction={false}
              dropShadow={true}
              title={<img alt="Options" src={ellipsisV} />}
              variant="link"
            >
              <Dropdown.Item
                href="#"
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/companies/${showCompanyId}/segments/${data.id}/edit`,
                    {
                      prevPage: document.location.pathname,
                    },
                  );
                }}
              >
                Edit
              </Dropdown.Item>
            </DropdownButton>
          </span>,
        )}
        {sideBarContent("Segment Criteria")}
        <div>
          <div className="summary-info2">Include </div>
          <TextingAccordion
            label={`Customer Details (${criteriaCounts.include.customer})`}
            eventKey="1"
            isDefaultActiveKey={false}
            labelStyles={{
              fontSize: "1.06rem",
              color: "#313541",
            }}
          >
            {showCriteriaElement("include", displayContactsData)}
          </TextingAccordion>
          <TextingAccordion
            label={`Transactions (${criteriaCounts.include.transaction})`}
            eventKey="1"
            isDefaultActiveKey={false}
            labelStyles={{
              fontSize: "1.06rem",
              color: "#313541",
            }}
          >
            {showCriteriaElement("include", displayTransactionsData)}
          </TextingAccordion>
        </div>

        {criteriaCounts.advanceFilters > 0 ? (
          <div>
            <div className="summary-info2">Advanced Filter</div>
            <div className="include-details">
              <div className="values">
                {showAdvanceCriteriaElement(
                  shapeForAdvanceCriteriaDisplay(advanceFilter, metaData),
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="summary-info2">Advanced Filter</div>
            <div className="include-details">
              <div className="values">N/A</div>
            </div>
          </>
        )}

        <div>
          <div className="summary-info2">Exclude</div>
          <TextingAccordion
            label={`Transactions (${criteriaCounts.exclude.transaction})`}
            eventKey="1"
            isDefaultActiveKey={false}
            labelStyles={{
              fontSize: "1.06rem",
              color: "#313541",
            }}
          >
            {showCriteriaElement("exclude", displayTransactionsData)}
          </TextingAccordion>
        </div>

        {sideBarContent(
          "Total Customers",
          data.customersCount ? numberFormatter(data.customersCount) : 0,
        )}
        {sideBarContent(
          "Segment Status",
          `${data.archived ? "Archived" : "Active"}`,
        )}
      </div>
    </div>
  );
};

export default SegmentsSummarySidebar;
