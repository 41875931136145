import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MEDIUM_CHART_HEIGHT } from "src/constants/ReviewResults";
import { QUERY_RESPONSE_RATE_BY_PLATFORM } from "src/graphql/reviews/results/queries";
import { useQuery } from "@apollo/react-hooks";
import CalendarUtil from "src/utils/CalendarUtil";
import LineChart from "./LineChart";

export const SentimentScoreTrend = ({
  companyId,
  filter,
  hasGoogleReviews = false
}) => {
  // state
  const [noData, setNoData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [responseRateByPlatform, setResponseRateByPlatform] = useState([
    {
      name: "Customer Lobby",
      data: [0, 0, 0, 0, 0],
      color: "#f47821"
    },
    {
      name: "Google",
      data: [0, 0, 0, 0, 0],
      color: "#898989"
    }
  ]);

  // call apis
  const { loading, data } = useQuery(QUERY_RESPONSE_RATE_BY_PLATFORM, {
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });

  // effects
  useEffect(() => {
    if (data && data.responseRateByPlatform) {
      const newResponseRateByPlatform = [...responseRateByPlatform];
      newResponseRateByPlatform[0].data = [];
      newResponseRateByPlatform[1].data = [];
      setCategories(
        data.responseRateByPlatform.dateRanges
          .map(dateRangeStr => dateRangeStr.split("to")[0].trim())
          .map(dateStr => CalendarUtil.toDateStrByChartType(dateStr, filter.id))
      );
      data.responseRateByPlatform.data.forEach((sentiment, index) => {
        const dateRangeStr = data.responseRateByPlatform.dateRanges[index];
        const [from, to] = dateRangeStr
          .split("to")
          .map(dateStr => dateStr.trim());
        newResponseRateByPlatform[0].data.push({
          from,
          to,
          y: sentiment.customerlobby
        });
        newResponseRateByPlatform[1].data.push({
          from,
          to,
          y: sentiment.google
        });
      });
      const total = data.responseRateByPlatform.data.reduce((acc, val) => {
        return acc + val.customerlobby + (hasGoogleReviews ? val.google : 0);
      }, 0);
      setNoData(total === 0);
      setResponseRateByPlatform(newResponseRateByPlatform);
    }
  }, [data]);

  useEffect(() => {
    const newResponseRateByPlatform = [...responseRateByPlatform];
    newResponseRateByPlatform[1].visible = hasGoogleReviews;
    newResponseRateByPlatform[1].showInLegend = hasGoogleReviews;
    setResponseRateByPlatform(newResponseRateByPlatform);
  }, [hasGoogleReviews]);

  // render
  return (
    <LineChart
      noData={noData}
      loading={loading}
      chartId="responseRateByPlatform"
      title="Response Rate by Platform"
      popupInfoMsg="This shows the percentage of the number of review invitations sent compared to the number of reviews submitted across the various platforms for the selected date range."
      categories={categories}
      chartData={responseRateByPlatform}
      chartHeight={MEDIUM_CHART_HEIGHT}
      tooltipFormatter={function() {
        const { point } = this;
        return `${point.from ? point.from : ""}${
          point.to && point.from !== point.to ? ` to ${point.to}` : ""
        }<br />${this.series.name}: ${point.y}`;
      }}
    />
  );
};

SentimentScoreTrend.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  hasGoogleReviews: PropTypes.bool
};

export default SentimentScoreTrend;
