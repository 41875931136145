import React from "react";
import ResultsNotFound from "src/shared/errorpages/ResultsNotFound";

const NotFoundFolder = () => {
  // render
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="folder"
      width="130"
      height="108.637"
      viewBox="0 0 140 118.637"
    >
      <defs>
        <style>
          {`.cls-1{fill:#dee3e6}.cls-2{fill:#fff}.cls-3{fill:#c3c9ce}`}
        </style>
      </defs>
      <path
        d="M175.648 82.2v74.213a11.528 11.528 0 0 1-11.528 11.528H51.172V82.2A11.528 11.528 0 0 1 62.7 70.672h101.42A11.528 11.528 0 0 1 175.648 82.2zm-54.119 58.316a20.088 20.088 0 0 0 12.22-18.23c0-11.461-10.042-20.678-22.128-19.968-10.834.638-19.476 9.469-19.513 19.9a19.775 19.775 0 0 0 12.22 18.224v12a4.192 4.192 0 0 0 4.193 4.2h8.816a4.192 4.192 0 0 0 4.193-4.2zm0 0"
        className="cls-1"
        transform="translate(-35.648 -49.304)"
      />
      <path
        d="M227.75 194.868a20.088 20.088 0 0 1-12.22 18.23v4.062h-17.2v-4.138a19.775 19.775 0 0 1-12.22-18.224c.037-10.43 8.679-19.261 19.513-19.9 12.085-.708 22.127 8.51 22.127 19.97zm0 0"
        className="cls-2"
        transform="translate(-129.649 -121.886)"
      />
      <path
        d="M243.592 314.289v7.864a4.192 4.192 0 0 1-4.193 4.2h-8.816a4.192 4.192 0 0 1-4.193-4.2v-7.864zm0 0"
        className="cls-2"
        transform="translate(-157.71 -219.015)"
      />
      <path
        d="M51.752 4.48l13.973 17.126H27.052a11.528 11.528 0 0 0-11.528 11.528v85.742h-4A11.528 11.528 0 0 1 0 107.347V11.766A11.528 11.528 0 0 1 11.528.238H42.82a11.531 11.531 0 0 1 8.932 4.242zm0 0"
        className="cls-3"
        transform="translate(0 -.238)"
      />
      <g transform="translate(35.092 31.327)">
        <path
          d="M251.592 116.154v-10.227a2.427 2.427 0 1 0-4.854 0v10.227a2.427 2.427 0 0 0 4.854 0zm0 0"
          className="cls-2"
          transform="translate(-206.977 -103.5)"
        />
        <path
          d="M118.1 229.77a2.427 2.427 0 1 0 0 4.854h10.642a2.427 2.427 0 0 0 0-4.854zm0 0"
          className="cls-2"
          transform="translate(-115.672 -191.463)"
        />
        <path
          d="M355.8 229.77h-10.643a2.427 2.427 0 0 0 0 4.854H355.8a2.427 2.427 0 0 0 0-4.854zm0 0"
          className="cls-2"
          transform="translate(-273.847 -191.463)"
        />
        <path
          d="M164.124 152.5a2.427 2.427 0 0 0 1.672-4.187l-7.578-7.2a2.427 2.427 0 0 0-3.343 3.52l7.578 7.2a2.416 2.416 0 0 0 1.671.667zm0 0"
          className="cls-2"
          transform="translate(-142.456 -129.24)"
        />
        <path
          d="M316.808 152.5a2.416 2.416 0 0 0 1.671-.667l7.578-7.2a2.427 2.427 0 1 0-3.343-3.52l-7.578 7.2a2.427 2.427 0 0 0 1.672 4.187zm0 0"
          className="cls-2"
          transform="translate(-254.098 -129.239)"
        />
      </g>
    </svg>
  );
};

const NoResultsFound = () => {
  // render
  return (
    <ResultsNotFound
      svgIcon={<NotFoundFolder />}
      primaryMsg="No results found"
      secondaryMsg="We couldn’t find any results. Try adjusting your filters to display results."
    />
  );
};

const NoCustomersAdded = () => {
  // render
  return (
    <ResultsNotFound
      svgIcon={<NotFoundFolder />}
      primaryMsg="No customers added"
      secondaryMsg="There are no customers added, to add a customer fill in the details and request invite."
    />
  );
};

const PhoneActionButton = () => {
  // render
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.787"
      height="18.779"
      viewBox="0 0 21.787 21.779"
    >
      <g transform="translate(-.563 .35)">
        <path
          d="M5.167 14.012a22.363 22.363 0 0 0 7.439 5.827 11.577 11.577 0 0 0 4.163 1.232c.1 0 .2.009.3.009a3.447 3.447 0 0 0 2.673-1.149.1.1 0 0 0 .017-.022 11.256 11.256 0 0 1 .843-.874c.205-.2.415-.4.616-.612a2.109 2.109 0 0 0-.009-3.141l-2.625-2.625a2.125 2.125 0 0 0-1.538-.708 2.2 2.2 0 0 0-1.555.7l-1.564 1.564c-.144-.083-.293-.157-.432-.227a5.4 5.4 0 0 1-.48-.262 16.549 16.549 0 0 1-3.953-3.6A9.53 9.53 0 0 1 7.722 8c.411-.371.795-.76 1.166-1.14.131-.135.266-.271.4-.406a2.239 2.239 0 0 0 .725-1.572 2.222 2.222 0 0 0-.723-1.575L7.989 2c-.153-.153-.3-.3-.446-.454-.288-.3-.59-.6-.887-.878A2.178 2.178 0 0 0 5.119 0a2.242 2.242 0 0 0-1.555.677L1.931 2.311a3.356 3.356 0 0 0-1 2.149 8.084 8.084 0 0 0 .607 3.494 20.329 20.329 0 0 0 3.629 6.058zM2 4.551a2.31 2.31 0 0 1 .695-1.485l1.62-1.625a1.176 1.176 0 0 1 .8-.371 1.116 1.116 0 0 1 .786.38c.293.271.568.555.865.856l.454.463 1.3 1.3a1.186 1.186 0 0 1 .411.817 1.186 1.186 0 0 1-.411.817c-.135.135-.271.275-.406.411-.406.411-.786.8-1.206 1.171l-.022.022a.854.854 0 0 0-.218.97c0 .013.009.022.013.035a10.28 10.28 0 0 0 1.539 2.494 17.467 17.467 0 0 0 4.211 3.835 6.252 6.252 0 0 0 .577.314 5.4 5.4 0 0 1 .48.262l.048.026a.913.913 0 0 0 .424.109.926.926 0 0 0 .651-.3l1.634-1.634a1.156 1.156 0 0 1 .8-.389 1.1 1.1 0 0 1 .773.389l2.634 2.629a1.053 1.053 0 0 1-.013 1.647c-.183.2-.376.384-.581.581a12.58 12.58 0 0 0-.913.948 2.4 2.4 0 0 1-1.874.795c-.074 0-.153 0-.227-.009a10.518 10.518 0 0 1-3.765-1.127A21.245 21.245 0 0 1 6 13.34 19.458 19.458 0 0 1 2.546 7.6 7.045 7.045 0 0 1 2 4.551z"
          fill="#667587"
          stroke="#667587"
          strokeWidth="0.7"
        />
      </g>
    </svg>
  );
};

const EmailActionButton = () => {
  // render
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.199"
      height="14.719"
      viewBox="0 0 23.199 17.719"
    >
      <g transform="translate(.1 -60.9)">
        <g transform="translate(0 61)">
          <path
            fill="#667587"
            stroke="#667587"
            strokeWidth="0.1"
            d="M20.979 61H2.021A2.025 2.025 0 0 0 0 63.021V76.5a2.025 2.025 0 0 0 2.021 2.02h18.958A2.025 2.025 0 0 0 23 76.5V63.021A2.025 2.025 0 0 0 20.979 61zm-.279 1.348L11.543 71.5l-9.236-9.152zM1.348 76.219V63.294l6.49 6.435zm.953.953l6.494-6.494 2.276 2.256a.674.674 0 0 0 .951 0l2.219-2.219 6.459 6.457zm19.352-.953l-6.46-6.459 6.459-6.46z"
            transform="translate(0 -61)"
          />
        </g>
      </g>
    </svg>
  );
};

const PhoneActionButtonWithWait = () => {
  // render
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.787"
      height="18.779"
      viewBox="0 0 21.787 21.779"
    >
      <g
        style={{
          fill: "#667587",
          stroke: "#667587",
          strokeWidth: 0.2,
        }}
        id="prefix__clock-with-white-face"
        transform="translate(12 .1)"
      >
        <g>
          <path
            d="M5.3 0a5.3 5.3 0 1 0 5.3 5.3A5.316 5.316 0 0 0 5.3 0zm0 9.54A4.24 4.24 0 1 1 9.54 5.3 4.252 4.252 0 0 1 5.3 9.54z"
          />
          <path
            d="M230.341 127.5h-.841v4l2.646 1.8.551-.708-2.356-1.623z"
            transform="translate(-224.847 -125.43)"
          />
        </g>
      </g>

      <g transform="translate(-.563 .35)">
        <path
          d="M5.167 14.012a22.363 22.363 0 0 0 7.439 5.827 11.577 11.577 0 0 0 4.163 1.232c.1 0 .2.009.3.009a3.447 3.447 0 0 0 2.673-1.149.1.1 0 0 0 .017-.022 11.256 11.256 0 0 1 .843-.874c.205-.2.415-.4.616-.612a2.109 2.109 0 0 0-.009-3.141l-2.625-2.625a2.125 2.125 0 0 0-1.538-.708 2.2 2.2 0 0 0-1.555.7l-1.564 1.564c-.144-.083-.293-.157-.432-.227a5.4 5.4 0 0 1-.48-.262 16.549 16.549 0 0 1-3.953-3.6A9.53 9.53 0 0 1 7.722 8c.411-.371.795-.76 1.166-1.14.131-.135.266-.271.4-.406a2.239 2.239 0 0 0 .725-1.572 2.222 2.222 0 0 0-.723-1.575L7.989 2c-.153-.153-.3-.3-.446-.454-.288-.3-.59-.6-.887-.878A2.178 2.178 0 0 0 5.119 0a2.242 2.242 0 0 0-1.555.677L1.931 2.311a3.356 3.356 0 0 0-1 2.149 8.084 8.084 0 0 0 .607 3.494 20.329 20.329 0 0 0 3.629 6.058zM2 4.551a2.31 2.31 0 0 1 .695-1.485l1.62-1.625a1.176 1.176 0 0 1 .8-.371 1.116 1.116 0 0 1 .786.38c.293.271.568.555.865.856l.454.463 1.3 1.3a1.186 1.186 0 0 1 .411.817 1.186 1.186 0 0 1-.411.817c-.135.135-.271.275-.406.411-.406.411-.786.8-1.206 1.171l-.022.022a.854.854 0 0 0-.218.97c0 .013.009.022.013.035a10.28 10.28 0 0 0 1.539 2.494 17.467 17.467 0 0 0 4.211 3.835 6.252 6.252 0 0 0 .577.314 5.4 5.4 0 0 1 .48.262l.048.026a.913.913 0 0 0 .424.109.926.926 0 0 0 .651-.3l1.634-1.634a1.156 1.156 0 0 1 .8-.389 1.1 1.1 0 0 1 .773.389l2.634 2.629a1.053 1.053 0 0 1-.013 1.647c-.183.2-.376.384-.581.581a12.58 12.58 0 0 0-.913.948 2.4 2.4 0 0 1-1.874.795c-.074 0-.153 0-.227-.009a10.518 10.518 0 0 1-3.765-1.127A21.245 21.245 0 0 1 6 13.34 19.458 19.458 0 0 1 2.546 7.6 7.045 7.045 0 0 1 2 4.551z"
          style={{
            fill: "#667587",
            stroke: "#667587",
            strokeWidth: 0.7,
          }}
        />
      </g>
    </svg>
  );
};

export {
  NoCustomersAdded,
  NoResultsFound,
  EmailActionButton,
  PhoneActionButton,
  PhoneActionButtonWithWait,
};
