import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MEDIUM_CHART_HEIGHT } from "src/constants/ReviewResults";
import { QUERY_PAGE_VIEWS } from "src/graphql/reviews/results/queries";
import { useQuery } from "@apollo/react-hooks";
import CalendarUtil from "src/utils/CalendarUtil";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";
import LineChart from "./LineChart";

const PageViews = ({ companyId, filter }) => {
  // state
  const [noData, setNoData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [pageViews, setPageViews] = useState([
    {
      name: "On Website",
      data: [],
      color: "#f47821"
    },
    {
      name: "On Review Page",
      data: [],
      color: "#5d7ae1"
    }
  ]);

  // call apis
  const { loading, data } = useQuery(QUERY_PAGE_VIEWS, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });

  // effects
  useEffect(() => {
    if (data && data.reviewPagesViews) {
      setCategories(
        data.reviewPagesViews.trends.map(trend =>
          CalendarUtil.toDateStrByChartType(trend.from, filter.id)
        )
      );
      const newPageViews = { ...pageViews };
      newPageViews[0].data = [];
      newPageViews[1].data = [];
      data.reviewPagesViews.trends.forEach(trend => {
        newPageViews[0].data.push({
          from: trend.from,
          to: trend.to,
          y: trend.site_page_views
        });
        newPageViews[1].data.push({
          from: trend.from,
          to: trend.to,
          y: trend.review_page_views
        });
      });
      const total = data.reviewPagesViews.trends.reduce(
        (acc, val) => acc + val.site_page_views + val.review_page_views,
        0
      );
      setNoData(total === 0);
      setPageViews(newPageViews);
    }
  }, [data]);

  // render
  return (
    <LineChart
      loading={false}
      noData={noData}
      chartId="pageViews"
      title="Page Views"
      popupInfoMsg="This shows the number of visits to your Customer Lobby review page for the selected date range."
      categories={categories}
      chartData={pageViews}
      chartHeight={MEDIUM_CHART_HEIGHT}
      tooltipFormatter={function() {
        const { point } = this;
        return `${point.from}${
          point.from !== point.to ? ` to ${point.to}` : ""
        }<br />${this.series.name}: ${point.y}`;
      }}
    />
  );
};

PageViews.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired
};

export default PageViews;
