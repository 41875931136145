import React from "react";
import PropTypes from "prop-types";

const PopupIcon = ({ iconSize = 16, fill = "#7e7e7e", props = {} }) => {
  // render
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 17 17"
        fill={fill}
        {...props}
      >
        <path d="M8.5 0A8.5 8.5 0 1017 8.5 8.51 8.51 0 008.5 0zm0 15.455A6.955 6.955 0 1115.455 8.5 6.962 6.962 0 018.5 15.455z" />
        <path
          d="M146.032 70a1.03 1.03 0 101.03 1.031 1.032 1.032 0 00-1.03-1.031z"
          transform="translate(-137.532 -66.394)"
        />
        <path
          d="M150.773 140a.773.773 0 00-.773.773v4.636a.773.773 0 101.545 0v-4.636a.773.773 0 00-.772-.773z"
          transform="translate(-142.273 -132.788)"
        />
      </svg>
    </span>
  );
};

PopupIcon.propTypes = {
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  props: PropTypes.object,
};

export default PopupIcon;
