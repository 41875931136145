import {
  REVIEW_DETAIL,
  REVIEW_CHALLENGE,
  REVIEW_WITHDRAWAL_REQUEST
} from './review_details_queries'
import {API_GRAPHQL_URL} from '../../../../../../shared/Config'
import axios from 'axios'

export const reviewDetailHelper = async ({reviewId, reviewType, authHeader}) => {
  const queryParams = {
    query: REVIEW_DETAIL,
    variables: {
      reviewId,
      reviewType
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}

export const reviewChallengeHelper = async ({reviewId, authHeader}) => {
  const queryParams = {
    query: REVIEW_CHALLENGE,
    variables: {
      reviewId
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}

export const reviewWithdrawalRequestHelper = async ({reviewId, authHeader}) => {
  const queryParams = {
    query: REVIEW_WITHDRAWAL_REQUEST,
    variables: {
      reviewId
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}
