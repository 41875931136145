import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import img1 from "src/assets/images/settings/facebook-popup-img.png";

const FacebookSmartInviteHelp = ({ showModal, setShowModal }) => {
  // render
  return (
    <Modal
      zIndex={2999}
      modalSize="lg"
      onHide={() => setShowModal(false)}
      body={
        <div className="pa-2">
          <h3 className="text-dark">Step 1</h3>
          <div>
            Go to your Facebook Local Business page. Click the "Reviews" tab.
          </div>
          <h3 className="text-dark my-2">Step 2</h3>
          <div>Copy the link address (see below).</div>
          <img className="my-4 img-fluid" src={img1} alt="Google search" />
          <h3 className="text-dark">Step 3</h3>
          <div>
            Paste the link in the Facebook box above.
            <p>
              *Note all Facebook business pages have a reviews tab. If you
              cannot find yours, you may need enable ratings (
              <a
                href="https://www.facebook.com/help/589747184405742"
                rel="noopener noreferrer"
                target="_blank"
                className="sg-text-info"
              >
                https://www.facebook.com/help/589747184405742
              </a>
              ) or change your page's category to Local Business (
              <a
                href="https://www.facebook.com/help/222732947737668"
                rel="noopener noreferrer"
                target="_blank"
                className="sg-text-info"
              >
                https://www.facebook.com/help/222732947737668
              </a>
              ).
            </p>
          </div>
        </div>
      }
      footer={
        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      }
      show={showModal}
      handleClose={() => {}}
    />
  );
};

export default FacebookSmartInviteHelp;
