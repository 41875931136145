import {gql} from 'apollo-boost';

const WIDGET_SETTINGS = gql`
  query widgetSettings($companyId: ID!) {
    companySettings(companyId: $companyId) {
      widgetColor
      widgetWidth
      reviewCount
    }
    company(companyId: $companyId) {
      abbreviatedName
      companyName
    }
  }
`;

export {
  WIDGET_SETTINGS
}
