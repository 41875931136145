import React, { useEffect, useState } from "react";
import "./TrackList.scss";
import { PLATFORM, FILTER_BY } from "../../../Data";
import more from "src/styles/assets/more.png";
import { REVIEW_DATA } from "./reviewListData";
import { NO_RECORD_FOUND } from "src/shared/Constants";
import ModalPopupRight from "src/shared/modal/ModalPopupRight";
import ReviewDetails from "./ReviewDetails";
import NoDataFoundComponent from "src/shared/no-data/NoDataFoundComponent";
import Header from "./TrackTable/Header";
import Row from "./TrackTable/Row";
import { invitationIndexHelper } from "./query_actions";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import UpArrow from "src/img/dannys_up_arrow.png";
import DownArrow from "src/img/dannys_down_arrow.png";
import StringUtil from "src/utils/StringUtil";
import TableMeta from "src/shared/styleguide/table/TableMeta";
import "./TrackReviewListComponent.scss";

const PAGE_SIZE = 5;

const TrackReviewListComponent = (props) => {
  // variables
  const { dateRange } = props;
  let { companyId, source, authHeader, companyUserId, filter } = props.state;

  // state
  const [queryRes, setQueryRes] = useState([]);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState(false);
  const [column, setColumn] = useState("");
  const [direction, setDirection] = useState("");
  // methods
  const getReviewListData = () => {
    return REVIEW_DATA;
  };

  const reviewMeActive = () => {
    return source && source === 'Reviewme'
  }

  const [selectedReview, setSelectedReview] = useState(REVIEW_DATA);
  const [reviewDetailsDisplay, setReviewDetailsDisplay] = useState(false);

  /**
   * update review on right modal close
   * @param {*} review Review
   */
  const updateReviewDetailsDisplay = (review) => {
    setSelectedReview(review);
    setReviewDetailsDisplay(!reviewDetailsDisplay);
  };

  /**
   * method invoked to apply date filter
   * @param {*} lastDays last days value
   */
  const getDateFilteredData = (lastDays) => {
    let currentDate = new Date();
    let dateFilteredData = reviewData.filter((review) => {
      let date = new Date(review.review_date);
      return (
        new Date(currentDate.getTime() - lastDays * 24 * 60 * 60 * 1000) <
        new Date(date.getTime() - 24 * 60 * 60 * 1000)
      );
    });
    updateReviewListData(dateFilteredData);
  };

  /**
   * method invoked to apply platform filter
   */
  const getPlatformFilterData = () => {
    if (props.filters.platform.label !== PLATFORM[0].label) {
      const platformFilter = reviewData.filter((review) => {
        return review.platform === props.filters.platform.label;
      });
      updateReviewListData(platformFilter);
    } else {
      updateReviewListData(reviewData);
    }
  };

  /**
   * method invoked to apply filter by filter
   */
  const getFilterByFilteredData = () => {
    if (props.filters.filterBy.label !== FILTER_BY[0].label) {
      const filterByFilter = reviewData.filter((review) => {
        return review.review_type === props.filters.filterBy.label;
      });
      updateReviewListData(filterByFilter);
    } else {
      updateReviewListData(reviewData);
    }
  };

  /**
   * method invoked to set page count
   */
  const reviewData = getReviewListData();
  let [reviewListData, updateReviewListData] = useState(reviewData);
  let [pageCount, setPageCount] = useState(props.state.total / PAGE_SIZE);
  let [filteredReview, updateFilterReview] = useState(
    reviewListData.slice(0, PAGE_SIZE),
  );

  const handleInvitations = async () => {
    if (props.nullifySorting) {
      setColumn("");
      setDirection("");
      props.setNullifySorting(false);
    }

    if (!dateRange) return null;
    let data = await invitationIndexHelper({
      companyId,
      source,
      filter,
      page,
      dateRange,
      authHeader,
      direction,
      column,
      searchTerm: props.filters.searchTerm || "",
    });
    if (data && data.data && data.data.data) {
      setQueryRes(data.data.data.trackInvitationIndex);
      setPage(0);
    }
  };

  // effects
  useEffect(() => {
    handleInvitations();
    setPageCount(props.totalCount / PAGE_SIZE);
    updateFilterReview(reviewListData.slice(0, PAGE_SIZE));
  }, [
    column,
    direction,
    reviewListData,
    props.totalCount,
    dateRange,
    props.state.filter,
    props.state.source,
    props.state.metadataSelector,
    props.filters.searchTerm,
  ]);

  /**
   * Method invoked on pagination change
   * @param {*} event event
   */
  const onPagination = async (event) => {
    let currPage = event.selected;
    let data = await invitationIndexHelper({
      companyId,
      source,
      filter,
      page: currPage,
      dateRange,
      authHeader,
      direction,
      column,
      searchTerm: props.filters.searchTerm || "",
    });
    if (!data) return null;
    props.setState({ ...props.state, currentPage: currPage });
    setQueryRes(data.data.data.trackInvitationIndex);
  };

  const presentTableRows = () => {
    const showInitials = (name) => {
      if (name === undefined || !name) return "N/A";

      let splitName = name.split(" ").filter((val) => val !== "");

      if (splitName.length === 0) return "N/A";

      if (splitName.length === 1) return splitName[0][0].toUpperCase();

      let nameRes = `${splitName[0][0]}${splitName[1][0]}`;

      return nameRes.toUpperCase();
    };

    let { rows } = props.state;
    let currRow = rows[props.state.source];

    if (!queryRes) return null;

    if (queryRes.length === 0) return null;
    return queryRes.map((contact, idx) => {
      return (
        <Row
          formattedPhoneDaytime={contact.formattedPhoneDaytime}
          formattedPhoneMobile={contact.formattedPhoneMobile}
          formattedPhoneAlt={contact.formattedPhoneAlt}
          searchTerm={props.filters.searchTerm}
          contact={contact}
          handleInvitations={handleInvitations}
          updateComponent={props.updateComponent}
          setUpdateComponent={props.setUpdateComponent}
          key={`${idx}-${filter}-${source}`}
          uniqueKey={`${idx}-${source}-${filter}`}
          companyId={props.state.companyId}
          reviewAction={updateReviewDetailsDisplay}
          image={more}
          reviewMeActive={reviewMeActive}
          updateReviewDetailsDisplay={updateReviewDetailsDisplay}
          initials={showInitials(contact[currRow[0]])}
          name={StringUtil.highlightWithinWord(
            reviewMeActive() ? contact[currRow[0]] : contact[currRow[1]],
            props.filters.searchTerm,
          )}
          email={StringUtil.highlightWithinWord(
            contact[currRow[2]],
            props.filters.searchTerm,
          )}
          dateOne={contact[currRow[3]]}
          dateTwo={contact[currRow[4]]}
          status={contact[currRow[5]]}
          source={props.state.source}
          authHeader={props.state.authHeader}
          reviewMeActive={reviewMeActive}
        />
      );
    });
  };

  const sortDataUp = (sortDirection, sortIdx) => {
    let sortField = props.state.sortFields[props.state.source][sortIdx];
    setColumn(sortField);
    setDirection(sortDirection);
  };

  const sortDataDown = (sortDirection, sortIdx) => {
    let sortField = props.state.sortFields[props.state.source][sortIdx];
    setColumn(sortField);
    setDirection(sortDirection);
  };

  let { headers } = props.state;
  let currHeaders = headers[props.state.source];

  const handlePageRange = () => {
    if (props.totalCount < 11) return 1;
    if (props.totalCount < 21) return 2;
    if (props.totalCount < 31) return 3;
    if (props.totalCount < 41) return 4;
    if (props.totalCount < 51) return 5;
  };

  const isThereNoData = () => {
    let counter = 0;
    let { sourceData } = props.state;

    if (!sourceData) return null;

    for (const [key, value] of Object.entries(sourceData)) {
      counter += value;
    }

    return counter === 0;
  };

  const handleNoDataMessages = () => {
    let messageRes = {};

    if (isThereNoData())
      return {
        headerMessage: "No results yet",
        message: "You haven't sent any review requests. Click Get Reviews",
      };

    return {
      headerMessage: NO_RECORD_FOUND.HEADING,
      message: NO_RECORD_FOUND.MESSAGE,
    };
  };

  const showNoData = () => {
    return props.totalCount === 0 || !props.totalCount;
  };

  const areFiltersApplied = () => {
    return (
      props.filters.searchTerm ||
      props.state.filter !== "all" ||
      (dateRange && !/30 days/i.test(dateRange.label))
    );
  };

  const resetFilters = () => {
    props.setFilters({
      ...props.filters,
      searchTerm: "",
    });
    props.setKioskHandwrittenSource("Handwritten");
    props.setReset(Date.now());
  };

  // render
  return (
    <div id="review-list" className="row">
      {showNoData() ? (
        <div className="col-12 text-center">
          <NoDataFoundComponent
            dateRange={dateRange}
            heading={handleNoDataMessages().headerMessage}
            message={handleNoDataMessages().message}
          />
        </div>
      ) : (
        <div className="col-12">
          {reviewDetailsDisplay ? (
            <ModalPopupRight
              isModalOpen={reviewDetailsDisplay}
              modalClose={updateReviewDetailsDisplay}
              modalHeader="Review Details"
              modalBody={
                <ReviewDetails
                  reviewData={selectedReview}
                  companyUserId={companyUserId}
                  authHeader={authHeader}
                  reviewId={selectedReview}
                />
              }
            />
          ) : null}
          <div className="row">
            <div className="col-12">
              <TableMeta
                label={dateRange.label}
                searchTerm={props.filters && props.filters.searchTerm}
                filtersApplied={areFiltersApplied()}
                resetFilters={resetFilters}
              />
            </div>
          </div>
          <div className="row mt-2" id="track-table-container">
            <div className="col-12">
              <Header
                sortDataUp={sortDataUp}
                sortDataDown={sortDataDown}
                upArrow={UpArrow}
                downArrow={DownArrow}
                rowOne={currHeaders[0]}
                rowTwo={currHeaders[1]}
                rowThree={currHeaders[2]}
                rowFour={currHeaders[3]}
                rowFive={currHeaders[4]}
                rowSix={reviewMeActive() ? currHeaders[5] : null}
                reviewMeActive={reviewMeActive}
              />
              <div className="hold-rows">{presentTableRows()}</div>
            </div>
          </div>
          {reviewListData.length > PAGE_SIZE ? (
            <PaginatorComponent
              pageCount={Math.ceil(props.totalCount / 10)}
              pageChangeHandler={onPagination}
              currentPage={props.state.currentPage}
            />
          ) : (
            <div className="p-3"></div>
          )}
        </div>
      )}
    </div>
  );
};
export default TrackReviewListComponent;
