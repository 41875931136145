import React, {useEffect, useState} from 'react';
import "./PrivateFeedbackReviewComponent.scss"
import PrivateFeedbackHeader from '../private-feedback-header/PrivateFeedbackHeader'
import {GET_PRIVATE_FEEDBACK_DETAILS} from "../graphql_queries/queries";
import {query} from '../../../../../../shared/GraphQLHandler';

/**
 * Component to render the private feedback review component.
 * @param review - review to get the review details.
 * @param companyId - company id for logged in user.
 * @param client - Client to execute graphQL.
 * @returns {XML} -> Return the PrivateFeedbackReviewComponent
 */
const PrivateFeedbackReviewComponent = ({review, companyId, client}) => {

  /**
   * Method invoked to get private feedback details.
   */
  const getPrivateFeedbackDetails = () => {
    let variables = {
      companyId: companyId,
      id: review.id
    }
    // Executing private feedback details graphql
    query(client, GET_PRIVATE_FEEDBACK_DETAILS, function (error, response) {
      if (response && response.data && response.data.privateFeedbackDetails) {
        setPrivateFeedback(response.data.privateFeedbackDetails);
      } else {
        console.log('Error in getting Private Feedback list: ', error);
      }
    }, variables);
  };

  let [privateFeedback, setPrivateFeedback] = useState();

  /**
   * Setting up initial state for the component by getting private feedback details.
   */
  useEffect(() => {
    getPrivateFeedbackDetails();
  }, []);


  return (
    <div className="row">
      {privateFeedback && <div className="col-12  pl-0 pl-md-2 pt-2">
        <div id="private-feedback-review-component">
          <div className="row">
            <div className="col-12 pr-lg-0">
              <div className="tile ml-3 mr-3 mt-0">
                <PrivateFeedbackHeader privateFeedback={privateFeedback}/>
                  <div className="row">
                    <div className="offset-md-1 pl-lg-0 col-11 mt-2">
                      {privateFeedback.records.map((suggestion, index) => (
                        <div className="pt-4" key={index}>
                          <p className="private-feedback-header mb-0">
                            {suggestion.reviewId ? suggestion.suggestionQuestion.suggestionTopic : 'Feedback'}
                          </p>
                          <p className="private-feedback-body">
                            {suggestion.suggestionAnswer}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
}

export default PrivateFeedbackReviewComponent;