import React from "react";
import Container from "./components/layout/Container";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import {
  MEMBERS_NEW_COMPANY_URL,
  API_GRAPHQL_URL,
  MEMBERS_URLS,
} from "./shared/Config";
import ReactGA from "react-ga";
import Routes from "src/routes/Routes";
import StyleGuide from "src/components/styleguide/StyleGuide";
import { showAuthHeader, showCompanyId } from "src/shared/SessionHelper";
import ErrorBoundary from "src/shared/errorpages/ErrorBoundary";
import ChartUtil from "src/utils/ChartUtil";
import { init as SentryInit } from "@sentry/browser";
import GraphQLUtil from "src/utils/GraphQLUtil";
import DirectConnectAndCreditToast from "src/components/company/DirectConnectAndCreditToast";
import * as sessionHelper from "src/shared/SessionHelper";
import "./App.scss";

// init apollo client
const client = new ApolloClient({
  uri: API_GRAPHQL_URL,
  request: (operation) => {
    operation.setContext({
      headers: {
        ...showAuthHeader.headers,
      },
    });
  },
  onError: (err) => {
    if (GraphQLUtil.getError(err) === "unauthorized") {
      window.location = `${MEMBERS_NEW_COMPANY_URL}/dashboard`;
    }
  },
});

// init sentry
if (process.env.REACT_APP_ENV !== "development") {
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
  });
}

// init highchart configs
ChartUtil.init();

const App = () => {
  // effects
  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.initialize(process.env.REACT_APP_GTAG_SRC);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  // checks
  if (!showCompanyId) {
    sessionHelper.removeCookies();
    sessionStorage.clear();
    window.location = MEMBERS_URLS.login;
    return null;
  }

  // render
  return (
    <ErrorBoundary>
        <ApolloProvider client={client}>
          <Routes
            routes={[
              {
                id: 0,
                path: "/companies/*",
                render: () => (
                  <React.Fragment>
                    <DirectConnectAndCreditToast />
                    <Container client={client} />
                  </React.Fragment>
                ),
                exact: true,
              },
              { id: 1, path: "/styleguide", render: () => <StyleGuide /> },
            ]}
          />
        </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
