import React, { useState, useEffect, useRef } from "react";
import Chart from "./Chart";
import Card from "src/shared/styleguide/card/Card";
import BlockUi from "react-block-ui";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import "./Chart.scss";

export const CardChart = ({
  popupInfoMsg,
  title,
  chartId,
  chartConfig,
  noData = false,
  loading = false,
  ...props
}) => {
  // state
  return (
    <BlockUi
      loader={loading ? undefined : <React.Fragment />}
      className="card-chart"
      blocking={noData || loading}
    >
      <Card
        padding={0}
        popupInfoMsg={popupInfoMsg}
        title={<div className="pl-4 pt-4 pb-2 d-inline-block">{title}</div>}
        {...props}
      >
        <Chart chartId={chartId} chartConfig={chartConfig} />
      </Card>
    </BlockUi>
  );
};
CardChart.propTypes = {
  popupInfoMsg: PropTypes.string,
  title: PropTypes.string.isRequired,
  chartId: PropTypes.string.isRequired,
  chartConfig: PropTypes.object.isRequired,
  noData: PropTypes.bool,
};

export const CardChartWithGraphQL = ({
  popupInfoMsg,
  title,
  chartId,
  chartConfig,
  gqlConfig,
  noData = false,
}) => {
  // state
  const [hasNoData, setNoData] = useState(noData);
  const chartRef = useRef();

  // graphql
  const { data, loading } = useQuery(gqlConfig.query, {
    ...gqlConfig.queryOptions,
  });
  useEffect(() => {
    if (gqlConfig.hasData(data) && chartRef && chartRef.current) {
      const seriesData = gqlConfig.getSeries(data);
      if (
        seriesData.data.filter((a) => a === 0).length === seriesData.data.length
      ) {
        setNoData(true);
      }

      if (chartRef.current.getChart) {
        chartRef.current.getChart().update({
          series: [seriesData],
        });
      }
    }
  }, [data, chartRef]);

  // render
  return (
    <BlockUi
      loader={<React.Fragment />}
      className="card-chart"
      blocking={hasNoData || loading}
    >
      <Card
        padding={0}
        popupInfoMsg={popupInfoMsg}
        title={<div className="pl-4 pt-4 pb-2 d-inline-block">{title}</div>}
      >
        <Chart ref={chartRef} chartId={chartId} chartConfig={chartConfig} />
      </Card>
    </BlockUi>
  );
};

CardChartWithGraphQL.propTypes = {
  popupInfoMsg: PropTypes.string,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  chartId: PropTypes.string.isRequired,
  chartConfig: PropTypes.object.isRequired,
  gqlConfig: PropTypes.object.isRequired,
  noData: PropTypes.bool,
};

export default CardChart;
