import React from "react";
import PropTypes from "prop-types";
import BlockUi from "react-block-ui";
import "./SidebarMenuComponent.scss";
import NumberUtil from "src/utils/NumberUtil";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";

/**
 * sidebar menu component
 * @param {*} title title : Menu Title
 * @param {*} menuItems menuItems : Menu items to be display
 * eg. menuItems = [{label:string,count:number,value:string,icon:image}]
 * @param {*} menuItemsFilters menuItemsFilters : selected menuItem object
 * @param {*} clickHandler clickHandler: on click action
 */
const SidebarMenuComponent = ({
  clickHandler,
  disabled = false,
  eventName,
  menuItems,
  menuItemsFilters,
  title,
  addHoverMessage = false,
}) => {
  const getSidebarMenuComponent = (menu) => {
    return (
      <span className="menu-label">
        {menu.label}{" "}
        {menu.count_formatted !== undefined
          ? `(${NumberUtil.toK(menu.count_formatted, 1)})`
          : menu.count !== undefined && `(${menu.count})`}
      </span>
    );
  };

  return (
    <div id="SidebarMenuComponent">
      {menuItems ? (
        <div className="mt-4">
          <div className="filter-title">{title}</div>
          <BlockUi
            blocking={disabled}
            className="sidebar-menu-disabled"
            loader={<React.Fragment />}
            style={{
              height: "100%",
            }}
          >
            <div className="mt-3">
              {menuItems.map((menu, index) => (
                <div
                  key={index}
                  className={`menu-style ${
                    menuItemsFilters.value === menu.value ? "active-menu" : ""
                  }`}
                >
                  <a
                    className="menu-text d-block"
                    value={menu.value}
                    onClick={() => {
                      clickHandler(eventName, menu);
                    }}
                  >
                    {menu.icon || menu.iconSvg ? (
                      menu.iconSvg ? (
                        <span className="menu-icon mx-xl-4 mx-md-3">
                          {menu.iconSvg}
                        </span>
                      ) : (
                        <img
                          src={menu.icon}
                          className="menu-icon mx-xl-4 mx-md-3"
                          alt="menu icon"
                        />
                      )
                    ) : (
                      <div className="menu-icon mx-xl-4 mx-md-3"></div>
                    )}
                    {addHoverMessage && menu.hoverText ? (
                      <PopupInfo
                        placement="right"
                        message={menu.hoverText}
                        icon={getSidebarMenuComponent(menu)}
                      />
                    ) : (
                      getSidebarMenuComponent(menu)
                    )}
                  </a>
                </div>
              ))}
            </div>
          </BlockUi>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

SidebarMenuComponent.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  eventName: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  menuItemsFilters: PropTypes.object.isRequired,
  title: PropTypes.node,
};
export default SidebarMenuComponent;

