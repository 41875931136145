import React from "react";

const STORAGE = sessionStorage;

export default function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(() => {
    return Object.prototype.hasOwnProperty.call(STORAGE, key)
      ? JSON.parse(STORAGE.getItem(key))
      : defaultValue;
  });
  React.useEffect(() => {
    STORAGE.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
