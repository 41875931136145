import React from "react";
import Card from "src/shared/styleguide/card/Card";
import PropTypes from "prop-types";
import "./SettingsCard.scss";

const SettingsCard = ({
  loading,
  settingsIcons = null,
  settingsName,
  settingsDescription,
  settingsForm,
  cardRight = null,
  cardStyle = null,
  cardBodyStyle = null,
  flat = false,
  id = undefined,
  className = ""
}) => {
  // checks
  if (loading) {
    return null;
  }

  // render
  return (
    <div id={id} className="mt-3 mb-3">
      <Card flat={flat} cardStyle={cardStyle} cardBodyStyle={cardBodyStyle} className={className}>
        <div className="d-flex flex-row">
          {settingsIcons ? (
            <div className="d-flex settings-card-icon mr-3">
              {settingsIcons}
            </div>
          ) : null}
          <div className="d-flex flex-column flex-grow-1">
            {/* header */}
            {settingsName ? (
              <div
                style={{ lineHeight: 0 }}
                className="font-weight-bold h2 text-dark m-0 d-flex align-items-center settings-card-name"
              >
                {settingsName}
              </div>
            ) : null}
            {/* description */}
            <div className="settings-card-description">
              {settingsDescription}
            </div>
            {settingsForm}
          </div>
          <div>{cardRight}</div>
        </div>
      </Card>
    </div>
  );
};

SettingsCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  settingsIcons: PropTypes.node,
  settingsName: PropTypes.node.isRequired,
  settingsDescription: PropTypes.node.isRequired,
  settingsForm: PropTypes.node.isRequired,
  cardRight: PropTypes.node,
  cardStyle: PropTypes.object,
  cardBodyStyle: PropTypes.object,
  flat: PropTypes.bool,
  id: PropTypes.string,
};

export default SettingsCard;
