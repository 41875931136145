import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EditEmailModal from "./EditEmailModal";
import { contactPageUrl } from "src/shared/Utils.js";
import ResendConfirmationModal from "./ResendConfirmationModal";

const Options = ({
  display,
  optionEl,
  companyId,
  authHeader,
  contact,
  source,
  updateReviewDetailsDisplay,
  handleInvitations,
  updateComponent,
  setUpdateComponent,
}) => {
  // hardcoded change later
  // let emailInvitationId = 87977
  const history = useHistory();
  const [modalDisplay, setModalDisplay] = useState("none");

  const handleModalClick = () => {
    setModalDisplay("flex");
  };

  const handleDiv = () => {
    if (source === "Reviewme") {
      if (contact)
        return (
          <div
            onClick={() => updateReviewDetailsDisplay(contact)}
            className="option-one"
          >
            View Review
          </div>
        );
    }

    if (
      source === "Email" &&
      (contact &&
        (contact.status === "bounced" || contact.status === "invalid_email"))
    ) {
      return (
        <div onClick={handleModalClick} className="option-one">
          Resend Email
        </div>
      );
    }

    if (
      (source === "Handwritten" || source === "Kiosk") &&
      (contact &&
        (contact.status === "wrong_number" || contact.status === "bounced"))
    ) {
      return (
        <div onClick={handleModalClick} className="option-one">
          Resend Confirmation
        </div>
      );
    }

    if (
      contact &&
      (source === "CustomInvites" ||
        contact.status === "reviewed" ||
        contact.status === "completed" ||
        contact.status === "confirmed")
    ) {
      return (
        <div
          onClick={() => updateReviewDetailsDisplay(contact)}
          className="option-one"
        >
          View Review
        </div>
      );
    }
    return null;
  };

  const modalHelper = () => {
    if (source === "Email") {
      if (contact)
        return (
          <EditEmailModal
            handleInvitations={handleInvitations}
            contact={contact}
            authHeader={authHeader}
            setDisplay={setModalDisplay}
            display={modalDisplay}
            updateComponent={updateComponent}
            setUpdateComponent={setUpdateComponent}
          />
        );
    }

    if (source === "Handwritten" || source === "Kiosk") {
      if (contact)
        return (
          <ResendConfirmationModal
            authHeader={authHeader}
            contact={contact}
            display={modalDisplay}
            handleInvitations={handleInvitations}
            setDisplay={setModalDisplay}
            setUpdateComponent={setUpdateComponent}
            updateComponent={updateComponent}
          />
        );
    }

    return null;
  };

  return (
    <React.Fragment>
      <div ref={optionEl} className="options-outer-container">
        <div className="options-container">
          {handleDiv()}
          <span
            onClick={() => {
              history.push(contactPageUrl(contact.contactId, companyId), {
                prevPage: document.location.pathname,
              });
            }}
            className="option-two"
          >
            View Customer Details
          </span>
        </div>
      </div>
      {modalHelper()}
    </React.Fragment>
  );
};

export default Options;
