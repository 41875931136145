import React, { useContext, useEffect, useState } from "react";
import SidebarMenuComponent from "src/shared/sidebar-menu/SidebarMenuComponent";
import SidebarMenuDropdownGrouped from "src/shared/sidebar-menu/SidebarMenuDropdownGrouped";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import { SegmentsContext } from "./SegmentsContext";
import { SEGMENT_SORT_BY } from "../customerConstants";
import classnames from "classnames";
import NumberUtil from "src/utils/NumberUtil";
import { SEGMENT_METADATA } from "src/graphql/customers_segments/customers/queries.js";
import { useApolloClient } from "@apollo/react-hooks";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import { showCompanyId } from "src/shared/SessionHelper";

const SegmentsSidebar = () => {
  const client = useApolloClient();

  const {
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    viewByMenuItems,
    setViewByMenuItems,
    filterBy,
    setFilterBy,
    sortBy,
    setSortBy,
    filterCount,
    setFilterCount,
  } = useContext(SegmentsContext);

  // methods
  /**
   * Setting the label with count for view by filter
   * @param {string} label -- Label of the filter
   * @param {string} value -- Value of selected filter
   * @param {number} count -- Count of the result for selected filter
   * @returns the label with count for view by filter
   */
  const getLabelWithCount = (label, value, count) => {
    let isActive = false;
    let countLabel = "";
    if (
      filters &&
      filters.selectedViewBy &&
      filters.selectedViewBy.value === value
    ) {
      isActive = true;
    }
    if (count !== undefined) {
      countLabel = (
        <span title={count}> ({NumberUtil.toK(count, 1, true)})</span>
      );
    }
    return (
      <span
        className={classnames({
          "font-weight-bold": isActive,
        })}
      >
        {label}
        {countLabel}
      </span>
    );
  };

  // variables
  // Returning the view by filter with icons
  const getViewByMenuItems = (props) => {
    return [
      {
        label: getLabelWithCount("All", "all", props.all),
        labelString: "All",
        value: "all",
        iconSvg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.9"
            height="19.9"
            viewBox="0 0 19.9 19.9"
          >
            <path
              data-name="Path 12363"
              d="M16.547 16.366a.2.2 0 0 1-.182.182h-9.03A1.138 1.138 0 0 1 6.2 15.413V7.335A1.138 1.138 0 0 1 7.335 6.2h8.078a1.138 1.138 0 0 1 1.135 1.135zm-.182 2.088h-6.33A1.138 1.138 0 0 0 8.9 19.588v5.378a1.138 1.138 0 0 0 1.135 1.134h5.378a1.138 1.138 0 0 0 1.135-1.135v-6.33a.2.2 0 0 0-.182-.182zm9.712 1.135a1.138 1.138 0 0 0-1.135-1.135h-6.307a.2.2 0 0 0-.182.182v6.308a1.138 1.138 0 0 0 1.135 1.135h5.378a1.138 1.138 0 0 0 1.134-1.136v-5.355zm0-9.553A1.138 1.138 0 0 0 24.943 8.9h-5.355a1.138 1.138 0 0 0-1.135 1.135v6.331a.2.2 0 0 0 .182.182h6.308a1.138 1.138 0 0 0 1.135-1.135v-5.378z"
              transform="translate(-6.2 -6.2)"
            />
          </svg>
        ),
      },
      {
        label: getLabelWithCount("Default Segments", "default", props.default),
        labelString: "Default",
        value: "default",
        iconSvg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
          >
            <g data-name="noun_Pie Chart_624648">
              <path
                data-name="Path 12364"
                d="M8.471 5.66a8.471 8.471 0 1 0 8.471 8.471H8.471z"
                transform="translate(0 -3.602)"
              />
              <path
                data-name="Path 12365"
                d="M52.83 0v8.821h8.821A8.821 8.821 0 0 0 52.83 0zm.529.546a8.305 8.305 0 0 1 7.746 7.746h-7.746z"
                transform="translate(-42.651)"
              />
            </g>
          </svg>
        ),
      },
      {
        label: getLabelWithCount("Custom Segments", "custom", props.custom),
        labelString: "Custom",
        value: "custom",
        iconSvg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18.893"
            viewBox="0 0 19 18.893"
          >
            <path
              data-name="Path 12366"
              d="M-181.714 226.083h-.881v-.883h.881zm8.006-1.628a8.449 8.449 0 0 1 2.108 5.6v.454h-8.913V221.6h.454a8.479 8.479 0 0 1 6.351 2.855zm-16.011 7.579a7.6 7.6 0 0 1 7.552-7.579h.454v-.881h-.881a8.465 8.465 0 0 0-8.006 8.459 8.462 8.462 0 0 0 8.459 8.459 8.484 8.484 0 0 0 8.459-8.006v-.908h-.881v.48a7.579 7.579 0 0 1-7.579 7.525 7.534 7.534 0 0 1-7.577-7.549zm10.327-.427v.881h.881v-.881zm-1.6 0v.881h.881v-.881zm4.8 0v.881h.881v-.881zm-1.6 0v.881h.881v-.881zm-4.8.881h.881v-.881h-.881zm0-4.8h.881v-.888h-.881zm0 1.6h.881v-.888h-.881zm0 1.6h.881v-.881h-.881z"
              transform="translate(190.6 -221.6)"
            />
          </svg>
        ),
      },
      {
        label: getLabelWithCount("Favorite", "favorite", props.favorite),
        labelString: "Favorite",
        value: "favorite",
        iconSvg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19.201"
            viewBox="0 0 20 19.201"
          >
            <path
              data-name="star (1)"
              d="M19.441 19.319a1.845 1.845 0 0 0-1.022-3.147l-4.324-.628a.674.674 0 0 1-.507-.369l-1.934-3.918a1.845 1.845 0 0 0-3.309 0l-1.933 3.918a.674.674 0 0 1-.507.369l-4.324.628a1.845 1.845 0 0 0-1.023 3.147l3.129 3.05a.673.673 0 0 1 .194.6l-.739 4.307a1.845 1.845 0 0 0 2.677 1.945l3.867-2.033a.673.673 0 0 1 .626 0l3.868 2.033a1.845 1.845 0 0 0 2.677-1.945l-.739-4.307a.673.673 0 0 1 .194-.6z"
              transform="translate(0 -10.229)"
            />
          </svg>
        ),
      },
      {
        label: getLabelWithCount("Archive", "archive", props.archived),
        labelString: "Archive",
        value: "archived",
        iconSvg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g data-name="Outlined-/-Content-/-archive">
              <g data-name="Group 12816">
                <path d="M0 0h24v24H0z" style={{ fill: "none" }} />
                <path
                  data-name="🔹-Icon-Color"
                  d="m20.54 5.23-1.39-1.68A1.451 1.451 0 0 0 18 3H6a1.486 1.486 0 0 0-1.16.55L3.46 5.23A1.958 1.958 0 0 0 3 6.5V19a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V6.5a1.958 1.958 0 0 0-.46-1.27zM6.24 5h11.52l.81.97H5.44zM5 19V8h14v11zm8.45-9h-2.9v3H8l4 4 4-4h-2.55z"
                  style={{ fill: "" }}
                />
              </g>
            </g>
          </svg>
        ),
      },
    ];
  };

  // hooks

  useEffect(() => {
    setViewByMenuItems(getViewByMenuItems(filterCount));
  }, [filterCount]);

  return (
    <div className="sidebar-view">
      <div className="search-title">Search</div>
      {/* search */}
      <SearchInput
        maxLength={30}
        value={searchTerm}
        onChange={(evt) => {
          setSearchTerm(evt.target.value);
        }}
        placeholder="Search by Segment"
      />
      <div className="mt-2">
        {/* view by */}
        <div className="view-by">
          <SidebarMenuComponent
            title="View By"
            menuItems={viewByMenuItems}
            menuItemsFilters={filterBy}
            clickHandler={(evtName, viewBy) => {
              setFilterBy(viewBy);
            }}
            eventName="viewBy"
          />
        </div>
        {/* sort by */}
        <SidebarMenuDropdownGrouped
          title="Sort By"
          value={sortBy}
          name="sort-by"
          isClearable={false}
          onChange={(item) => {
            setSortBy(item);
          }}
          options={SEGMENT_SORT_BY}
          isOptionDisabled={(option) => option.disabled}
          inputProps={{ readOnly: true }}
        />
      </div>
    </div>
  );
};

export default SegmentsSidebar;
