import { gql } from "apollo-boost";

export const GET_STARTED_COMPANY_STATUS = gql`
  query companyGetStartedStatus($companyId: ID!) {
    company(companyId: $companyId) {
      logoUrl
      website
      timezone
      timezoneAbbrevation
      zip
    }
    globalCampaignSetting(companyId: $companyId) {
      email
      emailFirstName
    }
    getCompanyInformation(companyId: $companyId) {
      setting {
        twilioPhone
      }
    }
    companyDataProcessingStatus(companyId: $companyId) {
      status
      dataUploadCount
      companyInsightsStatus
    }
  }
`;

export const COMPANY_DATA_PROCESSING_STATUS = gql`
  query companyDataProcessingStatus($companyId: ID!) {
    companyDataProcessingStatus(companyId: $companyId) {
      status
      dataUploadCount
      companyInsightsStatus
    }
  }
`;
