import React, { Suspense, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as config from "src/shared/Config";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import CustomersComponent from "src/components/customers_segments/CustomersComponent";
import BlockUi from "react-block-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addToast } from "src/utils/ToastUtil";
import isEqual from "lodash.isequal";
import ContactsIcon from "src/assets/images/common/group-icon.svg";
import Form from "src/shared/styleguide/form/Form";
import classnames from "classnames";
import { Accordion, Col, Row, Button, Collapse } from "react-bootstrap";
import AccordionToggle from "src/shared/styleguide/accordiontoggle/AccordionToggle";
import Card from "src/shared/styleguide/card/Card";
import ReadMoreElements from "src/shared/styleguide/common/ReadMoreElements.jsx";
import {
  clearFilterStorage,
  setCompanyIdStorage,
  setFilterStorage,
  updateFilterStorageExpiration,
} from "./SegmentLocalStorageHelper.js";
import addIcon from "src/assets/images/common/add-outline.svg";
import rightArrow from "src/assets/images/common/right-arrow.svg";
import SegmentInUseModal from "src/components/customers_segments/segments/segment/SegmentInUseModal";
import SegmentIsDefaultModal from "src/components/customers_segments/segments/segment/SegmentIsDefaultModal";
import { showCompanyId } from "src/shared/SessionHelper";
import {
  GET_COMPANY_FILTER_METRICS,
  GET_CUSTOM_FILTERS_LIST,
  GET_FILTER,
  GET_FILTERS_LIST,
  QUERY_CONTACTS_META_LIST,
} from "src/graphql/customers_segments/segment/queries.js";
import {
  CREATE_FILTER,
  UPDATE_FILTER,
  CREATE_CUSTOM_FILTER_CSV,
} from "src/graphql/customers_segments/segment/mutations";
import { NO_CACHE, msgConstants } from "src/shared/Constants";
import debounce from "lodash.debounce";
import { stringConvertor } from "src/shared/Utils";
import {
  confirmExit,
  getFilterFromStorage,
  getTouchedData,
  populateCompanyFilterMetrics,
  populateFormState,
  shapeForDisplay,
  shapeForForm,
  shapeForSubmit,
  soruceData,
  jobTypeFormatter,
} from "./SegmentUtils";
import {
  PAGE_LENGTH,
  initialCompanyFilterMetrics,
  initialMetaData,
  initialState,
  learnMoreContent,
  segmentTipsContent,
} from "./SegmentConstants";
import SegmentEmptyState from "./SegmentEmptyState";
import SegmentInclude from "./SegmentInclude";
import SegmentExclude from "./SegmentExclude";
import SegmentCancel from "./SegmentCancel";
import SegmentNameInput from "./SegmentNameInput";
import "./SegmentContent.scss";
import { numberFormatter } from "src/shared/Utils";
import Pusher from "pusher-js";

// lazy load
const UploadFilter = React.lazy(() =>
  import("src/components/upload_segment/UploadFilter"),
);

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

const SegmentContent = () => {
  const client = useApolloClient();
  const history = useHistory();
  const { filterId } = useParams();
  const localFilterId = filterId || "new";
  const localName = getFilterFromStorage(localFilterId)
    ? getFilterFromStorage(localFilterId).name
    : "";
  const localMetaData = getFilterFromStorage(localFilterId)
    ? getFilterFromStorage(localFilterId).metaData
    : {};

  // state
  const [filterOriginalName, setfilterOriginalName] = useState("");
  const [csvDownloadData, setCsvDownloadData] = React.useState(null);
  const [companyFilterMetrics, setCompanyFilterMetrics] = useState(
    initialCompanyFilterMetrics,
  );
  const [showUploadAdvancedFilterModal, setShowUploadAdvancedFilterModal] =
    useState(false);
  const [activeCard, setActiveCard] = useState(filterId ? "include" : null);
  const [activeSection, setActiveSection] = useState(null);
  const [nameIsValid, setNameIsValid] = useState(true);
  const [metaData, setMetaData] = useState({});
  const [isDefault, setIsDefault] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [fetchedFormData, setFetchedFormData] = useState(null);
  const [fetchedMetaData, setFetchedMetaData] = useState(null);
  const [formState, setFormState] = useState(initialState);

  const [showSegmentInUseModal, setShowSegmentInUseModal] = useState(false);
  const [showSegmentIsDefaultUseModal, setShowSegmentIsDefaultModal] =
    useState(false);
  const [rawStatesData, setRawStatesData] = useState({});
  const [rawCitiesData, setRawCitiesData] = useState({});
  const [rawZipCodesData, setRawZipCodesData] = useState({});
  const [customersList, setCustomersList] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCustomersFound, setTotalCustomersFound] = useState(0);
  const [filtersList, setFiltersList] = useState([]);
  const [customFiltersList, setCustomFiltersList] = useState([]);
  const [viewContactsOpen, setViewContactsOpen] = useState(false);
  const [selectedCriteriaOpen, setSelectedCriteriaOpen] = useState(true);
  const [segmentSearchTerm, setSegmentSearchTerm] = useState("");
  const [sourceAggregates, setSourceAggregates] = useState();
  const [jobTypes, setJobTypes] = useState([]);

  const formik = useFormik({
    initialValues: {
      // Flatten values since we reshape upon save
      name: initialState.name,
      // Include: Customer Details
      commercial: initialState.commercial,
      source: initialState.source,
      in_contract: initialState.in_contract,
      service_contract_type: initialState.service_contract_type,
      contract_start_date: initialState.contract_start_date,
      contract_end_date: initialState.contract_end_date,
      state: initialState.state,
      city: initialState.city,
      zip: initialState.zip,
      has_mailing_address: initialState.has_mailing_address,
      has_mobile_number: initialState.has_mobile_number,
      has_email_address: initialState.has_email_address,
      // Include: Transactions
      job_type: initialState.job_type,
      count: initialState.count,
      months: initialState.months,
      days: initialState.days,
      amount: initialState.amount,
      amount_per_txn: initialState.amount_per_txn,
      txn_date: initialState.txn_date,
      custom_filter_ids: initialState.customer_filter_id.values,
      // Exclude: Transactions
      not_count: initialState.not_count,
      not_amount: initialState.not_amount,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      // Include: Contact Details
      commercial: Yup.array().nullable(),
      source: Yup.array().nullable(),
      in_contract: Yup.array().nullable(),
      service_contract_type: Yup.array().nullable(),
      contract_start_date: Yup.object().nullable(),
      contract_end_date: Yup.object().nullable(),
      state: Yup.array().nullable(),
      city: Yup.array().nullable(),
      zip: Yup.array().nullable(),
      has_mailing_address: Yup.array().nullable(),
      has_mobile_number: Yup.array().nullable(),
      has_email_address: Yup.array().nullable(),
      // Include: Transactions
      job_type: Yup.array().nullable(),
      count: Yup.object().nullable(),
      months: Yup.object().nullable(),
      days: Yup.object().nullable(),
      amount: Yup.object().nullable(),
      amount_per_txn: Yup.object().nullable(),
      txn_date: Yup.object().nullable(),
      // Include: Advanced Filter
      custom_filter_ids: Yup.array().nullable(),

      // Exclude: Transactions
      not_count: Yup.object(),
      not_amount: Yup.object(),
    }),
    onSubmit: (values) => {
      saveHandler({ ...values });
    },
  });

  // methods
  const saveHandler = (values) => {
    if (nameIsValid) {
      createFilter();
    } else {
      formik.setErrors({
        ...formik.errors,
        name: "invalid submit",
      });
      addToast({
        type: "error",
        message: "Please enter segment name",
      });
    }
  };

  const updateHandler = (values) => {
    if (isDefault) {
      if (formik.values.name.substring(0, 7) !== "Custom ") {
        formik.setFieldValue("name", `Custom ${formik.values.name}`);
      }
      setShowSegmentIsDefaultModal(true);
      return;
    }

    if (nameIsValid) {
      updateFilter();
    } else {
      formik.setErrors({
        ...formik.errors,
        name: "invalid submit",
      });
      addToast({
        type: "error",
        message: "Invalid segment name",
      });
    }
  };

  const updateFilterData = (filterId, metaData, filter) => {
    if (filter) {
      filter.metaData = metaData;
    }

    setFilterStorage(filterId, filter);
    updateFilterStorageExpiration(filterId);
  };

  const segmentNameChangeHandler = (value) => {
    formik.setFieldValue("name", value);
  };

  const segmentCustomNameChangeHandler = (value) => {
    formik.setFieldValue("name", value);
  };

  const segmentNameErrorHandler = (value) => {
    formik.setErrors("name", value);
  };

  const addCriteriaElement = (type) => {
    return (
      <div
        className="add-criteria-container"
        onClick={() => {
          setActiveCard(type);
          setActiveSection(null);
        }}
      >
        <div className="add-criteria">
          <img src={addIcon} alt="Add Criteria" />
          Add Criteria
        </div>
      </div>
    );
  };

  const showCriteriaElement = (type, criteria = {}) => {
    const divider = <span className="show-criteria-divider">|</span>;

    let allowed = ["customer_details", "transactions", "advance_filter"];

    if (type === "exclude") {
      allowed = ["exclude_transactions"];
    }

    const displayObject = Object.keys(criteria)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        const displayKey =
          key === "exclude_transactions"
            ? "transactions"
            : key === "advance_filter"
              ? "advanced_filter"
              : key;
        obj[displayKey] = criteria[key];

        return obj;
      }, {});

    return (
      <div
        className="show-criteria-container"
        onClick={() => setActiveCard(type)}
      >
        {Object.keys(displayObject).length ? (
          Object.keys(displayObject).map((item, index) => (
            <div
              key={`segment-${type}-${item}`}
              className="show-criteria-category"
            >
              <div>
                <b>{stringConvertor(item)}</b>
              </div>
              {Object.keys(displayObject[item]).map((el, i) => {
                if (typeof displayObject[item][el] === "object") {
                  return (
                    <span key={`segment-${type}-${item}-${el}`}>
                      {stringConvertor(el)}:{" "}
                      <b>
                        {displayObject[item][el].map(
                          (node, n) =>
                            `${stringConvertor(node)}${n < displayObject[item][el].length - 1 ? ", " : ""
                            }`,
                        )}
                      </b>{" "}
                      {i < Object.keys(displayObject[item]).length - 1
                        ? divider
                        : ""}
                    </span>
                  );
                } else {
                  return `${displayObject[item][el]}${i < displayObject[item].length - 1 ? ", " : ""
                    }
                  `;
                }
              })}
            </div>
          ))
        ) : (
          <span>
            Select Criteria <img src={rightArrow} className="ml-1" />
          </span>
        )}
      </div>
    );
  };

  const setSelectMulti = ({ include, type, items, key }) => {
    let newItems = [...items];
    if (items.length > 1 && items.slice(-1)[0].value === "any") {
      newItems = [items.pop()];
    } else if (items.length > 1) {
      newItems = items.filter((item) => item.value !== "any");
    }

    formik.setFieldTouched(
      key,
      !isEqual(newItems, initialState[key]) && !!newItems.length,
    );
    formik.setFieldValue(key, newItems);
  };

  const CustomToggle = ({ children, disabled, eventKey }) => {
    return (
      <Button
        className="float-right d-block custom-toggle-button"
        disabled={disabled}
        onClick={() => {
          setSelectedCriteriaOpen(eventKey === "1");
          setViewContactsOpen(eventKey === "2");
        }}
        style={{
          fontWeight: "0.85rem",
        }}
        variant="outline-primary"
      >
        {children}
      </Button>
    );
  };

  // API Calls
  /**
   * Fetch data from API with different query variables
   */
  const getCustomers = React.useCallback(
    debounce((props = {}) => {
      setLoading(true);
      const queryVariables = {
        companyId: showCompanyId,
        metaData: props.metaData || metaData,
        rpp: 10,
        sortBy: props.sortBy ? props.sortBy : "created_at",
        sortOrder: props.sortOrder ? props.sortOrder : "desc",
        page: (props.page !== undefined ? props.page : currentPage) + 1,
        searchField: "name",
      };
      if (props.searchTerm) {
        queryVariables["searchTerm"] =
          props.searchTerm && props.searchTerm.length >= 3
            ? props.searchTerm.toLowerCase()
            : "";
      }
      client
        .query({
          query: QUERY_CONTACTS_META_LIST,
          fetchPolicy: NO_CACHE,
          variables: queryVariables,
        })
        .then((res) => {
          if (res && res.data && res.data.contactsWithMeta) {
            const contactsList = res.data.contactsWithMeta.data;
            const totalCustomers = res.data.contactsWithMeta.total;
            const sourceAggregates =
              res?.data?.contactsWithMeta?.sourceAggregates;
            const sourceOpt = soruceData(sourceAggregates);
            setCustomersList(contactsList);
            setSourceAggregates(sourceOpt);

            // Called from parent and child totalCustomersFound has not yet been set
            if (!props.contactsOnly && !totalCustomersFound) {
              // Set child totalCustomers value
              setTotalCustomersFound(totalCustomers);
              // Set parent totalCustomers value
              setCustomersCount(totalCustomers);

              // Called from child
            } else if (props.contactsOnly) {
              // set only child totalCustomers only
              setTotalCustomersFound(totalCustomers);

              // Called from parent
            } else {
              // set only parent totalCustomers only
              setCustomersCount(totalCustomers);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500),
    [],
  );

  const getContacts = (props = {}) => {
    let newProps = { ...props, metaData: metaData, contactsOnly: true };
    getCustomers(newProps);
  };

  const createFilter = () => {
    const queryVariables = {
      name: formik.values.name,
      companyId: showCompanyId,
      metaData: metaData,
    };

    client
      .query({
        query: CREATE_FILTER,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.createFilter) {
          addToast({
            type: "success",
            message: "Your segment has been created successfully",
          });

          // hide any open modals
          setShowSegmentInUseModal(false);
          setShowSegmentIsDefaultModal(false);
          setShowUploadAdvancedFilterModal(false);

          history.push(`/${config.COMPANIES_TOKEN}/segments`);
          clearFilterStorage();
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          message: formik.values.name
            ? "Segment not created, Please check your segment criteria"
            : "Please enter segment name",
        });
        console.error(err);
      });
  };

  const updateFilter = () => {
    const queryVariables = {
      name: formik.values.name,
      id: filterId,
      metaData: metaData,
    };

    client
      .query({
        query: UPDATE_FILTER,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.updateFilter) {
          addToast({
            type: "success",
            message: "Your segment has been updated successfully",
          });

          history.push(`/${config.COMPANIES_TOKEN}/segments`);
          clearFilterStorage();
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          message: "Segment not updated",
        });
        console.error(err);
      });
  };

  const getFilter = () => {
    const queryVariables = {
      id: filterId,
    };
    client
      .query({
        query: GET_FILTER,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.showFilter) {
          if (res.data.showFilter.companyId === showCompanyId) {
            if (res.data.showFilter.metaData) {
              const {
                campaignType,
                customersCount,
                default: isDefault,
                metaData,
                name,
              } = res.data.showFilter;
              setfilterOriginalName(name);

              // Preserve fetched metaData so we can use for clear all
              setFetchedMetaData(metaData);
              const newMetaData = {
                ...localMetaData,
                ...metaData,
              };

              setCustomersCount(customersCount);

              setIsDefault(isDefault);

              setShowSegmentInUseModal(!!campaignType);

              getTouchedData(newMetaData).map((item) => {
                formik.setFieldTouched(item, true);
              });

              formik.setValues({
                ...formik.values,
                name: localName || name,
                ...shapeForForm(newMetaData, companyFilterMetrics),
              });

              setFetchedFormData({
                ...formik.values,
                name: name,
                ...shapeForForm(metaData, companyFilterMetrics),
              });
            }
          } else {
            history.push(`/companies/${showCompanyId}/segments`, {
              prevPage: document.location.pathname,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useQuery(GET_COMPANY_FILTER_METRICS, {
    variables: {
      companyId: showCompanyId,
    },
    fetchPolicy: NO_CACHE,
    onCompleted: (data) => {
      const newCompanyFilterMetrics = populateCompanyFilterMetrics(
        data.companyFilterMetrics,
      );

      if (data && data.companyFilterMetrics) {
        const jobTypes = data?.companyFilterMetrics?.job_types;
        const jobTypeOption = jobTypes ? jobTypeFormatter(jobTypes) : null;
        setJobTypes(jobTypeOption);
        const metrics = {
          ...companyFilterMetrics,
          ...newCompanyFilterMetrics,
        };
        const newFormState = populateFormState(
          formik.values,
          newCompanyFilterMetrics,
        );
        const localFormValues = shapeForForm(localMetaData, metrics);

        setCompanyFilterMetrics(metrics);

        setFormState(newFormState);

        formik.setValues({
          ...newFormState,
          ...localFormValues,
        });
      }
    },
  });

  const createFilterCsv = (customFilterId) => {
    const queryVariables = {
      customFilterId: customFilterId,
    };

    client
      .query({
        query: CREATE_CUSTOM_FILTER_CSV,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res) {
          fetchFilterList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchFilterList = async () => {
    try {
      const response = await client.query({
        query: GET_CUSTOM_FILTERS_LIST,
        variables: {
          companyId: parseInt(showCompanyId),
        },
        fetchPolicy: NO_CACHE,
      });

      const data = response.data;

      if (data && data.listCustomFilters) {
        const optionsArray = data.listCustomFilters.map((item) => {
          if (!item.outputUrl) {
            createFilterCsv(item.id);
          }
          if (!!item.status) {
            return {
              value: item.id,
              label: item.name,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              outputUrl: item.outputUrl,
            };
          }
        });

        setCustomFiltersList(optionsArray);
      }
    } catch (err) {
      console.error("GET CUSTOM FILTERS LIST ERROR: ", err);
    }
  };

  useQuery(GET_FILTERS_LIST, {
    variables: {
      companyId: showCompanyId,
      rpp: 99999,
    },
    fetchPolicy: NO_CACHE,
    onCompleted: (data) => {
      if (data && data.listFilters && data.listFilters.data) {
        const namesArray = data.listFilters.data.map((item) =>
          item.name.toLowerCase(),
        );

        setFiltersList(namesArray);
      }
    },
  });

  React.useEffect(() => {
    fetchFilterList();
  }, [showCompanyId]);

  React.useEffect(() => {
    setDisplayData(
      shapeForDisplay(
        customFiltersList,
        metaData,
        rawCitiesData,
        rawStatesData,
        rawZipCodesData,
      ),
    );
  }, [rawStatesData, rawCitiesData, rawZipCodesData]);

  React.useEffect(() => {
    const channel = pusher.subscribe("mem_" + showCompanyId);
    channel.bind("csv_contacts_imported", (val) => {
      const data = typeof val === "string" ? JSON.parse(val) : val;
      setCsvDownloadData(data);
    });
    channel.bind("csv_header_malformed", (val) => {
      console.error("CSV Header Malformed: ", val);
      addToast({
        type: "error",
        message: msgConstants.FAILURE_MSG_FOR_PROCESS_BULK_CUSTOMERS,
      });
    });
    channel.bind("csv_processing_failed", (val) => {
      console.error("CSV Processing Failed: ", val);
      addToast({
        type: "error",
        message: msgConstants.FAILURE_MSG_FOR_PROCESS_BULK_CUSTOMERS,
      });
    });

    return () => {
      pusher.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const newMetaData = {
      ...shapeForSubmit(formik),
    };

    const localFormData = {
      ...shapeForForm(newMetaData, companyFilterMetrics),
      name: formik.values.name || localName,
    };

    setMetaData(newMetaData);
    updateFilterData(localFilterId, newMetaData, localFormData);

    if (Object.keys(newMetaData).length > 0) {
      if (
        isDefault &&
        !isEqual(fetchedMetaData, metaData) &&
        !showSegmentIsDefaultUseModal
      ) {
        updateHandler();
      }
    }
  }, [formik.values]);

  React.useEffect(() => {
    if (formik.dirty) {
      window.addEventListener("beforeunload", confirmExit);
    } else {
      window.removeEventListener("beforeunload", confirmExit);
    }
  }, [formik.dirty]);

  React.useEffect(() => {
    setDisplayData(
      shapeForDisplay(
        customFiltersList,
        metaData,
        rawCitiesData,
        rawStatesData,
        rawZipCodesData,
      ),
    );
    getCustomers({ metaData: metaData, searchTerm: segmentSearchTerm });
  }, [metaData]);

  React.useEffect(() => {
    setCompanyIdStorage(showCompanyId);

    if (filterId) {
      getFilter();
    } else {
      formik.setValues({
        ...formik.values,
        name: localName,
        ...shapeForForm(localMetaData, companyFilterMetrics),
      });

      getTouchedData(localMetaData).map((item) => {
        formik.setFieldTouched(item, true);
      });
    }
  }, []);

  // render
  return (
    <React.Fragment>
      <Suspense fallback={<React.Fragment />}>
        <UploadFilter
          customFiltersList={customFiltersList}
          filterName={formik.values.name}
          filtersList={filtersList}
          formik={formik}
          setCustomFiltersList={setCustomFiltersList}
          setShow={setShowUploadAdvancedFilterModal}
          show={showUploadAdvancedFilterModal}
        />
      </Suspense>
      <SegmentInUseModal
        showModal={showSegmentInUseModal}
        setShowModal={setShowSegmentInUseModal}
      />
      <SegmentIsDefaultModal
        createFilter={createFilter}
        filterName={formik.values.name}
        filterOriginalName={filterOriginalName}
        filtersList={filtersList}
        formik={formik}
        segmentNameChangeHandler={segmentCustomNameChangeHandler}
        setShowModal={setShowSegmentIsDefaultModal}
        showModal={showSegmentIsDefaultUseModal}
      />
      <Card
        className="segment-content-card"
        title="Segment Details"
        subTitle={
          <>
            <div>
              Segmentation allows you to create targeted marketing{" "}
              <ReadMoreElements
                content={learnMoreContent}
                icon={
                  <span
                    className="segmentation-learn-more"
                  >
                    Learn more
                  </span>
                }
                placement="right-start"
                rootClose={true}
                title={<span>&nbsp;</span>}
                minWidth={660}
                maxMoreHeight={800}
                maxLessHeight={200}
              />
            </div>
            <div className="mt-n2">
              Click{" "}
              <ReadMoreElements
                content={segmentTipsContent}
                icon={<span className="segmentation-learn-more">here</span>}
                placement="right-start"
                rootClose={true}
                title={<span>&nbsp;</span>}
                minWidth={440}
                maxMoreHeight={800}
                maxLessHeight={800}
              />{" "}
              for tips to create a segment
            </div>
          </>
        }
        padding={"2rem"}
      >
        <form
          className="users-add-edit-form-container"
          onSubmit={() => saveHandler()}
        >
          <Form.Group>
            <Row>
              <Col sm={3} className="mt-2 segment-name-container">
                <SegmentNameInput
                  isValid={nameIsValid}
                  setIsValid={setNameIsValid}
                  filterOriginalName={filterOriginalName}
                  filtersList={filtersList}
                  formik={formik}
                  segmentName={formik.values.name}
                  segmentNameChangeHandler={segmentNameChangeHandler}
                  segmentNameErrorHandler={segmentNameErrorHandler}
                />
              </Col>
              <Col lg={12} xl={11} className="my-4 segment-accordion-container">
                <Accordion className="py-2 segment-accordion">
                  <div className="pt-5 mt-5 view-contacts-container">
                    <div
                      className="segment-toggle-target"
                      onClick={() =>
                        setSelectedCriteriaOpen(!selectedCriteriaOpen)
                      }
                      aria-controls="selected-criteria"
                      aria-expanded={selectedCriteriaOpen}
                    >
                      <div className="segment-section-title-container">
                        <div className="segment-section-title">
                          Selected Criteria
                        </div>
                        <span className="segment-section-subtitle">
                          Here’s the detailed criteria for the segment
                        </span>
                      </div>
                      <AccordionToggle eventKey={"1"}></AccordionToggle>
                    </div>

                    <Collapse in={selectedCriteriaOpen}>
                      <div className="selected-criteria">
                        <Row>
                          <Col xs={6}>
                            <div className="selected-criteria-actions-container"></div>
                            <div className="mb-4 selected-criteria-card">
                              <div
                                className={classnames(
                                  "selected-criteria-card-header",
                                  {
                                    active: activeCard === "include",
                                  },
                                )}
                              >
                                Include
                              </div>
                              {!displayData.customer_details &&
                                !displayData.transactions &&
                                !displayData.advance_filter &&
                                activeCard !== "include"
                                ? addCriteriaElement("include")
                                : showCriteriaElement("include", displayData)}
                            </div>
                            <div className="selected-criteria-card">
                              <div
                                className={classnames(
                                  "selected-criteria-card-header",
                                  {
                                    active: activeCard === "exclude",
                                  },
                                )}
                              >
                                {" "}
                                Exclude
                              </div>
                              {!displayData.exclude_transactions &&
                                activeCard !== "exclude"
                                ? addCriteriaElement("exclude")
                                : showCriteriaElement("exclude", displayData)}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="selected-criteria-actions-container py-2">
                              <span>
                                <img src={ContactsIcon} className="mr-2" />
                                Total Contacts:{" "}
                                <span className="total-contacts-result">
                                  {" "}
                                  {numberFormatter(customersCount, 0)}{" "}
                                </span>
                              </span>
                              <span>
                                <CustomToggle
                                  eventKey={"2"}
                                  disabled={customersCount === 0}
                                >
                                  View Contacts
                                </CustomToggle>
                              </span>
                            </div>
                            {!activeSection &&
                              !activeCard &&
                              isEqual(metaData, initialMetaData) ? (
                              <SegmentEmptyState />
                            ) : activeCard !== "exclude" ? (
                              <SegmentInclude
                                PAGE_LENGTH={PAGE_LENGTH}
                                activeCard={activeCard}
                                activeSection={activeSection}
                                companyFilterMetrics={companyFilterMetrics}
                                customFiltersList={customFiltersList}
                                fetchedFormData={fetchedFormData}
                                fetchedMetaData={fetchedMetaData}
                                formik={formik}
                                formState={formState}
                                setActiveSection={setActiveSection}
                                setRawCitiesData={setRawCitiesData}
                                setRawStatesData={setRawStatesData}
                                setRawZipCodesData={setRawZipCodesData}
                                setSelectMulti={setSelectMulti}
                                setShowUploadAdvancedFilterModal={
                                  setShowUploadAdvancedFilterModal
                                }
                                setSubmitData={setMetaData}
                                filterId={filterId}
                                sourceOption={sourceAggregates}
                                jobTypes={jobTypes}
                              />
                            ) : (
                              <SegmentExclude
                                activeCard={activeCard}
                                activeSection={activeSection}
                                fetchedFormData={fetchedFormData}
                                fetchedMetaData={fetchedMetaData}
                                formik={formik}
                                formState={formState}
                                setActiveSection={setActiveSection}
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </div>

                  <div className="pt-5 mt-5 view-contacts-container">
                    <div
                      className="segment-toggle-target"
                      onClick={() => setViewContactsOpen(!viewContactsOpen)}
                      aria-controls="view-contacts"
                      aria-expanded={viewContactsOpen}
                    >
                      <div className="segment-section-title-container">
                        <div className="segment-section-title">
                          View Contacts
                        </div>
                        <span className="segment-section-subtitle">
                          You have filtered {numberFormatter(customersCount, 0)}{" "}
                          contacts by selecting the criteria.
                        </span>
                      </div>
                      <AccordionToggle eventKey={"2"}></AccordionToggle>
                    </div>
                    <Collapse in={viewContactsOpen}>
                      <BlockUi loader={<React.Fragment />} blocking={loading}>
                        <div className="customers-component-container">
                          <CustomersComponent
                            adHocButton={
                              <CustomToggle eventKey={"1"}>
                                View Criteria
                              </CustomToggle>
                            }
                            currentPage={currentPage}
                            customersList={customersList}
                            getCustomers={getContacts}
                            parent="segment"
                            setCurrentPage={setCurrentPage}
                            totalCustomersFound={totalCustomersFound}
                          />
                        </div>
                      </BlockUi>
                    </Collapse>
                  </div>
                </Accordion>
              </Col>
            </Row>
          </Form.Group>
        </form>
        <div className="submit-button-container">
          <SegmentCancel filterId={filterId} />
          {filterId ? (
            <Button onClick={() => updateHandler()} variant="primary">
              Update
            </Button>
          ) : (
            <Button onClick={() => saveHandler()} variant="primary">
              Create
            </Button>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default SegmentContent;
