import React from "react";
import WindowUtil from "src/utils/WindowUtil";

const FacebookConnectButton = () => {
  // variables
  const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
  const FACEBOOK_AUTH_SCOPE = process.env.REACT_APP_FACEBOOK_APP_SCOPE;
  const FACEBOOK_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/_cb/facebook`;

  // methods
  const openLoginWindow = async () => {
    WindowUtil.openWindow({
      url: `https://www.facebook.com/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${FACEBOOK_REDIRECT_URI}&response_type=code&scope=${encodeURIComponent(
        FACEBOOK_AUTH_SCOPE
      )}`,
      name: "Log in with Facebook",
      width: 800,
      height: 500
    });
  };

  // render
  return (
    <span
      onClick={openLoginWindow}
      className="cursor-pointer d-flex btn-facebook-connect cursor-point"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="40"
        viewBox="0 0 44 40"
      >
        <g transform="translate(0 5)">
          <g>
            <g>
              <path
                d="M4 0h40v40H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                transform="translate(0 -5)"
                fill="#314c96"
              />
              <path
                d="M96.033 64.744h-2.4a4.213 4.213 0 0 0-4.433 4.549v2.1h-2.413a.377.377 0 0 0-.377.377v3.04a.376.376 0 0 0 .377.377H89.2v8.052h3.9v-8.052h2.82a.377.377 0 0 0 .377-.377v-3.041a.378.378 0 0 0-.378-.378H93.1v-1.779c0-.855.2-1.29 1.317-1.29h1.617a.377.377 0 0 0 .377-.377v-2.824a.377.377 0 0 0-.377-.377z"
                transform="translate(-69.41 -58.74)"
                fill="#ffffff"
              />
            </g>
          </g>
        </g>
      </svg>

      <span className="d-flex flex-grow-1 justify-content-center align-items-center">
        Connect to Facebook
      </span>
    </span>
  );
};

export default FacebookConnectButton;
