import {gql} from 'apollo-boost';

export const UPDATE_BUSINESS_HOURS = gql`
  mutation updateBusinessHours( $companyId: ID!
    $open0: Boolean
    $open1: Boolean
    $open2: Boolean
    $open3: Boolean
    $open4: Boolean
    $open5: Boolean
    $open6: Boolean
    $openTime0 : String
    $openTime1 : String
    $openTime2 : String
    $openTime3 : String
    $openTime4 : String
    $openTime5 : String
    $openTime6 : String
    $closeTime0: String
    $closeTime1: String
    $closeTime2: String
    $closeTime3: String
    $closeTime4: String
    $closeTime5: String
    $closeTime6: String){
    updateBusinessHours(
      companyId: $companyId
      businessHours: [
        {
            day: "0",
            open: $open0,
            openTime: $openTime0,
            closeTime: $closeTime0
        },
        {
            day: "1",
            open: $open1,
            openTime: $openTime1,
            closeTime: $closeTime1
        },
        {
            day: "2",
            open: $open2,
            openTime: $openTime2,
            closeTime: $closeTime2
        },
        {
            day: "3",
            open: $open3,
            openTime: $openTime3,
            closeTime: $closeTime3
        },
        {
            day: "4",
            open: $open4,
            openTime: $openTime4,
            closeTime: $closeTime4
        },
        {
            day: "5",
            open: $open5,
            openTime: $openTime5,
            closeTime: $closeTime5
        },
        {
            day: "6",
            open: $open6,
            openTime: $openTime6,
            closeTime: $closeTime6
        }
      ]
    ){
      day,
      closed,
      openTime,
      closeTime
    }
  }
 `;

export const UPDATE_COMPANY_SETTINGS = gql`
  mutation company(
        $companyId: ID!
        $companyDescription: String
        $website: String
        $phone: String
        $phoneExt: String
        $address1: String
        $address2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $logoBase64: String
        $logoOriginalFilename: String
        $primaryCategory: Int
        $secondaryCategory: Int
        $tertiaryCategory: Int
        $isLogoRemoved: Boolean
        ) {
        updateCompany(
          companyId: $companyId
          companyDescription: $companyDescription
          website: $website
          phone: $phone
          phoneExt: $phoneExt
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          zip: $zip
          country: $country
          logoBase64: $logoBase64
          logoOriginalFilename: $logoOriginalFilename
          primaryCategory: $primaryCategory
          secondaryCategory: $secondaryCategory
          tertiaryCategory: $tertiaryCategory
          isLogoRemoved: $isLogoRemoved
          ) {
          companyDescription
          website
          phone
          phoneExt
          address1
          address2
          city
          state
          zip
          country
          logoUrl
          primaryCategory {
            id
            name
          }
          secondaryCategory {
            id
            name
          }
          tertiaryCategory {
            id
            name
          }
        }
      }
 `;

export const UPDATE_GLOBAL_CAMPAIGN_SETTINGS = gql`
  mutation updateglobalCampaignSetting(
    $companyId: ID!
    $email: String
    $emailFirstName: String
    $emailLastName: String
    ) {
    updateGlobalCampaignSetting(
      companyId: $companyId
      email: $email
      emailFirstName: $emailFirstName
      emailLastName: $emailLastName
      ) {
      email
      emailFirstName
      emailLastName
    }
  }
`;

export const UPDATE_TEXTING_SETTING = gql`
mutation updateCompanySettings(
  $companyId: ID!,
  $autoResponseContent: String
) {
  updateCompanySettings(
    companyId: $companyId
    autoResponseContent: $autoResponseContent
  ) {
    autoResponseContent
  }
}
`;

export const UPDATE_FEATURE_FLAGS = gql`
mutation updateCompanySettings(
  $companyId: ID!,
  $component: String!,
  $status: Boolean!,
  $userId: ID!,
  $userType: String!,
) {
  updateCompanyFeatureFlag (
    companyId: $companyId
    component: $component
    status: $status
    userId: $userId
    userType: $userType
  )
}
`;