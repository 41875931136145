import React, { useState, createContext } from "react";
import { SEARCH_BY_TYPE_OPTIONS } from "../customerConstants";

const CustomersContext = createContext(null);

const CustomersProvider = ({ children }) => {
  const initialFilters = {
    searchTerm: "",
    searchType: SEARCH_BY_TYPE_OPTIONS[0],
    sortBy: { value: "created_at" },
    sortOrder: { value: "desc" },
    filterByType: [{ label: "All", value: "all" }],
  };

  const [detailsShown, setDetailsShown] = useState(false);
  const [filters, setFilters] = useState({ ...initialFilters });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState(SEARCH_BY_TYPE_OPTIONS[0]);
  const [disabledFilters, setDisabledFilters] = useState([]);
  // methods
  const resetFilters = () => {
    setFilters({ ...initialFilters });
    setSearchTerm("");
    setSearchType(SEARCH_BY_TYPE_OPTIONS[0]);
  };

  // render
  return (
    <CustomersContext.Provider
      value={{
        detailsShown,
        setDetailsShown,
        filters,
        setFilters,
        initialFilters,
        resetFilters,
        searchTerm,
        setSearchTerm,
        searchType,
        setSearchType,
        disabledFilters,
        setDisabledFilters,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

export { CustomersContext, CustomersProvider };

export default CustomersContext;
