import React, { useEffect, useState, useRef } from "react";
import ReviewWidgetForm from "src/components/settings/widgets/review_widgets/ReviewWidgetForm.jsx";
import { useFormik } from "formik";
import { showCompanyId as companyId } from "src/shared/SessionHelper";
import axios from "axios";
import { UPDATE_WIDGET_COLOR } from "src/graphql/company/mutations";
import { NO_CACHE } from "src/shared/Constants";
import { useMutation } from "@apollo/react-hooks";
import { copyReviewWidgetToClipboard } from "src/utils/CopyToClipboardUtil";
import { addToast } from "src/utils/ToastUtil"

const ReviewWidgetBody = ({ widgetSettings }) => {
  const widgetContainerEl = useRef(null);
  const [currWidgetColor, setCurrWidgetColor] = useState("#37445b");
  const [valuesInitialized, setValuesInitialized] = useState(false);
  const [updateWidgetColor] = useMutation(UPDATE_WIDGET_COLOR);
  const [okayToUpdate, setOkayToUpdate] = useState(true);
  const [widgetQueryData, setWidgetQueryData] = useState({
    widgetColor: "",
    widgetWidth: 0,
    reviewCount: 0,
  });

  const handleAbbreviatedName = () => {
    if (
      !widgetSettings.loading &&
      widgetSettings.data &&
      widgetSettings.data.company
    ) {
      return widgetSettings.data.company.abbreviatedName;
    }

    return "";
  };

  const clipboardHelper = () => {
    let { widgetWidth, reviewCount } = formik.values;

    reviewCount = !reviewCount || Number(reviewCount) === 0 ? 1 : reviewCount;
    widgetWidth =
      !widgetWidth || Number(widgetWidth) <= 215 ? 215 : widgetWidth;
    copyReviewWidgetToClipboard({
      companyId,
      abbreviatedName: handleAbbreviatedName(),
      widgetWidth,
      reviewCount,
      widgetColor: currWidgetColor,
    });

    addToast({
      message: "Widget copied to clipboard!",
      type: "success",
    });
  };

  const getReviewWidget = async () => {
    if (!okayToUpdate) return;

    let { reviewCount } = formik.values;
    const widgetWidth = handleWidgetWidth();
    reviewCount = reviewCount || 0;
    let widgetColorForQuery =
      currWidgetColor[0] === "#"
        ? `%23${currWidgetColor.slice(1)}`
        : currWidgetColor;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_SECURE_URL}/widgets/widget-view/?company_id=${companyId}&widget_type=review_widget&widget_width=${widgetWidth}&review_count=${reviewCount}&widget_color=${widgetColorForQuery}`,
      );

      if (widgetContainerEl && res.data) {
        widgetContainerEl.current.innerHTML = res.data;

        const widgetChildEl = widgetContainerEl.current.children[0];
        if (widgetChildEl) {
          let aTags = widgetChildEl.querySelectorAll("a");
          if (aTags) {
            Array.from(aTags).forEach((aTag) => {
              aTag.removeAttribute("href");
              aTag.style.cursor = "auto";
            });
          }
        }
      }
    } catch (e) {}
  };

  const formik = useFormik({
    initialValues: {
      widgetWidth: 365,
      reviewCount: 3,
      widgetColor: "#37445b",
      valuesSet: false,
    },
    onSubmit: async (values) => {
      const res = await updateWidgetColor({
        variables: {
          widgetColor: values.widgetColor,
          companyId,
          widgetWidth: Number(handleWidgetWidth()),
          reviewCount: Number(values.reviewCount) || 1,
        },
        fetchPolicy: NO_CACHE,
      });

      if (!res.loading && res.data && res.data.updateCompanySettings) {
        if (res.data.updateCompanySettings.widgetColor)
          setCurrWidgetColor(res.data.updateCompanySettings.widgetColor);
        addToast({
          message: "Reviews Widget updated successfully",
          type: "success",
        });
        setWidgetQueryData({ ...res.data.updateCompanySettings });
      }
    },
  });

  const handleWidgetWidth = () => {
    const { widgetWidth } = formik.values;
    if (widgetWidth && widgetWidth >= 215) return widgetWidth;

    return 215;
  };

  useEffect(() => {
    if (!widgetSettings.loading && widgetSettings.data && !valuesInitialized) {
      setValuesInitialized(true);
      if (widgetSettings.data.companySettings.widgetColor)
        formik.setFieldValue(
          "widgetColor",
          widgetSettings.data.companySettings.widgetColor,
          false,
        );
      if (widgetSettings.data.companySettings.widgetWidth)
        formik.setFieldValue(
          "widgetWidth",
          widgetSettings.data.companySettings.widgetWidth,
          false,
        );
      if (widgetSettings.data.companySettings.reviewCount)
        formik.setFieldValue(
          "reviewCount",
          widgetSettings.data.companySettings.reviewCount,
          false,
        );
      formik.setFieldValue("valuesSet", true, false);
      if (widgetSettings.data.companySettings.widgetColor)
        setCurrWidgetColor(widgetSettings.data.companySettings.widgetColor);
      setWidgetQueryData({ ...widgetSettings.data.companySettings });
    }

    if (formik.values.valuesSet) getReviewWidget();
  }, [formik.values, widgetSettings.data, currWidgetColor]);

  const buttonDisabled = () => {
    if (
      !widgetSettings.loading &&
      widgetSettings.data &&
      widgetSettings.data.companySettings
    ) {
      const { widgetWidth, reviewCount, widgetColor } = widgetQueryData;
      const { values } = formik;

      if (!values.widgetWidth || Number(values.widgetWidth) < 215) return true;
      if (!values.reviewCount || Number(values.reviewCount) < 1) return true;
      if (!values.widgetColor) return true;
      if (
        Number(widgetWidth) === Number(values.widgetWidth) &&
        Number(reviewCount) === Number(values.reviewCount) &&
        widgetColor === values.widgetColor
      )
        return true;

      return false;
    }

    return false;
  };

  return (
    <div style={{ marginTop: "33px" }} className="d-flex">
      <ReviewWidgetForm
        setOkayToUpdate={setOkayToUpdate}
        clipboardHelper={clipboardHelper}
        currWidgetColor={currWidgetColor}
        formik={formik}
        buttonDisabled={buttonDisabled}
        okayToUpdate={okayToUpdate}
      />

      <div
        style={{ marginTop: "-20px" }}
        className="d-flex flex-column justify-center"
      >
        <div
          ref={widgetContainerEl}
          id="widget-view"
          data-selenium-id="review-widget"
          data-selenium-name="review-widget"
        />
      </div>
    </div>
  );
};

export default ReviewWidgetBody;
