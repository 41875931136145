export default class NumberUtil {

  /**
   * Return string representation of large numbers
   * @param {string} val value to be tested as number
   */
  static isNumber = (val) => {
    var reg = new RegExp("^[0-9]+$");
    return reg.test(val);
  };

  /**
   * Return string representation of large numbers
   * Examples: 1 => 1
   *           7300 => 7.3k
   * @param {number} num value to be processed
   * @param {number} precision - number of decimal places to keep, defaults to 1
   * @param {boolean} round - round when truncating
   * @returns {string} string representaton of number
   */
  static toK(num, precision = 1, round = false) {
    const typeOfNum = typeof num;
    if (typeOfNum !== "number") {
      return num;
    }
    const truncateFunction = round
      ? NumberUtil.toFixed
      : NumberUtil.toFixedWithoutRound;
    const formats = [
      {
        value: 1.0e12,
        suffix: "T",
      },
      {
        value: 1.0e9,
        suffix: "B",
      },
      {
        value: 1.0e6,
        suffix: "M",
      },
      {
        value: 1.0e3,
        suffix: "K",
      },
    ];
    for (let i = 0; i < formats.length; i++) {
      if (num > formats[i].value) {
        return (
          truncateFunction(num / formats[i].value, precision) +
          formats[i].suffix
        );
      }
    }
    return Number.isInteger(num) ? num : truncateFunction(num, precision);
  }

  /**
   * Convert file size in bytes to human readable file size
   * @param {number} bytes file size in bytes
   * @param {boolean} si SI or metric system
   * @param {number} precision display precision
   */
  static readableFileSize(bytes, si = false, precision = 1) {
    const threshold = si ? 1000 : 1024;
    if (Math.abs(bytes) < threshold) {
      return bytes + " B";
    }
    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** precision;
    do {
      bytes /= threshold;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= threshold &&
      u < units.length - 1
    );
    return bytes.toFixed(precision) + " " + units[u];
  }

  /**
   * Convert number to a fixed format without rounding
   * @param {number} num number to be converted
   * @param {number} precision precision
   */
  static toFixedWithoutRound(num, precision = 1) {
    const numStr = `${num}`;
    if (numStr.indexOf(".") <= 0) {
      return numStr;
    }
    return precision === 0
      ? numStr.slice(0, numStr.indexOf("."))
      : numStr.slice(0, numStr.indexOf(".") + precision + 1);
  }

  static toFixed(num, precision) {
    return num.toFixed(precision);
  }

  /**
   * Convert a number to percentage on a given scale
   * @param {number} num number to be converted to percentage
   * @param {number} scale original scale of the data
   * @param {string} pctSign percentage symbol
   */
  static toPercentage(num, scale = 1, pctSign = "%") {
    return NumberUtil.toFixedWithoutRound((num * 100) / scale) + pctSign;
  }

  static toHumanReadableNumber(num) {
    return Intl.NumberFormat().format(num);
  }

  static toUSD(num) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(num);
  }
}
