import React from 'react';
import 'src/components/settings/widgets/review_widgets/LightBlueCard.scss'

const LightBlueCard = () => {
  const content = [
    {id: 0, content: 'Select the type of widget.'},
    {id: 1, content: 'Click on "Copy Code" link'},
    {id: 2, content: 'Paste the code in an email and send it to your webmaster.'},
    {id: 3, content: 'Alternatively, we can also do the installation for you. Call Member Services at (866) 718-9549'},
  ]

  const handleContent = () => {
    return content.map(sentence => {
      return (
        <div key={`${sentence.id}${sentence.content}`} className='text-container'>
          <div className='square' />
          <div className='text'>{sentence.content}</div>
        </div>
      )
    })
  }

  return (
    <div className='light-blue-card-container'>
      {handleContent()}
    </div>
  )
}

export default LightBlueCard;
