import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PropTypes from "prop-types";
import "./PopupInfo.scss";

export const PopupInfo = ({
  icon,
  placement = "top",
  delay = 250,
  trigger = ['hover', 'focus'],
  iconSize = 16,
  fill = "#7e7e7e",
  iconClassName = "",
  tooltipClassName = "",
  message,
}) => (
  <OverlayTrigger
    placement={placement}
    trigger={trigger}
    delay={delay}
    popperConfig={{
      modifiers: {
        offset: {
          offset: "0,4",
        },
      },
    }}
    overlay={
      <Tooltip className={tooltipClassName}>
        <div className="text-left">{message}</div>
      </Tooltip>
    }
  >
    {icon ? (
      icon
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 17 17"
        fill={fill}
        className={iconClassName}
      >
        <path d="M8.5 0A8.5 8.5 0 1017 8.5 8.51 8.51 0 008.5 0zm0 15.455A6.955 6.955 0 1115.455 8.5 6.962 6.962 0 018.5 15.455z" />
        <path
          d="M146.032 70a1.03 1.03 0 101.03 1.031 1.032 1.032 0 00-1.03-1.031z"
          transform="translate(-137.532 -66.394)"
        />
        <path
          d="M150.773 140a.773.773 0 00-.773.773v4.636a.773.773 0 101.545 0v-4.636a.773.773 0 00-.772-.773z"
          transform="translate(-142.273 -132.788)"
        />
      </svg>
    )}
  </OverlayTrigger>
);

PopupInfo.propTypes = {
  delay: PropTypes.number,
  fill: PropTypes.string,
  icon: PropTypes.node,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placement: PropTypes.string,
  trigger: PropTypes.string,
};

export default PopupInfo;
