import { gql } from "apollo-boost";

const QUERY_FEATURE_FLAGS = gql`
  query companyFeatureFlags($companyId: ID!) {
    companyFeatureFlags(companyId: $companyId)
  }
`;

const QUERY_APPOINTMENT_COUNT = gql`
  query appointmentCounts(
    $companyId: ID!
    $searchTerm: String
    $source: String
  ) {
    appointmentCounts(
      companyId: $companyId
      searchTerm: $searchTerm
      source: $source
    ) {
      allRequests
      upcomingRequests
      pastRequests
    }
    companyFeatureFlags(companyId: $companyId)
  }
`;

const QUERY_APPOINTMENTS_LIST = gql`
  query appointmentsList(
    $companyId: ID!
    $searchTerm: String
    $source: String
    $disposition: String
    $page: Int
    $limit: Int
    $sortBy: String
    $sortOrder: String
  ) {
    appointmentsList(
      companyId: $companyId
      searchTerm: $searchTerm
      source: $source
      disposition: $disposition
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      appointments {
        id
        address1
        address2
        city
        comments
        country
        createdAt
        date1
        date2
        email
        finalAppointment
        formattedPhone
        name
        source
        state
        zip
      }
      rpp
      total
      page
    }
  }
`;

const QUERY_APPOINTMENT_SETTINGS = gql`
  query appointmentEnabled($companyId: ID!) {
    appointments: companySettings(companyId: $companyId) {
      companyId
      appointmentEmailTo
      appointmentCustomUrl
    }
  }
`;

const QUERY_APPOINTMENT_URL = gql`
  query getAppointmentLink( $companyId: ID!) {
    appointment: getAppointmentLink(companyId: $companyId) {
      url
    }
  }
`;


export {
  QUERY_FEATURE_FLAGS,
  QUERY_APPOINTMENT_COUNT,
  QUERY_APPOINTMENTS_LIST,
  QUERY_APPOINTMENT_SETTINGS,
  QUERY_APPOINTMENT_URL
};
