import { gql } from "apollo-boost";
const MUTATION_SAVE_PUBLISHING_SETTINGS = gql`
  mutation UpdateCompanySettings(
    $companyId: ID!
    $autoPublishHighScoreReviews: Boolean!
    $syndicateLowScoreReviews: Boolean!
    $reviewPageDisplayElement: String!
  ) {
    updateCompanySettings(
      companyId: $companyId
      autoPublishHighScoreReviews: $autoPublishHighScoreReviews
      syndicateLowScoreReviews: $syndicateLowScoreReviews
      reviewPageDisplayElement: $reviewPageDisplayElement
    ) {
      companyId
      autoPublishHighScoreReviews
      syndicateLowScoreReviews
      reviewPageDisplayElement
    }
  }
`;

const MUTATION_SAVE_FEATURE_FLAG_PUBLISHING_SETTINGS = gql`
  mutation updateCompanyFeatureFlag(
    $companyId: ID!, 
    $component: String!,
    $status: Boolean!,
    $userId: ID!,
    $userType: String!,
    ) {
      updateCompanyFeatureFlag (
      companyId: $companyId
      component: $component
      status: $status
      userId: $userId
      userType: $userType
    )
  }
`;

const MUTATION_POSTING_FREQUENCY_SETTINGS = gql`
  mutation updateCompanySettings(
    $companyId: ID!
    $reviewsDailyMax: Int!
    $reviewsIntervalDays: Int!
  ) {
    updateCompanySettings(
      companyId: $companyId
      reviewsDailyMax: $reviewsDailyMax
      reviewsIntervalDays: $reviewsIntervalDays
    ) {
      companyId
      reviewsDailyMax
      reviewsIntervalDays
    }
  }
`;

const MUTATION_REMINDER_NOTIFICATION_SETTINGS = gql`
  mutation updateCompanySettings(
    $companyId: ID!
    $noReviewReminderStatus: Boolean!
    $noReviewReminderFrequency: Int!
    $noReviewReminderPeriod: Int!
    $noContactReminderStatus: Boolean!
    $noContactReminderFrequency: Int!
  ) {
    updateCompanySettings(
      companyId: $companyId
      noReviewReminderStatus: $noReviewReminderStatus
      noReviewReminderFrequency: $noReviewReminderFrequency
      noReviewReminderPeriod: $noReviewReminderPeriod
      noContactReminderStatus: $noContactReminderStatus
      noContactReminderFrequency: $noContactReminderFrequency
    ) {
      companyId
      noReviewReminderStatus
      noReviewReminderFrequency
      noReviewReminderPeriod
      noContactReminderStatus
      noContactReminderFrequency
    }
  }
`;

const MUTATION_REVIEW_TOPICS = gql`
  mutation updateReviewTopics(
    $companyId: ID!
    $reviewTopics: [ReviewTopicInput!]
  ) {
    updateReviewTopics(companyId: $companyId, reviewTopics: $reviewTopics) {
      companyId
      reviewTopic
      reviewTopicId
      currentOrder
      averageScore
    }
  }
`;

const MUTATION_SUGGESTION_TOPICS = gql`
  mutation updateSuggestionTopics(
    $companyId: ID!
    $suggestionTopics: [SuggestionTopicInput!]
  ) {
    updateSuggestionTopics(
      companyId: $companyId
      suggestionTopics: $suggestionTopics
    ) {
      suggestionTopic
      suggestionTopicId
    }
  }
`;

const MUTATION_SET_SUGGESTION_TOPIC = gql`
  mutation updateCompanySettings(
    $companyId: ID!
    $acceptContactSuggestions: Boolean!
  ) {
    updateCompanySettings(
      companyId: $companyId
      acceptContactSuggestions: $acceptContactSuggestions
    ) {
      companyId
      acceptContactSuggestions
    }
  }
`;

const MUTATION_CREATE_TWITTER_PROFILE = gql`
  mutation createTwitterNetworkProfile(
    $companyId: ID!
    $verifier: String!
    $token: String!
    $secret: String!
  ) {
    createTwitterNetworkProfile(
      companyId: $companyId
      oauthVerifier: $verifier
      token: $token
      secret: $secret
    ) {
      id
    }
  }
`;

const MUTATION_CREATE_FACEBOOK_PROFILE = gql`
  mutation createFacebookNetworkProfile($companyId: ID!, $code: String!) {
    createFacebookNetworkProfile(companyId: $companyId, code: $code) {
      id
    }
  }
`;

const MUTATION_SMART_QUEUE_CALLING = gql`
  mutation updateCompanyFeatureFlag(
    $companyId: ID!, 
    $component: String!,
    $status: Boolean!,
    $userId: ID!,
    $userType: String!,
    ) {
      updateCompanyFeatureFlag (
      companyId: $companyId
      component: $component
      status: $status
      userId: $userId
      userType: $userType
    )
  }
`;

const MUTATION_AUTO_INVITATION = gql`
  mutation updateCompanySettings(
    $companyId: ID!
    $autoInviteTransactionThreshold: Float
    $invitationDelayInterval: Int!
  ) {
    updateCompanySettings(
      companyId: $companyId
      autoInviteTransactionThreshold: $autoInviteTransactionThreshold
      invitationDelayInterval: $invitationDelayInterval
    ) {
      autoInviteTransactionThreshold
      invitationDelayInterval
    }
  }
`;
const MUTATION_UPDATE_FEATURE_FLAG = gql`
  mutation updateCompanyFeatureFlag(
    $companyId: ID!, 
    $component: String!,
    $status: Boolean!,
    $userId: ID!,
    $userType: String!,
    ) {
      updateCompanyFeatureFlag (
      companyId: $companyId
      component: $component
      status: $status
      userId: $userId
      userType: $userType
    )
  }
`;

const MUTATION_CUSTOM_EMAIL_TEMPLATE = gql`
  mutation updateCustomEmailTemplate(
    $id: ID
    $status: String
    $autoResend: Boolean
    $subject: String
    $companyId: ID!
    $replyEmail: String
    $replyName: String
    $fromEmail: String
    $greeting: String
    $mainBody: String
    $signature: String
    $unhappyCustomerFeedback: Boolean
  ) {
    updateCompanySettings(
      companyId: $companyId
      unhappyCustomerFeedback: $unhappyCustomerFeedback
    ) {
      unhappyCustomerFeedback
    }
    updateCustomEmailTemplate(
      id: $id
      status: $status
      autoResend: $autoResend
      companyId: $companyId
      subject: $subject
      greeting: $greeting
      mainBody: $mainBody
      signature: $signature
      fromEmail: $fromEmail
      replyName: $replyName
      replyEmail: $replyEmail
    ) {
      id
      status
      autoResend
      subject
      fromEmail
      replyName
      replyEmail
      mainBody
      signature
    }
  }
`;

const MUTATION_RESET_TWITTER = gql`
  mutation resetTwitter($companyId: ID!) {
    resetSocialProfile(companyId: $companyId, type: "TwitterProfile") {
      id
    }
  }
`;

const MUTATION_RESET_FACEBOOK = gql`
  mutation resetFacebook($companyId: ID!) {
    resetSocialProfile(companyId: $companyId, type: "FacebookProfile") {
      id
    }
  }
`;

const MUTATION_FACEBOOK_PAGE_STATUS = gql`
  mutation updateSocialNetworkPage($id: ID!, $status: String!) {
    updateSocialNetworkPage(id: $id, status: $status) {
      id
      status
    }
  }
`;

const MUTATION_CREATE_SMART_INVITES = gql`
mutation updateCompanyFeatureFlag(
  $companyId: ID!, 
  $component: String!,
  $status: Boolean!,
  $userId: ID!,
  $userType: String!,
  ) {
    updateCompanyFeatureFlag (
    companyId: $companyId
    component: $component
    status: $status
    userId: $userId
    userType: $userType
  )
}
`;

const MUTATION_CREATE_REVIEW_DESTINATION = gql`
  mutation createReviewDestination(
    $defaultReviewDestinationId: ID!
    $companyId: ID!
    $url: String!
    $gmailOnly: Boolean
    $connectionStatus: String
    $accessToken: String
    $refreshToken: String
  ) {
    createReviewDestination(
      defaultReviewDestinationId: $defaultReviewDestinationId
      companyId: $companyId
      url: $url
      gmailOnly: $gmailOnly
      connectionStatus: $connectionStatus
      accessToken: $accessToken
      refreshToken: $refreshToken
    ) {
      id
    }
  }
`;

const MUTATION_UPDATE_REVIEW_DESTINATION = gql`
  mutation updateReviewDestination(
    $id: ID!
    $url: String!
    $gmailOnly: Boolean
    $connectionStatus: String
    $accessToken: String
    $refreshToken: String
  ) {
    updateReviewDestination(
      id: $id
      url: $url
      gmailOnly: $gmailOnly
      connectionStatus: $connectionStatus
      refreshToken: $refreshToken
      accessToken: $accessToken
    ) {
      id
    }
  }
`;

const MUTATION_DELETE_REVIEW_DESTINATION = gql`
  mutation deleteReviewDestination($companyId: ID!, $id: ID!) {
    deleteReviewDestination(companyId: $companyId, id: $id) {
      success
    }
  }
`;

const MUTATION_GOOGLE_TOKENS = gql`
  mutation googleTokensGenerator(
    $companyId: ID!
    $clientId: String!
    $code: String!
  ) {
    googleTokensGenerator(
      companyId: $companyId
      clientId: $clientId
      code: $code
    ) {
      accessToken
      refreshToken
    }
  }
`;

export {
  MUTATION_UPDATE_FEATURE_FLAG,
  MUTATION_SAVE_PUBLISHING_SETTINGS,
  MUTATION_SAVE_FEATURE_FLAG_PUBLISHING_SETTINGS,
  MUTATION_POSTING_FREQUENCY_SETTINGS,
  MUTATION_REMINDER_NOTIFICATION_SETTINGS,
  MUTATION_REVIEW_TOPICS,
  MUTATION_SUGGESTION_TOPICS,
  MUTATION_SET_SUGGESTION_TOPIC,
  MUTATION_SMART_QUEUE_CALLING,
  MUTATION_AUTO_INVITATION,
  MUTATION_CUSTOM_EMAIL_TEMPLATE,
  MUTATION_CREATE_FACEBOOK_PROFILE,
  MUTATION_CREATE_TWITTER_PROFILE,
  MUTATION_RESET_FACEBOOK,
  MUTATION_RESET_TWITTER,
  MUTATION_FACEBOOK_PAGE_STATUS,
  MUTATION_CREATE_SMART_INVITES,
  MUTATION_UPDATE_REVIEW_DESTINATION,
  MUTATION_DELETE_REVIEW_DESTINATION,
  MUTATION_CREATE_REVIEW_DESTINATION,
  MUTATION_GOOGLE_TOKENS
};
