import React from "react";
import Chart from "./Chart";
import PropTypes from "prop-types";
import "./Chart.scss";

export const BarChart = ({
  chartId,
  xAxisCategories,
  chartData,
  yAxisTitle
}) => (
  <Chart
    chartId={chartId}
    chartConfig={{
      chart: {
        type: "column"
      },
      credits: {
        enabled: false
      },
      title: null,
      xAxis: {
        categories: xAxisCategories
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisTitle
        }
      },
      legend: {
        layout: "vertical",
        backgroundColor: "#FFFFFF",
        align: "left",
        verticalAlign: "top",
        x: 100,
        y: 70,
        floating: true,
        shadow: true
      },
      series: [
        {
          data: chartData,
          color: "#f8955f",
          showInLegend: false
        }
      ]
    }}
  />
);

BarChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  chartData: PropTypes.array.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  xAxisCategories: PropTypes.array.isRequired,
};

export default BarChart;
