import React from "react";
import { Dropdown } from "react-bootstrap";
import Button from "src/shared/styleguide/button/Button";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./Dropdown.scss";

export const DropdownButton = ({
  title,
  children,
  dropdownClassName,
  buttonClassName,
  alignRight = false,
  direction = "down",
  dropShadow = false,
  drop,
  ...props
}) => {
  return (
    <Dropdown
      className={classnames("sg-dropdown", dropdownClassName, {
        "sg-dropdown-shadow": dropShadow,
      })}
      drop={drop}
    >
      <Dropdown.Toggle
        className="shadow-none"
        as={React.forwardRef(({ className, onClick }, ref) => {
          return (
            <Button
              ref={ref}
              {...props}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                onClick(evt);
              }}
              className={classnames(className, buttonClassName)}
            >
              {title}
              {direction !== "" && direction !== false ? (
                <span
                  className={classnames(
                    "dropdown-toggle-indicator fa",
                    "fa-chevron-" + direction,
                  )}
                />
              ) : null}
            </Button>
          );
        })}
      />
      <Dropdown.Menu alignRight={alignRight}>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

DropdownButton.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  direction: PropTypes.oneOf(["up", "down", "left", "right", false]),
  alignRight: PropTypes.bool,
  dropdownClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  dropShadow: PropTypes.bool,
};

export default Dropdown;
