import React from "react";
import PropTypes from "prop-types";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import UserInitials from "src/shared/styleguide/common/UserInitials";
import { showCompanyId as companyId } from "src/shared/SessionHelper";
import { contactPageUrl } from "src/shared/Utils.js";
import EmailIcon from "src/assets/images/customer/icon_mail.svg";
import EmailIconUnsubscribed from "src/assets/images/customer/icon_mail_unsubscribed.svg";
import PhoneIcon from "src/assets/images/customer/icon_phone.svg";
import PhoneIconUnsubscribed from "src/assets/images/customer/icon_phone_unsubscribed.svg";
import MobileIcon from "src/assets/images/customer/icon_mobile.svg";
import MobileIconUnsubscribed from "src/assets/images/customer/icon_mobile_unsubscribed.svg";
import MapPinIcon from "src/assets/images/customer/icon_map_pin.svg";
import MapPinIconUnsubscribed from "src/assets/images/customer/icon_map_pin_unsubscribed.svg";
import ServiceContractIcon from "src/assets/images/customer/icon_service_contract.svg";
import "./CustomerDetails.scss";

const InfoStrip = ({ img, info }) => {
  if (!info) return null;

  return (
    <div className="d-flex align-items-center info-text-container">
      {img}
      <div className="info-txt">{info}</div>
    </div>
  );
};

const CustomerDetails = ({ contact, navigateToOldMembers }) => {
  const formatAddress = () => {
    let address = "";
    if (contact.address1) address += `${contact.address1}, `;
    if (contact.address2) address += `${contact.address2}, `;
    if (contact.city) address += `${contact.city}, `;
    if (contact.state) address += `${contact.state}, `;
    if (contact.zip) address += `${contact.zip}, `;

    if (!address) return address;

    return address.slice(0, address.length - 2);
  };

  return (
    <div className="p-3 bg-white customer-info-container">
      <div className="d-flex align-items-center">
        <UserInitials
          size="3.2rem"
          fontSize="1rem"
          firstName={contact.firstName || ""}
          lastName={contact.lastName || ""}
        />
        <div className="d-flex flex-column customer-name-container">
          <a
            href={contactPageUrl(
              contact.contactId,
              companyId,
              navigateToOldMembers,
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="customer-name"
          >
            {contact.contactDisplayName || contact.fullName}
          </a>
        </div>
      </div>
      <InfoStrip
        img={
          contact.wantsEmail ? (
            <PopupInfo
              message={"Email"}
              icon={<img src={EmailIcon} alt="Email" />}
            />
          ) : (
            <PopupInfo
              message={"Unsubscribed"}
              icon={<img src={EmailIconUnsubscribed} alt="Unsubscribed" />}
            />
          )
        }
        info={contact.email}
      />
      <InfoStrip
        img={
          contact.wantsSms ? (
            <PopupInfo
              message={"Mobile Number"}
              icon={<img src={MobileIcon} alt="Mobile Number" />}
            />
          ) : (
            <PopupInfo
              message={"Unsubscribed"}
              icon={<img src={MobileIconUnsubscribed} alt="Unsubscribed" />}
            />
          )
        }
        info={contact.formattedPhoneMobile}
      />
      <InfoStrip
        img={
          contact.wantsCalls ? (
            <PopupInfo
              message={"Phone Number"}
              icon={<img src={PhoneIcon} alt="Phone Number" />}
            />
          ) : (
            <PopupInfo
              message={"Unsubscribed"}
              icon={<img src={PhoneIconUnsubscribed} alt="Unsubscribed" />}
            />
          )
        }
        info={contact.formattedPhoneDaytime}
      />
      <InfoStrip
        img={
          contact.wantsCalls ? (
            <PopupInfo
              message={"Alternate Phone Number"}
              icon={<img src={PhoneIcon} alt="Alternate Phone Number" />}
            />
          ) : (
            <PopupInfo
              message={"Unsubscribed"}
              icon={<img src={PhoneIconUnsubscribed} alt="Unsubscribed" />}
            />
          )
        }
        info={contact.formattedPhoneAlt}
      />
      <InfoStrip
        img={
          contact.wantsMail ? (
            <PopupInfo
              message={"Location"}
              icon={<img src={MapPinIcon} alt="Location" />}
            />
          ) : (
            <PopupInfo
              message={"Unsubscribed"}
              icon={<img src={MapPinIconUnsubscribed} alt="Unsubscribed" />}
            />
          )
        }
        info={formatAddress()}
      />
      <InfoStrip
        img={
          <PopupInfo
            message={"Service Contract"}
            icon={<img src={ServiceContractIcon} alt="Service Contract" />}
          />
        }
        info={`Service Contract: ${contact.inContract ? "Yes" : "No"}`}
      />
    </div>
  );
};

CustomerDetails.propTypes = {
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contact: PropTypes.object.isRequired,
};

export default CustomerDetails;
