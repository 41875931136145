import React from "react";
import { Col, Row } from "react-bootstrap";
import { SegmentsProvider } from "./SegmentsContext";
import "./Segments.scss";
import SegmentsSidebar from "./SegmentsSidebar";
import SegmentsContent from "./SegmentsContent";

const Segments = () => {
  // render
  return (
    <SegmentsProvider>
      <div className="segment-container">
        <div className="segment-block">
          <Row>
            <Col md={3} sm={12} className="segment-sidebar">
              <SegmentsSidebar />
            </Col>
            <Col md={9} sm={12} className="segment-content">
              <SegmentsContent />
            </Col>
          </Row>
        </div>
      </div>
    </SegmentsProvider>
  );
};

export default Segments;
