export const chartBorderOffset = 4;
export const legendLineHeight = 1.1667;
export const legendVerticalPadding = 23;
export const getLeftPositionCorrection = (pieSize) =>
  (pieSize - 70) / 3.3333 + 1;

export const defaultNoDataDemonstrated = (config) => {
  config.series[0].data = config.series[0].data.map(
    (row) => (row.y = 100 / config.series[0].data.length),
  );

  return config;
};

export const defaultNoData = (config, pieSize, pieThickness) => {
  config.series[0].borderWidth = pieThickness - 2;
  config.series[0].borderColor = "transparent";
  config.plotOptions.pie.size = pieSize - pieThickness;

  return config;
};

export const getBaseConfig = ({
  chartBorderOffset,
  chartData,
  labelFormatter,
  leftPositionCorrection,
  legendEnabled,
  legendFontSize,
  legendIsPaginated,
  legendItemMargin,
  legendLayout,
  legendX,
  pieSize,
  pieThickness,
  spacingBottom,
  spacingLeft,
  spacingRight,
  spacingTop,
  symbolPadding,
  symbolRadius,
  symbolSize,
  tooltipClassName,
  tooltipFormatter,
}) => ({
  chart: {
    align: "left",
    margin: [0, 0, 0, leftPositionCorrection],
    plotBorderWidth: 0,
    spacingBottom: spacingBottom,
    spacingLeft: spacingLeft,
    spacingRight: spacingRight,
    spacingTop: spacingTop,
    style: {
      layout: legendLayout,
      floating: true,
      width: "100%",
    },
    verticalAlign: "middle",
  },
  xAxis: {
    categories: [],
    maxPadding: 0,
    minPadding: 0,
  },
  yAxis: {
    maxPadding: 0,
    minPadding: 0,
    title: {
      text: null,
    },
  },
  legend: {
    align: "left",
    backgroundColor: "transparent",
    borderWidth: 0,
    enabled: legendEnabled,
    itemMarginBottom: legendItemMargin / 2,
    itemMarginTop: legendItemMargin / 2,
    itemStyle: {
      color: "#667587",
      "font-size": `${legendFontSize}px`,
      "font-weight": "400",
      "font-family": "'proxima-nova', Arial, Helvetica, sans-serif",
    },
    labelFormatter() {
      return labelFormatter ? labelFormatter(this) : `${this.name}`;
    },
    layout: "vertical",
    navigation: {
      enabled: legendIsPaginated,
    },
    shadow: false,
    styledMode: true,
    symbolHeight: symbolSize,
    symbolPadding: symbolPadding,
    symbolRadius: symbolRadius,
    symbolWidth: symbolSize,
    verticalAlign: "middle",
    x: legendX ? pieSize + legendX : pieSize * 1.2,
  },
  series: [
    {
      data: chartData,
      type: "pie",
    },
  ],
  tooltip: {
    backgroundColor: tooltipClassName ? null : "unset",
    className: tooltipClassName,
    followPointer: true,
    formatter: tooltipFormatter,
    outside: true,
    useHTML: !!tooltipClassName,
  },
  plotOptions: {
    pie: {
      allowPointSelect: false,
      borderColor: null,
      borderWidth: 0,
      cursor: "pointer",
      dataLabels: { enabled: false },
      innerSize: pieSize - chartBorderOffset - (pieThickness - 2) * 2,
      point: {
        events: {
          legendItemClick: () => false,
        },
      },
      showInLegend: legendEnabled,
      size: pieSize - chartBorderOffset,
      states: {
        hover: {
          halo: null,
        },
      },
    },
  },
});

export const getHorizontalConfig = ({
  baseConfig,
  leftPositionCorrection,
  legendX,
  pieSize,
}) => ({
  ...baseConfig,
  chart: {
    ...baseConfig.chart,
    height: pieSize,
    margin: [0, 0, 0, leftPositionCorrection],
  },
  legend: {
    ...baseConfig.legend,
    align: "left",
    x: legendX ? pieSize + legendX : pieSize * 1.2,
    y: 0,
  },
  plotOptions: {
    pie: {
      ...baseConfig.plotOptions.pie,
      center: [pieSize * 0.2 + "px", "50%"],
    },
  },
});

export const getVerticalConfig = ({
  baseConfig,
  legendHeight,
  legendY,
  pieSize,
}) => ({
  ...baseConfig,
  chart: {
    ...baseConfig.chart,
    height: pieSize + legendHeight + legendY,
    margin: [-legendHeight - legendY, 0, 0, 0],
  },
  legend: {
    ...baseConfig.legend,
    align: "center",
    x: 0,
    y: pieSize / 2,
  },
  plotOptions: {
    pie: {
      ...baseConfig.plotOptions.pie,
      center: ["50%", "50%"],
    },
  },
});

export const updateChartConfig = ({
  chartConfig,
  chartData,
  noDataDemonstrated,
  pieSize,
  pieThickness,
  setChartConfig,
  setNoData,
  zeroStateCustom,
  zeroStateDemonstrated,
}) => {
  let newChartConfig = { ...chartConfig };
  const total = chartData.reduce((acc, val) => acc + val.y, 0);
  if (total === 0) {
    if (zeroStateDemonstrated || zeroStateCustom) {
      newChartConfig = noDataDemonstrated(newChartConfig);
    } else {
      newChartConfig = defaultNoData(newChartConfig, pieSize, pieThickness);
    }
  } else {
    setNoData(false);
  }
  newChartConfig.series[0].data = chartData;
  setChartConfig(newChartConfig);
};

export const updateTooltipFormatter = ({
  chartConfig,
  tooltipFormatter,
  setChartConfig,
}) => {
  const newChartConfig = { ...chartConfig };
  newChartConfig.tooltip.formatter = tooltipFormatter;
  setChartConfig(newChartConfig);
};

export const updateNoData = ({ loading, setNoData }) => {
  loading ? setNoData(true) : setNoData(false);
};
