import React, { useState } from "react";
import GetReviewsSidebar from "./GetReviewsSidebar";
import GetReviewsContent from "./GetReviewsContent";
import { Row, Col } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_EMAIL_INVITATION,
  CREATE_CUSTOMER_CALL,
} from "src/graphql/reviews/get_reviews/mutations";
import { useApolloClient } from "@apollo/react-hooks";
import { QUERY_INVITATIONS_SUMMARY_FILTER } from "src/graphql/reviews/get_reviews/queries";
import { NETWORK_ONLY } from "src/shared/Constants";
import { showCompanyId, featureFlags } from "src/shared/SessionHelper";
import debounce from "lodash.debounce";
import "./GetReviews.scss";

const GetReviews = () => {
  // variables
  const initialFilters = {
    searchTerm: "",
    selectedViewBy: { value: "all_customers", labelString: "All Customers" },
    missingEmailAddress: false,
    missingPhoneNumber: false,
  };

  // state
  const [display, setDisplay] = useState("none");
  const [filters, setFilters] = React.useState({ ...initialFilters });
  const [viewByCount, setViewByCount] = React.useState([]);
  const client = useApolloClient();

  // call apis
  const [createCustomerCall] = useMutation(CREATE_CUSTOMER_CALL);
  const [createEmailInvitation] = useMutation(CREATE_EMAIL_INVITATION);

  // methods
  const resetFilters = () => {
    setFilters({ ...initialFilters });
  };
  const getSidebarCount = debounce(() => {
    client
      .query({
        query: QUERY_INVITATIONS_SUMMARY_FILTER,
        fetchPolicy: NETWORK_ONLY,
        variables: {
          companyId: showCompanyId,
          searchTerm:
            filters.searchTerm && filters.searchTerm.length > 2
              ? filters.searchTerm
              : "",
          missingEmailAddress: filters.missingEmailAddress,
          missingPhoneNumber: filters.missingPhoneNumber,
        },
      })
      .then((res) => {
        if (res && res.data && res.data.invitationsSummaryFilters) {
          const counts = res.data.invitationsSummaryFilters;
          setViewByCount([
            counts.allCustomers || 0,
            counts.lastInvitedViaEmail || 0,
            counts.lastInvitedViaCall || 0,
            counts.eligibleForInvitation || 0,
            counts.neverResponded || 0,
          ]);
        }
      })
      .catch(() => {});
  }, 500);

  React.useEffect(() => {
    getSidebarCount();
  }, [
    filters.searchTerm,
    filters.missingEmailAddress,
    filters.missingPhoneNumber,
  ]);

  // feature flag check
  if ((!featureFlags.reviewRead)) {
    return null;
  }

  // render
  return (
    <div className="get-reviews-container">
      <div className="get-reviews-block">
        <Row>
          <Col md={3} sm={12} className="get-reviews-sidebar">
            <GetReviewsSidebar
              viewByCount={viewByCount}
              filters={filters}
              setFilters={setFilters}
            />
          </Col>
          <Col md={9} sm={12} className="get-reviews-content">
            <GetReviewsContent
              createCustomerCall={createCustomerCall}
              createEmailInvitation={createEmailInvitation}
              initialFilters={initialFilters}
              resetFilters={resetFilters}
              filters={filters}
              setFilters={setFilters}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GetReviews;
