import React, { useEffect, useState, useRef } from 'react'
import './EditEmailModal.scss'
import editEmailImg from '../../../../../../../styles/assets/information.png'
import { editEmailHelper } from './query_actions'
import SuccessToast from './SuccessToast'

const EditEmailModal = ({ display, updateComponent, setUpdateComponent, handleInvitations, contact, setDisplay, authHeader }) => {
  const emailModalEl = useRef(null)
  const [ successDisplay, setSuccessDisplay ] = useState('none')
  const [ state, setState ] = useState({ email:  "" })
  const [ emailValid, setEmailValid ] = useState(false)

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    if(contact.emailAddress && contact.emailAddress.length > 0) setState({email: contact.emailAddress})
  }, [contact])

  const handleChange = (e) => {
    let email = e.target.value
    if(email !== contact.emailAddress && validateEmail(email)) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
    }

    setState({...state, email})
  }

  const closeModal = (e) => {
    setDisplay('none')
  }

  const handleSubmit = async () => {
    let { email } = state
    let data = await editEmailHelper({ email, emailInvitationId: contact.id, authHeader })
    setDisplay('none')
    setSuccessDisplay ('flex')
    handleInvitations()
    setUpdateComponent(!updateComponent)
    setTimeout(() => {
      setSuccessDisplay('none')
    }, 5000)
  }

  let { email } = state

  return (
    <React.Fragment>
      <div ref={emailModalEl} style={{ display }} id="email-modal-container">
        <div className="inner-container">
          <div className="header-container">
            <div className="header-modal-label">Edit Email</div>
            <div onClick={closeModal} className="close-modal">X</div>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="edit-email-label">Email Address</div>
            <input onChange={handleChange} className="edit-email-input" type="email" value={email}/>
            <div className="email-info-container">
              <img className="edit-email-img" alt="Edit Email" src={editEmailImg} />
              <div className="edit-email-info-txt">Update the email address to resend the review invite.</div>
            </div>
          </form>
          <div className="edit-email-divider"></div>
          <div className="edit-email-btns-container">
            <div onClick={() => setDisplay('none')} className='edit-email-cancel'>Cancel</div>
            <div onClick={emailValid ? handleSubmit : null} style={{opacity: emailValid ? 1 : 0.6}} className="edit-email-resend">Resend</div>
          </div>
        </div>
      </div>
      <SuccessToast content={"Email successfully resent."} display={successDisplay} />
    </React.Fragment>
  )
}

export default EditEmailModal
