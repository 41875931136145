import React from "react";
import { Table as RBTable } from "react-bootstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import UpArrow from "src/assets/images/common/up_arrow.png";
import UpArrowActive from "src/assets/images/common/up_arrow_active.png";
import DownArrow from "src/assets/images/common/down_arrow.png";
import DownArrowActive from "src/assets/images/common/down_arrow_active.png";
import "./Table.scss";

const PageInformation = ({ currentPage, perPage, totalItems }) => {
  const start = currentPage * perPage + 1;
  const end = (currentPage + 1) * perPage;

  // checks
  if (totalItems <= 0) {
    return null;
  }
  // render
  return (
    <div>
      Displaying {start}
      {end < totalItems
        ? `-${end}`
        : start === totalItems
        ? ""
        : `-${totalItems}`}{" "}
      of {totalItems}
    </div>
  );
};

PageInformation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
};

const SortableTableHeader = ({
  columnLabel,
  sortable = false,
  columnValue = undefined,
  ascendingKey = "asc",
  descendingKey = "desc",
  onSort = () => {},
  currentSortValue = "",
  currentSortOrder = "",
  enabledOpacity = 1,
  disabledOpacity = 0.6,
  enabledSize = 7,
  SortType = "custmer_markting",
}) => {
  // variable
  const callbackValue = columnValue !== undefined ? columnValue : columnLabel;
  const sortedByAsc =
    currentSortValue === callbackValue && currentSortOrder === ascendingKey;
  const sortedByDesc =
    currentSortValue === callbackValue && currentSortOrder === descendingKey;
  const downActive = SortType==="prospect"?DownArrowActive:DownArrow;
  const UpActive = SortType==="prospect"?UpArrowActive:UpArrow


  // render
  return (
    <div
      className={classnames(
        "d-inline-flex align-items-center",
        `column-${columnValue}`,
      )}
    >
      <span className="text-dark">{columnLabel}</span>
      {sortable ? (
        <div className="d-flex flex-column ml-2 sort-icon">
          {sortedByDesc ? null : (
            <img
              style={{
                marginBottom: 0.6,
                opacity: sortedByAsc ? disabledOpacity : enabledOpacity,
                minWidth: sortedByAsc ? enabledSize : undefined,
              }}
              onClick={() => {
                onSort(
                  callbackValue,
                  sortedByAsc ? descendingKey : ascendingKey,
                );
              }}
              src={sortedByAsc ? UpActive : UpArrow}
              alt="Ascending"
              className="cursor-pointer"
            />
          )}
          {sortedByAsc ? null : (
            <img
              style={{
                opacity: sortedByDesc ? disabledOpacity : enabledOpacity,
                minWidth: sortedByDesc ? enabledSize : undefined,
              }}
              className="cursor-pointer"
              onClick={() => {
                onSort(
                  callbackValue,
                  sortedByDesc ? ascendingKey : descendingKey,
                );
              }}
              src={sortedByDesc ? downActive : DownArrow}
              alt="Descending"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

SortableTableHeader.propTypes = {
  columnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  columnValue: PropTypes.any,
  onSort: PropTypes.func,
  sortable: PropTypes.bool,
  ascendingKey: PropTypes.string,
  descendingKey: PropTypes.string,
  currentSortValue: PropTypes.string,
  currentSortOrder: PropTypes.string,
  enabledOpacity: PropTypes.number,
  disabledOpacity: PropTypes.number,
  enabledSize: PropTypes.number,
};

const Table = ({
  body,
  className = "",
  currentPage = 0,
  hasPagination = false,
  head,
  loading = false,
  loadingMessage = "Your data is being generated!",
  noData = "No Data",
  onPagination = () => {},
  pageCount = 0,
  perPage = 10,
  shouldScrollToTop = true,
  showPageInformation = false,
  totalItems = 0,
}) => {
  // methods
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // render
  return (
    <React.Fragment>
      <RBTable className={classnames("sg-table", className)} responsive>
        {head && head.length > 0 ? (
          <thead>
            <tr>
              {head.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
        ) : null}
        {body && body.length > 0 ? (
          <tbody>
            {body.map((row, index) => (
              <tr key={index}>
                {row.map(({ node, props = {} }, index) => (
                  <td key={index} {...props}>
                    {node}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                className="text-center"
                colSpan={head && head.length ? head.length : 1}
              >
                {loading ? loadingMessage : noData}
              </td>
            </tr>
          </tbody>
        )}
      </RBTable>
      {showPageInformation ? (
        <div className="sg-table-page-info mt-4 float-right">
          <PageInformation
            currentPage={currentPage}
            perPage={perPage}
            totalItems={totalItems}
          />
        </div>
      ) : null}
      {hasPagination && pageCount > 1 ? (
        <PaginatorComponent
          pageCount={pageCount}
          pageChangeHandler={(...args) => {
            if (shouldScrollToTop) {
              scrollToTop();
            }
            onPagination(...args);
          }}
          currentPage={currentPage}
        />
      ) : null}
    </React.Fragment>
  );
};

Table.propTypes = {
  head: PropTypes.arrayOf(PropTypes.node),
  body: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  hasPagination: PropTypes.bool,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  onPagination: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noData: PropTypes.node,
  perPage: PropTypes.number,
  totalItems: PropTypes.number,
  showPageInformation: PropTypes.bool,
  shouldScrollToTop: PropTypes.bool,
};

export default Table;

export { Table, SortableTableHeader, PageInformation };
