import { GET_REVIEWS_METADATA, GET_CREDITS } from "./queries";
import { API_GRAPHQL_URL } from "../../../../shared/Config";
import axios from "axios";

export const getMetadataHelper = async ({
  companyId,
  dateFrom,
  source,
  authHeader,
  searchTerm,
}) => {
  const queryParams = {
    query: GET_REVIEWS_METADATA,
    variables: {
      companyId,
      dateFrom,
      source,
      searchTerm,
    },
  };
  try {
    let {
      data: { data },
    } = await axios.post(API_GRAPHQL_URL, queryParams, authHeader);
    return data;
  } catch (e) {
    return null;
  }
};

export const getCreditsHelper = async ({
  companyId,
  creditType,
  authHeader,
}) => {
  const queryParams = {
    query: GET_CREDITS,
    variables: {
      companyId,
      creditType,
    },
  };
  try {
    let {
      data: { data },
    } = await axios.post(API_GRAPHQL_URL, queryParams, authHeader);

    return data;
  } catch (e) {
    return null;
  }
};
