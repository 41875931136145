import React from 'react'
const Header = ({
                  rowOne,
                  sortDataUp,
                  sortDataDown,
                  rowTwo,
                  rowThree,
                  reviewMeActive,
                  rowFour,
                  rowFive,
                  rowSix,
                  upArrow,
                  downArrow
                }) => {

  const helpMe = () => {

  }

  return (
    <div className="header">
      <div className="header-img-container">
        <div className="header-label">{rowOne}</div>
        <div className="header-arrows-container">
          <img onClick={() => sortDataUp('ASC', 0)} className="header-up-arrow" src={upArrow} alt="Arrow" />
          <img onClick={() => sortDataDown("DESC", 0)} className="header-down-arrow" src={downArrow} alt="Arrow" />
        </div>
      </div>
      <div className="header-img-container">
        <div className="header-label">{rowTwo}</div>
        <div className="header-arrows-container">
          <img onClick={() => sortDataUp('ASC', 1)} className="header-up-arrow" src={upArrow} alt="Arrow" />
          <img onClick={() => sortDataDown("DESC", 1)} className="header-down-arrow" src={downArrow} alt="Arrow" />
        </div>
      </div>
      <div className="header-img-container">
        <div className="header-label">{rowThree}</div>
        <div className="header-arrows-container">
          <img onClick={() => sortDataUp('ASC', 2)} className="header-up-arrow" src={upArrow} alt="Arrow" />
          <img onClick={() => sortDataDown("DESC", 2)} className="header-down-arrow" src={downArrow} alt="Arrow" />
        </div>
      </div>
      <div className="header-img-container">
        <div className="header-label">{rowFour}</div>
        <div className="header-arrows-container">
          <img onClick={() => sortDataUp('ASC', 3)} className="header-up-arrow" src={upArrow} alt="Arrow" />
          <img onClick={() => sortDataDown("DESC", 3)} className="header-down-arrow" src={downArrow} alt="Arrow" />
        </div>
      </div>
      <div className="header-img-container">
        <div className="header-label">{rowFive}</div>
        <div className="header-arrows-container">
          <img onClick={() => sortDataUp('ASC', 4)} className="header-up-arrow" src={upArrow} alt="Arrow" />
          <img onClick={() => sortDataDown("DESC", 4)} className="header-down-arrow" src={downArrow} alt="Arrow" />
        </div>
      </div>
    {reviewMeActive() ? (
        <div className="header-img-container">
          <div className="header-label">{rowSix}</div>
          <div className="header-arrows-container">
            <img onClick={() => sortDataUp('ASC', 5)} className="header-up-arrow" src={upArrow} alt="Arrow" />
            <img onClick={() => sortDataDown("DESC", 5)} className="header-down-arrow" src={downArrow} alt="Arrow" />
          </div>
        </div>
      ): null}
    </div>
  )
}

export default Header
