import { gql } from "apollo-boost";

const QUERY_OVERALL_PERFORMANCE_REVIEW = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    overallReviewPerformance(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      data
      dateRanges
    }
  }
`;

const QUERY_REVIEW_VS_INVITES = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    reviewInviteSummary(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      invites
      reviews
      source
    }
  }
`;

const QUERY_SENTIMENT_SCORE_TREND = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    sentimentScoreTrend(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      currentPeriod
      previousPeriod
      data
      dateRanges
    }
  }
`;

const QUERY_TRAFFIC_TO_REVIEW_PAGES = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    reviewPagesTraffic(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    )
  }
`;

const QUERY_REVIEW_DISTRIBUTION_BY_SOURCE = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    reviewsDistributionBySource(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      customCount
      customPercent
      customerCallCount
      customerCallPercent
      emailCount
      emailPercent
      handwrittenCount
      handwrittenPercent
      kioskCount
      kioskPercent
      reviewmeCount
      reviewmePercent
      webCount
      webPercent
      webTextCount
      webTextPercent
    }
  }
`;

const QUERY_REVIEW_FILTERS = gql`
  query($companyId: ID!) {
    filters: reviewsMeta(companyId: $companyId) {
      dateRange {
        label
        value
        disabled
        bucket
      }
    }
  }
`;

const QUERY_REVIEWS_META = gql`
  query($companyId: ID!) {
    filters: reviewsMeta(companyId: $companyId) {
      dateRange {
        label
        value
        disabled
        bucket
      }
    }
    company(companyId: $companyId) {
      abbreviatedName
      companyName
      state
      city
    }
    smartInviteSettings(companyId:$companyId) {
      googleLongUrl
    }
  }
`;

const QUERY_RESPONSE_RATE_BY_PLATFORM = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    responseRateByPlatform(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      data
      dateRanges
    }
  }
`;

const QUERY_AVERAGE_RATING_BY_LOCATION = gql`
  query(
    $companyId: ID!
    $dateFrom: String!
    $dateBucket: String!
    $filter: String!
  ) {
    averageRatingByLocation(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      filter: $filter
    )
  }
`;

const QUERY_DISTRIBUTION_BY_RATING = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    distributionByRating(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      source: "all"
    ) {
      fiveStarCount
      fiveStarPercent
      fourStarCount
      fourStarPercent
      oneStarCount
      oneStarPercent
      threeStarCount
      threeStarPercent
      twoStarCount
      twoStarPercent
    }
  }
`;

const QUERY_DISTRIBUTION_BY_TOPIC = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    distributionByRating(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      source: "all"
    ) {
      topicOne
      topicOneAverage
      topicThree
      topicThreeAverage
      topicTwo
      topicTwoAverage
      topicOneAverageLabel
      topicTwoAverageLabel
      topicThreeAverageLabel
    }
  }
`;

const QUERY_MINI_WIDGETS = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    privateFeedback: privateFeedbackDispositions(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      id
      count
      name
    }
    responseRate: inviteResponseRate(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      change
      currentResponseRate
      previousResponseRate
      previousRange {
        from
        to
      }
      currentRange {
        from
        to
      }
    }
    publishedReviews: reviewCounts(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      status: "publish"
    ) {
      negative
      positive
      published
    }
    pendingReviews: reviewCounts(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      status: "pending"
    ) {
      negative
      positive
      pending
    }
    sentimentScore: reviewCounts(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      status: "publish"
    ) {
      positive
      negative
      sentimentScore
    }
    overallRating: reviewCounts(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      allReviews
      averageScore
    }
  }
`;

const QUERY_PAGE_VIEWS = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    reviewPagesViews(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    )
  }
`;

export {
  QUERY_MINI_WIDGETS,
  QUERY_OVERALL_PERFORMANCE_REVIEW,
  QUERY_REVIEW_VS_INVITES,
  QUERY_SENTIMENT_SCORE_TREND,
  QUERY_REVIEW_DISTRIBUTION_BY_SOURCE,
  QUERY_REVIEWS_META,
  QUERY_RESPONSE_RATE_BY_PLATFORM,
  QUERY_AVERAGE_RATING_BY_LOCATION,
  QUERY_DISTRIBUTION_BY_RATING,
  QUERY_DISTRIBUTION_BY_TOPIC,
  QUERY_REVIEW_FILTERS,
  QUERY_TRAFFIC_TO_REVIEW_PAGES,
  QUERY_PAGE_VIEWS,
};
