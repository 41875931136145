import { gql } from 'apollo-boost';

const GET_APP_INTEGRATIONS = gql`
  query appIntegrations($companyId: ID!) {
      appIntegrations(companyId: $companyId) {
      shortName
      name
      status
      updatedAt
      metaData
    }
  }
`;

export {
  GET_APP_INTEGRATIONS
}
