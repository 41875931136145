import React from 'react'
import PropTypes from 'prop-types';
import Form from "src/shared/styleguide/form/Form";
import "react-select/dist/react-select.css";
import "src/components/reviews/manage_reviews/sidebar/SidebarComponent.scss";

const SidebarMenuMultiDropdown = ({
    disabled,
    inputProps,
    name,
    onChange,
    options,
    placeholder,
    subTitle,
    title,
    value,
 }) => { 

  const optionRender = (node) => {
    const isChecked = !!value.filter(item => item.value === node.value).length;

    return (
      <div className="dropdown-option-container">
        <Form.Group className="dropdown-checkbox-container">
          <Form.Check
            custom
            checked={isChecked}
            onChange={() => {}}
            type="checkbox"
            label=""
          />
        </Form.Group>
        <div className="dropdown-option-label">{node.label}</div>
      </div>
    )
  }

  return (
    <div className="sg-sidebar-component-container">
      {title
        ? <div className="filter-title">{title}</div> 
        : null
      }
      {subTitle 
        ? <div style={{marginBottom: '7px'}} className="filter-sub-title">
            {subTitle}
          </div>
        : null
      }

      <Form.MultiSelect
        closeOnSelect={false}
        clearable={false}
        disabled={disabled}
        inputProps={inputProps}
        multi
        name={name}
        onChange={onChange}
        optionRenderer={optionRender}
        options={options}
        placeholder={placeholder}
        removeSelected={false}
        value={value}
      />
    </div>
  )
}

SidebarMenuMultiDropdown.propTypes = {
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.array,
}

export default SidebarMenuMultiDropdown;
