import React from "react";
import { toast } from "react-toastify";

const typeMapping = (type) => {
  switch (type) {
    case "success":
      return "SUCCESS";
    case "getsupport":
      return "SUCCESS";
    case "warn":
    case "warning":
      return "WARNING";
    case "info":
      return "INFO";
    case "error":
      return "ERROR";

    default:
      return "DEFAULT";
  }
};

const headerTypeMapping = (type) => {
  switch (type) {
    case "success":
      return "Success";
    case "getsupport":
      return "Get Support";
    case "warn":
    case "warning":
      return "Warning";
    case "info":
      return "Info";
    case "error":
      return "Error";
    default:
      return "Default";
  }
};

export const addToast = (toastObj) => {
  toast(
    <React.Fragment>
      <div className="decorator"></div>
      <div className={`${toastObj.type ? toastObj.type : "default"}-toast`}>
        <h3>{headerTypeMapping(toastObj.type)}</h3>
        {toastObj.message}
        {toastObj.action && (
          <div className="toast-action">{toastObj.action}</div>
        )}
      </div>
    </React.Fragment>,
    {
      ...toastObj,
      autoClose:
        typeof toastObj.persist !== "undefined" ? !toastObj.persist : undefined,
      draggable: false,
      hideProgressBar: true,
      pauseOnHover: true,
      position: toastObj.position || "bottom-left",
      type: toast.TYPE[typeMapping(toastObj.type)],
    },
  );
};
