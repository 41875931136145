import React, { useState } from "react";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  QUERY_REVIEW_TOPICS,
  QUERY_DEFAULT_REVIEW_TOPICS,
} from "src/graphql/settings/reviews/queries";
import { MUTATION_REVIEW_TOPICS } from "src/graphql/settings/reviews/mutations";
import { showCompanyId } from "src/shared/SessionHelper";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import errorAlertImg from "src/assets/images/alerts/error.svg";
import swal from "sweetalert";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import { addToast } from "src/utils/ToastUtil"

const ReviewTopicsSettings = () => {
  // state
  const [numReviews, setNumReviews] = useState(0);
  const [numTopics, setNumTopics] = useState(0);
  const [reviewTopics, setReviewTopics] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const formik = useFormik({
    initialValues: {
      topic1: null,
      topic2: null,
      topic3: null,
    },
    validationSchema: Yup.object().shape({
      topic1: Yup.object()
        .typeError("Invalid option")
        .required("Field required"),
      topic2: Yup.object()
        .typeError("Invalid option")
        .required("Field required"),
      topic3: Yup.object()
        .typeError("Invalid option")
        .required("Field required"),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });

  // call apis
  const { loading, data: dataReviewTopics } = useQuery(QUERY_REVIEW_TOPICS, {
    variables: {
      companyId: showCompanyId,
    },
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    onCompleted(data) {
      populateData(data);
    },
  });
  useQuery(QUERY_DEFAULT_REVIEW_TOPICS, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    onCompleted(data) {
      if (data && data.defaultReviewTopics) {
        setReviewTopics(
          data.defaultReviewTopics
            .sort(({ displayOrder: da, displayOrder: db }) => da - db)
            .map((topic) => ({
              ...topic,
              value: topic.topicId,
              label: topic.topic,
              reviewTopic: topic.topic,
            })),
        );
      }
    },
  });
  const [saveSettings] = useMutation(MUTATION_REVIEW_TOPICS);

  // methods
  const populateData = (data) => {
    if (data && data.company && data.reviewsList) {
      setNumReviews(data.reviewsList.total);
    }
    if (data && data.company && data.company.reviewTopics) {
      if (data.company.reviewTopics.length > 0) {
        const topics = data.company.reviewTopics.map((topic) => ({
          ...topic,
          value: topic.reviewTopicId,
          label: topic.reviewTopic,
        }));
        setNumTopics(topics.length);
        formik.resetForm({
          values: {
            topic1: topics[0],
            topic2: topics[1],
            topic3: topics[2],
          },
        });
      }
    }
  };

  const saveChanges = ({ topic1, topic2, topic3 }) => {
    const reviewTopics = [topic1, topic2, topic3]
      .filter((topic) => !!topic)
      .map((topic, index) => ({
        reviewTopicId: topic.reviewTopicId,
        reviewTopic: topic.reviewTopic,
        currentOrder: index + 1,
      }));

    swal({
      title: "Are you sure?",
      className: "review-topics",
      text:
        "Once topics are selected and saved, you will not be able to change them after you receive your first review.",
      icon: "error",
      buttons: {
        save: {
          text: "Yes, Save",
          value: "save",
        },
        cancel: true,
      },
      dangerMode: true,
      confirmButtonColor: "LightSeaGreen",
      cancelButtonColor: "Crimson",
    }).then((confirm) => {
      if (confirm === "save") {
        saveSettings({
          variables: {
            companyId: showCompanyId,
            reviewTopics,
          },
        })
          .then(() => {
            addToast({
              type: "success",
              message: "Review Topics updated",
            });
          })
          .catch(() => {
            addToast({
              type: "error",
              message: "Review Topics not updated",
            });
          });
      }
    });
  };

  // effects
  React.useEffect(() => {
    if (numReviews > 0) {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  }, [numReviews, numTopics]);
  React.useEffect(() => {
    if (dataReviewTopics) {
      populateData(dataReviewTopics);
    }
  }, [loading, dataReviewTopics]);

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Review Topics"
      settingsDescription="This section enables you to change the category for each review topic. Each review includes 3 rating categories. Choose what you are rated on."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.234"
          height="36.363"
          viewBox="0 0 38.234 36.363"
        >
          <path
            d="M37.853 25.554l-12.536-2.646-6.39-11.1-6.39 11.1L0 25.554l8.587 9.509L7.229 47.8l11.7-5.228 11.7 5.228-1.358-12.74zM18.927 40L10 43.983l1.035-9.717-6.544-7.253 9.562-2.018 4.874-8.47 4.874 8.47 9.562 2.018-6.549 7.253 1.035 9.717z"
            transform="translate(.191 -11.603)"
            strokeWidth=".2px"
          />
        </svg>
      }
      settingsForm={
        <form onSubmit={formik.handleSubmit}>
          {formDisabled ? (
            <div
              style={{
                borderRadius: 6,
                border: "solid 1px #f42134",
              }}
              className="px-4 d-inline-block py-2 mt-4"
            >
              <div className="d-flex flex-row align-items-center">
                <img
                  src={errorAlertImg}
                  alt="Error"
                  className="mr-2"
                  style={{
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <div style={{ color: "#f42134" }}>
                  You have reviews already, and cannot change the topic
                  categories
                </div>
              </div>
            </div>
          ) : null}

          {/* Topic 1 */}
          <div className="d-flex flex-row mt-4 align-items-center">
            <Form.Group
              className="mb-0"
              style={{ width: 280 }}
              controlId="topic1"
            >
              <Form.Label className="text-dark small">
                Topic 1<span className="ml-2 text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="topic1"
                value={formik.values.topic1}
                onChange={(val) => formik.setFieldValue("topic1", val)}
                placeholder="Select a Topic"
                options={reviewTopics}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>
          </div>

          {/* Topic 2 */}
          <div className="d-flex flex-row mt-2 align-items-center">
            <Form.Group
              className="mb-0"
              style={{ width: 280 }}
              controlId="topic2"
            >
              <Form.Label className="text-dark small">
                Topic 2<span className="ml-2 text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="topic2"
                value={formik.values.topic2}
                onChange={(val) => formik.setFieldValue("topic2", val)}
                placeholder="Select a Topic"
                options={reviewTopics}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>
          </div>

          {/* Topic 3 */}
          <div className="d-flex flex-row mt-2 align-items-center">
            <Form.Group
              className="mb-0"
              style={{ width: 280 }}
              controlId="topic3"
            >
              <Form.Label className="text-dark small">
                Topic 3<span className="ml-2 text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="topic3"
                value={formik.values.topic3}
                onChange={(val) => formik.setFieldValue("topic3", val)}
                placeholder="Select a Topic"
                options={reviewTopics}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>
          </div>
          <div className="mt-3">
            <Button
              type="submit"
              variant="primary"
              className="mr-2"
              disabled={!formik.dirty || formDisabled || !formik.isValid}
            >
              Save Changes
            </Button>
          </div>
        </form>
      }
    />
  );
};

export default ReviewTopicsSettings;
