export const commPreferencesShaper = (data) => ({
  setting: {
    email: data ? data.wantsEmail : false,
    call: data ? data.wantsCalls : false,
    text: data ? data.wantsSms : false,
    mail: data ? data.wantsMail : false,
  },
  sources: {
    directConnect: {
      email: data ? data.dcWantsEmail : false,
      call: data ? data.dcWantsCall : false,
      text: data ? data.dcWantsSms : false,
      mail: data ? data.dcWantsMail : false,
    },
    manual: {
      email: data ? data.memberWantsEmail : false,
      call: data ? data.memberWantsCall : false,
      text: data ? data.memberWantsSms : false,
      mail: data ? data.memberWantsMail : false,
    },
    customer: {
      email: data ? data.customerWantsEmail : false,
      call: data ? data.customerWantsCall : false,
      text: data ? data.customerWantsSms : false,
      mail: data ? data.customerWantsMail : false,
    },
  },
});

export const initialCustomerData = {
  id: null,
  firstName: "",
  lastName: "",
  commercial: false,
  company_name: "",
  email: "",
  mobile_phone_number: "",
  daytime_phone_number: "",
  daytime_phone_ext: "",
  alternate_phone_number: "",
  alterenate_phone_ext: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip: null,
  inContract: false,
  source: "direct_connect",
  contractStartDate: "1900/01/01",
  contractEndDate: "1900/01/02",
};
