import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Form from "src/shared/styleguide/form/Form";
import StringUtil from "src/utils/StringUtil";
import { MAXIMUM_SEGMENT_NAME_LENGTH } from "./SegmentConstants";
import { nameValidator as validator } from "./SegmentUtils";

const SegmentNameInput = ({
  classNames,
  filterOriginalName,
  filtersList,
  formik,
  isValid,
  segmentName,
  segmentNameChangeHandler,
  setIsValid,
  type = "Segment",
}) => {
  React.useEffect(() => {
    if (formik && formik.errors && formik.errors.name === "invalid submit") {
      setIsValid(false);
    }
  }, [formik && formik.errors]);

  return (
    <Form.Group controlId="segmentName">
      <Form.Label className="text-dark form-label">
        {StringUtil.capitalizeEachWord(type)} Name{" "}
        <small className="text-danger">*</small>{" "}
      </Form.Label>
      <div className="input-container">
        <Form.Control
          placeholder=""
          onChange={(e) => {
            setIsValid(true);
            if (e.target.value.length <= 100) {
              segmentNameChangeHandler(e.target.value);
            }
          }}
          onBlur={(e) => {
            setIsValid(
              validator(filtersList, e.target.value, filterOriginalName),
            );
          }}
          value={segmentName}
          className={classnames({
            "is-invalid": !isValid,
            [classNames]: classNames,
          })}
        />
      </div>

      <div className="segment-name-messages">
        {!isValid && !segmentName.length
          ? "Required field"
          : !isValid
          ? "This name already exists, please use a different name."
          : null}
        <div className="segment-name-count">
          {segmentName.length}/{MAXIMUM_SEGMENT_NAME_LENGTH}
        </div>
      </div>
    </Form.Group>
  );
};

SegmentNameInput.propTypes = {
  classNames: PropTypes.string,
  filterOriginalName: PropTypes.string,
  filtersList: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
  segmentName: PropTypes.string.isRequired,
  segmentNameChangeHandler: PropTypes.func.isRequired,
};

export default SegmentNameInput;
