export const REVIEW_DETAIL = `
query($reviewId: ID!, $reviewType: String!) {
    reviewDetail(reviewId: $reviewId, reviewType: $reviewType) {
      reviewId
      reviewType
      reviewStatus
      contact {
        contactId
        fullName
      }
      totalScore
      source
      reviewDate
      destination
      xrefTopics {
        reviewTopic
        rating
      }
      reviewSummary
      reviewBody
      isFeatured
      contactSuggestions {
        suggestionTopic
        suggestionAnswer
      }
      comments {
        comment
      }
    }
  }
`
export const REVIEW_CHALLENGE = `
  query($reviewId: ID!) {
    reviewChallenge(reviewId: $reviewId) {
      id
      reviewId
      status
      read
      reason
      challengeCommunications {
        communicationBody
        userId
        userType
        accessedAt
        createdAt
        updatedAt
      }
    }
  }
`

export const REVIEW_WITHDRAWAL_REQUEST = `
  query($reviewId: ID!) {
    reviewWithdrawlRequest(reviewId: $reviewId) {
      id
      reviewId
      requestDate
      note
      formattedRequestDate
      formattedWithdrawDate
    }
  }
`
