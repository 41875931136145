import React from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import Button from "src/shared/styleguide/button/Button";
import { constants } from "src/shared/Constants";
import { ToastContainer } from "react-toastify";
import { addToast } from "src/utils/ToastUtil";
import { toast } from "react-toastify";

export const AlertsStyleGuide = () => {
  useDocumentTitle("Style Guide - Alerts");

  const SomeComponent = ({ name }) => {
    return (
      <div>
        <p>Hello, {name}</p>
        <Button
          className="mx-2"
          variant="success"
          onClick={() =>
            addToast({
              action: <a href="#">Action</a>,
              message: `Hello, ${name}`,
              persist: true,
              type: "success",
            })
          }
        >
          Show Success Toast
        </Button>
        <Button
          className="mx-2"
          variant="danger"
          onClick={() =>
            addToast({
              action: <a href="#">Acción</a>,
              message: `Hola, ${name}`,
              persist: true,
              type: "error",
            })
          }
        >
          Show Error Toast
        </Button>
        <Button
          className="mx-2"
          variant="info"
          onClick={() =>
            addToast({
              action: <a href="#">Azione</a>,
              message: `Ciao, ${name}`,
              persist: true,
              type: "info",
            })
          }
        >
          Show Info Toast
        </Button>
        <Button
          className="mx-2"
          variant="warning"
          onClick={() =>
            addToast({
              action: <a href="#">Hana</a>,
              message: `Aloha, ${name}`,
              persist: true,
              type: "warning",
            })
          }
        >
          Show Warning Toast
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
      <h3 className="text-dark font-weight-bold">Alerts</h3>
      <StyleGuideSection
        title="Toast Notifications"
        description="ToastContainer is necessary here in the style guide,
          but should not be necessary in implementing in the App
          as the App is already wrapped in a container"
      >
        <CodePreview
          elements={
            <React.Fragment>
              <SomeComponent name="Friend" />
            </React.Fragment>
          }
          code={`import { addToast } from "src/utils/ToastUtil";
  
  // your component
  const SomeComponent = ({ name }) => {
    return (
      <div> 
      <p>Hello, {name}</p>
      <Button
        className="mx-2"
        variant="success"
        onClick={() => 
          addToast({
            action: <a href="#">Action</a>,
            message: \`Hello, \${name}\`, 
            persist: true,
            type: "success", 
            toastId : "sg-success-demo"
          })
        }
      >
        Show Success Toast
      </Button>
      <Button
        className="mx-2"
        variant="danger"
        onClick={() => 
          addToast({
            action: <a href="#">Acción</a>,
            message:\`Hola, \${name}\` , 
            persist: true,
            type: "error",
            toastId : "sg-error-demo"
  
          })
        }
      >
        Show Error Toast
      </Button>
      <Button
        className="mx-2"
        variant="info"
        onClick={() => 
          addToast({ 
            action: <a href="#">Azione</a>,
            message: \`Ciao, \${name}\`, 
            persist: true,
            type: "info",
            toastId : "sg-info-demo" 
          })
        }
      >
        Show Info Toast
      </Button>
      <Button
        className="mx-2"
        variant="warning"
        onClick={() => 
          addToast({
            action: <a href="#">Hana</a>,
            message: \`Aloha, \${name}\`, 
            persist: true,
            type: "warning",
            toastId : "sg-warning-demo" 
          })
        }
      >
        Show Warning Toast
      </Button>
    </div>
    );
  };
  export default SomeComponent;
  
          `}
        />
      </StyleGuideSection>
      <StyleGuideSection
        title="Toast Notifications - with toastId"
        description="React toastify allows us to apply a `toastId` parameter to limit notification duplication."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button
                className="mx-2"
                variant="info"
                onClick={() =>
                  addToast({
                    message: `I have toastID, so I will only appear on screen once at a time.`,
                    persist: false,
                    type: "info",
                    toastId: "sg-info-demo",
                  })
                }
              >
                Click me 3x!
              </Button>
              <Button
                className="mx-2"
                variant="warning"
                onClick={() =>
                  addToast({
                    message: `I DO NOT have toastID, so I may only appear on screen multiple times, 
                    only restrained by the limit applied to ToastContainer.`,
                    persist: false,
                    type: "warning",
                  })
                }
              >
                Click me 3x!
              </Button>
            </React.Fragment>
          }
          code={`import { addToast } from "src/utils/ToastUtil";
  
<Button
  className="mx-2"
  variant="info"
  onClick={() =>
    addToast({
      message: "I have toastID, so I will only appear on screen once at a time.",
      persist: false,
      type: "info",
      toastId: "sg-info-demo",
    })
  }
>
  Click me 3x!
</Button>
<Button
  className="mx-2"
  variant="warning"
  onClick={() =>
    addToast({
      message: "I DO NOT have toastID, so I may only appear on screen multiple times, only restrained by the limit applied to ToastContainer.",
      persist: false,
      type: "warning",
    })
  }
>
  Click me 3x!
</Button>
          `}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default AlertsStyleGuide;
