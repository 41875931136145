import {gql} from 'apollo-boost';

const CREATE_DIRECT_CONNECT_SETTINGS = gql`
  mutation createDirectConnectSetting(
      $companyId: ID!
      $workflowSystemId: ID
      $directConnectStatus: String
      $systemOfRecord: String
      $companyUserId: ID
    ) {
      createDirectConnectSetting(
        companyId: $companyId,
        workflowSystemId: $workflowSystemId
        directConnectStatus: $directConnectStatus
        systemOfRecord: $systemOfRecord
        companyUserId: $companyUserId
      ) {
        accountName
        connectionStatus
        createdAt
        dataProcessingEnabled
        lastRunDate
        useClosedInvoices
        workflowSystem
        downloadUrl
        linkEstablished
        connectionParams
        msg
        clientCredentials
      }
    }
  `;

const UPDATE_DIRECT_CONNECT_SETTINGS = gql`
  mutation updateDirectConnectSetting(
    $companyId: ID!
    $accessData: String
    $accessToken: String
    $accessSecret: String
    $connectionParams: JSON
    $dataProcessingEnabled: Boolean
    $useClosedInvoices: Boolean
    $linkEstablished: Boolean
		$redirectUri: String
		$tokenAcquiredAt: ISO8601DateTime
		$authenticationDate: ISO8601DateTime
    $code: String
		$workflowSystem: String
		$realmId: String
		$domain: String
		$callback: String
		$companyKey: String
		$oauthVerifier: String
		$oauthToken: String
		$secret: String
		$desktopSystem: Boolean
		$connectionStatus: String
  ) {
    updateDirectConnectSetting(
      companyId: $companyId
      accessData: $accessData
      accessToken: $accessToken
      accessSecret: $accessSecret
      connectionParams: $connectionParams
      dataProcessingEnabled :$dataProcessingEnabled
      useClosedInvoices: $useClosedInvoices
      linkEstablished: $linkEstablished
      redirectUri: $redirectUri
      code: $code
      workflowSystem: $workflowSystem
      realmId: $realmId
      domain: $domain
      callback: $callback
      companyKey: $companyKey
      tokenAcquiredAt: $tokenAcquiredAt
      authenticationDate: $authenticationDate
      oauthVerifier: $oauthVerifier
      oauthToken: $oauthToken
      secret: $secret
      desktopSystem: $desktopSystem
      connectionStatus: $connectionStatus
    ) {
      accountName
      connectionStatus
      createdAt
      dataProcessingEnabled
      lastRunDate
      useClosedInvoices
      workflowSystem
      downloadUrl
      linkEstablished
      connectionParams
      formattedDate
      lastRunDate
      quickbooksWebconnectorFile
    }
  }
`;

const DISCONNECT_DIRECT_CONNECT = gql`
  mutation disconnectDirectConnect($companyId: ID!) {
    disconnectDirectConnect(companyId: $companyId)
  }
`;

const CREATE_SUCCESSWARE21_ASP_CONNECTION = gql`
  mutation createSuccessware21AspConnection(
    $companyId: ID!
    $masterId: String!
    $swapiUrl: String!
    $agentPassword: String!
  ) {
    createSuccessware21AspConnection(
      companyId: $companyId
      masterId: $masterId
      swapiUrl: $swapiUrl
      agentPassword: $agentPassword
    ) {
      connectionParams
    }
  }
`

export {
  CREATE_DIRECT_CONNECT_SETTINGS,
  UPDATE_DIRECT_CONNECT_SETTINGS,
  DISCONNECT_DIRECT_CONNECT,
  CREATE_SUCCESSWARE21_ASP_CONNECTION,
}
