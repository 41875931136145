import React from "react";
import { cleanInitials } from "src/utils/company_users/dataCleaner";
import PropTypes from "prop-types";

const UserInitials = ({
  bgColor = "#8aa2c0",
  fgColor = "#ffffff",
  firstName,
  lastName,
  size = "1.8rem",
  fontSize = "0.85rem",
  className = "",
  style = {},
  initialsOverride = null,
}) => {
  // render
  return (
    <div
      style={{
        width: size,
        minWidth: size,
        height: size,
        minHeight: size,
        borderRadius: "50%",
        fontSize: fontSize,
        backgroundColor: bgColor,
        color: fgColor,
        ...style
      }}
      className={`name-circle d-inline-flex justify-content-center align-items-center mr-2 ${className}`}
    >
      {
        initialsOverride 
        ? initialsOverride.toUpperCase()
        : cleanInitials(firstName, lastName)
      }
    </div>
  );
};

UserInitials.propTypes = {
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  initialsOverride: PropTypes.string,
};

export default UserInitials;
