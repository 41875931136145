import React, { useEffect, useState } from "react";
import orange_unsubscribe from "src/img/orange_unsubscribe_email.png";
import orange_bounced from "src/img/bounced_orange.png";
import { SOURCES, CREDIT_TYPE } from "src/shared/Constants";
import CreditInfoCard from "./CreditInfoCard";
import { getMetadataHelper, getCreditsHelper } from "./query_actions";
import { metadataState } from "./metadataState";
import Select from "react-select";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import SidebarMenuComponent from "src/shared/sidebar-menu/SidebarMenuComponent";
import debounce from "lodash.debounce";
import "react-select/dist/react-select.css";
import "./TrackSidebarComponent.scss";

const HandwrittenKioskData = [
  { label: "Handwritten", value: "Handwritten" },
  { label: "Kiosk", value: "Kiosk" },
];

const TrackSidebarComponent = (props) => {
  // variables
  const { dateOptions } = props;
  const { metadataSelectors, setKioskHandwrittenSource, currMetadataSelector, source } = props.state;

  // state
  const [searchTerm, setSearchTerm] = React.useState("");
  const [metadataQueryRes, setMetadataQueryRes] = useState(metadataState);
  const [creditData, setCreditData] = useState({
    formattedGrantDate: "Jan 1, 2020",
    formattedDay: "1",
    formattedCount: "0",
    text: "Available Call Credits:",
  });

  // methods
  const handleData = async () => {
    const { dateRange } = props;
    const { companyId, authHeader, source } = props.state;
    if (source === SOURCES.CUSTOM_INVITE) {
      return props.setTotalCount(props.state.sourceData.customInvites);
    }
    const data = await getMetadataHelper({
      companyId,
      authHeader,
      dateFrom: dateRange ? dateRange.value : "",
      source,
      searchTerm: props.filters.searchTerm || "",
    });
    if (!data) return null;

    props.setTotalCount(
      data.getReviewsMetadata.total
    );
    props.setState({ ...props.state, currentPage: 0 });
    setMetadataQueryRes({ ...data.getReviewsMetadata });
  };

  const handleCreditData = async () => {
    let { companyId, authHeader, source } = props.state;
    if (source === SOURCES.CUSTOMER_CALLS) {
      let creditType = CREDIT_TYPE.CALL_CREDIT;
      let data = await getCreditsHelper({ companyId, authHeader, creditType });
      if (!data) return null;
      setCreditData(data.getCredits);
    } else if (
      source === SOURCES.HANDWRITTEN ||
      source === SOURCES.KIOSK ||
      source === SOURCES.HANDWRITTEN_KIOSK
    ) {
      let creditType = CREDIT_TYPE.HANDWRITTEN_CREDIT;
      let data = await getCreditsHelper({ companyId, authHeader, creditType });

      if (!data) return null;
      setCreditData(data.getCredits);
    }
  };

  const updateMetadata = (view) => {
    props.setTotalCount(metadataQueryRes[view.countHelper]);

    props.setState({
      ...props.state,
      currMetadataSelector: view.value,
      filter: view.filter,
      currentPage: 0,
      currentCountHelper: view.countHelper,
    });
  };

  const isThereData = (view) => {
    return updateMetadata(view);
  };

  const showHandwrittenKiosk = () => {
    let { source } = props.state;

    if (
      source !== SOURCES.HANDWRITTEN &&
      source !== SOURCES.HANDWRITTEN_KIOSK &&
      source !== SOURCES.KIOSK
    )
      return null;

    const handleStateChange = ({ value }) => {
      props.setState({
        ...props.state,
        source: value,
        filter: "all",
        currMetadataSelector: "All Review Invites",
      });

      props.setKioskHandwrittenSource(value)
    };

    return (
      <div className="mt-4">
        <div className="filter-title mb-2">Source</div>
        <Select
          value={props.state.source}
          name="source"
          isClearable={false}
          onChange={handleStateChange}
          options={HandwrittenKioskData}
          isOptionDisabled={(option) => option.disabled}
        />
      </div>
    );
  };

  const presentHelper = (img, view, index) => {
    return (
      <div key={index} className={`track-menu-style`}>
        <a
          className="track-menu-text"
          onClick={() => isThereData(view)}
          style={{ color: "#f47821" }}
        >
          <img
            src={img}
            className="track-menu-icon mx-xl-4 mx-md-2"
            alt="track-menu icon"
          />
          {view.label} ({metadataQueryRes[view.countHelper] || 0})
        </a>
      </div>
    );
  };

  const presenterHelperTwo = (view, index) => {
    return (
      <div
        key={index}
        className={`track-menu-style ${
          currMetadataSelector === view.value ? "track-active-menu" : ""
        }`}
      >
        <a className="track-menu-text" onClick={() => isThereData(view)}>
          <img
            src={view.icon}
            className="track-menu-icon mx-xl-4 mx-md-2"
            alt="track-menu icon"
          />
          {view.label} ({metadataQueryRes[view.countHelper] || 0})
        </a>
      </div>
    );
  };

  const presentViewBy = () => {
    if (source === SOURCES.CUSTOM_INVITE) return null;
    return metadataSelectors[source].map((view, index) => {
      if (
        SOURCES.EMAIL === source &&
        view.value === "Unsubscribed" &&
        currMetadataSelector === view.value
      ) {
        return presentHelper(orange_unsubscribe, view, index);
      } else if (
        SOURCES.REVIEW_ME === source &&
        view.value === "Unsubscribed" &&
        currMetadataSelector === view.value
      ) {
        return presentHelper(orange_unsubscribe, view, index);
      } else if (
        SOURCES.REVIEW_ME === source &&
        view.value === "Bounced" &&
        currMetadataSelector === view.value
      ) {
        return presentHelper(orange_bounced, view, index);
      } else if (
        SOURCES.HANDWRITTEN === source &&
        view.value === "Bounced" &&
        currMetadataSelector === view.value
      ) {
        return presentHelper(orange_bounced, view, index);
      } else {
        return presenterHelperTwo(view, index);
      }
    });
  };

  const showCreditInfoCard = () => {
    if (
      source === SOURCES.CUSTOMER_CALLS ||
      source === SOURCES.HANDWRITTEN ||
      source === SOURCES.KIOSK ||
      source === SOURCES.HANDWRITTEN_KIOSK
    ) {
      let availText =
        source === SOURCES.CUSTOMER_CALLS
          ? "Available Call Credits:"
          : "Available Handwritten Credits:";
      return (
        <CreditInfoCard
          companyId={props.state.companyId}
          source={source}
          availText={availText}
          grantDate={creditData.formattedGrantDate}
          credits={creditData.formattedCount}
          dateOfMonth={creditData.formattedDate}
        />
      );
    }
    return null;
  };

  const updateSearchTerm = debounce((term) => {
    props.setFilters({
      ...props.filters,
      searchTerm: term,
    });
  }, 300);

  const getSearchPlaceholder = (source) => {
    switch (source) {
      case "Email":
        return "Search by name or email";
      case "Reviewme":
        return "Search by name";
      case "CustomerCalls":
        return "Search by name or phone";
      case "CustomInvites":
        return "Search by name or email";
      case "Handwritten":
        return "Search by name, email or phone";
      default:
        return "";
    }
  };
  // effects
  useEffect(() => {
    handleCreditData();
    handleData();
  }, [
    props.updateComponent,
    props.dateRange,
    props.state.source,
    props.currMetadataSelector,
    props.filters.searchTerm,
  ]);

  useEffect(() => {
    if (props.reset) {
      setSearchTerm("");
      if (metadataSelectors[source] && metadataSelectors[source].length > 0) {
        isThereData(metadataSelectors[source][0]);
      }
      if (props.dateOptions && props.dateOptions.length > 1) {
        props.setDate(props.dateOptions[1]);
      }
    }
  }, [props.reset]);

  // render
  return (
    <div id="track-sidebar-component">
      {/* search */}
      <SidebarMenuComponent
        title="Search"
        menuItems={[]}
        menuItemsFilters={{}}
        clickHandler={() => {}}
        eventName="search"
      />

      <SearchInput
        value={searchTerm}
        maxLength={256}
        onKeyUp={(evt) => {
          if (evt.keyCode === 13) {
            updateSearchTerm(searchTerm);
          }
        }}
        onChange={(evt) => {
          const term = evt.target.value;
          setSearchTerm(term);
          if (term.length > 2 || term.length < searchTerm.length) {
            updateSearchTerm(term);
          }
        }}
        placeholder={getSearchPlaceholder(props.state.source)}
      />

      {/* view by */}
      {source !== SOURCES.CUSTOM_INVITE ? <div className="mt-4">
        <div className="filter-title">View By</div>
        <div className="mt-3">{presentViewBy()}</div>
      </div> : null}

      <div className="mt-4">
        <div className="filter-title mb-2">Date Range</div>
        <Select
          value={props.dateRange}
          name="date-range"
          isClearable={false}
          onChange={(date) => {
            props.setDate(date);
          }}
          options={dateOptions}
          isOptionDisabled={(option) => option.disabled}
        />
      </div>

      {showHandwrittenKiosk()}
      {showCreditInfoCard()}
    </div>
  );
};
export default TrackSidebarComponent;
