import React from "react";
import "./SidebarComponent.scss";
import information from "src/img/information.svg";
import Popup from "reactjs-popup";
import Select from "react-select";
import "react-select/dist/react-select.css";
import SidebarMenuComponent from "src/shared/sidebar-menu/SidebarMenuComponent";
import ReviewStatusCard from "./ReviewStatusCard";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import debounce from "lodash.debounce";

const ReviewsSidebarComponent = (props) => {
  const { viewBy, sortBy, filterBy, platform, dateRange } =
    props.sidebarData || {};

  // state
  const [searchTerm, setSearchTerm] = React.useState("");

  // methods
  const updateFilter = (filterKey, value) => {
    let filters = {
      ...props.filters,
    };
    filters[filterKey] = value;
    props.setFilters(filters);
    props.viewByUpdate(filters);
  };

  const updateSearchTerm = debounce((term) => {
    props.setFilters({
      ...props.filters,
      searchTerm: term,
    });
  }, 300);

  // effects
  React.useEffect(() => {
    if (props.reset) {
      setSearchTerm("");
      props.setFilters({
        ...props.filters,
        searchTerm: "",
      });
      const filters = {
        ...props.filters,
        ...props.defaultFilters,
      };
      props.setFilters(filters);
      props.viewByUpdate(filters);
    }
  }, [props.reset]);

  // render
  return (
    <div id="sidebar-component">
      {/* search */}
      <SidebarMenuComponent
        title="Search"
        menuItems={[]}
        menuItemsFilters={{}}
        clickHandler={() => {}}
        eventName="search"
      />

      <SearchInput
        value={searchTerm}
        maxLength={256}
        onKeyUp={(evt) => {
          if (evt.keyCode === 13) {
            updateSearchTerm(searchTerm);
          }
        }}
        onChange={(evt) => {
          const term = evt.target.value;
          setSearchTerm(term);
          if (term.length > 2 || term.length < searchTerm.length) {
            updateSearchTerm(term);
          }
        }}
        placeholder="Search by name, email or phone"
      />

      {viewBy ? (
        <SidebarMenuComponent
          title={"View By"}
          menuItems={viewBy}
          menuItemsFilters={props.filters.viewBy}
          clickHandler={updateFilter}
          eventName={"viewBy"}
        />
      ) : null}

      {sortBy ? (
        <div className="mt-4">
          <div className="filter-title mb-2">Sort By</div>
          <Select
            value={props.filters.sortBy.value}
            name="sort-by"
            isClearable={false}
            onChange={(value) => {
              updateFilter("sortBy", value);
            }}
            options={sortBy}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
        </div>
      ) : null}

      {filterBy && platform ? (
        <div className="mt-4">
          <div className="filter-title">Filter By</div>
          <div className="filter-sub-title mt-2">
            Source
            <Popup
              trigger={
                <img className="ml-1" alt="information" src={information} />
              }
              position="right center"
              on="hover"
            >
              <div className="p-2">Reviews sent via</div>
            </Popup>
            <br />
          </div>

          <Select
            value={props.filters.filterBy.value}
            name="source"
            isClearable={false}
            onChange={(value) => {
              updateFilter("filterBy", value);
            }}
            options={filterBy}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
          <div className="filter-sub-title mt-4">
            Platform
            <Popup
              trigger={
                <img className="ml-1" alt="information" src={information} />
              }
              position="right center"
              on="hover"
            >
              <div className="p-2">Review posted to</div>
            </Popup>
            <br />
          </div>

          <Select
            value={props.filters.platform.value}
            name="platform"
            isClearable={false}
            onChange={(value) => {
              updateFilter("platform", value);
            }}
            options={platform}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
        </div>
      ) : null}

      {dateRange ? (
        <div className="mt-4">
          <div className="filter-title mb-2">Date Range</div>
          <Select
            value={props.filters.dateRange.value}
            name="date-range"
            isClearable={false}
            onChange={(value) => {
              updateFilter("dateRange", value);
            }}
            options={dateRange}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
        </div>
      ) : null}
      {props.showReviewStatus ? <ReviewStatusCard /> : null}
    </div>
  );
};
export default ReviewsSidebarComponent;
