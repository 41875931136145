import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NumberUtil from "src/utils/NumberUtil";
import Chart from "src/shared/styleguide/charts/Chart";
import classnames from "classnames";
import BlockUi from "react-block-ui";

const MiniDonutChart = ({
  chartId,
  pieSize = 56,
  fontSize= 12,
  pieInnerSize = 0.75,
  chartTitle = "",
  chartData = [],
  labelFormatter,
  legendIsPaginated = true,
  tooltipFormatter = undefined
}) => {
  const leftPositionCorrection = ((pieSize -  70) / 3.3333) + 1;

  // state
  const [noData, setNoData] = useState(false);
  const [chartConfig, setChartConfig] = useState({
    chart: {
      width: pieSize * 3,
      height: pieSize,
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0, 0, 0, leftPositionCorrection]
    },
    xAxis: {
      categories: [],
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      title: {
        text: null
      }
    },
    tooltip: {
      outside: true,
      followPointer: true,
      formatter: tooltipFormatter
    },
    legend: {
      enabled: true,
      itemStyle: {
        "font-size": `${fontSize}px`,
      },
      navigation: {
        enabled: legendIsPaginated
      },
      align: "left",
      verticalAlign: "middle",
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false,
      itemMarginBottom: 4,
      x: pieSize * 1.2,
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      labelFormatter() {
        return labelFormatter ?
        labelFormatter(this):
        `${this.name}`
      }
    },
    plotOptions: {
      pie: {
        center: [pieSize * 0.2 + "px", "50%"],
        cursor: "pointer",
        size: pieSize * 0.95,
        borderColor: null,
        innerSize: pieSize * pieInnerSize,
        showInLegend: true,
        allowPointSelect: false,
        dataLabels: { enabled: false },
        states: {
          hover: {
            halo: null
          }
        },
        point: {
          events: {
            legendItemClick: () => false
          }
        }
      }
    },
    series: [
      {
        type: "pie",
        data: chartData
      }
    ]
  });

  // effects
  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    const total = chartData.reduce((acc, val) => acc + val.y, 0);
    if (total === 0) {
      setNoData(true);
      newChartConfig.series[0].data = chartData.map(
        (row) => (row.y = 100 / chartData.length)
      );
    } else {
      newChartConfig.series[0].data = chartData;
      setNoData(false);
    }
    setChartConfig(newChartConfig);
  }, [chartData]);

  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.tooltip.formatter = tooltipFormatter;
    setChartConfig(newChartConfig);
  }, [tooltipFormatter]);

  // render
  const chartTitleLength = chartTitle.toString().length;
  return (
    <React.Fragment>
      <span
        className={classnames("mini-donut-chart-title text-dark font-weight-bold my-0", {
          h2: chartTitleLength < 3,
          h4: chartTitleLength >= 3,
          h5: chartTitleLength >= 5
        })}
        style={{
          position: "absolute",
          left: `calc(${2}px + 1rem)`,
          height: pieSize,
          width: pieSize,
          display: "grid",
          placeItems: "center",
          zIndex: 0
        }}
      >
        {NumberUtil.toK(chartTitle)}
      </span>
      <div className="card-chart">
        <BlockUi loader={<React.Fragment />} blocking={noData}>
          <Chart chartId={chartId} chartConfig={chartConfig} />
        </BlockUi>
      </div>
    </React.Fragment>
  );
};
MiniDonutChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  chartId: PropTypes.string.isRequired,
  chartTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelFormatter: PropTypes.func,
  legendIsPaginated: PropTypes.bool,
  pieSize: PropTypes.number.isRequired,
  pieInnerSize: PropTypes.number.isRequired,
  tooltipFormatter: PropTypes.func
};

export default MiniDonutChart;
