import { gql } from "apollo-boost";

const QUERY_DIRECT_CONNECT = gql`
  query(
    $searchTerm: String
    $visible: Boolean
    $active: Boolean
    $companyId: ID!
  ) {
    workflowSystemsList(
      searchTerm: $searchTerm
      visible: $visible
      active: $active
    ) {
      id
      name
      displayName
      status
      visible
      accessType
    }

    directConnectSettings(companyId: $companyId) {
      useClosedInvoices
      dataProcessingEnabled
      connectionStatus
      lastRunDate
      workflowSystem
      createdAt
      downloadUrl
      connectionParams
      clientCredentials
      linkEstablished
      formattedDate
      ftpPassword
      accessToken
      lastRunDate
      quickbooksWebconnectorFile
    }

    company(companyId: $companyId) {
      systemOfRecord
      directConnectStatus
    }
  }
`;

const GET_EXTERNAL_ACCOUNTS = gql`
  query ($companyId: ID! $swapiUrl: String! $masterId: String! $agentPassword: String!) {
    retrieveSuccesswareExternalAccounts(
      companyId: $companyId
      swapiUrl: $swapiUrl
      agentPassword: $agentPassword
      masterId: $masterId
    ) {
      companyName
      companyId
    }
  }
`

const QUERY_DIRECT_CONNECT_SETTINGS = gql`
  query($companyId: ID!) {
    directConnectSettings(companyId: $companyId) {
      useClosedInvoices
      dataProcessingEnabled
      connectionStatus
      workflowSystem
      createdAt
      downloadUrl
      connectionParams
      linkEstablished
      formattedDate
      lastRunDate
      invoiceSystemConnected
      quickbooksWebconnectorFile
    }
  }
`;

const GET_AUTH_URL = gql`
  query getAuthUrl(
    $companyId: ID!
    $redirectUri: String!
    $workflowSystem: String!
    $domain: String
  ) {
    getAuthUrl(
      companyId: $companyId
      redirectUri: $redirectUri
      workflowSystem: $workflowSystem
      domain: $domain
    ) {
      authUrl
      token
      secret
    }
  }
`;

const QUERY_DIRECT_CONNECTION_STATUS = gql`
  query getDirectConnectSettings($id: ID!) {
    directConnectSettings(companyId: $id) {
      systemEnabled
      lastRunDate
    }
  }
`;

export {
  QUERY_DIRECT_CONNECT_SETTINGS,
  QUERY_DIRECT_CONNECT,
  GET_AUTH_URL,
  GET_EXTERNAL_ACCOUNTS,
  QUERY_DIRECT_CONNECTION_STATUS,
};
