import {showCompanyId, showAuthHeader, showUserId } from '../../../../../shared/SessionHelper';
import total from '../../../../../img/total.png'
import requested from '../../../../../img/requested.png'
import reviewed from '../../../../../img/reviewed.png'
import invalid_email from '../../../../../img/invalid_email.png'
import declined from '../../../../../img/declined.png'
import unsubscribed from '../../../../../img/unsubscribed.png'
import bounced from '../../../../../img/bounced_grey.png'
import in_progress from '../../../../../img/in_progress.png'
import wrong_number from '../../../../../img/new_wrong_number.png'
import new_unresponsive from '../../../../../img/new_unresponive.png'
import pending_clock from '../../../../../img/pending_clock.png'
import confirmed_tic from '../../../../../img/confirmed_tic.png'

export const originalState = {
  authHeader: showAuthHeader,
  companyId: showCompanyId,
  companyUserId: showUserId,
  source: "Email",
  currSortField: null,
  currSortDirection: 'ASC',
  dateRange: 30,
  direction: null,
  currentCountHelper: 'total',
  column: null,
  currentPage: 0,
  sourceData: {
    email: 0,
    reviewme: 0,
    customerCalls: 0,
    customInvites: 0,
    handwritten: 0
  },
  currMetadataSelector: 'All Review Invites',
  totalCount: 0,
  filter: 'all',
  headers: {
    Email: ['NAME', 'EMAIL ADDRESS', 'INVITE DATE', 'LAST SERVICE DATE', 'STATUS'],
    Reviewme: ['NAME', 'EMAIL/PHONE', 'INVITED VIA', 'INVITE DATE', 'LAST SERVICE DATE', 'STATUS'],
    CustomerCalls: ['NAME', 'PHONE NUMBER', 'CALL DATE', 'LAST SERVICE DATE', 'STATUS'],
    CustomInvites: ['NAME', 'EMAIL ADDRESS', 'INVITE DATE', 'LAST SERVICE DATE', 'STATUS'],
    Handwritten: ['NAME', 'CONFIRMATION VIA', 'DATE TRANSCRIBED', 'EMAIL/PHONE', 'STATUS'],
    Kiosk: ['NAME', 'CONFIRMATION VIA', 'DATE TRANSCRIBED', 'EMAIL/PHONE', 'STATUS'],
    HandwrittenKiosk: ['NAME', 'CONFIRMATION VIA', 'DATE TRANSCRIBED', 'EMAIL/PHONE', 'STATUS']
  },
  sortFields: {
    Email: ['displayName', 'emailAddress', 'inviteDate', 'lastServiceDate', 'status'],
    Reviewme: ['displayName', 'emailPhone', 'inviteType', 'inviteDate', 'lastServiceDate', 'status'],
    CustomerCalls: ['displayName', 'phoneNumber', 'callDate', 'lastServiceDate', 'status'],
    CustomInvites: ['displayName', 'emailAddress', 'inviteDate', 'lastServiceDate', 'status'],
    Handwritten: ['displayName', 'confirmationVia', 'dateTranscribed', 'emailPhone', 'status'],
    Kiosk: ['displayName', 'confirmationVia', 'dateTranscribed', 'emailPhone', 'status'],
    HandwrittenKiosk: ['displayName', 'confirmationVia', 'dateTranscribed', 'emailPhone', 'status']
  },
  rows: {
    Email: ['displayName', 'displayName', 'emailAddress', 'inviteDate', 'lastServiceDate', 'status'],
    Reviewme: ['displayName', 'emailPhone', 'medium', 'inviteDate', 'lastServiceDate', 'status'],
    CustomerCalls: ['displayName', 'displayName', 'phoneNumber', 'callDate', 'lastServiceDate', 'status'],
    CustomInvites: ['displayName', 'displayName', 'emailAddress', 'inviteDate', 'lastServiceDate', 'status'],
    Handwritten: ['displayName', 'displayName', 'confirmationVia', 'dateTranscribed', 'emailPhone', 'status'],
    Kiosk: ['displayName', 'displayName', 'confirmationVia', 'dateTranscribed', 'emailPhone', 'status'],
    HandwrittenKiosk: ['displayName', 'displayName', 'confirmationVia', 'dateTranscribed', 'emailPhone', 'status']
  },
  metadataSelectors: {
    Email: [
      {label: 'All', count: 0, value:'All Review Invites', countHelper: 'total', icon: total, filter: 'all'},
      {label: 'Requested', count: 0, value:'Requested', countHelper: 'requested', icon: requested, filter: "('sent', 'opened_smart_link', 'opened_link', 'unhappy', 'opened_email', 'one_time_send')"},
      {label: 'Reviewed',   count: 0, value:'Reviewed', countHelper: 'reviewed', icon: reviewed, filter: "('reviewed')"},
      {label: 'Invalid Email', count: 0, value:'Invalid Email', countHelper: 'invalidEmail', icon: invalid_email, filter: "('invalid_email', 'bounced')"},
      {label: 'Unsubscribed', count: 0, value:'Unsubscribed', countHelper: 'unsubscribed', icon: unsubscribed, filter: "('unsubscribed')"},
      {label: 'Declined', count: 0, value:'Declined', countHelper: 'declined', icon: declined, filter: "('declined')"}
    ],
    Reviewme: [
      {label: 'All', count: 0, value:'All Review Invites', countHelper: 'total', icon: total, filter: 'all'},
      {label: 'Requested', count: 0, value:'Requested', countHelper: 'requested', icon: requested, filter: "('sent', 'opened')"},
      {label: 'Reviewed', count: 0, value:'Reviewed', countHelper: 'reviewed', icon: reviewed, filter: "('reviewed')"},
      {label: 'Unsubscribed', count: 0, value:'Unsubscribed', countHelper: 'unsubscribed', icon: unsubscribed, filter: "('unsubscribed')"},
      {label: 'Bounced', count: 0, value:'Bounced', countHelper: 'bounced', icon: bounced, filter: "('bounced')"}
    ],
    CustomerCalls: [
      {label: 'All', count: 0, value:'All Review Invites', countHelper: 'total', icon: total, filter: 'all'},
      {label: 'Requested', count: 0, value:'Requested', countHelper: 'requested', icon: requested, filter: "('requested')"},
      {label: 'In Progress',   count: 0, value:'In Progress', countHelper: 'inProgress', icon: in_progress, filter: "('scheduled', 'ready', 'queued', 'attempted', 'pending_credit')"},
      {label: 'Reviewed', count: 0, value:'Reviewed', countHelper: 'reviewed', icon: reviewed, filter: "('completed')"},
      {label: 'Wrong Number', count: 0, value:'Wrong Number', countHelper: 'wrongNumber', icon: wrong_number, filter: "('wrong_number')"},
      {label: 'Unresponsive', count: 0, value:'Unresponsive', countHelper: 'unresponsive', icon: new_unresponsive, filter: "('unresponsive')"},
      {label: 'Declined', count: 0, value:'Declined', countHelper: 'declined', icon: declined, filter: "('declined', 'unsubscribed', 'converted_to_email')"}
    ],
    CustomInvites: [],
    Handwritten: [
      {label: 'All', count: 0, value:'All Review Invites', countHelper: 'total', icon: total, filter: 'all'},
      {label: 'Pending', count: 0, value:'Pending', countHelper: 'pending', icon: pending_clock, filter: "('sent')"},
      {label: 'Confirmed',   count: 0, value:'Confirmed', countHelper: 'confirmed', icon: confirmed_tic, filter: "('confirmed')"},
      {label: 'Declined', count: 0, value:'Declined', countHelper: 'declined', icon: declined, filter: "('declined')"},
      {label: 'Bounced', count: 0, value:'Bounced', countHelper: 'bounced', icon: bounced, filter: "('bounced')"}
    ],
    Kiosk: [
      {label: 'All', count: 0, value:'All Review Invites', countHelper: 'total', icon: total, filter: 'all'},
      {label: 'Pending', count: 0, value:'Pending', countHelper: 'pending', icon: pending_clock, filter: "('sent')"},
      {label: 'Confirmed',   count: 0, value:'Confirmed', countHelper: 'confirmed', icon: confirmed_tic, filter: "('confirmed')"},
      {label: 'Declined', count: 0, value:'Declined', countHelper: 'declined', icon: declined, filter: "('declined')"},
      {label: 'Bounced', count: 0, value:'Bounced', countHelper: 'bounced', icon: bounced, filter: "('bounced')"}
    ],
    HandwrittenKiosk: [
      {label: 'All', count: 0, value:'All Review Invites', countHelper: 'total', icon: total, filter: 'all'},
      {label: 'Pending', count: 0, value:'Pending', countHelper: 'pending', icon: pending_clock, filter: "('sent')"},
      {label: 'Confirmed',   count: 0, value:'Confirmed', countHelper: 'confirmed', icon: confirmed_tic, filter: "('confirmed')"},
      {label: 'Declined', count: 0, value:'Declined', countHelper: 'declined', icon: declined, filter: "('declined')"},
      {label: 'Bounced', count: 0, value:'Bounced', countHelper: 'bounced', icon: bounced, filter: "('bounced')"}
    ]
  }
}
