import React, {Fragment} from "react";

const PublishInstructionsModal = (props) => {

    return (
     <Fragment>
       <p className="text-uppercase">
         Reviews that includes any of the following will not be published.
       </p>

       <p>
         Obscenities, discriminatory language, or other inappropriate comments (including any comments written in a language other than English) not suitable for this forum.
       </p>

       <p>
         Advertisements, ‘spam’ content, or references to other companies, reviewers, services, products, offers, or websites.
       </p>

       <p>
         Email addresses, URL’s, phone numbers, physical addresses, or other forms of contact information.
       </p>

       <p>
         Reviews that are not written by a paying customers (including pre-paid customers) of the company being reviewed are not written about an experience a customer had within the last 12 months, have been withdrawn by the reviewer, or that Customer Lobby believes to be untrue.
       </p>
     </Fragment>
    );
}

export default PublishInstructionsModal;