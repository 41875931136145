import React, { Fragment, useEffect } from "react";
import Header from "src/components/layout/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { constants } from "src/shared/Constants";
import { toggleTheme } from "src/redux/themeSlice";
import { Container as RBContainer } from "react-bootstrap";
import ContainerRoutes from "src/routes/ContainerRoutes";
import { SettingsProvider } from "src/shared/context/SettingsContext";
import "./Container.scss";

const Container = ({ client }) => {
  // variables
  const dispatch = useDispatch();
  const location = useLocation();
  const { darkMode } = useSelector((state) => state.theme);

  useEffect(() => {
    if (location?.pathname.includes("prospect") && !location?.pathname.includes("dashboard/prospectmarketing")) {
      dispatch(toggleTheme(true));
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.body.className = "dark-mode";
    }
  }, [darkMode]);

  return (
    <RBContainer fluid className={`px-0 mx-0 main-container`}>
      {/* Setting context provider  */}
      <SettingsProvider client={client}>
        <Fragment>
          <Header client={client}></Header>
          <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
          <ContainerRoutes client={client} />
        </Fragment>
      </SettingsProvider>
    </RBContainer>
  );
};

export default Container;
