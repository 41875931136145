import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./MultiItemTextArea.scss";

const MultiItemTextArea = ({
  array,
  setArray,
  arrayItemValidator,
  keyStrokeValidator,
  maxArraySize = 5,
}) => {
  const inputRef = React.useRef({ current: <div></div> });
  const [pendingItem, setPendingItem] = React.useState("");
  const [invalidItem, setInvalidItem] = React.useState(false);

  const inputFocus = () => {
    inputRef.current && inputRef.current.focus();
  };

  const changeHandler = (e) => {
    // Prevents adding more items to array than maxArraySize value
    if (array.length === maxArraySize) {
      return;
    }

    if (!e.target.value.length || keyStrokeValidator(e.target.value)) {
      setPendingItem(e.target.value);
    }

    if (invalidItem) {
      setInvalidItem(false);
    }
  };

  const submit = (e) => {
    // user presses Enter/Tab to submit valid number
    if (
      e.target.value.length &&
      arrayItemValidator(e.target.value) &&
      (e.key === "Enter" || e.key === "Tab")
    ) {
      setArray([...array, pendingItem]);
      setPendingItem("");

      setTimeout(() => {
        inputFocus();
      }, 50);
      // user presses Enter/Tab to attempt to submit invalid number
    } else if (
      !arrayItemValidator(e.target.value) &&
      (e.key === "Enter" || e.key === "Tab")
    ) {
      setInvalidItem(true);
      // user presses delete or backspace to remove last item in array
    } else if (
      !e.target.value.length &&
      (e.key === "Backspace" || e.key === "Delete")
    ) {
      setArray([...array.slice(0, -1)]);
    }
  };

  const removeItem = (index) => {
    const temp = [...array];
    temp.splice(index, 1);
    setArray(temp);
  };

  return (
    <div className="pill-box" onClick={() => inputFocus()}>
      {array.map((item, index) => {
        return (
          <div className="preview-item-pill" key={`preview-item-${index}-key`}>
            {item}
            <span
              onClick={() => {
                removeItem(index);
              }}
            >
              x
            </span>
          </div>
        );
      })}
      <input
        ref={inputRef}
        className={classnames({
          "multi-item-text-area-input": true,
          "multi-item-text-area-error": invalidItem,
        })}
        value={pendingItem}
        onChange={(e) => {
          changeHandler(e);
        }}
        onKeyDown={(e) => {
          // submit on key event to detect Enter or Tab press
          submit(e);
        }}
      />
    </div>
  );
};

MultiItemTextArea.propTypes = {
  array: PropTypes.array.isRequired,
  setArray: PropTypes.func.isRequired,
  arrayItemValidator: PropTypes.func.isRequired,
  keyStrokeValidator: PropTypes.func.isRequired,
  maxArraySize: PropTypes.number,
};

export default MultiItemTextArea;
