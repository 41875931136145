export default class WindowUtil {
  /**
   * Opens a window
   * @param {string} url url to be opened
   * @param {string} name name of the new window
   */
  static openWindow = ({ url, name, width = 400, height = 500 }) => {
    const top = (window.innerHeight - height) / 2 + window.screenY;
    const left = (window.innerWidth - width) / 2 + window.screenX;
    return window.open(
      url,
      name,
      `dialog=yes,top=${top}px,left=${left},width=${width}px,height=${height}px`,
    );
  };

  /**
   * Opens a new tab
   * @param {string} url url to be opened
   */
  static openTab(url) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  }
}
