import React from "react";
import PublishingSettings from "./PublishingSettings";
import PostingFrequencySettings from "./PostingFrequencySettings";
import ReminderNotificationSettings from "./ReminderNotificationSettings";

const PublishingNotification = () => {
  return (
    <React.Fragment>
      <div className="mb-4">
        <PublishingSettings />
      </div>
      <div className="mb-3">
        <PostingFrequencySettings />
      </div>
      <div className="mb-3">
        <ReminderNotificationSettings />
      </div>
    </React.Fragment>
  );
};

export default PublishingNotification;
