import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ButtonStyleGuide from "src/components/styleguide/buttons/ButtonStyleGuide";
import CardStyleGuide from "src/components/styleguide/cards/CardStyleGuide";
import ChartStyleGuide from "src/components/styleguide/charts/ChartStyleGuide";
import DropdownStyleGuide from "src/components/styleguide/dropdown/DropdownStyleGuide";
import FormStyleGuide from "src/components/styleguide/forms/FormStyleGuide";
import MapStyleGuide from "src/components/styleguide/maps/MapStyleGuide";
import ModalStyleGuide from "src/components/styleguide/modal/ModalStyleGuide";
import PopoverStyleGuide from "src/components/styleguide/popover/PopoverStyleguide";
import TableStyleGuide from "src/components/styleguide/table/TableStyleGuide";
import TypographyStyleGuide from "src/components/styleguide/typography/TypographyStyleGuide";
import IconStyleGuide from "src/components/styleguide/icons/IconStyleGuide";
import AlertsStyleGuide from "src/components/styleguide/alerts/AlertsStyleGuide";
export const StyleGuideComponents = [
  {
    id: 0,
    label: "Foundation",
    links: ["Colors", "Grid", "Typography", "Icons", "Downloads"].map(
      (component, id) => ({
        id,
        label: component,
        to: `/styleguide/${component.toLowerCase()}`,
      }),
    ),
    expanded: true,
  },
  {
    id: 1,
    label: "Components",
    links: [
      "Accordion",
      "Alerts",
      "Badges",
      "Button",
      "Carousel",
      "Dropdown",
      "Form",
      "Map",
      "Modal",
      "Navbar",
      "Navs",
      "Card",
      "Chart",
      "Pagination",
      "Popover",
      "Progress",
      "Tab",
      "Table",
      "Tooltip",
    ]
      .sort()
      .map((component, id) => ({
        id,
        label: component,
        to: `/styleguide/${component.toLowerCase()}`,
      })),
    expanded: true,
  },
  {
    id: 2,
    label: "Templates",
    links: [],
    expanded: false,
  },
];

export const StyleGuideRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/styleguide"
        render={() => <Redirect to="/styleguide/typography" />}
      />{" "}
      <Route
        exact
        path="/styleguide/button"
        render={() => <ButtonStyleGuide />}
      />{" "}
      <Route
        exact
        path="/styleguide/typography"
        render={() => <TypographyStyleGuide />}
      />{" "}
      <Route exact path="/styleguide/icons" render={() => <IconStyleGuide />} />{" "}
      <Route
        exact
        path="/styleguide/dropdown"
        render={() => <DropdownStyleGuide />}
      />{" "}
      <Route
        exact
        path="/styleguide/alerts"
        render={() => <AlertsStyleGuide />}
      />{" "}
      <Route exact path="/styleguide/card" render={() => <CardStyleGuide />} />{" "}
      <Route
        exact
        path="/styleguide/chart"
        render={() => <ChartStyleGuide />}
      />{" "}
      <Route exact path="/styleguide/map" render={() => <MapStyleGuide />} />{" "}
      <Route
        exact
        path="/styleguide/modal"
        render={() => <ModalStyleGuide />}
      />{" "}
      <Route
        exact
        path="/styleguide/table"
        render={() => <TableStyleGuide />}
      />
      <Route exact path="/styleguide/form" render={() => <FormStyleGuide />} />{" "}
      <Route
        exact
        path="/styleguide/popover"
        render={() => <PopoverStyleGuide />}
      />{" "}
      <Route>
        <div className="py-4">
          Style Guide for this component is not available yet.{" "}
        </div>{" "}
      </Route>{" "}
    </Switch>
  );
};

export default StyleGuideRoutes;
