export const GET_REVIEWS_BY_SOURCE = `
  query($companyId: ID!, $dateFrom: String!) {
    getReviewsBySource(companyId: $companyId, dateFrom: $dateFrom) {
      email
      reviewme
      customerCalls
      customInvites
      handwritten
    }
  }
`;
