import React, { useState } from "react";
import { Button } from "react-bootstrap";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import Modal from "src/shared/styleguide/modal/Modal.jsx";

export const ModalStyleGuide = () => {
  useDocumentTitle("Style Guide - Modal");
  const [showBasicModal, setShowBasicModal] = useState(false);

  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Modal</h3>
      <div>
        A modal is a dialog box or popup window that is displayed on top of the
        current page.
      </div>

      {/* Modal */}
      <StyleGuideSection
        title="Basic Example"
        description={
          <div>
            Our style guide modal is build on top of React Bootstrap's modal. It
            provides a small selection of classes to make it our own.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button variant="primary" onClick={() => setShowBasicModal(true)}>
                Unleash The Modal!
              </Button>
              <Modal
                modalSize="sm"
                className="best-practices-modal"
                onHide={() => setShowBasicModal(false)}
                header={
                  // Positioning and display classes are provided in component.
                  <div>Preview</div>
                }
                body={
                  <div>You can implement any simple or complex node here.</div>
                }
                show={showBasicModal}
              />
            </React.Fragment>
          }
          code={`
import Modal from "src/shared/styleguide/modal/Modal.jsx";

const [showBasicModal, setShowBasicModal] = useState(false);

<React.Fragment>
  <Button variant="primary" onClick={() => setShowBasicModal(true)}>
    Unleash The Modal!
  </Button>
  <Modal
    modalSize="sm"
    className="best-practices-modal"
    onHide={() => setShowBasicModal(false)}
    header={
      <div>Preview</div>
    }
    body={
      <div>You can implement any simple or complex node here.</div>
    }
    show={showBasicModal}
  />
</React.Fragment>          
          `}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default ModalStyleGuide;
