import React, { useState, useEffect } from "react";
import "./ReviewDetails.scss";
import information from "../../../../../../img/information.svg";
import { reviewWithdrawalRequestHelper } from './review_details_query_actions'
import { createReviewWithdrawalRequestHelper } from './review_details_mutation_actions'
/**
 * Reviews Details Display Withdrawal Request component
 */
const ReviewWithdrawalRequest = ({ reviewId, authHeader, handleSuccess }) => {
  let [ request, setRequest ] = useState(null)
  let [ note, setNote ] = useState("")
  let [ loading, setLoading ] = useState(true)
  const getRequest = async () => {
    let data = await reviewWithdrawalRequestHelper({ reviewId, authHeader })
    setRequest(data.data.data.reviewWithdrawlRequest)
    setLoading(false)
  }

  useEffect(() => {
    getRequest()
  }, [loading])

  if(loading) return null

  const handleSubmit = async () => {
    let data = await createReviewWithdrawalRequestHelper({ reviewId, note, authHeader })

    setRequest(data.data.data.createReviewWithdrawalRequest)
    handleSuccess('The review withdrawal request is created')
  }

  const handleBtnState = () => {
    if(note.length > 0) return (
      <button onClick={handleSubmit} type="submit" className="btn btn-primary">
        Send Request
      </button>
    )

    return (
      <button type="submit" className="btn btn-primary disabled-btn">
        Send Request
      </button>
    )
  }

  return (
    <div id="review-details-withdraw-request">
        {!request ?
      <div className="row">
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 mt-4">
              <label className="required-field label-style">Reason</label>

              <textarea
                rows="3"
                className="form-control mt-0"
                name="withdrawalRequest"
                value={note}
                onChange={e => setNote(e.target.value)}
                placeholder="Your reason"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-inline-flex align-items-start">
              <img
                className="mr-1"
                alt="information"
                src={information}
              />
              <div className="withdrawal-request-information">
                Enter your message and Customer Lobby will send it directly to the reviewer. For a review to be withdrawn, the author must agree to the removal of the review.The review author has the option to either accept or decline the withdrawal request. If the review author does not respond, the review will be automatically withdrawn and will no longer be published.
              </div>
            </div>
          </div>
          <div className="row form-group mt-4 mb-3">
            <div className="col-12">
              {handleBtnState()}
            </div>
          </div>
        </div>
      </div> :
      <div className="row">
        <div className="col-12 ml-2 p-4">
          <div className="review-details-withdraw-request-warning">
            A withdrawal request was made for this review on {request.formattedRequestDate}. The
            review author has seven (7) days to either accept or decline the
            withdrawal request. If the review author does not respond, the
            review will be automatically withdrawn and will no longer be
            published
          </div>
          <div className="review-details-withdraw-request-warning">
            This review will be withdrawn automatically on {request.formattedWithdrawDate} if the
            review author does not take action.
          </div>
        </div>
        </div> }
    </div>
  );
};

export default ReviewWithdrawalRequest;
