import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Card from "src/shared/styleguide/card/Card";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import UserInitials from "src/shared/styleguide/common/UserInitials";
import { formatDate } from "src/shared/Utils.js";
import ResidentialImage from "src/assets/images/customer/house.svg";
import CommercialImage from "src/assets/images/customer/building.svg";
import LockIcon from "src/assets/images/customer/lock-icon.svg";
import SourceImage from "src/assets/images/customer/radar-light.svg";
import DateImage from "src/assets/images/customer/calendar.svg";
import ContractImage from "src/assets/images/customer/file-contract-light.svg";
import "./CustomerContent.scss";
import { CONTACT_SOURCE_DISPLAY } from "src/shared/Constants"

const AboutCard = ({ customerData }) => {
  // variables
  const initialsVars = {
    firstName: customerData.firstName,
    lastName: customerData.lastName,
    size: "3.4667rem",
    fontSize: "1.3333rem",
    bgColor: "#37485b",
  };

  // For commercial customers, custom initials are produced as first 3 letters of company name
  const customInitials = customerData.commercial
    ? (customerData.companyName || "").replace(/\s/g, "").substring(0, 3)
    : null;

  // render
  return (
    <React.Fragment>
      <Card>
        <div className="customer-card-icon-container">
          {!customerData.commercial ? (
            <UserInitials {...initialsVars} bgColor="#37485b" />
          ) : (
            <UserInitials {...initialsVars} initialsOverride={customInitials} />
          )}
        </div>
        <div className="customer-card-content-container">
          <Row className="customer-card-title">
            <Col sm={12}>
              {!customerData.commercial ? (
                <h2 className="pb-2">{`${customerData.firstName ||
                  ""} ${customerData.lastName || ""}`}</h2>
              ) : (
                <h2 className="pb-2">{`${customerData.companyName ||
                  customerData.contactDisplayName ||
                  ""}`}</h2>
              )}
              <img
                className="customer-lock-icon"
                src={LockIcon}
                alt="Items are not editable"
              />
              <PopupInfo
                message="Update information in your invoicing system for any changes required."
                iconClassName="customer-info-icon"
              />
            </Col>
          </Row>
          <Row className="customer-card-body">
            <Col md={12} lg={10} xl={8}>
              <Row>
                <Col md={4} sm={6}>
                  <div className="customer-about-card-item">
                    {!customerData.commercial ? (
                      <img src={ResidentialImage} alt="Residential" />
                    ) : (
                      <img src={CommercialImage} alt="Commercial" />
                    )}
                    {customerData.commercial ? "Commercial" : "Residential"}
                  </div>
                  {customerData.inContract && (
                    <div className="customer-about-card-item">
                      <img src={ContractImage} alt="Source" />
                      Service Contract :{" "}
                      {customerData.inContract ? "Yes" : "No"}
                    </div>
                  )}
                </Col>
                <Col md={4} sm={6}>
                  <div className="customer-about-card-item">
                    <img src={SourceImage} alt="Service Contract" />
                    Source : {CONTACT_SOURCE_DISPLAY[`${customerData.source}`]}
                  </div>
                  {customerData.inContract && (
                    <div className="customer-about-card-item">
                      <img src={DateImage} alt="Start Date" />
                      Start date :{" "}
                      {formatDate(customerData.contractStartDate, "MM/DD/YYYY")}
                    </div>
                  )}
                </Col>
                {customerData.inContract && (
                  <Col md={4} sm={6}>
                    <div className="customer-about-card-item d-none d-md-block"></div>
                    <div className="customer-about-card-item">
                      <img src={DateImage} alt="End Date" />
                      End date :{" "}
                      {formatDate(customerData.contractEndDate, "MM/DD/YYYY")}
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};

AboutCard.propTypes = {
  customerData: PropTypes.object.isRequired,
};

export default AboutCard;
