import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";

export default class ChartUtil {
  /**
   * initialize Highcharts
   */
  static init() {
    // hoist highchart
    window.Highcharts = Highcharts;
    drilldown(Highcharts);
    // init options
    Highcharts.setOptions({
      title: null,
      animation: {
        enabled: true,
        duration: 300,
        easing: "linear",
      },
      tooltip: {
        backgroundColor: "#1a1a1a",
        borderWidth: 0,
        style: {
          color: "#ffffff",
        },
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend: {
        backgroundColor: "transparent",
        itemStyle: {
          color: "#667587",
          fontSize: "12px",
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: "#667587",
          fontSize: "12px",
          fontWeight: 700,
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick() {
              return ChartUtil.shouldDisableSeries(this.chart, this.index);
            },
          },
        },
      },
    });
  }

  /**
   * @param {Object} chart - Chart for which series is being disabled
   * @param {number} seriesIndex - index of data series being disabled
   * @param {number} yPosition - yPosition of error message
   * @param {string} type - type of chart
   * @param {string} errMsgStr - error message
   * @param {string} errColor - error color
   * @returns {boolean} - returns true if series should be disabled
   */
  static shouldDisableSeries(
    chart,
    seriesIndex,
    yPosition = -40,
    type = "series",
    errMsgStr = "Sorry, you can’t disable all the legends.",
    errColor = "#dc5700",
  ) {
    let chartSeries;
    let visibleSeries;
    switch (type) {
      case "pie":
        chartSeries =
          chart.series && chart.series.length > 0 ? chart.series[0].data : [];
        visibleSeries = chartSeries
          .filter((s) => s.visible)
          .map((s) => s.index);
        break;
      case "series":
      default:
        chartSeries = chart.series;
        visibleSeries = chartSeries
          .filter((s) => s.visible)
          .map((s) => s.index);
        break;
    }
    const allow =
      !visibleSeries.includes(seriesIndex) || visibleSeries.length > 1;
    if (!allow && !chart.hasErrorMessage) {
      const errMsg = chart.renderer.text(errMsgStr);
      chart.hasErrorMessage = true;
      errMsg
        .attr({
          fill: errColor,
          zIndex: 20,
        })
        .add()
        .align({
          verticalAlign: "bottom",
          align: "center",
          x: -errMsg.getBBox().width / 2,
          y: yPosition,
        });
      setTimeout(() => {
        errMsg.destroy();
        chart.hasErrorMessage = false;
      }, 2500);
    }
    return allow;
  }
}
