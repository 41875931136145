import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import Card from "src/shared/styleguide/card/Card";
import { formatTitle } from "src/shared/styleguide/charts/ChartUtils.js";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import "./Chart.scss";

export const HorizontalBarChart = ({
  popupInfoMsg,
  title,
  subTitle,
  noData = false,
  chartData = [],
  loading = false,
  ...props
}) => {
  const [displayData, setDisplayData] = useState(chartData);

  // hooks
  useEffect(() => {
    setDisplayData(chartData);
  }, [chartData]);

  // render
  return (
    <BlockUi
      loader={noData ? <React.Fragment /> : undefined}
      className="card-chart"
      blocking={noData || loading}
    >
      <Card
        padding={0}
        popupInfoMsg={popupInfoMsg}
        title={<div className="pl-4 pt-4 d-inline-block">{title}</div>}
        subTitle={
          <div className="pl-4 pt-1 pb-2 sg-card-subtitle d-inline-block">
            {subTitle}
          </div>
        }
        {...props}
      >
        {displayData.map(
          (
            { labelLeft, labelRight, labelTop, barValue, variant, infoMessage },
            index,
          ) => (
            <React.Fragment key={index}>
              {labelTop && infoMessage ? (
                <PopupInfo
                  placement="top"
                  message={
                    <div className="horizontal-bar-chart-popup-label">
                      {labelTop}{" "}
                      <span className="horizontal-bar-chart-popup-value">
                        {labelRight} ({formatTitle(barValue, 2)})
                      </span>{" "}
                      <br />
                      <div className="horizontal-bar-chart-popup-message">
                        {infoMessage}
                      </div>
                    </div>
                  }
                  iconClassName="mb-1"
                  icon={
                    <div className="horizontal-bar-label-top text-dark px-4">
                      {labelTop}
                    </div>
                  }
                  tooltipClassName="horizontal-bar-chart-popup"
                />
              ) : labelTop ? (
                <div className="horizontal-bar-label-top text-dark px-4">
                  {labelTop}
                </div>
              ) : null}
              <div className="horizontal-bar-chart text-dark d-flex align-items-center px-4">
                {labelLeft ? (
                  <span className="label-left">{labelLeft}</span>
                ) : null}
                <ProgressBar
                  title={barValue ? `${formatTitle(barValue, 0)}` : "No data"}
                  className="flex-grow"
                  now={barValue ? barValue : 0}
                  variant={variant}
                />
                {labelRight || labelRight === 0 ? (
                  <span className="label-right">{labelRight}</span>
                ) : null}
              </div>
            </React.Fragment>
          ),
        )}
      </Card>
    </BlockUi>
  );
};
HorizontalBarChart.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      labelTop: PropTypes.node,
      labelLeft: PropTypes.node,
      barValue: PropTypes.number.isRequired,
      labelRight: PropTypes.node,
    }),
  ).isRequired,
  popupInfoMsg: PropTypes.string,
  noData: PropTypes.bool,
  loading: PropTypes.bool,
};

export default HorizontalBarChart;
