import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  COMPANY_SETTINGS_SIDEBAR,
  PROFILE_SETTINGS_TAB,
  COMPANY_SETTINGS_ROUTES,
} from "src/shared/Constants";
import profileSettingImg from "src/img/hotel-with-three-floors.svg";
import userImg from "src/img/group (2).svg";
import billingImg from "src/img/credit-card (2).svg";
import directConnectImg from "src/img/link.svg";
import reviewImg from "src/img/star (1).svg";
import textingImg from "src/img/black-bubble-speech.svg";
import campaignImg from "src/img/campaign.svg";
import appointmentsImg from "src/img/time.svg";
import syndicationImg from "src/img/transfer.svg";
import widgetsImg from "src/img/surface1.svg";
import GrayAppImg from "src/img/gray_app.png";
import OrangeAppImg from "src/img/orange_app.png";
import ProfileSettings from "./ProfileSettings";
import CampaignsSettings from "./CampaignsSettings";
import CompanyUsersScreen from "src/containers/CompanyUsersScreen";
import * as config from "src/shared/Config";
import {
  showCompanyId as companyId,
  showIsManager,
  featureFlags,
  pulsem,
} from "src/shared/SessionHelper";
import classnames from "classnames";
import SyndicationSettings from "src/components/settings/syndication/SyndicationSettings";
import ReviewSettings from "src/components/settings/reviews/ReviewSettings";
import Widgets from "src/components/settings/widgets/Widgets";
import { ManagerAuthBlock } from "src/shared/auth/AuthBlock";
import "./CompanySettings.scss";
import AppsAndIntegrationSettings from "src/components/company/settings/apps/AppsAndIntegrationSettings";

// lazy load
const Billing = React.lazy(() =>
  import("src/components/settings/billing/Billing"),
);
const AppointmentsSettings = React.lazy(() =>
  import("src/components/settings/appointments/AppointmentsSettings"),
);
const DirectConnectSettings = React.lazy(() =>
  import("src/components/settings/direct_connect/DirectConnectSettings"),
);
const TextingSettings = React.lazy(() =>
  import("src/components/messaging/MessagesSettings"),
);

class CompanySettings extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedMenu: props.tab,
      selectedTab: PROFILE_SETTINGS_TAB.companyInformation,
      showOrange: false,
      tabNavigator: props.tabNavigator,
      reload: false,
    };
  }

  UNSAFE_componentWillMount() {
    document.title = "Settings | Customer Lobby";
  }
  /**
   *
   * @returns {string}
   */
  setDefaultSelectedMenu() {
    const { location } = this.props;
    return location &&
      location.pathname &&
      location.pathname.includes("/message-settings")
      ? COMPANY_SETTINGS_SIDEBAR.texting
      : COMPANY_SETTINGS_SIDEBAR.profileSetting;
  }

  /**
   * updates the state of the sidebar.
   * @param value
   */

  updateState(value) {
    this.setState({
      selectedMenu: value,
      selectedTab: PROFILE_SETTINGS_TAB.companyInformation,
      tabNavigator: 0,
      reload: !this.state.reload,
    });
  }

  /**
   * Set selected left sidebar and selected tab.
   */
  setSelectedTabs = (value, tab) => {
    this.setState({
      selectedMenu: value,
      selectedTab: tab,
    });
  };

  /**
   * Renders right side settings component.
   */
  renderRightComponent() {
    switch (this.state.selectedMenu) {
      case COMPANY_SETTINGS_SIDEBAR.profileSetting:
        return (
          <ProfileSettings
            reload={this.state.reload}
            client={this.props.client}
            selectedTab={this.state.tabNavigator}
          />
        );

      case COMPANY_SETTINGS_SIDEBAR.users:
        return <CompanyUsersScreen />;
      case COMPANY_SETTINGS_SIDEBAR.billing:
        return (
          <React.Suspense fallback={<React.Fragment />}>
            <ManagerAuthBlock
              manager={showIsManager}
              redirectTo={`/companies/${companyId}/settings`}
            >
              <Billing />
            </ManagerAuthBlock>
          </React.Suspense>
        );
      case COMPANY_SETTINGS_SIDEBAR.directConnect:
        return (
          <React.Suspense fallback={<React.Fragment />}>
            <DirectConnectSettings />
          </React.Suspense>
        );
      case COMPANY_SETTINGS_SIDEBAR.appointments:
        return (
          <React.Suspense fallback={<React.Fragment />}>
            <AppointmentsSettings />
          </React.Suspense>
        );
      case COMPANY_SETTINGS_SIDEBAR.review:
        return (
          <ReviewSettings />
        );
      case COMPANY_SETTINGS_SIDEBAR.texting:
        return (
          <React.Suspense fallback={<React.Fragment />}>
            <TextingSettings
              client={this.props.client}
              setSelectedTabs={this.setSelectedTabs}
            />
          </React.Suspense>
        );
      case COMPANY_SETTINGS_SIDEBAR.campaign:
        return <CampaignsSettings client={this.props.client} />;
      case COMPANY_SETTINGS_SIDEBAR.appointment:
        return null;
      case COMPANY_SETTINGS_SIDEBAR.syndication:
        return (
          <SyndicationSettings />
        );
      case COMPANY_SETTINGS_SIDEBAR.widgets:
        return <Widgets />;
      case COMPANY_SETTINGS_SIDEBAR.app:
        return (
          <AppsAndIntegrationSettings
            reload={this.state.reload}
            client={this.props.client}
            selectedTab={this.state.tabNavigator}
          />
        );
      default:
        return (
          <ProfileSettings
            selectedTab={PROFILE_SETTINGS_TAB.companyInformation}
          />
        );
    }
  }

  classnamesHelper = (sidebarOption) => {
    return this.state.selectedMenu === sidebarOption;
  };

  render() {
    return (
      <div id="company-settings">
        <div className="container-fluid settings-header">
          <div className="row">
            <div className="col-11 offset-xl-1 pl-0">
              <div className="title">Manage your account</div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{
            backgroundColor: "#fbfdfa",
          }}
        >
          <div className="row">
            <div className="col-12 col-md-2 offset-xl-1 sidebar">
              <div className="row sidebar-title">
                <h3 className="menu-header"> Company Settings</h3>

                <div className="col-12 p-0">
                  <Link to={`/companies/${companyId}/settings`}>
                    <div
                      onClick={this.updateState.bind(
                        this,
                        COMPANY_SETTINGS_SIDEBAR.profileSetting,
                      )}
                      className={classnames("menu-style", {
                        active: this.classnamesHelper(
                          COMPANY_SETTINGS_SIDEBAR.profileSetting,
                        ),
                      })}
                    >
                      <div className="menu-text">
                        <img
                          src={profileSettingImg}
                          alt="Profile Setting"
                          className="menu-icon mx-xl-4 mx-md-2"
                        ></img>
                        Profile Settings
                      </div>
                    </div>
                  </Link>
                  <Link
                    to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.users}`}
                  >
                    <div
                      onClick={this.updateState.bind(
                        this,
                        COMPANY_SETTINGS_SIDEBAR.users,
                      )}
                      className={classnames("menu-style", {
                        active: this.classnamesHelper(
                          COMPANY_SETTINGS_SIDEBAR.users,
                        ),
                      })}
                    >
                      <div className="menu-text">
                        <img
                          src={userImg}
                          alt="Users"
                          className="menu-icon mx-xl-4 mx-md-2"
                        ></img>
                        Users
                      </div>
                    </div>
                  </Link>
                  <ManagerAuthBlock manager={showIsManager}>
                    <div
                      onClick={this.updateState.bind(
                        this,
                        COMPANY_SETTINGS_SIDEBAR.billing,
                      )}
                      className={classnames("menu-style", {
                        active: this.classnamesHelper(
                          COMPANY_SETTINGS_SIDEBAR.billing,
                        ),
                      })}
                    >
                      <Link
                        className="menu-text"
                        to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.billing}`}
                      >
                        <img
                          src={billingImg}
                          alt="Billing"
                          className="menu-icon mx-xl-4 mx-md-2"
                        ></img>
                        Billing
                      </Link>
                    </div>
                  </ManagerAuthBlock>
                  <div
                    className={classnames("menu-style", {
                      active: this.classnamesHelper(
                        COMPANY_SETTINGS_SIDEBAR.directConnect,
                      ),
                    })}
                  >
                    <a
                      className="menu-text"
                      href={config.MEMBERS_URLS.directConnectSettings}
                    >
                      <img
                        src={directConnectImg}
                        alt="Direct Connect"
                        className="menu-icon mx-xl-4 mx-md-2"
                      ></img>
                      Direct Connect
                    </a>
                  </div>
                </div>
              </div>

              <div className="row sidebar-title mt-lg-5 mt-sm-5">
                <h3 className="menu-header"> Global Options</h3>
                <div className="col-12 p-0">
                  {featureFlags.reviewWrite && !pulsem ? (
                    <div
                      onClick={this.updateState.bind(
                        this,
                        COMPANY_SETTINGS_SIDEBAR.review,
                      )}
                      className={classnames("menu-style", {
                        active: this.classnamesHelper(
                          COMPANY_SETTINGS_SIDEBAR.review,
                        ),
                      })}
                    >
                      <a className="menu-text">
                        <img
                          src={reviewImg}
                          alt="Reviews"
                          className="menu-icon mx-xl-4 mx-md-2 mx-xs-4"
                        ></img>
                        Reviews
                      </a>
                    </div>
                  ) : null}
                  <div
                    onClick={this.updateState.bind(
                      this,
                      COMPANY_SETTINGS_SIDEBAR.texting,
                    )}
                    className={classnames("menu-style", {
                      active: this.classnamesHelper(
                        COMPANY_SETTINGS_SIDEBAR.texting,
                      ),
                    })}
                  ></div>
                  {(featureFlags.textingWrite || featureFlags.messageWrite) && !pulsem ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.texting}`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.texting,
                        )}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.texting,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={textingImg}
                            alt="Texting"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Messages
                        </div>
                      </div>
                    </Link>
                  ) : null}

                  {featureFlags.postcardCampaignWrite ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.campaign}`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.campaign,
                        )}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.campaign,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={campaignImg}
                            alt="Campaigns"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Campaigns
                        </div>
                      </div>
                    </Link>
                  ) : null}

                  {featureFlags.appointmentWrite ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.appointments}`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.appointments,
                        )}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.appointments,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={appointmentsImg}
                            alt="Appointment"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Appointment
                        </div>
                      </div>
                    </Link>
                  ) : null}
                  {featureFlags.reviewWrite && !pulsem
                    ? (
                      <Link
                        to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.syndication}`}
                      >
                        <div
                          onClick={this.updateState.bind(
                            this,
                            COMPANY_SETTINGS_SIDEBAR.syndication,
                          )}
                          className={classnames("menu-style", {
                            active: this.classnamesHelper(
                              COMPANY_SETTINGS_SIDEBAR.syndication,
                            ),
                          })}
                        >
                          <span className="menu-text">
                            <img
                              src={syndicationImg}
                              alt="Syndication"
                              className="menu-icon mx-xl-4 mx-md-2"
                            ></img>
                            Syndication
                          </span>
                        </div>
                      </Link>
                    ) : null}

                  {(featureFlags.appointmentWrite ||
                    featureFlags.reviewWrite) &&
                    !pulsem ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.widgets}/reviews`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.widgets,
                        )}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.widgets,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={widgetsImg}
                            alt="Widgets"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Widgets
                        </div>
                      </div>
                    </Link>
                  ) : null}
                  {featureFlags.appointmentWrite &&
                    pulsem ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.widgets}/appointment`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.widgets,
                        )}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.widgets,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={widgetsImg}
                            alt="Widgets"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Widgets
                        </div>
                      </div>
                    </Link>
                  ) : null}

                  {!pulsem ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.app}`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.app,
                        )}
                        onMouseOver={() => this.setState({ showOrange: true })}
                        onMouseOut={() => this.setState({ showOrange: false })}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.app,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={
                              this.state.selectedMenu ===
                                COMPANY_SETTINGS_SIDEBAR.app ||
                                this.state.showOrange
                                ? OrangeAppImg
                                : GrayAppImg
                            }
                            alt="app"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Apps & Integrations
                        </div>
                      </div>
                    </Link>
                  ) : null}
                  {pulsem ? (
                    <Link
                      to={`/companies/${companyId}/${COMPANY_SETTINGS_ROUTES.integration}`}
                    >
                      <div
                        onClick={this.updateState.bind(
                          this,
                          COMPANY_SETTINGS_SIDEBAR.app,
                        )}
                        onMouseOver={() => this.setState({ showOrange: true })}
                        onMouseOut={() => this.setState({ showOrange: false })}
                        className={classnames("menu-style", {
                          active: this.classnamesHelper(
                            COMPANY_SETTINGS_SIDEBAR.app,
                          ),
                        })}
                      >
                        <div className="menu-text">
                          <img
                            src={
                              this.state.selectedMenu ===
                                COMPANY_SETTINGS_SIDEBAR.app ||
                                this.state.showOrange
                                ? OrangeAppImg
                                : GrayAppImg
                            }
                            alt="app"
                            className="menu-icon mx-xl-4 mx-md-2"
                          ></img>
                          Integrations
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-10 col-sm-12 mt-4 mt-sm-0">
              {this.renderRightComponent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanySettings);
