import React, { useState } from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import Button, {
  ToggleButton,
  ToggleButtonGroup,
} from "src/shared/styleguide/button/Button";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";

export const ButtonStyleGuide = () => {
  useDocumentTitle("Style Guide - Button");
  const [radioValue, setRadioValue] = useState(1);
  const radios = [
    { name: "Option #1", value: 1 },
    { name: "Option #2", value: 2 },
  ];

  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Buttons</h3>
      <div>
        A button means an operation (or a series of operations). Clicking a
        button will trigger corresponding business logic.
      </div>

      <StyleGuideSection
        title="Basic Styling"
        description="Includes several predefined button styles, each serving its own semantic purpose, thrown in for more control."
        marginTop="1.2rem"
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button variant="primary" className="mr-2">
                Primary
              </Button>
              <Button variant="secondary" className="mr-2">
                Secondary
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button variant="primary">
  Primary
</Button>

<Button variant="secondary">
  Secondary
</Button>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Outline Buttons"
        description="In need of a button, but not the hefty background colors they bring?
        Replace the default variant prop definition with the outline-* ones to
        remove all background images and colors on any button."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button variant="outline-primary" className="mr-2">
                Primary
              </Button>
              <Button variant="outline-light" className="mr-2">
                Secondary
              </Button>
              <Button variant="outline-danger" className="mr-2">
                Danger
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button variant="outline-primary">
  Primary
</Button>

<Button variant="outline-secondary">
  Secondary
</Button>

<Button variant="outline-danger">
  Danger
</Button>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Sizes"
        description="Buttons can have additional sizes like larger or smaller buttons."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button variant="primary" size="xs" className="mr-2">
                Extra Small
              </Button>
              <Button variant="primary" size="sm" className="mr-2">
                Small
              </Button>
              <Button variant="primary" className="mr-2">
                Normal
              </Button>
              <Button variant="primary" size="lg" className="mr-2">
                Large
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button variant="primary" size="xs">
  Extra Small
</Button>

<Button variant="primary" size="sm">
  Small
</Button>

<Button variant="primary">Normal</Button>

<Button variant="primary" size="lg">
  Large
</Button>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Full Width"
        description="Create block level buttons - those that span the full width of a parent."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button block>Primary Block Button</Button>
              <Button block variant="outline-primary">
                Outline Block Button
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button block>Primary Block Button</Button>

<Button block variant="outline-primary">
  Outline Block Button
</Button>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Active state"
        description={
          <div>
            Buttons will appear pressed (with a darker background, darker
            border, and inset shadow) when active and also by adding{" "}
            <code>active</code> prop.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button active variant="primary" className="mr-2">
                Primary
              </Button>
              <Button active variant="secondary">
                Secondary
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button active variant="primary">
  Primary
</Button>

<Button active variant="secondary">
  Secondary
</Button>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Disabled State"
        description={
          <div>
            Make buttons appear disabled by adding <code>disabled</code> prop to
            any button element.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button disabled className="mr-2">
                Primary
              </Button>
              <Button disabled variant="secondary" className="mr-2">
                Secondary
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button disabled>
  Primary
</Button>

<Button disabled variant="secondary">
  Secondary
</Button>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Rounded Corner"
        description={
          <div>
            Make buttons rounded by adding the utility class{" "}
            <code>rounded-pill</code> to the buttons.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button style={{ width: 140 }} className="rounded-pill mr-2">
                Primary
              </Button>
              <Button
                style={{ width: 140 }}
                variant="outline-light"
                className="rounded-pill  mr-2"
              >
                Secondary
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button style={{ width: 140 }} className="rounded-pill mr-2">
  Primary
</Button>

<Button
  style={{ width: 140 }}
  variant="outline-light"
  className="rounded-pill  mr-2"
>
  Secondary
</Button>
`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Button Group"
        description={
          <div>
            Make buttons rounded by adding the utility class{" "}
            <code>rounded-pill</code> to the buttons.
          </div>
        }
      >
        <CodePreview
          elements={
            <ToggleButtonGroup type="radio" name="optionsXX" defaultValue={1}>
              {radios.map((radio, index) => {
                return (
                  <ToggleButton
                    key={index}
                    id={`radio-${index}`}
                    type="radio"
                    variant={
                      radio.value === radioValue
                        ? "primary"
                        : "outline-secondary"
                    }
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          }
          code={`
import Button, {
  ToggleButton,
  ToggleButtonGroup,
} from "src/shared/styleguide/button/Button";

const [radioValue, setRadioValue] = useState(1);
const radios = [
  { name: "Option #1", value: 1 },
  { name: "Option #2", value: 2 },
];

<ToggleButtonGroup type="radio" name="optionsXX" defaultValue={1}>
{radios.map((radio, index) => {
  return (
    <ToggleButton
      key={index}
      id={\`radio-\${index}\`}
      type="radio"
      variant={
        radio.value == radioValue
          ? "primary"
          : "outline-secondary"
      }
      name="radio"
      value={radio.value}
      checked={radioValue === radio.value}
      onChange={(e) => setRadioValue(e.currentTarget.value)}
    >
      {radio.name}
    </ToggleButton>
  );
})}
</ToggleButtonGroup>
`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Icon Buttons"
        description="Make icon buttons by adding icons from fontawesome icon library."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Button className="btn-icon mr-2">
                <span className="fa fa-chevron-left" />
              </Button>
              <Button className="mr-2">
                <span className="fa fa-chevron-right" />
              </Button>
              <Button variant="outline-primary" className="mr-2">
                <span className="fa fa-pencil" />
              </Button>
              <Button variant="outline-primary" className="mr-2">
                <span className="fa fa-cog" />
              </Button>
            </React.Fragment>
          }
          code={`import Button from "src/shared/styleguide/button/Button";

<Button>
  <span className="fa fa-chevron-left" />
</Button>

<Button>
  <span className="fa fa-chevron-right" />
</Button>

<Button variant="outline-primary">
  <span className="fa fa-pencil" />
</Button>

<Button variant="outline-primary">
  <span className="fa fa-cog" />
</Button>`}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default ButtonStyleGuide;
