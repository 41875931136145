import format from "date-fns/format";
import parse from "date-fns/parse";

export const DATE_FORMATS = {
  WEEK: 0,
  MONTH: 1,
  YEAR: 2,
};
export const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const DAYS_IN_A_WEEK = 7;

export default class CalendarUtil {
  /**
   * Convert a date string from one string format to another
   * @param {string} dateStr input date string
   * @param {string} fromFormat input date format
   * @param {string} toFormat output date form
   */
  static fromStrToStr(dateStr, fromFormat, toFormat) {
    return format(parse(dateStr, fromFormat, new Date()), toFormat);
  }

  /**
   *
   * @param {string} dateStr date string
   * @param {number} filterId filter id
   * @param {string} formatStr input date format
   * @param {string} chartType type of the chart
   * @param {string} chartMode mode of the chart
   * @returns {string} string representation of date as per filter
   */
  static toDateStrByChartType(
    dateStr,
    filterId,
    formatStr = "MMM dd, yyyy",
    chartType,
    chartMode,
  ) {
    const fromDate = parse(dateStr, formatStr, new Date(0));
    switch (filterId) {
      case 0:
        return format(fromDate, "MMM dd");
      case 1:
        return format(fromDate, "MMM dd");
      case 2:
        return format(fromDate, "MMM dd");
      case 3:
        return format(fromDate, "MMM dd");
      case 4:
        if (chartType === "responseRate") {
          return format(fromDate, "MMM yy");
        }
        return format(fromDate, "MMM yyyy");
      case 5:
        if (chartType === "responseRate") {
          if (chartMode === "month") {
            return format(fromDate, "MMM dd");
          }
          return format(fromDate, "MMM yy");
        }
        return format(fromDate, "MMM yyyy");
      default:
        return format(fromDate, "MMM dd, yyyy");
    }
  }

  /**
   *
   * @param {string} dateStr date string
   * @param {number} filterId filter id
   * @param {string} formatStr input date format
   * @returns {string} string representation of date as per filter
   */
  static toDateStrByFilter(
    dateStr,
    filterId,
    formatStr = "MMM dd, yyyy",
    weekOfStr = "Week of ",
  ) {
    const fromDate = parse(dateStr, formatStr, new Date(0));
    switch (filterId) {
      case 0:
        return format(fromDate, "MMM dd");
      case 1:
        return format(fromDate, "MMM dd");
      case 2:
        return weekOfStr + format(fromDate, "MMM dd");
      case 3:
        return weekOfStr + format(fromDate, "MMM dd");
      case 4:
        return format(fromDate, "MMM yyyy");
      default:
        return format(fromDate, "MMM dd, yyyy");
    }
  }

  /**
   *
   * @param {date} date - date object to be converted
   * @param {String} short - date format
   */
  static dateToStr(date, fmt = DATE_FORMATS.YEAR) {
    switch (fmt) {
      case DATE_FORMATS.WEEK:
        return `${SHORT_MONTHS[date.getMonth()]} ${date.getDate()}`;
      case DATE_FORMATS.MONTH:
        return `${SHORT_MONTHS[date.getMonth()]} ${date.getFullYear()}`;
      case DATE_FORMATS.YEAR:
      default:
        return `${
          SHORT_MONTHS[date.getMonth()]
        } ${date.getDate()}, ${date.getFullYear()}`;
    }
  }

  /**
   * Get string representation for the last x number of days
   * @param {number} numDays - number of days
   */
  static getLastXDays(numDays) {
    const today = new Date();
    const dates = [];
    for (let i = 0; i < numDays; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      dates.push({
        x: SHORT_MONTHS[date.getMonth()] + " " + date.getDate(),
        start: date,
      });
    }
    return dates.reverse();
  }

  /**
   * Get string representation for the last x number of weeks
   * @param {number} numWeeks - number of weeks
   */
  static getLastXWeeks(numWeeks) {
    const sunday = new Date();
    sunday.setDate(sunday.getDate() - sunday.getDay());
    const dates = [];
    let last = new Date();
    for (let i = 0; i < numWeeks; i++) {
      const date = new Date();
      date.setDate(sunday.getDate() - i * DAYS_IN_A_WEEK);
      dates.push({
        x: "Week of " + SHORT_MONTHS[date.getMonth()] + " " + date.getDate(),
        start: date,
        end: last,
      });
      // update to last date of next week
      last = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    }
    return dates.reverse();
  }

  /**
   * Get string representation for the last x number of months
   * @param {number} numMonths - number of weeks
   */
  static getLastXMonths(numMonths) {
    const dates = [];
    let last = new Date();
    for (let i = 0; i < numMonths; i++) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - i);
      dates.push({
        x: SHORT_MONTHS[date.getMonth()] + " " + date.getFullYear(),
        start: date,
        end: last,
      });
      // update to last date of next month
      last = new Date(date.getFullYear(), date.getMonth(), 0);
    }
    return dates.reverse();
  }

  /**
   * Returns the start and end of the week for a given date object
   * @param {object} date - date object
   */
  static getWeekFromDate(date) {
    const timestamp = date.getTime();
    const today = new Date();
    let startDate = new Date(timestamp - date.getDay() * 86400000);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    if (startDate < today) {
      startDate = today;
    }
    const endDate = new Date(
      timestamp - date.getDay() * 86400000 + 6 * 86400000,
    );
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);
    return {
      start: startDate,
      end: endDate,
    };
  }
}
