import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAccordionToggle } from "react-bootstrap";
import classnames from "classnames";
import "./AccordionToggle.scss";

export const AccordionToggle = ({
  children,
  eventKey,
  isCollapseAll,
  expanded = false,
  showExpandedCollapsedIcon = true,
  iconExpanded = "fa icon-accordion-toggle fa-chevron-up",
  iconCollapsed = "fa icon-accordion-toggle fa-chevron-down",
  callback = null,
  accordionHeaderClass = "",
}) => {
  const [expandedState, setExpandedState] = useState(expanded);
  const toggleAccordion = useAccordionToggle(eventKey, () =>
    setExpandedState(!expandedState),
  );

  const toggleAccordionHelper = () => {
    if (callback) callback();

    toggleAccordion();
  };

  useEffect(() => {
    if (isCollapseAll) {
      setExpandedState(eventKey);
    }
  }, [eventKey]);

  return (
    <h6
      className={`m-0 py-2 d-flex cursor-pointer ${accordionHeaderClass}`}
      onClick={toggleAccordionHelper}
    >
      <span className="flex-grow font-weight-bold">{children}</span>
      {showExpandedCollapsedIcon ? (
        <small>
          <span
            className={classnames({
              [iconExpanded]: expandedState,
              [iconCollapsed]: !expandedState,
            })}
          />
        </small>
      ) : null}
    </h6>
  );
};

AccordionToggle.propTypes = {
  eventKey: PropTypes.string,
  expanded: PropTypes.bool,
  showExpandedCollapsedIcon: PropTypes.bool,
  iconExpanded: PropTypes.string,
  iconCollapsed: PropTypes.string,
  callback: PropTypes.func,
};

export default AccordionToggle;
