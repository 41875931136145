import React, { useState, useEffect, useContext, useRef } from "react";
import BaseSegmentCard from "./BaseSegmentCard";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import {
  dateFormatter,
  formatDate,
  numberFormatter,
  stringConvertor,
} from "src/shared/Utils";
import { DATE_FORMATS } from "src/shared/Constants";
import ellipsisV from "src/styles/assets/ellipsis-v.svg";
import defaultImage from "src/assets/images/segments/default.svg";
import PropTypes from "prop-types";
import Table from "src/shared/styleguide/table/Table";
import emailIcon from "src/assets/images/common/email.svg";
import textIcon from "src/assets/images/common/message.svg";
import followUpIcon from "src/assets/images/common/followup.svg";
import postcardIcon from "src/assets/images/common/postcard.svg";
import ReadMoreCustomModal from "src/shared/modal/ReadMoreCustomModal";
import customImage from "src/assets/images/segments/custom.svg";
import { Row, Col } from "react-bootstrap";
import {
  FILTER_ACTIVITY,
  MUTATION_CLONE_SEGMENT,
  MUTATION_SET_FAVORITE_SEGMENT,
  MUTATION_SET_ARCHIVED_SEGMENT,
} from "src/graphql/customers_segments/customers/queries.js";
import { useApolloClient } from "@apollo/react-hooks";
import { NO_CACHE } from "src/shared/Constants";
import { addToast } from "src/utils/ToastUtil";
import StringUtil from "src/utils/StringUtil";
import swal from "sweetalert";
import tickMark from "src/assets/images/common/info-icon-large.svg";
import { showCompanyId, featureFlags } from "src/shared/SessionHelper";
import { useHistory } from "react-router-dom";
import { SegmentsContext } from "./SegmentsContext";
import { confirmExit } from "src/components/customers_segments/segments/segment/SegmentUtils";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import { getCriteriaCount, shapeForDisplay } from "./segment/SegmentUtils";

const SegmentsCard = ({
  cardData,
  totalCustomers,
  cardKey,
  expand = false,
  segmentUpdate,
  metaData,
  advanceFilter,
}) => {
  // variables
  const parentRef = useRef();
  const client = useApolloClient();
  const history = useHistory();
  const {
    filterBy,
    rawCitiesData,
    rawStatesData,
    rawZipCodesData,
  } = useContext(SegmentsContext);
  // states
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [activities, setActivities] = useState([]);
  const [displayData, setDisplayData] = useState({});
  // methods

  /**
   * Setup the URL for the new campaign
   * @param {*} channel -- Campaign channel
   * @param {*} id -- segment id
   */
  const createNewCampaign = (channel, id) => {
    switch (channel) {
      case "text_message":
        history.push(`/companies/${showCompanyId}/text-campaigns/new/${id}`, {
          prevPage: document.location.pathname,
        });
        break;
      case "postcard":
        history.push(
          `/companies/${showCompanyId}/postcard-campaigns/new/${id}`,
          {
            prevPage: document.location.pathname,
          },
        );
        break;
      case "email":
        history.push(`/companies/${showCompanyId}/email-campaigns/new/${id}`, {
          prevPage: document.location.pathname,
        });
        break;
    }
  };

  const showCriteriaElement = (type, criteria = {}) => {
    const divider = <span className="show-criteria-divider">|</span>;

    let allowed = ["customer_details", "transactions"];

    if (type === "exclude") {
      allowed = ["exclude_transactions"];
    }

    const displayObject = Object.keys(criteria)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        const displayKey =
          key === "exclude_transactions" ? "transactions" : key;
        obj[displayKey] = criteria[key];

        return obj;
      }, {});

    return (
      <div className="show-criteria-container">
        {Object.keys(displayObject).map((item, index) => (
          <div
            key={`segment-${type}-${item}`}
            className="show-criteria-category"
          >
            <div className="sub-title">
              <b>{stringConvertor(item)}</b>
            </div>
            {Object.keys(displayObject[item]).map((el, i) => {
              if (typeof displayObject[item][el] === "object") {
                return (
                  <span key={`segment-${type}-${item}-${el}`}>
                    {stringConvertor(el)}:{" "}
                    <b>
                      {displayObject[item][el].map(
                        (node, n) =>
                          `${stringConvertor(node)}${n < displayObject[item][el].length - 1 ? ", " : ""
                          }`,
                      )}
                    </b>{" "}
                    {i < Object.keys(displayObject[item]).length - 1
                      ? divider
                      : ""}
                  </span>
                );
              } else {
                return `${displayObject[item][el]}${i < displayObject[item].length - 1 ? ", " : ""
                  }
              `;
              }
            })}
          </div>
        ))}
      </div>
    );
  };

  const showAdvanceCriteriaElement = (criteria = {}) => {
    const displayObject = Object.keys(criteria)
      .filter((key) => "advance_filter" === key)
      .reduce((obj, key) => {
        const displayKey =
          key === "exclude_transactions" ? "transactions" : key;
        obj[displayKey] = criteria[key];
        return obj;
      }, {});

    return (
      <div className="show-criteria-container">
        {Object.keys(displayObject).map((item, index) => (
          <div
            key={`segment-include-${item}`}
            className="show-criteria-category"
          >
            {Object.keys(displayObject[item]).map((el, i) => {
              return `${displayObject[item][el]}${i < displayObject[item].length - 1 ? ", " : ""
                }`;
            })}
          </div>
        ))}
      </div>
    );
  };
  const shapeForAdvanceCriteriaDisplay = (customFiltersList, data) => {
    const displayObj = {};
    if (data.customer_tags) {
      displayObj.advance_filter = [];
      if (
        data.customer_tags.custom_filter_ids &&
        data.customer_tags.custom_filter_ids.values
      ) {
        customFiltersList.map((item) => {
          if (data.customer_tags.custom_filter_ids.values.includes(item.id)) {
            displayObj.advance_filter.push(item.name);
          }
        });
      }
    }
    return displayObj;
  };

  const criteriaCounts = getCriteriaCount(advanceFilter, metaData);
  /**
   * Returns the list of activity for the given filter id
   */
  const getActivity = () => {
    const queryVariables = {
      filterId: cardData.id,
    };
    client
      .query({
        query: FILTER_ACTIVITY,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data.filterActivities) {
          setActivities(res.data.filterActivities);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  /**
   * Creates a clone of the selected segment
   */
  const cloneSegment = (e) => {
    const queryVariables = {
      filterId: cardData.id,
    };
    client
      .mutate({
        mutation: MUTATION_CLONE_SEGMENT,
        variables: queryVariables,
      })
      .then((res) => {
        if (res) {
          addToast({
            type: "success",
            message: "Your segment has been cloned successfully",
          });
          segmentUpdate();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  /**
   * Mark the given segment as unarchived
   */
  const unarchiveSegment = () => {
    const queryVariables = {
      filterId: cardData.id,
      state: false,
    };
    client
      .mutate({
        mutation: MUTATION_SET_ARCHIVED_SEGMENT,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data) {
          addToast({
            type: "success",
            message: `Your segment has been unarchived successfully`,
          });
          segmentUpdate();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  /**
   * Mark the given segment as archived
   */
  const archiveSegment = (state) => {
    const queryVariables = {
      filterId: cardData.id,
      state: true,
    };
    client
      .mutate({
        mutation: MUTATION_SET_ARCHIVED_SEGMENT,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data) {
          addToast({
            type: "success",
            message: `Your segment has been archived successfully`,
          });
          segmentUpdate();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  /**
   * Mark the given segment as favorite
   */
  const favoriteSegment = (e, state) => {
    e.stopPropagation();
    const queryVariables = {
      filterId: cardData.id,
      state: state,
    };
    client
      .mutate({
        mutation: MUTATION_SET_FAVORITE_SEGMENT,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data) {
          addToast({
            type: "success",
            message: `Your segment has been ${state ? "favorited" : "unfavorited"
              } successfully`,
          });
          segmentUpdate();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const message = `
      <div>    
        <div>
          This segment is not a part of any campaigns
        </div>
        <div>
          Note : The segment will not be available for creating campaigns
        </div>
      </div>`;

  const messageContainer = document.createElement("div");
  messageContainer.innerHTML = message;

  /**
   *
   * @param {*} e -- event
   * @param {*} name -- name of the segment
   */
  const setArchive = (e, name) => {
    e.stopPropagation();
    swal({
      title: "",
      className: "archive-segment-modal",
      text: `Do you want to archive "${name}"?`,
      content: messageContainer,
      icon: tickMark,
      buttons: {
        cancel: {
          text: "No",
          value: "no",
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: "yes",
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((confirm) => {
      if (confirm === "yes") {
        archiveSegment();
      }
    });
  };

  /**
   *
   * @param {*} event -- event
   * Set the background color for the active submenu
   */
  const setBackgroundColor = (event) => {
    event.currentTarget.classList.toggle("active-menu-item");
  };

  /**
   *
   * @returns table to display in popup
   */
  const showTableData = () => {
    let filterData = activities.map((row) => [
      {
        node: (
          <div className="campaign-name">
            <a className="campaign-title" target="_blank">
              {row.campaignName}
            </a>
          </div>
        ),
      },
      {
        node: (
          <div className="campaign-type">
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={StringUtil.capitalize(row.channel)}
              icon={
                <span>
                  <img
                    src={
                      row.channel === "email"
                        ? emailIcon
                        : row.Channel === "text"
                          ? postcardIcon
                          : followUpIcon
                    }
                    alt={row.Channel}
                    width={"20"}
                    height={"20"}
                  />
                </span>
              }
            />
          </div>
        ),
      },
      {
        node: <div className="campaign-date">{row.mailingDate}</div>,
      },
    ]);
    return filterData;
  };

  useEffect(() => {
    window.removeEventListener("beforeunload", confirmExit);
    getActivity();
  }, []);

  React.useEffect(() => {
    setDisplayData(
      shapeForDisplay(
        advanceFilter,
        metaData,
        rawCitiesData,
        rawStatesData,
        rawZipCodesData,
      ),
    );
  }, [rawStatesData, rawCitiesData, rawZipCodesData]);

  if (cardData && totalCustomers) {
    const {
      id,
      name,
      updatedAt,
      customersCount,
      recentActivityName,
      archived,
      favorite,
      repeatCustomerRate,
      createdAt,
      canArchive,
    } = cardData;

    return (
      <BaseSegmentCard
        expand={expand}
        cardKey={cardKey}
        highlight={true}
        archived={archived}
        favorite={favorite}
        filterBy={filterBy.value}
        cardTitle={
          <>
            <div className="title">
              {StringUtil.capitalize(name)}
              <PopupInfo
                tooltipClassName=""
                placement="top"
                message={
                  cardData.default ? "Default Segment" : "Custom Segment"
                }
                icon={
                  <span>
                    <img
                      className="segment-icon"
                      src={cardData.default ? defaultImage : customImage}
                      alt="default"
                    />
                  </span>
                }
              />
            </div>
            <div className="subtitle">
              Modified on
              <span className="subtitle-date">
                {` ${updatedAt ? formatDate(updatedAt, DATE_FORMATS.lll) : "-"
                  }`}
              </span>
            </div>
          </>
        }
        cardBody={
          <React.Fragment>
            <Row>
              <Col className="card-details-middle-column mb-2">
                <div className="segment-card-body-element">
                  <div className="segment-card-body-title">
                    Number Of Customers
                  </div>
                  <div className="segment-card-value">
                    {numberFormatter(customersCount, 0)}
                  </div>
                </div>
                <div className="segment-card-body-element">
                  <div className="segment-card-body-title">
                    Repeat Customer Rate
                  </div>
                  <div className="segment-card-value">
                    {repeatCustomerRate ? repeatCustomerRate : "0"}%
                  </div>
                </div>
                <div className="segment-card-body-element">
                  <div className="segment-card-body-title">
                    Percent of Customer Base
                  </div>
                  <div className="segment-card-value">
                    {cardData.customerShare ? cardData.customerShare : "0"}%
                  </div>
                </div>
              </Col>
              <Col className="card-details-last-column">
                <div className="segment-card-body-element">
                  <div className="segment-card-body-title">Last Activity</div>
                  <div className="segment-card-body-value">
                    {recentActivityName ? (
                      <PopupInfo
                        iconClassName="sg-text-info"
                        placement="top"
                        message={recentActivityName}
                        icon={
                          <span>
                            <ReadMoreCustomModal
                              title={"Recent Campaigns"}
                              position="left top"
                              triggerComponent={
                                <div className="last-activity-link">
                                  <span>
                                    {recentActivityName}
                                  </span>
                                </div>
                              }
                              modalContents={
                                <Table
                                  className="recent-campaign-table"
                                  head={[
                                    "Campaign",
                                    "Channel",
                                    "Mailing/Schedule Date",
                                  ]}
                                  body={showTableData()}
                                />
                              }
                              readLessHeight={`${activities.length >= 6 ? "17.667rem" : "auto"
                                }`}
                              classNamePrefix="last-activity-popup"
                              showReadMore={
                                activities && activities.length >= 6
                                  ? true
                                  : false
                              }
                              closeOnDocumentClick={true}
                            />
                          </span>
                        }
                      />
                    ) : (
                      <div className="segment-card-value">N/A</div>
                    )}
                  </div>
                </div>

                <div className="segment-card-created-date">
                  <div className="segment-card-body-title">Created On</div>
                  <div className="segment-card-value">
                    {dateFormatter(createdAt)}
                  </div>
                </div>
                {/* Ellipses menu */}
                <span className="segment-card-menu-container">
                  {archived ? (
                    <DropdownButton
                      alignRight={true}
                      direction={false}
                      dropShadow={true}
                      title={<img alt="Options" src={ellipsisV} />}
                      variant="link"
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          unarchiveSegment();
                        }}
                      >
                        Unarchive
                      </Dropdown.Item>
                    </DropdownButton>
                  ) : (
                    <DropdownButton
                      alignRight={true}
                      direction={false}
                      dropShadow={true}
                      title={<img alt="Options" src={ellipsisV} />}
                      variant="link"
                    >
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `/companies/${showCompanyId}/segments/${id}/details`,
                            {
                              prevPage: document.location.pathname,
                            },
                          );
                        }}
                      >
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `/companies/${showCompanyId}/segments/${id}/edit`,
                            {
                              prevPage: document.location.pathname,
                            },
                          );
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          cloneSegment(e);
                          e.stopPropagation();
                        }}
                      >
                        Clone
                      </Dropdown.Item>
                      {featureFlags.campaignWrite && (
                        <Dropdown.Item
                          onClick={(e) => e.stopPropagation()}
                          href="#"
                          className="parent-item-container"
                        >
                          <DropdownButton
                            title={"Create Campaign"}
                            variant="link"
                            dropShadow={true}
                          >
                            {featureFlags.postcardCampaignWrite && (
                              <Dropdown.Item
                                href="#"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  createNewCampaign("postcard", id);
                                }}
                              >
                                <img src={postcardIcon} alt="Postcard" />
                                <span>Postcard</span>
                              </Dropdown.Item>
                            )}
                            {featureFlags.textCampaignWrite && (
                              <Dropdown.Item
                                href="#"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  createNewCampaign("text_message", id);
                                }}
                              >
                                <img src={textIcon} alt="Text" />
                                <span>Text</span>
                              </Dropdown.Item>
                            )}
                            {featureFlags.emailCampaignWrite && (
                              <Dropdown.Item
                                href="#"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  createNewCampaign("email", id);
                                }}
                              >
                                <img src={emailIcon} alt="Email" />
                                <span>Email</span>
                              </Dropdown.Item>
                            )}
                          </DropdownButton>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        onClick={(e) => {
                          e.stopPropagation();
                          favoriteSegment(e, !favorite);
                        }}
                        href="#"
                      >
                        {favorite ? "Unfavorite" : "Favorite"}
                      </Dropdown.Item>
                      {canArchive === "true" && (
                        <Dropdown.Item
                          onClick={(e) => {
                            e.stopPropagation();
                            setArchive(e, name);
                          }}
                          href="#"
                        >
                          Archive
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  )}
                </span>
              </Col>
            </Row>
          </React.Fragment>
        }
        toggleContent={
          <React.Fragment>
            <div className="toggle-header">
              This segment is a combination of -
            </div>
            <div className="d-flex flex-row ">
              <div className="d-flex flex-column col-6">
                <div className="container-main">
                  <div className="container-header">Include</div>
                  <div className="container-body">
                    <div className="info">
                      {showCriteriaElement("include", displayData)}
                    </div>
                  </div>
                </div>
                {showAdvanceFilter ? (
                  <div className="container-main">
                    <div className="container-header">Advanced Filter</div>
                    {criteriaCounts.advanceFilters > 0 ? (
                      <div className="container-body">
                        <div className="info">
                          <b>
                            {showAdvanceCriteriaElement(
                              shapeForAdvanceCriteriaDisplay(
                                advanceFilter,
                                metaData,
                              ),
                            )}
                          </b>
                        </div>
                      </div>
                    ) : (
                      <div className="container-body">
                        <div className="info">N/A</div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column col-6">
                <div className="container-main">
                  <div className="container-header">Exclude</div>
                  <div className="container-body">
                    <div className="info">
                      {showCriteriaElement("exclude", displayData)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              target="_blank"
              className="nav-link cursor-pointer toggle-footer"
              onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}
            >
              {showAdvanceFilter ? "Read Less" : "Read More"}
            </a>
          </React.Fragment>
        }
      />
    );
  }
  return null;
};

SegmentsCard.propTypes = {
  cardData: PropTypes.object,
  cardKey: PropTypes.number,
  expand: PropTypes.bool,
  type: PropTypes.string,
  totalCustomers: PropTypes.number,
  metaData: PropTypes.object,
  segmentUpdate: PropTypes.func,
  advanceFilter: PropTypes.array,
};

export default SegmentsCard;
