import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { showCompanyId, featureFlags } from "src/shared/SessionHelper";
import TabsComponent from "src/shared/tabs/TabsComponent";
import PublishingNotification from "./tabs/PublishingNotification";
import ReviewCategorySettings from "./tabs/ReviewCategorySettings";
import SocialMediaSettings from "./tabs/SocialMediaSettings";
import InvitesSettings from "./tabs/InvitesSettings";
import "./ReviewSettings.scss";

export const ReviewsComponent = () => {
  // variables
  const tabs = [
    {
      eventKey: 0,
      title: "Categories",
      path: "categories",
      documentTitle: "Categories",
      component: <ReviewCategorySettings />
    },
    {
      eventKey: 1,
      title: "Publishing/Notifications",
      path: "publishing-notifications",
      documentTitle: "Publishing/Notification",
      component: <PublishingNotification />
    },
    {
      eventKey: 2,
      title: "Invites",
      path: "invites",
      documentTitle: "Invites",
      component: <InvitesSettings />
    },
    {
      eventKey: 3,
      title: "Social Media",
      path: "social-media",
      documentTitle: "Social Media",
      component: <SocialMediaSettings />
    }
  ];

  // states
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const history = useHistory();
  const location = useLocation();

  // methods
  const onTabChange = (tabIndex) => {
    const selectedTab = tabs[tabIndex];
    history.push(
      `/companies/${showCompanyId}/review-settings/${selectedTab.path}`
    );
    document.title = selectedTab.documentTitle;
    setSelectedTab(selectedTab);
  };

  // effects
  useEffect(() => {
    const selectedTab = tabs.find((tab) =>
      location.pathname.includes(tab.path)
    );
    if (selectedTab) {
      document.title = selectedTab.documentTitle;
      setSelectedTab(selectedTab);
    }
  }, [location]);

  // feature flag check
  if ((!featureFlags.reviewWrite)) {
    return null;
  }

  // render
  return (
    <TabsComponent
      className="tab-review-settings settings-tabs"
      unmountOnExit={false}
      onTabChange={onTabChange}
      tabs={tabs}
      selectedTab={selectedTab.eventKey}
    />
  );
};

export default ReviewsComponent;