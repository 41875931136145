import {
  NEXT_MONTH_STARTING,
  NEXT_MONTH_ENDING,
  contactsAnyList,
  contactsDateList,
  contactsKeys,
  contactsLocationList,
  initialState,
  optionsServiceContractStatus,
  optionsHasMailingAddress,
  optionsHasMobileNumber,
  optionsHasEmailAddress,
  transactionsAnyList,
  transactionsKeys,
  transactionsRangeAvgList,
  transactionsRangeCountList,
  transactionsRangeList,
  transactionsRangeSumList,
} from "./SegmentConstants";
import {
  getFilter,
  getCompanyIdStorage,
  filterStorageExpired,
  clearExpiredFilterStorage,
} from "./SegmentLocalStorageHelper.js";
import { dateFormatter, stringConvertor } from "src/shared/Utils";
import { showCompanyId } from "src/shared/SessionHelper";
import { isEmpty } from "lodash";
import isEqual from "lodash.isequal";
import { numberFormatter } from "src/shared/Utils";
import StringUtil from "src/utils/StringUtil";

// Shapes Form input data to write for API
export const shapeForSubmit = (data) => {
  const touched = Object.fromEntries(
    Object.entries(data.touched).filter(([key, value]) => value === true),
  );
  const touchedList = Object.keys(touched);

  const submitObj = {};
  submitObj.transactions = {};
  submitObj.contacts = {};

  submitObj.transactions["count"] = {
    operator: "range",
    aggregator: "COUNT",
    values: ["any", "any"],
  };
  submitObj.transactions["amount"] = {
    operator: "range",
    aggregator: "SUM",
    values: ["any", "any"],
  };
  submitObj.transactions["months"] = {
    operator: "range",
    values: ["any", "any"],
  };

  touchedList.map((key) => {
    // Populate contacts object
    if (contactsAnyList.includes(key)) {
      const valuesList = Object.values(data.values[key]);
      let values = valuesList.map((item) => item.value || item);

      if (!values.length) {
        values = ["any"];
      }

      submitObj.contacts[key] = {
        operator: key === "service_contract_type" ? "IN" : "=",
        values: values,
      };
    }

    if (contactsDateList.includes(key)) {
      const value = data.values[key];
      const operator = value.type;
      const values = [value.start, value.end];

      submitObj.contacts[key] = {
        operator: operator,
        values: values,
      };
    }

    if (contactsLocationList.includes(key)) {
      const valuesList = Object.values(data.values[key]);
      const values = valuesList.map((item) => item.value || item);

      submitObj.contacts[key] = {
        operator: "IN",
        values: values,
      };
    }

    // Populate transactions object
    if (transactionsAnyList.includes(key)) {
      const valuesList = Object.values(data.values[key]);
      const values = valuesList.map((item) => item.value || item);

      submitObj.transactions[key] = {
        operator: "IN",
        values: values,
      };
    }

    if (transactionsRangeList.includes(key)) {
      const value = data.values[key];
      const values = [value.min || "any", value.max || "any"];

      submitObj.transactions[key] = {
        operator: "range",
        values: values,
      };
    }

    if (transactionsRangeCountList.includes(key)) {
      const value = data.values[key];
      const values = [
        value.min !== undefined ? value.min : "any",
        value.max !== undefined ? value.max : "any",
      ];

      submitObj.transactions[key] = {
        operator: "range",
        aggregator: "COUNT",
        values: values,
      };
    }

    if (transactionsRangeAvgList.includes(key)) {
      const value = data.values[key];
      const values = [
        value.min !== undefined ? value.min : "any",
        value.max !== undefined ? value.max : "any",
      ];

      submitObj.transactions[key] = {
        operator: "range",
        aggregator: "AVG",
        values: values,
      };
    }

    if (transactionsRangeSumList.includes(key)) {
      const value = data.values[key];
      const values = [
        value.min !== undefined ? value.min : "any",
        value.max !== undefined ? value.max : "any",
      ];

      submitObj.transactions[key] = {
        operator: "range",
        aggregator: "SUM",
        values: values,
      };
    }

    if (key === "custom_filter_ids") {
      submitObj.customer_tags = {};
      const values = data.values[key];

      const filterIds = values.map((item) => {
        if (typeof item === "string") {
          return item;
        } else if (typeof item.value === "string") {
          return item.value;
        }
      });

      submitObj.customer_tags[key] = {
        operator: "IN",
        values: filterIds,
      };
    }
  });

  return submitObj;
};

// Shapes API response and form input data to display in readable terms
export const shapeForDisplay = (
  customFiltersList,
  data,
  rawCitiesData,
  rawStatesData,
  rawZipCodesData,
  includeAdvanceCriteria = true, // If true advance criteria is included
) => {
  const displayObj = {};

  //declaring default object shape
  displayObj.customer_details = {};
  displayObj.customer_details.type = [];
  displayObj.customer_details.source = [];
  displayObj.customer_details.service_contract_status = [];
  displayObj.customer_details.service_contract_type = [];
  displayObj.customer_details.service_contract_start_date = [];
  displayObj.customer_details.service_contract_end_date = [];
  displayObj.customer_details.state = [];
  displayObj.customer_details.city = [];
  displayObj.customer_details.zip_code = [];
  displayObj.customer_details.has_mailing_address = [];
  displayObj.customer_details.has_mobile_number = [];
  displayObj.customer_details.has_email_address = [];

  if (data.contacts && data.contacts.commercial) {
    const isAny = data.contacts.commercial.values[0] === "any";
    data.contacts.commercial.values.map((item) => {
      if (item === "0") {
        displayObj.customer_details.type.push("Residential");
      } else if (item === "1") {
        displayObj.customer_details.type.push("Commercial");
      } else if (isAny) {
        displayObj.customer_details.type.push("All");
      }
    });
  } else {
    displayObj.customer_details.type.push("All");
  }

  if (data.contacts && data.contacts.source) {
    data.contacts.source.values.map((item) => {
      displayObj.customer_details.source.push(item);
    });
  } else {
    displayObj.customer_details.source.push("All");
  }

  if (data.contacts && data.contacts.in_contract) {
    data.contacts.in_contract.values.map((item) => {
      if (parseInt(item) === 0) {
        displayObj.customer_details.service_contract_status.push(
          "Has No Service Contract",
        );
      } else if (parseInt(item) === 1) {
        displayObj.customer_details.service_contract_status.push(
          "Has Service Contract",
        );
      } else if (item === "all") {
        displayObj.customer_details.service_contract_status.push("All");
      }
    });
  } else {
    displayObj.customer_details.service_contract_status.push("All");
  }

  if (data.contacts && data.contacts.service_contract_type) {
    data.contacts.service_contract_type.values.map((item) => {
      displayObj.customer_details.service_contract_type.push(item);
    });
  } else {
    displayObj.customer_details.service_contract_type.push("All");
  }

  if (data.contacts && data.contacts.contract_start_date) {
    if (data.contacts.contract_start_date.operator === "range") {
      displayObj.customer_details.service_contract_start_date.push(
        `${
          data.contacts.contract_start_date.values[0]
            ? dateFormatter(data.contacts.contract_start_date.values[0])
            : ""
        } ${
          data.contacts.contract_start_date.values[1]
            ? ` to ${dateFormatter(
                data.contacts.contract_start_date.values[1],
              )}`
            : ""
        }`,
      );
    } else {
      displayObj.customer_details.service_contract_start_date.push(
        data.contacts.contract_start_date.values[0] !== NEXT_MONTH_STARTING
          ? `${
              data.contacts.contract_start_date.values[0] === 0
                ? "Next"
                : "Previous"
            } 30 Days`
          : "Next Calendar Month",
      );
    }
  } else {
    displayObj.customer_details.service_contract_start_date.push("Any to Any");
  }

  if (data.contacts && data.contacts.contract_end_date) {
    if (data.contacts.contract_end_date.operator === "range") {
      displayObj.customer_details.service_contract_end_date.push(
        ` ${
          data.contacts.contract_end_date.values[0]
            ? dateFormatter(data.contacts.contract_end_date.values[0])
            : ""
        } ${
          data.contacts.contract_end_date.values[1]
            ? ` to ${dateFormatter(data.contacts.contract_end_date.values[1])}`
            : ""
        }`,
      );
    } else {
      displayObj.customer_details.service_contract_end_date.push(
        data.contacts.contract_end_date.values[0] !== NEXT_MONTH_ENDING
          ? ` ${
              data.contacts.contract_end_date.values[0] === 0
                ? "Next"
                : "Previous"
            } 30 days`
          : "Next Calendar Month",
      );
    }
  } else {
    displayObj.customer_details.service_contract_end_date.push("Any to Any");
  }

  if (data.contacts && data.contacts.state) {
    data.contacts.state.values.map((item) => {
      displayObj.customer_details.state.push(
        `${item}${
          rawStatesData[item]
            ? ` (${numberFormatter(rawStatesData[item], 0)})`
            : ""
        }`,
      );
    });
  } else {
    displayObj.customer_details.state.push("All");
  }

  if (data.contacts && data.contacts.city) {
    data.contacts.city.values.map((item) => {
      displayObj.customer_details.city.push(
        `${item}${
          rawCitiesData[item]
            ? ` (${numberFormatter(rawCitiesData[item], 0)})`
            : ""
        }`,
      );
    });
  } else {
    displayObj.customer_details.city.push("All");
  }

  if (data.contacts && data.contacts.zip) {
    data.contacts.zip.values.map((item) => {
      displayObj.customer_details.zip_code.push(
        `${item}${
          rawZipCodesData[item]
            ? ` (${numberFormatter(rawZipCodesData[item], 0)})`
            : ""
        }`,
      );
    });
  } else {
    displayObj.customer_details.zip_code.push("All");
  }

  if (data.contacts && data.contacts.has_mailing_address) {
    data.contacts.has_mailing_address.values.map((item) => {
      if (parseInt(item) === 0) {
        displayObj.customer_details.has_mailing_address.push("No");
      } else if (parseInt(item) === 1) {
        displayObj.customer_details.has_mailing_address.push("Yes");
      }
    });
  } else {
    displayObj.customer_details.has_mailing_address.push("All");
  }

  if (data.contacts && data.contacts.has_mobile_number) {
    data.contacts.has_mobile_number.values.map((item) => {
      if (parseInt(item) === 0) {
        displayObj.customer_details.has_mobile_number.push("No");
      } else if (parseInt(item) === 1) {
        displayObj.customer_details.has_mobile_number.push("Yes");
      }
    });
  } else {
    displayObj.customer_details.has_mobile_number.push("All");
  }

  if (data.contacts && data.contacts.has_email_address) {
    data.contacts.has_email_address.values.map((item) => {
      if (parseInt(item) === 0) {
        displayObj.customer_details.has_email_address.push("No");
      } else if (parseInt(item) === 1) {
        displayObj.customer_details.has_email_address.push("Yes");
      }
    });
  } else {
    displayObj.customer_details.has_email_address.push("All");
  }

  //declaring default object shape for transaction
  displayObj.transactions = {};
  displayObj.transactions.number_of_transactions = [];
  displayObj.transactions.time_since_last_transaction = [];
  displayObj.transactions.total_dollars_spent = [];
  displayObj.transactions.dollar_spent_per_transaction = [];
  displayObj.transactions.transaction_date_range = [];
  displayObj.exclude_transactions = {};

  if (data.transactions && data.transactions.job_type) {
    displayObj.transactions.job_type = data.transactions.job_type.values;
  } else {
    displayObj.transactions.job_type = ["All"];
  }

  if (data.transactions && data.transactions.count) {
    displayObj.transactions.number_of_transactions.push(
      `${StringUtil.capitalizeEachWord(
        data.transactions.count.values[0] || "Any",
      )}${
        data.transactions.count.values[1]
          ? ` to ${StringUtil.capitalizeEachWord(
              data.transactions.count.values[1] || "Any",
            )}`
          : ""
      }`,
    );
  } else {
    displayObj.transactions.number_of_transactions.push("Any to Any");
  }

  if (
    data.transactions &&
    (data.transactions.months || data.transactions.days)
  ) {
    if (data.transactions.months) {
      displayObj.transactions.time_since_last_transaction.push(
        `${StringUtil.capitalizeEachWord(
          data.transactions.months.values[0] || "Any",
        )}${
          data.transactions.months.values[1]
            ? ` to ${StringUtil.capitalizeEachWord(
                data.transactions.months.values[1] || "Any",
              )}`
            : ""
        } months`,
      );
    } else {
      displayObj.transactions.time_since_last_transaction.push(
        `${StringUtil.capitalizeEachWord(
          data.transactions.days.values[0] || "Any",
        )}${
          data.transactions.days.values[1]
            ? ` to ${StringUtil.capitalizeEachWord(
                data.transactions.days.values[1] || "Any",
              )}`
            : ""
        } days`,
      );
    }
  } else {
    displayObj.transactions.time_since_last_transaction.push("Any to Any");
  }

  if (data.transactions && data.transactions.amount) {
    displayObj.transactions.total_dollars_spent.push(
      `${StringUtil.capitalizeEachWord(
        data.transactions.amount.values[0] || "Any",
      )}${
        data.transactions.amount.values[1]
          ? ` to ${StringUtil.capitalizeEachWord(
              data.transactions.amount.values[1] || "Any",
            )}`
          : ""
      }`,
    );
  } else {
    displayObj.transactions.total_dollars_spent.push("Any to Any");
  }

  if (data.transactions && data.transactions.amount_per_txn) {
    displayObj.transactions.dollar_spent_per_transaction.push(
      `${StringUtil.capitalizeEachWord(
        data.transactions.amount_per_txn.values[0] || "Any",
      )}${
        data.transactions.amount_per_txn.values[1]
          ? ` to ${StringUtil.capitalizeEachWord(
              data.transactions.amount_per_txn.values[1] || "Any",
            )}`
          : ""
      }`,
    );
  } else {
    displayObj.transactions.dollar_spent_per_transaction.push("Any to Any");
  }

  if (data.transactions && data.transactions.txn_date) {
    displayObj.transactions.transaction_date_range.push(
      `${dateFormatter(data.transactions.txn_date.values[0])}${
        data.transactions.txn_date.values[1]
          ? ` to ${dateFormatter(data.transactions.txn_date.values[1])}`
          : ""
      }`,
    );
  } else {
    displayObj.transactions.transaction_date_range.push("Any to Any");
  }

  if (data.transactions && data.transactions.not_count) {
    const val = `${StringUtil.capitalizeEachWord(
      data.transactions.not_count.values[0] || "Any",
    )}${
      data.transactions.not_count.values[1]
        ? ` to ${StringUtil.capitalizeEachWord(
            data.transactions.not_count.values[1] || "Any",
          )}`
        : ""
    }`;
    if (val.length) {
      displayObj.exclude_transactions.number_of_transactions = [val];
    }
  } else {
    displayObj.exclude_transactions.number_of_transactions = [`N/A`];
  }

  if (data.transactions && data.transactions.not_amount) {
    const val = `${StringUtil.capitalizeEachWord(
      data.transactions.not_amount.values[0] || "Any",
    )}${
      data.transactions.not_amount.values[1]
        ? ` to ${StringUtil.capitalizeEachWord(
            data.transactions.not_amount.values[1] || "Any",
          )}`
        : ""
    }`;

    if (val.length) {
      displayObj.exclude_transactions.total_dollars_spent = [val];
    }
  } else {
    displayObj.exclude_transactions.total_dollars_spent = [`N/A`];
  }

  // "Advanced Filter"
  if (includeAdvanceCriteria) {
    if (data.customer_tags) {
      displayObj.advance_filter = [];
      if (
        data.customer_tags.custom_filter_ids &&
        data.customer_tags.custom_filter_ids.values
      ) {
        customFiltersList.map((item) => {
          if (
            data.customer_tags.custom_filter_ids.values.includes(item.value)
          ) {
            displayObj.advance_filter.push(item.label);
          }
        });
      }
    }
  }

  return displayObj;
};

const getOptions = (item, companyFilterMetrics) => {
  let options;
  switch (item) {
    case "commercial":
      options = companyFilterMetrics.resident_status;
      break;
    case "service_contract_type":
      options = companyFilterMetrics.service_contract_types;
      break;
    case "source":
      options = companyFilterMetrics.sources;
      break;
    case "has_mailing_address":
      options = optionsHasMailingAddress;
      break;
    case "has_mobile_number":
      options = optionsHasMobileNumber;
      break;
    case "has_email_address":
      options = optionsHasEmailAddress;
      break;
  }
  return options;
};

// Shapes API response to populate form
export const shapeForForm = (data, companyFilterMetrics) => {
  const formData = {};

  if (data.contacts) {
    const contactsDataList = Object.keys(data.contacts);

    contactsDataList.map((item) => {
      if (item === "contract_start_date" || item === "contract_end_date") {
        const isRelative =
          typeof data.contacts[item].values[0] === "number" ||
          data.contacts[item].values[0] === NEXT_MONTH_STARTING ||
          data.contacts[item].values[0] === NEXT_MONTH_ENDING;
        formData[item] = {
          type: isRelative ? "relative" : "range",
          start: isRelative
            ? data.contacts[item].values[0]
            : new Date(data.contacts[item].values[0]),
          end: isRelative
            ? data.contacts[item].values[1]
            : new Date(data.contacts[item].values[1]),
        };
      } else if (
        item === "has_email_address" ||
        item === "has_mailing_address" ||
        item === "has_mobile_number"
      ) {
        const originalData = data.contacts[item].values || data.contacts[item];
        const options = getOptions(item, companyFilterMetrics) || [];
        const newData = options.filter((option) =>
          originalData.includes(option.value),
        );
        formData[item] = newData;
      } else {
        formData[item] = data.contacts[item].values || data.contacts[item];
      }
    });
  }

  if (data.transactions) {
    const transactionsDataList = Object.keys(data.transactions);

    transactionsDataList.map((item) => {
      formData[item] = data.transactions[item].values;
      if (item === "txn_date") {
        formData[item] = {
          min: new Date(data.transactions[item].values[0]),
          max: new Date(data.transactions[item].values[1]),
        };
      } else if (item === "job_type") {
        formData[item] =
          data.transactions[item].values || data.transactions[item];
      } else if (item === "not_count" || item === "not_amount") {
        const limitAccessor = item.split("_")[1];
        const rMin =
          data.transactions &&
          data.transactions[limitAccessor] &&
          data.transactions[limitAccessor].values &&
          data.transactions[limitAccessor].values[0]
            ? typeof data.transactions[limitAccessor].values[0] === "string"
              ? parseInt(
                  companyFilterMetrics[`min_transaction_${limitAccessor}`],
                ) + 1
              : data.transactions[limitAccessor].values[0] + 1
            : initialState[limitAccessor].min + 1;

        const rMax =
          data.transactions &&
          data.transactions[limitAccessor] &&
          data.transactions[limitAccessor].values &&
          data.transactions[limitAccessor].values[1]
            ? typeof data.transactions[limitAccessor].values[1] === "string"
              ? parseInt(
                  companyFilterMetrics[`max_transaction_${limitAccessor}`],
                ) - 1
              : data.transactions[limitAccessor].values[1] - 1
            : initialState[limitAccessor].max - 1;

        formData[item] = {
          min: data.transactions[item].values[0],
          max: data.transactions[item].values[1],
          rMin: rMin,
          rMax: rMax,
        };
      } else if (item === "count" || item === "amount") {
        const min =
          data.transactions[item].values[0] !== "any"
            ? parseInt(data.transactions[item].values[0])
            : parseInt(companyFilterMetrics[`min_transaction_${item}`]);
        const max =
          data.transactions[item].values[1] !== "any"
            ? parseInt(data.transactions[item].values[1])
            : parseInt(companyFilterMetrics[`max_transaction_${item}`]);

        formData[item] = {
          min: min,
          max: max,
        };

        const notValue = `not_${item}`;
        const rMin =
          data.transactions &&
          data.transactions[notValue] &&
          data.transactions[notValue].values &&
          data.transactions[notValue].values[0]
            ? data.transactions[notValue].values[0] + 1
            : min + 1 || initialState[notValue].min + 1;

        const rMax =
          data.transactions &&
          data.transactions[notValue] &&
          data.transactions[notValue].values &&
          data.transactions[notValue].values[1]
            ? data.transactions[notValue].values[1] - 1
            : max - 1 || initialState[item].max - 1;

        formData[notValue] = {
          ...formData[notValue],
          rMin: rMin,
          rMax: rMax,
        };
      } else {
        formData[item] = {
          min: data.transactions[item].values[0],
          max: data.transactions[item].values[1],
        };
      }
    });
  }

  if (data.customer_tags) {
    formData.custom_filter_ids = {};
    const customFilterIdList = data.customer_tags.custom_filter_ids.values;
    const filtersList = customFilterIdList.map((id) => ({
      value: id,
      selected: true,
    }));

    formData.custom_filter_ids = filtersList;
  }

  return formData;
};

export const soruceData = (data) => {
  const customLabel = { value: "any", label: "All" };

  return [
    customLabel,
    ...data.map(([name, value]) => {
      const formattedName = name
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase());
      const formattedLabel = `${formattedName}(${value})`;
      const isDisabled = value === 0;

      return { value: name, label: formattedLabel, disabled: isDisabled };
    }),
  ];
};

export const getTouchedData = (data) => {
  const touchedList = [];

  if (data.contacts) {
    const contactsDataList = Object.keys(data.contacts);

    contactsDataList.map((item) => {
      touchedList.push(item);
    });
  }

  if (data.transactions) {
    const transactionsDataList = Object.keys(data.transactions);

    transactionsDataList.map((item) => {
      if (!isEqual(data.transactions[item].values, ["any", "any"])) {
        touchedList.push(item);
      }
    });
  }

  if (data.customer_tags) {
    touchedList.push("custom_filter_ids");
  }

  return touchedList;
};

export const nameValidator = (filtersList, filterName, filterOriginalName) => {
  const sanitize = (item) => item.replace(/[_-\s]+/gi, "");
  const cleanList = filtersList.map((item) => sanitize(item));
  const cleanName = sanitize(filterName.toLowerCase());

  const hasValidLength =
    filterName && filterName.length && filterName.length <= 100 ? true : false;
  const isUnique =
    !filterName || (hasValidLength && !cleanList.includes(cleanName));
  const hasOriginalName = filterOriginalName === filterName;

  return hasValidLength && (isUnique || hasOriginalName);
};

export const locationShaper = (data) => {
  return [
    { value: "any", label: "All" },
    ...Object.keys(data).map((key) => ({
      value: key,
      label: `${key} (${data[key]})`,
    })),
  ];
};

export const populateCompanyFilterMetrics = (data) => {
  const truthyObjects = Object.keys(data).filter((item) => data[item]);

  const validData = {};
  truthyObjects.map((item) => {
    if (data[item]) {
      if (item === "resident_status") {
        const accessor = "commercial";

        const allItem = initialState[accessor];

        const parsedData = JSON.parse(data[item]);

        const fetchedData = parsedData.map((item) => ({
          value: item === "residential" ? "0" : "1",
          label: stringConvertor(item),
        }));

        validData[item] = allItem.concat(fetchedData);
      } else if (item === "service_contract_types" || item === "sources") {
        const accessor =
          item === "service_contract_types"
            ? "service_contract_type"
            : item === "sources"
            ? "source"
            : item;

        const allItem = initialState[accessor];
        const parsedData = JSON.parse(data[item]).sort();

        // Remove blank items that are unable to be used to fetch data.
        const sanitationList = ["", ".", ",", "null", "undefined", "false"];
        sanitationList.map((item) => {
          if (parsedData.includes(item)) {
            const sanitationIndex = parsedData.indexOf(item);
            parsedData.splice(sanitationIndex, 1);
          }
        });

        const fetchedData = parsedData.map((item) => ({
          value: item,
          label: stringConvertor(item),
        }));

        validData[item] = allItem.concat(fetchedData);
      } else {
        validData[item] = data[item];
      }
    }
  });

  return validData;
};

export const jobTypeFormatter = data => {
  try {
    const parsedData = JSON.parse(data.replace(/\\/g, ''));
    const customLabel = { value: "any", label: "All" };

    const convertedArray = Object.entries(parsedData).map(([category, count]) => {
      const formattedCount = count.toLocaleString();
      return {
        value: category,
        label: `${category} (${formattedCount})`
      };
    });

    convertedArray.unshift(customLabel);
    return convertedArray;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const populateFormState = (formState, validData) => {
  let newFormState = { ...formState };

  // count
  if (validData.max_transaction_count) {
    newFormState.count = {
      min: parseInt(validData.min_transaction_count),
      max: parseInt(validData.max_transaction_count),
    };
    newFormState.not_count = {
      ...newFormState.not_count,
      rMin: parseInt(validData.min_transaction_count) + 1,
      rMax: parseInt(validData.max_transaction_count) - 1,
    };
  }
  // amount
  if (validData.max_transaction_amount) {
    newFormState.amount = {
      min: parseInt(validData.min_transaction_amount),
      max: parseInt(validData.max_transaction_amount),
    };
    newFormState.not_amount = {
      ...newFormState.not_amount,
      rMin: parseInt(validData.min_transaction_amount) + 1,
      rMax: parseInt(validData.max_transaction_amount) - 1,
    };
  }
  // dollar spent per transation
  if (validData.max_amount_per_transaction) {
    newFormState.amount_per_txn = {
      min: parseInt(validData.min_amount_per_transaction),
      max: parseInt(validData.max_amount_per_transaction),
    };
  }

  if (validData.min_transacted_at && validData.max_transacted_at) {
    const today = new Date();
    const minDate = new Date(validData.min_transacted_at);
    const maxDate = new Date(validData.max_transacted_at);

    // maxDate is most recent transaction, and therefore sets minimum time from today
    const minInTime = Math.abs(maxDate - today);
    const minInDays = Math.ceil(minInTime / (1000 * 60 * 60 * 24));
    const minInMonths = Math.ceil(minInDays / 30);

    // minDate is least recent transaction, and therefore sets maximum time from today
    const maxInTime = Math.abs(today - minDate);
    const maxInDays = Math.ceil(maxInTime / (1000 * 60 * 60 * 24));
    const maxInMonths = Math.ceil(maxInDays / 30);

    // time since last transaction: days
    newFormState.days = {
      min: minInDays,
      max: maxInDays,
    };

    // time since last transaction: months
    newFormState.months = {
      min: minInMonths,
      max: maxInMonths,
    };
  }

  return newFormState;
};

export const confirmExit = (evt) => {
  evt.preventDefault();
  evt.returnValue = "";
};

export const goToAllSegments = () => {
  window.location = `/companies/${showCompanyId}/segments`;
};

/**
 * Validates the expiration of the filter metadata.
 * @returns {boolean} - Returns true if the filter is not expired otherwise false.
 */
const validateFilterExpiration = (filterId) => {
  let isValid = true;
  if (filterStorageExpired(filterId)) {
    isValid = false;
    clearExpiredFilterStorage();
  }
  return isValid;
};

/**
 * Provides the filter data from the session.
 * @returns {*} Returns the filter object.
 */
export const getFilterFromStorage = (filterId) => {
  const localCompanyId = getCompanyIdStorage();
  const sessionCompanyId = showCompanyId;

  if (
    localCompanyId === sessionCompanyId &&
    validateFilterExpiration(filterId)
  ) {
    return getFilter(filterId);
  }
  return null;
};

export const validateRange = (
  formik,
  field,
  limitType,
  defaultVal,
  formState,
) => {
  const reverseLimitType = limitType === "min" ? "max" : "min";
  const reverseLimitRType = limitType === "min" ? "rMax" : "rMin";
  const defaultValInt = parseInt(defaultVal);
  const formikValInt =
    formik.values[field][limitType] === "0" ||
    formik.values[field][limitType] === 0
      ? 0
      : parseInt(formik.values[field][limitType]) || "";
  const reverseLimit =
    parseInt(formik.values[field][reverseLimitType]) ||
    parseInt(formik.values[field][reverseLimitRType]) ||
    "";

  const returnValue =
    limitType === "max"
      ? formikValInt === ""
        ? defaultValInt
        : formikValInt >= defaultValInt
        ? defaultValInt
        : formikValInt > reverseLimit && formikValInt <= defaultValInt
        ? formikValInt
        : formikValInt <= reverseLimit
        ? typeof reverseLimit === "number" && reverseLimit + 1
        : defaultValInt
      : formikValInt === ""
      ? defaultValInt
      : formikValInt <= defaultValInt
      ? defaultValInt
      : formikValInt < reverseLimit && formikValInt >= defaultValInt
      ? formikValInt
      : formikValInt >= reverseLimit
      ? typeof reverseLimit === "number" && reverseLimit - 1
      : defaultValInt;

  const newFormObj = {
    ...formik.values[field],
    [limitType]: returnValue,
  };

  formik.setFieldValue(field, newFormObj);

  if (formState) {
    const isTouchedObj = {
      ...formState[field],
      [limitType]: returnValue,
    };

    formik.setFieldTouched(field, !isEqual(isTouchedObj, formState[field]));
  } else {
    const isTouched =
      formik.values[field][limitType] !== "any" &&
      formik.values[field][limitType] !== "all" &&
      parseInt(defaultVal) !== parseInt(formik.values[field][limitType]);

    formik.setFieldTouched(field, isTouched);
  }
};

export const inputValueFormatter = (value, precision = 0) =>
  value === "-" ? "-" : !isNaN(value) ? numberFormatter(value, precision) : "";

export const inputValueSanitizer = (value) =>
  value === "-" ? "-" : parseInt(value.replace(/,/g, ""));

/**
 *
 * @param {*} data
 * @returns
 */

export const getCriteriaCount = (advanceFilter, data) => {
  let includeTransaction = 0;
  let excludeTransaction = 0;
  let includeContacts = 0;
  let excludeContacts = 0;
  let advanceFilters = 0;

  for (var datakey in data) {
    let criteria = data[datakey];
    for (var key in criteria) {
      if (criteria.hasOwnProperty(key)) {
        if (
          criteria[key].values &&
          !key.includes("not") &&
          datakey.includes("contacts")
        ) {
          includeContacts++;
        } else if (
          criteria[key].values &&
          key.includes("not") &&
          datakey.includes("contacts")
        ) {
          excludeContacts++;
        }
        if (
          criteria[key].values &&
          key.includes("not") &&
          datakey.includes("transactions")
        ) {
          excludeTransaction++;
        } else if (
          criteria[key].values &&
          !key.includes("not") &&
          datakey.includes("transactions")
        ) {
          includeTransaction++;
        }
        if (datakey.includes("customer_tags")) {
          advanceFilter.map((item) => {
            if (
              data.customer_tags.custom_filter_ids &&
              data.customer_tags.custom_filter_ids.values &&
              data.customer_tags.custom_filter_ids.values.includes(item.id)
            ) {
              advanceFilters++;
            }
          });
        }
      }
    }
  }
  return {
    include: { customer: includeContacts, transaction: includeTransaction },
    exclude: { customer: excludeContacts, transaction: excludeTransaction },
    advanceFilters: advanceFilters,
  };
};
