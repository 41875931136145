import React, { useState, useEffect } from "react";
import "./ReviewDetails.scss";
import StarRatings from "react-star-ratings";
import ReviewComment from "./ReviewComment";
import ReviewChallenge from "./ReviewChallenge";
import ReviewWithdrawalRequest from "./ReviewWithdrawalRequest";
import Switch from "react-switch";
import { REVIEW_DATA } from "./reviewListData";
import { showCompanyId } from "../../../../../../shared/SessionHelper";

import {
  PLATFORM_TYPE,
  REVIEW_DETAILS_DISPLAY_TABS,
  REVIEW_STATUS,
} from "../../../../../../shared/Constants";
import TabsComponent from "../../../../../../shared/tabs/TabsComponent";
import {
  DEFAULT_STAR_RATING_CONFIG,
  SWITCH_COLOR_CONFIG,
} from "../../../../../../shared/Config";
import ReviewHeader from "./ReviewHeader";
import { reviewDetailHelper } from "./review_details_query_actions";
import { updateReviewHelper } from "./review_details_mutation_actions";
import SuccessMessage from "./SuccessMessage";
import ErrorMessage from "./ErrorMessage";

const ReviewDetails = ({
  authHeader,
  companyUserId,
  reviewData,
  refreshHandler,
}) => {
  let [privateFeedbackToggle, setPrivateFeedbackToggle] = useState(false);
  let [reviewId, setReviewId] = useState(null);
  let [review, setReview] = useState(null);
  let [reviewChanged, setReviewChanged] = useState(false);
  let [isFeatured, setIsFeatured] = useState(false);
  let [reviewStatus, setReviewStatus] = useState("");
  let [display, setDisplay] = useState("none");
  let [message, setMessage] = useState("");
  let companyId = showCompanyId;
  let [errorDisplay, setErrorDisplay] = useState("none");
  let [errorMessage, setErrorMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    REVIEW_DETAILS_DISPLAY_TABS.comment.eventKey,
  );
  /**
   * Tabs structure for the tabs componennt
   */

  const handleSuccess = (newMessage) => {
    setDisplay("flex");
    setMessage(newMessage);
    if (refreshHandler) {
      refreshHandler();
    }
    setTimeout(() => {
      setDisplay("none");
    }, 3000);
  };

  const handleFailure = (newMessage) => {
    setErrorDisplay("flex");
    setErrorMessage(newMessage);
    setTimeout(() => {
      setErrorDisplay("none");
    }, 3000);
  };

  const setReviewData = async () => {
    let reviewType =
      reviewData.destination === "google" ? "ScrapedReview" : "Review";

    let reviewId =
      reviewData.destination === "google"
        ? reviewData.scrapedReviewId
        : reviewData.reviewId;
    setReviewId(reviewId);
    let goodData = await reviewDetailHelper({
      reviewId,
      reviewType,
      authHeader,
    });

    if (!goodData || !goodData.data || !goodData.data.data) return null;
    setReview(goodData.data.data.reviewDetail);
    setIsFeatured(goodData.data.data.reviewDetail.isFeatured);
    setReviewStatus(goodData.data.data.reviewDetail.reviewStatus);
  };

  useEffect(() => {
    setReviewData();
  }, [reviewChanged]);

  if (!review) return null;

  const tabs = [
    {
      eventKey: REVIEW_DETAILS_DISPLAY_TABS.comment.eventKey,
      title: REVIEW_DETAILS_DISPLAY_TABS.comment.title,
      component: (
        <ReviewComment
          reviewChanged={reviewChanged}
          setReviewChanged={setReviewChanged}
          setReview={setReview}
          review={review}
          companyUserId={companyUserId}
          reviewId={reviewId}
          authHeader={authHeader}
          handleSuccess={handleSuccess}
        />
      ),
    },
    {
      eventKey: REVIEW_DETAILS_DISPLAY_TABS.challenge.eventKey,
      title: REVIEW_DETAILS_DISPLAY_TABS.challenge.title,
      component: (
        <ReviewChallenge
          companyUserId={companyUserId}
          reviewId={reviewId}
          authHeader={authHeader}
          handleSuccess={handleSuccess}
          challenge={REVIEW_DATA[0].review_challenge}
        />
      ),
    },
    {
      eventKey: REVIEW_DETAILS_DISPLAY_TABS.withdrawalRequest.eventKey,
      title: REVIEW_DETAILS_DISPLAY_TABS.withdrawalRequest.title,
      component: (
        <ReviewWithdrawalRequest
          reviewId={reviewId}
          authHeader={authHeader}
          handleSuccess={handleSuccess}
          request={REVIEW_DATA[0].review_withdrawal_request}
        />
      ),
    },
  ];

  const featuredReviewHandler = async () => {
    // Handler for featured Review.
    let newStatus = !isFeatured;
    setIsFeatured(newStatus);
    let data = updateReviewHelper({
      reviewId,
      isFeatured: newStatus,
      authHeader,
    });
    data.then((response) => {
      if (response.data.errors && response.data.errors.length > 0) {
        setIsFeatured(!newStatus);
        handleFailure(response.data.errors[0].message);
      } else {
        setIsFeatured(newStatus);
        handleSuccess("The review information has been updated");
      }
    });
  };

  const publishReviewHandler = async () => {
    // Handler for featured Review.
    let newStatus = REVIEW_STATUS.PUBLISH;
    let data = await updateReviewHelper({
      reviewId,
      reviewStatus: newStatus,
      authHeader,
    });

    setReviewStatus(newStatus);
    handleSuccess("Review Updated!");
  };

  const starRating = (totalScore) => {
    var val = Math.floor(totalScore * 5);
    return val;
  };

  if (!review) return null;
  // if(!review.contact) return null;

  const contactName = (review, reviewData) => {
    if (review && review.contact) return review.contact.fullName;
    if (reviewData.contact) return reviewData.contact.name;
    return "";
  };
  const contactId = (review, reviewData) => {
    if (review && review.contact) return review.contact.contactId;
    if (reviewData.contact) return reviewData.contact.contactId;
    return "";
  };

  return (
    <div className="row mx-md-2">
      <div className="col-12 px-3 px-md-2">
        <div id="review-details">
          <div className="row">
            <div className="col-12">
              <div className="review-tile">
                <ReviewHeader
                  fullName={contactName(review, reviewData)}
                  destination={reviewData.destination}
                  totalScore={review.totalScore}
                  source={review.source}
                  reviewDate={review.reviewDate}
                  contactId={contactId(review, reviewData)}
                  companyId={companyId}
                />
                <div className="row mt-2">
                  <div className="offset-md-1 col-md-11 col-12 pl-lg-0 pl-sm-3">
                    {reviewData.destination !== PLATFORM_TYPE.GOOGLE ? (
                      <div className="row mt-4 additional-star-ratings">
                        {review.xrefTopics.map((reviewTopic, index) => (
                          <div
                            className={`col-md-3 col-12 ${
                              index !== 0 ? "mt-3 mt-md-0" : ""
                            }`}
                            key={index}
                          >
                            <div className="additional-star-ratings-label mb-2">
                              {reviewTopic.reviewTopic}
                            </div>
                            <StarRatings
                              rating={starRating(reviewTopic.rating)}
                              starDimension={
                                DEFAULT_STAR_RATING_CONFIG.dimension
                              }
                              starSpacing={DEFAULT_STAR_RATING_CONFIG.spacing}
                              starEmptyColor={
                                DEFAULT_STAR_RATING_CONFIG.emptyColor
                              }
                              starRatedColor={
                                DEFAULT_STAR_RATING_CONFIG.ratedColor
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row"></div>
                    )}
                    <div className="row mt-3">
                      <div className="col-12 subject">
                        {review.reviewSummary}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-12 review-body mt-1">
                        {review.reviewBody}
                      </div>
                    </div>
                    {review.comments && review.comments.length > 0 && (
                      <div className="row">
                        <div className="col-12 mt-2">
                          <div className="p-4 comment-tile">
                            <div className="comment-header">
                              Company Comment
                            </div>
                            <div className="comment-body">
                              {
                                review.comments[review.comments.length - 1]
                                  .comment
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {reviewData.destination !== PLATFORM_TYPE.GOOGLE &&
                      review.contactSuggestions &&
                      review.contactSuggestions.length > 0 && (
                        <div className="row mt-2">
                          <div className="col-12 pt-2 private-feedback-toggle-button">
                            <a
                              onClick={() =>
                                setPrivateFeedbackToggle(!privateFeedbackToggle)
                              }
                            >
                              {privateFeedbackToggle
                                ? "Hide Private Feedback"
                                : "View Private Feedback"}
                            </a>
                          </div>
                        </div>
                      )}
                    {privateFeedbackToggle &&
                      review.contactSuggestions.length !== 0 && (
                        <div className="row mt-2">
                          <div className="col-12 pt-2">
                            <div className="p-4 private-feedback-tile">
                              {review.contactSuggestions.map((suggestion) => (
                                <div>
                                  <p className="private-feedback-header mb-0">
                                    {suggestion.suggestionTopic}
                                  </p>
                                  <p className="private-feedback-body">
                                    {suggestion.suggestionAnswer}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    {reviewData.destination !== PLATFORM_TYPE.GOOGLE && (
                      <div className="row my-3">
                        <div className="col-md-4 col-12 pt-1">
                          <div className="row mt-2 mt-sm-0">
                            <div className="col-12 featured-review mt-md-1 d-flex align-items-center">
                              <span className="mr-3">Featured Review</span>
                              <Switch
                                onColor={SWITCH_COLOR_CONFIG.onColor}
                                offColor={SWITCH_COLOR_CONFIG.offColor}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={isFeatured}
                                onChange={() => featuredReviewHandler()}
                              />
                            </div>
                          </div>
                        </div>
                        {reviewStatus === REVIEW_STATUS.PENDING && (
                          <div className="col-md-4 col-12 pt-1">
                            <div className="row mt-2 mt-sm-0">
                              <div className="col-12 publish-review mt-md-1 d-flex align-items-center">
                                <span className="mr-3">Publish Review</span>
                                <Switch
                                  onColor={SWITCH_COLOR_CONFIG.onColor}
                                  offColor={SWITCH_COLOR_CONFIG.offColor}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  checked={
                                    reviewStatus === REVIEW_STATUS.PUBLISH
                                  }
                                  onChange={() => publishReviewHandler()}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {reviewData.destination !== PLATFORM_TYPE.GOOGLE && (
            <div className="row">
              <div className="col-12">
                <div className="review-tile">
                  <TabsComponent
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onTabChange={(tab) => {
                      if (tabs[tab]) {
                        setSelectedTab(tabs[tab].eventKey);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <SuccessMessage display={display} message={message} />
      <ErrorMessage display={errorDisplay} message={errorMessage} />
    </div>
  );
};
export default ReviewDetails;
