import React from 'react'
import 'src/components/settings/widgets/review_widgets/RequestAnAppointment.scss'

const RequestAnAppointment = ({ width = '200px'}) => {
  return (
    <a style={{textDecoration: 'none', marginBottom: '-4px'}}>
      <img src='https://customerlobby-widget-images.s3.amazonaws.com/v2/appointment_widget.png' style={{ width }} />
    </a>
  )
}

export default RequestAnAppointment
