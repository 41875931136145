import React from "react";
import { SOURCES } from "src/shared/Constants";
import NumberUtil from "src/utils/NumberUtil";
import classnames from "classnames";

const TrackCardOne = ({
  setNullifySorting,
  sourceEl,
  state,
  setState,
  label,
  count,
  image,
}) => {
  // methods
  const handleStyles = () => {
    let counterSource = state.source;
    if (state.source === SOURCES.KIOSK) counterSource = SOURCES.HANDWRITTEN;

    if (sourceEl === counterSource)
      return {
        border: "solid 1.5px #f47821",
        padding: "24.25px",
        background: "rgba(126, 126, 126, 0.05)",
      };
  };

  const handleNothing = () => {
    if (count === 0) return { opacity: 0.6 };
  };

  const handleClick = () => {
    if (count === 0 && sourceEl !== "Email") return null;
    setNullifySorting(true);

    setState({
      ...state,
      source: sourceEl,
      filter: "all",
      currMetadataSelector: "All Review Invites",
    });
  };

  const countStr = NumberUtil.toK(count, 1);

  // render
  return (
    <div
      onClick={handleClick}
      className="track-reviews-card"
      style={{
        ...handleStyles(),
        ...handleNothing(),
        cursor: count === 0 ? "not-allowed" : "pointer",
      }}
    >
      <div className="left-container">
        <div
          className={classnames("count", {
            lengthy: countStr.length > 3,
          })}
        >
          {countStr}
        </div>
        <div className={classnames("label")}>{label}</div>
      </div>
      <img className="track-overview-img" src={image} alt="Track Overview" />
    </div>
  );
};

export default TrackCardOne;
