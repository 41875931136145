import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ColumnChart from "./ColumnChart";
import { MEDIUM_CHART_HEIGHT } from "src/constants/ReviewResults";
import { QUERY_REVIEW_VS_INVITES } from "src/graphql/reviews/results/queries";
import { useQuery } from "@apollo/react-hooks";
import BlockUi from "react-block-ui";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";

const InvitesVSReviews = ({ companyId, filter, fromDate, toDate }) => {
  // state
  const [noData, setNoData] = useState(false);
  const [invitesVSReviews, setInvitesVSReviews] = useState({
    categories: ["Email", "Review Me", "Customer Calls"],
    data: [
      {
        name: "Invitations Sent",
        data: [0, 0, 0],
        color: "#f47821"
      },
      {
        name: "Reviews Received",
        data: [0, 0, 0],
        color: "#5d7ae1"
      }
    ]
  });

  // call apis
  const { loading, data } = useQuery(QUERY_REVIEW_VS_INVITES, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });

  // effects
  useEffect(() => {
    if (data && data.reviewInviteSummary) {
      const newInvitesVSReviews = { ...invitesVSReviews };
      newInvitesVSReviews.data[0].data = [];
      newInvitesVSReviews.data[1].data = [];
      data.reviewInviteSummary.forEach(summary => {
        newInvitesVSReviews.data[0].data.push(summary.invites);
        newInvitesVSReviews.data[1].data.push(summary.reviews);
      });
      const total = data.reviewInviteSummary.reduce(
        (acc, val) => acc + val.invites + val.reviews,
        0
      );
      setNoData(total === 0);
      setInvitesVSReviews(newInvitesVSReviews);
    }
  }, [data]);

  // render
  return (
    <BlockUi blocking={loading}>
      <ColumnChart
        noData={noData}
        chartId="invitesVsReviews"
        title="Invites vs Reviews"
        loading={loading}
        popupInfoMsg="This shows the number of invites sent vs. reviews submitted, by source for the selected date range."
        categories={invitesVSReviews.categories}
        chartHeight={MEDIUM_CHART_HEIGHT}
        chartData={invitesVSReviews.data}
        tooltipFormatter={function() {
          const { point } = this;
          const { data } = this.series;
          const total = data.reduce((acc, val) => acc + val.y, 0);
          return `${fromDate ? fromDate : ""}${
            toDate && toDate !== fromDate ? ` to ${toDate}` : ""
          }
          <br />${point.name || point.series.name}: ${point.y}
          <br />Total: ${total}`;
        }}
      />
    </BlockUi>
  );
};

InvitesVSReviews.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  fromDate: PropTypes.string,
  toDate: PropTypes.string
};

export default InvitesVSReviews;
