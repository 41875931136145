/**
 * Session Helper for the application.
 */
import cookie from "react-cookies";
import { COOKIES } from "./Config";
import { ffConstants } from "src/shared/Constants";

/**
 * Sets the current session context for the application.
 * @param data
 */
export function setContext(data) {
  localStorage.setItem("token", data.token);
  localStorage.setItem("isLoggedIn", true);
  localStorage.setItem("company_id", data.company_id);
  localStorage.setItem("company_name", data.company_name);
  localStorage.setItem("user_first_name", data.user_first_name);
  localStorage.setItem("user_last_name", data.user_last_name);
  localStorage.setItem("user_id", data.user_id);
  localStorage.setItem("email", data.email);
  localStorage.setItem("account_class", data.account_class);
  localStorage.setItem("user_type", data.is_manager);
  localStorage.setItem("account_level_id", data.account_level_id);
}

/**
 * Removes the current session context of the application.
 * @param url
 */
export function removeContext() {
  localStorage.removeItem("token");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("company_id");
  localStorage.removeItem("company_name");
  localStorage.removeItem("user_first_name");
  localStorage.removeItem("user_last_name");
  localStorage.removeItem("user_id");
  localStorage.removeItem("email");
  localStorage.removeItem("account_class");
  localStorage.removeItem("user_type");
  localStorage.removeItem("account_level_id");
  localStorage.setItem("lastPath", "");
}

export function getUserFirstName() {
  return localStorage.getItem("user_first_name") || "";
}

export function getUserLastName() {
  return localStorage.getItem("user_last_name") || "";
}

export function getCompanyName() {
  return localStorage.getItem("company_name") || "";
}

export function getCompanyId() {
  return localStorage.getItem("company_id") || "";
}

export function getUserId() {
  return localStorage.getItem("user_id") || "";
}

export function getEmail() {
  return localStorage.getItem("email") || "";
}

export function getToken() {
  return localStorage.getItem("token") || null;
}

export function getAccountClass() {
  return localStorage.getItem("account_class") || null;
}

export function getUserType() {
  return localStorage.getItem("user_type" || null);
}

export function isLoggedIn() {
  if (getToken() && localStorage.getItem("isLoggedIn")) {
    return true;
  }
  return false;
}

export function setLastPath(path) {
  localStorage.setItem("lastPath", path);
}

export function getLastPath() {
  return localStorage.getItem("lastPath");
}

export function setDetailsShown(isShown) {
  return localStorage.setItem("detailsShown", isShown ? "true" : "false");
}

export function setCookie(key, value, domain = "", path = "/") {
  cookie.save(key, value, { path: path, domain: `.${domain}` });
}

export function loadCookie(key) {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  return cookie.load(key, { path: "/", domain: `.${appDomain}` });
}

export function removeCookie(key) {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  cookie.remove(key, { path: "/", domain: `.${appDomain}` });
}

export function removeCookies() {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  const options = { path: "/", domain: `.${appDomain}`, expires: new Date() };
  cookie.remove(COOKIES.AUTH_TOKEN, options);
  cookie.remove(COOKIES.COMPANY_ID, options);
  cookie.remove(COOKIES.USER_ID, options);
  cookie.remove(COOKIES.USER_NAME, options);
  cookie.remove(COOKIES.HIDE_DC_TOAST_KEY, options);
  cookie.remove(COOKIES.HIDE_CC_TOAST_KEY, options);
  cookie.remove(COOKIES.USER_TYPE, options);
}

export const showAuthHeader = {
  headers: { Authorization: loadCookie(COOKIES.AUTH_TOKEN) },
};
export const pulsem = loadCookie(COOKIES.IS_PULSEM) === "true" ? true : false;
export const showCompanyId = loadCookie(COOKIES.COMPANY_ID);
export const showIsActive = loadCookie(COOKIES.IS_ACTIVE);
export const showIsManager = loadCookie(COOKIES.IS_MANAGER);
export const showUserId = loadCookie(COOKIES.USER_ID);
export const ghostUser = loadCookie(COOKIES.GHOST_USER);
export const showUsername = loadCookie(COOKIES.USER_NAME);
export const userType = loadCookie(COOKIES.USER_TYPE);
export const acceptsTerms =
  loadCookie(COOKIES.ACCEPT_TERMS) === "true" ? true : false;

// Feature Flags
const featureFlagsString = loadCookie(COOKIES.COMPANY_FEATURE_FLAGS);
const companyFeatureFlags = featureFlagsString
  ? JSON.parse(featureFlagsString)
  : null;

/**
 * Returns true or false based on company feature flag value.
 * @param {*} component -- Component name
 * @param {*} operation -- operation (write or read)
 */
export const getCompanyFeatureFlags = (
  component,
  operation,
  featureFlags = companyFeatureFlags,
) => {
  let featureFlag = false;
  if (featureFlags) {
    let companyFeatureFlag = featureFlags.find(
      (item) => item.component === component && item.operation === operation,
    );
    featureFlag = companyFeatureFlag ? companyFeatureFlag.enabled : false;
  }

  return featureFlag;
};

// Set preliminary objects to be used in setting feature flags
const textingWrite = getCompanyFeatureFlags(
  ffConstants.texting,
  ffConstants.write,
);
// Appointment Flags
const appointmentRead = getCompanyFeatureFlags(
  ffConstants.appointment,
  ffConstants.read,
);
const appointmentWrite = getCompanyFeatureFlags(
  ffConstants.appointment,
  ffConstants.write,
);
const customAppointmentWrite = getCompanyFeatureFlags(
  ffConstants.customAppointment,
  ffConstants.write,
);
const customerlobbyAppointmentWrite = getCompanyFeatureFlags(
  ffConstants.customerlobbyAppointment,
  ffConstants.write,
);

// Campaign Flags
const campaignRead = getCompanyFeatureFlags(
  ffConstants.campaign,
  ffConstants.read,
);
const campaignWrite = getCompanyFeatureFlags(
  ffConstants.campaign,
  ffConstants.write,
);
const emailCampaignRead = getCompanyFeatureFlags(
  ffConstants.emailCampaign,
  ffConstants.read,
);
const emailCampaignWrite = getCompanyFeatureFlags(
  ffConstants.emailCampaign,
  ffConstants.write,
);
const postcardCampaignRead = getCompanyFeatureFlags(
  ffConstants.postcardCampaign,
  ffConstants.read,
);
const postcardCampaignWrite = getCompanyFeatureFlags(
  ffConstants.postcardCampaign,
  ffConstants.write,
);
const textCampaignRead = getCompanyFeatureFlags(
  ffConstants.textCampaign,
  ffConstants.read,
);

const textCampaignWrite =
  textingWrite &&
  getCompanyFeatureFlags(ffConstants.textCampaign, ffConstants.write);

// Message Flags
const messageRead = getCompanyFeatureFlags(
  ffConstants.message,
  ffConstants.read,
);
const messageWrite =
  textingWrite &&
  getCompanyFeatureFlags(ffConstants.message, ffConstants.write);
const autoResponseWrite = getCompanyFeatureFlags(
  ffConstants.autoResponse,
  ffConstants.write,
);
const autoResponseAllWrite = getCompanyFeatureFlags(
  ffConstants.autoResponseAll,
  ffConstants.write,
);
const autoResponseAfterHoursWrite = getCompanyFeatureFlags(
  ffConstants.autoResponseAfterHours,
  ffConstants.write,
);
const messageTemplatesWrite = getCompanyFeatureFlags(
  ffConstants.messageTemplates,
  ffConstants.write,
);
const quickReplyWrite = getCompanyFeatureFlags(
  ffConstants.quickReply,
  ffConstants.write,
);

const mobileAppWrite = getCompanyFeatureFlags(
  ffConstants.mobileApp,
  ffConstants.write,
);

// Review Flags
export const reviewRead = getCompanyFeatureFlags(
  ffConstants.review,
  ffConstants.read,
);
export const reviewWrite = getCompanyFeatureFlags(
  ffConstants.review,
  ffConstants.write,
);
const autoEmailInvitationWrite = getCompanyFeatureFlags(
  ffConstants.autoEmailInvitation,
  ffConstants.write,
);
const autoQueueCustomerCallsWrite = getCompanyFeatureFlags(
  ffConstants.autoQueueCustomerCalls,
  ffConstants.write,
);
const ignoreAutoInvitesForCommercialWrite = getCompanyFeatureFlags(
  ffConstants.ignoreAutoInvitesForCommercial,
  ffConstants.write,
);
const invitationDelayWrite = getCompanyFeatureFlags(
  ffConstants.invitationDelay,
  ffConstants.write,
);
const smartCallQueuinglWrite = getCompanyFeatureFlags(
  ffConstants.smartCallQueuing,
  ffConstants.write,
);
const smartInviteWrite = getCompanyFeatureFlags(
  ffConstants.smartInvite,
  ffConstants.write,
);
const prospectMarketingWrite = getCompanyFeatureFlags(
  ffConstants.prospect,
  ffConstants.write,
);

const magnetMailerWrite = getCompanyFeatureFlags(
  ffConstants.magnetMailer,
  ffConstants.write,
);

// The data is flat for convenience, but there is a hierarchy noted in comments
export const featureFlags = {
  // Appointment
  appointmentRead: appointmentRead || appointmentWrite,
  appointmentWrite: appointmentWrite,
  // Appointment > Custom Appointment
  customAppointmentWrite: appointmentWrite && customAppointmentWrite,
  // Appointment > Customer Lobby Appointment
  customerlobbyAppointmentWrite:
    appointmentWrite && customerlobbyAppointmentWrite,

  // Campaign
  campaignRead: campaignRead || campaignWrite,
  campaignWrite: campaignWrite,
  // Campaign > Email Campaign
  emailCampaignRead:
    (campaignRead || campaignWrite) &&
    (emailCampaignRead || emailCampaignWrite),
  emailCampaignWrite: campaignWrite && emailCampaignWrite,
  // Campaign > Postcard Campaign
  postcardCampaignRead:
    (campaignRead || campaignWrite) &&
    (postcardCampaignRead || postcardCampaignWrite),
  postcardCampaignWrite: campaignWrite && postcardCampaignWrite,
  // Campaign > Text Campaign
  textCampaignRead:
    (campaignRead || campaignWrite) && (textCampaignRead || textCampaignWrite),
  textCampaignWrite: campaignWrite && textCampaignWrite,

  // Message
  messageRead: messageRead || messageWrite,
  messageWrite: messageWrite,
  // Message > Message Templates
  messageTemplatesWrite: messageWrite && messageTemplatesWrite,
  // Message > Quick Reply
  quickReplyWrite: messageWrite && quickReplyWrite,
  // Message > Auto Response
  autoResponseWrite: messageWrite && autoResponseWrite,
  // Message > Auto Response > Auto Response After Hours
  autoResponseAfterHoursWrite:
    messageWrite && autoResponseWrite && autoResponseAfterHoursWrite,
  // Message > Auto Response > Auto Response All
  autoResponseAllWrite:
    messageWrite && autoResponseWrite && autoResponseAllWrite,

  // Mobile App
  mobileAppWrite: mobileAppWrite,

  // Review
  reviewRead: reviewRead || reviewWrite,
  reviewWrite: reviewWrite,
  // Review > Auto Email Invitation
  autoEmailInvitationWrite: reviewWrite && autoEmailInvitationWrite,
  // Review > Auto Queue Customer Calls
  autoQueueCustomerCallsWrite: reviewWrite && autoQueueCustomerCallsWrite,
  // Review > Ignore Auto Invites For Commercial
  ignoreAutoInvitesForCommercialWrite:
    reviewWrite && ignoreAutoInvitesForCommercialWrite,
  // Review > Invitation Delay
  invitationDelayWrite: reviewWrite && invitationDelayWrite,
  // Review > Smart Call Queuing
  smartCallQueuinglWrite: reviewWrite && smartCallQueuinglWrite,
  // Review > Smart Invite
  smartInviteWrite: reviewWrite && smartInviteWrite,

  // Texting
  textingWrite: textingWrite,

  // Prospect
  prospectMarketingWrite: prospectMarketingWrite,

  //magnetMailer
  magnetMailerWrite:magnetMailerWrite
};
