import { gql } from "apollo-boost";

const SEGMENTS = gql`
  query($contactId: ID!, $companyId: ID!) {
    getContactsSegments(contactId: $contactId) {
      name
      id
    }
    filters(companyId: $companyId) {
      name
      explanation
      customersCount
      metaData
      id
    }
  }
`;

const CONTACT_REVIEW_SUMMARY = gql`
  query($contactId: ID!) {
    contactReviewSummary(contactId: $contactId) {
      reviewsCount
      averageRating
      sentimentScore
      unhappyFeedbackCount
    }
  }
`;

const GET_ES_CONTACT = gql`
  query($contactId: ID!, $esContact: Boolean) {
    contact(contactId: $contactId, esContact: $esContact) {
      email
      firstName
      lastName
      formattedPhoneDaytime
      formattedPhoneMobile
      formattedPhoneAlt
      contactDisplayName
      wantsSms
      address1
      address2
      city
      state
      zip
      inContract
      allTransactionsCount
      allTransactionsAmount
      lastServiceDate
      formattedLastServiceDate
    }
  }
`;

const GET_CONTACT = gql`
  query($contactId: ID!) {
    contact(contactId: $contactId) {
      address1
      address2
      city
      contactDisplayName
      contactId
      email
      firstName
      formattedPhoneAlt
      formattedPhoneDaytime
      formattedPhoneMobile
      inContract
      lastName
      name
      state
      wantsCalls
      wantsEmail
      wantsMail
      wantsSms
      zip
    }
  }
`;

const CONTACT_COMMUNICATIONS_LIST = gql`
  query contactCommunicationsList($contactId: ID!, $days: Int) {
    contactCommunicationsList(contactId: $contactId, days: $days) {
      communications {
        campaignId
        campaignName
        pieceId
        channel
        iterationId
        resultsUrl
        createdAt
      }
      limit
      total
      page
    }
  }
`;

const QUERY_CONVERSATION = gql`
  query conversation($contactId: ID!, $companyId: ID!, $page: Int) {
    companySettings(companyId: $companyId) {
      companyId
      twilioPhone
    }
    conversation(contactId: $contactId, companyId: $companyId, page: $page) {
      after
      noMore
      drafts {
        id
        conversationFormattedDate
        companyId
        contactId
        contactDisplayName
        sourcePhone
        destinationPhone
        formattedDate
        content
        status
        viewed
        deleted
        category
        createdAt
        updatedAt
      }
      conversation {
        id
        companyId
        contactId
        sourcePhone
        contactDisplayName
        desktopTime
        campaignName
        formattedDate
        content
        status
        viewed
        deleted
        category
        createdAt
        updatedAt
        contact {
          firstName
          lastName
          contactId
        }
      }
    }
  }
`;

const QUERY_CONVERSATIONS = gql`
  query conversations(
    $companyId: ID!
    $searchTerm: String
    $page: Int
    $disposition: String!
  ) {
    conversations(
      companyId: $companyId
      page: $page
      disposition: $disposition
      searchTerm: $searchTerm
    ) {
      companyId
      category
      content
      createdAt
      viewed
      status
      conversationFormattedDate
      desktopInboxDate
      contact {
        companyId
        contactId
        firstName
        formattedPhoneMobile
        formattedPhoneDaytime
        lastName
        starred
        contactDisplayName
        inContract
        formattedPhoneDaytime
        formattedPhoneMobile
        formattedPhoneAlt
        allTransactionsCount
        allTransactionsAmount
        lastServiceDate
      }
    }
  }
`;

const QUERY_QUICK_REPLIES = gql`
  query quickReplies($companyId: ID!) {
    companyFeatureFlags(companyId: $companyId)
    listQuickReplyTemplates(companyId: $companyId) {
      id
      name
      content
      enabled
    }
  }
`;

const QUERY_MESSAGE_TEMPLATES = gql`
  query messageTemples($companyId: ID!) {
    companySettings(companyId: $companyId) {
      twilioPhone
    }
    companyFeatureFlags(companyId: $companyId)
    listTextMessageTemplates(companyId: $companyId) {
      id
      title
      content
      enabled
      defaultTemplate {
        id
        title
        content
      }
    }
    liquidVars {
      vars
    }
  }
`;

const LIST_MESSAGING_TEMPLATES = gql`
  query listMessagingTemplates($companyId: ID!, $contactId: ID!) {
    listQuickReplyTemplates(companyId: $companyId) {
      id
      companyId
      name
      content
      enabled
      defaultTemplate {
        id
        content
        name
      }
    }

    listTextMessageTemplates(companyId: $companyId) {
      id
      companyId
      title
      content
      enabled
      defaultTemplate {
        id
        title
        content
        isDefault
      }
    }

    listLiquidVariables(
      companyId: $companyId
      contactId: $contactId
      source: "web_text"
    )
  }
`;

const GET_COMPANY_INFORMATION = gql`
  query($companyId: ID!) {
    getCompanyInformation(companyId: $companyId) {
      setting {
        twilioPhone
        autoResponseContent
      }
    }
    company(companyId: $companyId) {
      companyName
    }
    companyFeatureFlags(companyId: $companyId)
  }
`;

const QUERY_MESSAGING_COUNTS = gql`
  query messagingCounts($companyId: ID!) {
    messagingCounts(companyId: $companyId) {
      unreadMessages
      allMessages
      starredMessages
      draftMessages
    }
  }
`;

const QUERY_SEARCH_CONTACTS = gql`
  query searchContacts($query: String, $companyId: ID!) {
    searchContacts(query: $query, companyId: $companyId) {
      contacts {
        contactId
        firstName
        lastName
        companyId
        wantsSms
        contactDisplayName
        inContract
        formattedPhoneDaytime
        formattedPhoneMobile
        formattedPhoneAlt
        allTransactionsCount
        allTransactionsAmount
        lastServiceDate
      }
    }
  }
`;

export {
  SEGMENTS,
  GET_CONTACT,
  GET_ES_CONTACT,
  CONTACT_COMMUNICATIONS_LIST,
  CONTACT_REVIEW_SUMMARY,
  LIST_MESSAGING_TEMPLATES,
  GET_COMPANY_INFORMATION,
  QUERY_CONVERSATIONS,
  QUERY_QUICK_REPLIES,
  QUERY_MESSAGE_TEMPLATES,
  QUERY_MESSAGING_COUNTS,
  QUERY_SEARCH_CONTACTS,
  QUERY_CONVERSATION,
};
