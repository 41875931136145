import React, { useState } from "react";
import "./ReviewDetails.scss";
import Switch from "react-switch";
import { SWITCH_COLOR_CONFIG } from "../../../../../../shared/Config";
import { COMPANY_USER } from "../../../../../../shared/Constants";
import { createReviewCommentHelper } from './review_details_mutation_actions'

/**
 * Review Comment component
 */
const ReviewComment = ({ reviewId,
                         reviewChanged,
                         setReviewChanged,
                         setReview,
                         review,
                         handleSuccess,
                         companyUserId,
                         authHeader
                        }) => {
  const [ comment, setComment ] = useState("")
  const [ emailReviewer, setEmailReviewer ] = useState(false)

  const handleSwitch = (e) => {
    setEmailReviewer(!emailReviewer)
  }

  const handleSubmit = async() => {
    let userType = COMPANY_USER

    let res = await createReviewCommentHelper({
      reviewId,
      comment,
      emailReviewer,
      userId: companyUserId,
      userType,
      authHeader,
    })

    let oldReview = review
    let newComment = { comment}

    oldReview.comments.unshift(comment)

    setComment("")
    setReview(oldReview)
    setReviewChanged(!reviewChanged)
    handleSuccess('The review comment has been added')
  }

  const handleBtnState = () => {
    if(comment.length > 0) return (
      <button type="submit" onClick={handleSubmit} className="btn btn-primary">
        Add Comment
      </button>
    )

    return (
      <button type="submit" className="btn btn-primary disabled-btn">
        Add Comment
      </button>
    )
  }

  return (
    <div id="review-details-comment">
      <div className="row">
        <div className="col-12 pl-4 pr-4">
          <div className="mt-3 ml-1">
            <div className="row">
              <div className="col-12">
                <label className="required-field no-text-transform label-style">Write a comment</label>

                <textarea
                  rows="3"
                  className="form-control mt-0"
                  name="comment"
                  placeholder="Write a comment below and it will be published with this review."
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 col-12 pt-1">
                <div className="row">
                  <div className="col-9 email-review-author mt-md-1 d-flex align-items-center">
                    <span className="mr-3">Send Email to Review Author</span>
                    <Switch
                      onColor={SWITCH_COLOR_CONFIG.onColor}
                      offColor={SWITCH_COLOR_CONFIG.offColor}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      checked={emailReviewer}
                      onChange={(e) => handleSwitch(e)}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className="row mb-3 mt-3">
              <div className="col-12">
                {handleBtnState()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewComment;
