import React from "react";
import "./SearchInputWithDropdown.scss";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import PropTypes from "prop-types";

/**
 * Search component with input to search the term and dropdown for search types.
 * @param {props} - Properties to get the inputs for the component.
 * @returns - Set the search term and search Type.
 */
const SearchInputWithDropdown = ({
  searchTerm = "",
  searchType = {},
  setSearchTerm,
  setSearchType,
  searchTypeOptions = [],
  maxLength,
}) => {
  /**
   * Search type Handler.
   * @param {*} item -- Search type selected.
   */
  const searchTypeHandler = (item) => {
    if (searchType && item && searchType.value !== item.value) {
      setSearchTerm("");
    }
    setSearchType(item);
  };

  return (
    <div className="search-section">
      <div className="search-input">
        <SearchInput
          maxLength={maxLength}
          value={searchTerm}
          onChange={(evt) => setSearchTerm(evt.target.value)}
          placeholder={`Search by ${searchType &&
            searchType.label &&
            searchType.label.toLowerCase()}`}
        />
      </div>
      <div className="search-toggle">
        <div className="search-separator"></div>
        <DropdownButton
          alignRight={true}
          direction="down"
          dropShadow={true}
          variant="link"
        >
          {searchTypeOptions.map((item) => (
            <Dropdown.Item
              href="#"
              key={item.value}
              onClick={() => searchTypeHandler(item)}
              className={`${item.value === searchType.value ? "selected" : ""}`}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </div>
  );
};

// PropTypes of the component.
SearchInputWithDropdown.propTypes = {
  searchTerm: PropTypes.string,
  searchType: PropTypes.object,
  setSearchTerm: PropTypes.func,
  setSearchType: PropTypes.func,
  searchTypeOptions: PropTypes.array,
};

export default SearchInputWithDropdown;
