export const SORT_BY = [
  { label: "Last Modified", value: "updated_at" },
  { label: "Last Added", value: "created_at" },
];

export const FILTER_BY_TYPE = [
  { label: "All", value: "all" },
  { label: "Missing Email Address", value: "missing_email" },
  { label: "Missing Mailing Address", value: "missing_mailing" },
  { label: "Missing Mobile Number", value: "missing_number" },
  { label: "Invalid Email Address", value: "invalid_email" },
  { label: "Invalid Mailing Address", value: "invalid_mailing" },
  { label: "Invalid Mobile Number", value: "invalid_number" },
  { label: "Unsubscribed From Email", value: "unsubscribed_email" },
  { label: "Unsubscribed From Sms", value: "unsubscribed_sms" },
  { label: "Unsubscribed From Mail", value: "unsubscribed_mail" },
  { label: "Unsubscribed From Call", value: "unsubscribed_call" },
];

export const HEADER_ITEMS = [
  { columnLabel: "NAME", columnValue: "first_name", sortable: true },
  { columnLabel: "EMAIL ADDRESS", columnValue: "email", sortable: true },
  { columnLabel: "PHONE NUMBER", columnValue: "phone", sortable: false },
  {
    columnLabel: "MAILING ADDRESS",
    columnValue: "address",
    sortable: true,
  },
  {
    columnLabel: "LAST SERVICE DATE",
    columnValue: "last_service_date",
    sortable: true,
  },
  {
    columnLabel: "RECENT ACTIVITY",
    columnValue: "recent_activity",
    sortable: true,
  },
  { columnLabel: "CREATED DATE", columnValue: "created_at", sortable: true },
  { columnLabel: "", columnValue: "", sortable: false },
];

export const SEARCH_BY_TYPE_OPTIONS = [
  { label: "Name, Email, Phone", value: "name" },
  { label: "ZIP code", value: "zip" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
];

export const SOURCES = [
  { label: "direct", value: "direct_connect" },
  { label: "uploads", value: "csv_upload" },
  { label: "custom", value: "custom" },
  { label: "web", value: "web" },
  { label: "handwritten", value: "handwritten" },
  { label: "kiosk", value: "kiosk" },
  { label: "manual", value: "manual_input" },
  { label: "ncoa", value: "ncoa" },
  { label: "review me", value: "reviewme" },
  { label: "unknown", value: "unknown" },
];

export const SEGMENT_SORT_BY = [
  { label: "Recently Used", value: "recently_used" },
  { label: "Last Modified", value: "updated_at" },
];
