import React from "react";
import { showCompanyId } from "src/shared/SessionHelper";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center jumbotron">
          <div>
            <h3>
              <i className="fa fa-warning text-orange"></i> Oops! An error
              occurred.
            </h3>
            <p>
              Meanwhile, you may{" "}
              <a href={`/companies/${showCompanyId}/dashboard`}>
                return to dashboard
              </a>
              .
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
