import React, { useState, useEffect } from "react";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import TextingAccordion from "src/shared/styleguide/accordions/TextingAccordion";
import "./RecentCampaigns.scss";
import { useQuery } from "@apollo/react-hooks";
import { CONTACT_COMMUNICATIONS_LIST } from "src/graphql/texting/queries";
import PropTypes from "prop-types";
import { featureFlags } from "src/shared/SessionHelper";

const Icon = ({ iconLetter, message }) => {
  return (
    <PopupInfo
      message={message}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
        >
          <path
            fill="#7e7e7e"
            d="M8.5 0A8.5 8.5 0 1 0 17 8.5 8.51 8.51 0 0 0 8.5 0zm0 15.455A6.955 6.955 0 1 1 15.455 8.5 6.962 6.962 0 0 1 8.5 15.455z"
          />
          <text
            fill="#7e7e7e"
            fontFamily="ProximaNova-Bold, Proxima Nova"
            fontSize="10px"
            fontWeight="700"
            letterSpacing="-0.01em"
            transform="translate(9 12)"
          >
            <tspan x="-3.12" y="0">
              {iconLetter}
            </tspan>
          </text>
        </svg>
      }
    />
  );
};

const CampaignStrip = ({ content, iconLetter, message, resultsUrl }) => {
  return (
    <div className="d-flex align-items-center campaign-strip-container">
      <a
        href={resultsUrl}
        rel="noopener noreferrer"
        target="_blank"
        className="campaign-strip-text"
      >
        {content}
      </a>
      <Icon iconLetter={iconLetter} message={message} />
    </div>
  );
};
const RecentCampaigns = ({ contactId }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [hideInfo, setHideInfo] = useState(false);
  const [contactCommunications, setContactCommunications] = useState([]);
  const [communicationsIndex, setCommunicationsIndex] = useState(5);

  const res = useQuery(CONTACT_COMMUNICATIONS_LIST, {
    variables: { contactId, days: 180 },
  });

  useEffect(() => {
    if (!res.loading && res.data && res.data.contactCommunicationsList) {
      setContactCommunications(
        res.data.contactCommunicationsList.communications,
      );
    }
  }, [res]);

  const dateHelper = (createdAt) => {
    const date = new Date(createdAt);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;

    return `${month}/${day}`;
  };

  const handleSeeMoreClick = () => {
    setSeeMore(!seeMore);
    setCommunicationsIndex(contactCommunications.length);
  };

  const handleViewLessClick = () => {
    setSeeMore(!seeMore);
    setCommunicationsIndex(5);
  };

  const renderHelper = () => {
    if (contactCommunications.length > 0)
      return (
        <React.Fragment>
          {contactCommunications
            .slice(0, communicationsIndex)
            .map((contactCommunication) => {
              return (
                <CampaignStrip
                  key={`contact-communication-${contactCommunication.campaignId}`}
                  content={`${dateHelper(contactCommunication.createdAt)} - ${
                    contactCommunication.campaignName
                  }`}
                  iconLetter={contactCommunication.channel[0].toUpperCase()}
                  message={
                    contactCommunication.channel[0].toUpperCase() === "T"
                      ? "Text Campaign"
                      : contactCommunication.channel[0].toUpperCase() === "E"
                      ? "Email Campaign"
                      : "Postcard Campaign"
                  }
                  resultsUrl={contactCommunication.resultsUrl}
                />
              );
            })}
          {contactCommunications.length > 5 ? (
            seeMore ? (
              <div
                onClick={handleSeeMoreClick}
                className="campaign-strip-text see-more-button"
              >
                See More...
              </div>
            ) : (
              <div
                onClick={handleViewLessClick}
                className="campaign-strip-text see-more-button"
              >
                - View less
              </div>
            )
          ) : null}
        </React.Fragment>
      );

    return <div className="no-campaigns">No campaigns</div>;
  };

  return featureFlags.campaignRead ? (
    <div className="recent-campaigns-container">
      <TextingAccordion
        isDefaultActiveKey={false}
        callback={() => setHideInfo(!hideInfo)}
        label={
          hideInfo || contactCommunications <= 0 ? (
            <div>Recent Campaigns</div>
          ) : (
            <div className="d-flex align-items-center">
              <div className="mr-2">Recent Campaigns</div>
              <PopupInfo message="List of recent campaigns this customer was a part of, in last 3 months." />
            </div>
          )
        }
        eventKey="1"
        labelStyles={{
          fontSize: "1.2rem",
          color: "#313541",
        }}
      >
        {renderHelper()}
      </TextingAccordion>
    </div>
  ) : null;
};

RecentCampaigns.propTypes = {
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RecentCampaigns;
