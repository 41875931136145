import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
  name: "company",
  initialState: {
    details: null,
    subscriptionDetails: null,
  },
  reducers: {
    setCompanyDetails: (state, action) => {
      state.details = action.payload;
    },
    setSubscriptionDetails: (state, action) => {
      state.subscriptionDetails = action.payload;
    },
  },
});

export const { setCompanyDetails, setSubscriptionDetails } =
  companySlice.actions;
export default companySlice.reducer;
