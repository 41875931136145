import { GET_REVIEWS_BY_SOURCE } from "./queries";
import { API_GRAPHQL_URL } from "../../../../../../shared/Config";
import axios from "axios";

export const reviewsBySource = async ({ companyId, dateRange, authHeader }) => {
  const queryParams = {
    query: GET_REVIEWS_BY_SOURCE,
    variables: {
      companyId,
      dateFrom: dateRange.value,
      dateBucket: dateRange.bucket,
    },
  };
  try {
    let {
      data: { data },
    } = await axios.post(API_GRAPHQL_URL, queryParams, authHeader);
    return data;
  } catch (e) {
    return null;
  }
};
