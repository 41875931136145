import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import notesIcon from "./notesIcon.svg";
import "./ReadMore.scss";

let globalSetContentHeight = (el) => {};

const UpdatingPopover = React.forwardRef((render, ref) => {
  React.useEffect(() => {
    globalSetContentHeight(render.contentref.current.clientHeight);
  }, []);

  React.useEffect(() => {
    render.scheduleUpdate();
  }, [render]);

  return (
    <Popover ref={ref} {...render}>
      {render.children}
    </Popover>
  );
});

export const ReadMoreElements = ({
  className = "",
  content = "",
  icon = <img src={notesIcon} />,
  maxMoreHeight = 460,
  maxLessHeight = 250,
  minWidth = 380,
  placement = "left",
  rootClose = false,
  title = "Notes",
  trigger = "click",
  zIndex = "unset",
  ...props
}) => {
  // state
  let overlayRef = useRef(null);
  const contentRef = useRef(null);
  const [height, setContentHeight] = useState(0);
  const [myContent, setMyContent] = useState(content);
  const [readMore, setReadMore] = useState(false);

  // variables
  globalSetContentHeight = setContentHeight;

  // effects
  useEffect(() => {
    /**
     *  We always want myContent === content.
     *  We only ever change it to briefly reset y position
     */
    setMyContent(content);
  }, [myContent, content]);

  return (
    <OverlayTrigger
      {...props}
      show={false}
      rootClose={rootClose}
      ref={overlayRef}
      trigger={trigger}
      placement={placement}
      overlay={
        <UpdatingPopover
          className={className}
          style={{
            boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
            border: "1px solid rgba(126, 126, 126, 0.2)",
            minWidth: minWidth,
            marginLeft: 12,
            zIndex: zIndex,
          }}
          contentref={contentRef}
        >
          <Popover.Content>
            <div className="p-2 read-more-notes">
              <div className="font-weight-bold mb-1">
                <span className="read-more-notes-title">{title}</span>
                <div
                  onClick={() => {
                    overlayRef.current.handleHide();
                    // delay readMore change until handleHide() fade out completes
                    setTimeout(() => {
                      setReadMore(false);
                    }, 300);
                  }}
                  className="cursor-pointer float-right inline-block"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="13.999"
                    viewBox="0 0 15 14.999"
                  >
                    <g opacity="0.8" transform="translate(0 -.001)">
                      <path
                        fill="#667587"
                        d="M8.827 7.5l5.9-5.9A.938.938 0 1 0 13.4.276l-5.9 5.9-5.9-5.9A.938.938 0 0 0 .275 1.6l5.9 5.9-5.9 5.9A.938.938 0 1 0 1.6 14.726l5.9-5.9 5.9 5.9a.938.938 0 0 0 1.325-1.326z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div
                className="text-black-50"
                ref={contentRef}
              >
                {height > maxLessHeight ? (
                  <span>
                    <div
                      className="content-container"
                      style={{
                        maxHeight: `${readMore ? maxMoreHeight : maxLessHeight}px`,
                        overflow: `${height > maxLessHeight && readMore === true ? "scroll" : "hidden"}`,
                      }}
                    >
                      {myContent}
                    </div>
                    <div
                      className="sg-text-info cursor-pointer read-more-notes-toggle"
                      style={{ fontWeight: 500 }}
                      onClick={() => {
                        setMyContent(null);
                        setReadMore(!readMore);
                      }}
                    >
                      {`Read ${readMore === true ? "Less" : "More"}`}
                    </div>
                  </span>
                ) : (
                  myContent
                )}
              </div>
            </div>
          </Popover.Content>
        </UpdatingPopover>
      }
    >
      <span className="cursor-pointer"> {icon}</span>
    </OverlayTrigger>
  );
};

ReadMoreElements.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.any,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  placement: PropTypes.string,
  rootClose: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  trigger: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReadMoreElements;
