import { gql } from 'apollo-boost';

const COMPANY_USERS = gql`
  query(
    $companyId: ID!,
    $sortField: String,
    $sortDirection: String,
    $page: Int,
    $showTotal: Boolean,
  ) {
    companyUsers(
      companyId: $companyId,
      sortField: $sortField,
      sortDirection: $sortDirection,
      page: $page,
      showTotal: $showTotal,
    ) {
      users {
        firstName
        lastName
        companyUserId
        isManager
        isActive
        email
        avatarUrl
        ghostUser
        createdAt
        formattedUserDate
      }
      total
    }
  }
`;

const GET_COMPANY_USER = gql`
  query getCompanyUser($companyUserId: ID!) {
    getCompanyUser(companyUserId: $companyUserId) {
      companyUserId
      email
      firstName
      lastName
      companyUserId
      isManager
      isActive
      createdAt
      avatarUrl
      formattedUserDate
    }
  }
`

const GET_MY_PROFILE = gql`
  query($companyUserId: ID!) {
    getCompanyUser(companyUserId: $companyUserId) {
      companyUserId
      email
      firstName
      lastName
      userDisplayName
      isManager
      isActive
      avatarUrl
      formattedPhone
      jobTitle
      phoneMobile
      formattedAddress
      company {
        country
        companyName
      }
    }
  }
`

export {
  COMPANY_USERS,
  GET_MY_PROFILE,
  GET_COMPANY_USER,
};
