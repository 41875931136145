import React, { useRef, useEffect } from "react";
import Options from "./Options";
import StringUtil from "src/utils/StringUtil";

const Row = ({
  image,
  companyId,
  handleInvitations,
  contact,
  source,
  initials,
  name,
  email,
  uniqueKey,
  dateOne,
  dateTwo,
  authHeader,
  status,
  updateReviewDetailsDisplay,
  updateComponent,
  setUpdateComponent,
  formattedPhoneDaytime,
  formattedPhoneMobile,
  reviewMeActive,
  formattedPhoneAlt,
  searchTerm,
}) => {
  const optionEl = useRef(null);
  const optionImgEl = useRef(null);

  const handleOptionClose = (e) => {
    if (e.target !== optionEl.current && e.target !== optionImgEl.current)
      optionEl.current.style.display = "none";
  };

  const handleOptionOpen = (e) => {
    optionEl.current.style.display = "flex";
  };

  useEffect(() => {
    window.addEventListener("click", handleOptionClose);
    return () => window.removeEventListener("click", handleOptionClose);
  }, []);

  const colorsOfTheRainbow = () => {
    //if (source === 'Reviewme') return { border: "solid 1px #22b5e6", color: "#22b5e6", width: "82px" };

    if (source === "CustomInvites")
      return { border: "solid 1px #22b5e6", color: "#22b5e6", width: "82px" };
    if ((source === "Handwritten" || source === "Kiosk") && status === "sent")
      return { border: "solid 1px #e6a422", color: "#e6a422", width: "80px" };
    if (
      [
        "requested",
        "opened",
        "sent",
        "unhappy",
        "one_time_send",
        "opened_email",
        "opened_link",
        "opened_smart_link",
      ].includes(status)
    )
      return { border: "solid 1px #5fcc29", color: "#5fcc29", width: "92px" };
    if (["reviewed", "completed"].includes(status))
      return { border: "solid 1px #22b5e6", color: "#22b5e6", width: "82px" };
    if (
      ["declined", "unsubscribed"].includes(status) &&
      source === "CustomerCalls"
    )
      return { border: "solid 1px #e6a422", color: "#e6a422", width: "112px" };
    if (["invalid_email", "bounced"].includes(status) && source === "Email")
      return { border: "solid 1px #f47821", color: "#f47821", width: "108px" };
    if (
      [
        "in_progress",
        "scheduled",
        "attempted",
        "pending_credit",
        "queued",
        "ready",
      ].includes(status)
    )
      return { border: "solid 1px #667587", color: "#667587", width: "112px" };
    if (
      ["declined", "unsubscribed"].includes(status) &&
      source === "CustomerCalls"
    )
      return { border: "solid 1px #e64322", color: "#e64322", width: "80px" };
    if ("converted_to_email" === status)
      return { border: "solid 1px #e6a422", color: "#e6a422", width: "112px" };
    if (status === "unsubscribed")
      return { border: "solid 1px #e6a422", color: "#e6a422", width: "112px" };
    if (status === "invalid_email")
      return { border: "solid 1px #f47821", color: "#f47821", width: "108px" };
    if (status === "declined")
      return { border: "solid 1px #e64322", color: "#e64322", width: "80px" };
    if (status === "unresponsive")
      return { border: "solid 1px #b4555e", color: "#b4555e", width: "112px" };
    if (status === "wrong_number")
      return { border: "solid 1px #f47821", color: "#f47821", width: "118px" };
    if (status === "pending")
      return { border: "solid 1px #e6a422", color: "#e6a422", width: "90px" };
    if (status === "confirmed")
      return { border: "solid 1px #22b5e6", color: "#22b5e6", width: "90px" };
    if (status === "bounced")
      return { border: "solid 1px #e64322", color: "#e64322", width: "82px" };
  };

  const cleanPhone = (phone) => {
    if (!phone) return "N/A";

    if (phone[0] === "+") return phone.slice(3);

    return phone;
  };

  const handleStatus = () => {
    // if (source === 'Reviewme') {
    //   return 'REVIEWED'
    // }

    if ((source === "Handwritten" || source === "Kiosk") && status === "sent")
      return "PENDING";
    if (source === "CustomInvites") return "REVIEWED";
    if (
      [
        "requested",
        "opened",
        "sent",
        "one_time_send",
        "opened_email",
        "opened_link",
        "opened_smart_link",
      ].includes(status)
    )
      return "REQUESTED";
    if (["unhappy"].includes(status)) return "FEEDBACK";
    if (["reviewed", "completed"].includes(status)) return "REVIEWED";
    if (["invalid_email", "bounced"].includes(status) && source === "Email")
      return "INVALID EMAIL";
    if (
      ["in_progress", "scheduled", "attempted", "queued", "ready"].includes(
        status,
      )
    )
      return "IN PROGRESS";
    if (
      ["declined", "unsubscribed"].includes(status) &&
      source === "CustomerCalls"
    )
      return "DECLINED";
    if (status === "declined") return "DECLINED";
    if (status === "invalid_email") return "INVALID EMAIL";
    if (status === "unsubscribed") return "UNSUBSCRIBED";
    if (status === "pending_credit") return "PENDING CREDIT";
    if (status === "converted_to_email") return "EMAIL INVITE";
    if (status === "unresponsive") return "UNRESPONSIVE";
    if (status === "wrong_number") return "WRONG NUMBER";
    if (status === "pending") return "PENDING";
    if (status === "confirmed") return "CONFIRMED";
    if (status === "bounced") return "BOUNCED";
  };

  const handleDate = (dateOne) => {
    if (source === "CustomInvites") return "N/A";

    return dateOne || "N/A";
  };

  const handleVia = (email) => {
    if (!email && source !== "CustomerCalls") {
      return "N/A";
    }

    if (source === "Handwritten" || source === "Kiosk") {
      if (email === "sms") {
        return email.toUpperCase();
      }
      return email[0].toUpperCase() + email.slice(1);
    }

    if (source === "Reviewme") {
      if (contact.reviewType === "Email") {
        return contact.email || "N/A";
      }
      return (
        <div>
          {formattedPhoneDaytime ? (
            <div>
              {StringUtil.highlightWithinWord(
                formattedPhoneDaytime,
                searchTerm,
                true,
              )}
            </div>
          ) : null}
          {formattedPhoneMobile ? (
            <div className="text-black-50">
              {StringUtil.highlightWithinWord(
                formattedPhoneMobile,
                searchTerm,
                true,
              )}{" "}
              (mob)
            </div>
          ) : null}
          {formattedPhoneAlt ? (
            <div className="text-black-50">
              {StringUtil.highlightWithinWord(
                formattedPhoneAlt,
                searchTerm,
                true,
              )}{" "}
              (alt.)
            </div>
          ) : null}
          {!formattedPhoneDaytime && !formattedPhoneMobile && !formattedPhoneAlt
            ? "N/A"
            : null}
        </div>
      );
    }

    if (source === "CustomerCalls") {
      return (
        <div>
          {formattedPhoneDaytime ? (
            <div>
              {StringUtil.highlightWithinWord(
                formattedPhoneDaytime,
                searchTerm,
                true,
              )}
            </div>
          ) : null}
          {formattedPhoneMobile ? (
            <div className="text-black-50">
              {StringUtil.highlightWithinWord(
                formattedPhoneMobile,
                searchTerm,
                true,
              )}{" "}
              (mob)
            </div>
          ) : null}
          {formattedPhoneAlt ? (
            <div className="text-black-50">
              {StringUtil.highlightWithinWord(
                formattedPhoneAlt,
                searchTerm,
                true,
              )}{" "}
              (alt.)
            </div>
          ) : null}
          {!formattedPhoneDaytime && !formattedPhoneMobile && !formattedPhoneAlt
            ? "N/A"
            : null}
        </div>
      );
    }

    return email;
  };

  const handleNames = (name) => {
    if (!name) return "N/A";

    let numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "+"];

    if (numbers.includes(name[0])) return "N/A";

    return name;
  };

  const handleMedium = () => {
    if (!contact || (contact && !contact.medium)) return "N/A";

    switch (contact.medium) {
      case "reviewme_email":
        return `Email`;
      case "reviewme_sms":
        return `Text`;
      case "web_text":
        return `Web Text`;
      default:
        return "N/A";
    }
  };

  return (
    <div className="table-row" key={uniqueKey}>
      <div className="row-size name-container">
        <div className="name-inner-container">{handleNames(initials)}</div>
        <div className="name">{handleNames(name)}</div>
      </div>

      <div className="row-size row-text">{handleVia(email)}</div>
      {reviewMeActive() ? (
        <div className="row-size row-text">{handleMedium()}</div>
      ) : null}
      <div className="row-size row-text">{handleDate(dateOne)}</div>
      <div className="row-size row-text">{dateTwo || "N/A"}</div>

      <div className="row-size status-container">
        <div
          style={{ ...colorsOfTheRainbow(), fontSize: "12px" }}
          className="status-text"
        >
          {handleStatus()}
        </div>
        <img
          ref={optionImgEl}
          onClick={handleOptionOpen}
          className="table-img"
          src={image}
          alt="option"
        />
        <Options
          handleInvitations={handleInvitations}
          updateReviewDetailsDisplay={updateReviewDetailsDisplay}
          companyId={companyId}
          contact={contact}
          source={source}
          optionEl={optionEl}
          authHeader={authHeader}
          updateComponent={updateComponent}
          setUpdateComponent={setUpdateComponent}
        />
      </div>
    </div>
  );
};

export default Row;
