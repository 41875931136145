import React from "react";
import Dropdown, {
  DropdownButton
} from "src/shared/styleguide/dropdown/Dropdown";

const DateFilter = ({ filter, filters, setFilter }) => {
  return (
    <DropdownButton
      dropShadow
      alignRight
      title={filter.label}
      dropdownClassName="d-inline-block"
      buttonClassName="text-dark text-decoration-none"
      variant="link"
    >
      {filters.map(filterOpt => (
        <Dropdown.Item
          key={filterOpt.id}
          onClick={() => setFilter(filterOpt)}
          href="#"
          active={filter.id === filterOpt.id}
        >
          {filterOpt.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DateFilter;
