import { gql } from "apollo-boost";

const UPDATE_COMMUNICATION_PREFERENCES = gql`
  mutation(
    $contactId: ID!
    $companyId: ID!
    $memberWantsCall: Boolean
    $memberWantsEmail: Boolean
    $memberWantsMail: Boolean
    $memberWantsSms: Boolean
  ) {
    updateContact(
      contactId: $contactId
      companyId: $companyId
      memberWantsCall: $memberWantsCall
      memberWantsEmail: $memberWantsEmail
      memberWantsMail: $memberWantsMail
      memberWantsSms: $memberWantsSms
    ) {
      wantsCalls
      wantsEmail
      wantsMail
      wantsSms
    }
  }
`;

const UPDATE_CONTACT_INFORMATION = gql`
  mutation(
    $contactId: ID!
    $companyId: ID!
    $address1: String
    $address2: String
    $city: String
    $companyName: String
    $email: String
    $firstName: String
    $lastName: String
    $phoneAlt: String
    $phoneAltExt: String
    $phoneDaytime: String
    $phoneDaytimeExt: String
    $phoneMobile: String
    $state: String
    $zip: String
  ) {
    updateContact(
      contactId: $contactId
      companyId: $companyId
      address1: $address1
      address2: $address2
      city: $city
      companyName: $companyName
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneAlt: $phoneAlt
      phoneAltExt: $phoneAltExt
      phoneDaytime: $phoneDaytime
      phoneDaytimeExt: $phoneDaytimeExt
      phoneMobile: $phoneMobile
      state: $state
      zip: $zip
    ) {
      contactId
      companyId
      address1
      address2
      city
      companyName
      email
      firstName
      lastName
      phoneAlt
      phoneAltExt
      phoneDaytime
      phoneDaytimeExt
      phoneMobile
      state
      zip
    }
  }
`;

export { UPDATE_COMMUNICATION_PREFERENCES, UPDATE_CONTACT_INFORMATION };
