
import { createSlice } from "@reduxjs/toolkit";

// variables
const initialFilters = {
  selectedViewBy: { value: "all", labelString: "All" },
  sort: { value: "target_mail_date", direction: "desc" },
};

const initialUpcomingFilters = {
  channel: "",
  searchTerm: "",
  selectedViewBy: { value: "days", labelString: "Days" },
  scheduled: false,
  paused: false,
};

const initialState = {
  campaign: "",
  filters: "",
  pageHeader: "",
  showDashboard: false,
  iterationMetadata: "",
  formFilter: "",
  cardControlIndex: 0,
  prospectDisabled: true,
  campaignsDeleted: 0,
  campaignCloned: 0,
  campaignName: "",
  modalCampaignId: null,
  showModal: false,
  prospectDataReady: false,
  selectedChannel: "",
  initialUpcomingFilters,
  upcomingFilters: initialUpcomingFilters,
  journeyActiveModal: {
    name: "",
    title: "",
    prospectId: localStorage.prospectId,
  },
  selectedZipCode: [],
  leadsUploadStatus: null,
  isCalculatorUpdated: true,
  isCalculatorEdit: false,
  closeCalculatorModal: false,
  campaignDetail: null,
  isCalculatorContentUpdated: false,
  isCalulatorModalOpen:false,
  openEnquiryModal:false,
  isCalculateCredits: false,
  isQrUrlChanged:false
};

export const prospectSlice = createSlice({
  name: "prospect",
  initialState,
  reducers: {
    setProspectDataReady: (state, action) => {
      state.prospectDataReady = action.payload;
    },
    setProspectDisabled: (state, action) => {
      state.prospectDisabled = action.payload;
    },
    setCampaignsDeleted: (state, action) => {
      state.campaignsDeleted = action.payload;
    },
    setCampaignCloned: (state, action) => {
      state.campaignCloned = action.payload;
    },
    setIsCalulatorUpdated: (state, action) => {
      state.isCalculatorUpdated = action.payload;
    },
    setIsCalculatorContentUpdated: (state, action) => {
      state.isCalculatorContentUpdated = action.payload;
    },
    setModalCampaignId: (state, action) => {
      state.modalCampaignId = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    setUpcomingFilters: (state, action) => {
      state.upcomingFilters = action.payload;
    },
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    setActiveModal: (state, action) => {
      state.journeyActiveModal = {
        ...state.journeyActiveModal,
        ...action.payload,
      };
    },
    addZipCode: (state, action) => {
      if (typeof action.payload === "string") {
        const data = state.selectedZipCode;
        if (!state.selectedZipCode.includes(action.payload)) {
          data.push(action.payload);
        }
        state.selectedZipCode = data;
      } else {
        state.selectedZipCode = action.payload;
      }
    },
    deleteZipCode: (state, action) => {
      state.selectedZipCode = state.selectedZipCode.filter(
        (value) => value !== action.payload,
      );
    },
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setLeadsUploadStatus: (state, action) => {
      state.leadsUploadStatus = action.payload;
    },
    setIsCalculatorEdit: (state, action) => {
      state.isCalculatorEdit = action.payload;
    },
    setCloseCalculatorModal: (state, action) => {
      state.closeCalculatorModal = action.payload;
    },
    setCampaignDetail: (state, action) => {
      state.campaignDetail = action.payload;
    },
    setIsCalulatorModalOpen: (state, action) => {
      state.isCalulatorModalOpen = action.payload;
    },
    setOpenEnquiryModal: (state, action) => {
      state.openEnquiryModal = action.payload;
    },
    setCalculateApprovedCredits: (state, action) => {
      state.isCalculateCredits = action.payload;
    },
    setIsQrUrlChanged: (state, action) => {
      state.isQrUrlChanged = action.payload;

    },
    
  }
});

// Action creators are generated for each case reducer function
export const {
  setProspectDataReady,
  setProspectDisabled,
  setCampaignsDeleted,
  setCampaignCloned,
  setModalCampaignId,
  setShowModal,
  setSelectedChannel,
  setUpcomingFilters,
  setCampaign,
  setActiveModal,
  addZipCode,
  deleteZipCode,
  setCampaignName,
  setLeadsUploadStatus,
  setIsCalulatorUpdated,
  setIsCalculatorEdit,
  setCloseCalculatorModal,
  setCampaignDetail,
  setIsCalculatorContentUpdated,
  setIsCalulatorModalOpen,
  setOpenEnquiryModal,
  setCalculateApprovedCredits,
  setIsQrUrlChanged,

} = prospectSlice.actions;

export default prospectSlice.reducer;
