export const RESEND_EMAIL_INVITE = `
  mutation resendEmailInvitation($emailInvitationId: ID!, $email: String!) {
    resendEmailInvitation(emailInvitationId: $emailInvitationId, email: $email) {
      id
      status
    }
  }
`

export const RESEND_CONFIRMATION = `
  mutation resendReviewConfirmation(
      $id: ID!,
      $firstName: String,
      $lastName: String,
      $email: String,
      $phoneMobile: String,
      ) {
    resendReviewConfirmation(
      id: $id,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      phoneMobile: $phoneMobile,
      ) {
      id
      status
    }
  }
`
