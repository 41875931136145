import React from "react";
import ReviewWidget from "src/components/settings/widgets/review_widgets/ReviewWidget.jsx";
import StaticButtons from "src/components/settings/widgets/review_widgets/StaticButtons.jsx";
import DynamicButtons from "src/components/settings/widgets/review_widgets/DynamicButtons.jsx";
import AppointmentsAndReviews from "src/components/settings/widgets/review_widgets/AppointmentsAndReviews.jsx";
import { featureFlags } from "src/shared/SessionHelper";

const ReviewWidgetContainer = ({ widgetSettings }) => {
  // feature flag check
  if (!featureFlags.reviewWrite) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="mb-4">
        <ReviewWidget widgetSettings={widgetSettings} />
      </div>
      <div className="mb-4">
        <StaticButtons widgetSettings={widgetSettings} />
      </div>
      <div className="mb-4">
        <DynamicButtons widgetSettings={widgetSettings} />
      </div>
      <div className="mb-4">
        <AppointmentsAndReviews widgetSettings={widgetSettings} />
      </div>
    </React.Fragment>
  );
};

export default ReviewWidgetContainer;
