import React from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  showCompanyId as companyId,
  showUserId,
  showIsManager as companyUserIsManager,
} from "src/shared/SessionHelper";
import Dropdown, { DropdownButton } from "src/shared/styleguide/dropdown/Dropdown";

const ActivationOptions = ({
  companyUserId,
  activationCallback,
  active,
  setQueriedUser,
  queriedUser,
}) => {
  const disableDeactivation = () => {
    return active && companyUserId === showUserId
  }

  const handleDropDown = () => {
    if (Number(companyUserIsManager) === 1 || showUserId === companyUserId)
      return (
        <DropdownButton
          variant="link"
          direction={false}
          alignRight={true}
          title={<span className="text-dark fa fa-ellipsis-v" />}
        >
          <Dropdown.Item
            onClick={() => setQueriedUser(q => ({...q, ...queriedUser}))}
            as={Link}
            to={`/companies/${companyId}/users/${companyUserId}/edit`}
          >
            Edit
          </Dropdown.Item>
          {Number(companyUserIsManager) === 1 ? (<Dropdown.Item disabled={disableDeactivation()} onClick={() => activationCallback(companyUserId, active)}>
            {active ? 'Deactivate' : 'Activate'}
          </Dropdown.Item>) : null}
        </DropdownButton>
      )

    return null
  }

  return handleDropDown()
};

ActivationOptions.propTypes = {
  companyUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  activationCallback: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  queriedUser: PropTypes.object.isRequired,
  setQueriedUser: PropTypes.func.isRequired,
}

export default withRouter(ActivationOptions);
