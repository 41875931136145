import { gql } from 'apollo-boost';


const UPDATE_WIDGET_COLOR = gql`
  mutation updateWidgetColor($companyId: ID!, $widgetColor: String, $widgetWidth: Int, $reviewCount: Int) {
    updateCompanySettings(companyId: $companyId, widgetColor: $widgetColor, widgetWidth: $widgetWidth, reviewCount: $reviewCount) {
      widgetColor
      widgetWidth
      reviewCount
    }
  }
`

export {
  UPDATE_WIDGET_COLOR
}
