import React from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import Form from "src/shared/styleguide/form/Form";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import { Row, Col } from "react-bootstrap";
import Table from "src/shared/styleguide/table/Table";

export const TableStyleGuide = () => {
  // state
  useDocumentTitle("Style Guide - Table");
  const [tableData] = React.useState([
    {
      selected: false,
      firstname: "Mark",
      lastname: "Otto",
      username: "@mdo",
      email: "mdo@example.com",
    },
    {
      selected: false,
      firstname: "Jacob",
      lastname: "Thornton",
      username: "@fat",
      email: "fat@example.com",
    },
    {
      selected: false,
      firstname: "Larry",
      lastname: "the Bird",
      username: "@twitter",
      email: "twitter@example.com",
    },
  ]);

  // render
  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Table</h3>
      <div>
        Examples for opt-in styling of tables (given their prevalent use in
        JavaScript plugins) with Bootstrap.{" "}
      </div>

      <StyleGuideSection
        title="Basic Example"
        description="Using the most basic table markup, here’s how to use the Table component."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <Table
                    head={[
                      <Form.Check
                        custom
                        checked={false}
                        onChange={() => {}}
                        type="checkbox"
                        label=""
                        className="text-dark"
                      />,
                      "FIRST NAME",
                      "LAST NAME",
                      "USERNAME",
                      "EMAIL",
                      "",
                    ]}
                    body={tableData.map((row) => [
                      {
                        node: (
                          <Form.Check
                            custom
                            checked={row.selected}
                            onChange={() => {
                              row.selected = !row.selected;
                            }}
                            type="checkbox"
                            label=""
                            className="text-dark"
                          />
                        ),
                      },
                      { node: row.firstname },
                      { node: row.lastname },
                      { node: row.username },
                      { node: row.email },
                    ])}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import Table from "src/shared/styleguide/table/Table";
import Form from "src/shared/styleguide/form/Form";

const [tableData] = React.useState([
  {
    selected: false,
    firstname: "Mark",
    lastname: "Otto",
    username: "@mdo",
    email: "mdo@example.com"
  },
  {
    selected: false,
    firstname: "Jacob",
    lastname: "Thornton",
    username: "@fat",
    email: "fat@example.com"
  },
  {
    selected: false,
    firstname: "Larry",
    lastname: "the Bird",
    username: "@twitter",
    email: "twitter@example.com"
  }
]);

<Table
  head={[
    <Form.Check
      custom
      checked={false}
      onChange={() => {}}
      type="checkbox"
      label=""
      className="text-dark"
    />,
    "FIRST NAME",
    "LAST NAME",
    "USERNAME",
    "EMAIL",
    ""
  ]}
  body={tableData.map((row) => [
    {
      node: (
        <Form.Check
          custom
          checked={row.selected}
          onChange={() => {
            row.selected = !row.selected;
          }}
          type="checkbox"
          label=""
          className="text-dark"
        />
      )
    },
    { node: row.firstname },
    { node: row.lastname },
    { node: row.username },
    { node: row.email }
  ])}
/>
`}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default TableStyleGuide;
