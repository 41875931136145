import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "src/shared/styleguide/card/Card";
import { MINI_CARD_HEIGHT } from "src/constants/ReviewResults";

const baseCardStyle = {
  width: "100%",
  position: "relative",
  height: MINI_CARD_HEIGHT,
  borderColor: "#cccfd3",
  borderRadius: 4,
  borderStyle: "solid",
  borderWidth: 0,
  boxShadow: "0 1px 3px 0 rgb(0 0 0 / 24%)"
};
const baseCardBodyStyle = {
  paddingBottom: 0,
  paddingTop: 0,
}

export const MiniCard = ({
  cardBodyStyle,
  cardStyle,
  children,
  className = "",
  height = MINI_CARD_HEIGHT,
  onClickViewMore = undefined,
  popupInfoMsg,
  popupInfoPlacement,
  title,
  tooltipClassName,
  viewMore,
  width = "100%",
}) => {
  // We do this because previous implementations were very opinionated.
  const customCardStyle = { ...baseCardStyle, ...cardStyle, width: width, height: height };
  const customCardBodyStyle = { ...baseCardBodyStyle, ...cardBodyStyle }

  return (
    <Card
      flat={false}
      padding={0}
      popupInfoMsg={popupInfoMsg}
      popupIconSize={14}
      popupInfoPlacement={popupInfoPlacement}
      title={
        <div
          className="my-0 h6 pl-3 py-3 text-uppercase text-dark card-title"
          style={{
            fontWeight: 600,
          }}
        >
          {title}
        </div>
      }
      tooltipClassName={tooltipClassName}
      cardStyle={customCardStyle}
      cardBodyStyle={customCardBodyStyle}
    >
      {children}
      {viewMore ? (
        <div className={`text-right px-3 ${className}`}>
          <Link
            onClick={onClickViewMore}
            style={{ color: "#428bca" }}
            to={viewMore.to}
          >
            {viewMore.label}
          </Link>
        </div>
      ) : null}
    </Card>
  );
};

MiniCard.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onClickViewMore: PropTypes.func,
  popupInfoMsg: PropTypes.node.isRequired,
  popupInfoPlacement: PropTypes.string,
  title: PropTypes.node.isRequired,
  tooltipClassName: PropTypes.string,
  viewMore: PropTypes.object,
};

export default MiniCard;