import { RESEND_EMAIL_INVITE, RESEND_CONFIRMATION } from './queries'
import {API_GRAPHQL_URL} from '../../../../../../../shared/Config'
import axios from 'axios'

export const editEmailHelper = async ({emailInvitationId, email, authHeader}) => {
  const queryParams = {
    query: RESEND_EMAIL_INVITE,
    variables: {
      emailInvitationId,
      email
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}

export const confirmationHelper = async ({ id, firstName, lastName, phoneMobile, email, authHeader}) => {
  const queryParams = {
    query: RESEND_CONFIRMATION,
    variables: {
      id,
      firstName,
      lastName,
      email,
      phoneMobile
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}
