import {
  CREATE_REVIEW_CHALLENGE,
  CREATE_REVIEW_COMMENT,
  CREATE_REVIEW_WITHDRAWAL_REQUEST,
  UPDATE_REVIEW
} from './review_details_mutations'
import {API_GRAPHQL_URL} from '../../../../../../shared/Config'
import axios from 'axios'

export const createReviewWithdrawalRequestHelper = async ({ reviewId, note, authHeader }) => {
  const queryParams = {
    query: CREATE_REVIEW_WITHDRAWAL_REQUEST,
    variables: {
      reviewId,
      note
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}

export const createReviewChallengeHelper = async ({ reviewId, reason, communicationBody, userId, userType, authHeader }) => {
  const queryParams = {
    query: CREATE_REVIEW_CHALLENGE,
    variables: {
      reviewId,
      reason,
      communicationBody,
      userId,
      userType
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}

export const createReviewCommentHelper = async ({ reviewId, comment, emailReviewer, userId, userType, authHeader }) => {
  const queryParams = {
    query: CREATE_REVIEW_COMMENT,
    variables: {
      reviewId,
      comment,
      emailReviewer,
      userId,
      userType
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}

export const updateReviewHelper = async ({ reviewId, isFeatured, reviewStatus, authHeader }) => {
  const queryParams = {
    query: UPDATE_REVIEW,
    variables: {
      reviewId,
      isFeatured,
      reviewStatus
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, authHeader)
 } catch(e) {
   return null
 }
}
