export const titleize = (word) => {
  if (word.length < 2) return word.toUpperCase()

  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const cleanName = (firstName, lastName) => {
  if (!firstName) return titleize(lastName)

  if (!lastName) return titleize(firstName)

  return `${titleize(firstName)} ${titleize(lastName)}`
};

export const cleanInitials = (firstName, lastName) => {
  if (!firstName && !lastName) return 'N/A';

  if (!firstName) {
    if (lastName.length < 2) return titleize(lastName[0])

    return `${titleize(lastName[0])}${titleize(lastName[1])}`
  }

  if (!lastName) {
    if (firstName.length < 2) return titleize(firstName[0])

    return `${titleize(firstName[0])}${titleize(firstName[1])}`
  }

  return `${titleize(firstName[0])}${titleize(lastName[0])}`
}

export const cleanEmail = (email) => {
  return email.toLowerCase();
};
