import React, { useState } from "react";
import HorizontalBarChart from "src/shared/styleguide/charts/HorizontalBarChart";
import { QUERY_DISTRIBUTION_BY_TOPIC } from "src/graphql/reviews/results/queries";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { useEffect } from "react";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";

const TOPIC_NOT_CONFIGURED = (
  <span style={{ opacity: 0.8 }}>Topic Not Configured</span>
);

const ReviewDistributionByRating = ({ companyId, filter }) => {
  const [reviewDistributionByTopic, setReviewDistributionByTopic] = useState([
    { labelTop: "", labelRight: 0, barValue: 0 },
    { labelTop: "", labelRight: 0, barValue: 0 },
    { labelTop: "", labelRight: 0, barValue: 0 }
  ]);
  const { loading, data } = useQuery(QUERY_DISTRIBUTION_BY_TOPIC, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });
  useEffect(() => {
    if (data && data.distributionByRating) {
      const newReviewDistributionByTopic = [...reviewDistributionByTopic];
      ["One", "Two", "Three"].map((number, index) => {
        newReviewDistributionByTopic[index].labelTop =
          data.distributionByRating[`topic${number}`] || TOPIC_NOT_CONFIGURED;
        newReviewDistributionByTopic[index].barValue = Math.round(
          data.distributionByRating[`topic${number}Average`] * 100
        );
        newReviewDistributionByTopic[index].labelRight =
          data.distributionByRating[`topic${number}AverageLabel`];
      });
      setReviewDistributionByTopic(
        newReviewDistributionByTopic.sort((data) => {
          return data.labelTop === TOPIC_NOT_CONFIGURED ? 1 : 0;
        })
      );
    }
  }, [data]);
  return (
    <HorizontalBarChart
      loading={loading}
      title="Review Distribution"
      subTitle="by Topic Overall"
      popupInfoMsg="This shows the average rating for the selected review topics that appear on the Customer Lobby review form for the selected date range."
      chartData={reviewDistributionByTopic}
    />
  );
};

ReviewDistributionByRating.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired
};

export default ReviewDistributionByRating;
