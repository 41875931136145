export default class GraphQLUtil {
  /**
   * Parse and return err msg
   * @param {object} err graphql error response object
   */
  static getError(err) {
    return err && err.graphQLErrors && err.graphQLErrors[0]
      ? err.graphQLErrors[0].message
      : "";
  }
}
