import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import notesIcon from "./notesIcon.svg";
import "./ReadMore.scss";

const UpdatingPopover = React.forwardRef((render, ref) => {
  React.useEffect(() => {
    render.scheduleUpdate();
  }, [render]);

  return (
    <Popover ref={ref} {...render}>
      {render.children}
    </Popover>
  );
});

export const ReadMoreNotes = ({
  className = "",
  content = "",
  icon = <img src={notesIcon} alt="Notes" />,
  minWidth = 380,
  placement = "left",
  rootClose = false,
  threshold = 160,
  title = "Notes",
  trigger = "click",
  zIndex = "unset",
}) => {
  // variables
  const readMoreThreshold = threshold;

  const markupCount =
    content.props && content.props.children
      ? content.props.children.reduce((acc, val) => {
        if (val && val.props && val.props.children) {
          if (typeof val.props.children === "object") {
            val.props.children.map((v) => {
              if (typeof v === "string") {
                return acc + (v.length || 0);
              }
              return acc + (v.props.children.length || 0);
            });
          } else {
            return acc + (val.props.children.length || 0);
          }
        }

        return acc + (val.length || 0);
      }, 0)
      : 0;

  let markupTracker = 0;
  const markupTruncated =
    content.props &&
    content.props.children &&
    content.props.children.map((el) => {
      while (markupTracker < threshold) {
        if (el && el.props && el.props.children) {
          if (typeof el.props.children === "object") {
            el.props.children.map((e) => {
              markupTracker = markupTracker + (e.length || 0);
            });
          } else {
            markupTracker = markupTracker + (el.props.children.length || 0);
          }
        } else {
          markupTracker = markupTracker + (el.length || 0);
        }
        return el;
      }
    });

  // state
  let overlayRef = React.useRef(null);
  const [readMore, setReadMore] = React.useState(false);
  return (
    <OverlayTrigger
      show={false}
      rootClose={rootClose}
      ref={overlayRef}
      trigger={trigger}
      placement={placement}
      overlay={
        <UpdatingPopover
          className={className}
          style={{
            boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
            border: "1px solid rgba(126, 126, 126, 0.2)",
            minWidth: minWidth,
            marginLeft: 12,
            zIndex: zIndex,
          }}
        >
          <Popover.Content>
            <div className="p-2 read-more-notes">
              <div className="font-weight-bold mb-1">
                <span className="read-more-notes-title">{title}</span>
                <div
                  onClick={() => {
                    overlayRef.current.handleHide();
                    // delay readMore change until handleHide() fade out completes
                    setTimeout(() => {
                      setReadMore(false);
                    }, 300)
                  }}
                  className="cursor-pointer float-right inline-block"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="13.999"
                    viewBox="0 0 15 14.999"
                  >
                    <g opacity="0.8" transform="translate(0 -.001)">
                      <path
                        fill="#667587"
                        d="M8.827 7.5l5.9-5.9A.938.938 0 1 0 13.4.276l-5.9 5.9-5.9-5.9A.938.938 0 0 0 .275 1.6l5.9 5.9-5.9 5.9A.938.938 0 1 0 1.6 14.726l5.9-5.9 5.9 5.9a.938.938 0 0 0 1.325-1.326z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="text-black-50">
                {content.length > readMoreThreshold ||
                  markupCount > readMoreThreshold ? (
                    <span>
                      {readMore === true
                        ? content
                        : typeof content === "string"
                          ? content.substr(0, readMoreThreshold)
                          : markupTruncated}
                      <div
                        className="sg-text-info cursor-pointer read-more-notes-toggle"
                        style={{ fontWeight: 500 }}
                        onClick={() => setReadMore(!readMore)}
                      >
                        {`Read ${readMore === true ? "Less" : "More"}`}
                      </div>
                    </span>
                  ) : (
                    content
                  )}
              </div>
            </div>
          </Popover.Content>
        </UpdatingPopover>
      }
    >
      <span className="cursor-pointer" > {icon}</span>
    </OverlayTrigger>
  );
};

ReadMoreNotes.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  icon: PropTypes.any,
  minWidth: PropTypes.number,
  placement: PropTypes.string,
  rootClose: PropTypes.bool,
  threshold: PropTypes.number,
  title: PropTypes.string,
  trigger: PropTypes.string,
  zIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default ReadMoreNotes;
