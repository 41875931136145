import { gql } from "apollo-boost";

export const QUERY_SEGMENTS = gql`
  query filters($companyId: ID!) {
    company(companyId: $companyId) {
      id
      timezone
      timezoneAbbrevation
    }
    filters(companyId: $companyId) {
      id
      campaignType
      customersCount
      explanation
      lastUsedAt
      name
      validEmails
    }
  }
`;

export const QUERY_CAMPAIGN = gql`
  query communicationCampaign($campaignId: ID!, $companyId: ID!) {
    company(companyId: $companyId) {
      id
      timezone
      timezoneAbbrevation
    }
    communicationCampaign(id: $campaignId) {
      id
      algoMetadata
      approved
      approvedCredits
      attachments {
        filename
        id
      }
      category
      channel
      companyEmailCampaignTemplate {
        id
        categoryEmailCampaignTemplateId
        content
      }
      companyId
      costPerMonth
      emailSubject
      filter {
        id
        campaignType
        customersCount
        explanation
        lastUsedAt
        name
        validEmails
      }
      followUpEmailEmailAddress
      followUpEmailEnabled
      followUpEmailFirstName
      followUpEmailLastName
      frequency
      includeCommercialCustomers
      includeCustomersInContract
      iterationsCount
      locked
      name
      paused
      recommendedCredits
      magnetMailer
      repeatInterval
      replyToEmail
      replyToName
      resumeDateOptions
      resumeDate
      scheduledAt
      sendSamplePostcard
      status
      campaignsFrontTemplates {
        content
        frontTemplateId
        s3Urls
      }
      frontTemplateIds
      monthlyTargetCount
      upcomingMailingDate
      magnetImage {
        imageUrls
      }
    }
  }
`;
export const QUERY_SUBSCRIPTION_DETAILS = gql`
  query subscriptionDetails($companyId: ID!) {
    subscriptionDetails(companyId: $companyId) {
      costPerPostcard
      costPerProspectPostcard
      costPerMagnetMailer
    }
  }
`;

export const QUERY_COMPANY_FEATURE_FLAGS = gql`
  query companyFeatureFlags($companyId: ID!) {
    companyFeatureFlags(companyId: $companyId)
  }
`;

export const QUERY_MEMBER_INSIGHT = gql`
  query getInsight($companyId: ID!) {
    memberAggregateInsight(companyId: $companyId) {
      commercialCustomerCount
      inContractCustomersCount
    }
  }
`;

export const QUERY_GET_POSTCARD_CATEGORIES = gql`
  query postcardCategories($companyId: ID!, $campaignId: ID!, $type: String) {
    postcardCategories(
      companyId: $companyId
      campaignId: $campaignId
      type: $type
    ) {
      id
      name
      selectedCount
      totalCount
      displayName
    }
  }
`;

export const QUERY_FOLLOW_UP_EMAIL_PREVIEW = gql`
  query followupEmailPreview($campaignId: ID!, $frontTemplateId: ID!) {
    followupEmailPreview(
      campaignId: $campaignId
      frontTemplateId: $frontTemplateId
    )
  }
`;

export const POSTCARD_PREVIEW = gql`
  query postcardPreview(
    $campaignId: ID!
    $frontTemplateId: ID!
    $type: String
    $refresh: Boolean
  ) {
    postcardPreview(
      campaignId: $campaignId
      frontTemplateId: $frontTemplateId
      type: $type
      refresh: $refresh
    )
  }
`;
export const QUERY_CREDIT_SUMMARY = gql`
  query campaignCreditSummaryQuery($companyId: ID!) {
    campaignCreditSummary(companyId: $companyId) {
      postcardCreditsRequired
      postcardCreditsAvailable
    }
  }
`;

export const QUERY_GET_SAMPLE_CONTACT = gql`
  query sampleContact($companyId: ID!) {
    sampleContact(companyId: $companyId) {
      id
      firstName
      zip
      name
      address1
      address2
      city
      state
      country
      lastName
      email
    }
  }
`;

export const QUERY_GET_POSTCARD_FRONT_TEMPLATES = gql`
  query postcardImages(
    $companyId: ID!
    $campaignId: ID!
    $tagId: ID
    $isFront: Boolean
    $page: Int
    $rpp: Int
    $sortOrder: String
    $sortBy: String
    $type: String
  ) {
    postcardImages(
      companyId: $companyId
      campaignId: $campaignId
      tagId: $tagId
      isFront: $isFront
      page: $page
      rpp: $rpp
      sortOrder: $sortOrder
      sortBy: $sortBy
      type: $type
    ) {
      images {
        id
        imageUrls
        imageFilename
        content
        messagePosition
        content
        status
        offset
        customImage
        responseRate
        resizeStatus
      }
      total
    }
  }
`;

export const QUERY_GET_POSTCARD_DATA = gql`
  query communicationCampaign($id: ID!) {
    communicationCampaign(id: $id) {
      id
      name
      showContactName
      backgroundColor
      companyId
      frontLogo
      frontTemplateIds
      textColor
      frontLogoUrl
      campaignsFrontTemplates {
        content
        frontTemplateId
        custom
      }
      backImage {
        id
        customImage
      }
      servicesOffered
      callToActionTracking
      reviewSource
      reviewCategory
      marketingMessageTemplates {
        id
        content
        upperBound
        lowerBound
        status
      }
      offerTemplates {
        id
        disclaimers
        lowerBound
        upperBound
        content
      }
      category
      approved
      frequency
      iterationsCount
      magnetMailer
    }
  }
`;

export const QUERY_PRESIGNED_URL = gql`
  query s3PresignedUrl(
    $companyId: ID!
    $type: String
    $fileExt: String
    $prospectCampaignId: ID
  ) {
    s3PresignedUrl(
      companyId: $companyId
      type: $type
      fileExt: $fileExt
      prospectCampaignId: $prospectCampaignId
    )
  }
`;

export const QUERY_GET_COMPANY_DETAILS = gql`
  query company($companyId: ID!) {
    company(companyId: $companyId) {
      id
      companyName
      phone
      website
    }
  }
`;

export const QUERY_GET_POSTCARD_GLOBAL_SETTINGS = gql`
  query globalCampaignSetting($companyId: ID!) {
    globalCampaignSetting(companyId: $companyId) {
      regulatoryInfo
      tagLine
      logoFilename
      logoUrl
      emailFirstName
      emailLastName
      email
    }
  }
`;

export const QUERY_GET_COMPANY_SETTINGS = gql`
  query companySettings($companyId: ID!) {
    companySettings(companyId: $companyId) {
      id
      reviewPlatform
    }
  }
`;

export const QUERY_GET_REVIEWS = gql`
  query postcardReviews(
    $companyId: ID!
    $source: String!
    $filter: String!
    $limit: Int
  ) {
    postcardReviews(
      companyId: $companyId
      source: $source
      filter: $filter
      limit: $limit
    ) {
      reviewSummary
      reviewBody
      reviewId
      reviewDate
      totalScore
      isFeatured
      contact {
        firstName
        lastName
      }
    }
  }
`;
