import { gql } from "apollo-boost";

const GET_CONTACT = gql`
  query($contactId: ID!) {
    contact(contactId: $contactId) {
      address1
      address2
      city
      commercial
      companyId
      companyName
      contactDisplayName
      contactId
      contractEndDate
      contractStartDate
      customerWantsCall
      customerWantsEmail
      customerWantsMail
      customerWantsSms
      dcWantsCall
      dcWantsEmail
      dcWantsMail
      dcWantsSms
      email
      firstName
      formattedPhoneAlt
      formattedPhoneDaytime
      formattedPhoneMobile
      fullName
      id
      inContract
      lastName
      memberWantsCall
      memberWantsEmail
      memberWantsMail
      memberWantsSms
      name
      phoneAlt
      phoneAltExt
      phoneDaytime
      phoneDaytimeExt
      phoneMobile
      source
      state
      wantsCalls
      wantsEmail
      wantsMail
      wantsSms
      zip
    }
  }
`;

const QUERY_CONTACTS_LIST = gql`
  query contactsLists(
    $companyId: ID!
    $rpp: Int
    $page: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
    $searchField: String
    $communicationInfo: [String!]
  ) {
    contacts(
      companyId: $companyId
      rpp: $rpp
      page: $page
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
      searchField: $searchField
      communicationInfo: $communicationInfo
    ) {
      data {
        contactId
        companyId
        firstName
        lastName
        email
        fullName
        phoneAlt
        phoneAltExt
        phoneMobile
        phoneDaytime
        formattedLastServiceDate
        lastServiceDate
        address1
        address2
        city
        recentActivityName
        recentActivityDatetime
        source
        createdAt
        zip
        state
        communicationInfoEmail
        communicationInfoPhone
        communicationInfoMailing
        customerWantsCall
        dcWantsCall
        memberWantsCall
        customerWantsMail
        dcWantsMail
        memberWantsMail
        customerWantsEmail
        dcWantsEmail
        memberWantsEmail
        customerWantsSms
        dcWantsSms
        memberWantsSms
      }
      ciMetaData
      total
    }
  }
`;

const LIST_FILTERS = gql`
  query list_filters(
    $companyId: ID!
    $rpp: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
    $filterBy: String
    $page: Int
  ) {
    listFilters(
      companyId: $companyId
      rpp: $rpp
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
      filterBy: $filterBy
      page: $page
    ) {
      total
      data {
        archived
        createdAt
        customersCount
        default
        favorite
        id
        lastUsedAt
        metaData
        name
        recentActivityDate
        recentActivityName
        repeatCustomerRate
        updatedAt
        customerShare
        canArchive
      }
    }
  }
`;

const FILTER_ACTIVITY = gql`
  query filter_activities($filterId: ID!) {
    filterActivities(filterId: $filterId) {
      mailingDate
      campaignName
      filterId
      campaignIterationId
      channel
    }
  }
`;

const MUTATION_CLONE_SEGMENT = gql`
  mutation cloneFilter($filterId: ID!) {
    cloneFilter(filterId: $filterId) {
      name
      id
      updatedAt
    }
  }
`;

const MUTATION_SET_FAVORITE_SEGMENT = gql`
  mutation setFavoriteFilter($filterId: ID!, $state: Boolean!) {
    setFavoriteFilter(filterId: $filterId, state: $state) {
      name
      favorite
    }
  }
`;

const MUTATION_SET_ARCHIVED_SEGMENT = gql`
  mutation setArchivedFilter($filterId: ID!, $state: Boolean!) {
    setArchivedFilter(filterId: $filterId, state: $state) {
      name
      archived
    }
  }
`;

const SEGMENT_METADATA = gql`
  query filterMetaData($companyId: ID!) {
    filterMetaData(companyId: $companyId)
  }
`;

const SHOW_FILTER = gql`
  query showFilter($id: ID!) {
    showFilter(id: $id) {
      archived
      campaignType
      customerShare
      customersCount
      explanation
      favorite
      id
      lastUsedAt
      metaData
      name
      default
      recentActivityDate
      recentActivityName
      repeatCustomerRate
      repeatCustomerRate
      updatedAt
      validEmails
    }
  }
`;

const CONTACTS_WITH_METADATA = gql`
  query contactsWithMeta(
    $companyId: ID!
    $metaData: JSON!
    $page: Int
    $rpp: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
    $searchField: String
  ) {
    contactsWithMeta(
      companyId: $companyId
      metaData: $metaData
      page: $page
      rpp: $rpp
      searchField: $searchField
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        address1
        address2
        city
        communicationInfoEmail
        communicationInfoMailing
        communicationInfoPhone
        companyId
        contactId
        createdAt
        customerWantsCall
        customerWantsEmail
        customerWantsMail
        customerWantsSms
        dcWantsCall
        dcWantsEmail
        dcWantsMail
        dcWantsSms
        email
        firstName
        formattedLastServiceDate
        fullName
        lastName
        lastServiceDate
        memberWantsCall
        memberWantsEmail
        memberWantsMail
        memberWantsSms
        phoneAlt
        phoneAltExt
        phoneDaytime
        phoneMobile
        recentActivityDatetime
        recentActivityName
        source
        state
        zip
      }
      total
    }
  }
`;

const FILTER_CAMPAIGN_USAGE = gql`
  query filterCampaignUsage($filterId: Int!) {
    filterCampaignUsage(filterId: $filterId)
  }
`;

const LIST_CUSTOM_FILTERS = gql`
  query listCustomFilters($companyId: Int!) {
    listCustomFilters(companyId: $companyId) {
      name
      id
    }
  }
`;

export {
  CONTACTS_WITH_METADATA,
  FILTER_ACTIVITY,
  FILTER_CAMPAIGN_USAGE,
  GET_CONTACT,
  LIST_FILTERS,
  MUTATION_CLONE_SEGMENT,
  MUTATION_SET_ARCHIVED_SEGMENT,
  MUTATION_SET_FAVORITE_SEGMENT,
  QUERY_CONTACTS_LIST,
  SEGMENT_METADATA,
  SHOW_FILTER,
  LIST_CUSTOM_FILTERS,
};
