import { useEffect } from 'react';

/**
 * Sets document title
 * @param {string} title - title to be set
 */
export const useDocumentTitle = (title) => {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = title;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};

export default useDocumentTitle;
