import { gql } from "apollo-boost";

export const REVIEWS_COUNTS = gql`
  query(
    $companyId: ID!
    $dateFrom: String
    $dateBucket: String
    $platform: String
    $source: String
    $searchTerm: String
  ) {
    reviewCounts(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
      platform: $platform
      source: $source
      searchTerm: $searchTerm
    ) {
      allReviews
      pending
      positive
      negative
      featured
    }
  }
`;

export const REVIEWS_META = gql`
  query($companyId: ID!) {
    reviewsMeta(companyId: $companyId) {
      platform {
        label
        value
        disabled
      }
      source {
        label
        value
        disabled
      }
    }
  }
`;

export const REVIEWS_SUMMARY = gql`
  query($companyId: ID!, $dateFrom: String!, $dateBucket: String!) {
    reviewSummary(
      companyId: $companyId
      dateFrom: $dateFrom
      dateBucket: $dateBucket
    ) {
      days
      positiveCounts {
        currentPeriod
        previousPeriod
        trends
        dateRanges
      }
      negativeCounts {
        currentPeriod
        previousPeriod
        trends
        dateRanges
      }
      pendingCounts {
        reviewsCount
        reviewPercentage
      }
    }
  }
`;

export const REVIEWS_LIST = gql`
  query(
    $companyId: ID!
    $dateFrom: String
    $disposition: String
    $platform: String
    $source: String
    $sortBy: String
    $page: Int
    $limit: Int
    $searchTerm: String
  ) {
    reviewsList(
      companyId: $companyId
      dateFrom: $dateFrom
      disposition: $disposition
      platform: $platform
      source: $source
      page: $page
      limit: $limit
      sortBy: $sortBy
      searchTerm: $searchTerm
    ) {
      reviews {
        id
        reviewId
        scrapedReviewId
        companyId
        status
        totalScore
        featured
        reviewedAt
        reviewType
        reviewPublicUrl
        source
        platform
        destination
        summary
        body
        featured
        contact {
          id
          email
          name
          contactId
          formattedPhoneAlt
          formattedPhoneMobile
          formattedPhoneDaytime
        }
      }
      total
      rpp
      page
    }
  }
`;
