const MINI_CARD_HEIGHT = 134;
const MINI_CARD_CONTENT_HEIGHT = 64;
const MEDIUM_CHART_HEIGHT = 240;
const REVIEW_RESULTS_FETCH_POLICY = "cache-and-network";
const REVIEWS_BASEURL = "https://www.customerlobby.com/reviews/";
const GOOGLE_REVIEWS_BASEURL = "https://www.google.com/search?q=";

export {
  MINI_CARD_HEIGHT,
  MINI_CARD_CONTENT_HEIGHT,
  MEDIUM_CHART_HEIGHT,
  REVIEW_RESULTS_FETCH_POLICY,
  REVIEWS_BASEURL,
  GOOGLE_REVIEWS_BASEURL,
};
