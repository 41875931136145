import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import stepActive from "src/assets/images/getting_started/step_active.svg";
import stepInactive from "src/assets/images/getting_started/step_inactive.svg";
import step1 from "src/assets/images/getting_started/step1.png";
import step2 from "src/assets/images/getting_started/step2.png";
import step3 from "src/assets/images/getting_started/step3.png";
import step4 from "src/assets/images/getting_started/step4.png";

const GettingStartedModal = ({ show, onClose }) => {
  // states
  const [steps] = React.useState([
    {
      id: 0,
      label: "Hold your marketing dollars accountable",
      content: (
        <div>
          <img className="preview-img" src={step2} alt="Step 2" />
          <div className="header">Hold your marketing dollars accountable</div>
          <div className="description">
          Customer Lobby tracks every marketing dollar spent and ties it back
          to your sold jobs. We find out what drives results, stop everything
          that doesn’t, and we show it all to you in an easy-to-use dashboard.
          </div>
        </div>
      ),
    },
    {
      id: 1,
      label: "Smarter Segmentation",
      content: (
        <div>
          <img className="preview-img" src={step1} alt="Step 1" />
          <div className="header">Smarter Segmentation</div>
          <div className="description">
            Customer Lobby's branding algorithm analyzes each customer's history with your business, then uses the data to automatically send your highest value customers postcards and follow up emails with the right message at the right time - keeping your business top of mind. And, our custom filters enable you to easily segment customers so you can create targeted
            campaigns across text, email and direct mail without having to upload and manage contact lists.
          </div>
        </div>
      ),
    },
    {
      id: 2,
      label: "Reach customers across channels",
      content: (
        <div>
          <img className="preview-img" src={step3} alt="Step 3" />
          <div className="header">Reach customers across channels</div>
          <div className="description">
          Customer Lobby supports multiple communication methods, including direct mail, email, and text messaging, enabling you to connect with your customers on their preferred channel. We track performance of campaigns by channel so you can optimize your marketing. We also learn what images your individual customers respond to, and adjust accordingly.
          </div>
        </div>
      ),
    },
    {
      id: 3,
      label: "Improve your online presence",
      content: (
        <div>
          <img className="preview-img" src={step4} alt="Step 4" />
          <div className="header">Improve your online presence</div>
          <div className="description">
            <p>
              Customer Lobby can automatically follow up every transaction with
              a review request. Collect more 5-star reviews on Google, Facebook,
              and other sites of your choice. With Customer Lobby, your techs
              get access to a mobile app that allows them to secure great
              reviews on the spot.
            </p>
          </div>
        </div>
      ),
    },
  ]);
  const [activeStep, setActiveStep] = React.useState(0);

  // methods
  const next = () => {
    setActiveStep(Math.min(activeStep + 1, steps.length - 1));
  };

  const prev = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  // render
  return (
    <Modal
      modalSize="lg"
      onHide={onClose}
      className="getting-started-modal set-1080"
      body={
        <div className="steps">
          {/* close button */}

          <span className="close-button" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 28.001 28"
            >
              <g>
                <path
                  fill="#313541aa"
                  d="M16.477 14L27.488 2.99A1.751 1.751 0 1 0 25.012.514L14 11.525 2.989.514A1.751 1.751 0 0 0 .513 2.99L11.525 14 .513 25.012a1.751 1.751 0 1 0 2.476 2.476L14 16.477l11.012 11.011a1.751 1.751 0 0 0 2.476-2.476z"
                  transform="translate(0 -.001)"
                />
              </g>
            </svg>
          </span>
          <div className="sidebar">
            <div className="header">Customer Lobby Features</div>
            {steps.map((step) => (
              <div className="step" key={step.id}>
                <img
                  src={step.id === activeStep ? stepActive : stepInactive}
                  alt="Step status"
                />
                <div className={step.id === activeStep ? "sideLabelActive" : "sideLabel"}> {step.label}</div>
              </div>
            ))}
          </div>
          {/* content based on active step */}
          <div className="content">
            {steps.find((step) => step.id === activeStep).content}
            <div className="paginate">
              {/* todo: change icon or design */}
              <span
                class={
                  "fa cursor-pointer fa-chevron-left" +
                  (activeStep === 0 ? " disabled" : "")
                }
                onClick={prev}
              />
              {steps.map((step) => (
                <span
                  key={step.id}
                  className={
                    "next-dot" + (step.id === activeStep ? " active" : "")
                  }
                />
              ))}

              {/* todo: change icon or design */}
              <span
                class={
                  "fa cursor-pointer fa-chevron-right chevron" +
                  (activeStep === steps.length - 1 ? " disabled" : "")
                }
                onClick={next}
              />
            </div>
          </div>
          {/* navigate */}
        </div>
      }
      show={show}
    />
  );
};

export default GettingStartedModal;
