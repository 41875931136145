import React from "react";
import Form from "src/shared/styleguide/form/Form";
import { clearFilterStorage } from "./SegmentLocalStorageHelper.js";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import classnames from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import AccordionToggle from "src/shared/styleguide/accordiontoggle/AccordionToggle";
import { initialState } from "./SegmentConstants";
import {
  getTouchedData,
  inputValueFormatter,
  inputValueSanitizer,
  validateRange,
} from "./SegmentUtils";
import "./SegmentContent.scss";

const SegmentExclude = ({
  activeCard,
  activeSection,
  fetchedFormData,
  fetchedMetaData,
  formik,
  formState,
  setActiveSection,
}) => {
  // state
  const [ExpandAll, setExpandAll] = React.useState(false);

  // methods
  const touchCheck = (obj) =>
    (obj.min !== "" && obj.min >= obj.rMin && obj.min < obj.rMax) ||
    (obj.max !== "" && obj.max <= obj.rMax && obj.max > obj.rMin);

  // render
  return (
    <div className="criteria-form-card">
      <div className="criteria-form-card-header active">Exclude Criteria</div>
      <div className="criteria-form-card-body">
        <div className="expand-controls">
          <span
            onClick={() => {
              setActiveSection(null);
              setExpandAll(true);
            }}
          >
            Expand All
          </span>
          <span
            onClick={() => {
              setActiveSection(null);
              setExpandAll(false);
            }}
          >
            Collapse All
          </span>
          <span
            onClick={() => {
              const excludeKeys = ["not_count", "not_amount"];
              const fetchedExcludeData = {
                transactions: {},
              };

              if (fetchedFormData && fetchedMetaData) {
                excludeKeys.map((item) => {
                  // Clear all touched items
                  formik.setFieldTouched(item, false);

                  // Get relevant metaData to set original touched items
                  if (
                    fetchedMetaData.transactions &&
                    fetchedMetaData.transactions[item]
                  ) {
                    fetchedExcludeData.transactions[item] =
                      fetchedMetaData.transactions[item];
                  }

                  // Set original form data
                  formik.setFieldValue(item, fetchedFormData[item]);
                });

                // Set original touched items
                getTouchedData(fetchedExcludeData).map((item) => {
                  formik.setFieldTouched(item, true);
                });
              } else {
                excludeKeys.map((item) => {
                  formik.setFieldTouched(item, false);
                  formik.setFieldValue(item, formState[item]);
                });
              }
              clearFilterStorage();
            }}
          >
            Clear All
          </span>
        </div>
        <Accordion
          activeKey={
            ExpandAll ? "3" : activeSection === "transactions" ? "1" : null
          }
          className={classnames("mt-2 mb-3 form-accordion", {
            "segment-section-active":
              activeSection === "transactions" && activeCard === "exclude",
          })}
        >
          <div
            className="form-toggle-target"
            onClick={() => {
              setExpandAll(false);
              setActiveSection(
                activeSection === "transactions" ? null : "transactions",
              );
            }}
          >
            <div className="form-section-title-container">
              <div className="form-section-title">Transactions</div>
            </div>
            <AccordionToggle eventKey={ExpandAll ? "3" : "1"}></AccordionToggle>
          </div>
          <Accordion.Collapse eventKey={ExpandAll ? "3" : "1"}>
            <div>
              <Row>
                <Col xl={5} lg={6}>
                  <Form.Label
                    className={classnames("text-dark", {
                      "criteria-touched": formik.touched.not_count,
                    })}
                  >
                    Number of transactions
                    <PopupInfo
                      tooltipClassName="create-segement-tooltip"
                      iconClassName="create-segement-tooltip-icon"
                      placement="top"
                      message={
                        "This filters by the number of transactions a customer has had with you"
                      }
                    />
                  </Form.Label>
                </Col>
                <Col xl={3}>
                  <Form.Control
                    onChange={(e) => {
                      const newObj = {
                        ...formik.values.not_count,
                        min: inputValueSanitizer(e.target.value),
                      };
                      formik.setFieldTouched("not_count", touchCheck(newObj));
                      formik.setFieldValue("not_count", newObj);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched(
                        "not_count",
                        touchCheck(formik.values.not_count),
                      );
                      validateRange(
                        formik,
                        "not_count",
                        "min",
                        formik.values.not_count.rMin ||
                          formState.not_count.rMin ||
                          initialState.not_count.rMin,
                        formState,
                      );
                    }}
                    placeholder="Min"
                    value={inputValueFormatter(formik.values.not_count.min)}
                  />
                </Col>
                <Col xl={3}>
                  <Form.Control
                    onChange={(e) => {
                      const newObj = {
                        ...formik.values.not_count,
                        max: inputValueSanitizer(e.target.value),
                      };
                      formik.setFieldTouched("not_count", touchCheck(newObj));
                      formik.setFieldValue("not_count", newObj);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched(
                        "not_count",
                        touchCheck(formik.values.not_count),
                      );
                      validateRange(
                        formik,
                        "not_count",
                        "max",
                        formik.values.not_count.rMax ||
                          formState.not_count.rMax ||
                          initialState.not_count.rMax,
                        formState,
                      );
                    }}
                    placeholder="Max"
                    value={inputValueFormatter(formik.values.not_count.max)}
                  />
                </Col>
                <Col xl={{ span: 6, offset: 5 }}>
                  <div className="segment-slider-container">
                    <Form.MultiRange
                      min={
                        parseInt(formik.values.not_count.rMin) ||
                        parseInt(formState.not_count.rMin) ||
                        initialState.not_count.rMin
                      }
                      max={
                        parseInt(formik.values.not_count.rMax) ||
                        parseInt(formState.not_count.rMax) ||
                        initialState.not_count.rMax
                      }
                      value={{
                        min:
                          formik.values.not_count.min ||
                          formik.values.not_count.rMin ||
                          formState.not_count.rMin,
                        max:
                          formik.values.not_count.max ||
                          formik.values.not_count.rMax ||
                          formState.not_count.rMax,
                      }}
                      onChange={(value) => {
                        const newObj = {
                          ...formik.values.not_count,
                          ...value,
                        };
                        formik.setFieldTouched("not_count", touchCheck(newObj));
                        formik.setFieldValue("not_count", newObj);
                      }}
                      formattedValueNeeded={true}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={5} lg={6}>
                  <Form.Label
                    className={classnames("text-dark", {
                      "criteria-touched": formik.touched.not_amount,
                    })}
                  >
                    Total Dollars spent
                    <PopupInfo
                      tooltipClassName="create-segement-tooltip"
                      iconClassName="create-segement-tooltip-icon"
                      placement="top"
                      message={
                        "This filters by total dollars spent by the customer"
                      }
                    />
                  </Form.Label>
                </Col>
                <Col xl={3}>
                  <Form.Control
                    onChange={(e) => {
                      const newObj = {
                        ...formik.values.not_amount,
                        min: inputValueSanitizer(e.target.value),
                      };
                      formik.setFieldTouched("not_amount", touchCheck(newObj));
                      formik.setFieldValue("not_amount", newObj);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched(
                        "not_amount",
                        touchCheck(formik.values.not_amount),
                      );
                      validateRange(
                        formik,
                        "not_amount",
                        "min",
                        formik.values.not_amount.rMin ||
                          formState.not_amount.rMin ||
                          initialState.not_amount.rMin,
                        formState,
                      );
                    }}
                    placeholder="Min"
                    value={inputValueFormatter(formik.values.not_amount.min)}
                  />
                </Col>
                <Col xl={3}>
                  <Form.Control
                    onChange={(e) => {
                      const newObj = {
                        ...formik.values.not_amount,
                        max: inputValueSanitizer(e.target.value),
                      };
                      formik.setFieldTouched("not_amount", touchCheck(newObj));
                      formik.setFieldValue("not_amount", newObj);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched(
                        "not_amount",
                        touchCheck(formik.values.not_amount),
                      );
                      validateRange(
                        formik,
                        "not_amount",
                        "max",
                        formik.values.not_amount.rMax ||
                          formState.not_amount.rMax ||
                          initialState.not_amount.rMax,
                        formState,
                      );
                    }}
                    placeholder="Max"
                    value={inputValueFormatter(formik.values.not_amount.max)}
                  />
                </Col>
                <Col xl={{ span: 6, offset: 5 }}>
                  <div className="segment-slider-container">
                    <Form.MultiRange
                      min={
                        parseInt(formik.values.not_amount.rMin) ||
                        parseInt(formState.not_amount.rMin) ||
                        initialState.not_amount.rMin
                      }
                      max={
                        parseInt(formik.values.not_amount.rMax) ||
                        parseInt(formState.not_amount.rMax) ||
                        initialState.not_amount.rMax
                      }
                      value={{
                        min:
                          formik.values.not_amount.min ||
                          formik.values.not_amount.rMin ||
                          formState.not_amount.rMin,
                        max:
                          formik.values.not_amount.max ||
                          formik.values.not_amount.rMax ||
                          formState.not_amount.rMax,
                      }}
                      onChange={(value) => {
                        const newObj = {
                          ...formik.values.not_amount,
                          ...value,
                        };
                        formik.setFieldTouched(
                          "not_amount",
                          touchCheck(newObj),
                        );
                        formik.setFieldValue("not_amount", newObj);
                      }}
                      formattedValueNeeded={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </div>
  );
};

export default SegmentExclude;
