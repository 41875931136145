import React from "react";
import { Tab, Nav } from "react-bootstrap";
import "./TabsComponent.scss";
import { PropTypes } from "prop-types";

/**
 * Tabs component to render the tabs based on the props.
 * @param tabs -> {array} -> Array of Tabs with the structure {eventKey {number}, title {String}, component {<Component />}}
 * @param selectedTab -> {number}-> Tab to be set as selected.
 * @returns {XML} -> Returns the Tabs component with the generated tabs using props.
 */
const TabsComponent = ({
  tabs = [],
  selectedTab,
  onTabChange,
  unmountOnExit = true,
  transition = false,
  className = "",
}) => {

  return (
    <div id="TabsComponent" className={className}>
      <Tab.Container
        onSelect={onTabChange}
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        transition={transition}
        unmountOnExit={unmountOnExit}
      >
        <Nav className="tabs">
          {tabs.map((tab) => (
            <Nav.Item key={tab.eventKey}>
              <Nav.Link eventKey={tab.eventKey} as="span">
                {tab.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content>
          {tabs.map((tab) => (
            <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
              {tab.component}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

/**
 * Setting default props for component
 */
TabsComponent.defaultProps = {
  tabs: [],
  selectedTab: 0,
};

/**
 * Adding required props for component.
 */
TabsComponent.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.number,
  onTabChange: PropTypes.func,
  unmountOnExit: PropTypes.bool,
  transition: PropTypes.bool,
  className: PropTypes.string,
};

export default TabsComponent;
