import React from "react";
import Modal from "react-bootstrap4-modal";
import "./ModalPopupRight.scss";
import rightArrow from "../../styles/assets/review-settings-icon/right-arrow.svg";

/**
 * Popup renders at right hand side of the screen with full height.
 */
export default class ModalPopupRight extends React.Component {
  /**
   * Handle Modal Close action
   */
  onCloseModal = () =>{
    this.props.modalClose();
  }

  /**
   * Header for review details popup.
   * @returns {XML}
   */
  reviewDetailsHeader = () => {
    return(
      <div className="row">
        <div className="col-12 mt-4 ml-sm-3">
          <a className="review-details-title">
            <img
              src={rightArrow}
              alt="right-arrow"
              onClick={() => this.onCloseModal()}
            />
            {this.props.modalHeader}
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="modal-popup-right-view">
        {this.props.isModalOpen ? (
          <Modal
            visible={this.props.isModalOpen}
            onClickBackdrop={() => this.onCloseModal()}
          >
            <div className="modal-header">{this.reviewDetailsHeader()}</div>
            <div className="modal-body">{this.props.modalBody}</div>
          </Modal>
        ) : (
          ""
        )}
      </div>
    );
  }
}
