import React from "react";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import ReviewWidgetBody from "src/components/settings/widgets/review_widgets/ReviewWidgetBody.jsx";

const ReviewWidget = ({ widgetSettings }) => {
  // render
  return (
    <SettingsCard
      loading={false}
      settingsName="Reviews Widget"
      settingsDescription="Display your Customer Lobby reviews right on your website, by copying and pasting the code to your website."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.234"
          height="36.363"
          viewBox="0 0 38.234 36.363"
        >
          <path
            d="M37.853 25.554l-12.536-2.646-6.39-11.1-6.39 11.1L0 25.554l8.587 9.509L7.229 47.8l11.7-5.228 11.7 5.228-1.358-12.74zM18.927 40L10 43.983l1.035-9.717-6.544-7.253 9.562-2.018 4.874-8.47 4.874 8.47 9.562 2.018-6.549 7.253 1.035 9.717z"
            transform="translate(.191 -11.603)"
            strokeWidth=".2px"
          />
        </svg>
      }
      settingsForm={
        <ReviewWidgetBody
          widgetSettings={widgetSettings}
        />
      }
    />
  );
};

export default ReviewWidget;
