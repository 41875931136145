import React, { useEffect } from 'react'
import { useMutation } from "@apollo/react-hooks";
import { showCompanyId as companyId } from 'src/shared/SessionHelper'
import { UPDATE_DIRECT_CONNECT_SETTINGS } from 'src/graphql/direct_connect/mutations'
import { NO_CACHE } from 'src/shared/Constants'

const XeroCallback = () => {
  const [updateDirectConnectSettings] = useMutation(UPDATE_DIRECT_CONNECT_SETTINGS)

  const handleUpdate = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    const redirectUri = window.location.origin + window.location.pathname

    const res = await updateDirectConnectSettings({
      variables: {
        companyId,
        workflowSystem: 'Xero',
        code,
        redirectUri,
      },
      fetchPolicy: NO_CACHE,
    })

    if (res && !res.loading && res.data && res.data.updateDirectConnectSetting) {
      window.location.replace(`${document.location.origin}/companies/${companyId}/direct-connect-settings`)
    }
  }

  useEffect(() => {
    handleUpdate()
  }, [])

  return null;
}

export default XeroCallback
