export const formatTitle = (value, precision) => {
  const smartPrecision = value === 100 ? 0 : precision;
  if (value || value === 0) {
    return `${value.toLocaleString("en-US", {
      minimumFractionDigits: smartPrecision,
      maximumFractionDigits: smartPrecision,
    })}%`;
  } else {
    return "";
  }
};
