import React from "react";

export const MAXIMUM_SEGMENT_NAME_LENGTH = 100;
export const PAGE_LENGTH = 12;

export const initialCompanyFilterMetrics = {
  job_types: [{ value: "any", label: "All" }],
  max_amount_per_transaction: 100000,
  max_transacted_at: null,
  max_transaction_amount: 100000,
  max_transaction_count: 1000,
  min_amount_per_transaction: 0,
  min_transacted_at: null,
  min_transaction_amount: 0,
  min_transaction_count: 0,
  service_contract_types: [{ value: "any", label: "All" }],
  max_since_last_transaction_months: 1000,
  min_since_last_transaction_months: 0,
  max_since_last_transaction_days: 10000,
  min_since_last_transaction_days: 0,
  has_mailing_address: [{ value: "any", label: "All" }],
  has_mobile_number: [{ value: "any", label: "All" }],
  has_email_address: [{ value: "any", label: "All" }],
};

export const initialState = {
  name: "",
  commercial: [{ value: "any", label: "All" }],
  source: [{ value: "any", label: "All" }],
  in_contract: [{ value: "any", label: "All" }],
  service_contract_type: [{ value: "any", label: "All" }],
  contract_start_date: { type: "range", start: null, end: null },
  contract_end_date: { type: "range", start: null, end: null },
  state: [{ value: "any", label: "All" }],
  city: [{ value: "any", label: "All" }],
  zip: [{ value: "any", label: "All" }],
  has_mailing_address: [{ value: "any", label: "All" }],
  has_mobile_number: [{ value: "any", label: "All" }],
  has_email_address: [{ value: "any", label: "All" }],
  job_type: [{ value: "any", label: "All" }],
  count: { min: 0, max: 1000 },
  // days and months max at 30 years for default
  months: { min: 0, max: 361 },
  days: { min: 0, max: 10958 },
  amount: { min: 0, max: 100000 },
  amount_per_txn: { min: 0, max: 100000 },
  txn_date: { min: null, max: null },
  not_count: { min: "", max: "", rMin: 0, rMax: 1000 },
  not_amount: { min: "", max: "", rMin: 0, rMax: 100000 },
  customer_filter_id: {
    values: [],
  },
};

export const initialMetaData = {
  transactions: {
    count: {
      operator: "range",
      aggregator: "COUNT",
      values: ["any", "any"],
    },
    amount: {
      operator: "range",
      aggregator: "SUM",
      values: ["any", "any"],
    },
    months: {
      operator: "range",
      values: ["any", "any"],
    },
  },
  contacts: {},
};

// OPTIONS
// Service Contract Status
export const optionsServiceContractStatus = [
  { value: "any", label: "All" },
  { value: "1", label: "Has Service Contract" },
  { value: "0", label: "No Service Contract" },
];

export const optionsHasMailingAddress = [
  { value: "any", label: "All" },
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];

export const optionsHasMobileNumber = [
  { value: "any", label: "All" },
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];

export const optionsHasEmailAddress = [
  { value: "any", label: "All" },
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];

// Service Contract Start Date
// Service Contract End Date
export const NEXT_MONTH_STARTING = "nms";
export const NEXT_MONTH_ENDING = "nme";
export const RELATIVE_NEXT = { value: "next", label: "Next 30 Days" };
export const RELATIVE_PREV = {
  value: "prev",
  label: "Previous 30 Days",
};
export const RELATIVE_NEXT_MONTH = {
  value: "next_month",
  label: "Next Calendar Month",
};

// Utility Constants
export const contactsKeys = [
  "commercial",
  "source",
  "in_contract",
  "service_contract_type",

  "contract_start_date",
  "contract_end_date",

  "state",
  "city",
  "zip",

  "has_mailing_address",
  "has_mobile_number",
  "has_email_address",
];

export const contactsAnyList = [
  "commercial",
  "source",
  "in_contract",
  "service_contract_type",
  "has_mailing_address",
  "has_mobile_number",
  "has_email_address",
];

export const contactsLocationList = ["state", "city", "zip"];

export const contactsDateList = ["contract_start_date", "contract_end_date"];
export const transactionsKeys = [
  "job_type",
  "count",
  "months",
  "days",
  "amount",
  "amount_per_txn",
  "txn_date",
  "not_count",
  "not_amount",
];

export const transactionsAnyList = ["job_type"];

export const transactionsRangeList = ["months", "days", "txn_date"];

export const transactionsRangeCountList = ["count", "not_count"];

export const transactionsRangeAvgList = ["amount_per_txn"];

export const transactionsRangeSumList = ["amount", "not_amount"];

export const learnMoreContent = (
  <>
    <div>Before you start</div>
    <p>
      Here's some useful information if you're just getting started with
      segments
    </p>
    <p>
      Your customer list contains a lot of information, such as: when they were
      added, where they live, and how they interact with your business. Instead
      of a one-size-fits-all approach, cluster your customer data into groups
      which share the same properties or behavioral characteristics. This will
      help drive dynamic content and personalization tactics for timelier, more
      relevant and more effective marketing communications.
    </p>
    <p>
      When you create a segment, you'll set conditions for filtering by the
      information that's available in your database.
    </p>
    <p>
      <b>
        Note: All customers will be selected, if none of the filters and
        attributes you've specified apply to any customer records.
      </b>
    </p>
    {/* ----- Read More/Less ----- */}
    <p>How can segmentation benefit your business?</p>
    <p>
      Segmentation allows businesses like yours to make better use of their
      marketing budgets, gain a competitive edge over rival companies and,
      importantly, demonstrate a better knowledge of your customers’ needs and
      wants. It can also help:
    </p>
    <p>
      <b>Marketing efficiency</b> – Breaking down a large customer base into
      more manageable pieces, making it easier to identify your target audience
      and launch campaigns to the most relevant people, using the most relevant
      channel.
    </p>
    <p>
      <b>Determine new market opportunities</b> – During the process of grouping
      your customers into clusters, you may find that you have identified a new
      market segment, which could in turn alter your marketing focus and
      strategy to fit.
    </p>
    <p>
      <b>Better brand strategy</b> – Once you have identified the key motivators
      for your customer, such as design, price, or practical needs, you can
      brand your products and services appropriately.
    </p>
    <p>
      <b>Customer retention</b> – Using segmentation, you can identify groups
      that require extra attention and those that churn quickly, along with
      customers with the highest potential value. It can also help with creating
      targeted strategies that capture your customers’ attention and create
      positive, high-value experiences with your brands.
    </p>
  </>
);

export const segmentTipsContent = (
  <>
    <ol className="pl-3">
      <li>
        For <b>Include Criteria</b>, you've got two filters:{" "}
        <b>Customer Details</b> and <b>Transactions.</b> Those two filters each
        have multiple attributes available.
      </li>
      <li>
        If the information for any of the attributes is not available, those
        attributes will be greyed out; you won't be able to click on or select
        them.
      </li>
      <li>
        For all attributes, <b>All</b> is selected by default.
      </li>
      <li>
        For any criteria you update, the total contacts will be{" "}
        <b>auto updated.</b>
      </li>
      <li>
        The custom filter functionality that longtime users might remember has
        been renamed <b>Advanced Filter.</b>
      </li>
      <li>
        You can only <b>Exclude</b> two <b>Transaction</b> attributes per
        filter.
      </li>
      <li>
        The segment is only saved once you click on <b>Create.</b>
      </li>
      <li>
        To view the list of your contacts, click on <b>View Contacts</b>
      </li>
      <li>
        You can always contact your Customer Success Manager with any questions.
      </li>
    </ol>
  </>
);
