import React from "react";

const DEFAULT_PROFILE_IMAGE =
  "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png";
const TWITTER_BASE_URL = "https://twitter.com/";

const TwitterProfileInformation = ({ twitterProfile }) => {
  // render
  return (
    <div>
      <div className="block-twitter-confirmed d-flex flex-row">
        <img
          src={twitterProfile.twitterAvatarUrl || DEFAULT_PROFILE_IMAGE}
          alt={twitterProfile.screenName}
          className="img-twitter-profile mr-3"
        />
        <div>
          You are currently posting to the{" "}
          <a
            href={`${TWITTER_BASE_URL}/${twitterProfile.screenName}`}
            target="_blank"
            className="sg-text-info"
            rel="noopener noreferrer"
          >
            {twitterProfile.screenName}
          </a>{" "}
          account.
          <br />
          Twitter review syndication is working normally.
        </div>
      </div>
    </div>
  );
};

export default TwitterProfileInformation;
