import { gql } from "apollo-boost";

const UPDATE_COMPANY_USERS_ACTIVATION = gql`
  mutation updateCompanyUser($companyUserId: ID!, $isActive: Boolean) {
    updateCompanyUser(companyUserId: $companyUserId, isActive: $isActive) {
      companyUserId
      isActive
    }
  }
`;

const UPDATE_COMPANY_USER = gql`
  mutation updateCompanyUser(
    $companyUserId: ID!
    $isActive: Boolean
    $isManager: Boolean
    $avatarUrl: String
    $email: String
    $firstName: String
    $lastName: String
    $password: String
    $passwordConfirmation: String
    $image: String
    $imageOriginalFileName: String
    $jobTitle: String
    $address: String
    $phoneMobile: String
  ) {
    updateCompanyUser(
      companyUserId: $companyUserId
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      isManager: $isManager
      avatarUrl: $avatarUrl
      isActive: $isActive
      image: $image
      imageOriginalFileName: $imageOriginalFileName
      jobTitle: $jobTitle
      address: $address
      phoneMobile: $phoneMobile
    ) {
      companyUserId
      companyId
      firstName
      lastName
      email
      formattedPhone
      avatarUrl
      ghostUser
      isManager
      isActive
      userDisplayName
      jobTitle
      formattedAddress
    }
  }
`;

const CREATE_COMPANY_USER = gql`
  mutation createCompanyUser(
    $companyId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $avatarUrl: String
    $isManager: Boolean
    $isActive: Boolean
    $password: String
    $passwordConfirmation: String
    $source: String!
    $image: String
    $creatorId: ID
    $imageOriginalFileName: String
  ) {
    createCompanyUser(
      companyId: $companyId
      creatorId: $creatorId
      firstName: $firstName
      lastName: $lastName
      email: $email
      avatarUrl: $avatarUrl
      isManager: $isManager
      isActive: $isActive
      password: $password
      passwordConfirmation: $passwordConfirmation
      source: $source
      image: $image
      imageOriginalFileName: $imageOriginalFileName
    ) {
      companyUserId
      companyId
      firstName
      lastName
      email
      avatarUrl
      isManager
      isActive
    }
  }
`;

const MUTATION_ACCEPT_TERMS = gql`
  mutation updateCompanyUser($companyUserId: ID!, $acceptTerms: Boolean!) {
    updateCompanyUser(
      companyUserId: $companyUserId
      acceptsTerms: $acceptTerms
    ) {
      companyUserId
      acceptsTerms
    }
  }
`;

export {
  UPDATE_COMPANY_USERS_ACTIVATION,
  UPDATE_COMPANY_USER,
  CREATE_COMPANY_USER,
  MUTATION_ACCEPT_TERMS,
};
