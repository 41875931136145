import { showUserId as creatorId } from "src/shared/SessionHelper";
import { addToast } from "src/utils/ToastUtil";

const createCompanyUsersAction = async ({
  setSuccessMessage,
  setDisplay,
  setSubmitting,
  setShowError,
  createCompanyUser,
  values,
  companyId,
}) => {
  setSubmitting(true);
  try {
    const res = await createCompanyUser({
      variables: { ...values, creatorId },
      fetchPolicy: "no-cache",
    });

    if (res && res.data && res.data.createCompanyUser) {
      document
        .querySelectorAll(`a[href='/companies/${companyId}/users']`)[1]
        .click();
      setSubmitting(false);
      setSuccessMessage("The company user has been created successfully");
      setDisplay("flex");
      setTimeout(() => setDisplay("none"), 3000);
    }
  } catch (e) {
    const splitError = e.message.split(",");
    if (splitError[splitError.length - 1] === "Email has already been taken") {
      setShowError("User already exists with this Email");
      setTimeout(() => setShowError(""), 3000);
    }
    setSubmitting(false);
  }
};

const updateCompanyUsersAction = async ({
  setSuccessMessage,
  setDisplay,
  setSubmitting,
  updateCompanyUser,
  values,
  companyId,
  setShowError,
}) => {
  setSubmitting(true);
  try {
    const res = await updateCompanyUser({
      variables: { ...values },
      fetchPolicy: "no-cache",
    });

    if (res && res.data && res.data.updateCompanyUser) {
      document
        .querySelectorAll(`a[href='/companies/${companyId}/users']`)[1]
        .click();
      setSubmitting(false);
      setSuccessMessage("The company user has been updated");
      setDisplay("flex");
      setTimeout(() => setDisplay("none"), 3000);
    }
  } catch (e) {
    setSubmitting(false);
    const splitError = e.message.split(",");
    if (splitError[splitError.length - 1] === "Email has already been taken") {
      setShowError("User already exists with this Email");
      setTimeout(() => setShowError(""), 3000);
    } else {
      setShowError(e.message ? e.message.replace("GraphQL error: ", "") : "");
    }
  }
};

const updateMyInformation = async ({
  setSubmitting,
  updateCompanyUser,
  values,
  queriedUser,
  setQueriedUser,
  companyId,
}) => {
  setSubmitting(true);
  try {
    let newValues = { ...values };
    delete newValues["avatarUrl"];
    delete newValues["jobTitle"];
    delete newValues["formattedAddress"];
    delete newValues["userDisplayName"];

    const res = await updateCompanyUser({
      variables: { ...newValues },
      fetchPolicy: "no-cache",
    });

    if (res && res.data && res.data.updateCompanyUser) {
      setSubmitting(false);
      setQueriedUser({
        ...queriedUser,
        ...values,
        ...res.data.updateCompanyUser,
        phoneMobile: res.data.updateCompanyUser.formattedPhone,
      });
      addToast({
        message: "Profile Details updated successfully",
        type: "success",
      });
    }
  } catch (e) {
    setSubmitting(false);
    const splitError = e.message.split(",");
    if (splitError[splitError.length - 1] === "Email has already been taken") {
      addToast({
        message: "User already exists with this Email",
        type: "error",
      });
    }
  }
};

const updatePassword = async ({
  setSubmitting,
  updateCompanyUser,
  values,
  setShowPasswordError,
  companyId,
}) => {
  setSubmitting(true);
  try {
    const res = await updateCompanyUser({
      variables: { ...values },
      fetchPolicy: "no-cache",
    });

    if (res && res.data && res.data.updateCompanyUser) {
      setSubmitting(false);
      addToast({
        message: "Profile Details updated successfully",
        type: "success",
      });
      setTimeout(() => {
        document.querySelector("#logout-btn").click();
      }, 3000);
    }
  } catch (e) {
    setSubmitting(false);
    setShowPasswordError(e.message ? e.message.replace("GraphQL error: ", "") : "");
  }
};

export {
  createCompanyUsersAction,
  updateCompanyUsersAction,
  updatePassword,
  updateMyInformation,
};
