import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    darkMode: false,
  },
  reducers: {
    toggleTheme: (state, action) => {
      state.darkMode = action.payload;
    },
  },
});

// Extract action creators and reducer
export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
