import React, { useState, useEffect } from "react";
import Chart from "src/shared/styleguide/charts/Chart";
import Card from "src/shared/styleguide/card/Card";
import BlockUi from "react-block-ui";
import PropTypes from "prop-types";
import classnames from "classnames";

export const DonutChart = ({
  popupInfoMsg,
  title,
  subTitle,
  chartId,
  categories,
  chartHeight,
  noData = false,
  showLegend = true,
  loading = false,
  tooltipFormatter = undefined,
  chartData = [],
  ...props
}) => {
  // state
  const [chartConfig, setChartConfig] = useState({
    chart: {
      type: "line",
      height: chartHeight,
      events: {
        render() {
          this.series.forEach(series => {
            if (series.legendSymbol) {
              series.legendSymbol.attr({
                width: 10,
                height: 10,
                y: 6
              });
            }
          });
        }
      }
    },
    xAxis: { categories },
    yAxis: {
      min: 0,
      title: { text: null }
    },
    tooltip: {
      formatter: tooltipFormatter
    },
    legend: {
      enabled: showLegend,
      align: "center",
      verticalAlign: "bottom",
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        marker: {
          symbol: "circle",
          radius: 3,
          states: {
            hover: {
              radiusPlus: 1
            }
          }
        }
      }
    },
    series: chartData
  });

  // update chart data
  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.series = chartData;
    setChartConfig(newChartConfig);
  }, [chartData]);

  // update categories
  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.xAxis.categories = categories;
    setChartConfig(newChartConfig);
  }, [categories]);

  return (
    <BlockUi
      loader={loading ? undefined : <React.Fragment />}
      className={classnames("card-chart", { "no-chart-data": noData })}
      blocking={noData || loading}
    >
      <Card
        padding={0}
        popupInfoMsg={popupInfoMsg}
        title={<div className="pl-4 pt-4 pb-2 d-inline-block">{title}</div>}
        subTitle={
          <div className="pl-4 pt-1 pb-2 sg-card-subtitle d-inline-block">
            {subTitle}
          </div>
        }
        cardBodyStyle={{
          paddingLeft: "1rem",
          paddingRight: "1rem"
        }}
        {...props}
      >
        <Chart chartId={chartId} chartConfig={chartConfig} />
      </Card>
    </BlockUi>
  );
};
DonutChart.propTypes = {
  title: PropTypes.string.isRequired,
  popupInfoMsg: PropTypes.string,
  categories: PropTypes.array.isRequired,
  chartId: PropTypes.string.isRequired,
  noData: PropTypes.bool,
  loading: PropTypes.bool,
  chartHeight: PropTypes.number.isRequired,
  tooltipFormatter: PropTypes.func,
  showLegend: PropTypes.bool
};

export default DonutChart;
