import React from "react";
import { Row, Col } from "react-bootstrap";
import MapVisualization from "src/shared/styleguide/maps/MapVisualization.jsx";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const mapCompany = {
  companyName: "Carini Heating, Air and Plumbing",
  latitude: 32.7499681,
  longitude: -117.1568408,
};

const mapCoverage = [
  {
    zip: "92103",
    lat: 32.7498,
    lng: -117.168,
    customers_count: 633,
  },
  {
    zip: "92104",
    lat: 32.7399,
    lng: -117.121,
    customers_count: 541,
  },
  {
    zip: "92037",
    lat: 32.8283,
    lng: -117.256,
    customers_count: 512,
  },
  {
    zip: "92116",
    lat: 32.7679,
    lng: -117.124,
    customers_count: 506,
  },
  {
    zip: "92117",
    lat: 32.8251,
    lng: -117.203,
    customers_count: 451,
  },
];

// Note that these values align with key value pairs in mapCoverage object
const mapLegend = [
  {
    label: "ZIP",
    value: "zip",
  },
  {
    label: "Customers Reached",
    value: "customers_count",
  },
];

export const MapStyleGuide = () => {
  useDocumentTitle("Style Guide - Cards");
  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Maps</h3>
      <div>
        Maps are the obvious solution to presenting location based data. For
        now, we do not utilize many maps, but it is important to maintain
        demonstratable examples to help us utilize maplibre in the future.
      </div>

      <StyleGuideSection
        title="Map Visualization"
        description="This component was originally designed for overal campaign results, and refactored and moved into the style guide. Currently, there are still artifacts of that file in CustomerCoverage.jsx. Hint: HTML entities can be used in `popupLegendEqualSymbol` which may be helpful in adding whitespace."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <MapVisualization
                    company={mapCompany}
                    coverage={mapCoverage}
                    popupClassName="sg-custom-class-name"
                    popupLegend={mapLegend}
                    popupLegendEqualSymbol=" : "
                    showPopupLegend={true}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import MapVisualization from "src/shared/styleguide/charts/MapVisualization";

<MapVisualization
  company={mapCompany}
  coverage={mapCoverage}
  popupClassName="sg-custom-class-name"
  popupLegend={mapLegend}
  popupLegendEqualSymbol=" : "
  showPopupLegend={true}
/>`}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default MapStyleGuide;
