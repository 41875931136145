import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import InfoIcon from "src/assets/images/common/info-icon-large.svg";
import "./SegmentModal.scss";

const SegmentInUseModal = ({ setShowModal, showModal }) => {
  // render
  return (
    <React.Fragment>
      <Modal
        className="segment-modal-container"
        modalSize="md"
        onHide={() => {
          setShowModal(false);
        }}
        body={
          <div className="modal-body-content">
            <img src={InfoIcon} alt="Card Style Guide Image" />
            <p className="segment-in-use-paragraph">
              You are editing a segment which is currently being used in the
              campaign
            </p>
            <div className="button-container segment-in-use-modal-button-container">
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Ok
              </Button>
            </div>
          </div>
        }
        show={showModal}
      />
    </React.Fragment>
  );
};

export default SegmentInUseModal;
