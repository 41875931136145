import React from "react";
import PropTypes from "prop-types";
import Form from "src/shared/styleguide/form/Form";
import "react-select/dist/react-select.css";
import "src/components/reviews/manage_reviews/sidebar/SidebarComponent.scss";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import ascendingIcon from "src/assets/images/campaigns/common/ascending-icon.svg";
import descendingIcon from "src/assets/images/campaigns/common/descending-icon.svg";

const SidebarMenuDropdownGrouped = ({
  inputProps,
  isOptionDisabled,
  name,
  onChange,
  options,
  subTitle,
  title,
  value,
  sortIcon = false,
  sortByOnChange,
  defaultSortAscending = false
}) => {
  const [toggleSort, setToggleSort] = React.useState(defaultSortAscending);

  return (
    <div className="sg-sidebar-component-container">
      {title && (
        <div className="filter-title">
          {title}
          {sortIcon && (
            <PopupInfo
              tooltipClassName="tooltip-text tooltip-w-233"
              placement="right"
              message="Click here to change the sort order"
              icon={
                <img
                  src={toggleSort ? ascendingIcon : descendingIcon}
                  width="20"
                  height="18"
                  className="ml-2 sort-icon"
                  onClick={() => {
                    setToggleSort(!toggleSort);
                    sortByOnChange(value, toggleSort);
                  }}
                />
              }
            />
          )}
        </div>
      )}

      {subTitle && (
        <div style={{ marginBottom: "7px" }} className="filter-sub-title">
          {subTitle}
        </div>
      )}

      <Form.GroupedSelect
        value={value}
        name={name}
        clearable={false}
        onChange={onChange}
        options={options}
        isOptionDisabled={isOptionDisabled}
        inputProps={inputProps}
      />
    </div>
  );
};

// PropTypes for type-checking
SidebarMenuDropdownGrouped.propTypes = {
  inputProps: PropTypes.object.isRequired,
  isOptionDisabled: PropTypes.func,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  defaultSortAscending: PropTypes.bool, 
};

export default SidebarMenuDropdownGrouped;
