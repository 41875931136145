import React from "react";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import SidebarMenuComponent from "src/shared/sidebar-menu/SidebarMenuComponent";
import Form from "src/shared/styleguide/form/Form";
import CallCreditInfo from "./CallCreditInfo";
import PropTypes from "prop-types";
import classnames from "classnames";
import NumberUtil from "src/utils/NumberUtil";

const GetReviewsSidebar = ({ filters, setFilters, viewByCount }) => {
  // methods
  const getLabelWithCount = (label, value, count) => {
    let isActive = false;
    let countLabel = "";
    if (
      filters &&
      filters.selectedViewBy &&
      filters.selectedViewBy.value === value
    ) {
      isActive = true;
    }
    if (count !== undefined) {
      countLabel = <span title={count}> ({NumberUtil.toK(count, 1, true)})</span>;
    }
    return (
      <span
        className={classnames({
          "font-weight-bold": isActive,
        })}
      >
        {label}
        {countLabel}
      </span>
    );
  };

  // variables
  const viewByMenuItems = [
    {
      label: getLabelWithCount(
        "All Customers",
        "all_customers",
        viewByCount[0],
      ),
      labelString: "All Customers",
      value: "all_customers",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="12.727"
          viewBox="0 0 20 12.727"
        >
          <g transform="translate(0 -85.333)">
            <g transform="translate(0 85.333)">
              <g>
                <path
                  d="M88.06 90.788a2.727 2.727 0 1 0-2.727-2.727 2.722 2.722 0 0 0 2.727 2.727z"
                  transform="translate(-81.697 -85.333)"
                />
                <path
                  d="M300.29 256c-.264 0-.559.018-.877.05a3.811 3.811 0 0 1 1.786 3.132v2.273h5.455v-2.273c0-2.118-4.241-3.182-6.364-3.182z"
                  transform="translate(-286.654 -248.727)"
                />
                <path
                  d="M258.727 90.788A2.727 2.727 0 1 0 256 88.06a2.722 2.722 0 0 0 2.727 2.728z"
                  transform="translate(-245.091 -85.333)"
                />
                <path
                  d="M6.364 256C4.241 256 0 257.064 0 259.182v2.273h12.727v-2.273c0-2.118-4.241-3.182-6.363-3.182z"
                  transform="translate(0 -248.727)"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      label: getLabelWithCount(
        "Last Invited via Email",
        "last_invited_via_email",
        viewByCount[1],
      ),
      labelString: "Last Invited via Email",
      value: "last_invited_via_email",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="13.711"
          viewBox="0 0 18 13.711"
        >
          <g transform="translate(0 -61)">
            <g transform="translate(.904 61)">
              <g>
                <path
                  d="M41.235 61H26.4a1.564 1.564 0 0 0-.678.159l8.061 8.061 1.807-1.736 6.324-6.324a1.564 1.564 0 0 0-.679-.16z"
                  transform="translate(-25.721 -61)"
                />
              </g>
            </g>
            <g transform="translate(11.89 61.904)">
              <g>
                <path
                  d="M344.164 86.728l-5.951 5.951 5.951 5.951a1.564 1.564 0 0 0 .159-.678V87.406a1.563 1.563 0 0 0-.159-.678z"
                  transform="translate(-338.213 -86.728)"
                />
              </g>
            </g>
            <g transform="translate(0 61.904)">
              <g>
                <path
                  d="M.159 86.721A1.564 1.564 0 0 0 0 87.4v10.546a1.564 1.564 0 0 0 .158.678l5.952-5.952z"
                  transform="translate(0 -86.721)"
                />
              </g>
            </g>
            <g transform="translate(.904 68.601)">
              <g>
                <path
                  d="M35.955 277.211l-1.807 1.736a.527.527 0 0 1-.746 0l-1.737-1.737-5.951 5.951a1.564 1.564 0 0 0 .678.159h14.836a1.563 1.563 0 0 0 .678-.159z"
                  transform="translate(-25.714 -277.211)"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      label: getLabelWithCount(
        "Last Invited via Call",
        "last_invited_via_call",
        viewByCount[2],
      ),
      labelString: "Last Invited via Call",
      value: "last_invited_via_call",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.709"
          height="17.7"
          viewBox="0 0 17.709 17.7"
        >
          <g transform="translate(-.563 .35)">
            <path
              d="M4.344 11.3a18.035 18.035 0 0 0 6 4.7 9.337 9.337 0 0 0 3.357.993c.081 0 .159.007.24.007a2.78 2.78 0 0 0 2.156-.926.077.077 0 0 0 .014-.018 9.078 9.078 0 0 1 .68-.7c.166-.159.335-.324.5-.493a1.7 1.7 0 0 0-.007-2.533l-2.117-2.117a1.714 1.714 0 0 0-1.24-.571 1.771 1.771 0 0 0-1.254.567l-1.261 1.261c-.116-.067-.236-.127-.349-.183a4.356 4.356 0 0 1-.387-.211 13.347 13.347 0 0 1-3.188-2.9A7.686 7.686 0 0 1 6.4 6.45c.331-.3.641-.613.941-.919.106-.109.215-.218.324-.328a1.806 1.806 0 0 0 .585-1.268 1.792 1.792 0 0 0-.585-1.268l-1.05-1.05c-.123-.123-.24-.243-.359-.366-.232-.24-.476-.486-.715-.708A1.756 1.756 0 0 0 4.3 0a1.808 1.808 0 0 0-1.249.546L1.733 1.863A2.707 2.707 0 0 0 .927 3.6a6.519 6.519 0 0 0 .49 2.818A16.4 16.4 0 0 0 4.344 11.3zM1.786 3.671a1.863 1.863 0 0 1 .56-1.2l1.31-1.31c.2-.2-1.979 3.115-1.76 3.115a43.683 43.683 0 0 1 5.156.474c.236.22-1.652-3.133-1.416-2.89l.366.373 1.05 1.05a.957.957 0 0 1 .331.659c0 .218-.55.881-.659.99C6.4 5.263 2.1 4.51 2.09 4.517 1.8 4.809 5.566 6.693 5.569 6.7a25.429 25.429 0 0 0 4.632 5.1c.151.1.129 2.977.246 3.051.014.007 4.817.954 4.93.954l1.282-2.382-4.7-1.287L16.5 13.43c.2-.2-2.8-2.918-2.582-2.918a.885.885 0 0 1 .623.314l2.124 2.121a.849.849 0 0 1-.011 1.328c-.148.159-.3.31-.469.469a10.145 10.145 0 0 0-.736.764 1.934 1.934 0 0 1-1.511.641c-.06 0-.123 0-.183-.007a8.482 8.482 0 0 1-3.036-.909c-2.191-1.06 3.017.4 1.422-1.511-1.314-1.582-1.358-.428-1.943-2.006-.359-.971-8.468-7.337-8.412-8.045z"
              style={{
                strokeWidth: 0.7,
              }}
            />
          </g>
        </svg>
      ),
    },
    {
      label: getLabelWithCount(
        "Eligible for Invitation",
        "eligible_for_invitation",
        viewByCount[3],
      ),
      labelString: "Eligible for Invitation",
      value: "eligible_for_invitation",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17.739"
          viewBox="0 0 17 17.739"
        >
          <g transform="translate(-10.666)">
            <g transform="translate(10.666 4.877)">
              <g>
                <path
                  d="M12.287 143.585a.367.367 0 0 1-.137-.293h-.006v-2.514l-1.08.9a1.105 1.105 0 0 0-.4.851v10a1.1 1.1 0 0 0 .14.529l6.181-4.836.633-.494z"
                  transform="translate(-10.666 -140.778)"
                />
              </g>
            </g>
            <g transform="translate(20.714 4.877)">
              <g>
                <path
                  d="M307.226 141.678l-1.08-.9v2.514h-.006a.362.362 0 0 1-.137.292l-5.331 4.144 1.368 1.062 5.461 4.24a1.1 1.1 0 0 0 .123-.5v-10a1.105 1.105 0 0 0-.398-.852z"
                  transform="translate(-300.672 -140.778)"
                />
              </g>
            </g>
            <g transform="translate(11.368 11.826)">
              <g>
                <path
                  d="M39.335 341.536a1 1 0 0 0-1.212 0l-7.19 5.626a1.092 1.092 0 0 0 .407.08h14.782a1.1 1.1 0 0 0 .444-.095z"
                  transform="translate(-30.933 -341.331)"
                />
              </g>
            </g>
            <g transform="translate(12.883)">
              <g>
                <path
                  d="M74.667 0v7.211l5.349 4.157a1.715 1.715 0 0 1 1.868 0l5.348-4.157V0zm8.5 5.913h-1.848v1.848a.37.37 0 1 1-.739 0V5.913h-1.848a.37.37 0 0 1 0-.739h1.848V3.326a.37.37 0 1 1 .739 0v1.848h1.848a.37.37 0 1 1 0 .739z"
                  transform="translate(-74.667)"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      label: getLabelWithCount(
        "Did Not Respond",
        "never_responded",
        viewByCount[4],
      ),
      labelString: "Did Not Respond",
      value: "never_responded",
      iconSvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="16.979"
          viewBox="0 0 22 16.979"
        >
          <g transform="translate(-197 -339.5)">
            <path
              d="M15.6 39.4a3.52 3.52 0 0 0 1.639-.416v6.262c0 .034.01.067.007.1l-5.258-4.9 1.685-1.624a3.466 3.466 0 0 0 1.927.578zm-2.743-1.347a3.507 3.507 0 0 1-.753-2.173 3.6 3.6 0 0 1 .431-1.689H1.237a1.188 1.188 0 0 0-.782.295l8.183 7.625zM0 35.618v9.624a1.252 1.252 0 0 0 .054.315l5.221-5.029zm9.034 7.668a.562.562 0 0 1-.773.006l-2.143-2-5.3 5.1a1.175 1.175 0 0 0 .424.08h14.809a1.179 1.179 0 0 0 .6-.164l-5.469-5.1z"
              transform="translate(197 310)"
            />
            <g transform="translate(-3 -4)">
              <circle
                fill="#ffffff"
                cx="6.5"
                cy="6.5"
                r="6.5"
                transform="translate(209 343.5)"
              />
            </g>
            <g transform="translate(-3 -4)">
              <g transform="translate(210.601 345.1)">
                <path d="M8.537 1.463a5 5 0 1 0 0 7.074 5.008 5.008 0 0 0 0-7.074zm-1.564 5.51a.385.385 0 0 1-.544 0L5 5.544 3.5 7.04a.385.385 0 0 1-.541-.54L4.456 5 3.027 3.571a.385.385 0 0 1 .544-.544L5 4.456 6.36 3.1a.385.385 0 1 1 .544.544L5.544 5l1.429 1.428a.385.385 0 0 1 0 .545z" />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
  ];

  // render
  return (
    <div className="sidebar-view">
      {/* search */}
      <SidebarMenuComponent
        title="Search"
        menuItems={[]}
        menuItemsFilters={{}}
        clickHandler={() => {}}
        eventName="search"
      />

      <SearchInput
        value={filters.searchTerm}
        onChange={(evt) =>
          setFilters({ ...filters, searchTerm: evt.target.value })
        }
        placeholder="Search by name, email or phone"
      />
      <div className="mt-2">
        {/* view by */}
        <SidebarMenuComponent
          title="View By"
          menuItems={viewByMenuItems}
          menuItemsFilters={filters.selectedViewBy}
          clickHandler={(evtName, viewBy) => {
            setFilters({
              ...filters,
              selectedViewBy: viewBy,
            });
          }}
          eventName="viewBy"
        />

        {/* show customers with */}
        <SidebarMenuComponent
          title="Show Customers with"
          menuItems={[]}
          menuItemsFilters={{}}
          clickHandler={() => {}}
          eventName="showCustomersWith"
        />
        <Form.Group controlId="missingEmailAddress">
          <Form.Check
            custom
            checked={filters.missingEmailAddress}
            onChange={() =>
              setFilters({
                ...filters,
                missingEmailAddress: !filters.missingEmailAddress,
              })
            }
            type="checkbox"
            label={
              <span className="mb-0 h4 ml-2 text-dark">
                Missing Email Address
              </span>
            }
            className="text-dark"
          />
        </Form.Group>
        <Form.Group controlId="missingPhoneNumber">
          <Form.Check
            custom
            checked={filters.missingPhoneNumber}
            onChange={() =>
              setFilters({
                ...filters,
                missingPhoneNumber: !filters.missingPhoneNumber,
              })
            }
            type="checkbox"
            label={
              <span className="mb-0 h4 ml-2 text-dark">
                Missing Phone Number
              </span>
            }
            className="text-dark"
          />
        </Form.Group>
      </div>

      <CallCreditInfo />
    </div>
  );
};

GetReviewsSidebar.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  viewByCount: PropTypes.array.isRequired,
};

export default GetReviewsSidebar;
