import React from "react";

// Setting context
const SettingsContext = React.createContext({});

// Setting context provider
const SettingsProvider = ({ children, client }) => {
  // state
  const [showAppointment, setShowAppointment] = React.useState(false);
  const [showLearningCenter, setShowLearningCenter] = React.useState(false);
  const [activeTabForLearningCenter, setActiveTabForLearningCenter] = React.useState(false);
  const [companyAppointmentEnabled, setCompanyAppointmentEnabled] = React.useState(false);
  const [logoEnabled, setLogoEnabled] = React.useState(false);
  const [followUpEnabled, setFollowUpEnabled] = React.useState(false);
  const [smartInviteEnabled, setSmartInviteEnabled] = React.useState(false);
  const [textQuickReplyEnabled, setTextQuickReplyEnabled] = React.useState(false);
  const [textAutoResponseEnabled, setTextAutoResponseEnabled] = React.useState(false);
  const [textMessageTemplateEnabled, setTextMessageTemplateEnabled] = React.useState(false);
  const [websiteEnabled, setWebsiteEnabled] = React.useState(false);

  // render
  return (
    <SettingsContext.Provider
      value={{
        showAppointment,
        setShowAppointment,
        showLearningCenter,
        setShowLearningCenter,
        activeTabForLearningCenter,
        setActiveTabForLearningCenter,
        companyAppointmentEnabled,
        setCompanyAppointmentEnabled,
        logoEnabled,
        setLogoEnabled,
        followUpEnabled,
        setFollowUpEnabled,
        smartInviteEnabled,
        setSmartInviteEnabled,
        textAutoResponseEnabled,
        setTextAutoResponseEnabled,
        textMessageTemplateEnabled,
        setTextMessageTemplateEnabled,
        textQuickReplyEnabled,
        setTextQuickReplyEnabled,
        websiteEnabled,
        setWebsiteEnabled
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};


export { SettingsContext, SettingsProvider };
export default SettingsContext;
