import { gql } from "apollo-boost";

const CREATE_FILTER = gql`
  mutation($companyId: ID!, $metaData: JSON!, $name: String!) {
    createFilter(companyId: $companyId, metaData: $metaData, name: $name) {
      companyId
      customersCount
      createdAt
      metaData
      name
      id
    }
  }
`;

const UPDATE_FILTER = gql`
  mutation($id: ID!, $metaData: JSON!, $name: String!) {
    updateFilter(id: $id, metaData: $metaData, name: $name) {
      customersCount
      metaData
      name
      id
    }
  }
`;

const CREATE_CUSTOM_FILTER = gql`
  mutation($companyId: ID!, $name: String!) {
    createCustomFilter(
      companyId: $companyId, 
      name: $name
    ) {
      createdAt
      name
      id
    }
  }
`;

const CREATE_CUSTOM_FILTER_UPLOAD = gql`
  mutation($customFilterId: ID!, $filename: String!) {
    createCustomFilterUpload(
      customFilterId: $customFilterId, 
      filename: $filename
    ) {
      customFilterId
      filename
      id
      status
    }
  }
`;

const CREATE_CUSTOM_FILTER_CSV = gql`
  mutation createCustomFilterOutputCsv($customFilterId: ID!) {
    createCustomFilterOutputCsv(
      customFilterId: $customFilterId, 
    )
  }
`;

export { 
  CREATE_FILTER, 
  UPDATE_FILTER, 
  CREATE_CUSTOM_FILTER,
  CREATE_CUSTOM_FILTER_UPLOAD,
  CREATE_CUSTOM_FILTER_CSV,  
 };
