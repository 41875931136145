import React from "react";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

const MiniGridWidget = ({ children, className }) => (
  <Col
    xl={2}
    lg={4}
    md={4}
    sm={6}
    xs={12}
    className={classnames("mb-4", className)}
  >
    {children}
  </Col>
);

MiniGridWidget.propType = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default MiniGridWidget;
