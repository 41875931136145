import React from "react";
import classnames from "classnames";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import Slider from "rc-slider/lib/Slider";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import StringUtil from "src/utils/StringUtil";
import MultiSelectFilter from "./MultiSelectFilter";
import MultiItemTextArea from "./MultiItemTextArea";
import "./Form.scss";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { numberFormatter } from "../../Utils";

// Select
Form.Select = ({ ...props }) => (
  <div className="sg-select">
    <Select {...props} clearable={false} />
  </div>
);

// Select
Form.GroupedSelect = ({
  inputProps,
  isOptionDisabled,
  name,
  onChange,
  options,
  subTitle,
  title,
  value,
  ...props
}) => (
  <div className="sg-dropdown-grouped-container">
    <div className="sg-select">
      <Select
        {...props}
        value={value}
        name={name}
        clearable={false}
        onChange={onChange}
        options={options}
        isOptionDisabled={isOptionDisabled}
        inputProps={inputProps}
      />
    </div>
  </div>
);

Form.MultiItemTextArea = ({
  array,
  setArray,
  arrayItemValidator,
  keyStrokeValidator,
}) => (
  <MultiItemTextArea
    array={array}
    setArray={setArray}
    arrayItemValidator={arrayItemValidator}
    keyStrokeValidator={keyStrokeValidator}
  />
);

Form.MultiSelect = ({
  disabled,
  inputProps,
  name,
  onChange,
  options,
  placeholder,
  subTitle,
  title,
  value,
  ...props
}) => {
  const optionRender = (node) => {
    const isChecked = !!value.filter((item) => item.value === node.value)
      .length;

    return (
      <div className="dropdown-option-container">
        <Form.Group className="dropdown-checkbox-container">
          <Form.Check
            custom
            checked={isChecked}
            onChange={() => {}}
            type="checkbox"
            label=""
          />
        </Form.Group>
        <div className="dropdown-option-label">{node.label}</div>
      </div>
    );
  };

  return (
    <div className="sg-multi-select-container">
      <div className="sg-multi-select">
        <Select
          {...props}
          closeOnSelect={false}
          clearable={false}
          disabled={disabled}
          inputProps={inputProps}
          multi
          name={name}
          onChange={onChange}
          optionRenderer={optionRender}
          options={options}
          placeholder={placeholder}
          removeSelected={false}
          value={value}
        />
      </div>
    </div>
  );
};

Form.MultiSelectFilter = Form.MultiSelectFilter = ({
  allPagination,
  disabled,
  inputProps,
  name,
  onChange,
  options,
  pageLength,
  placeholder,
  value,
  ...props
}) => {
  return (
    <MultiSelectFilter
      {...props}
      allPagination={allPagination}
      disabled={disabled}
      inputProps={inputProps}
      name={name}
      onChange={onChange}
      options={options}
      pageLength={pageLength}
      placeholder={placeholder}
      value={value}
    />
  );
};

// Range
Form.Range = ({
  fgColor = "#f47821",
  bgColor = "#d6d3d3",
  height = 8,
  ...props
}) => (
  <Slider
    trackStyle={{
      backgroundColor: fgColor,
      height,
      top: 0,
    }}
    handleStyle={{
      backgroundColor: fgColor,
      borderColor: fgColor,
      height: height * 2.5,
      width: height * 2.5,
      top: "-50%",
      margin: 0,
    }}
    railStyle={{
      backgroundColor: bgColor,
      height,
      top: 0,
    }}
    {...props}
  />
);

// Multi Range
Form.MultiRange = ({
  className,
  max,
  min,
  onChange,
  onChangeComplete,
  value,
  formattedValueNeeded = false,
  ...props
}) => (
  <div className="sg-multi-range-container">
    <InputRange
      maxValue={max}
      minValue={min}
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
      formatLabel={(value) =>
        formattedValueNeeded ? numberFormatter(value, 0) : value
      }
      {...props}
    />
  </div>
);

// DatePicker
Form.DatePicker = ({ className, ...props }) => (
  <div className="sg-datepicker">
    <DatePicker
      className={classnames("form-control shadow-none w-100", className)}
      showDisabledMonthNavigation
      popperPlacement="bottom-start"
      {...props}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const year = date.getFullYear();
        const month = date.toLocaleString("default", { month: "long" });
        return (
          <Row>
            <Col className="text-left" xs={2}>
              <Button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                size="xs"
                variant="link"
                className={classnames("sg-datepicker-header-prev", {
                  "sg-datepicker-header-prev-disabled": prevMonthButtonDisabled,
                })}
              >
                <span className="fa text-dark fa-chevron-left" />
              </Button>
            </Col>
            <Col className="text-center" xs={8}>
              <span className="sg-datepicker-header-month">{month}</span>,{" "}
              <span className="sg-datepicker-header-year">{year}</span>
            </Col>
            <Col className="text-right" xs={2}>
              <Button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                size="xs"
                variant="link"
                className={classnames("sg-datepicker-header-next", {
                  "sg-datepicker-header-next-disabled": nextMonthButtonDisabled,
                })}
              >
                <span className="fa text-dark fa-chevron-right" />
              </Button>
            </Col>
          </Row>
        );
      }}
    />
  </div>
);

// TimePicker
Form.TimePicker = ({ className, disabled, ...props }) => {
  return (
    <div className={classnames("sg-timepicker")}>
      <TimePicker
        prefixCls="sg-timepicker rc-time-picker"
        className={classnames("form-control w-100", className, {
          "sg-timepicker-disabled": disabled,
        })}
        clearIcon={<React.Fragment />}
        {...props}
      />
    </div>
  );
};

// Autocomplete
const Autocomplete = ({
  placeholder = "",
  options,
  selectedOption,
  setSelectedOption,
  onChange = null,
  noOptionsText = "No Options",
  disabled = false,
  showClearIcon = false,
  onClear = () => {},
  ...props
}) => {
  // variables
  const ClearIcon = () => {
    return (
      <svg
        className="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="22.627"
        height="22.627"
        fill="#667587"
        viewBox="0 0 22.627 22.627"
      >
        <path
          d="M13.657 2.343A8 8 0 0 0 2.343 13.657 8 8 0 0 0 13.657 2.343zM8 14.75A6.75 6.75 0 1 1 14.75 8 6.758 6.758 0 0 1 8 14.75zm.625-7.376h2.813v1.25H8.625v2.813h-1.25V8.624H4.562v-1.25h2.813V4.562h1.25zm0 0"
          transform="rotate(-45 13.657 5.657)"
        />
      </svg>
    );
  };

  // state
  const [word, setWord] = React.useState("");

  // methods
  const handleChange = (selectedOption) => {
    if (onChange) {
      return onChange({ selectedOption, setSelectedOption });
    }
    return setSelectedOption(selectedOption);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Form.Select
        key={selectedOption}
        value={selectedOption}
        onInputChange={(word) => {
          setWord(word);
        }}
        disabled={disabled}
        onChange={handleChange}
        options={options.map((option) => {
          let { label } = option;
          if (option && option.label && typeof option.label === "string") {
            label = StringUtil.highlightWithinWord(option.label, word);
          }
          return {
            ...option,
            label,
          };
        })}
        placeholder={placeholder}
        className="autocomplete-select"
        {...props}
      />
      {(showClearIcon && (word || selectedOption)) || disabled ? (
        <div
          style={{
            position: "absolute",
            right: 8,
            top: 0,
            bottom: 0,
            display: "grid",
            placeItems: "center",
          }}
          onClick={onClear}
        >
          <ClearIcon />
        </div>
      ) : null}
    </div>
  );
};

Form.Autocomplete = Autocomplete;

// Be sure to include all file validations in parent formik.validationSchema as well
const MultiUpload = ({
  ERROR_POSITION = "right",
  FILE_SIZE,
  FILE_HEIGHT,
  FILE_WIDTH,
  VALUE_NAME = "attachments",
  FILE_TYPES,
  files,
  setFiles,
  filesData,
  setFilesData,
  validAttachmentRes,
  setValidAttachmentRes,
  checkFileType,
  checkFileSize,
  checkFileResolution,
  formik,
  isLocked,
}) => {
  // Methods
  // Clean up attachments input `id`s and `for`s
  const uploadInputCleanup = (inputs) => {
    inputs.forEach((input, i) => {
      const label = document.querySelector(`[for="${input.id}"]`);
      input.id = `file-upload-${i}`;
      label.setAttribute("for", input.id);
      input.nextSibling.setAttribute("for", input.id);
      if (i === inputs.length - 1) {
        input.value = "";
      }
    });
  };

  // Track file uploads to trigger useEffect for display purposes
  const uploadHelper = (item, i) => {
    const itemKey = `file-upload-${i}`;
    const itemObj = item.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(item.target.files[0]);
    reader.onload = () => {
      let file = "";
      if (reader.result) {
        file = reader.result.substring(
          reader.result.indexOf(",") + 1,
          reader.result.length,
        );
      }

      setFilesData([
        ...filesData,
        {
          filename: itemObj.name,
          file: file,
          details: itemObj,
        },
      ]);
    };

    setTimeout(() => {
      setFiles({
        ...files,
        [itemKey]: { filename: itemObj.name },
      });
    }, 100);

    formik.setFieldTouched(VALUE_NAME, true);
  };

  const fileRemoveHelper = (item) => {
    setValidAttachmentRes(true);
    const inputs = document.querySelectorAll('input[type="file"]');
    const thisKey = item.target.getAttribute("for");
    const newFiles = { ...files };
    const removedFile = { ...newFiles[thisKey] };

    delete newFiles[thisKey];

    if (thisKey === "file-upload-0") {
      newFiles["file-upload-0"] = newFiles["file-upload-1"];
      newFiles["file-upload-1"] = newFiles["file-upload-2"];
    } else if (thisKey === "file-upload-1") {
      newFiles["file-upload-1"] = newFiles["file-upload-2"];
    }
    Object.keys(newFiles).forEach((key) =>
      newFiles[key] === undefined ? delete newFiles[key] : {},
    );
    delete newFiles[`file-upload-2`];

    if (removedFile && removedFile.id) {
      setFilesData([...filesData, { id: removedFile.id, deleted: true }]);
    } else {
      setFilesData(
        [...filesData].filter((item) => item.file === removedFile.file),
      );
    }

    setFiles({ ...newFiles });

    uploadInputCleanup(inputs);
    formik.setFieldTouched(VALUE_NAME, false);
  };

  // Effects
  React.useEffect(() => {
    const inputs = document.querySelectorAll('input[type="file"]');
    const maxQuantityReached = ![...inputs].some((input) => {
      return !files[input.id] && !input.value;
    });
    const indexOffset = maxQuantityReached ? 1 : 2;

    if (inputs.length < 3) {
      uploadInputCleanup(inputs);
    }

    [...inputs].some((input) => {
      return !files[input.id] && !input.value;
    });
    inputs.forEach((input, i) => {
      const label = document.querySelector(`[for="${input.id}"]`);
      const removeButton = document.querySelector(
        `[for="${input.id}"] ~ .remove-file-button`,
      );

      // If attachment is invalid, add invalid class to apply disabled styling
      if (!validAttachmentRes && i === inputs.length - indexOffset) {
        label.classList.add("invalid");
        removeButton.classList.add("invalid");
      } else {
        label.classList.remove("invalid");
        removeButton.classList.remove("invalid");
      }

      // If attachment is invalid, disable remove buttons except for attachment that needs to be replaced
      if (!validAttachmentRes && i < inputs.length - indexOffset) {
        removeButton.classList.add("click-through");
      } else {
        removeButton.classList.remove("click-through");
      }
    });
  }, [files]);

  React.useEffect(() => {
    if (checkFileResolution) {
      checkFileResolution(filesData, setValidAttachmentRes);
    }
    setTimeout(() => {
      formik.setFieldValue(VALUE_NAME, filesData);
    }, 75);
  }, [filesData]);

  const errorDialog = (
    <div className={`attachments-validation-messages ${ERROR_POSITION}`}>
      {checkFileType && FILE_TYPES ? (
        <div
          className={
            checkFileType(filesData) ? "sg-text-success" : "sg-text-danger"
          }
        >
          - Only {FILE_TYPES} formats are accepted.
        </div>
      ) : null}

      {ERROR_POSITION !== "right" ||
      (checkFileResolution &&
        FILE_WIDTH &&
        FILE_HEIGHT &&
        filesData.length &&
        filesData[filesData.length - 1].details &&
        filesData[filesData.length - 1].details.type !== "application/pdf") ? (
        <div
          className={validAttachmentRes ? "sg-text-success" : "sg-text-danger"}
        >
          - The image should be {FILE_WIDTH} x {FILE_HEIGHT} pixels or larger.
        </div>
      ) : null}

      {checkFileSize && FILE_SIZE ? (
        <div
          className={
            checkFileSize(filesData) ? "sg-text-success" : "sg-text-danger"
          }
        >
          - The file size should not exceed {FILE_SIZE}MB
        </div>
      ) : null}
    </div>
  );

  return (
    <div className="position-relative">
      {ERROR_POSITION === "top" ? errorDialog : null}
      {[
        ...Array(
          Object.keys(files).length < 3 ? Object.keys(files).length + 1 : 3,
        ),
      ].map((item, i) => {
        const inputId = `file-upload-${i}`;
        const invalidItemPresent =
          (formik.touched[VALUE_NAME] && formik.errors[VALUE_NAME]) ||
          !validAttachmentRes;
        return (
          <div className="upload-parent" key={`upload-parent-${i}`}>
            <label
              htmlFor={inputId}
              className={`custom-file-upload ${
                !invalidItemPresent ? "cursor-pointer" : "click-through"
              } 
              ${files[inputId] ? "" : "is-empty"} 
              `}
            >
              <div className="upload-file-button-container">
                <div className="upload-file-button"></div>
                {files[inputId] ? files[inputId].filename : ""}
              </div>
            </label>
            <input
              accept="image/png,image/jpeg,application/pdf"
              disabled={isLocked || invalidItemPresent}
              hidden
              id={inputId}
              onChange={(item) => {
                uploadHelper(item, i);
              }}
              type="file"
            />
            <div
              onClick={(item) => fileRemoveHelper(item)}
              className="remove-file-button"
              htmlFor={inputId}
            ></div>
          </div>
        );
      })}
      {(!formik.isSubmitting &&
        ERROR_POSITION === "right" &&
        formik.touched[VALUE_NAME]) ||
      ERROR_POSITION === "bottom"
        ? errorDialog
        : null}
    </div>
  );
};

Form.MultiUpload = MultiUpload;

export default Form;
