import React, { useState } from "react";
import ListCard from "src/shared/styleguide/card/ListCard";
import { Accordion } from "react-bootstrap";
import archiveImage from "src/assets/images/segments/archive_white.svg";
import defaultImage from "src/assets/images/segments/default.svg";
import favoriteImage from "src/assets/images/segments/star_white.svg";
import PropTypes from "prop-types";
import { COLOR_PALETTE } from "src/shared/Constants.js";

const BaseSegmentCard = ({
  cardBody,
  highlight,
  cardKey,
  expand,
  toggleContent,
  archived,
  favorite,
  cardTitle,
  filterBy,
}) => {
  const showListCardFlag = archived || favorite ? true : false;

  // state
  const [cardBodyShow, setCardBodyShow] = useState(false);

  // methods

  /**
   * Show or Hide the card body
   */
  const toggleCardBody = (evt) => {
    setCardBodyShow(!cardBodyShow);
  };

  return (
    <div>
      <ListCard
        highlight={showListCardFlag ? highlight : null}
        flagColor={showListCardFlag ? COLOR_PALETTE.lightSlateGrey : ""}
        flagIcon={
          archived ? archiveImage : favorite ? favoriteImage : defaultImage
        }
        className={"segment-list-card"}
        cardKey={`segment-${cardKey}`}
      >
        <Accordion
          defaultActiveKey={`${expand ? "0" : "null"}`}
          activeKey={`${
            expand ? (cardBodyShow ? "1" : "0") : cardBodyShow ? "0" : "1"
          }`}
          className={`${filterBy === "archived" ? "archived-segment" : ""}`}
        >
          <Accordion.Toggle
            as="div"
            eventKey="0"
            expanded={expand ? "0" : "1"}
            onClick={toggleCardBody}
          >
            <div className="segment-list-card-head">
              {toggleContent ? (
                <div className="segment-list-card-title">
                  <div
                    className={`chevron ${
                      expand
                        ? cardBodyShow
                          ? "collapsed"
                          : ""
                        : cardBodyShow
                        ? ""
                        : "collapsed"
                    }`}
                    style={{
                      color: `${
                        expand
                          ? cardBodyShow
                            ? ""
                            : COLOR_PALETTE.carrotOrange
                          : cardBodyShow
                          ? COLOR_PALETTE.carrotOrange
                          : ""
                      }`,
                    }}
                  ></div>
                  <div className="card-title">{cardTitle}</div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="card-content mb-4">{cardBody}</div>
            </div>
          </Accordion.Toggle>
          {toggleContent ? (
            <Accordion.Collapse eventKey="0">
              <div className="card-bottom-body">{toggleContent}</div>
            </Accordion.Collapse>
          ) : null}
        </Accordion>
      </ListCard>
    </div>
  );
};

BaseSegmentCard.propTypes = {
  cardBody: PropTypes.node,
  highlight: PropTypes.bool,
  cardKey: PropTypes.number,
  expand: PropTypes.bool,
  toggleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  cardType: PropTypes.string,
  archived: PropTypes.bool,
  favorite: PropTypes.bool,
  cardTitle: PropTypes.object,
  filterBy: PropTypes.string,
};

export default BaseSegmentCard;
