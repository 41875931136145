import React from "react";
import "./ReviewHeader.scss";
import { getNameInitials, dateFormatter, contactPageUrl} from "../../../../../../shared/Utils";
import StarRatings from "react-star-ratings";
import customerlobby_icon from "../../../../../../styles/assets/review-settings-icon/spotlight.svg";
import google_icon from "../../../../../../styles/assets/review-settings-icon/google-icon.svg";
import { PLATFORM_TYPE, REVIEW_SOURCE_DISPLAY } from "../../../../../../shared/Constants";
import { DEFAULT_STAR_RATING_CONFIG } from "../../../../../../shared/Config";

/**
 * Review Header Component.
 */

/**
 * return image for platform
 * @param {*} platform Platform names
 */
const getPlatformImage = platform => {
  switch (platform) {
    case PLATFORM_TYPE.CUSTOMER_LOBBY:
      return customerlobby_icon;
    case PLATFORM_TYPE.GOOGLE:
      return google_icon;
    default:
      return customerlobby_icon;
  }
};

const starRating = totalScore => {
  var val = Math.floor(totalScore * 5 );
  return val;
}

/**
 * Render the review with props
 * @param Review Header Name Initials - Initial Letters of Reviewer.
 * @param customer_full_name - Reviewer Name.
 * @param platform -  Platform of reviewer.
 * @param StarRatings - Star Ratings of Reviewer.
 * @param source - Source of Reviewer.
 * @param reviewedAt - Review Date.
 */
const ReviewHeader = ({ totalScore, source, destination, fullName, reviewDate, companyId, contactId }) => {

  return (
    <div className="row">
      <div className="col-md-1 px-sm-0 col-12 mt-md-4 d-flex justify-content-md-center">
        <div className="ellipse d-flex align-items-center">
          <div className="name-initials w-100">
            {getNameInitials(fullName)}
          </div>
        </div>
      </div>
      <div className="col-md-11 col-12 pl-lg-0">
        <div className="row mt-md-4 mt-2">
          <div className="col-9 col-md-10 reviewer-name">
            <a className="reviews-learn-more" href={contactPageUrl(contactId, companyId)}>
              {fullName ? fullName : 'N/A'}
            </a>
          </div>
          <div className="col-3 col-md-2 pl-0 text-right">
            <img
              alt="platform"
              src={getPlatformImage(destination)}
              className="platform-image mt-n3"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-inline-flex ">
            <StarRatings
              rating={starRating(totalScore)}
              numberOfStars={5}
              starDimension={DEFAULT_STAR_RATING_CONFIG.dimension}
              starSpacing={DEFAULT_STAR_RATING_CONFIG.spacing}
              starEmptyColor={DEFAULT_STAR_RATING_CONFIG.emptyColor}
              starRatedColor={DEFAULT_STAR_RATING_CONFIG.ratedColor}
            />
            <div className="after-rating">
              <span className="dot">.</span>
              <span className="review-type pt-sm-1">{REVIEW_SOURCE_DISPLAY[source]}</span>
              <span className="dot">.</span>
              <span className="review-type pt-sm-1">
                {dateFormatter(reviewDate)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewHeader;
