import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "src/shared/styleguide/button/Button";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import ResultsNotFound from "src/shared/errorpages/ResultsNotFound";
import CustomerDetailsSidebar from "src/shared/customer-details-sidebar/CustomerDetailsSidebar";
import SearchInput from "src/shared/styleguide/form/SearchInput";
import "./CustomersComponent.scss";
import { Row, Col } from "react-bootstrap";
import { useApolloClient } from "@apollo/react-hooks";
import "./CustomersComponent.scss";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import ellipsisV from "src/styles/assets/ellipsis-v.svg";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import StringUtil from "src/utils/StringUtil";
import * as utils from "src/shared/Utils";
import UserInitials from "src/shared/styleguide/common/UserInitials";
import format from "date-fns/format";
import { showCompanyId } from "src/shared/SessionHelper";
import { GET_CONTACT } from "src/graphql/customers_segments/customers/queries";
import { NO_CACHE } from "src/shared/Constants";
import { useHistory } from "react-router-dom";
import { numberFormatter } from "../../shared/Utils";

const CustomersComponent = ({
  adHocButton,
  currentPage,
  customersList,
  getCustomers,
  setCurrentPage,
  totalCustomersFound,
  metaData = {},
  setSegmentSearchTerm = null,
}) => {
  // variables
  const perPage = 10;
  const client = useApolloClient();
  const history = useHistory();

  // state
  const [dataReady, setDataReady] = useState(false);
  const [customerEmptyState, setCustomerEmptyState] = useState(false);
  const [detailsShown, setDetailsShown] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [queriedContact, setQueriedContact] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterByType, setFilterByType] = useState("all");
  const [pageCount, setPageCount] = useState(10);

  // table headers
  const HEADER_ITEMS = [
    { columnLabel: "NAME", columnValue: "first_name", sortable: true },
    { columnLabel: "EMAIL ADDRESS", columnValue: "email", sortable: true },
    { columnLabel: "PHONE NUMBER", columnValue: "phone", sortable: false },
    {
      columnLabel: "MAILING ADDRESS",
      columnValue: "address",
      sortable: true,
    },
    {
      columnLabel: "LAST SERVICE DATE",
      columnValue: "last_service_date",
      sortable: true,
    },
    {
      columnLabel: "RECENT ACTIVITY",
      columnValue: "recent_activity",
      sortable: true,
    },
    { columnLabel: "CREATED DATE", columnValue: "created_at", sortable: true },
    { columnLabel: "", columnValue: "", sortable: false },
  ];

  const SOURCES = [
    { label: "direct", value: "direct_connect" },
    { label: "uploads", value: "csv_upload" },
    { label: "custom", value: "custom" },
    { label: "web", value: "web" },
    { label: "handwritten", value: "handwritten" },
    { label: "kiosk", value: "kiosk" },
    { label: "manual", value: "manual_input" },
    { label: "ncoa", value: "ncoa" },
    { label: "review me", value: "reviewme" },
    { label: "unknown", value: "unknown" },
  ];

  // methods
  /**
   *
   * @param {*} selected --  Selected page
   * Fetch the data for selected page
   */
  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getCustomers({
      page: selected,
      metadata: metaData,
      searchTerm: searchTerm,
      sortBy: sortBy,
      sortOrder: sortOrder,
    });
  };

  /**
   *
   * @param {*} columnValue -- Column to sort
   * @param {*} sortDirection -- sort direction
   *
   * Sorts the table as per columnvalue
   */
  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setSortBy(columnValue);
    setSortOrder(sortDirection);
  };

  /**
   * Reset the filter to initial state
   */
  const resetFilters = () => {
    setSearchTerm("");
  };

  /**
   *
   * @returns The filters text to be shown above customers table
   */
  const showFiltersText = () => {
    if (searchTerm.length >= 3) {
      return `Showing results for - All Customers having ${searchTerm} (${numberFormatter(
        totalCustomersFound,
        0,
      )})`;
    } else {
      return `Showing results for - All (${numberFormatter(
        totalCustomersFound,
        0,
      )})`;
    }
  };

  /**
   *
   * @param {*} phoneMobile -- Phone mobile
   * @param {*} phoneAlt -- Alternate phone
   * @param {*} phoneDaytime -- Phone day time
   * @returns The formatted phone
   */
  const formattedPhoneNumber = (phoneMobile, phoneAlt, phoneDaytime) => {
    const phoneNumber = phoneMobile
      ? phoneMobile
      : phoneAlt
      ? phoneAlt
      : phoneDaytime
      ? phoneDaytime
      : null;

    return phoneNumber
      ? StringUtil.highlightWithinWord(
          utils.formatPhoneNumber(phoneNumber),
          searchTerm,
          true,
        )
      : "N/A";
  };

  /**
   *
   * @param {*} name -- name of the customer
   * @returns The formatted customer name
   */
  const formattedName = (name) => {
    return name
      ? StringUtil.highlightWithinWord(name, searchTerm, true)
      : "N/A";
  };

  /**
   *
   * @param {*} contactId -- sets query contact for selected contactId
   */
  const getCustomerDetailsData = (contactId) => {
    client
      .query({
        query: GET_CONTACT,
        variables: { contactId: contactId, esContact: true },
        fetchCounts: NO_CACHE,
      })
      .then((response) => {
        if (!response.loading && response.data && response.data.contact) {
          setQueriedContact({ ...response.data.contact });
        }
      })
      .then(() => {
        setDetailsShown(true);
      })
      .catch((e) => {
        setDetailsShown(false);
        console.error("Get Customer Details Error: ", e);
      });
  };

  /**
   *
   * @param {*} customer -- Customers data
   * @returns The customer's row to appear in customer's table
   */
  const getCustomerRow = (customer) => {
    const {
      contactId,
      address1,
      address2,
      city,
      country,
      createdAt,
      lastServiceDate,
      email,
      state,
      zip,
      fullName,
      source,
      firstName,
      lastName,
      recentActivityDatetime,
      recentActivityName,
      phoneAlt,
      phoneMobile,
      phoneDaytime,
      formattedLastServiceDate,
      communicationInfoEmail,
      communicationInfoPhone,
      communicationInfoMailing,
      customerWantsEmail,
      dcWantsEmail,
      memberWantsEmail,
      customerWantsCall,
      dcWantsCall,
      memberWantsCall,
      customerWantsMail,
      dcWantsMail,
      memberWantsMail,
      customerWantsSms,
      dcWantsSms,
      memberWantsSms,
    } = customer;

    // complete address field
    const address =
      (address1 ? address1 : "") +
      (address2 ? `, ${address2}` : "") +
      (city ? `, ${city}` : "") +
      (state ? `, ${state}` : "") +
      (zip ? `, ${zip}` : "") +
      (country ? `, ${country}` : "");

    const smsFilterIndex = filterByType === "unsubscribed_sms";
    const phoneFilterIndex = filterByType === "unsubscribed_call";
    const allIndex = filterByType === "all";
    const phoneToolTip =
      smsFilterIndex >= 0 || phoneFilterIndex >= 0 || allIndex === 0
        ? (customerWantsSms === false ||
            dcWantsSms === false ||
            memberWantsSms === false) &&
          (customerWantsCall === false ||
            dcWantsCall === false ||
            memberWantsCall === false)
          ? smsFilterIndex > phoneFilterIndex
            ? "SMS"
            : "Call"
          : customerWantsSms === false ||
            dcWantsSms === false ||
            memberWantsSms === false
          ? "SMS"
          : customerWantsCall === false ||
            dcWantsCall === false ||
            memberWantsCall === false
          ? "Call"
          : null
        : null;

    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <UserInitials
              firstName={firstName}
              lastName={lastName}
              size="2.2rem"
            />
            <span
              className="text-capitalize cursor-pointer"
              onClick={() => {
                getCustomerDetailsData(customer.contactId);
              }}
            >
              {formattedName(
                fullName
                  ? fullName
                  : firstName && lastName
                  ? firstName + " " + lastName
                  : firstName
                  ? firstName
                  : lastName
                  ? lastName
                  : null,
              )}
            </span>
          </div>
        ),
      },
      {
        node:
          communicationInfoEmail && communicationInfoEmail !== "valid" ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={
                StringUtil.capitalize(communicationInfoEmail) + " email address"
              }
              icon={
                <span>
                  {communicationInfoEmail === "missing"
                    ? "N/A"
                    : StringUtil.highlightWithinWord(email, searchTerm)}
                </span>
              }
            />
          ) : email &&
            (customerWantsEmail === false ||
              dcWantsEmail === false ||
              memberWantsEmail === false) ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={"Unsubscribed From Email"}
              icon={
                <span>{StringUtil.highlightWithinWord(email, searchTerm)}</span>
              }
            />
          ) : email ? (
            StringUtil.highlightWithinWord(email, searchTerm)
          ) : (
            "N/A"
          ),
      },
      {
        node:
          communicationInfoPhone && communicationInfoPhone !== "valid" ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={StringUtil.capitalize(communicationInfoPhone) + " phone"}
              icon={
                <span>
                  {communicationInfoPhone === "missing"
                    ? "N/A"
                    : StringUtil.highlightWithinWord(
                        formattedPhoneNumber(
                          phoneMobile,
                          phoneAlt,
                          phoneDaytime,
                        ),
                        searchTerm,
                      )}
                </span>
              }
            />
          ) : formattedPhoneNumber(phoneMobile, phoneAlt, phoneDaytime) !==
              "N/A" && phoneToolTip ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={`Unsubscribed From ${phoneToolTip}`}
              icon={
                <span>
                  {formattedPhoneNumber(phoneMobile, phoneAlt, phoneDaytime)}
                </span>
              }
            />
          ) : (
            formattedPhoneNumber(phoneMobile, phoneAlt, phoneDaytime)
          ),
      },
      {
        node:
          communicationInfoMailing && communicationInfoMailing !== "valid" ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={
                StringUtil.capitalize(communicationInfoMailing) +
                " mailing address"
              }
              icon={
                <span>
                  {communicationInfoMailing === "missing" || !address
                    ? "N/A"
                    : address}
                </span>
              }
            />
          ) : address &&
            (customerWantsMail === false ||
              dcWantsMail === false ||
              memberWantsMail === false) ? (
            <PopupInfo
              tooltipClassName="customers-table-address-column-tooltip"
              placement="top"
              message={"Unsubscribed From Mail"}
              icon={<span>{address}</span>}
            />
          ) : address ? (
            <PopupInfo
              tooltipClassName="customers-table-address-column-tooltip"
              placement="top"
              message={address}
              icon={<span>{address}</span>}
            />
          ) : (
            "N/A"
          ),
      },
      {
        node: (
          <span>
            {formattedLastServiceDate && formattedLastServiceDate.trim()
              ? formattedLastServiceDate
              : "N/A"}
          </span>
        ),
      },
      {
        node: recentActivityDatetime ? (
          <PopupInfo
            tooltipClassName="customers-table-address-column-tooltip"
            placement="top"
            message={recentActivityName}
            icon={
              <span>
                {format(new Date(recentActivityDatetime), "MMM dd, yyyy")}
              </span>
            }
          />
        ) : (
          <span>{format(new Date(createdAt), "MMM dd, yyyy")}</span>
        ),
      },
      {
        node: (
          <span>
            {format(new Date(createdAt), "MMM dd, yyyy")}
            {source ? (
              <span className="text-black-50">{` (${SOURCES.filter(
                (src) => src.value === source,
              ).map((filteredSource) => filteredSource.label)})`}</span>
            ) : null}
          </span>
        ),
      },
      {
        node: (
          <div className="option-dropdown">
            <DropdownButton
              alignRight={true}
              direction={false}
              dropShadow={true}
              title={<img alt="Options" src={ellipsisV} />}
              variant="link"
            >
              <Dropdown.Item
                href="#"
                target="_blank"
                onClick={() => {
                  getCustomerDetailsData(customer.contactId);
                }}
              >
                Quick View
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    utils.contactPageUrl(contactId, showCompanyId, false),
                    {
                      prevPage: document.location.pathname,
                    },
                  );
                }}
              >
                Edit
              </Dropdown.Item>
            </DropdownButton>
          </div>
        ),
      },
    ];
  };

  // effects
  // Calls API when searchTerm, sort, sortorder will changed
  useEffect(() => {
    if (setSegmentSearchTerm) {
      setSegmentSearchTerm(searchTerm);
    }
    if (searchTerm.length >= 3 || searchTerm === "") {
      setCurrentPage(0);
      if (dataReady) {
        getCustomers({
          page: 0,
          searchTerm: searchTerm,
          sortBy: sortBy,
          sortOrder: sortOrder,
          metaData: metaData,
        });
      } else {
        setDataReady(true);
      }
    }
    setFiltersApplied(searchTerm !== "" && searchTerm.length >= 3);
  }, [searchTerm, sortBy, sortOrder]);

  // sets empty state according to the customers found
  useEffect(() => {
    setCustomerEmptyState(customersList.length === 0);
  }, [customers]);

  // passes each customer to generate customerrow for the table
  useEffect(() => {
    setCustomers(customersList.map((customer) => getCustomerRow(customer)));
  }, [customersList]);

  // calls API to load data in customers table
  useEffect(() => {
    setCurrentPage(0);
  }, []);

  // sets the pageCount from total customers found and perpage
  useEffect(() => {
    setPageCount(Math.ceil(totalCustomersFound / perPage));
  }, [totalCustomersFound]);

  // render
  return (
    <div className="customers-content-view mt-4 mb-4">
      <Row className="mb-2 d-flex align-items-center">
        <Col className="show-filter-text" sm={9} lg={8}>
          <>
            <div>
              {showFiltersText()}
              {filtersApplied || searchTerm ? (
                <Button
                  variant="link"
                  className="m-0 p-0 ml-2 font-weight-bold sg-text-info"
                  onClick={() => resetFilters()}
                  style={{
                    fontWeight: "0.85rem",
                  }}
                >
                  Reset Filter
                </Button>
              ) : null}
            </div>
            {adHocButton ? adHocButton : null}
          </>
        </Col>
        <Col sm={3} lg={4}>
          <SearchInput
            maxLength={30}
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder={"Search by name, email or phone"}
          />
        </Col>
      </Row>
      {/* table */}
      {filtersApplied && customerEmptyState ? (
        <ResultsNotFound
          primaryMsg="No results found"
          secondaryMsg="We couldn’t find any results. Try adjusting your filters to display results."
          primaryMsgClassName="primary-message-style"
          secondaryMsgClassName="secondary-message-style"
        />
      ) : (
        <div className="customers-table-container mt-2 mb-0">
          {detailsShown && (
            <div className="customer-details-sidebar-restrictor">
              <CustomerDetailsSidebar
                setDetailsShown={setDetailsShown}
                contact={queriedContact}
                navigateToOldMembers={false}
              />
            </div>
          )}
          <Table
            className="customer-table mb-0"
            head={[
              // Name
              <SortableTableHeader
                sortable={HEADER_ITEMS[0].sortable}
                columnLabel={HEADER_ITEMS[0].columnLabel}
                columnValue={HEADER_ITEMS[0].columnValue}
                onSort={onSort}
                currentSortValue={sortBy.value}
                currentSortOrder={sortOrder.value}
              />,
              // Email Address
              <SortableTableHeader
                sortable={HEADER_ITEMS[1].sortable}
                columnLabel={HEADER_ITEMS[1].columnLabel}
                columnValue={HEADER_ITEMS[1].columnValue}
                onSort={onSort}
                currentSortValue={sortBy.value}
                currentSortOrder={sortOrder.value}
              />,
              // Phone Number
              <SortableTableHeader
                sortable={HEADER_ITEMS[2].sortable}
                columnLabel={HEADER_ITEMS[2].columnLabel}
                columnValue={HEADER_ITEMS[2].columnValue}
                onSort={onSort}
                currentSortValue={sortBy.value}
                currentSortOrder={sortOrder.value}
              />,
              // Mailing Address
              <div style={{ minWidth: 132 }}>
                <SortableTableHeader
                  sortable={HEADER_ITEMS[3].sortable}
                  columnLabel={HEADER_ITEMS[3].columnLabel}
                  columnValue={HEADER_ITEMS[3].columnValue}
                  onSort={onSort}
                  currentSortValue={sortBy.value}
                  currentSortOrder={sortOrder.value}
                />
              </div>,
              // Last Service Date
              <div className="column-service-date">
                <SortableTableHeader
                  sortable={HEADER_ITEMS[4].sortable}
                  columnLabel={HEADER_ITEMS[4].columnLabel}
                  columnValue={HEADER_ITEMS[4].columnValue}
                  onSort={onSort}
                  currentSortValue={sortBy.value}
                  currentSortOrder={sortOrder.value}
                />
              </div>,
              // Recent Activity
              <div className="column-service-date">
                <SortableTableHeader
                  sortable={HEADER_ITEMS[5].sortable}
                  columnLabel={HEADER_ITEMS[5].columnLabel}
                  columnValue={HEADER_ITEMS[5].columnValue}
                  onSort={onSort}
                  currentSortValue={sortBy.value}
                  currentSortOrder={sortOrder.value}
                />
              </div>,
              // Created Date
              <div className="column-created-on">
                <SortableTableHeader
                  sortable={HEADER_ITEMS[6].sortable}
                  columnLabel={HEADER_ITEMS[6].columnLabel}
                  columnValue={HEADER_ITEMS[6].columnValue}
                  onSort={onSort}
                  currentSortValue={sortBy.value}
                  currentSortOrder={sortOrder.value}
                />
              </div>,
              // Quick view and Edit
              <SortableTableHeader
                sortable={HEADER_ITEMS[7].sortable}
                columnLabel={HEADER_ITEMS[7].columnLabel}
                columnValue={HEADER_ITEMS[7].columnValue}
                onSort={onSort}
                currentSortValue={sortBy.value}
                currentSortOrder={sortOrder.value}
              />,
            ]}
            body={customers}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};

CustomersComponent.propTypes = {
  currentPage: PropTypes.number,
  customersList: PropTypes.array,
  getCustomers: PropTypes.func,
  setCurrentPage: PropTypes.func,
  adHocButton: PropTypes.object,
  totalCustomersFound: PropTypes.number,
  metaData: PropTypes.object,
};

export default CustomersComponent;
