import { gql } from "apollo-boost";

export const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

export const GET_COMPANY_SETTINGS = gql`
  query company($id: ID!) {
    company(companyId: $id) {
      id
      companyName
      companyDescription
      address1
      address2
      city
      state
      zip
      country
      phone
      phoneExt
      website
      logoUrl
      activeCampaignsCount
      primaryCategory {
        id
        name
      }
      secondaryCategory {
        id
        name
      }
      tertiaryCategory {
        id
        name
      }
    }
  }
`;

export const GET_BUSINESS_HOURS = gql`
  query businessHours($id: ID!) {
    businessHours(companyId: $id) {
      companyId
      day
      openTime
      closeTime
      closed
      createdAt
      updatedAt
    }
  }
`;

export const GET_STATES = gql`
  query listStates($country: String!) {
    listStates(country: $country) {
      name
      code
    }
  }
`;

export const GET_COUNTRIES = gql`
  query listCountries {
    listCountries {
      countries
    }
  }
`;

export const GET_GLOBAL_CAMPAIGN_SETTINGS = gql`
  query globalCampaignSetting($id: ID!) {
    globalCampaignSetting(companyId: $id) {
      emailFirstName
      emailLastName
      email
    }
  }
`;

export const GET_TEXTING_SERVICE = gql`
  query getCompanyInformation($companyId: ID!, $flag: String) {
    getCompanyInformation(companyId: $companyId, flag: $flag) {
      setting {
        companyId
        autoResponseContent
        widgetColor
        unhappyCustomerFeedback
      }
      twilioSetting {
        phone
      }
      businessHours {
        day
        closed
        companyId
      }
    }
  }
`;

export const GET_TEXTING_SETTINGS = gql`
  query getCompanyInformation($companyId: ID!, $flag: String) {
    getCompanyInformation(companyId: $companyId, flag: $flag) {
      setting {
        companyId
        autoResponseContent
        twilioPhone
        widgetColor
        unhappyCustomerFeedback
      }
      businessHours {
        day
        closed
        companyId
      }
    }
    companyFeatureFlags(companyId: $companyId)
  }
`;
