import React from 'react'
import 'src/components/settings/widgets/review_widgets/StaticButtonsBody.scss'
import { copyWordpressButton } from 'src/utils/CopyToClipboardUtil.js'
import { showCompanyId as companyId } from "src/shared/SessionHelper";
import 'src/components/settings/widgets/wordpress_widgets/WordpressWidgetBody.scss'

const WordpressWidgetBody = ({ handleToast, widgetSettings }) => {
  const [copied, setCopied] = React.useState(false);

  const handleCompanyName = () => {
    if (widgetSettings.data && !widgetSettings.loading) {
      return widgetSettings.data.company.companyName
    }

    return ''
  }

  const handleDynamicButtonClick = callback => {
    handleToast()
    callback({
      companyId,
      abbreviatedName: widgetSettings.data.company.abbreviatedName,
      companyName: widgetSettings.data.company.companyName
    })
    setCopiedTemporarily();
  }

  const setCopiedTemporarily = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div style={{marginLeft: 'calc(-30px - 1rem)'}}>
      <div className='download-text'>Download plugin</div>
      <a href="https://cdn.customerlobby.com/local/cl-wordpress-plugin-r4.3.zip" className='download-button'>Customer Lobby Reviews Widget (v4.3)</a>
      <div className='rss-container'>
        <div className='rss-text'>RSS Feed URL:</div>
        <div onClick={() => handleDynamicButtonClick(copyWordpressButton)} className='copy-code'>
          {copied ? "Copied" : "Copy Code"}
        </div>
      </div>
      <div className='copy-code-container'>{`${process.env.REACT_APP_API_SECURE_URL}/v1/companies/${companyId}/reviews.rss?featured=true`}</div>
    </div>

  )
}

export default WordpressWidgetBody;
