import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import insights1 from "src/assets/images/getting_started/insights1.png";
import insights2 from "src/assets/images/getting_started/insights2.png";
import insights3 from "src/assets/images/getting_started/insights3.png";
import insights4 from "src/assets/images/getting_started/insights4.png";
import insights5 from "src/assets/images/getting_started/insights5.png";
import "./GettingStarted.scss"

const AboutInsightsModal = ({ show, onClose }) => {
  // render
  return (
    <Modal
      modalSize="lg"
      onHide={onClose}
      header={
        <div className="w-100 d-flex align-items-center">
          <div className="flex-grow">About Insights</div>
        </div>
      }
      className="about-insights-modal set-1080"
      body={
        <div className="div-content">
          {/* 1 */}
          <div className="content">
            <div>
              <div className="header">Optimal Repeat Customer Marketing</div>
              <div className="description">
                We analyze and determine how many customers you should target as
                a part of a repeat marketing campaign and optimize your
                marketing return on investment.
              </div>
            </div>
            <div className="preview">
              <img className="preview-img" src={insights1} alt="" />
            </div>
          </div>

          {/* 2 */}
          <div className="content">
            <div className="preview">
              <img className="preview-img" src={insights2} alt="" />
            </div>
            <div>
              <div className="header">Repeat Customer Revenue</div>
              <div className="description">
                See what your Repeat Customer Revenue is as a percentage of your total revenue, and how your company compares to other businesses in your industry. You can use this metric to optimize your marketing spend on driving repeat business.
              </div>
            </div>
          </div>

          {/* 3 */}
          <div className="content">
            <div>
              <div className="header">Customer Lifetime Value</div>
              <div className="description">
              Customer Lifetime Value shows you the total worth of existing customers to your business over the entire period of the relationship, and compares it to industry benchmarks.{" "}
              </div>
            </div>
            <div className="preview">
              <img className="preview-img" src={insights3} alt="" />
            </div>
          </div>

          {/* 4 */}
          <div className="content">
            <div className="preview">
              <img className="preview-img" src={insights4} alt="" />
            </div>
            <div>
              <div className="header">Customer Information Breakdown</div>
              <div className="description">
              The Customer Information Breakdown shows you where there may be invalid or missing contact details in your customer data so you know which records need updating.
              </div>
            </div>
          </div>

          {/* 5 */}
          <div className="content">
            <div>
              <div className="header">New Vs. Repeat Customers</div>
              <div className="description">
              Compare revenue between your new and repeat customers over a 12 month period so you can determine how to best spend your marketing dollars for maximum ROI.
              </div>
            </div>
            <div className="preview">
              <img className="preview-img" src={insights5} alt="" />
            </div>
          </div>

          {/* end */}
        </div>
      }
      show={show}
    />
  );
};

export default AboutInsightsModal;
