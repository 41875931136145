import React from "react";
import { Modal as RBModal } from "react-bootstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Modal.scss";

const Modal = ({
  show,
  modalSize = "lg",
  onHide,
  body = "",
  header = "",
  footer = "",
  zIndex = 1999,
  className = "",
  backdropClassName = "",
}) => {
  return (
    <RBModal
      style={{
        zIndex: zIndex,
      }}
      size={modalSize}
      className={classnames("sg-modal", className)}
      show={show}
      onHide={onHide}
      backdropClassName={backdropClassName}
    >
      {header ? (
        <RBModal.Header className="d-flex align-items-center" closeButton>
          <RBModal.Title className="flex-grow">{header}</RBModal.Title>
        </RBModal.Header>
      ) : null}
      {body ? (
        <RBModal.Body
          className={classnames({
            "rounded-bottom": !footer,
            "rounded-top": !header,
          })}
        >
          {body}
        </RBModal.Body>
      ) : null}
      {footer ? <RBModal.Footer>{footer}</RBModal.Footer> : null}
    </RBModal>
  );
};

Modal.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node.isRequired,
  modalSize: PropTypes.string,
  zIndex: PropTypes.number,
  footer: PropTypes.node,
};

export default Modal;
