import React from "react";
import { Accordion } from "react-bootstrap";
import PropTypes from "prop-types";
import AccordionToggle from "src/shared/styleguide/accordiontoggle/AccordionToggle";
import "src/shared/styleguide/accordions/TextingAccordion.scss";

const TextingAccordion = ({
  label,
  expanded,
  eventKey,
  children,
  isCollapseAll = false,
  labelStyles,
  callback = null,
  isDefaultActiveKey = true,
  accordionHeaderClass = "",
  onClick,
}) => {
  return (
    <div className="texting-accordion-container">
      <Accordion
        defaultActiveKey={isDefaultActiveKey ? eventKey : null}
        onClick={onClick}
      >
        <AccordionToggle
          eventKey={eventKey}
          expanded={expanded}
          iconExpanded="fa fa-2x fa-chevron-down"
          iconCollapsed="fa fa-2x fa-chevron-up"
          callback={callback}
          accordionHeaderClass={accordionHeaderClass}
          isCollapseAll={isCollapseAll}
        >
          <div style={labelStyles}>{label}</div>
        </AccordionToggle>
        <Accordion.Collapse eventKey={eventKey} >
          <React.Fragment>
            <div className="texting-accordion-line" />
            {children}
          </React.Fragment>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

TextingAccordion.propTypes = {
  children: PropTypes.node,
  eventKey: PropTypes.string,
  styles: PropTypes.object,
  labelStyles: PropTypes.object,
  expanded: PropTypes.bool,
  callback: PropTypes.func,
  isDefaultActiveKey: PropTypes.bool,
};

export default TextingAccordion;
