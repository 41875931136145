import React, { useEffect } from "react";
import maplibre from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "./MapVisualization.scss";

const MapVisualization = ({
  company,
  coverage,
  popupClassName,
  popupLegend: customLegend,
  popupLegendEqualSymbol=" = ",
  showPopupLegend= true,
}) => {
  // Variables
  let map = null;
  let legend = customLegend || [
    {
      label: "ZIP",
      value: "zip",
    },
  ];

  /**
   * Returns map object centered with company lat and long
   */
  const getMap = () => {
    let map = new maplibre.Map({
      container: "map",
      // format is strictly: [longitude, latitude]
      center: [company.longitude, company.latitude],
      // initial zoom level is set to 8; try adjusting
      zoom: 8,
      style: {
        version: 8,
        sources: {
          basemap: {
            type: "raster",
            tiles: ["	https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "basemap",
            type: "raster",
            source: "basemap",
            minzoom: 0,
            maxzoom: 20,
          },
        ],
      },
    });

    return map;
  };

  /**
   * Initiate zero state for map.
   */
  const initAndLoadZeroStateMap = () => {
    map.on("load", () => {
      let el = document.createElement("div");
      el.className = "marker";
      // add marker to map
      new maplibre.Marker(el)
        .setLngLat([company.longitude, company.latitude])
        .addTo(map);

      new maplibre.Popup({ closeButton: false })
        .setLngLat([company.longitude, company.latitude])
        .setHTML(`<div class="p-2">${company.companyName}</div>`)
        .addTo(map);
    });
  };
  const popupProps = (item) => {
    let props = {};
    legend.map((el) => {
      props[`${el.value}`] = item[`${el.value}`];
    });
    return props;
  };
  const popupContent = (marker) => {
    let string = "";
    legend.map(
      (el) =>
        (string += `<span>${el.label}${popupLegendEqualSymbol}${
          marker.properties[`${el.value}`]
        }</span> <br />`),
    );
    return string;
  };
  /**
   * Initiate and load map with customer coverage markers on map.
   */
  const initAndLoadMap = () => {
    map.on("load", () => {
      const geoJson = {
        type: "FeatureCollection",
        features: [
          ...coverage.map((item, index) => {
            return {
              // feature for maplibre DC
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [item.lng, item.lat],
              },
              properties: popupProps(item),
            };
          }),
        ],
      };

      // add markers to map
      geoJson.features.forEach(function(marker) {
        // create a DOM element for the marker
        let el = document.createElement("div");
        el.className = "marker";
        // add marker to map
        let thisMarker = new maplibre.Marker(el)
          .setLngLat(marker.geometry.coordinates);
        if (showPopupLegend) {
          thisMarker.setPopup(
            new maplibre.Popup({
              closeButton: false,
              className: popupClassName,
            }).setHTML(`
          <div class="p-1">
            ${popupContent(marker)}
          </div>
          `),
          );
        }
        thisMarker.addTo(map);
      });
    });
  };

  /**
   * Adds controls to the map.
   */
  const mapControls = () => {
    // this is required
    map.addControl(
      new maplibre.AttributionControl({
        customAttribution:
          '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>',
      }),
    );
    // fullscreen control
    map.addControl(new maplibre.FullscreenControl(), "top-right");

    // zoom in/out control; disable compass tool here (this does pitch/bearing reset on the map)
    map.addControl(
      new maplibre.NavigationControl({
        showCompass: false,
      }),
      "bottom-right",
    );

    // geo-locate control; lets user find themselves with click of a button
    map.addControl(
      new maplibre.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "bottom-right",
    );
  };

  /**
   * UseEffect hook will executed on company and coverage updates.
   */
  useEffect(() => {
    if (company && coverage && coverage.length > 0) {
      // initialize the map
      if (map) {
        map.remove();
      }
      map = getMap();

      // add controls
      mapControls();

      // wait for the map to init and load start adding data only after this
      initAndLoadMap();
    } else if (company) {
      // initialize the map
      map = getMap();

      // add controls
      mapControls();

      // wait for the map to init and load start adding data only after this
      initAndLoadZeroStateMap();
    }
  }, [company, coverage]);

  return (
    <div className="map-coverage-container">
      <div className="map-coverage">
        <div id="map" />
      </div>
    </div>
  );
};

export default MapVisualization;
