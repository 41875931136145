import React from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import "./Iconstyleguide.scss";

const IconsStyleGuide = () => {
  useDocumentTitle("Style Guide - Icons");

  // methods
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const titleSanitizer = (title) =>
    title.substring(title.indexOf("media/") + 6, title.indexOf("."));

  // constants
  const imagesAlerts = importAll(
    require.context(
      "src/assets/images/alerts",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesCampaigns = importAll(
    require.context(
      "src/assets/images/campaigns",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesCampaignsResult = importAll(
    require.context(
      "src/assets/images/campaigns/result",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesGettingStarted = importAll(
    require.context(
      "src/assets/images/getting_started",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesEmail = importAll(
    require.context(
      "src/assets/images/campaigns/email",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesCommon = importAll(
    require.context(
      "src/assets/images/common",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesHeader = importAll(
    require.context(
      "src/assets/images/header",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesCompanyUsers = importAll(
    require.context(
      "src/assets/images/company_users",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesReviews = importAll(
    require.context(
      "src/assets/images/reviews",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesSettings = importAll(
    require.context(
      "src/assets/images/settings/apps",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const imagesWidgets = importAll(
    require.context(
      "src/assets/images/widgets",
      false,
      /\.(png|jpe?g|gif|svg)$/,
    ),
  );

  const sets = [
    {
      title: "Alerts",
      location: "src/assets/images/alerts",
      images: imagesAlerts,
    },
    {
      title: "Campaigns",
      location: "src/assets/images/campaigns",
      images: imagesCampaigns,
    },
    {
      title: "Campaign Results",
      location: "src/assets/images/campaigns/result",
      images: imagesCampaignsResult,
    },
    {
      title: "Getting Started",
      location: "src/assets/images/getting_started",
      images: imagesGettingStarted,
    },
    {
      title: "Email",
      location: "src/assets/images/campaigns/email",
      images: imagesEmail,
    },
    {
      title: "Common",
      location: "src/assets/images/common",
      images: imagesCommon,
    },
    {
      title: "Header",
      location: "src/assets/images/header",
      images: imagesHeader,
    },
    {
      title: "Company Users",
      location: "src/assets/images/company_users",
      images: imagesCompanyUsers,
    },
    {
      title: "Reviews",
      location: "src/assets/images/reviews",
      images: imagesReviews,
    },
    {
      title: "Settings",
      location: "src/assets/images/settings/apps",
      images: imagesSettings,
    },
    {
      title: "Widgets",
      location: "src/assets/images/widgets",
      images: imagesWidgets,
    },
  ];

  return (
    <div className="sg-icons">
      <h3 className="text-dark font-weight-bold">Icons</h3>
      <p className="sg-note">
        <i>
          This page is populated by scraping entire directories for image files,
          and therefore may contain non-icon images. None the less, it serves as
          a valuable index of icons (and images) in the application.{" "}
        </i>
      </p>
      <p>
        Many examples of importing icons and other images can be found through
        this application. For your reference, here is a very basic universal
        usage example.
      </p>
      <div className="sg-basic-code-example">
        <CodePreview
          code={`import img from "src/assets/images/alerts/close_sm_icon.svg";

<img className="example-class" src={img} alt="image description example" />`}
        />
      </div>
      <div className="style-guide-icon-container">
        {sets.map((set) => (
          <StyleGuideSection
            title={set.title}
            description={`Images found in ${set.location}`}
          >
            <CodePreview
              elements={
                <React.Fragment>
                  {set.images.map((img) => (
                    <div
                      className={`${
                        set.title === "Header"
                          ? "sg-header-icon-container"
                          : "d-inline-block"
                      }`}
                    >
                      <PopupInfo
                        message={titleSanitizer(img)}
                        icon={
                          <img
                            className="icon-example"
                            src={img}
                            alt={`Style Guide Example: ${titleSanitizer(img)}`}
                          />
                        }
                      />
                    </div>
                  ))}
                </React.Fragment>
              }
            />
          </StyleGuideSection>
        ))}
      </div>
    </div>
  );
};

export default IconsStyleGuide;