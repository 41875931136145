import React from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import EllipsisV from "src/styles/assets/ellipsis-v.svg";

import Dropdown, {
  DropdownButton
} from "src/shared/styleguide/dropdown/Dropdown";

export const DropdownStyleGuide = () => {
  useDocumentTitle("Style Guide - Dropdown");
  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Dropdown</h3>
      <div>
        Dropdowns are toggleable, contextual overlays for displaying list of
        links and more.
      </div>

      {/* Dropdown */}
      <StyleGuideSection
        title="Basic Example"
        description={
          <div>
            Use the <code>DropdownButton</code> component to create a button
            dropdown. Dropdowns can also be triggered from link or icons to
            better fit your potential needs.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <DropdownButton title="Dropdown Menu">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Something else here
                </Dropdown.Item>
              </DropdownButton>
            </React.Fragment>
          }
          code={`import Dropdown, { DropdownButton } from "src/shared/styleguide/dropdown/Dropdown";

<DropdownButton title="Dropdown Menu">
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
  <Dropdown.Item href="#/action-3">
    Something else here
  </Dropdown.Item>
</DropdownButton>`}
        />
      </StyleGuideSection>

      {/* Dropdown with active menu item */}
      <StyleGuideSection
        title="Active Menu Item"
        description={
          <div>
            Add <code>active</code> prop to menu items in the dropdown to style
            them as active.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <DropdownButton title="Dropdown Menu">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item active href="#/action-2">
                  Another action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Something else here
                </Dropdown.Item>
              </DropdownButton>
            </React.Fragment>
          }
          code={`import Dropdown, { DropdownButton } from "src/shared/styleguide/dropdown/Dropdown";

<DropdownButton title="Dropdown Menu">
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item active href="#/action-2">
    Another action
  </Dropdown.Item>
  <Dropdown.Item href="#/action-3">
    Something else here
  </Dropdown.Item>
</DropdownButton>`}
        />
      </StyleGuideSection>

      {/* Dropdown with disabled menu item */}
      <StyleGuideSection
        title="Disabled Menu Item"
        description={
          <div>
            Add <code>disabled</code> prop to menu items in the dropdown to
            style them as disabled.
          </div>
        }
      >
        <CodePreview
          elements={
            <React.Fragment>
              <DropdownButton title="Dropdown Menu">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item disabled href="#/action-2">
                  Another action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Something else here
                </Dropdown.Item>
              </DropdownButton>
            </React.Fragment>
          }
          code={`import Dropdown, { DropdownButton } from "src/shared/styleguide/dropdown/Dropdown";

<DropdownButton title="Dropdown Menu">
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item disabled href="#/action-2">
    Another action
  </Dropdown.Item>
  <Dropdown.Item href="#/action-3">
    Something else here
  </Dropdown.Item>
</DropdownButton>`}
        />
      </StyleGuideSection>

      {/* Dropdown with a menu divider */}
      <StyleGuideSection
        title="Dropdown Divider"
        description="Separate groups of related dropdown menu items can be separated using a divider."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <DropdownButton title="Dropdown Menu">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Something else here
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-4">Separator line</Dropdown.Item>
              </DropdownButton>
            </React.Fragment>
          }
          code={`import Dropdown, { DropdownButton } from "src/shared/styleguide/dropdown/Dropdown";

<DropdownButton title="Dropdown Menu">
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
  <Dropdown.Item href="#/action-3">
    Something else here
  </Dropdown.Item>
  <Dropdown.Divider />
  <Dropdown.Item href="#/action-4">Separator line</Dropdown.Item>
</DropdownButton>`}
        />
      </StyleGuideSection>

      {/* Flat dropdown toggle */}
      <StyleGuideSection
        title="Dropdown with Icon"
        description="Dropdowns can be applied to icons as well."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <DropdownButton
                variant="link"
                direction={false}
                title={<img src={EllipsisV} alt="Ellipsis" />}
              >
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Something else here
                </Dropdown.Item>
              </DropdownButton>
            </React.Fragment>
          }
          code={`import Dropdown, { DropdownButton } from "src/shared/styleguide/dropdown/Dropdown";

<DropdownButton
  variant="link"
  direction={false}
  title={<span className="text-dark fa fa-ellipsis-v" />}
>
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
  <Dropdown.Item href="#/action-3">
    Something else here
  </Dropdown.Item>
</DropdownButton>`}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default DropdownStyleGuide;
