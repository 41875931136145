import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

const CompanyInformation = ({ label, place = {} }) => {
  return (
    <div
      style={{
        border: "1px solid rgba(201, 201, 201, 0.6)",
        borderRadius: 8,
        position: "relative"
      }}
      className="p-4"
    >
      <div
        style={{
          position: "absolute",
          top: -12,
          left: 12,
          borderRadius: 13,
          backgroundColor: "#f47821",
          color: "#ffffff",
          fontSize: "0.85rem"
        }}
        className="px-2 text-capitalize"
      >
        {label}
      </div>
      <div className="text-dark">Name</div>
      <div>{place.name || "-"}</div>

      <Row className="mt-2">
        <Col xsl={6}>
          <div className="text-dark">Address 1</div>
          <div>{place.address1 || "-"}</div>
        </Col>
        <Col xsl={6}>
          <div className="text-dark">Address 2</div>
          <div>{place.address2 || "-"}</div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xsl={6}>
          <div className="text-dark">City</div>
          <div>{place.city || "-"}</div>
        </Col>
        <Col xsl={6}>
          <div className="text-dark">State</div>
          <div>{place.state || "-"}</div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xsl={12}>
          <div className="text-dark">ZIP Code</div>
          <div>{place.zipcode || "-"}</div>
        </Col>
      </Row>
    </div>
  );
};

CompanyInformation.propTypes = {
  place: PropTypes.object
};

export default CompanyInformation;
