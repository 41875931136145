import React, { useState } from "react";
import HorizontalBarChart from "src/shared/styleguide/charts/HorizontalBarChart";
import { QUERY_DISTRIBUTION_BY_RATING } from "src/graphql/reviews/results/queries";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { useEffect } from "react";
import NumberUtil from "src/utils/NumberUtil";

const ReviewDistributionByRating = ({ companyId, filter }) => {
  const [reviewDistributionByRating, setReviewDistributionByRating] = useState([
    { labelLeft: "5 star", labelRight: 0, barValue: 0 },
    { labelLeft: "4 star", labelRight: 0, barValue: 0 },
    { labelLeft: "3 star", labelRight: 0, barValue: 0 },
    { labelLeft: "2 star", labelRight: 0, barValue: 0 },
    { labelLeft: "1 star", labelRight: 0, barValue: 0 }
  ]);
  const { loading, data } = useQuery(QUERY_DISTRIBUTION_BY_RATING, {
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });
  useEffect(() => {
    if (data && data.distributionByRating) {
      const newReviewDistributionByRating = [...reviewDistributionByRating];

      ["five", "four", "three", "two", "one"].map((number, index) => {
        newReviewDistributionByRating[index].barValue =
          data.distributionByRating[`${number}StarPercent`];
        newReviewDistributionByRating[index].labelRight = (
          <span title={data.distributionByRating[`${number}StarCount`]}>
            {NumberUtil.toK(
              data.distributionByRating[`${number}StarCount`],
              1
            ).toString()}
          </span>
        );
      });
      setReviewDistributionByRating(newReviewDistributionByRating);
    }
  }, [data]);
  return (
    <HorizontalBarChart
      loading={loading}
      title="Review Distribution"
      subTitle="by Rating"
      popupInfoMsg="This shows the combined rating given and the distribution across the star ratings for the selected date range."
      chartData={reviewDistributionByRating}
    />
  );
};

ReviewDistributionByRating.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired
};

export default ReviewDistributionByRating;
