import { gql } from "apollo-boost";

const QUERY_CONTACTS_META_LIST = gql`
  query contactsWithMeta(
    $companyId: ID!
    $metaData: JSON!
    $rpp: Int
    $page: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
    $searchField: String
  ) {
    contactsWithMeta(
      companyId: $companyId
      metaData: $metaData
      rpp: $rpp
      page: $page
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
      searchField: $searchField
    ) {
      data {
        contactId
        companyId
        firstName
        lastName
        email
        fullName
        phoneAlt
        phoneAltExt
        phoneMobile
        phoneDaytime
        formattedLastServiceDate
        lastServiceDate
        address1
        address2
        city
        recentActivityName
        recentActivityDatetime
        source
        createdAt
        zip
        state
        communicationInfoEmail
        communicationInfoPhone
        communicationInfoMailing
        customerWantsCall
        dcWantsCall
        memberWantsCall
        customerWantsMail
        dcWantsMail
        memberWantsMail
        customerWantsEmail
        dcWantsEmail
        memberWantsEmail
        customerWantsSms
        dcWantsSms
        memberWantsSms
      }
      ciMetaData
      total
      sourceAggregates
    }
  }
`;

const GET_COMPANY_FILTER_METRICS = gql`
  query($companyId: ID!) {
    companyFilterMetrics(companyId: $companyId)
  }
`;

const GET_FILTER = gql`
  query($id: ID!) {
    showFilter(id: $id) {
      campaignType
      customersCount
      default
      metaData
      name
      companyId
    }
  }
`;

const GET_FILTERS_LIST = gql`
  query($companyId: ID!, $rpp: Int) {
    listFilters(companyId: $companyId, rpp: $rpp) {
      data {
        createdAt
        default
        id
        metaData
        name
      }
    }
  }
`;

const GET_CUSTOM_FILTERS_LIST = gql`
  query($companyId: Int!) {
    listCustomFilters(companyId: $companyId) {
      createdAt
      id
      name
      status
      updatedAt
      outputUrl
    }
  }
`;

const GET_COMPANY_STATES = gql`
  query($companyId: ID!) {
    listCompanyStates(companyId: $companyId)
  }
`;

const GET_CITIES = gql`
  query($companyId: ID!, $states: [String!]) {
    listCities(companyId: $companyId, states: $states)
  }
`;

const GET_ZIPCODES = gql`
  query($companyId: ID!, $cities: [String!], $states: [String!]) {
    listZipcodes(companyId: $companyId, cities: $cities, states: $states)
  }
`;

const GET_PRESIGNED_URL = gql`
  query($companyId: ID!, $type: String) {
    s3PresignedUrl(companyId: $companyId, type: $type)
  }
`;

export {
  GET_CITIES,
  GET_COMPANY_FILTER_METRICS,
  GET_COMPANY_STATES,
  GET_CUSTOM_FILTERS_LIST,
  GET_FILTER,
  GET_FILTERS_LIST,
  GET_PRESIGNED_URL,
  GET_ZIPCODES,
  QUERY_CONTACTS_META_LIST,
};
