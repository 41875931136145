import ExpiredStorage from 'expired-storage';

const expiredStorage = new ExpiredStorage();
const EXPIRATION_TIME = 7200;

export function setFilterStorage(filterId, filter) {
  expiredStorage.setJson(filterId, filter, EXPIRATION_TIME);
};

export function setCompanyIdStorage(companyId) {
  expiredStorage.setJson("companyId", companyId, EXPIRATION_TIME);
};

export function updateFilterStorageExpiration(filterId) {
  expiredStorage.updateExpiration(filterId, EXPIRATION_TIME);
};

export function getFilter(filterId) {
  return expiredStorage.getJson(filterId);
};

export function getCompanyIdStorage() {
  return expiredStorage.getJson("companyId");
};

export function filterStorageExpired(filterId) {
  return expiredStorage.getTimeLeft(filterId) > 0 ? false : true;
};

export function clearExpiredFilterStorage() {
  expiredStorage.clearExpired();
};

export function clearFilterStorage() {
  expiredStorage.clear();
};