import React from "react";
import classnames from "classnames";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import PropTypes from "prop-types";
import "./Card.scss";

export const Card = ({
  cardBodyStyle,
  cardStyle,
  children,
  className = "",
  flat = false,
  padding = "1.25rem",
  popupIconSize,
  popupInfoDelay,
  popupInfoMsg,
  popupInfoPlacement,
  popupInfoTrigger,
  subTitle,
  title,
  tooltipClassName = "",
}) => (
  <div
    style={{
      padding,
      ...cardStyle,
    }}
    className={classnames("sg-card", className, {
      "shadow-none": flat,
    })}
  >
    {/* if title is passed */}
    {title ? (
      <div className="sg-card-title">
        <div
          className={classnames({
            "d-inline-block mr-2": !!popupInfoMsg,
          })}
        >
          {title}
        </div>
        {/* if popoverInfo is passed */}
        {popupInfoMsg ? (
          <PopupInfo
            delay={popupInfoDelay}
            iconSize={popupIconSize}
            message={popupInfoMsg}
            placement={popupInfoPlacement}
            tooltipClassName={tooltipClassName}
            trigger={popupInfoTrigger}
          />
        ) : null}
      </div>
    ) : null}

    {/* if subtitle is passed */}
    {subTitle ? <div className="sg-card-sub-title">{subTitle}</div> : null}
    <div
      className="sg-card-body"
      style={{
        ...cardBodyStyle,
      }}
    >
      {children}
    </div>
  </div>
);

Card.propTypes = {
  cardBodyStyle: PropTypes.object,
  cardStyle: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  flat: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  popupIconSize: PropTypes.number,
  popupInfoDelay: PropTypes.number,
  popupInfoMsg: PropTypes.string,
  popupInfoPlacement: PropTypes.string,
  popupInfoTrigger: PropTypes.string,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  tooltipClassName: PropTypes.string,
};

export default Card;
