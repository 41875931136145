import React from "react";
import "./NoDataFoundComponent.scss";
import folder from "../../styles/assets/folder.svg";

const NoDataFoundComponent = ({ heading, message, dateRange }) => {
  return (
    <div id="NoDataFound">
      <div className="d-flex justify-content-center no-data-lower-container">
        <div className="circle d-flex justify-content-center align-items-center">
          <img alt="Directory" src={folder}></img>
        </div>
      </div>
      <div className=" mt-4">
        <div className="d-flex justify-content-center">
          <div>
            <div className="row d-flex justify-content-center">
              <div className="no-data">{heading}</div>{" "}
            </div>
            <div className="row d-flex justify-content-center">
              <div className="no-data-subheading">{message}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataFoundComponent;
