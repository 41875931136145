import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import logo from "src/img/header/logo.png";
import "./Header.scss";

const Header = ({
  brandLogo = logo,
  brandLogoAlt = "Brand Logo",
  brandLogoHref = "/",
  leftMenus = [],
  rightMenus = [],
  backgroundColor = "#37485b",
  sticky = true,
}) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  return (
    <div
      className={classnames("navbar navbar-light navbar-expand-xl", {
        "sticky-top": sticky,
        "navbar-static-top": !sticky,
      })}
      id="top-header-section"
      style={{
        backgroundColor,
      }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href={brandLogoHref}>
          <img src={brandLogo} className="logo-image" alt={brandLogoAlt} />
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse"
          onClick={() => setMenuCollapsed(!menuCollapsed)}
        >
          ☰
        </button>
        <div
          className={`navbar-collapse collapse ${
            menuCollapsed ? "show-menu" : ""
            }`}
        >
          <ul className="nav navbar-nav">
            {leftMenus.map((menu) => (
              <li key={menu.id} className="nav-item">
                <a href={menu.href} className="nav-link">
                  {menu.label}
                </a>
              </li>
            ))}
          </ul>
          <ul className="nav navbar-nav pull-right user-menu">
            {rightMenus.map((menu) => (
              <li key={menu.id} className="nav-item">
                <a href={menu.href} className="nav-link">
                  {menu.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  brandLogo: PropTypes.string,
  brandLogoAlt: PropTypes.string,
  leftMenus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  backgroundColor: PropTypes.string,
  sticky: PropTypes.bool,
};

export default Header;
