import React from "react";
import BlockUi from "react-block-ui";
import { MiniCard } from "src/shared/styleguide/card/MiniCard";
import {
  MINI_CARD_HEIGHT,
  MINI_CARD_CONTENT_HEIGHT
} from "src/constants/ReviewResults";
import MiniDonutChart from "./MiniDonutChart";
import PropTypes from "prop-types";

const SentimentScore = ({ loading, fromDate, toDate, data }) => {
  return (
    <MiniCard
      popupInfoMsg="This refers to your customers opinion of your business, which is based on positive vs. negative Customer Lobby reviews submitted, in the selected date range."
      title="SENTIMENT SCORE"
    >
      <BlockUi blocking={loading}>
        <div className="px-3">
          <MiniDonutChart
            chartId="sentimentScoreChart"
            height={MINI_CARD_HEIGHT}
            pieSize={MINI_CARD_CONTENT_HEIGHT}
            chartTitle={data ? data.sentimentScore : 0}
            chartData={[
              {
                y: data ? data.positive : 0,
                name: "Positive",
                color: "#f47821"
              },
              {
                y: data ? data.negative : 0,
                name: "Negative",
                color: "#cacaca"
              }
            ]}
            tooltipFormatter={function() {
              const { data } = this.series;
              const total = data[0].y + data[1].y || 1;
              return `${fromDate ? fromDate : ""}${
                toDate && toDate !== fromDate ? ` to ${toDate}` : ""
              }
                  <br />${data[0].name}: ${Math.ceil(data[0].y)} (${Math.ceil(
                (data[0].y * 100) / total
              )}%)
                  <br />${data[1].name}: ${Math.floor(data[1].y)} (${Math.floor(
                (data[1].y * 100) / total
              )}%)`;
            }}
          />
        </div>
      </BlockUi>
    </MiniCard>
  );
};

SentimentScore.propTypes = {
  loading: PropTypes.bool.isRequired,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  data: PropTypes.object
};

export default SentimentScore;
