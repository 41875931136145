import React, { Component } from "react";
import clockLogo from "../../../img/clock.svg";
import companyInformationLogo from "../../../img/building.svg";
import contactDetailsLogo from "../../../img/contact-information@3x.png";
import campaignsSettingsLogo from "../../../img/campaignsSettingsLogo.png";
class ProfileSettingTitleBar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  render() {
    return (
      <div className="row">
        <div className="col-2 col-md-1 pl-3">
          {this.props.title === "Business Hours" && (
            <img src={clockLogo} alt="Clock" className="tab-logo" />
          )}
          {this.props.title === "Company Information" && (
            <img
              src={companyInformationLogo}
              alt="Company Information"
              className="tab-logo"
            />
          )}
          {this.props.title === "Contact Details" && (
            <img
              src={contactDetailsLogo}
              alt="Contact Details"
              className="tab-logo"
            />
          )}
          {this.props.title === "Campaigns Setting" && (
            <img
              src={campaignsSettingsLogo}
              alt="Campaign Settings"
              className="tab-logo"
            />
          )}
        </div>
        <div className="col-10 col-md-11 pl-3 ">
          <div className="tab-title-block">
            <div className="tab-title">{this.props.title}</div>
            <div className="tab-subtitle d-none d-sm-block">
              {this.props.subTitle}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-11 pl-3 d-block d-sm-none mt-3">
          <div className="tab-title-block">
            <div className="tab-subtitle d-inline-flex">
              {this.props.subTitle}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSettingTitleBar;
