export default class CookieUtil {
  static getCookie(name) {
    function escape(s) {
      return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
    }
    const match = document.cookie.match(
      RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)"),
    );
    return match ? match[1] : null;
  }

  static setCookie (name, value, domain = null, days = 7) {
    if (domain === null) {
      const domainBits = document.domain.split(".");
      domain = domainBits.length > 2 ? domainBits.slice(1).join(".") : document.domain
    }
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + "; path=/; domain=" + domain + expires;
  }
}
