import React from "react";
import WindowUtil from "src/utils/WindowUtil";
import { useApolloClient } from "@apollo/react-hooks";
import { QUERY_TWITTER_AUTH_CREDS } from "src/graphql/settings/reviews/queries";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";

const ConnectTwitterButton = ({ setTwitterAuthCreds }) => {
  // const state
  const client = useApolloClient();

  // methods
  const openLoginWindow = () => {
    client
      .query({
        query: QUERY_TWITTER_AUTH_CREDS,
        fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY
      })
      .then((res) => {
        if (res && res.data) {
          const { twitterAuthCreds } = res.data;
          setTwitterAuthCreds(twitterAuthCreds);
          WindowUtil.openWindow({
            url: twitterAuthCreds.authUrl,
            name: "Log in with Twitter"
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  // render
  return (
    <span
      onClick={openLoginWindow}
      className="cursor-pointer d-flex btn-twitter-connect cursor-point"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="40"
        viewBox="0 0 44 40"
      >
        <g transform="translate(0 5)">
          <g>
            <g>
              <path
                d="M4 0h40v40H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                transform="translate(0 -5)"
                fill="#1597e6"
              />
              <path
                d="M35 81.584a6.256 6.256 0 0 0 5.068 1.307 6 6 0 0 0 3.252-1.705c-.14-.019-.573-.07-.689-.1-.435-.123-.81-.337-.885-.825-.089-.61.684-1.014.7-1.026a10.533 10.533 0 0 1-1.2-.242 2.443 2.443 0 0 1-1.346-1.05c-.1-.149-.092-.272.056-.348.374-.193.757-.368 1.132-.547a2.85 2.85 0 0 1-2.288-2.274l1.279-.2a3.3 3.3 0 0 1-1.85-2.883 26.847 26.847 0 0 1 4.3 1.717 39.389 39.389 0 0 1 3.421 2.3c.854-2.087 1.654-4.186 3.635-5.527l-.123.42a3.575 3.575 0 0 1 1.669-.641l-.444.592c.265-.067.51-.133.758-.191.65-.165.766.312.485.475a8.894 8.894 0 0 1-.96.37c-.2.069-.4.118-.564.164a10.392 10.392 0 0 1 1.623.423 5.309 5.309 0 0 1 3.079 3.78.407.407 0 0 0 .392.369 4.349 4.349 0 0 0 2.036-.23l.317-.108c-.172.65-.8 1.061-2.25 1.478a12.411 12.411 0 0 0 2.4.351 2.691 2.691 0 0 0-.237.156 3.619 3.619 0 0 1-2.278.8c-.154 0-.249.02-.3.2a9.155 9.155 0 0 1-3.841 5.162 12.6 12.6 0 0 1-6.8 2.2 11.413 11.413 0 0 1-8.066-2.725A8.2 8.2 0 0 1 35 81.584z"
                transform="translate(-24.999 -62.96)"
                fill="#ffffff"
              />
            </g>
          </g>
        </g>
      </svg>
      <span className="d-flex flex-grow-1 justify-content-center align-items-center">
        Connect to Twitter
      </span>
    </span>
  );
};

export default ConnectTwitterButton;
