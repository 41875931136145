import React from 'react';
import './ReviewsSettingInfo.scss';
import { Link } from "react-router-dom";

const ReviewsSettingInfo = ({ imgOne, display, top, imgTwo, content, marginBottom, link, hoverContent }) => {
  const handleMouseOver = e => {
    if(e.target.nextElementSibling) e.target.nextElementSibling.style.display = 'flex'
  }

  const handleMouseOut = e => {
    if(e.target.nextElementSibling) e.target.nextElementSibling.style.display = 'none'
  }


 return (
  <Link to={link} target="_blank">
   <div className={`review-link-container mt-2 ${marginBottom ? 'mb-3' : null}`}>
    <img className='mr-2' src={imgOne} alt="Icon" />
    <a className='mr-1 review-status-link'>{content}</a>
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={{position: 'relative'}}>
      <img className='ml-1' alt="information" src={imgTwo} />
      <div style={{ top }} className='hover-content'>{hoverContent}</div>
    </div>
   </div>
  </Link>
 )
}

export default ReviewsSettingInfo
