import React from "react";
import DynamicButtonsBody from "src/components/settings/widgets/review_widgets/DynamicButtonsBody";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import { addToast } from "src/utils/ToastUtil"

const DynamicButtons = ({ widgetSettings }) => {
  const handleToast = () => {
    addToast({
      message: "Widget copied to clipboard!",
      type: "success",
    });
  };

  return (
    <SettingsCard
      loading={false}
      settingsName="Dynamic Buttons"
      settingsDescription="Link to your Customer Lobby reviews page from a dynamic button on your website. Dynamic buttons automatically update as you get new reviews!"
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <g id="prefix___x33_3-Check" transform="translate(-9 -9)">
            <g
              id="prefix__Group_2530"
              data-name="Group 2530"
              transform="translate(9 9)"
            >
              <path
                fill="#7e7e7e"
                id="prefix__Path_10402"
                d="M43.8 9H10.2A1.2 1.2 0 0 0 9 10.2v33.6a1.2 1.2 0 0 0 1.2 1.2h33.6a1.2 1.2 0 0 0 1.2-1.2V10.2A1.2 1.2 0 0 0 43.8 9zm-1.2 33.6H11.4V11.4h31.2z"
                className="prefix__cls-1"
                data-name="Path 10402"
                transform="translate(-9 -9)"
              />
              <g
                id="prefix__Group_2531"
                data-name="Group 2531"
                transform="translate(11.007 13.004)"
              >
                <path
                  id="prefix__Path_10407"
                  d="M10.01 13.02h12.12a1.01 1.01 0 0 0 0-2.02H10.01a1.01 1.01 0 1 0 0 2.02z"
                  className="prefix__cls-1"
                  data-name="Path 10407"
                  transform="translate(-9 -11)"
                />
                <path
                  id="prefix__Path_10408"
                  d="M10.01 21.02h6.06a1.01 1.01 0 0 0 0-2.02h-6.06a1.01 1.01 0 1 0 0 2.02z"
                  className="prefix__cls-1"
                  data-name="Path 10408"
                  transform="translate(-9 -10.92)"
                />
              </g>
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <DynamicButtonsBody
          handleToast={handleToast}
          widgetSettings={widgetSettings}
        />
      }
    />
  );
};

export default DynamicButtons;
