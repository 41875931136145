import React from "react";
import { Col } from "react-bootstrap";
import classnames from "classnames";
import PropTypes from "prop-types";

const MediumGridWidget = ({ children, className }) => (
  <Col xl={4} lg={6} md={6} sm={12} className={classnames("mb-4", className)}>
    {children}
  </Col>
);

MediumGridWidget.propType = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default MediumGridWidget;
