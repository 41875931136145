import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import Button from "src/shared/styleguide/button/Button";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import { showCompanyId } from "src/shared/SessionHelper";
import { format } from "date-fns";
import debounce from "lodash.debounce";
import UserInitials from "src/shared/styleguide/common/UserInitials";
import StringUtil from "src/utils/StringUtil";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import ellipsisV from "src/styles/assets/ellipsis-v.svg";
import { GET_CONTACT } from "src/graphql/customers_segments/customers/queries";
import { NO_CACHE } from "src/shared/Constants";
import { HEADER_ITEMS, SOURCES } from "../customerConstants.js";
import { CustomersContext } from "./CustomersContext.js";
import BrowserUtil from "src/utils/BrowserUtil";
import ResultsNotFound from "src/shared/errorpages/ResultsNotFound";
import CustomerEmptyStateIcon from "src/assets/images/customer/customer_empty_state.svg";
import { QUERY_CONTACTS_LIST } from "src/graphql/customers_segments/customers/queries.js";
import * as utils from "../../../shared/Utils";
import CustomerDetailsSidebar from "src/shared/customer-details-sidebar/CustomerDetailsSidebar";
import BlockUi from "react-block-ui";
import { numberToDelimiterConversion } from "src/shared/Utils";

const CustomersContent = ({ setShowAddCustomersModal }) => {
  const {
    detailsShown,
    setDetailsShown,
    filters,
    setFilters,
    initialFilters,
    resetFilters,
    setUpdatedFilters,
    disabledFilters,
    setDisabledFilters,
  } = useContext(CustomersContext);
  // variables
  const perPage = 10;

  //state
  const history = useHistory();
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
  const [maxFiltersOnScreen, setMaxFiltersOnScreen] = useState(3);
  const [customerEmptyState, setCustomerEmptyState] = useState(false);
  const [minSearchTermLength, setMinSearchTermLength] = useState(3);
  const [totalCustomersFound, setTotalCustomersFound] = useState(0);
  const [queriedContact, setQueriedContact] = useState({});

  // methods
  const getCustomerDetailsData = (contactId) => {

    client
      .query({
        query: GET_CONTACT,
        variables: { contactId: contactId, esContact: true },
        fetchCounts: NO_CACHE,
      })
      .then((response) => {
        if (!response.loading && response.data && response.data.contact) {
          setQueriedContact({ ...response.data.contact });
        }
      })
      .then(() => {
        setDetailsShown(true);
      })
      .catch((e) => {
        setDetailsShown(false);
        console.error("Get Customer Details Error: ", e);
      });
  };

  const getCustomerRow = (customer, props = {}) => {
    const {
      contactId,
      address1,
      address2,
      city,
      country,
      createdAt,
      lastServiceDate,
      email,
      state,
      zip,
      fullName,
      source,
      firstName,
      lastName,
      recentActivityDatetime,
      recentActivityName,
      phoneAlt,
      phoneMobile,
      phoneDaytime,
      formattedLastServiceDate,
      communicationInfoEmail,
      communicationInfoPhone,
      communicationInfoMailing,
      customerWantsEmail,
      dcWantsEmail,
      memberWantsEmail,
      customerWantsCall,
      dcWantsCall,
      memberWantsCall,
      customerWantsMail,
      dcWantsMail,
      memberWantsMail,
      customerWantsSms,
      dcWantsSms,
      memberWantsSms,
    } = customer;

    const address =
      (address1 ? address1 : "") +
      (address2 ? `, ${address2}` : "") +
      (city ? `, ${city}` : "") +
      (state ? `, ${state}` : "") +
      (zip ? `, ${zip}` : "") +
      (country ? `, ${country}` : "");

    const smsFilterIndex = filters.filterByType?.findIndex(
      (type) => type.value === "unsubscribed_sms",
    );
    const phoneFilterIndex = filters.filterByType?.findIndex(
      (type) => type.value === "unsubscribed_call",
    );
    const allIndex = filters.filterByType?.findIndex(
      (type) => type.value === "all",
    );

    const phoneToolTip =
      smsFilterIndex >= 0 || phoneFilterIndex >= 0 || allIndex === 0
        ? (customerWantsSms === false ||
            dcWantsSms === false ||
            memberWantsSms === false) &&
          (customerWantsCall === false ||
            dcWantsCall === false ||
            memberWantsCall === false)
          ? smsFilterIndex > phoneFilterIndex
            ? "SMS"
            : "Call"
          : customerWantsSms === false ||
            dcWantsSms === false ||
            memberWantsSms === false
          ? "SMS"
          : customerWantsCall === false ||
            dcWantsCall === false ||
            memberWantsCall === false
          ? "Call"
          : null
        : null;

    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <UserInitials
              firstName={firstName}
              lastName={lastName}
              size="2.2rem"
            />
            <span
              className="text-capitalize cursor-pointer"
              onClick={() => {
                getCustomerDetailsData(customer.contactId);
              }}
            >
              {formattedName(
                fullName
                  ? fullName
                  : firstName && lastName
                  ? firstName + " " + lastName
                  : firstName
                  ? firstName
                  : lastName
                  ? lastName
                  : null,
              )}
            </span>
          </div>
        ),
      },
      {
        node:
          communicationInfoEmail && communicationInfoEmail !== "valid" ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={
                StringUtil.capitalize(communicationInfoEmail) + " email address"
              }
              icon={
                <span>
                  {communicationInfoEmail === "missing"
                    ? "N/A"
                    : StringUtil.highlightWithinWord(email, filters.searchTerm)}
                </span>
              }
            />
          ) : email &&
            (customerWantsEmail === false ||
              dcWantsEmail === false ||
              memberWantsEmail === false) ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={"Unsubscribed From Email"}
              icon={
                <span>
                  {StringUtil.highlightWithinWord(email, filters.searchTerm)}
                </span>
              }
            />
          ) : email ? (
            StringUtil.highlightWithinWord(email, filters.searchTerm)
          ) : (
            "N/A"
          ),
      },
      {
        node:
          communicationInfoPhone && communicationInfoPhone !== "valid" ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={StringUtil.capitalize(communicationInfoPhone) + " phone"}
              icon={
                <span>
                  {communicationInfoPhone === "missing"
                    ? "N/A"
                    : StringUtil.highlightWithinWord(
                        formattedPhoneNumber(
                          phoneMobile,
                          phoneAlt,
                          phoneDaytime,
                        ),
                        filters.searchTerm,
                      )}
                </span>
              }
            />
          ) : formattedPhoneNumber(phoneMobile, phoneAlt, phoneDaytime) !==
              "N/A" && phoneToolTip ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={`Unsubscribed From ${phoneToolTip}`}
              icon={
                <span>
                  {formattedPhoneNumber(phoneMobile, phoneAlt, phoneDaytime)}
                </span>
              }
            />
          ) : (
            formattedPhoneNumber(phoneMobile, phoneAlt, phoneDaytime)
          ),
      },
      {
        node:
          communicationInfoMailing && communicationInfoMailing !== "valid" ? (
            <PopupInfo
              tooltipClassName="customers-table-email-column-tooltip"
              placement="top"
              message={
                StringUtil.capitalize(communicationInfoMailing) +
                " mailing address"
              }
              icon={
                <span>
                  {communicationInfoMailing === "missing" || !address
                    ? "N/A"
                    : address}
                </span>
              }
            />
          ) : address &&
            (customerWantsMail === false ||
              dcWantsMail === false ||
              memberWantsMail === false) ? (
            <PopupInfo
              tooltipClassName="customers-table-address-column-tooltip"
              placement="top"
              message={"Unsubscribed From Mail"}
              icon={<span>{address}</span>}
            />
          ) : address ? (
            <PopupInfo
              tooltipClassName="customers-table-address-column-tooltip"
              placement="top"
              message={address}
              icon={<span>{address}</span>}
            />
          ) : (
            "N/A"
          ),
      },
      {
        node: (
          <span>
            {formattedLastServiceDate && formattedLastServiceDate.trim()
              ? formattedLastServiceDate
              : "N/A"}
          </span>
        ),
      },
      {
        node: recentActivityDatetime ? (
          <PopupInfo
            tooltipClassName="customers-table-address-column-tooltip"
            placement="top"
            message={recentActivityName}
            icon={
              <span>
                {format(new Date(recentActivityDatetime), "MMM dd, yyyy")}
              </span>
            }
          />
        ) : (
          <span>{format(new Date(createdAt), "MMM dd, yyyy")}</span>
        ),
      },
      {
        node: (
          <span>
            {format(new Date(createdAt), "MMM dd, yyyy")}
            {source ? (
              <span className="text-black-50">{` (${SOURCES.filter(
                (src) => src.value === source,
              ).map((filteredSource) => filteredSource.label)})`}</span>
            ) : null}
          </span>
        ),
      },
      {
        node: (
          <div className="option-dropdown">
            <DropdownButton
              alignRight={true}
              direction={false}
              dropShadow={true}
              title={<img alt="Options" src={ellipsisV} />}
              variant="link"
            >
              <Dropdown.Item
                href="#"
                target="_blank"
                onClick={() => {
                  getCustomerDetailsData(customer.contactId);
                }}
              >
                Quick View
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    utils.contactPageUrl(contactId, showCompanyId, false),
                    {
                      prevPage: document.location.pathname,
                    },
                  );
                }}
              >
                Edit
              </Dropdown.Item>
            </DropdownButton>
          </div>
        ),
      },
    ];
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getCustomers({
      page: selected,
    });
  };

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const getCustomers = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      companyId: showCompanyId,
      rpp: perPage,
      sortBy: filters.sortBy ? filters.sortBy.value : undefined,
      sortOrder: filters.sortOrder ? filters.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
      communicationInfo: filters.filterByType.map((filter) => {
        return filter.value;
      }),
    };
    if (filters.searchTerm) {
      queryVariables["searchTerm"] =
        filters.searchTerm && filters.searchTerm.length >= minSearchTermLength
          ? filters.searchTerm.toLowerCase()
          : "";
      queryVariables["searchField"] = filters.searchType
        ? filters.searchType.value
        : "";
    }
    client
      .query({
        query: QUERY_CONTACTS_LIST,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.contacts) {
          const contactsList = res.data.contacts.data;
          const totalCustomers = res.data.contacts.total;
          setDisabledFilters(res.data.contacts.ciMetaData);
          setTotalCustomersFound(numberToDelimiterConversion(totalCustomers));

          //setup customers
          setCustomers(
            contactsList.map((customer) =>
              getCustomerRow(customer, {
                ...queryVariables,
              }),
            ),
          );

          setCustomerEmptyState(contactsList.length === 0);
          setPageCount(Math.ceil(totalCustomers / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  // Construct selected filters
  const showFilters = (length) => {
    let filterData = filters.filterByType.slice(0, length).map((item) => {
      return item.label === "All"
        ? `${item.label} `
        : item === filters.filterByType[length - 1]
        ? `${item.label} `
        : `${item.label} & `;
    });
    filterData = filterData
      ? `${filterData
          .toString()
          .split(",")
          .join("")} (${totalCustomersFound})`
      : "";
    return filterData;
  };

  // Showing the filters with popup
  const showFiltersWithPopup = () => {
    return (
      <>
        {filters.searchTerm.length >= minSearchTermLength
          ? `Showing results for - All Customers ${
              filters.searchType.value.toLowerCase() === "name"
                ? `having `
                : `in `
            }  ${filters.searchType.label.toLowerCase()} ` +
            StringUtil.capitalize(filters.searchTerm) +
            ` and `
          : `Show customers - `}
        {showFilters(maxFiltersOnScreen)}
        ...
        <PopupInfo
          icon={
            <div> {` +${selectedFiltersCount - maxFiltersOnScreen} `} </div>
          }
          placement="top"
          message={filters.filterByType
            .slice(maxFiltersOnScreen, selectedFiltersCount)
            .map((item) => (
              <div>{item.label}</div>
            ))}
        />
      </>
    );
  };

  const showFiltersText = () => {
    // with searchterm and filters less or equal to given filters selected filters
    if (
      selectedFiltersCount <= maxFiltersOnScreen &&
      filters.filterByType.length !== 0 &&
      filters.searchTerm.length >= minSearchTermLength
    ) {
      return (
        `Showing results for - All Customers ${
          filters.searchType.value.toLowerCase() === "name" ? `having ` : `in `
        }  ${filters.searchType.label.toLowerCase()}  ` +
        StringUtil.capitalize(filters.searchTerm) +
        `${
          filters.filterByType[0].label === "All"
            ? ` (${totalCustomersFound})`
            : ` & ` + showFilters(filters.filterByType.length)
        } `
      );
    }
    // with searchterm and filters greater than given filters selected
    else if (
      selectedFiltersCount > maxFiltersOnScreen &&
      filters.searchTerm.length >= minSearchTermLength
    ) {
      return showFiltersWithPopup();
    }
    // with no searchterm and filters less or equal to given filters selected filters
    else if (
      selectedFiltersCount <= maxFiltersOnScreen &&
      filters.filterByType.length !== 0 &&
      filters.searchTerm === ""
    ) {
      return (
        <div>Show customers - {showFilters(filters.filterByType.length)}</div>
      );
    }
    // with no searchterm and filters greater than given filters selected
    else if (
      selectedFiltersCount > maxFiltersOnScreen &&
      filters.searchTerm === ""
    ) {
      return showFiltersWithPopup();
    }
    // with searchterm and no filters selected
    else if (
      filters.searchTerm &&
      filters.searchTerm.length >= minSearchTermLength
    ) {
      return (
        `Showing results for - All Customers ${
          filters.searchType.value.toLowerCase() === "name" ? `having ` : `in `
        }  ${filters.searchType.label.toLowerCase()} ` +
        StringUtil.capitalize(filters.searchTerm) +
        ` (${totalCustomersFound})`
      );
    } else {
      return `Show customers - All (${totalCustomersFound})`;
    }
  };

  const formattedPhoneNumber = (phoneMobile, phoneAlt, phoneDaytime) => {
    const phoneNumber = phoneMobile
      ? phoneMobile
      : phoneAlt
      ? phoneAlt
      : phoneDaytime
      ? phoneDaytime
      : null;

    return phoneNumber
      ? StringUtil.highlightWithinWord(
          utils.formatPhoneNumber(phoneNumber),
          filters.searchTerm,
          true,
        )
      : "N/A";
  };

  const formattedName = (name) => {
    return name
      ? StringUtil.highlightWithinWord(name, filters.searchTerm, true)
      : "N/A";
  };

  //effects
  useEffect(() => {
    setSelectedFiltersCount(filters.filterByType.length);
    setFiltersApplied(
      (filters.searchTerm !== initialFilters.searchTerm &&
        filters.searchTerm.length >= minSearchTermLength) ||
        (filters.filterByType.length >= 1 &&
          filters.filterByType[0].label !== "All"),
    );
  }, [filters]);

  useEffect(() => {
    if (
      filters.searchTerm.length >= minSearchTermLength ||
      filters.searchTerm === initialFilters.searchTerm
    ) {
      setCurrentPage(0);
      getCustomers({
        page: 0,
      });
    }
  }, [filters.searchTerm]);

  useEffect(() => {
    if (filters.searchType.value === "state") {
      setMinSearchTermLength(2);
    } else {
      setMinSearchTermLength(3);
    }

    setCurrentPage(0);
    getCustomers({
      page: 0,
    });
  }, [filters.searchType]);

  useEffect(() => {
    if (BrowserUtil.isIPad()) {
      if (filters.searchTerm !== "") {
        setMaxFiltersOnScreen(1);
      } else {
        setMaxFiltersOnScreen(2);
      }
    } else {
      if (filters.searchTerm !== "") {
        setMaxFiltersOnScreen(3);
      } else {
        setMaxFiltersOnScreen(4);
      }
    }
  }, [selectedFiltersCount]);

  useEffect(() => {
    setCurrentPage(0);
    getCustomers({
      page: 0,
    });
  }, [filters.sortBy, filters.filterByType]);

  // render
  return (
    <div className="customers-content-view mt-4 mb-4">
      <div className="customerlist-label">Customer List</div>
      {(!customerEmptyState || (filtersApplied && customerEmptyState)) && (
        <div className="mb-2 filters-list">
          {showFiltersText()}
          <div className="resest-filter">
            {filtersApplied || filters.searchTerm ? (
              <Button
                variant="link"
                className="m-0 p-0 ml-2 font-weight-bold sg-text-info"
                onClick={() => resetFilters()}
                style={{
                  fontWeight: "0.85rem",
                }}
              >
                Reset Filter
              </Button>
            ) : null}
          </div>
        </div>
      )}
      {/* table */}
      {!filtersApplied && customerEmptyState ? (
        <React.Fragment>
          <ResultsNotFound
            primaryMsg="You haven’t added any customers yet."
            secondaryMsg="To get started add your customers by either connecting with your business accounting software or uploading a CSV."
            svgIcon={
              <img src={CustomerEmptyStateIcon} alt="Customer Not Found" />
            }
            primaryMsgClassName="primary-message-style"
            secondaryMsgClassName="secondary-message-style"
          ></ResultsNotFound>
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={() => setShowAddCustomersModal(true)}
            >
              Add Customers
            </Button>
          </div>
        </React.Fragment>
      ) : filtersApplied && customerEmptyState ? (
        <ResultsNotFound
          primaryMsg="No results found"
          secondaryMsg="We couldn’t find any results. Try adjusting your filters to display results."
          primaryMsgClassName="primary-message-style"
          secondaryMsgClassName="secondary-message-style"
        />
      ) : (
        <div className="customers-table-container mt-2 mb-0">
          {detailsShown && (
            <div className="customer-details-sidebar-restrictor">
              <CustomerDetailsSidebar
                setDetailsShown={setDetailsShown}
                contact={queriedContact}
                navigateToOldMembers={false}
              />
            </div>
          )}
          <BlockUi loader={<React.Fragment />} blocking={loading}>
            <Table
              className="customer-table mb-0"
              loading={loading}
              head={[
                // Name
                <SortableTableHeader
                  sortable={HEADER_ITEMS[0].sortable}
                  columnLabel={HEADER_ITEMS[0].columnLabel}
                  columnValue={HEADER_ITEMS[0].columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />,
                // Email Address
                <SortableTableHeader
                  sortable={HEADER_ITEMS[1].sortable}
                  columnLabel={HEADER_ITEMS[1].columnLabel}
                  columnValue={HEADER_ITEMS[1].columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />,
                // Phone Number
                <SortableTableHeader
                  sortable={HEADER_ITEMS[2].sortable}
                  columnLabel={HEADER_ITEMS[2].columnLabel}
                  columnValue={HEADER_ITEMS[2].columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />,
                // Mailing Address
                <div style={{ minWidth: 132 }}>
                  <SortableTableHeader
                    sortable={HEADER_ITEMS[3].sortable}
                    columnLabel={HEADER_ITEMS[3].columnLabel}
                    columnValue={HEADER_ITEMS[3].columnValue}
                    onSort={onSort}
                    currentSortValue={filters.sortBy.value}
                    currentSortOrder={filters.sortOrder.value}
                  />
                </div>,
                // Last Service Date
                <div className="column-service-date">
                  <SortableTableHeader
                    sortable={HEADER_ITEMS[4].sortable}
                    columnLabel={HEADER_ITEMS[4].columnLabel}
                    columnValue={HEADER_ITEMS[4].columnValue}
                    onSort={onSort}
                    currentSortValue={filters.sortBy.value}
                    currentSortOrder={filters.sortOrder.value}
                  />
                </div>,
                // Recent Activity
                <div className="column-service-date">
                  <SortableTableHeader
                    sortable={HEADER_ITEMS[5].sortable}
                    columnLabel={HEADER_ITEMS[5].columnLabel}
                    columnValue={HEADER_ITEMS[5].columnValue}
                    onSort={onSort}
                    currentSortValue={filters.sortBy.value}
                    currentSortOrder={filters.sortOrder.value}
                  />
                </div>,
                // Created Date
                <div className="column-created-on">
                  <SortableTableHeader
                    sortable={HEADER_ITEMS[6].sortable}
                    columnLabel={HEADER_ITEMS[6].columnLabel}
                    columnValue={HEADER_ITEMS[6].columnValue}
                    onSort={onSort}
                    currentSortValue={filters.sortBy.value}
                    currentSortOrder={filters.sortOrder.value}
                  />
                </div>,
                // Quick view and Edit
                <SortableTableHeader
                  sortable={HEADER_ITEMS[7].sortable}
                  columnLabel={HEADER_ITEMS[7].columnLabel}
                  columnValue={HEADER_ITEMS[7].columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />,
              ]}
              body={customers}
              hasPagination={true}
              pageCount={pageCount}
              onPagination={onPagination}
              currentPage={currentPage}
            />
          </BlockUi>
        </div>
      )}
    </div>
  );
};

CustomersContent.propTypes = {
  setShowAddCustomersModal: PropTypes.func,
};

export default CustomersContent;
