import React from "react";
import PropTypes from "prop-types";
import "./ReviewsOverviewChart.scss";
import ReactHighcharts from "react-highcharts";

/**
 * Component to render Area chart for reviews.
 * @param chartData - Data to be rendered on chart.
 * @param color - Color gradient for chart.
 * @param seriesTitle - Series Title.
 * @returns {XML} - Chart.
 */
const ReviewsOverviewChart = ({
  chartData = [],
  dateRanges,
  color,
  seriesTitle,
  seriesStroke = "",
  markerColor,
}) => {
  /**
   * method to get chart configuration
   */
  const chartConfig = () => {
    chartData = chartData ? chartData : [];
    return {
      chart: {
        type: "area",
        className: "chart",
        margin: 0,
        marginLeft: 11,
        marginRight: 11,
        borderRadius: 1.2,
        showEmpty: true,
      },
      title: null,
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              halo: {
                size: 4,
              },
            },
          },
          marker: {
            enabled: false,
            radius: 2,
            states: {
              hover: {
                fillColor: markerColor,
                radius: 4,
                radiusPlus: 2,
                width: 10,
                height: 10,
              },
            },
          },
        },
      },
      tooltip: {
        backgroundColor: "black",
        formatter: function () {
          return (
            dateRanges[this.x] + "<br/> Reviews: " + "<b>" + this.y + "</b>"
          );
        },
        style: {
          color: "white",
        },
      },
      series: [
        {
          name: seriesTitle,
          data: chartData,
          color: color,
          showInLegend: false,
          className: seriesStroke,
        },
      ],
    };
  };
  return <ReactHighcharts config={chartConfig()} />;
};

ReviewsOverviewChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  chartData: PropTypes.array.isRequired,
};

export default ReviewsOverviewChart;
