import React from "react";
import ReviewTopicsSettings from "./ReviewTopicsSettings";
import PrivateFeedbackSettings from "./PrivateFeedbackSettings";

const PublishingNotification = () => {
  return (
    <React.Fragment>
      <div className="mb-4">
        <ReviewTopicsSettings />
      </div>
      <div className="mb-4">
        <PrivateFeedbackSettings />
      </div>
    </React.Fragment>
  );
};

export default PublishingNotification;
