import React from "react";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import Header from "src/shared/styleguide/header/Header";
import Sidebar from "src/shared/styleguide/sidebar/Sidebar";
import StyleGuideRoutes, {
  StyleGuideComponents
} from "src/routes/StyleGuideRoutes";
import { useLocation } from "react-router";
import "./StyleGuide.scss";

// Configs for styleguide
const headerConfig = {
  leftMenu: [{ id: 0, label: "STYLE GUIDE", href: "/styleguide" }],
  rightMenu: [{ id: 0, label: <small>VERSION 1</small>, href: "#" }]
};

export const StyleGuide = ({
  leftHeaderMenu = headerConfig.leftMenu,
  rightHeaderMenu = headerConfig.rightMenu,
  sidebarCategories = StyleGuideComponents
}) => {
  useDocumentTitle("Style Guide");
  const location = useLocation();
  return (
    <React.Fragment>
      <Header
        brandLogoAlt="Customer Lobby"
        leftMenus={leftHeaderMenu}
        rightMenus={rightHeaderMenu}
      />
      <Row>
        <Col className="styleguide-sidebar pl-5" xl={2} lg={3} md={3}>
          <Sidebar
            categories={sidebarCategories.map(category => ({
              ...category,
              links: category.links.map(link => ({
                ...link,
                isActive: location.pathname === link.to
              }))
            }))}
          />
        </Col>
        <Col className="styleguide-content px-5 mt-2" xl={8} lg={8} md={8}>
          <StyleGuideRoutes />
        </Col>
      </Row>
    </React.Fragment>
  );
};

StyleGuide.propTypes = {
  leftHeaderMenu: PropTypes.array,
  rightHeaderMenu: PropTypes.array,
  sidebarCategories: PropTypes.array
};

export default StyleGuide;
