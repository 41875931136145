import React from "react";
import "./SuccessMessage.scss";
import successCircle from "../../../../../../styles/assets/success_circle.png";

const SuccessToast = ({ display, message }) => {
  return (
    <div className="edit-email-success" style={{ display }}>
      <div className="edit-success-inner-container">
        <img alt="Success" className="success-circle-img" src={successCircle} />
        <div className="edit-email-success-toast">{message}</div>
      </div>
    </div>
  );
};

export default SuccessToast;
