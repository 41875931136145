import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import Button from "src/shared/styleguide/button/Button";
import PropTypes from "prop-types";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import highlighterStyle from "react-syntax-highlighter/dist/esm/styles/prism/ghcolors";
import "./CodePreview.scss";
SyntaxHighlighter.registerLanguage("jsx", jsx);

export const CodePreview = ({ label = "Preview", code = "", elements, codeLanguage = "jsx" }) => {
  const [copied, setCopied] = useState(false);
  const [copyInterval, setCopyInterval] = useState(null);
  const setCopiedTemporarily = () => {
    setCopied(true);
    const interval = setTimeout(() => setCopied(false), 3000);
    setCopyInterval(interval);
  };
  useEffect(() => {
    return () => {
      if (copyInterval) {
        clearInterval(copyInterval);
      }
    };
  });

  return (
    <Card className="code-preview">
      <div className="p-1 code-preview-label">{label}</div>
      <div className="code-preview-elements">{elements}</div>
      {code !== "" ? (
        <code className="code-preview-code">
          <CopyToClipboard onCopy={setCopiedTemporarily} text={code || ""}>
            <Button
              size="xs"
              variant="link"
              className="font-weight-bold small text-secondary text-decoration-none copy-button"
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          </CopyToClipboard>
          <SyntaxHighlighter
            style={highlighterStyle}
            customStyle={{
              backgroundColor: "transparent",
              padding: 0,
              border: 0
            }}
            language={codeLanguage}
          >
            {code}
          </SyntaxHighlighter>
        </code>
      ) : null}
    </Card>
  );
};

CodePreview.defaultProps = {
  label: "Preview",
  code: ""
};

CodePreview.propTypes = {
  label: PropTypes.string,
  elements: PropTypes.node,
  code: PropTypes.node,
  codeLanguage: PropTypes.oneOf(["jsx"])
};

export default CodePreview;
