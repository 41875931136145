import React from "react";
import creditsInformation from "../../../../styles/assets/information.png";

const CreditInfoCard = ({
  source,
  grantDate,
  credits,
  dateOfMonth,
  availText,
  companyId,
}) => {
  const handleRedirect = () => {
    if (source === "CustomerCalls") {
      return (
        <a
          style={{ cursor: "pointer" }}
          href={`${process.env.REACT_APP_MEMBER_URL}companies/${companyId}/customer_call_credits`}
          rel="noopener noreferrer"
          target="_blank"
          className="credit-txt-three"
        >
          Track Credits
        </a>
      );
    }
    return null;
  };

  return (
    <div className="credits-container">
      <div className="credits-label">Credit Info</div>
      <div className="credits-divider"></div>
      <div className="credits-upper-txt-container">
        <div className="credit-txt-one">{availText}</div>
        <div className="credit-txt-two">{credits}</div>
        {handleRedirect()}
      </div>
      <div className="credits-upper-txt-container">
        <div className="credit-txt-one">Next Grant Date:</div>
        <div className="credit-txt-two">{grantDate}</div>
      </div>
      <div className="credits-lower-txt"></div>
      <div className="credits-lower-container">
        <img
          className="credits-info-img"
          src={creditsInformation}
          alt="Credit Information"
        />
        <div className="credits-info-txt">
          Credits for your account are granted on the {dateOfMonth} of each
          month
        </div>
      </div>
    </div>
  );
};

export default CreditInfoCard;
