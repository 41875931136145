import React from "react";
import Highcharts from "highcharts";
import ReactHighcharts from "highcharts-react-official";
import PropTypes from "prop-types";
import "./Chart.scss";

const Chart = React.forwardRef(({ chartId, chartConfig, immutable = false }, ref) => {
  return (
    <ReactHighcharts
      ref={ref}
      highcharts={Highcharts}
      options={chartConfig}
      domProps={{ id: chartId }}
      immutable={immutable}
    />
  );
});

Chart.defaultProps = {
  immutable: false
};

Chart.propTypes = {
  chartId: PropTypes.string.isRequired,
  chartConfig: PropTypes.object.isRequired,
  immutable: PropTypes.bool,
};

export default Chart;
