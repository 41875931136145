import React from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CustomerContent from "./CustomerContent";
import { PageHeader } from "src/shared/styleguide/common/PageHeader";
import { CUSTOMER_TAB } from "src/shared/Constants";
import "./Customer.scss";

const Customer = () => {
  const selectedTab = CUSTOMER_TAB;
  const history = useHistory();
  const prevPage =
    history.location.state &&
      history.location.state.prevPage
      ? history.location.state.prevPage
      : "";
  const headerRef = React.useRef({ prevPage: prevPage });
  const usePrevPage =
    headerRef && headerRef.current && !!headerRef.current.prevPage;

  // render
  return (
    <React.Fragment>
      <PageHeader
        className="customer-header"
        showArrow={usePrevPage}
        onArrowClick={() => {
          history.push(usePrevPage ? headerRef.current.prevPage : "#");
        }}
        leftComponent={selectedTab.pageHeader}
      />
      <div className="customer-container">
        <div className="customer-block">
          <Row>
            <Col xl={{ span: 10, offset: 1 }} sm={12}>
              <CustomerContent />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customer;
