import React from "react";
import ReactPaginate from "react-paginate";
import "./PaginatorComponent.scss";

/**
 * Component to render the paginator.
 * @param pageCount Total number of pages.
 * @param pageChangeHandler on page change handler.
 * @param current page number.
 */
const PaginatorComponent = ({ pageCount, pageChangeHandler, currentPage }) => {
  const handlePageRange = () => {
    if (pageCount < 11) return 1;
    if (pageCount < 21) return 2;
    if (pageCount < 31) return 3;
    if (pageCount < 41) return 4;
    if (pageCount < 51) return 5;
  };
  // Note that this component uses zero (0) indexed pages, but our APIs are generally
  // indexed on one (1). This may cause unexpected behavior. Be sure to +/- 1 appropriately.
  let forcePageObj = {};
  if (currentPage === 0) {
    forcePageObj["forcePage"] = 0;
  }
  return (
    <div id="Paginator">
      <ReactPaginate
        previousLabel={
          <span className="left-arrow" aria-hidden="true">
            Prev
          </span>
        }
        nextLabel={
          <span className="right-arrow" aria-hidden="true">
            Next
          </span>
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        initialSelected={1}
        forcePage={currentPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={handlePageRange()}
        containerClassName={"pagination d-flex justify-content-center mt-4"}
        pageClassName={"page-counts"}
        disabledClassName={"disable-page"}
        activeClassName={"active-page d-flex justify-content-center"}
        onPageChange={(e) => pageChangeHandler(e)}
        {...forcePageObj}
        pageCount={Math.ceil(pageCount)}
      />
    </div>
  );
};

export default PaginatorComponent;
