import React from "react";
import segmentEmptyState from "src/styles/assets/segments/segment-empty-state.svg";

const SegmentEmptyState = () => (
  <div className="selected-criteria-card empty-state">
    <img src={segmentEmptyState} alt="Card Style Guide Image" />
    <div className="message-body">
      <h3>Build your desired Segment</h3>
      <div>
        Click on add group in the left column to select the criteria you want to
        Include or Exclude for your segment.
      </div>
    </div>
  </div>
);

export default SegmentEmptyState;