import React from "react";
import "./Success.scss";
import successCircle from "src/styles/assets/success_circle.png";

const SuccessToast = ({ display }) => {
  return (
    <div className="edit-email-success" style={{ display }}>
      <div className="edit-success-inner-container">
        <img alt="Success" className="success-circle-img" src={successCircle} />
        <div className="edit-email-success-toast">
          Email Successfully Resent
        </div>
      </div>
    </div>
  );
};

export default SuccessToast;
