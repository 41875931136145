import React, { Fragment } from "react";

const ChallengeLearnModal = props => {
  return (
    <Fragment>
      <p>
        A Valid Review is written by a customer of the business being reviewed
        and includes none of the following: Obscenities, discriminatory
        language, or other inappropriate comments (including any comments
        written in a language other than English) not suitable for this forum;
        Advertisements, “spam” content, or references to other companies,
        reviewers, services, products, offers, or web sites; Email addresses,
        URLs, phone numbers, physical addresses, or other forms of contact
        information; Reviews that: are not written about an experience a
        customer had within the last 12 months; have been withdrawn by the
        reviewer; or that Customer Lobby believes to be untrue; Critical or
        spiteful comments about other reviews or their authors; Computer
        viruses, scripts, tags, worms or other potentially damaging computer
        programs or files; or Comments that may reasonably be considered to be
        defamatory, libelous, hateful, racially or religiously biased or
        offensive, unlawfully threatening or unlawfully harassing to any
        individual, partnership or corporation.
      </p>
    </Fragment>
  );
};

export default ChallengeLearnModal;
