const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return true;
  phoneNumber = phoneNumber.replace(/\s+/g, "");
  return phoneNumber.length > 9 &&
    phoneNumber.match(/^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/);
}

export {
  validatePhoneNumber
}
