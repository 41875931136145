import React from "react";

const FullpageOverlay = ({ children }) => {
  // render
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        background: "#ffffff",
        zIndex: 99999,
        display: "grid",
        placeItems: "center"
      }}
    >
      {children}
    </div>
  );
};

export default FullpageOverlay;
