export const REVIEW_DATA = [
  {
    summary:
      "Hello all keep eye.Test Please ignore using for twitter syndication functionality test new review text added.",
    body:
      "Test123456 Test Please ignore using for twitter syndication functionality test new review text added.123456",
    status: "publish",
    reviewedAt: "2019-06-26 09:05:05 UTC",
    contact: { name: "Aaliyah Moore"},
    totalScore: 1.0,
    source: "Web",
    platform: "Customer Lobby",
    featured: true,
    review_topics: [
      {
        id: 4985365,
        review_topic: "Price for Value",
        rating: 2
      },
      {
        id: 4985366,
        review_topic: "Value",
        rating: 0.8
      },
      {
        id: 4985367,
        review_topic: "Value for Price",
        rating: 1.0
      }
    ],
    review_comments: [
      {
        id: 90444,
        comment: "Test2 comment",
        created_at: "2020-01-08 06:56:31 UTC"
      },
      {
        id: 90443,
        comment: "Test comment",
        created_at: "2020-01-08 06:56:13 UTC"
      }
    ],
    review_challenge: {
      id: 28211,
      reason: "other",
      status: "requested"
    },
    review_withdrawal_request: {
      id: 19898,
      status: "requested",
      request_date: "2020-01-08 06:58:08 UTC",
      note: "Test withdral",
      author_action_date: null
    },
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary: "Deleniti soluta cupiditate veritatis voluptatem quis.",
    body:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    status: "pending",
    reviewedAt: "2019-12-27 09:05:05 UTC",
    contact: { name: "Aaliyah Moore"},
    totalScore: 2.0,
    source: "Web",
    platform: "Customer Lobby",
    featured: false,
    review_topics: [
      {
        id: 4985365,
        review_topic: "Price for Value",
        rating: 0.2
      },
      {
        id: 4985366,
        review_topic: "Value",
        rating: 1
      },
      {
        id: 4985367,
        review_topic: "Value for Price",
        rating: 3.0
      }
    ],
    review_comments: [
      {
        id: 90444,
        comment: "Test3 comment",
        created_at: "2020-12-27 06:56:31 UTC"
      },
      {
        id: 90443,
        comment: "Test",
        created_at: "2020-01-08 06:56:13 UTC"
      }
    ],
    review_challenge: {
      id: 28211,
      reason: "other",
      status: "requested"
    },
    review_withdrawal_request: {
      id: 19898,
      status: "requested",
      request_date: "2020-01-08 06:58:08 UTC",
      note: "Test withdral",
      author_action_date: null
    },
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary: "Deleniti soluta cupiditate veritatis voluptatem quis.",
    body:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    status: "publish",
    reviewedAt: "2020-01-02 09:05:05 UTC",
    contact: { name: "Leslie Cooper"},
    totalScore: 3.0,
    source: "Email",
    platform: "Google",
    featured: false,
    review_topics: [
      {
        id: 4985365,
        review_topic: "Price for Value",
        rating: 0.2
      }
    ],
    review_comments: [],
    review_challenge: {
      id: 28211,
      reason: "other",
      status: "requested"
    },
    review_withdrawal_request: {
      id: 19898,
      status: "requested",
      request_date: "2020-01-08 06:58:08 UTC",
      note: "Test withdral",
      author_action_date: null
    },
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary: "Deleniti soluta cupiditate veritatis voluptatem quis.",
    body:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    status: "pending",
    reviewedAt: "2020-01-07 09:05:05 UTC",
    contact: { name: "Grace George" },
    totalScore: 3.5,
    source: "Customer Calls",
    platform: "Customer Lobby",
    featured: false,
    review_topics: [
      {
        id: 4985367,
        review_topic: "Value for Price",
        rating: 3.0
      }
    ],
    review_comments: [
      {
        id: 90444,
        comment: "Test3 comment",
        created_at: "2020-12-27 06:56:31 UTC"
      }
    ],
    review_challenge: null,
    review_withdrawal_request: null,
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary:
      "Hello all keep eye.Test Please ignore using for twitter syndication functionality test new review text added.",
    body:
      "Test123456 Test Please ignore using for twitter syndication functionality test new review text added.123456",
    status: "publish",
    reviewedAt: "2020-01-05 09:05:05 UTC",
    contact: { name: "April Robinson"},
    totalScore: 1.0,
    source: "Handwritten",
    platform: "Google",
    featured: false,
    review_topics: [
      {
        id: 4985365,
        review_topic: "Price for Value",
        rating: 0.4
      },
      {
        id: 4985366,
        review_topic: "Value",
        rating: 0.8
      },
      {
        id: 4985367,
        review_topic: "Value for Price",
        rating: 1.0
      }
    ],
    review_comments: [],
    review_challenge: {
      id: 28211,
      reason: "other",
      status: "requested"
    },
    review_withdrawal_request: {
      id: 19898,
      status: "requested",
      request_date: "2020-01-08 06:58:08 UTC",
      note: "Test withdral",
      author_action_date: null
    },
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary: "Sed volutpat non ligula quis volutpat",
    body:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet",
    status: "publish",
    reviewedAt: "2019-12-12 09:05:05 UTC",
    contact: { name: "Aaliyah Moore" },
    totalScore: 4.0,
    source: "Custom Invites",
    platform: "Customer Lobby",
    featured: false,
    review_topics: [
      {
        id: 4985365,
        review_topic: "Price for Value",
        rating: 0.2
      },
      {
        id: 4985366,
        review_topic: "Value",
        rating: 1
      },
      {
        id: 4985367,
        review_topic: "Value for Price",
        rating: 3.0
      }
    ],
    review_comments: [
      {
        id: 90444,
        comment: "Test3 comment",
        created_at: "2020-12-27 06:56:31 UTC"
      },
      {
        id: 90443,
        comment: "Test",
        created_at: "2020-01-08 06:56:13 UTC"
      }
    ],
    review_challenge: {
      id: 28211,
      reason: "other",
      status: "requested"
    },
    review_withdrawal_request: {
      id: 19898,
      status: "requested",
      request_date: "2020-01-08 06:58:08 UTC",
      note: "Test withdral",
      author_action_date: null
    },
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary: "Deleniti soluta cupiditate veritatis voluptatem quis.",
    body:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    status: "publish",
    reviewedAt: "2020-01-02 09:05:05 UTC",
    contact: { name: "Leslie Cooper"},
    totalScore: 3.0,
    source: "Email",
    platform: "Google",
    featured: false,
    review_topics: [
      {
        id: 4985365,
        review_topic: "Price for Value",
        rating: 0.2
      }
    ],
    review_comments: [],
    review_challenge: {
      id: 28211,
      reason: "other",
      status: "requested"
    },
    review_withdrawal_request: {
      id: 19898,
      status: "requested",
      request_date: "2020-01-08 06:58:08 UTC",
      note: "Test withdral",
      author_action_date: null
    },
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  },
  {
    summary: "Deleniti soluta cupiditate veritatis voluptatem quis.",
    body:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
    status: "pending",
    reviewedAt: "2020-01-07 09:05:05 UTC",
    contact: { name: "Grace George"},
    totalScore: 3.5,
    source: "Customer Calls",
    platform: "Customer Lobby",
    featured: false,
    review_topics: [
      {
        id: 4985367,
        review_topic: "Value for Price",
        rating: 3.0
      }
    ],
    review_comments: [],
    review_challenge: null,
    review_withdrawal_request: null,
    suggestions: [
      {
        suggestion_topic: "How can they improve?",
        suggestion_answer:
          "The device the man used to clear the shower drain clog had a broken end. Even though he was able to clear the drain, nothing was hooked and brought up so the cause of the clog is still unknown. One of the reasons I chose Carini was because your web ad stated that you used advanced equipment."
      },
      {
        suggestion_topic: "How did you first hear about them?",
        suggestion_answer: "Their office is on our street."
      },
      {
        suggestion_topic: "Will you use them again?",
        suggestion_answer: "Yes, absolutely!!!"
      }
    ]
  }
];
