import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import BlockUi from "react-block-ui";
import Chart from "src/shared/styleguide/charts/Chart";
import "./Chart.scss";

const ResponsiveDonutChart = ({
  chartData = [],
  chartId,
  height = 0,
  labelPostFix = "",
  legendVerbose,
  legendY = 0,
  loading = false,
  pieSize = 188,
  pieThickness = 36,
  showCarousel,
  spacingBottom = 0,
  spacingTop = 0,
  tooltipClassName,
  tooltipFormatter = undefined,
}) => {
  // state
  const fontSize = 6 + pieSize * 0.0735;
  const fontPadding = 24;
  const dataPointCount = chartData.data ? chartData.data.length : 0;
  const [chartWidth, setChartWidth] = useState(0);
  const chartRef = useRef();
  const [noData, setNoData] = useState(false);
  const [chartConfig, setChartConfig] = useState({
    chart: {
      height:
        height > 0
          ? height
          : pieSize * (legendVerbose ? 1.35 : 1.25) +
            (legendVerbose
              ? ((fontSize + fontPadding) * dataPointCount) / 3
              : 0),
      type: "pie",
      align: "center",
      verticalAlign: "middle",
      spacingTop:
        spacingTop > 0
          ? spacingTop
          : legendVerbose
          ? dataPointCount > 2
            ? -((fontSize + fontPadding) * (dataPointCount - 1)) / 3
            : 0
          : 0,
      spacingBottom:
        spacingBottom > 0
          ? spacingBottom
          : legendVerbose
          ? dataPointCount > 2
            ? fontSize + fontPadding
            : 0
          : 0,
      style: {
        layout: "horizontal",
        floating: true,
        width: "100%",
        "padding-bottom": showCarousel ? 40 : legendVerbose ? 20 : 0,
      },
    },
    title: {
      text: chartData.chartTitle ? chartData.chartTitle : null,
      verticalAlign: "middle",
      align: "center",
      style: {
        color: "#343a40",
        fontWeight: 700,
        fontSize: `${fontSize}px`,
        "font-family": "'proxima-nova', Arial, Helvetica, sans-serif",
      },
      y: fontSize,
      x: 0,
    },
    tooltip: {
      outside: true,
      followPointer: true,
      formatter: tooltipFormatter,
      useHTML: !!tooltipClassName,
      backgroundColor: tooltipClassName ? null : "unset",
      borderWidth: 0,
      shadow: false,
      className: tooltipClassName,
    },
    legend: {
      layout: "vertical",
      styledMode: true,
      itemStyle: {
        color: "#667587",
        "font-size": "15px",
        "font-weight": "400",
        "font-family": "'proxima-nova', Arial, Helvetica, sans-serif",
      },
      itemHoverStyle: {
        fontWeight: "bold",
      },
      itemMarginTop: legendVerbose ? 6 : 0,
      itemMarginBottom: legendVerbose ? 6 : 0,
      align: "center",
      verticalAlign: "middle",
      shadow: false,
      y:
        legendY > 0
          ? legendY
          : pieSize / 2.1 +
            (legendVerbose
              ? ((fontSize + fontPadding) * dataPointCount) / 3
              : 18),
      symbolHeight: 14,
      symbolWidth: 14,
      symbolPadding: 8,
      labelFormatter() {
        return legendVerbose
          ? `${this.name} (${this.count || 0}${labelPostFix})`
          : `${this.name}`;
      },
    },
    plotOptions: {
      pie: {
        className: "responsive-donut-chart",
        cursor: "pointer",
        size: pieSize >= 200 ? pieSize * 0.8 : pieSize * 0.57 + 30,
        innerSize: pieSize >= 200 ? pieSize * 0.8 - (pieThickness * 2) : pieSize * 0.57,
        dataLabels: { enabled: false },
        showInLegend: true,
        states: {
          hover: {
            halo: null,
          },
        },
        borderWidth: 0,
        borderColor: null
      },
    },
    series: chartData,
    responsive: {
      rules: [
        {
          condition: {
            callback: function() {
              setChartWidth(this.chartWidth);
              return this.chartWidth >= 596 && legendVerbose;
            },
          },
          chartOptions: {
            chart: {
              margin: [90, 0, 16, 0],
              height: pieSize * 1.14,
              spacingTop: 0,
              spacingBottom: 0,
            },
            title: {
              align: "left",
              x: chartData.chartTitle
                ? pieSize / 2 -
                  (chartData.chartTitle
                    ? chartData.chartTitle.toString().length === 1
                      ? chartData.chartTitle.length * 0.002 * pieSize
                      : [",", "."].some((smallChar) =>
                          chartData.chartTitle.includes(smallChar),
                        )
                      ? chartData.chartTitle.length * 0.022 * pieSize
                      : chartData.chartTitle.length * 0.014 * pieSize
                    : 0)
                : null,
              y: fontSize + 38,
            },
            legend: {
              align: "left",
              verticalAlign: "middle",
              x: pieSize + 40,
              y: 42,
            },
            plotOptions: {
              pie: {
                center: [`${pieSize / 2}`, "50%"],
              },
            },
          },
        },
      ],
    },
  });

  // methods

  // effects
  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.title.text = chartData.chartTitle
      ? chartData.chartTitle
      : null;
    newChartConfig.title.x =
      chartData.chartTitle && newChartConfig.title.align === "left"
        ? pieSize / 2 -
          (chartData.chartTitle
            ? chartData.chartTitle.toString().length === 1
              ? chartData.chartTitle.length * 0.002 * pieSize
              : [",", "."].some((smallChar) =>
                  chartData.chartTitle.includes(smallChar),
                )
              ? chartData.chartTitle.length * 0.022 * pieSize
              : chartData.chartTitle.length * 0.014 * pieSize
            : 0)
        : null;
    newChartConfig.series = chartData;
    setChartConfig(newChartConfig);
  }, [chartData]);

  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.title.x =
      chartData.chartTitle && newChartConfig.title.align === "left"
        ? pieSize / 2 -
          (chartData.chartTitle
            ? chartData.chartTitle.toString().length === 1
              ? chartData.chartTitle.length * 0.002 * pieSize
              : [",", "."].some((smallChar) =>
                  chartData.chartTitle.includes(smallChar),
                )
              ? chartData.chartTitle.length * 0.022 * pieSize
              : chartData.chartTitle.length * 0.014 * pieSize
            : 0)
        : null;
    setChartConfig(newChartConfig);
  }, [chartWidth]);

  useEffect(() => {
    loading ? setNoData(true) : setNoData(false);
  }, [loading]);

  // render
  return (
    <BlockUi
      loader={<React.Fragment />}
      blocking={noData}
      className="card-chart"
      style={{
        height: "100%",
      }}
    >
      <Chart ref={chartRef} chartId={chartId} chartConfig={chartConfig} />
    </BlockUi>
  );
};

ResponsiveDonutChart.propTypes = {
  chartData: PropTypes.object,
  chartId: PropTypes.string.isRequired,
  height: PropTypes.number,
  labelPostFix: PropTypes.string,
  legendVerbose: PropTypes.bool,
  legendY: PropTypes.number,
  loading: PropTypes.bool,
  pieSize: PropTypes.number,
  pieThickness: PropTypes.number,
  showCarousel: PropTypes.bool,
  spacingBottom: PropTypes.number,
  spacingTop: PropTypes.number,
  tooltipClassName: PropTypes.string,
  tooltipFormatter: PropTypes.func,
};

export default ResponsiveDonutChart;