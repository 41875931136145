import React from "react";
import Chart from "src/shared/styleguide/charts/Chart";
import Card from "src/shared/styleguide/card/Card";
import BlockUi from "react-block-ui";
import PropTypes from "prop-types";

export const DonutChart = ({
  popupInfoMsg,
  title,
  subTitle,
  chartId,
  categories,
  chartHeight,
  noData = false,
  showLegend = true,
  chartData = [],
  tooltipFormatter = undefined,
  ...props
}) => {
  // state

  return (
    <BlockUi
      loader={<React.Fragment />}
      className="card-chart no-chart-data"
      blocking={noData}
    >
      <Card
        padding={0}
        popupInfoMsg={popupInfoMsg}
        title={<div className="pl-4 pt-4 pb-2 d-inline-block">{title}</div>}
        subTitle={
          <div className="pl-4 pt-1 pb-2 sg-card-subtitle d-inline-block">
            {subTitle}
          </div>
        }
        cardBodyStyle={{
          paddingLeft: "1rem",
          paddingRight: "1rem"
        }}
        {...props}
      >
        <Chart
          chartId={chartId}
          chartConfig={{
            chart: {
              type: "column",
              height: chartHeight
            },
            xAxis: { categories },
            yAxis: {
              min: 0,
              title: { text: null }
            },
            tooltip: {
              formatter: tooltipFormatter
            },
            legend: {
              enabled: showLegend,
              align: "center",
              verticalAlign: "bottom",
              backgroundColor: "transparent",
              borderWidth: 0,
              shadow: false
            },
            plotOptions: {
              column: {
                groupPadding: 0.3,
                pointWidth: 20
              }
            },
            series: chartData
          }}
        />
      </Card>
    </BlockUi>
  );
};
DonutChart.propTypes = {
  title: PropTypes.string.isRequired,
  popupInfoMsg: PropTypes.string,
  categories: PropTypes.array.isRequired,
  chartId: PropTypes.string.isRequired,
  noData: PropTypes.bool,
  chartHeight: PropTypes.number.isRequired,
  tooltipFormatter: PropTypes.func,
  showLegend: PropTypes.bool
};

export default DonutChart;
