import React from "react";
import PropTypes from "prop-types";
import "./Table.scss";

const TableMeta = ({
  label = "",
  searchTerm = "",
  filtersApplied = false,
  resetFilters = () => {},
}) => {
  // render
  return (
    <div className="sg-table-meta d-flex">
      {label ? (
        <React.Fragment>
          Showing results for -<span className="meta-label">{label}</span>
        </React.Fragment>
      ) : null}
      {searchTerm ? (
        <React.Fragment>
          <span className="meta-label">having</span>
          <span className="meta-label">{searchTerm}</span>
        </React.Fragment>
      ) : null}
      {filtersApplied ? (
        <span
          className="meta-label sg-text-info font-weight-bold cursor-pointer meta-reset"
          onClick={() => resetFilters()}
        >
          Reset Filters
        </span>
      ) : null}
    </div>
  );
};

TableMeta.proTypes = {
  label: PropTypes.string,
  searchTerm: PropTypes.string,
  filtersApplied: PropTypes.bool,
  resetFilters: PropTypes.func,
};

export default TableMeta;
