import React, { useState, useEffect } from "react";
import { CardChart } from "src/shared/styleguide/charts/CardChart";
import ChartUtil from "src/utils/ChartUtil";
import CalendarUtil from "src/utils/CalendarUtil";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_OVERALL_PERFORMANCE_REVIEW } from "src/graphql/reviews/results/queries";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";

const OverallReviewPerformance = ({ companyId, filter }) => {
  // state
  const [noData, setNoData] = useState(false);
  const [visibleYAxis, setVisibleYAxis] = useState(0);
  const { loading, data } = useQuery(QUERY_OVERALL_PERFORMANCE_REVIEW, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });
  const [chartConfig, setChartConfig] = useState({
    chart: {
      height: 300,
      events: {
        render() {
          this.series.forEach(series => {
            if (series.legendSymbol) {
              series.legendSymbol.attr({
                width: 10,
                height: 10,
                y: 6,
                x: 6
              });
            }
          });
        }
      }
    },
    xAxis: { categories: [] },
    yAxis: [
      {
        visible: visibleYAxis === 0,
        min: 0,
        title: { text: null }
      },
      {
        visible: visibleYAxis === 1,
        min: 0,
        max: 5,
        startOnTick: false,
        endOnTick: false,
        tickInterval: 1,
        title: { text: null }
      }
    ],
    tooltip: {
      formatter() {
        const { point, series, total, y } = this;
        if (series.type === "column") {
          return `${point.from}${
            point.from !== point.to ? ` to ${point.to}` : ""
          }<br />${series.name.split(" ")[0]}: ${y}
          <br/>Total: ${total}`;
        } else {
          return `${point.from}${
            point.from !== point.to ? ` to ${point.to}` : ""
          }<br />${series.name}: ${y.toFixed(1)}`;
        }
      }
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick() {
            const allow = ChartUtil.shouldDisableSeries(this.chart, this.index);
            if (allow) {
              const visibleAxes = this.chart.series
                .filter(
                  s =>
                    (s.visible && s.index !== this.index) ||
                    (s.index === this.index && !s.visible)
                )
                .map(s => s.index);
              if (visibleAxes.length === 1 && visibleAxes[0] === 2) {
                setVisibleYAxis(1);
              } else {
                setVisibleYAxis(0);
              }
            }
            return allow;
          }
        }
      },
      column: {
        borderWidth: 0,
        maxPointWidth: 30,
        stacking: "normal",
        dataLabels: { enabled: false }
      }
    },
    series: [
      {
        data: [],
        name: "Negative Reviews",
        color: "#dc5700",
        type: "column",
        showInLegend: true,
        legendIndex: 2
      },
      {
        data: [],
        name: "Positive Reviews",
        color: "#5d7ae1",
        type: "column",
        showInLegend: true,
        legendIndex: 1
      },
      {
        data: [],
        name: "Avg. Rating",
        color: "#5cb85c",
        type: "line",
        showInLegend: true,
        legendIndex: 0,
        yAxis: 1
      }
    ]
  });
  useEffect(() => {
    if (data) {
      // init new config
      const newChartConfig = { ...chartConfig };
      newChartConfig.series[0].data = [];
      newChartConfig.series[1].data = [];
      newChartConfig.series[2].data = [];
      newChartConfig.xAxis.categories = [];
      let totalScore = 0;
      if (data && data.overallReviewPerformance) {
        data.overallReviewPerformance.data.forEach(data => {
          newChartConfig.xAxis.categories.push(
            CalendarUtil.toDateStrByFilter(data.from, filter.id)
          );
          newChartConfig.series[0].data.push({
            y: data.negative,
            from: data.from,
            to: data.to
          });
          newChartConfig.series[1].data.push({
            y: data.positive,
            from: data.from,
            to: data.to
          });
          newChartConfig.series[2].data.push({
            y: data.average_score,
            from: data.from,
            to: data.to
          });
          totalScore += Math.abs(data.average_score);
        });
        // handle no data scenario
        if (totalScore === 0) {
          newChartConfig.series[0].visible = true;
          newChartConfig.series[0].showInLegend = true;
          newChartConfig.series[1].visible = true;
          newChartConfig.series[1].showInLegend = true;
          newChartConfig.yAxis[0].visible = false;
          newChartConfig.yAxis[1].visible = true;
          newChartConfig.yAxis[1].min = null;
          newChartConfig.yAxis[1].max = null;
          setNoData(true);
        } else if (noData === true) {
          // reset noData
          newChartConfig.series[0].visible = true;
          newChartConfig.series[0].showInLegend = true;
          newChartConfig.series[1].visible = true;
          newChartConfig.series[1].showInLegend = true;
          newChartConfig.yAxis[0].visible = visibleYAxis === 0;
          newChartConfig.yAxis[1].visible = visibleYAxis === 1;
          newChartConfig.yAxis[1].min = 0;
          newChartConfig.yAxis[1].max = 5;
          setNoData(false);
        }
        setChartConfig(newChartConfig);
      }
    }
  }, [data]);

  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.yAxis[0].visible = visibleYAxis === 0;
    newChartConfig.yAxis[1].visible = visibleYAxis === 1;
    setChartConfig(newChartConfig);
  }, [visibleYAxis]);

  //render
  return (
    <CardChart
      popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
      title="Overall Review Performance"
      loading={loading}
      chartId="overallReviewPerformance"
      cardStyle={{
        height: 360
      }}
      chartConfig={chartConfig}
    />
  );
};
OverallReviewPerformance.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired
};

export default OverallReviewPerformance;
