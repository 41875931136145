import React from "react";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import { QUERY_PUBLISHING_NOTIFICATION } from "src/graphql/settings/reviews/queries";
import { MUTATION_REMINDER_NOTIFICATION_SETTINGS } from "src/graphql/settings/reviews/mutations";
import { showCompanyId } from "src/shared/SessionHelper";
import SettingsCard from "../SettingsCard";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import { addToast } from "src/utils/ToastUtil"

const ReminderNotificationSettings = () => {
  // state
  const formik = useFormik({
    initialValues: {
      noReviewReminderStatus: false,
      noReviewReminderFrequency: 1,
      noReviewReminderPeriod: 1,
      noContactReminderStatus: false,
      noContactReminderFrequency: 1,
    },
    validationSchema: Yup.object().shape({
      noReviewReminderFrequency: Yup.number()
        .typeError("Invalid number")
        .required("Required field")
        .positive("Field must be greater than 0"),
      noReviewReminderPeriod: Yup.number()
        .typeError("Invalid number")
        .required("Required field")
        .positive("Field must be greater than 0"),
      noContactReminderFrequency: Yup.number()
        .typeError("Invalid number")
        .required("Required field")
        .positive("Field must be greater than 0"),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });

  // call apis
  const { loading } = useQuery(QUERY_PUBLISHING_NOTIFICATION, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    variables: {
      companyId: showCompanyId,
    },
    onCompleted(data) {
      populateData(data);
    },
  });
  const [saveSettings] = useMutation(MUTATION_REMINDER_NOTIFICATION_SETTINGS);

  // methods
  const populateData = (data) => {
    if (data && data.getCompanyInformation) {
      const { setting } = data.getCompanyInformation;
      formik.resetForm({
        values: {
          noReviewReminderStatus: setting.noReviewReminderStatus,
          noReviewReminderFrequency: setting.noReviewReminderFrequency,
          noReviewReminderPeriod: setting.noReviewReminderPeriod,
          noContactReminderStatus: setting.noContactReminderStatus,
          noContactReminderFrequency: setting.noContactReminderFrequency,
        },
      });
    }
  };
  const saveChanges = (values) => {
    saveSettings({
      variables: {
        companyId: showCompanyId,
        ...values,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Publishing/Notifications settings updated successfully.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Publishing/Notifications settings not saved.",
        });
      });
  };

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Reminder Notification"
      settingsDescription="Select if and how often you want to receive reminders regarding your reviews."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="37.84"
          viewBox="0 0 37 37.84"
        >
          <g>
            <path
              d="M36.406 14.6a6.786 6.786 0 0 0-9.176-6.374 13.16 13.16 0 0 0-7.361-4.454v-.644a3.128 3.128 0 0 0-6.256 0v.827a13.219 13.219 0 0 0-9.731 12.727v10.427h-.348a3.128 3.128 0 0 0 0 6.256h9.509a4.514 4.514 0 0 0 8.786 0h8.814a3.128 3.128 0 0 0 0-6.256H30.3v-5.742a6.816 6.816 0 0 0 6.106-6.767zm-1.39 0A5.415 5.415 0 1 1 29.6 9.182a5.421 5.421 0 0 1 5.416 5.418zM15 3.128a1.738 1.738 0 1 1 3.475 0v.422A12.432 12.432 0 0 0 15 3.656zm2.436 32.322a3.1 3.1 0 0 1-2.94-2.085h5.88a3.1 3.1 0 0 1-2.94 2.085zm13.207-6.95a1.738 1.738 0 1 1 0 3.475H3.534a1.738 1.738 0 1 1 0-3.475h1.043a.7.7 0 0 0 .695-.7V16.682a11.8 11.8 0 0 1 20.663-7.81 6.8 6.8 0 0 0 2.97 12.5V27.8a.7.7 0 0 0 .7.7zm0 0"
              data-name="Path 2355"
            />
            <path
              d="M262.729 116.348a.694.694 0 0 0 .492-.2l1.86-1.859a.7.7 0 0 0 .2-.492v-2.657a.7.7 0 0 0-1.39 0v2.369l-1.656 1.656a.7.7 0 0 0 .491 1.186zm0 0"
              transform="translate(-234.989 -99.196)"
            />
            <path
              d="M166.331 266.238h-1.39a.7.7 0 1 0 0 1.391h1.39a.7.7 0 0 0 0-1.391zm0 0"
              transform="translate(-147.157 -239.129)"
            />
            <path
              d="M76.55 266.238h-7.183a.7.7 0 0 0 0 1.391h7.183a.7.7 0 0 0 0-1.391zm0 0"
              transform="translate(-61.315 -239.129)"
            />
          </g>
        </svg>
      }
      settingsForm={
        <form onSubmit={formik.handleSubmit}>
          {/* 1. Review(s) posted per day */}
          <div className="d-flex flex-row mt-4 align-items-center text-dark">
            <Form.Group className="mb-0">
              <Form.Check
                custom
                name="noReviewReminderStatus"
                id="noReviewReminderStatus"
                checked={formik.values.noReviewReminderStatus}
                onChange={() => {
                  formik.setFieldValue(
                    "noReviewReminderStatus",
                    !formik.values.noReviewReminderStatus,
                  );
                }}
                type="checkbox"
                label=""
                className="text-dark"
              />
            </Form.Group>
            <div className="d-flex flex-row align-items-center">
              Send me a reminder email every
              <span className="ml-2 text-danger">*</span>
            </div>
            <Form.Group
              style={{ marginLeft: 12, marginRight: 12, width: 80 }}
              className="mb-0"
            >
              <Form.Control
                type="number"
                id="noReviewReminderFrequency"
                name="noReviewReminderFrequency"
                onChange={formik.handleChange}
                value={formik.values.noReviewReminderFrequency}
                className="text-dark"
              />
            </Form.Group>
            day(s) if I have not had a review for
            <Form.Group
              style={{ marginLeft: 12, marginRight: 12, width: 80 }}
              className="mb-0"
            >
              <Form.Control
                type="number"
                id="noReviewReminderPeriod"
                name="noReviewReminderPeriod"
                onChange={formik.handleChange}
                value={formik.values.noReviewReminderPeriod}
                className="text-dark"
              />
            </Form.Group>
            days(s)
          </div>
          <div className="text-danger">
            {formik.errors.noReviewReminderFrequency ? (
              <div>{formik.errors.noReviewReminderFrequency}</div>
            ) : null}
          </div>
          <div className="text-danger">
            {formik.errors.noReviewReminderPeriod ? (
              <div>{formik.errors.noReviewReminderPeriod}</div>
            ) : null}
          </div>

          {/* 1. Review(s) posted per day */}
          <div className="d-flex flex-row mt-4 align-items-center text-dark">
            <Form.Group className="mb-0">
              <Form.Check
                custom
                name="noContactReminderStatus"
                id="noContactReminderStatus"
                checked={formik.values.noContactReminderStatus}
                onChange={() => {
                  formik.setFieldValue(
                    "noContactReminderStatus",
                    !formik.values.noContactReminderStatus,
                  );
                }}
                type="checkbox"
                label=""
                className="text-dark"
              />
            </Form.Group>
            <div className="d-flex flex-row align-items-center">
              Send me a reminder email every
              <span className="ml-2 text-danger">*</span>
            </div>
            <Form.Group
              style={{ marginLeft: 12, marginRight: 12, width: 80 }}
              className="mb-0"
            >
              <Form.Control
                type="number"
                id="noContactReminderFrequency"
                name="noContactReminderFrequency"
                onChange={formik.handleChange}
                value={formik.values.noContactReminderFrequency}
                className="text-dark"
              />
            </Form.Group>
            day(s) if I have unused customer call credits.
          </div>
          <div className="text-danger">
            {formik.errors.noContactReminderFrequency ? (
              <div>{formik.errors.noContactReminderFrequency}</div>
            ) : null}
          </div>

          <div className="mt-3">
            <Button
              type="submit"
              variant="primary"
              className="mr-2"
              disabled={!formik.dirty || !formik.isValid}
            >
              Save Changes
            </Button>
          </div>
        </form>
      }
    />
  );
};

export default ReminderNotificationSettings;
