import React, { useState, useEffect } from "react";
import "./ReviewDetails.scss";
import information from "../../../../../../img/information.svg";
import ChallengeLearnModal from "./ChallengeLearnModal";
import ModalPopup from "../../../../../../shared/modal/ModalPopup";
import { reviewChallengeHelper } from "./review_details_query_actions";
import { createReviewChallengeHelper } from "./review_details_mutation_actions";
import { COMPANY_USER } from "../../../../../../shared/Constants";

import Select from "react-select";
import "react-select/dist/react-select.css";
/**
 * Reviews Details Display Challenge component
 */

/**
 * Open modal popup
 */
const ReviewChallenge = ({
  authHeader,
  companyUserId,
  handleSuccess,
  reviewId,
}) => {
  const [loading, setLoading] = useState(true);
  const [challengeLearnModalModal, setChallengeLearnModal] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [reason, setReason] = useState("other");
  const [communicationBody, setCommunicationBody] = useState("");
  /**
   * Close modal popup
   */
  const updateChallengeLearnModal = () => {
    setChallengeLearnModal(!challengeLearnModalModal);
  };

  const getChallengeData = async () => {
    let data = await reviewChallengeHelper({ reviewId, authHeader });
    if (!data) return null;
    setChallenge(data.data.data.reviewChallenge);
    setLoading(false);
  };

  const handleReasonChange = (reason) => {
    setReason(reason ? reason.value : "");
  };

  const handleBodyChange = (e) => {
    setCommunicationBody(e.target.value);
  };

  const getChallengeReasons = () => {
    return [
      { label: "Other", value: "other" },
      { label: "Not A Customer", value: "not_a_customer" },
      { label: "Inappropriate Content", value: "inappropriate_content" },
    ];
  };

  const handleSubmit = async () => {
    let userType = COMPANY_USER;
    let userId = companyUserId;
    let res = await createReviewChallengeHelper({
      reviewId,
      reason,
      communicationBody,
      userId,
      authHeader,
      userType,
    });
    setChallenge(true);
    handleSuccess("The review challenge request has been created");
  };

  useEffect(() => {
    getChallengeData();
  }, []);

  if (loading) return null;

  const handleBtnState = () => {
    if (communicationBody.length < 1)
      return (
        <button type="submit" className="btn btn-primary disabled-btn">
          Submit Challenge
        </button>
      );

    return (
      <button onClick={handleSubmit} type="submit" className="btn btn-primary">
        Submit Challenge
      </button>
    );
  };

  return (
    <div id="review-details-challenge">
      {!challenge || challenge.status === "denied" ? (
        <div className="row">
          <div className="col-12">
            {challengeLearnModalModal ? (
              <ModalPopup
                isModalOpen={challengeLearnModalModal}
                modalHeader="What is a valid review?"
                modalBody={<ChallengeLearnModal />}
                onModalClose={updateChallengeLearnModal}
              />
            ) : null}
            <div className="ml-3 mr-3 mt-4">
              <div className="row">
                <div className="col-md-6 col-12">
                  <label className="required-field input-label label-style mt-1">
                    Reason for Challenge
                  </label>
                  <br />
                  <Select
                    value={reason}
                    isClearable={false}
                    onChange={(value) => handleReasonChange(value)}
                    options={getChallengeReasons()}
                    isSearchable={false}
                    inputProps={{ readOnly: true }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  <label className="required-field label-style">
                    Additional Notes
                  </label>

                  <textarea
                    rows="3"
                    className="form-control mt-0"
                    name="challenge"
                    value={communicationBody}
                    onChange={handleBodyChange}
                    placeholder="Your reason"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 d-inline-flex align-items-start">
                  <img className="mr-1" alt="information" src={information} />
                  <div className="challenge-information">
                    To challenge this review as invalid, please select your
                    reason for challenging the review, and enter any additional
                    information that might help us with our decision. If we
                    agree with your challenge, the review will be not be
                    published.{" "}
                    <a
                      className="reviews-learn-more"
                      onClick={() => updateChallengeLearnModal()}
                    >
                      Learn
                    </a>{" "}
                    what makes a review invalid.
                  </div>
                </div>
              </div>
              <div className="row form-group mt-4 mb-3">
                <div className="col-12">{handleBtnState()}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 m-3">
            <div className="review-details-challenge-warning">
              This review was recently challenged by your company. We are
              currently investigating this review, and will keep you updated on
              our status.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewChallenge;
