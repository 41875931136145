import React, { useState, useRef, useEffect } from "react";
import closeButton from "src/assets/images/alerts/close_sm_icon.svg";
import Popup from "reactjs-popup";
import "./ReadMoreCustomModal.scss";
import PropTypes from "prop-types";

/**
 * Modal to render the modal contents provided as input.
 * @param {object} - Configuration for the read more modal.
 * @returns -- Modal with contents and read less or read more link.
 */
const ReadMoreCustomModal = ({
  title, // Title for the modal
  position = "left top", // position of side arrow to show with modal.
  triggerOn = "click", // action on which modal will be triggered
  triggerComponent, // element on which modal will be triggered
  modalContents = "", // modal contents
  readLessHeight = "17.667rem", // height to show the minimal contents
  readMoreHeight = "33rem", // height to show the maximum contents
  classNamePrefix = "", // prefix for className. It will help developer to extend the css in their own components.
  closeOnDocumentClick = false, // boolean value to close modal on document click.
  showReadMore = true, // boolean value to display readmore option.
}) => {
  // use states
  const [readMore, setReadMore] = useState(false);
  const ref = useRef();

  /**
   * Closes the modal
   */
  const closeModal = () => {
    setReadMore(false);
    // Hiding popup info.
    const tooltip = document.getElementsByClassName("bs-tooltip-top");
    if (tooltip && tooltip.length > 0) {
      for (let index = 0; index < tooltip.length; index++) {
        tooltip[index].style.display = "none";
      }
    }
    ref.current.close();
  };

  // use effects
  useEffect(() => {
    const divPopupContents = document.getElementById("modalContents");
    if (!readMore && divPopupContents) {
      divPopupContents.scroll({ top: 0, behavior: "smooth" });
    }
  }, [readMore]);

  return (
    <div>
      <Popup
        ref={ref}
        trigger={triggerComponent}
        position={position}
        on={triggerOn}
        className={classNamePrefix}
        onClose={() => setReadMore(false)}
        closeOnDocumentClick={closeOnDocumentClick}
      >
        <div className="popup-header">
          <div className="popup-title">{title}</div>
          <div className="cursor-pointer" onClick={closeModal}>
            <img src={closeButton} alt="Close Button"></img>
          </div>
        </div>
        <div
          id="modalContents"
          style={{
            height: "auto",
            maxHeight: readMore ? readMoreHeight : readLessHeight,
          }}
          className={`content-${readMore ? "long" : "short"}`}
        >
          {modalContents}
        </div>
        {showReadMore && (
          <div
            className="sg-text-info cursor-pointer"
            onClick={() => {
              setReadMore(!readMore);
            }}
          >
            {`Read ${readMore ? "Less" : "More"}`}
          </div>
        )}
      </Popup>
    </div>
  );
};

// Prop types.
ReadMoreCustomModal.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string,
  triggerOn: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  triggerComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  popupContents: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  readLessHeight: PropTypes.string,
  readMoreHeight: PropTypes.string,
  classNamePrefix: PropTypes.string,
  closeOnDocumentClick: PropTypes.bool,
  showReadMore: PropTypes.bool,
};

export default ReadMoreCustomModal;
