import React, { useState, createContext, useEffect } from "react";
import {
  GET_CITIES,
  GET_COMPANY_STATES,
  GET_ZIPCODES,
} from "src/graphql/customers_segments/segment/queries.js";
import isEqual from "lodash.isequal";
import { showCompanyId } from "src/shared/SessionHelper";
import { NO_CACHE } from "src/shared/Constants";
import { locationShaper } from "./segment/SegmentUtils";
import { useApolloClient } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";

const SegmentsContext = createContext(null);

const SegmentsProvider = ({ children }) => {
  const client = useApolloClient();

  const [viewByMenuItems, setViewByMenuItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState({
    value: "all",
    label: "All",
  });
  const [sortBy, setSortBy] = useState({
    label: "Last Modified",
    value: "updated_at",
  });

  const [filterCount, setFilterCount] = useState({
    all: 0,
    archived: 0,
    custom: 0,
    default: 0,
    favorite: 0,
  });

  const [rawStatesData, setRawStatesData] = useState({});
  const [rawCitiesData, setRawCitiesData] = useState({});
  const [rawZipCodesData, setRawZipCodesData] = useState({});
  const [stateData, setStateData] = React.useState([]);
  const [zipCodeData, setZipCodeData] = React.useState([]);
  const [allStates, setAllStates] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [cityData, setCityData] = React.useState([]);
  const [citiesList, setCitiesList] = React.useState([]);

  const resetFilters = () => {
    setSearchTerm("");
    setFilterBy({
      value: "all",
      label: "All",
    });
    setSortBy({
      label: "Last Modified",
      value: "updated_at",
    });
  };

  // Get Location Data
  const getStates = () => {
    const queryVariables = {
      companyId: showCompanyId,
    };
    client
      .query({
        query: GET_COMPANY_STATES,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res.data && res.data.listCompanyStates) {
          // form states  data
          setStateData(locationShaper(res.data.listCompanyStates));
          setStatesList(Object.keys(res.data.listCompanyStates));
          setAllStates(Object.keys(res.data.listCompanyStates));
          // add states data for display reference
          setRawStatesData(res.data.listCompanyStates);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCities = () => {
    const statesArray = !isEqual(statesList, ["any"]) ? statesList : allStates;
    const queryVariables = {
      companyId: showCompanyId,
      states: statesArray,
    };
    client
      .query({
        query: GET_CITIES,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listCities) {
          // form states data
          setCityData(locationShaper(res.data.listCities));
          setCitiesList(Object.keys(res.data.listCities));
          // add states data for display reference
          setRawCitiesData(res.data.listCities);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getZipCodes = () => {
    const citiesDataList = cityData.map((item) => item.value);
    const statesDataList = stateData.map((item) => item.value);
    const citiesArray = !isEqual(citiesList, ["any"])
      ? citiesList
      : citiesDataList.slice(1, citiesDataList.length);

    const statesArray = !isEqual(statesList, ["any"])
      ? statesList
      : statesDataList.slice(1, statesDataList.length);

    const queryVariables = {
      companyId: showCompanyId,
      cities: citiesArray,
      states: statesArray,
    };
    client
      .query({
        query: GET_ZIPCODES,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listZipcodes) {
          // form states  data
          setZipCodeData(locationShaper(res.data.listZipcodes));
          // add states data for display reference
          setRawZipCodesData(res.data.listZipcodes);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getLocationData = () => {
    getStates();
    getCities();
    getZipCodes();
  };

  useEffect(() => {
    getLocationData();
  }, []);

  return (
    <SegmentsContext.Provider
      value={{
        resetFilters,
        viewByMenuItems,
        setViewByMenuItems,
        searchTerm,
        setSearchTerm,
        filterBy,
        setFilterBy,
        sortBy,
        setSortBy,
        filterCount,
        setFilterCount,
        rawCitiesData,
        rawStatesData,
        rawZipCodesData,
        getLocationData,
      }}
    >
      {children}
    </SegmentsContext.Provider>
  );
};

export { SegmentsContext, SegmentsProvider };

export default SegmentsContext;
