import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import Chart from "src/shared/styleguide/charts/Chart";
import ChartUtil from "src/utils/ChartUtil";
import "./Chart.scss";

const DonutChart = ({
  chartData = [],
  chartId,
  drilldownButton = null,
  drilldownCallback = function() {},
  drilldownSeries = [],
  hasDrilldown = false,
  labelFormatter = undefined,
  loading = false,
  noData = false,
  pieSize = 188,
  popupInfoMsg,
  subTitle,
  title,
  tooltipFormatter = undefined,
  ...props
}) => {
  // state
  const chartRef = useRef();
  const [didDrilldown, setDidDrilldown] = useState(false);
  const [chartConfig, setChartConfig] = useState({
    chart: {
      width: pieSize * 2.1,
      height: pieSize,
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0, 25, 0, 0],
      className: "mx-auto",
      type: "pie",
      events: {
        drilldown({ point }) {
          setDidDrilldown(true);
          if (drilldownCallback) {
            drilldownCallback(point);
          }
        },
        drillupall() {
          setDidDrilldown(false);
          if (drilldownCallback) {
            drilldownCallback(null);
          }
        },
      },
    },
    xAxis: {
      categories: [],
      minPadding: 0,
      maxPadding: 0,
    },
    credits: { enabled: false },
    yAxis: {
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      title: {
        text: null,
      },
    },
    tooltip: {
      outside: true,
      followPointer: true,
      formatter: tooltipFormatter,
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "middle",
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false,
      itemMarginBottom: 8,
      x: window.innerWidth <= 1200 ? pieSize * 1 : pieSize * 1.1,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolRadius: 8,
      labelFormatter() {
        return noData === true ? `${this.name} (0)` : labelFormatter(this);
      },
    },
    plotOptions: {
      pie: {
        center: ["25%", "50%"],
        cursor: "pointer",
        borderColor: null,
        size: pieSize * 0.8,
        innerSize: pieSize * 0.57,
        showInLegend: true,
        allowPointSelect: false,
        dataLabels: { enabled: false },
        states: {
          hover: {
            halo: null,
          },
        },
      },
    },
    series: chartData,
    drilldown: {
      series: drilldownSeries,
    },
  });

  // methods
  const drillup = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.chart.drillUp();
    }
  };

  // effects
  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.legend.labelFormatter = function() {
      return noData === true ? `${this.name} (0)` : labelFormatter(this);
    };
    setChartConfig(newChartConfig);
  }, [labelFormatter]);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const newChartConfig = { ...chartConfig };
      newChartConfig.plotOptions.pie.point = {
        events: {
          legendItemClick() {
            return ChartUtil.shouldDisableSeries(
              chartRef.current.chart,
              this.index,
              -4,
              "pie",
            );
          },
        },
      };
      setChartConfig(newChartConfig);
    }
  }, [chartRef]);

  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    if (noData) {
      newChartConfig.series[0].data = newChartConfig.series[0].data.map(
        (row) => ({
          ...row,
          y: 100 / newChartConfig.series[0].data.length,
        }),
      );
    }
    setChartConfig(newChartConfig);
  }, [noData]);

  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.series = chartData;
    setChartConfig(newChartConfig);
  }, [chartData]);

  useEffect(() => {
    if (drilldownSeries && drilldownSeries.length > 0) {
      const newChartConfig = { ...chartConfig };
      newChartConfig.drilldown.series = drilldownSeries;
      setChartConfig(newChartConfig);
    }
  }, [drilldownSeries]);

  useEffect(() => {
    const newChartConfig = { ...chartConfig };
    newChartConfig.tooltip.formatter = tooltipFormatter;
    setChartConfig(newChartConfig);
  }, [tooltipFormatter]);

  // render
  return (
    <BlockUi
      className="card-chart"
      blocking={loading}
      style={{
        height: "100%",
      }}
    >
      <div
        className="position-relative"
        style={{
          height: "100%",
        }}
      >
        <Card
          padding={0}
          popupInfoMsg={popupInfoMsg}
          title={<div className="pl-4 pt-4 pb-1 d-inline-block">{title}</div>}
          subTitle={
            <div className="pl-4 pt-1 pb-2 sg-card-subtitle d-inline-block">
              {subTitle}
            </div>
          }
          cardStyle={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          cardBodyStyle={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
          }}
          {...props}
        >
          <BlockUi
            loader={<React.Fragment />}
            blocking={noData}
            title={noData ? "No data" : ""}
          >
            <Chart ref={chartRef} chartId={chartId} chartConfig={chartConfig} />
          </BlockUi>
        </Card>
        {hasDrilldown && didDrilldown ? (
          <div
            onClick={drillup}
            className="position-absolute text-right"
            style={{
              bottom: 8,
              right: 8,
            }}
          >
            {drilldownButton}
          </div>
        ) : null}
      </div>
    </BlockUi>
  );
};
DonutChart.propTypes = {
  popupInfoMsg: PropTypes.string,
  title: PropTypes.string.isRequired,
  chartId: PropTypes.string.isRequired,
  labelFormatter: PropTypes.func,
  tooltipFormatter: PropTypes.func.isRequired,
  noData: PropTypes.bool,
  loading: PropTypes.bool,
  hasDrilldown: PropTypes.bool,
  drilldownSeries: PropTypes.array,
  drilldownButton: PropTypes.node,
};

export default DonutChart;
