export default class UrlUtil {
  /**
   * Returns a URL with all the bits joined
   * @param  {array} urlBits - array of string containing URL bits
   * @returns {string}
   */
  static joinUrl(...urlBits) {
    return urlBits.map(bit => (bit ? bit.replace(/\/$/, "") : bit)).join("/");
  }
}
