import React, { useState, useEffect } from "react";
import DonutChart from "src/shared/styleguide/charts/DonutChart";
import PropTypes from "prop-types";
import Dropdown, {
  DropdownButton
} from "src/shared/styleguide/dropdown/Dropdown";
import { QUERY_AVERAGE_RATING_BY_LOCATION } from "src/graphql/reviews/results/queries";
import { useQuery } from "@apollo/react-hooks";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";

export const DRILLDOWN_OPTIONS = [
  { id: 0, label: "Top 5 Locations", value: "top" },
  { id: 1, label: "Bottom 5 Locations", value: "bottom" }
];
const chartColors = ["#4970d7", "#dc5700", "#a3cbf5", "#faa600", "#d7f034"];

const AverageRatingByLocation = ({ companyId, filter, toDate, fromDate }) => {
  // state
  const [noData, setNoData] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [drilldownOption, setDrilldownOption] = useState(DRILLDOWN_OPTIONS[0]);
  const [averageRatingByLocation, setAverageRatingByLocation] = useState({
    data: [
      {
        name: "Locations",
        data: []
      }
    ],
    drilldownSeries: []
  });

  // call apis
  const { loading, data } = useQuery(QUERY_AVERAGE_RATING_BY_LOCATION, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket,
      filter: drilldownOption.value
    }
  });

  // effects
  useEffect(() => {
    if (data && data.averageRatingByLocation) {
      // update series
      const newAverageRatingByLocation = { ...averageRatingByLocation };

      newAverageRatingByLocation.data[0].data = data.averageRatingByLocation.map(
        (location, index) => ({
          y: location.ratings.reduce((acc, val) => acc + val.count, 0),
          average_rating: location.average_rating,
          total: location.total_rating_count,
          name: location.city_name,
          drilldown: index.toString(),
          color: chartColors[index]
        })
      );

      // no data
      if (data.averageRatingByLocation.length === 0) {
        setNoData(true);
        newAverageRatingByLocation.data[0].data = [1, 2, 3, 4, 5].map(
          (val, index) => ({
            y: 100 / 5,
            average_rating: 100 / 5,
            total: 100,
            name: "No data",
            drilldown: "",
            color: chartColors[index]
          })
        );
      } else {
        const total = newAverageRatingByLocation.data[0].data.reduce(
          (acc, val) => acc + val.y,
          0
        );
        if (total === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      }

      // update drilldown
      newAverageRatingByLocation.drilldownSeries = data.averageRatingByLocation.map(
        (location, index) => ({
          name: location.city_name,
          id: index.toString(),
          data: location.ratings
            .sort((a, b) => a.rating > b.rating)
            .map(rating => ({
              y: rating.count,
              name: rating.rating + " Star",
              color: chartColors[rating.rating - 1]
            }))
        })
      );
      setAverageRatingByLocation(newAverageRatingByLocation);
    }
  }, [data]);

  // render
  return (
    <DonutChart
      loading={loading}
      chartId="averageRatingByLocation"
      title="Average Rating by Location"
      noData={noData}
      tooltipFormatter={function() {
        const { point } = this;
        const total = point.total || 1;
        const dateStr = `${fromDate ? fromDate : ""}${
          toDate && fromDate !== toDate ? ` to ${toDate}` : ""
        }`;
        return point.drilldown
          ? `${dateStr}
        <br />${point.name} Avg. Rating: ${point.average_rating}
        <br />Ratings: ${point.y}/${total} (${Math.floor(
              (100 * point.y) / total
            )}%)`
          : `${dateStr}
        <br />${point.name} Rating: ${point.y}`;
      }}
      labelFormatter={ctx =>
        `${ctx.name} (${ctx.drilldown ? ctx.average_rating.toFixed(1) : ctx.y})`
      }
      subTitle={
        selectedCategory ? (
          <span className="pl-0 pt-1 font-weight-bold">
            Selected location - {selectedCategory}
          </span>
        ) : (
          <DropdownButton
            dropShadow
            title={drilldownOption.label}
            dropdownClassName="d-inline-block ml-n3"
            buttonClassName="text-dark font-weight-bold text-decoration-none"
            variant="link"
          >
            {DRILLDOWN_OPTIONS.map(option => (
              <Dropdown.Item
                key={option.id}
                onClick={() => setDrilldownOption(option)}
                href="#"
                active={option.id === drilldownOption.id}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )
      }
      popupInfoMsg="This shows the average review rating by location, and the locations where the rating scores are highest and lowest."
      chartData={averageRatingByLocation.data}
      hasDrilldown={true}
      drilldownButton={
        <span
          className="cursor-pointer"
          style={{
            color: "#428bca",
            fontSize: "0.9rem"
          }}
        >
          <span className="fa fa-chevron-left font-weight-light small" /> Back
          to Locations
        </span>
      }
      drilldownSeries={averageRatingByLocation.drilldownSeries}
      drilldownCallback={point => {
        setSelectedCategory(
          point ? `${point.name} (${point.average_rating.toFixed(1)})` : null
        );
      }}
      pieSize={188}
    />
  );
};

AverageRatingByLocation.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  toDate: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired
};

export default AverageRatingByLocation;
