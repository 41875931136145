import { createSlice } from "@reduxjs/toolkit";
// variables
const initialFilters = {
  selectedViewBy: { value: "all", labelString: "All" },
  sort: { value: "target_mail_date", direction: "desc" },
};

const initialFormFilter = {
  commercial: false,
  daysSinceLastTxn: {
    min: "",
    max: "",
  },
  followupStatus: [],
  inServiceContact: false,
  lastTxnDate: {
    min: "",
    max: "",
  },
  notProspects: false,
  onlyTransactions: false,
  searchTerm: {
    name: "",
    zip: "",
  },
  status: [],
  txnAmount: {
    min: "",
    max: "",
  },
};

const initialState = {
  chartsLoading: true,
  campaign: null,
  filters: initialFilters,
  pageHeader: "",
  showDashboard: false,
  iterationMetadata: null,
  formFilter: initialFormFilter,
  cardControlIndex: 0,
};

export const individualResultSlice = createSlice({
  name: "prospect",
  initialState,
  reducers: {
    setChartsLoading: (state, action) => {
      state.chartsLoading = action.payload;
    },
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPageHeader: (state, action) => {
      state.pageHeader = action.payload;
    },
    setShowDashboard: (state, action) => {
      state.showDashboard = action.payload;
    },
    setIterationMetadata: (state, action) => {
      state.iterationMetadata = action.payload;
    },
    setFormFilter: (state, action) => {
      state.formFilter = action.payload;
    },
    setCardControlIndex: (state, action) => {
      state.cardControlIndex = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialFilters;
      state.formFilter = initialFormFilter;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCardControlIndex,
  setFormFilter,
  setIterationMetadata,
  setShowDashboard,
  setPageHeader,
  setFilters,
  setCampaign,
  setDataFetched,
  setChartsLoading,
  resetFilters,
} = individualResultSlice.actions;

export default individualResultSlice.reducer;
