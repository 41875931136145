import { REVIEW_INVITATION_INDEX } from "./queries";
import { API_GRAPHQL_URL } from "src/shared/Config";
import axios from "axios";

export const invitationIndexHelper = async ({
  companyId,
  source,
  dateRange,
  page,
  filter,
  authHeader,
  direction,
  column,
  searchTerm,
}) => {
  const queryParams = {
    query: REVIEW_INVITATION_INDEX,
    variables: {
      companyId,
      dateFrom: dateRange.value,
      dateBucket: dateRange.bucket,
      page,
      source,
      filter,
      direction,
      column,
      searchTerm,
    },
  };
  try {
    const data = await axios.post(API_GRAPHQL_URL, queryParams, authHeader);
    return data;
  } catch (e) {
    return null;
  }
};
