import React from "react";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import WordpressWidgetBody from "src/components/settings/widgets/wordpress_widgets/WordpressWidgetBody";
import { addToast } from "src/utils/ToastUtil"
import { featureFlags } from "src/shared/SessionHelper";

const WordpressWidget = ({ widgetSettings }) => {
  // methods
  const handleToast = () => {
    addToast({
      message: "Widget copied to clipboard!",
      type: "success",
    });
  };

  // feature flag check
  if ((!featureFlags.reviewWrite)) {
    return null;
  }

  return (
    <SettingsCard
      loading={false}
      settingsName="WordPress Plugin"
      settingsDescription="This reviews widget works only with websites built in WordPress."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <path
            id="prefix__social-media"
            d="M18 0a18 18 0 1 0 18 18A18.02 18.02 0 0 0 18 0zM1.815 18a16.053 16.053 0 0 1 1.4-6.583l7.722 21.15A16.191 16.191 0 0 1 1.815 18zM18 34.185a16.083 16.083 0 0 1-4.572-.661l4.855-14.109 4.973 13.631a2.434 2.434 0 0 0 .115.225 16.206 16.206 0 0 1-5.371.914zm2.227-23.767c.972-.051 1.85-.151 1.85-.151a.67.67 0 0 0-.1-1.337s-2.62.2-4.311.2c-1.59 0-4.261-.2-4.261-.2a.67.67 0 0 0-.1 1.337s.826.1 1.7.151l2.518 6.909-3.546 10.617-5.892-17.528c.974-.051 1.851-.152 1.851-.152a.67.67 0 0 0-.1-1.336s-2.62.2-4.311.2c-.3 0-.661-.007-1.045-.021A16.187 16.187 0 0 1 28.93 6.06a2 2 0 0 0-.21-.013 2.8 2.8 0 0 0-2.715 2.874c0 1.335.77 2.46 1.59 3.8a8.5 8.5 0 0 1 1.337 4.464 19.416 19.416 0 0 1-1.232 5.23l-1.613 5.392zm11.973-.183a16.187 16.187 0 0 1-6.067 21.753l4.942-14.292a15.323 15.323 0 0 0 1.235-5.8 11.986 11.986 0 0 0-.108-1.661z"
            style={{ fill: "#7e7e7e" }}
          />
        </svg>
      }
      settingsForm={
        <WordpressWidgetBody
          widgetSettings={widgetSettings}
          handleToast={handleToast}
        />
      }
    />
  );
};

export default WordpressWidget;
