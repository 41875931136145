import React from "react";
import "./ErrorMessage.scss";
import errorCircle from "../../../../../../styles/assets/error_circle.png";

const ErrorToast = ({ display, message }) => {
  return (
    <div className="edit-email-error" style={{ display }}>
      <div className="edit-error-inner-container">
        <img alt="Error" className="error-circle-img" src={errorCircle} />
        <div className="edit-email-error-toast">{message}</div>
      </div>
    </div>
  );
};

export default ErrorToast;
