import React, { useState, useEffect } from "react";
import "./CustomerReviews.scss";
import TextingAccordion from "src/shared/styleguide/accordions/TextingAccordion";
import { useQuery } from "@apollo/react-hooks";
import { CONTACT_REVIEW_SUMMARY } from "src/graphql/texting/queries";
import { CACHE_AND_NETWORK } from "src/shared/Constants";

import PropTypes from "prop-types";

const ReviewStrip = ({
  countLeft,
  countRight,
  textLeft,
  textRight,
  dividerPosition = null,
}) => {
  const leftContent = textLeft.split(" ");
  const rightContent = textRight.split(" ");

  return (
    <div className={`d-flex justify-content-between ${dividerPosition}`}>
      <div className="d-flex flex-column review-inner-container">
        <div className="review-count">{countLeft}</div>
        <div className="review-info">{leftContent[0]}</div>
        <div className="review-info">{leftContent[1]}</div>
      </div>
      <div className="reviews-divider" />
      <div className="d-flex flex-column review-inner-container">
        <div className="review-count">{countRight}</div>
        <div className="review-info">{rightContent[0]}</div>
        <div className="review-info">{rightContent[1]}</div>
      </div>
    </div>
  );
};

const CustomerReviews = ({ contactId }) => {
  // state
  const [contactReviewSummary, setContactReviewSummary] = useState({
    averageRating: 0,
    reviewsCount: 0,
    sentimentScore: 0,
    unhappyFeedbackCount: 0,
  });

  // functions
  const res = useQuery(CONTACT_REVIEW_SUMMARY, {
    variables: { contactId },
    fetchPolicy: CACHE_AND_NETWORK,
  });

  useEffect(() => {
    if (!res.loading && res.data && res.data.contactReviewSummary) {
      setContactReviewSummary(res.data.contactReviewSummary);
    }
  }, [res]);

  const handleAvgScore = () => {
    if (contactReviewSummary.averageRating == null) return 0;
    let roundedNumber =
      Math.round(contactReviewSummary.averageRating * 10) / 10;
    return roundedNumber % 1 === 0 ? `${roundedNumber}.0` : roundedNumber;
  };

  // render
  return (
    <div className="customer-reviews-container">
      <TextingAccordion
        label="Reviews"
        eventKey="1"
        isDefaultActiveKey={false}
        labelStyles={{
          fontSize: "1.2rem",
          color: "#313541",
        }}
      >
        {contactReviewSummary &&
        (contactReviewSummary.reviewsCount > 0 ||
          contactReviewSummary.unhappyFeedbackCount > 0) ? (
          <React.Fragment>
            <ReviewStrip
              countLeft={handleAvgScore()}
              countRight={`${
                contactReviewSummary.sentimentScore == null
                  ? 0
                  : contactReviewSummary.sentimentScore
              }%`}
              textLeft="Average Rating"
              textRight="Sentiment Score"
              dividerPosition="align-items-end"
            />
            <div className="texting-accordion-line" />
            <ReviewStrip
              countLeft={contactReviewSummary.reviewsCount}
              countRight={contactReviewSummary.unhappyFeedbackCount}
              textLeft="Reviews Submitted"
              textRight="Unhappy Feedback"
            />
          </React.Fragment>
        ) : (
          <div className="no-reviews-text">No reviews submitted</div>
        )}
      </TextingAccordion>
    </div>
  );
};

CustomerReviews.propTypes = {
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomerReviews;
