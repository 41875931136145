import React from 'react';
import Modal from 'react-bootstrap4-modal';

export default class ModalPopup extends React.Component {
    /**
     * Handle Modal Close action
     */
    onCloseModal() {
        this.props.onModalClose();
    }

    render() {
        return (
            <div>
                {this.props.isModalOpen ?
                    <Modal id="modalView" visible={this.props.isModalOpen} onClickBackdrop={this.onCloseModal.bind(this)}>
                        <div className="modal-header">
                            <h5 data-selenium-id="modal-title"
                                 data-selenium-name="modal-title" className="modal-title"> {this.props.modalHeader}
                                <button className="close" onClick={this.onCloseModal.bind(this)}>
                                    <i  data-selenium-id="modal-close-btn" data-selenium-name="modal-close-btn" className="fa fa-times-thin"></i>
                                </button>
                            </h5>
                        </div>
                        <div className="modal-body">
                            {this.props.modalBody}
                        </div>
                    </Modal>
                    : ''}</div>
        )
    }
}