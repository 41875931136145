export const REVIEW_OVERVIEW_DATA = [
  {
    days: 30,
    positiveCounts: {
      currentPeriod: "0",
      reviewDirection: "",
      previousPeriod: "0",
      trends: []
    },
    negativeCounts: {
      currentPeriod: "0",
      reviewDirection: "",
      previousPeriod: "0",
      trends: []
    },
    pendingCounts: {
      reviewCount: "0",
      reviewPercentage: "0"
    }
  },
  {
    days: 7,
    positiveCounts: {
      currentPeriod: "0",
      reviewDirection: "",
      previousPeriod: "0",
      trends: []
    },
    negativeCounts: {
      currentPeriod: "0",
      reviewDirection: "",
      previousPeriod: "0",
      trends: []
    },
    pendingCounts: {
      reviewCount: "0",
      reviewPercentage: "0"
    }
  }
];
