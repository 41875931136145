import React, { useEffect, useState } from "react";
import { PLATFORM, FILTER_BY } from "../../../Data";
import { REVIEW_DATA } from "./reviewListData";
import { query } from "src/shared/GraphQLHandler";
import { REVIEWS_LIST } from "../graphql-queries/queries";
import ModalPopupRight from "src/shared/modal/ModalPopupRight";
import ReviewDetails from "./ReviewDetails";
import NoDataFoundComponent from "src/shared/no-data/NoDataFoundComponent";
import { NO_RECORD_FOUND } from "src/shared/Constants";
import { resetPagination } from "src/shared/Utils";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import ManageTileComponent from "src/components/reviews/manage_reviews/tabs/ManageTab/review-list/ManageTileComponent.jsx";
import {
  showCompanyId,
  showAuthHeader,
  showUserId,
} from "src/shared/SessionHelper";
import TableMeta from "src/shared/styleguide/table/TableMeta";
import "./ReviewListComponent.scss";

const PAGE_SIZE = 5;

const ReviewListComponent = ({
  props,
  filters,
  setFilters,
  refreshPage,
  setReset,
  defaultFilters,
}) => {
  // variables
  let companyId = showCompanyId;
  let companyUserId = showUserId;
  let authHeader = showAuthHeader;
  let refreshParent = false;

  // state
  const [filter, updateFilter] = useState(filters);
  let [filteredData, setFilteredData] = useState();
  let [pageSize, setPageSize] = useState();
  let [total, setTotal] = useState();
  const [selectedReview, setSelectedReview] = useState(REVIEW_DATA);
  const [reviewDetailsDisplay, setReviewDetailsDisplay] = useState(false);

  // methods
  const resetFilters = () => {
    setFilters({
      ...filters,
      searchTerm: "",
    });
    setReset(Date.now());
  };

  const areFiltersApplied = () => {
    return (
      (filters && filters.searchTerm) ||
      Object.keys(defaultFilters).filter(
        (key) => filters[key].value !== defaultFilters[key].value,
      ).length > 0
    );
  };

  const getReviewListData = () => {
    return REVIEW_DATA;
  };

  const updateReviewDetailsDisplay = (review) => {
    if (refreshParent) {
      let filter = {
        ...filters,
      };
      setFilters(filter);
      refreshPage(filters);
    }
    setSelectedReview(review);
    setReviewDetailsDisplay(!reviewDetailsDisplay);
  };

  const updateParent = () => {
    refreshParent = true;
  };

  /**
   * method invoked to apply date filter
   * @param {*} lastDays last days value
   */
  const getDateFilteredData = (lastDays) => {
    let currentDate = new Date();
    let dateFilteredData = reviewData.filter((review) => {
      let date = new Date(review.review_date);
      return (
        new Date(currentDate.getTime() - lastDays * 24 * 60 * 60 * 1000) <
        new Date(date.getTime() - 24 * 60 * 60 * 1000)
      );
    });
    setReviewData(dateFilteredData);
  };

  /**
   * method invoked to apply platform filter
   */
  const getPlatformFilterData = () => {
    if (filters.platform.label !== PLATFORM[0].label) {
      const platformFilter = reviewData.filter((review) => {
        return review.platform === filters.platform.label;
      });
      setReviewData(platformFilter);
    } else {
      setReviewData(reviewData);
    }
  };

  /**
   * method invoked to apply filter by filter
   */
  const getFilterByFilteredData = () => {
    if (filters.filterBy.label !== FILTER_BY[0].label) {
      const filterByFilter = reviewData.filter((review) => {
        return review.review_type === filters.filterBy.label;
      });
      setReviewData(filterByFilter);
    } else {
      setReviewData(reviewData);
    }
  };

  /**
   * method invoked to set page count
   */
  let [reviewData, setReviewData] = useState([]);
  let [pageCount, setPageCount] = useState(total / PAGE_SIZE);
  let [page, setPage] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);

  /**
   * Method invoked on pagination change
   * @param {*} event event
   */
  const onPagination = (event) => {
    let startCount = event.selected === 0 ? 0 : event.selected * PAGE_SIZE;
    let endCount = startCount + PAGE_SIZE;
    reviewsList(event.selected + 1);
  };

  const reviewsList = (currentPage) => {
    let variables = {
      companyId: parseInt(companyId),
      dateFrom: filters.dateRange.value,
      dateBucket: filters.dateRange.bucket,
      disposition: filters.viewBy.value,
      platform: filters.platform.value,
      source: filters.filterBy.value,
      sortBy: filters.sortBy.value,
      page: currentPage,
      limit: PAGE_SIZE,
      searchTerm: filters.searchTerm,
    };
    query(
      props.client,
      REVIEWS_LIST,
      function(error, response) {
        if (response && response.data && response.data.reviewsList) {
          setReviewData(response.data.reviewsList.reviews);
          setPageSize(response.data.reviewsList.rpp);
          setPageCount(response.data.reviewsList.total / PAGE_SIZE);
          setTotal(response.data.reviewsList.total);
          setCurrentPage(currentPage - 1);
          resetPagination(currentPage - 1);
          window.scrollTo(0, 0);
        } else {
          console.log("Error in getting filters list: ", error);
        }
      },
      variables,
    );
  };

  // effects
  useEffect(() => {
    reviewsList(1);
  }, [filters]);

  // render
  return (
    <div id="review-list" className="row">
      {reviewData && reviewData.length === 0 ? (
        <div className="col-12 text-center">
          <NoDataFoundComponent
            heading={NO_RECORD_FOUND.HEADING}
            message={NO_RECORD_FOUND.MESSAGE}
          />
        </div>
      ) : (
        <div className="col-12 pr-0">
          {reviewDetailsDisplay ? (
            <ModalPopupRight
              isModalOpen={reviewDetailsDisplay}
              modalClose={updateReviewDetailsDisplay}
              modalHeader="Review Details"
              modalBody={
                <ReviewDetails
                  reviewData={selectedReview}
                  companyUserId={companyUserId}
                  authHeader={authHeader}
                  refreshHandler={updateParent}
                />
              }
            />
          ) : null}
          <div className="row">
            <div className="col-12 mt-4">
              <TableMeta
                label={filters.dateRange.label}
                searchTerm={filters && filters.searchTerm}
                filtersApplied={areFiltersApplied()}
                resetFilters={resetFilters}
              />
            </div>
          </div>
          <ManageTileComponent
            searchTerm={filters.searchTerm}
            clickEvent={updateReviewDetailsDisplay}
            reviewData={reviewData}
          />
          {total > PAGE_SIZE ? (
            <PaginatorComponent
              pageCount={pageCount}
              pageChangeHandler={onPagination}
              currentPage={currentPage}
            />
          ) : (
            <div className="p-3"></div>
          )}
        </div>
      )}
    </div>
  );
};
export default ReviewListComponent;
