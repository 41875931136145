const maintainStateObj = ({data, path = '', key }) => {
  if (path === 'add') return data;
  const oldState = JSON.parse(sessionStorage.getItem(key))
  const changeState = data.email === ''
  if (changeState && oldState) {
    return {...data, ...oldState}
  }

  sessionStorage.setItem(key, JSON.stringify(data, null, 2))
  return data
}

const maintainStateStr = ({data, path = '', key}) => {
  if (path === 'add') return null;
  const oldState = sessionStorage.getItem(key)
  let res = ''

  if (oldState && !data) return oldState

  sessionStorage.setItem(key, data)
  return data
}

export {
  maintainStateObj,
  maintainStateStr,
}
