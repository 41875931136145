import React from "react";
import Form from "src/shared/styleguide/form/Form";
import Button, { ButtonGroup } from "src/shared/styleguide/button/Button";
import "src/components/settings/widgets/review_widgets/ReviewWidgetForm.scss";
import InformationSvg from "src/img/information.svg";
import ColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";

const ReviewWidgetForm = ({
  formik,
  buttonDisabled,
  okayToUpdate,
  currWidgetColor,
  clipboardHelper,
  setOkayToUpdate,
}) => {
  // methods
  const isNumber = (val) => {
    var reg = new RegExp("^[0-9]+$");
    return reg.test(val);
  };

  const handleColorChange = (e) => {
    formik.setFieldValue("widgetColor", e.target.value, false);
  };

  const handleColorPickerChange = (colors) => {
    if (okayToUpdate) {
      setTimeout(() => {
        setOkayToUpdate(true);
      }, 500);
    }

    if (okayToUpdate) {
      setOkayToUpdate(false);
    }
    formik.setFieldValue("widgetColor", colors.color, false);
  };

  const handleReviewCount = (e) => {
    const { value } = e.target;
    if (!value || isNumber(value)) {
      formik.setFieldValue("reviewCount", e.target.value, false);
    }
  };

  const handleWidgetWidth = (e) => {
    const { value } = e.target;
    if (!value || isNumber(value)) {
      formik.setFieldValue("widgetWidth", e.target.value, false);
    }
  };

  // render
  return (
    <div className="review-widget-form-container">
      <Form.Group
        className="d-flex widget-input-container align-items-center justify-between"
        controlId="widgetWidth"
      >
        <Form.Label
          style={{ paddingBottom: "27px" }}
          className="label  text-dark"
        >
          Widget Width
        </Form.Label>
        <div className="input-container">
          <Form.Control
            className="widget-input"
            value={formik.values.widgetWidth}
            onChange={handleWidgetWidth}
          />
          <div
            style={{ marginTop: "6px" }}
            className="d-flex align-items-center"
          >
            <img
              style={{ marginRight: "7px", height: "16px", width: "16px" }}
              src={InformationSvg}
              alt="information"
            />
            <div className="info-text">Minimum width 215</div>
          </div>
        </div>
      </Form.Group>
      <Form.Group
        className="d-flex widget-input-container align-items-center justify-between"
        controlId="reviewCount"
      >
        <Form.Label className="label  text-dark">Review Count</Form.Label>
        <Form.Control
          className="widget-input"
          value={formik.values.reviewCount}
          onChange={handleReviewCount}
        />
      </Form.Group>
      <Form.Group
        style={{ position: "relative" }}
        className="d-flex widget-input-container align-items-center justify-between"
        controlId="widgetColor"
      >
        <Form.Label className="label  text-dark">Widget Color</Form.Label>
        <div className="color-input-container">
          <div className="color-input">
            <ColorPicker
              color={formik.values.widgetColor}
              onChange={handleColorPickerChange}
              style={{ border: "none" }}
              placement="bottomLeft"
            >
              <span
                style={{ border: "none" }}
                className="rc-color-picker-trigger"
              ></span>
            </ColorPicker>
          </div>
          <Form.Control
            className="widget-input"
            style={{ paddingLeft: "54px", backgroundColor: "white" }}
            onChange={handleColorChange}
            value={formik.values.widgetColor}
          />
        </div>
        <input
          type="color"
          className="hidden"
          onChange={handleColorChange}
          value={formik.values.widgetColor}
        />
      </Form.Group>
      <ButtonGroup style={{ marginTop: "40px" }}>
        <Button
          variant="primary"
          className="save-btn"
          onClick={formik.handleSubmit}
          disabled={buttonDisabled()}
        >
          Save Changes
        </Button>
        <Button
          className="copy-btn"
          variant="outline-secondary"
          onClick={clipboardHelper}
        >
          Copy Code
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ReviewWidgetForm;
