import React, { useState, useEffect } from "react";
import TextingAccordion from "src/shared/styleguide/accordions/TextingAccordion";
import { useQuery } from "@apollo/react-hooks";
import { GET_ES_CONTACT } from "src/graphql/texting/queries";
import { CACHE_AND_NETWORK } from "src/shared/Constants";
import PropTypes from "prop-types";
import NumberUtil from "src/utils/NumberUtil";
import "./CustomerTransactions.scss";

const CustomerTransactions = ({ contactId }) => {
  const [contact, setContact] = useState({
    allTransactionsCount: 0,
    allTransactionsAmount: 0,
    formattedLastServiceDate: "",
  });

  const res = useQuery(GET_ES_CONTACT, {
    skip: !contactId,
    variables: { contactId, esContact: true },
    fetchPolicy: CACHE_AND_NETWORK,
    onError: (e) => {
      console.error("GET ES CONTACT ERROR: ", e);
    },
  });

  useEffect(() => {
    if (!res.loading && res.data && res.data.contact) {
      setContact(res.data.contact);
    }
  }, [res]);

  return (
    <div className="customer-transactions-container">
      <TextingAccordion
        label="Transactions"
        eventKey="1"
        isDefaultActiveKey={false}
        labelStyles={{
          fontSize: "1.2rem",
          color: "#313541",
        }}
      >
        {!contact.allTransactionsCount || contact.allTransactionsCount <= 0 ? (
          <div className="no-transactions-text">No transactions yet</div>
        ) : (
          <React.Fragment>
            <div className="transaction-box d-flex">
              <div className="d-flex flex-column number-of-transactions-container">
                <div className="transactions-txt-bold">
                  {NumberUtil.toHumanReadableNumber(
                    contact.allTransactionsCount,
                  )}
                </div>
                <div className="transactions-txt number-of-transactions">
                  Number of Transactions
                </div>
              </div>
              <div className="vertical-divider" />
              <div className="d-flex overflow-hidden flex-column justify-content-center transaction-section">
                <div
                  title={NumberUtil.toUSD(contact.allTransactionsAmount)}
                  className="transactions-txt-bold"
                >
                  {NumberUtil.toUSD(contact.allTransactionsAmount)}
                </div>
                <div className="transactions-txt">Total Spend</div>
              </div>
            </div>
            {contact.formattedLastServiceDate ? (
              <React.Fragment>
                <div className="texting-accordion-line" />
                <div className="date-txt">
                  Last Visit Date:{" "}
                  <span className="font-weight-bold last-service-date">
                    {contact.formattedLastServiceDate}
                  </span>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </TextingAccordion>
    </div>
  );
};

CustomerTransactions.propTypes = {
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomerTransactions;
