import { API_GRAPHQL_URL } from '../../../../shared/Config'
import axios from 'axios'
import { showAuthHeader } from '../../../../shared/SessionHelper'

const GET_REVIEW_STATUSES = `
  query getReviewStatuses($companyId: ID!) {
    getReviewStatuses(companyId: $companyId) {
      reviewsPage
      smartInvite
      autoInvite
      directConnect
      googleAuthentication
    }
  }
`

export const getReviewStatusesHelper = async ({ companyId }) => {
  const queryParams = {
    query: GET_REVIEW_STATUSES,
    variables: {
      companyId,
    }
  }
  try {
    return await axios.post(API_GRAPHQL_URL, queryParams, showAuthHeader)
  } catch(e) {
   return null
 }
}
