import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import Select from "react-select";
import ReadMoreElements from "src/shared/styleguide/common/ReadMoreElements.jsx";
import { addToast } from "src/utils/ToastUtil";
import "./Form.scss";

// This component can be imported from here or from Form component
const MultiSelectFilter = ({
  allPagination = true,
  defaultAll = true,
  disabled,
  inputProps,
  name,
  onChange,
  options,
  pageLength = 12,
  placeholder,
  placement = "right-start",
  readMoreContent,
  value,
  zIndex,
  ...props
}) => {
  // Vars
  const PAGE_LENGTH = pageLength;

  // State
  const [enterStrike, setEnterStrike] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [invalidEntries, setInvalidEntries] = React.useState(0);
  const [alreadyEnteredEntries, setAlreadyEnteredEntries] = React.useState(0);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [paginatedData, setPaginatedData] = React.useState(
    options.slice(1, PAGE_LENGTH + 1),
  );

  // Methods
  const optionRender = (node) => {
    return (
      <div className="dropdown-option-container">
        <div className="dropdown-option-label">{node.label}</div>
      </div>
    );
  };

  const newOnBlur = (onBlur) => {
    if (onBlur) {
      onBlur();
    }
    setIsOpen(false);
  };

  const newOnFocus = () => {
    setIsOpen(true);
  };

  const onPagination = (event) => {
    let startCount =
      event.selected === 0 ? 1 : event.selected * PAGE_LENGTH + 1;
    let endCount = startCount + PAGE_LENGTH;
    setCurrentPage(event.selected);
    setPaginatedData(options.slice(startCount, endCount));
  };

  // Effects
  React.useEffect(() => {
    if (enterStrike) {
      const valueObjArray = [...value];
      const inputValArray = [
        ...new Set(inputValue.replace(/\s/g, "").split(",")),
      ];
      let alreadyEntries = 0;
      let invalidEntries = 0;

      if (inputValue.includes(",")) {
        inputValArray.map((val) => {
          if (!val) {
            return;
          }
          // Is val valid, included in options
          if (!options.filter((item) => item.value === val).length) {
            invalidEntries++;
            // Is val new, not included in value array
          } else if (value.filter((item) => item.value === val).length > 0) {
            alreadyEntries++;
            // No error, add to value array
          } else {
            valueObjArray.push({ value: val, label: val });
          }
        });
        onChange(valueObjArray);
      }
      setInvalidEntries(invalidEntries);
      setAlreadyEnteredEntries(alreadyEntries);
      setEnterStrike(false);
    }
  }, [enterStrike]);

  React.useEffect(() => {
    if (invalidEntries) {
      addToast({
        type: "error",
        message: `Your entry contained ${invalidEntries} invalid values`,
        toastId: "invalidEntries",
      });
      setInvalidEntries(0);
    }
  }, [invalidEntries]);

  React.useEffect(() => {
    if (alreadyEnteredEntries) {
      addToast({
        type: "error",
        message: `Your entry contained ${alreadyEnteredEntries} values already entered`,
        toastId: "alreadyEnteredEntries",
      });
      setAlreadyEnteredEntries(0);
    }
  }, [alreadyEnteredEntries]);

  React.useEffect(() => {
    if (
      (defaultAll && !value.length) ||
      (value &&
        value[0] &&
        (value[0].value === "all" || value[0].value === "any"))
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [value]);

  React.useEffect(() => {
    setPaginatedData(options.slice(1, PAGE_LENGTH + 1));
  }, [options]);

  return (
    <div className="sg-multi-select-container">
      <div className="sg-multi-select-filter">
        <Select
          {...props}
          arrowRenderer={() => isOpen || !value.length}
          clearable={!isOpen && !!value.length}
          closeOnSelect={false}
          disabled={disabled}
          inputProps={inputProps}
          multi
          name={name}
          onBlur={() => newOnBlur(props.onBlur)}
          onChange={onChange}
          onFocus={() => newOnFocus()}
          onInputChange={(inputVal) => setInputValue(inputVal)}
          onInputKeyDown={function(event) {
            if (event.key === "Enter") {
              setEnterStrike(true);
            }
          }}
          optionRenderer={optionRender}
          options={options}
          placeholder={placeholder}
          removeSelected={false}
          value={value}
          valueComponent={!isOpen ? () => null : undefined}
        />
        {!isOpen && readMoreContent && value.length ? (
          <div className="number-selected-overlay">
            <ReadMoreElements
              {...props}
              content={readMoreContent}
              icon={
                isAllSelected ? (
                  "All"
                ) : (
                  <span className="color-link">{value.length} Selected</span>
                )
              }
              placement={placement}
              rootClose={true}
              title={name}
              zIndex={zIndex}
            />
          </div>
        ) : null}
        {allPagination && isAllSelected && (
          <React.Fragment>
            <Row className="show-all-container container-fluid d-flex">
              {paginatedData.map((item, index) => (
                <Col xs={6} key={`${name}-${index}`}>
                  <div className="value">{item.label}</div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col xs={12} className="paginator-container">
                <PaginatorComponent
                  pageCount={(options.length - 1) / PAGE_LENGTH}
                  pageChangeHandler={onPagination}
                  currentPage={currentPage}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

MultiSelectFilter.propTypes = {
  allPagination: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  pageLength: PropTypes.number,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  props: PropTypes.object,
  value: PropTypes.array.isRequired,
};

export default MultiSelectFilter;
