import React from 'react'
import PropTypes from 'prop-types'
import 'src/components/company_users/CompanyUsersHeader.scss'
import { Link } from "react-router-dom"
import { showCompanyId as companyId } from "src/shared/SessionHelper"
import { maintainStateStr } from 'src/utils/company_users/pageStateMaintainer'
const EmptyUser = {
  firstName: '',
  lastName: '',
  email: '',
  companyId,
  source: '',
  password: '',
  passwordConfirmation: '',
  avatarUrl: '',
  isManager: true,
  isActive: true,
  imageOriginalFileName: '',
  base64Image: '',
  formattedUserDate: '',
  createdAt: '',
}

const CompanyUsersHeader = ({
  createdOn,
  source,
  showBtn,
  label,
  content,
  createdAt,
  setQueriedUser,
  path,
  companyUserIsManager = 0,
}) => {
  const showCreatedOn = () => {
    if (path === 'add') return null
    let date = maintainStateStr({data: createdAt, path, key: 'company-user-created-at'})
    if (createdOn)
      return (
        <div className='company-users-header-date'>{date}</div>
      )

    return null
  }
  return (
    <div className='container-fluid d-flex justify-content-between'>
      <div className='d-flex container-fluid'>
        <img style={{...path !== 'users' ? {height: '36px'} : null}} className='header-img mr-4' src={source} alt='Users'/>
        <div style={{width: '100%'}} className='d-flex flex-column'>
          <div className='mb-2 header-large-text'>{label}</div>
          <div className='d-flex justify-content-between header-small-text'>
            {content}
            <div>{showCreatedOn()}</div>
          </div>
        </div>
      </div>
      {(showBtn && companyUserIsManager === 1) ? (
        <Link to={`/companies/${companyId}/users/add`} style={{ textDecoration: 'none' }}>
          <div
            onClick={() => (setQueriedUser(EmptyUser))}
            className='add-user-btn d-flex align-items-center justify-content-center'
          >
            + Add User
          </div>
        </Link>) : null}
    </div>
  )
}

CompanyUsersHeader.propTypes = {
  source: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  createdOn: PropTypes.bool.isRequired,
  setQueriedUser: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  showBtn: PropTypes.bool.isRequired,
  companyUserIsManager: PropTypes.number.isRequired
}

export default CompanyUsersHeader
