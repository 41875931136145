import React from "react";
import "./SidebarComponent.scss";
import information from "../../../../img/information.svg";
import Popup from "reactjs-popup";
import Select from "react-select";
import "react-select/dist/react-select.css";
import SidebarMenuComponent from "../../../../shared/sidebar-menu/SidebarMenuComponent";

/**
 * Component to render the Sidebar Component.
 * @param props - Props to be used from parent.
 * @returns {XML} -> Return the PrivateFeedbackListComponent
 */
const SidebarComponent = (props) => {
  // variables
  const { viewBy, sortBy, filterBy, platform, dateRange } =
    props.sidebarData || {};

  // methods
  const updateFilter = (filterKey, value) => {
    let filter = {
      ...props.filter,
    };
    filter[filterKey] = value;
    props.setFilters(filter);
  };

  // effects
  React.useEffect(() => {
    if (props.reset) {
      let filter = {
        ...props.filter,
      };
      if (viewBy && viewBy.length > 0) {
        filter.viewBy = viewBy[0];
      }
      if (dateRange && dateRange.length > 1) {
        filter.dateRange = dateRange[1];
      }
      if (sortBy && sortBy.length > 0) {
        filter.sortBy = sortBy[0];
      }
      props.setFilters(filter);
    }
  }, [props.reset]);

  return (
    <div id="sidebar-component">
      {viewBy ? (
        <SidebarMenuComponent
          title={"View By"}
          menuItems={viewBy}
          menuItemsFilters={props.filter.viewBy}
          clickHandler={updateFilter}
          eventName={"viewBy"}
        />
      ) : (
        ""
      )}
      {sortBy ? (
        <div className="mt-4">
          <div className="filter-title mb-2">Sort By</div>
          <Select
            value={props.filter.sortBy.value}
            name="sort-by"
            isClearable={false}
            onChange={(value) => {
              updateFilter("sortBy", value);
            }}
            options={sortBy}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
        </div>
      ) : (
        ""
      )}
      {filterBy && platform ? (
        <div className="mt-4">
          <div className="filter-title">Filter By</div>
          <div className="filter-sub-title mt-2">
            Source
            <Popup
              trigger={
                <img className="ml-1" alt="information" src={information} />
              }
              position="right center"
              on="hover"
            >
              <div className="p-2">Reviews sent via</div>
            </Popup>
            <br />
          </div>

          <Select
            value={props.filter.filterBy.value}
            name="source"
            isClearable={false}
            onChange={(value) => {
              updateFilter("filterBy", value);
            }}
            options={filterBy}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
          <div className="filter-sub-title mt-4">
            Platform
            <Popup
              trigger={
                <img className="ml-1" alt="information" src={information} />
              }
              position="right center"
              on="hover"
            >
              <div className="p-2">Review posted to</div>
            </Popup>
            <br />
          </div>

          <Select
            value={props.filter.platform.value}
            name="platform"
            isClearable={false}
            onChange={(value) => {
              updateFilter("platform", value);
            }}
            options={platform}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
        </div>
      ) : (
        ""
      )}
      {dateRange ? (
        <div className="mt-4">
          <div className="filter-title mb-2">Date Range</div>
          <Select
            value={props.filter.dateRange}
            name="date-range"
            isClearable={false}
            onChange={(value) => {
              updateFilter("dateRange", value);
            }}
            options={dateRange}
            isOptionDisabled={(option) => option.disabled}
            inputProps={{ readOnly: true }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default SidebarComponent;
