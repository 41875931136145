import React, { useState, useEffect } from "react";
import NumberUtil from "src/utils/NumberUtil";
import DonutChart from "src/shared/styleguide/charts/DonutChart";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_REVIEW_DISTRIBUTION_BY_SOURCE } from "src/graphql/reviews/results/queries";
import { REVIEW_RESULTS_FETCH_POLICY } from "src/constants/ReviewResults";

const ReviewDistributionBySource = ({
  companyId,
  filter,
  toDate,
  fromDate
}) => {
  // state
  const [noData, setNoData] = useState(false);
  const [reviewDistributionBySource, setReviewDistributionBySource] = useState([
    {
      type: "pie",
      name: "Sentiment score",
      data: [
        { name: "Email", y: 0, count: 0, color: "#f6b200" },
        { name: "Review Me", y: 0, count: 0, color: "#dc5700" },
        { name: "Customer Calls", y: 0, count: 0, color: "#a3cbf5" },
        { name: "Web", y: 0, count: 0, color: "#5d7ae1" },
        { name: "Handwritten", y: 0, count: 0, color: "#d7f034" },
        { name: "Kiosk", y: 0, count: 0, color: "#898989" },
        { name: "Custom Invites", y: 0, count: 0, color: "#a78440" },
        { name: "Web Text", y: 0, count: 0, color: "#5cb85c" },
      ],
    },
  ]);

  // query
  const { loading, data } = useQuery(QUERY_REVIEW_DISTRIBUTION_BY_SOURCE, {
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    variables: {
      companyId,
      dateFrom: filter.value,
      dateBucket: filter.bucket
    }
  });

  // effects
  useEffect(() => {
    if (data && data.reviewsDistributionBySource) {
      const source = data.reviewsDistributionBySource;
      const newReviewDistributionBySource = { ...reviewDistributionBySource };
      // email
      newReviewDistributionBySource[0].data[0].y = source.emailPercent;
      newReviewDistributionBySource[0].data[0].count = source.emailCount;

      // review me
      newReviewDistributionBySource[0].data[1].y = source.reviewmePercent;
      newReviewDistributionBySource[0].data[1].count = source.reviewmeCount;

      // customer calls
      newReviewDistributionBySource[0].data[2].y = source.customerCallPercent;
      newReviewDistributionBySource[0].data[2].count = source.customerCallCount;

      // web
      newReviewDistributionBySource[0].data[3].y = source.webPercent;
      newReviewDistributionBySource[0].data[3].count = source.webCount;

      // handwritten
      newReviewDistributionBySource[0].data[4].y = source.handwrittenPercent;
      newReviewDistributionBySource[0].data[4].count = source.handwrittenCount;

      // kiosk
      newReviewDistributionBySource[0].data[5].y = source.kioskPercent;
      newReviewDistributionBySource[0].data[5].count = source.kioskCount;

      // custom
      newReviewDistributionBySource[0].data[6].y = source.customPercent;
      newReviewDistributionBySource[0].data[6].count = source.customCount;

      // Web Text
      newReviewDistributionBySource[0].data[7].y = source.webTextPercent;
      newReviewDistributionBySource[0].data[7].count = source.webTextCount;

      const total = newReviewDistributionBySource[0].data.reduce(
        (acc, val) => acc + val.y,
        0
      );
      if (total === 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setReviewDistributionBySource(newReviewDistributionBySource);
    }
  }, [data]);

  // render
  return (
    <DonutChart
      loading={loading}
      noData={noData}
      chartId="reviewDistributionBySource"
      title="Review Distribution"
      subTitle="by Source"
      popupInfoMsg="This shows a percentage of the reviews submitted from different invite sources for the selected date range. It compares all the sources for the selected date range."
      chartData={reviewDistributionBySource}
      pieSize={188}
      tooltipFormatter={function() {
        const { point } = this;
        const { data } = this.series;
        const total = data.reduce((acc, val) => acc + val.count, 0) || 1;
        return `
${fromDate}${fromDate !== toDate ? ` to ${toDate}` : ""}
<br />${point.name}: ${point.count} (${point.y}%)
<br />Total: ${total}`;
      }}
      labelFormatter={ctx => `${ctx.name} (${NumberUtil.toK(ctx.count, 2)})`}
    />
  );
};

ReviewDistributionBySource.propTypes = {
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  toDate: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired
};

export default ReviewDistributionBySource;
