import React from "react";
import PropTypes from "prop-types";

export const StyleGuideSection = ({
  separator = true,
  title = "",
  description = "",
  children,
  marginTop = "2.3rem",
  marginBottom = "2.3rem"
}) => {
  return (
    <React.Fragment>
      {separator ? (
        <hr
          style={{
            marginTop,
            marginBottom
          }}
          className="thick"
        />
      ) : null}
      {title ? (
        <h5 className="text-dark text-capitalize font-weight-bold">{title}</h5>
      ) : null}
      {description !== "" ? <div>{description}</div> : null}
      {children}
    </React.Fragment>
  );
};

StyleGuideSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  separator: PropTypes.bool,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string
};

export default StyleGuideSection;
